import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAsyncDebounce } from "react-table";
import { useIsMounted } from "@sportal/cdk/hooks";

import { useIntlTranslate } from "../../../hooks";
import Input from "../../input/Input";

export const TableSearch = ({
  setGlobalFilter,
  state: { globalFilter },
  ariaLabelMessage
}) => {
  const translate = useIntlTranslate();

  const [value, setValue] = useState(globalFilter || "");
  const isMounted = useIsMounted();
  const onChange = useAsyncDebounce(value => {
    if (!isMounted()) {
      return;
    }
    setGlobalFilter(value);
  }, 200);

  useEffect(() => {
    onChange(value.trim());
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Input
      value={value}
      placeholder={translate("search")}
      onChange={e => setValue(e.target.value)}
      onClear={() => setValue("")}
      className={"table__search"}
      icon="fas fa-search"
      aria-label={ariaLabelMessage && translate(ariaLabelMessage)}
    />
  );
};

TableSearch.propTypes = {
  setGlobalFilter: PropTypes.func.isRequired,
  state: PropTypes.shape({
    globalFilter: PropTypes.string
  }),
  ariaLabelMessage: PropTypes.string
};
