import {
  DataKeys,
  DrilldownDataKeys
} from "../../helpers/reports.helper.types";
import { DrilldownRoutePage, ChartConfig } from "./drilldown.types";

type ChartCofigs = {
  [key in DrilldownRoutePage]: ChartConfig;
};

export const chartProps: ChartCofigs = {
  [DrilldownRoutePage.Webfilter]: {
    tableDataEndpoint: DrilldownDataKeys.webfilter,
    chartDataEndpoint: DataKeys.webfilter
  },
  [DrilldownRoutePage.Malware]: {
    tableDataEndpoint: DrilldownDataKeys.malware,
    chartDataEndpoint: DataKeys.malware
  },
  [DrilldownRoutePage.Botnet]: {
    tableDataEndpoint: DrilldownDataKeys.botnet,
    chartDataEndpoint: DataKeys.botnet
  }
};
