import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { useToggle } from "@sportal/cdk/hooks";

import { useIntlTranslate } from "../../hooks";
import { CommonModalHeader, ModalDialog } from "../modal";
import { Copyable } from "../copyable/Copyable";
import { Button } from "../button/Button";
import { Icon } from "../icon/Icon";

import "./DeeplinkDialog.scss";

const DeeplinkGenerationStatus = ({ icon, messageId }) => (
  <div className="deeplink-modal__status">
    <Icon icon={classnames(icon, "deeplink-modal__icon")}/>
    <FormattedMessage id={messageId}/>
  </div>);

const DeeplinkDialog = ({ className, onSubmit, onClose, children }) => (
  <ModalDialog
    className={classnames("deeplink-modal", className)}
    submitHandler={onSubmit}
    rejectHandler={onClose}
  >
    {children}
  </ModalDialog>);

export const DeeplinkErrorDialog = ({ modal }) => {
  const close = () => modal.reject();
  const submit = () => modal.resolve();

  return (<DeeplinkDialog
    className="deeplink-modal--error"
    onSubmit={submit}
    onClose={close}
  >
    <CommonModalHeader>
      <FormattedMessage id="instalation_link"/>
    </CommonModalHeader>
    <ModalDialog.Body>
      <DeeplinkGenerationStatus
        icon="fas fa-exclamation-triangle"
        messageId="deeplink_generation_status_error"
      />
      <div className="deeplink-modal__text">
        <FormattedMessage id="deeplink_generation_failure"/>
      </div>
    </ModalDialog.Body>
    <ModalDialog.Footer>
      <Button size={"large"} onClick={submit}>
        <FormattedMessage id="deeplink_generation_try_again"/>
      </Button>
    </ModalDialog.Footer>
  </DeeplinkDialog>);
};

export const DeeplinkSuccessDialog = ({ modal, link, expires }) => {
  const close = () => modal.reject();
  const submit = () => modal.resolve();

  const translate = useIntlTranslate();
  const [isExpanded, expandDetails] = useToggle(false)

  return (<DeeplinkDialog
    className="deeplink-modal--success"
    onSubmit={submit}
    onClose={close}
  >
    <CommonModalHeader>
      <FormattedMessage id="instalation_link_title"/>
    </CommonModalHeader>
    <ModalDialog.Body>
      <div className="deeplink-modal__body">
        <FormattedMessage id="deeplink_generation_instructions_title"/>
        <ol className="deeplink-modal__list">
          <li>
            <FormattedMessage id="deeplink_generation_instructions_step_1"/>
          </li>
          <li>
            <FormattedMessage id="deeplink_generation_instructions_step_2"/>
          </li>
          <li>
            <FormattedMessage id="deeplink_generation_instructions_step_3"/>
          </li>
        </ol>
      </div>
    </ModalDialog.Body>
    <ModalDialog.Footer>
      <div>
        <Copyable
          text={translate("copy_link_with_instructions_content", {
            link, expires
          })}
        >
          <Button className="deeplink-modal__copy-btn" size={"large"}>
            <FormattedMessage id="copy_link_with_instructions"/>
          </Button>
        </Copyable>
        <div className="deeplink-modal__instruction-details">
          <div className="deeplink-modal__instruction-details--toggle"
               onClick={expandDetails}>
              <span>
                <FormattedMessage id="copy_link_instruction"/>
              </span>
            {isExpanded ? (<Icon icon="fa fa-angle-up"/>) : (
              <Icon icon="fa fa-angle-down"/>)}
          </div>
          {isExpanded && (
            <p className="deeplink-modal__instruction-details--text">
              <FormattedMessage
                id="copy_link_instruction_details"
                values={{
                  b: chunks => <p>{chunks}</p>, expires
                }}
              />
            </p>)}
        </div>
      </div>
    </ModalDialog.Footer>
  </DeeplinkDialog>);
};
