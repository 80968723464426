import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useGlobalFilter,
  useExpanded,
  usePagination,
  useSortBy,
  useTable
} from "react-table";
import _ from "lodash";

import { TableSearch } from "./components/TableSearch";
import { THead } from "./components/THead";
import { TBody } from "./components/TBody";
import { TFoot } from "./components/TFoot";
import { Pagination } from "./components/Pagination";
import { MobileSort } from "./components/MobileSort";
import { alphanumericSort } from "./helpers/sorting.helper";
import { isMobile } from "../../helpers/navigation.helper";
import { searchInArray, containsSubString } from "./helpers/filter.helper";

import "./Table.scss";

export const Table = ({
  columns,
  data,
  defaultSorted,
  noDataMessageID,
  noFilteredMessageID,
  renderGlobalFooter,
  renderRowSubComponent,
  searchable,
  searchKeys,
  showMobileSort,
  mobileSortAside,
  showPagination,
  subHeader,
  hiddenColumns,
  visibleHeader,
  searchAriaLabelMessage
}) => {
  const globalFilter = (rows, columnIds, globalFilterValue) => {
    return rows.filter(({ original: row }) => {
      const rowValues = _.pick(row, searchKeys);

      return _.values(rowValues).some(value =>
        _.isArray(value)
          ? searchInArray(value, globalFilterValue)
          : containsSubString(value, globalFilterValue)
      );
    });
  };

  const tableProps = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 25,
        sortBy: defaultSorted,
        hiddenColumns
      },
      sortTypes: { alphanumeric: alphanumericSort }, // override the default "react-table" sorting
      autoResetSortBy: false,
      ...(searchable && { globalFilter })
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  const {
    allColumns,
    getTableProps,
    headers,
    toggleSortBy,
    setGlobalFilter,
    state,
    visibleColumns,
    rows
  } = tableProps;

  const isPaginationVisible = isMobile()
    ? showPagination && rows.length > 25
    : showPagination;

  return (
    <Fragment>
      {searchable && (
        <TableSearch
          setGlobalFilter={setGlobalFilter}
          state={state}
          ariaLabelMessage={searchAriaLabelMessage}
        />
      )}
      {showMobileSort && (
        <MobileSort
          allColumns={allColumns}
          toggleSortBy={toggleSortBy}
          sortBy={state.sortBy}
          data-testid="mobile-sort"
        />
      )}
      {mobileSortAside}
      <div className={"table"}>
        <table className={"rt-table"} {...getTableProps()}>
          <THead
            headers={headers}
            subHeader={subHeader}
            visibleHeader={visibleHeader}
            visibleColumns={visibleColumns}
          />
          <TBody
            noDataMessageID={noDataMessageID}
            noFilteredMessageID={noFilteredMessageID}
            renderRowSubComponent={renderRowSubComponent}
            showPagination={showPagination}
            tableProps={tableProps}
          />
          {renderGlobalFooter && (
            <TFoot
              renderGlobalFooter={renderGlobalFooter}
              tableProps={tableProps}
            />
          )}
        </table>
        {isPaginationVisible && <Pagination {...tableProps} />}
      </div>
    </Fragment>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.array,
  defaultSorted: PropTypes.array,
  hiddenColumns: PropTypes.array,
  noDataMessageID: PropTypes.string,
  noFilteredMessageID: PropTypes.string,
  searchable: PropTypes.bool,
  searchKeys: PropTypes.array,
  showMobileSort: PropTypes.bool,
  showPagination: PropTypes.bool,
  visibleHeader: PropTypes.bool,
  searchAriaLabelMessage: PropTypes.string,
  mobileSortAside: PropTypes.node
};

Table.defaultProps = {
  columns: null,
  data: null,
  defaultSorted: [],
  hiddenColumns: [],
  noDataMessageID: "no_available_data",
  noFilteredMessageID: "no_matches_found",
  searchable: false,
  showMobileSort: false,
  showPagination: false,
  visibleHeader: true,
  renderRowSubComponent: undefined,
  renderGlobalFooter: undefined,
  subHeader: undefined,
  searchAriaLabelMessage: undefined
};
