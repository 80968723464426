import {
  REMOVE_BACKUP_ROUTE,
  SET_BACKUP_ROUTE
} from "../pages/login/login.actions";

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_BACKUP_ROUTE: {
      const backup = action.payload.backupRoute;
      const route = backup === "login" ? state.backupRoute : backup;

      return {
        ...state,
        backupRoute: route
      };
    }
    case REMOVE_BACKUP_ROUTE:
      return {};
    default:
      return state;
  }
};
