import { ReportsPeriod } from "../../helpers/reports.helper.types";

export const dateTimeFormat24h = {
  month: "short",
  year: "numeric",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit"
};

export const dateTimeFormat12h = {
  ...dateTimeFormat24h,
  hour: "numeric"
};

export interface TimePeriodComponentProps {
  className?: string;
  start?: number;
  end?: number;
  period?: ReportsPeriod;
  fullNotation?: boolean;
}

export interface TimePeriodFunctionArguments {
  start?: number;
  end?: number;
  period?: ReportsPeriod;
  is24: boolean;
  tz: string;
  locale: string;
}

export interface dateTimeFormatConfigs {
  start: {
    day: string;
    month: string;
    year?: string;
    hour?: string;
    minute?: string;
  };
  end: {
    day?: string;
    month?: string;
    year?: string;
    hour: string;
    minute: string;
  };
}
