import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog
} from "../../../../components/modal";
import Input from "../../../../components/input/Input";
import { validateProfileName } from "../../../../store/profiles/profiles.selectors";

const LIMIT = 30;

class ProfileDialog extends Component {
  constructor(props) {
    const { profile } = props;

    super(props);

    this.state = {
      group: profile ? profile.name : ""
    };
  }

  render() {
    const { modal, title, submitText } = this.props;

    return (
      <ModalDialog
        className={"add-profile-modal"}
        submitHandler={this.handleSubmit}
        rejectHandler={() => modal.reject()}
        disabled={this.isSubmitDisabled()}
      >
        <CommonModalHeader>
          <FormattedMessage id={title} />
        </CommonModalHeader>
        <ModalDialog.Body>
          <Input
            value={this.state.group}
            label={<FormattedMessage id={"group_name"} />}
            onChange={e => this.setState({ group: e.target.value })}
            descriptiveError={this.getMessageForInput()}
            validate={() => this.validateInputLength()}
            showLimit={true}
            limit={LIMIT}
            errorMessage={this.getErrorMessage()}
          />
        </ModalDialog.Body>
        <CommonModalFooter
          submitMessage={<FormattedMessage id={submitText} />}
        />
      </ModalDialog>
    );
  }

  getMessageForInput() {
    return (
      <FormattedMessage id={"max_characters_limit"} values={{ limit: LIMIT }} />
    );
  }

  getErrorMessage() {
    const { group } = this.state;
    const { profile } = this.props;
    const prevName = profile ? profile.name : "";

    const isUnique = this.isNameUnique();
    const validationError = this.isNameValid();

    if (_.isEmpty(group)) return "";
    if (group === prevName) return "";
    if (!isUnique) return <FormattedMessage id={"group_exists"} />;
    if (!_.isEmpty(validationError))
      return <FormattedMessage id={validationError} />;
  }

  validateInputLength() {
    return this.isLengthValid() ? null : this.getMessageForInput();
  }

  isLengthValid() {
    const { group } = this.state;

    return group.trim().length <= LIMIT;
  }

  isNameUnique() {
    const { profiles } = this.props;
    const { group } = this.state;
    const groupName = group
      .trim()
      .replace(/\s\s+/g, " ")
      .toLowerCase();

    const existing = _.chain(profiles)
      .without(this.props.name)
      .map(_.toLower)
      .value();

    return !_.includes(existing, groupName);
  }

  isNameValid() {
    return validateProfileName(this.state.group);
  }

  handleSubmit = () => {
    if (this.isSubmitDisabled()) return;

    const groupName = this.getNormalizedGroup();
    this.props.modal.resolve(groupName);
  };

  getNormalizedGroup = () => this.state.group.trim().replace(/\s\s+/g, " ");

  isSubmitDisabled = () => {
    const { group } = this.state;

    const isEmpty = _.isEmpty(group.trim());
    const isInvalidLength = !this.isLengthValid();
    const isInvalidName = !_.isEmpty(this.isNameValid());
    const isRepeatedName = !this.isNameUnique();

    return isEmpty || isInvalidLength || isInvalidName || isRepeatedName;
  };
}

export default ProfileDialog;
