import { RootState } from "../root.reducer";
import { getReports } from "../root.selectors";
import {
  FormattedBarChartData,
  TableData,
  WidgetsData,
  Zoom
} from "./reports.types";
import { ReportsPeriod } from "../../helpers/reports.helper.types";

export const getWidgetReports = (state: RootState): WidgetsData =>
  getReports(state).widgets;
export const getBarchartReports = (state: RootState): FormattedBarChartData[] =>
  getReports(state).barchart;
export const getReportsPeriod = (state: RootState): ReportsPeriod =>
  getReports(state).period;
export const getSelectedReportsProfile = (state: RootState): string =>
  getReports(state).profile;

export const getReportsZoom = (state: RootState): Zoom =>
  getReports(state).zoom;
export const getReportsZoomVisible = (state: RootState): boolean =>
  getReportsZoom(state).visible;
export const getZoomStart = state => getReportsZoom(state).start;
export const getZoomEnd = state => getReportsZoom(state).end;

export const getTableReports = (state: RootState): TableData =>
  getReports(state).table;
