import React from "react";
import { Button } from "../button/Button";
import "./SettingsButton.scss";

export const SettingsButton = props => {
  const { type, label, status, ...rest } = props;

  return (
    <Button
      {...rest}
      className={`settings-btn btn btn--outline--${type} ${props.className ||
        ""}`}
    >
      <span className="settings-btn__label">{label}</span>
      <span className="settings-btn__status">{status}</span>
    </Button>
  );
};
