import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import "./goBack.scss";

import { Icon } from "../icon/Icon";

export const GoBack = ({ url }) => {
  return (
    <a className={"go-back-btn"} href={url}>
      <Icon icon={"far fa-angle-left"} />
      <span>
        <FormattedMessage id={"back_to_selection"} />
      </span>
    </a>
  );
};

GoBack.propTypes = {
  url: PropTypes.string
};
