import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ProfileTab } from "./components/profileTab/ProfileTab";
import { WizardSidebarNavigation } from "../../components/wizardSidebarNavigation/WizardSidebarNavigation";
import { useDeleteProfileItem, useEditProfileItem } from "./components/hooks";
import { getVisibleProfilesWithKeys } from "../../store/profiles/profiles.selectors";

import "./Profiles.scss";
import { ActionsSection, MenuHeader, ProfileContent } from "./Profiles";
import { Card } from "../../components/card/Card";

const ProfilesTab = ({ profile }) => {
  const menuItems = [
    useEditProfileItem(profile),
    useDeleteProfileItem(profile)
  ];

  return (
    <ProfileTab
      type={profile.type}
      menu={profile.type === "custom" ? menuItems : null}
      profileId={profile.id}
      title={profile.type !== "custom" ? profile.type : profile.name}
      as={Card}
    />
  );
};

export const WizardProfiles = ({
  selected,
  onSelect,
  limit,
  customProfilesAmount
}) => {
  const profiles = useSelector(
    getVisibleProfilesWithKeys(["id", "name", "type"]),
    _.isEqual
  );

  const profileLimit = limit - 1; // 1 means hidden group for blocked devices
  const currentAmount = customProfilesAmount + 2; //TODO: 2 means default groups visitors and employees

  if (profiles.length === 1) {
    return (
      <div className="profiles">
        <ProfileContent />
      </div>
    );
  }

  const prepareTabs = () =>
    _.map(profiles, profile => {
      const profileId = profile.id;

      return {
        id: profileId,
        header: <ProfilesTab profile={profile} />,
        content: <ProfileContent />,
        name: profile.name,
        type: profile.type
      };
    });

  return (
    <div className="profiles">
      <WizardSidebarNavigation
        tabs={prepareTabs()}
        activeTab={selected}
        activeTabClass={ProfileTab.activeClass}
        onTabClick={onSelect}
        sidebarMenuHeader={
          <MenuHeader
            profileLimit={profileLimit}
            profilesLength={profiles.length}
            currentAmount={currentAmount}
          />
        }
        sideSection={<ActionsSection profileLimit={profileLimit} />}
      />
    </div>
  );
};

WizardProfiles.propTypes = {
  customProfilesAmount: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  selected: PropTypes.string,
  onSelect: PropTypes.func.isRequired
};
