import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import _ from "lodash";

import { processWidgetData } from "../../../helpers/reports.helper";
import { DataKeys } from "../../../helpers/reports.helper.types";
import { WidgetHeader } from "./WidgetHeader";
import Widget from "../../../components/charts/Widget";
import {
  getReportsPeriod,
  getWidgetReports
} from "../../../store/reports/reports.selectors";
import TimePeriodSelector from "../../../components/charts/timePeriodSelector/TimePeriodSelector";
import { ChartTitle } from "../Dashboard";
import { changeReportsTimePeriod } from "../dashboard.actions";
import { WidgetLineChart } from "../../../components/charts/widgetLineChart";

import "./WidgetsSection.scss";

const widgetsConsts = {
  [DataKeys.webfilter]: {
    icon: "globe",
    header: () => <WidgetHeader id={"webfilter"} />,
    drilldown: "webfilter",
    id: "webfilter",
    color: "#33C67C"
  },
  [DataKeys.malware]: {
    icon: "bug",
    header: () => <WidgetHeader id={"malware"} />,
    drilldown: "malware",
    id: "malware",
    color: "#ff5a34"
  },
  [DataKeys.botnet]: {
    icon: "ban",
    header: () => <WidgetHeader id={"botnet"} />,
    drilldown: "botnet",
    id: "botnet",
    color: "#16303e"
  }
};

const Widgets = memo(function WidgetsContainer() {
  const widgets = useSelector(getWidgetReports, _.isEqual);
  const period = useSelector(getReportsPeriod);

  return (
    <div className="widgets">
      {_.keys(widgets).map(key => {
        const data = processWidgetData(widgets[key], period);
        const classes = classnames(widgetsConsts[key].id);

        return (
          <Widget
            className={classes}
            data={data}
            key={key}
            period={period}
            {...widgetsConsts[key]}
          >
            <WidgetLineChart data={data} id={widgetsConsts[key].id} />
          </Widget>
        );
      })}
    </div>
  );
});

const WidgetsTimePeriodSelector = () => {
  const dispatch = useDispatch();

  const period = useSelector(getReportsPeriod);

  const handleTimePeriodChange = useCallback(
    period => {
      dispatch(changeReportsTimePeriod(period));
    },
    [dispatch]
  );

  return (
    <TimePeriodSelector
      showSelector={true}
      period={period}
      onChange={handleTimePeriodChange}
    />
  );
};

export const WidgetsSection = () => (
  <div className="chart-section">
    <h3 className="homepage-reports__title text-highlighted">
      <FormattedMessage id="statistic" />
    </h3>
    <WidgetsTimePeriodSelector />
    <ChartTitle title={<FormattedMessage id="blocked_by_1" />} />
    <Widgets />
  </div>
);
