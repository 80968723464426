import { ActionError } from "../../../../../store/redux.types";

export const ADD_SS_PROFILES = "[SS_PROFILES] ADD_SS_PROFILES";
export const ADD_SS_PROFILE = "[SS_PROFILES] ADD_SS_PROFILE";
export const SET_SUBSCRIBER_SAFETY = "[SS_PROFILES] SET_SUBSCRIBER_SAFETY";
export const GET_SS_PROFILES_SUCCESS = "[SS_PROFILES] GET_SS_PROFILES_SUCCESS";
export const GET_SS_PROFILES_FAILURE = "[SS_PROFILES] GET_SS_PROFILES_FAILURE";
export const SAVE_SS_PROFILE_SUCCESS = "[SS_PROFILES] SAVE_SS_PROFILE_SUCCESS";
export const SAVE_SS_PROFILES_SUCCESS =
  "[SS_PROFILES] SAVE_SS_PROFILES_SUCCESS";
export const SAVE_SS_PROFILES_FAILURE =
  "[SS_PROFILES] SAVE_SS_PROFILES_FAILURE";
export const REMOVE_SS_PROFILE = "[SS_PROFILES] REMOVE_SS_PROFILE";

export interface SSProfile {
  id: string;
  enabled: boolean;
}

export interface SSProfilesSlice {
  keys: string[];
  list: {
    [id: string]: SSProfile;
  };
}

export interface SSProfilesState {
  saved: SSProfilesSlice;
  changed: SSProfilesSlice;
}

export interface AddSsProfileAction {
  type: typeof ADD_SS_PROFILE;
  payload: {
    id: string;
    enabled: boolean;
  };
}

export interface AddSsProfilesAction {
  type: typeof ADD_SS_PROFILES;
  payload: SSProfile[];
}

export interface RemoveSsProfileAction {
  type: typeof REMOVE_SS_PROFILE;
  payload: string;
}

export interface SetSubscriberSafetyAction {
  type: typeof SET_SUBSCRIBER_SAFETY;
  payload: {
    id: string;
    enabled: boolean;
  };
}

export interface GetSsProfilesSuccessAction {
  type: typeof GET_SS_PROFILES_SUCCESS;
  payload: SSProfile[];
}

export interface GetSsProfilesFailureAction {
  type: typeof GET_SS_PROFILES_FAILURE;
  payload: ActionError;
}

export interface SaveSsProfileSuccessAction {
  type: typeof SAVE_SS_PROFILE_SUCCESS;
  payload: SSProfile;
}

export interface SaveSsProfilesSuccessAction {
  type: typeof SAVE_SS_PROFILES_SUCCESS;
  payload: SSProfile[];
}

export interface SaveSsProfilesFailureAction {
  type: typeof SAVE_SS_PROFILES_FAILURE;
  payload: ActionError;
}
