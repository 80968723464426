import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import {
  is24HourFormat,
  getTimeZone
} from "../../store/account/account.selectors";
import { formatIntlDateTime } from "@sportal/lib";
import { dateTimeFormat24h, dateTimeFormat12h } from "../time/time.types";

const formatLastSeen = (value, { is24, timezone, locale }) => {
  if (!value) {
    return null;
  }

  const baseConfig = is24 ? dateTimeFormat24h : dateTimeFormat12h;

  /**
   * time format functions of react-intl may return string like `24:46`. This is due to implementation of Intl.DateTimeFormat,
   * we need to specify "hourCycle" option to make it work properly. As I investigated react-intl ignores option with such name
   * (you can take a look at https://github.com/formatjs/formatjs/blob/1ec45cf4db91fe5523aa42c34bcd87a51a9ae245/src/formatters/dateTime.ts#L12-L28).
   * So, as a workaround we need to format datetime on our own.
   *
   * Here is the link to related issue on formatjs repo: https://github.com/formatjs/formatjs/issues/1577
   */

  return formatIntlDateTime({
    ...baseConfig,
    time: value,
    hour12: !is24,
    timeZone: timezone,
    locale
  });
};

export const useGetLastSeen = () => {
  const intl = useIntl();
  const is24 = useSelector(is24HourFormat);
  const timezone = useSelector(getTimeZone);

  const { locale } = intl;

  return useCallback(
    lastseen => formatLastSeen(lastseen, { is24, timezone, locale }),
    [is24, timezone, locale]
  );
};
