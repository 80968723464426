import React from "react";
import _ from "lodash";

import { ModalService } from "../modal";
import { getMappedProfilesForSelect } from "../../store/profiles/profiles.selectors";
import AssignDeviceDialog from "./modals/assign/AssignDeviceDialog";
import {
  editDevice,
  saveDevice,
  removeDevice,
  mergeDevice,
  unmergeDevice,
  editRoamingDevice,
  revokeRoamingDeviceAccess,
  blockDevice
} from "../../store/devices/devices.actions";
import {
  getLogicalDevicesList,
  getDevicesWithActiveRequests,
  getRoamingDevices,
  getTypesForIds,
  getLogicalDevices,
  getDeviceByGeneratedId,
  getRoamingDetailsByIdentifier
} from "../../store/devices/devices.selectors";
import BlockDeviceDialog from "./modals/block/BlockDeviceDialog";
import { UnblockDeviceDialog } from "./modals/unblock/UnblockDeviceDialog";
import { MoveDeviceDialog } from "./modals/move/MoveDeviceDialog";
import { EditRoamingDeviceDialog } from "./modals/edit/EditRoamingDeviceDialog";
import UnmergeDeviceDialog from "./modals/unmerge/UnmergeDeviceDialog";
import RemoveDeviceDialog from "./modals/remove/RemoveDeviceDialog";
import { RenameDeviceDialog } from "./modals/rename/RenameDeviceDialog";
import { RevokeRoamingDeviceAccessDialog } from "./modals/remove/RevokeRoamingDeviceAccessDialog";
import MergeDeviceDialog from "./modals/merge/MergeDeviceDialog";
import { AssignRoamingDeviceDialog } from "./modals/assignRoaming/AssignRoamingDeviceDialog";

export const openMergeModal = (device, devicesForMerge) => (
  dispatch,
  getState
) => {
  const state = getState();
  const profiles = getMappedProfilesForSelect(state);

  ModalService.show(modal => ({
    dialog: (
      <MergeDeviceDialog
        deviceToMerge={device}
        destinationDevices={devicesForMerge}
        profiles={profiles}
        modal={modal}
      />
    )
  }))
    .then(({ device, address }) => dispatch(mergeDevice(device, address)))
    .catch(() => null);
};

export const openAssignModal = (
  device,
  isDeviceExists,
  validateImmediately
) => (dispatch, getState) => {
  const state = getState();
  const devices = getLogicalDevicesList(state);
  const requested = getDevicesWithActiveRequests(state);
  const profiles = getMappedProfilesForSelect(state);

  ModalService.show(modal => ({
    dialog: (
      <AssignDeviceDialog
        device={device}
        devices={devices}
        profiles={profiles}
        requested={requested}
        validateImmediately={validateImmediately}
        modal={modal}
      />
    )
  }))
    .then(newDevice =>
      isDeviceExists
        ? dispatch(editDevice(newDevice, device.name))
        : dispatch(saveDevice(newDevice))
    )
    .catch(() => null);
};

export const openBlockModal = device => (dispatch, getState) => {
  const state = getState();
  const devices = getLogicalDevicesList(state);
  const requested = getDevicesWithActiveRequests(state);

  ModalService.show(modal => ({
    dialog: (
      <BlockDeviceDialog
        device={device}
        devices={devices}
        requested={requested}
        modal={modal}
      />
    )
  }))
    .then(device => {
      dispatch(blockDevice(device));
    })
    .catch(() => null);
};

export const openUnblockModal = device => (dispatch, getState) => {
  const state = getState();
  const profiles = getMappedProfilesForSelect(state);

  ModalService.show(modal => ({
    dialog: (
      <UnblockDeviceDialog device={device} modal={modal} profiles={profiles} />
    )
  }))
    .then(changes => {
      const updatedDevice = _.extend({}, device, changes);

      dispatch(editDevice(updatedDevice));
    })
    .catch(() => null);
};

export const openUnmergeModal = device => (dispatch, getState) => {
  const idsTypes = getTypesForIds(getState(), device.identifiers);

  ModalService.show(modal => ({
    dialog: (
      <UnmergeDeviceDialog device={device} idsTypes={idsTypes} modal={modal} />
    )
  }))
    .then(idToUnmerge => dispatch(unmergeDevice(device, idToUnmerge)))
    .catch(() => null);
};

export const openMoveModal = deviceName => (dispatch, getState) => {
  const state = getState();
  const devices = getLogicalDevicesList(state);
  const profiles = getMappedProfilesForSelect(state);
  const device = _.find(devices, { name: deviceName });

  ModalService.show(modal => ({
    dialog: (
      <MoveDeviceDialog
        device={device}
        devices={devices}
        profiles={profiles}
        modal={modal}
      />
    )
  }))
    .then(changes => {
      const updatedDevice = _.extend({}, device, changes);

      dispatch(editDevice(updatedDevice));
    })
    .catch(() => null);
};

export const openRemoveModal = deviceName => dispatch => {
  ModalService.show(modal => ({
    dialog: <RemoveDeviceDialog device={deviceName} modal={modal} />
  }))
    .then(device => dispatch(removeDevice(device)))
    .catch(() => null);
};

export const openRenameModal = device => (dispatch, getState) => {
  const state = getState();
  const savedName = device.name;
  const devices = getLogicalDevicesList(state);

  ModalService.show(modal => ({
    dialog: (
      <RenameDeviceDialog device={device} devices={devices} modal={modal} />
    )
  }))
    .then(changes => {
      const updatedDevice = _.extend({}, device, changes);

      dispatch(editDevice(updatedDevice, savedName));
    })
    .catch(() => null);
};

export const openEditRoamingDeviceModal = identifier => (
  dispatch,
  getState
) => {
  const state = getState();
  const devices = getRoamingDevices(state);
  const device = getRoamingDetailsByIdentifier(state, identifier);

  ModalService.show(modal => ({
    dialog: (
      <EditRoamingDeviceDialog
        modal={modal}
        device={device}
        devices={devices}
      />
    )
  }))
    .then(changes => dispatch(editRoamingDevice(device.identifier, changes)))
    .catch(() => null);
};

export const openAssignRoamingModal = device => (dispatch, getState) => {
  const state = getState();
  const allLogicalDevices = getLogicalDevices(state);
  const profiles = getMappedProfilesForSelect(state);
  const logicalDevice = getDeviceByGeneratedId(
    allLogicalDevices.list,
    device.logicalDeviceId
  );

  ModalService.show(modal => ({
    dialog: (
      <AssignRoamingDeviceDialog
        name={device.name}
        profile={device.profile}
        profiles={profiles}
        modal={modal}
      />
    )
  }))
    .then(changes => dispatch(editDevice(changes, logicalDevice.name)))
    .catch(() => null);
};

export const openRevokeRoamingDeviceAccessModal = address => (
  dispatch,
  getState
) => {
  const device = getRoamingDetailsByIdentifier(getState(), address);

  ModalService.show(modal => ({
    dialog: <RevokeRoamingDeviceAccessDialog modal={modal} device={device} />
  }))
    .then(() => dispatch(revokeRoamingDeviceAccess(device.identifier)))
    .catch(() => null);
};
