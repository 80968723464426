import React from "react";
import { useSelector } from "react-redux";

import { withLayout } from "../../../components/layout/Layout";
import { isSBProvisionedWithoutProfiles } from "../../../store/account";
import { ProtectionChoicePageForProvisioned } from "./ProtectionChoicePageForProvisioned";
import { ProtectionChoicePageForNonProvisioned } from "./ProtectionChoicePageForNonProvisioned";

import "./ProtectionChoicePage.scss";

const ProtectionChoicePageComponent = () => {
  const isProvisionedButNeedsConfiguration = useSelector(
    isSBProvisionedWithoutProfiles
  );

  return (
    <div className="row protection-choice-page">
      {isProvisionedButNeedsConfiguration ? (
        <ProtectionChoicePageForProvisioned />
      ) : (
        <ProtectionChoicePageForNonProvisioned />
      )}
    </div>
  );
};

export const ProtectionChoicePage = withLayout({
  showHeaderUnderline: true,
  showNavigation: false,
  showUserInfo: false,
  showLogOutLink: true
})(ProtectionChoicePageComponent);
