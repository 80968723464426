import React, { Component } from "react";
import { connect } from "react-redux";

import "./StaticNotifications.scss";
import { WebFilterOffNotification } from "./WebFilterOffNotification";

export const staticNotificationClassName = "static-notifications";

class StaticNotificationsComponent extends Component {
  render() {
    const { webFilterOff } = this.props;

    return (
      <div className={staticNotificationClassName}>
        {this.renderNotification(webFilterOff, <WebFilterOffNotification />)}
      </div>
    );
  }

  renderNotification = (notification, component) => {
    if (!notification || notification.dismissed) {
      return;
    }

    return (
      <div className={`${staticNotificationClassName}__${notification.type}`}>
        {component}
      </div>
    );
  };
}

export const StaticNotifications = connect(
  ({ notifications: { webFilterOff } }) => ({
    webFilterOff
  }),
  null
)(StaticNotificationsComponent);
