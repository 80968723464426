import { AccountData, CreateAccountData } from "@sportal/api";

export const GET_ACCOUNT_SUCCESS = "[AUTH] GET_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_FAILURE = "[AUTH] CREATE_ACCOUNT_FAILURE";
export const ACTIVATE_SERVICES_FAILURE = "[AUTH] ACTIVATE_SERVICES_FAILURE";
export const UPDATE_WEB_FILTER_SUCCESS = "[ACCOUNT] UPDATE_WEB_FILTER_SUCCESS";
export const UPDATE_WEB_FILTER_FAILURE = "[ACCOUNT] UPDATE_WEB_FILTER_FAILURE";
export const GET_SPSON = "[ACCOUNT] GET_SPSON";
export const GET_SPSON_SUCCESS = "[ACCOUNT] GET_SPSON_SUCCESS";
export const GET_SPSON_FAILURE = "[ACCOUNT] GET_SPSON_FAILURE";

interface getSpsonSuccessAction {
  type: typeof GET_SPSON_SUCCESS
}

interface GetAccountSuccessAction {
  type: typeof GET_ACCOUNT_SUCCESS;
  payload: AccountData | CreateAccountData;
}

interface UpdateWebFilterSuccessAction {
  type: typeof UPDATE_WEB_FILTER_SUCCESS;
  payload: boolean;
}

export type AccountActionTypes =
  | GetAccountSuccessAction
  | UpdateWebFilterSuccessAction
  | getSpsonSuccessAction;
