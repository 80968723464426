import React, { FC } from "react";
import classnames from "classnames";

import "./Button.scss";

type ButtonSize = "small" | "medium" | "large";
type ButtonColorType = "primary" | "destructive";
type ButtonType = "button" | "reset" | "submit";

interface ButtonProps extends React.HTMLAttributes<HTMLElement> {
  as?: "button" | "a" | "input" | "label";
  color?: ButtonColorType;
  "data-testid"?: string;
  disabled?: boolean;
  href?: string;
  pillButton?: boolean;
  isOutline?: boolean;
  size?: ButtonSize;
  type?: ButtonType;
}

type Button = FC<ButtonProps>;

enum ButtonSizes {
  small = "sm",
  medium = "md",
  large = "lg"
}

export const Button: Button = ({
  as,
  children,
  className,
  color,
  isOutline,
  pillButton,
  size,
  ...rest
}) => {
  const btnStyles = classnames(
    "btn",
    `btn--${color}`,
    `btn--${ButtonSizes[size]}`,
    isOutline ? "btn--outline" : "btn--solid",
    pillButton && "btn--pill",
    className
  );
  const Component = as;

  return (
    <Component {...rest} className={btnStyles}>
      {children}
    </Component>
  );
};

Button.defaultProps = {
  as: "button",
  isOutline: false,
  pillButton: false,
  size: "medium",
  color: "primary",
  type: "button"
};
