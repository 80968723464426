import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { AddDevice } from "../addDevice/AddDevice";
import { Icon } from "../../../../components/icon/Icon";

import "./NoDevices.scss";

export const NoDevices = ({ profileName }) => {
  return (
    <section className="no-devices">
      <div className="no-devices__icon">
        <Icon icon="far fa-address-card-o" />
      </div>
      <div className="no-devices__message">
        <FormattedMessage id="no_devices" />
      </div>

      <AddDevice currentProfile={profileName} />
    </section>
  );
};

NoDevices.propTypes = {
  profileName: PropTypes.string
};
