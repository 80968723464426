import React, { FC, Fragment } from "react";
import _ from "lodash";
import { GetItemPropsOptions } from "downshift";

import { SelectItem, ISelectItem } from "../selectItem";

import "./SelectMenu.scss";

interface SelectMenuProps {
  isOpen: boolean;
  menuProps: any;
  getItemProps: (options: GetItemPropsOptions<ISelectItem>) => any;
  items: ISelectItem[];
  selectedItem: ISelectItem;
  highlightedIndex: number;
  searchable?: boolean;
  noResultsMessage?: string;
}

export const SelectMenu: FC<SelectMenuProps> = ({
  isOpen,
  menuProps,
  getItemProps,
  items,
  selectedItem,
  highlightedIndex,
  searchable,
  noResultsMessage
}) => {
  return (
    <ul {...menuProps} className="select-menu">
      {isOpen && (
        <Fragment>
          {items.map((item, index) => (
            <SelectItem
              item={item}
              key={item.value}
              index={index}
              getItemProps={getItemProps}
              selected={items.indexOf(selectedItem) === index}
              highlighted={highlightedIndex === index}
            />
          ))}

          {searchable && _.isEmpty(items) && (
            <NoResultsFound message={noResultsMessage} />
          )}
        </Fragment>
      )}
    </ul>
  );
};

const NoResultsFound = ({ message = "No results found" }) => (
  <li className="select-menu__no-results" data-testid="no-result">
    {message}
  </li>
);
