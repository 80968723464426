import { useEffect } from "react";

import { useCallbackInstance } from "./useCallbackInstance";

export function useEventListener(eventName, handler, element = window) {
  const savedHandler = useCallbackInstance(handler);

  useEffect(() => {
    const eventListener = event => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName, element]);
}
