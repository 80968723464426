import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import _ from "lodash";
import { useSelector } from "react-redux";

import "./InternetSecurity.scss";

import SubscriberSafety from "./subscriberSafety/SubscriberSafety";
import { SafeSearch } from "./safeSearch/SafeSearch";
import { Icon } from "../../../../components/icon/Icon";
import { getProfileById } from "../../../../store/profiles/profiles.selectors";
import { useSelectedProfileId } from "../hooks";

function InternetSecurityContent({ className }) {
  const profileId = useSelectedProfileId();
  const { "safe-search-services": services } = useSelector(
    getProfileById(profileId),
    _.isEqual
  );

  return (
    <div className={classnames("internet-security", className)}>
      <h3 className="web-filters__header">
        <Icon icon="fas fa-lock" />
        <span>
          <FormattedMessage id={"advanced_security"} />
        </span>
      </h3>
      <SubscriberSafety profileId={profileId} />
      <SafeSearch
        className={"internet-security__option"}
        services={services}
        profileId={profileId}
      />
    </div>
  );
}

export const InternetSecurity = memo(InternetSecurityContent);
