import _ from "lodash";
import { Service } from "@sportal/api";
import { getSettings } from "../root.selectors";
import { PROFILES, SUPPORT } from "../../common/utils/permissions";

const getReporting = state => getSettings(state).reporting;
const getSBSettingsService = state => {
  const services = getSettings(state).services;

  return _.find(services, { service: Service.SecureBusiness });
};

export const isSBReportingLicensed = state => {
  const reporting = getReporting(state);
  return reporting && reporting[Service.SecureBusiness];
};

export const isMultipleSBSupported = state => {
  const service = getSBSettingsService(state);

  return service[SUPPORT[Service.SecureBusiness][PROFILES.MULTIPLE]];
};

export const getSBServiceProfilesLimit = state => {
  const service = getSBSettingsService(state);

  return service["max-profiles"];
};
