import React, { Fragment } from "react";

import { TableExpandableCard } from "../../../../components/table/components/TableExpandableCard";
import { SeverityIndicator } from "../../../../components/severityIndicator/SeverityIndicator";
import { HelpLink } from "../../../../components/helpLink/HelpLink";

interface Props {
  showExpand: boolean;
  isExpanded: boolean;
  severityIndex: number;
  threat: string;
  time: string;
  count: number;
  id: string;
  url?: string;
  device?: string;
}

export const CardWithThreats = ({
  showExpand,
  isExpanded,
  severityIndex,
  threat,
  time,
  count,
  id,
  url,
  device
}: Props) => {
  return (
    <TableExpandableCard
      showExpand={showExpand}
      isExpanded={isExpanded}
      content={
        <Fragment>
          {url && <span data-testid="threat-url">{url}</span>}
          <SeverityIndicator severity={severityIndex}>
            <HelpLink
              section={`threat-list-${id}`}
              data-for={`threat-description-${id}`}
              data-tip
            >
              {threat}
            </HelpLink>
          </SeverityIndicator>
          {device && <span data-testid="threat-device">{device}</span>}
          <span className="placeholder">{time}</span>
        </Fragment>
      }
      aside={<div className="count">{count}</div>}
    />
  );
};

CardWithThreats.defaultProps = {
  showExpand: true,
  isExpanded: false
};
