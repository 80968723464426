import React from "react";
import _ from "lodash";
import { getTimezoneOffset, format } from "date-fns-tz";
import { FormattedMessage } from "react-intl";

import {
  ATTRIBUTES,
  LANGUAGE_COOKIE_KEY,
  DEFAULT_FREQUENCY
} from "./attributes.constants";

import { changeTranslation } from "../../i18n/translation.actions";
import { setCookie } from "../../common/utils/cookie";
import Notificator from "../../components/notification/notification.actions";

const attributeNameToFieldMap = {
  [ATTRIBUTES.EMAIL]: "address",
  [ATTRIBUTES.EMAIL_FREQUENCY]: "frequency",
  [ATTRIBUTES.LANGUAGE]: "language",
  [ATTRIBUTES.TIME_FORMAT]: "is24hourFormat",
  [ATTRIBUTES.BUSINESS_NAME]: "businessName"
};

const attributeFieldToNameMap = _.invert(attributeNameToFieldMap);

export const loadAttributes = () => (dispatch, getState, { api }) => {
  const {
    subscriberInfo: { id },
    config
  } = getState();

  return api.ssm.account
    .getAttributes(id)
    .then(({ data: { content } }) => {
      let attributes = _.transform(
        content,
        (result, { name, value }) => {
          const field = attributeNameToFieldMap[name];
          if (field) result[field] = value;
        },
        {}
      );

      if (!attributes.language) {
        attributes.language =
          config.defaultLanguage || (config.useFallbackLanguage ? "us_EG" : "");
      }

      if (!attributes.frequency) {
        attributes.frequency = DEFAULT_FREQUENCY;
      }

      if (attributes.is24hourFormat) {
        // Fixes stringified boolean value from BE
        attributes.is24hourFormat = attributes.is24hourFormat === "true";
      }
      // TODO: following should be bettered in scope of EPORTAL-6205 fix
      if (_.has(attributes, "address") && _.isNil(attributes.address)) {
        attributes.address = "";
      }

      setCookie(LANGUAGE_COOKIE_KEY, attributes.language);
      dispatch(loadAttributeSuccess(attributes));
    })
    .catch(error => {
      dispatch({ type: LOAD_ATTRIBUTES_FAILURE, payload: error });
      return Promise.reject(error);
    });
};

export const getTimezones = () => (dispatch, getState, { api }) => {
  const getOffset = timezone => getTimezoneOffset(timezone);

  const byOffsetComparator = (a, b) => getOffset(a.value) - getOffset(b.value);

  const { accountSettings } = getState();

  if (!_.isEmpty(accountSettings.timezones)) {
    return;
  }

  return api.resources
    .getTimezones()
    .then(timezones => {
      const now = Date.now();

      const tz = _.map(timezones, timezone => ({
        value: timezone,
        name: timezone, // will be translated in component
        offset: format(now, "xxx", {
          timeZone: timezone
        })
      })).sort(byOffsetComparator);

      dispatch(loadTimezonesSuccess(tz));
    })
    .catch(error => {
      dispatch(generalFailure(error, LOAD_TIMEZONES_FAILURE));
      return Promise.reject();
    });
};

// TODO: seems transformable to saveAttribute
//  Additional action on start, and resolve,
//  not sure that current behavior is right in case when we saved language but failed to load translations
//  it seems that we can break state so that then it will lead to error on load
export const changeLanguage = language => (dispatch, getState, { api }) => {
  const { subscriberInfo } = getState();

  return Promise.all([
    api.ssm.account.updateAttribute(subscriberInfo.id, {
      name: ATTRIBUTES.LANGUAGE,
      value: language
    }),
    dispatch(changeTranslation(language))
  ])
    .then(() => {
      setCookie(LANGUAGE_COOKIE_KEY, language);
      dispatch(changeLanguageSuccess(language));
    })
    .catch(e => {
      dispatch(
        Notificator.error(<FormattedMessage id={"language_save_error"} />)
      );
      dispatch(generalFailure(e, CHANGE_LANGUAGE_FAILURE));

      return Promise.reject(e);
    });
};

// TODO: seems transformable to saveAttribute, but it uses different api call
export const changeTimezone = timezone => async (
  dispatch,
  getState,
  { api }
) => {
  const {
    subscriberInfo: { id }
  } = getState();

  try {
    await api.ssm.account.updateSubscriber(id, { "time-zone": timezone });
    dispatch(changeTimezoneSuccess(timezone));
  } catch (e) {
    dispatch(
      Notificator.error(<FormattedMessage id={"timezone_save_error"} />)
    );
    dispatch(generalFailure(e, CHANGE_TIMEZONE_FAILURE));

    return Promise.reject(e);
  }
};

// TODO: seems transformable to saveAttribute
//  the trick is that on success it should update different thing
//  Irina's approach should work good here
export const changeTimeFormat = timeFormat24h => async (
  dispatch,
  getState,
  { api }
) => {
  const {
    subscriberInfo: { id }
  } = getState();

  try {
    await api.ssm.account.updateAttribute(id, {
      name: ATTRIBUTES.TIME_FORMAT,
      value: timeFormat24h
    });
    dispatch(changeTimeFormatSuccess(timeFormat24h));
  } catch (e) {
    dispatch(
      Notificator.error(<FormattedMessage id={"timeformat_save_error"} />)
    );
    dispatch(generalFailure(e, CHANGE_TIME_FORMAT_FAILURE));

    return Promise.reject(e);
  }
};

export const saveScheduledReportsSettings = () => (dispatch, getState) => {
  const {
    accountSettings: {
      email,
      toSave: { email: newEmail }
    }
  } = getState();

  const shouldSaveEmail = newEmail.address !== email.address;
  const shouldSaveFrequency = newEmail.frequency !== email.frequency;

  return Promise.all(
    _.compact([
      shouldSaveEmail && dispatch(saveAttribute({ address: newEmail.address })),
      shouldSaveFrequency &&
        dispatch(saveAttribute({ frequency: newEmail.frequency }))
    ])
  );
};

const saveAttribute = attribute => (dispatch, getState, { api }) => {
  const {
    subscriberInfo: { id }
  } = getState();

  const [[field, value]] = _.toPairs(attribute);
  const dehydratedAttribute = { name: attributeFieldToNameMap[field], value };

  return api.ssm.account
    .updateAttribute(id, dehydratedAttribute)
    .then(() => {
      dispatch(saveAttributeSuccess(attribute));
    })
    .catch(error => {
      dispatch(saveAttributeFailure(dehydratedAttribute, error));
      return Promise.reject(error);
    });
};

export const LOAD_ATTRIBUTES_SUCCESS =
  "[USER_SETTINGS] LOAD_ATTRIBUTES_SUCCESS";
export const LOAD_ATTRIBUTES_FAILURE =
  "[USER_SETTINGS] LOAD_ATTRIBUTES_FAILURE";
export const LOAD_TIMEZONES_SUCCESS = "[USER_SETTINGS] LOAD_TIMEZONES_SUCCESS";
export const LOAD_TIMEZONES_FAILURE = "[USER_SETTINGS] LOAD_TIMEZONES_FAILURE";
export const CHANGE_LANGUAGE_SUCCESS =
  "[USER_SETTINGS] CHANGE_LANGUAGE_SUCCESS";
export const CHANGE_LANGUAGE_FAILURE =
  "[USER_SETTINGS] CHANGE_LANGUAGE_FAILURE";
export const CHANGE_TIMEZONE_SUCCESS =
  "[USER_SETTINGS] CHANGE_TIMEZONE_SUCCESS";
export const CHANGE_TIMEZONE_FAILURE =
  "[USER_SETTINGS] CHANGE_TIMEZONE_FAILURE";
export const CHANGE_TIME_FORMAT_SUCCESS =
  "[USER_SETTINGS] CHANGE_TIME_FORMAT_SUCCESS";
export const CHANGE_TIME_FORMAT_FAILURE =
  "[USER_SETTINGS] CHANGE_TIME_FORMAT_FAILURE";
export const CHANGE_ATTRIBUTE = "[USER_SETTINGS] CHANGE_ATTRIBUTE";

export const SAVE_ATTRIBUTE_SUCCESS = "[USER_SETTINGS] SAVE_ATTRIBUTE_SUCCESS";
export const saveAttributeSuccess = attribute => ({
  type: SAVE_ATTRIBUTE_SUCCESS,
  payload: { attribute }
});

const attributeNameToErrorIdMap = {
  [ATTRIBUTES.EMAIL_FREQUENCY]: "frequency_save_error",
  [ATTRIBUTES.EMAIL]: "email_save_error"
};

export const SAVE_ATTRIBUTE_FAILURE = "[USER_SETTINGS] SAVE_ATTRIBUTE_FAILURE";
export const saveAttributeFailure = (attribute, error) => dispatch => {
  dispatch({ type: SAVE_ATTRIBUTE_FAILURE, payload: error }); // atm just logging
  dispatch(
    Notificator.error(
      <FormattedMessage id={attributeNameToErrorIdMap[attribute.name]} />
    )
  );
};

export const DISCARD_SCHEDULED_REPORTS_SETTINGS =
  "[USER_SETTINGS] DISCARD_SCHEDULED_REPORTS_SETTINGS";
export const discardScheduledReportsSettings = () => ({
  type: DISCARD_SCHEDULED_REPORTS_SETTINGS
});

export const loadAttributeSuccess = attributes => ({
  type: LOAD_ATTRIBUTES_SUCCESS,
  payload: { attributes }
});
export const loadTimezonesSuccess = timezones => ({
  type: LOAD_TIMEZONES_SUCCESS,
  payload: timezones
});
export const changeLanguageSuccess = language => ({
  type: CHANGE_LANGUAGE_SUCCESS,
  payload: language
});
export const changeTimezoneSuccess = timezone => ({
  type: CHANGE_TIMEZONE_SUCCESS,
  payload: timezone
});
export const changeTimeFormatSuccess = is24hourFormat => ({
  type: CHANGE_TIME_FORMAT_SUCCESS,
  payload: is24hourFormat
});

export const changeAddress = address => changeAttribute({ address });
export const changeFrequency = frequency => changeAttribute({ frequency });

export const changeAttribute = attribute => ({
  type: CHANGE_ATTRIBUTE,
  payload: attribute
});

export const generalFailure = (error, type) => ({
  type,
  payload: error
});
