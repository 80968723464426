import * as d3 from "d3";

// NOTE: each attribute set by d3.selector stays on next component render,
// so its a good practice to do sort of cleanups fo some of them,
// just to avoid messing with other animations, take animationWalk as example

const INITIAL_DURATION = 2500;
const INITIAL_DELAY = 250;
export const INITIAL_TOTAL_DURATION = INITIAL_DURATION + INITIAL_DELAY;
export const animateWalk = (node: SVGPathElement, onComplete: () => void) => {
  const totalLength = node.getTotalLength();

  const cleanUp = () => {
    d3.select(node)
      .attr("stroke-dasharray", null)
      .attr("stroke-dashoffset", null);

    onComplete();
  };

  d3.select(node)
    .attr("stroke-dasharray", totalLength + " " + totalLength)
    .attr("stroke-dashoffset", totalLength)
    .transition()
    .duration(INITIAL_DURATION)
    .delay(INITIAL_DELAY)
    .ease(d3.easeCubicOut)
    .attr("stroke-dashoffset", 0)
    .on("end", cleanUp);
};

const DATA_TRANSITION_DURATION = 1000;
export const animateDataTransition = (node: SVGPathElement, path: string) => {
  d3.select(node)
    .transition()
    .duration(DATA_TRANSITION_DURATION)
    .ease(d3.easeCubicOut)
    .attr("d", path);
};

const APPEAR_DURATION = 500;
export const animateAppear = (node: SVGPathElement, onComplete: () => void) => {
  d3.select(node)
    .attr("opacity", 0)
    .transition()
    .duration(APPEAR_DURATION)
    .ease(d3.easeCubicIn)
    .delay(INITIAL_TOTAL_DURATION / 2)
    .attr("opacity", 1)
    .on("end", onComplete);
};
