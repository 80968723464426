import _ from "lodash";

const messages = {
  devices: {
    mac: {
      required: "mac_required",
      invalid: "invalid_mac"
    },
    name: {
      required: "name_required",
      length: "name_exceed_length",
      invalid: "device_name_invalid"
    }
  },
  usernames: {
    required: "name_required",
    length: "name_exceed_length",
    invalid: "device_name_invalid"
  }
};

export const patterns = {
  mac: /^(([0-9a-f]{2}-){5}[0-9a-f]{2}|([0-9a-f]{2}:){5}[0-9a-f]{2})$/i,
  code: /^[a-fA-F0-9]{0,4}$/i,
  deviceName: /^\.{1,2}$/,
  username: /^\.{1,2}$/
};

const constants = {
  devices: {
    name: {
      length: 30
    }
  },
  usernames: {
    length: 30
  }
};

export const isMacValid = mac => {
  if (!mac.length) {
    return messages.devices.mac.required;
  }

  if (!patterns.mac.test(mac)) {
    return messages.devices.mac.invalid;
  }
};

export const isDeviceNameValid = (value, allowEmptyName) => {
  const name = _.trim(value);

  if (!allowEmptyName && !name.length) {
    return messages.devices.name.required;
  }

  if (name.length > constants.devices.name.length) {
    return messages.devices.name.length;
  }

  if (patterns.deviceName.test(name)) {
    return messages.devices.name.invalid;
  }
};

export const isUserNameValid = (value, allowEmptyName) => {
  const name = _.trim(value);

  if (!allowEmptyName && !name.length) {
    return messages.usernames.required;
  }

  if (name.length > constants.usernames.length) {
    return messages.usernames.length;
  }

  if (patterns.username.test(name)) {
    return messages.usernames.invalid;
  }
};

export const isCodeSymbolsValid = code => {
  return patterns.code.test(code);
};

export const isDeviceNameBusy = (devices, value) => {
  const normalize = _.trim;
  const name = normalize(value);
  const names = _.map(devices, ({ name }) => normalize(name));

  return _.includes(names, name);
};

export const isDeviceAddressExist = (devices, address) => {
  const existingAddresses = _.flatMap(devices, "identifiers");
  const normalizedAddresses = _.map(existingAddresses, normalizeMacAddress);

  return _.includes(normalizedAddresses, normalizeMacAddress(address));
};

export const normalizeMacAddress = address => {
  const isMac = patterns.mac.test(address);

  return isMac ? ("" + address).toLowerCase().replace(/[:-]/g, ":") : address;
};
