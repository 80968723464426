import React from "react";
import { FormattedMessage } from "react-intl";
import { getInitialData } from "../../store/initial/initial.actions";
import Notificator from "../../components/notification/notification.actions";

export const login = (username, password, history) => (
  dispatch,
  getState,
  { api }
) => {
  let { backupRoute } = getState().history;
  backupRoute =
    backupRoute === "login" || backupRoute === undefined ? "home" : backupRoute;

  return api.auth
    .login(username, password)
    .catch((error = {}) => {
      dispatch(loginFailure(error));
      return Promise.reject();
    })
    .then(() => dispatch(resetUser()))
    .then(() => dispatch(getInitialData()))
    .then(() => history.push(`/${backupRoute}`))
    .then(() => dispatch(removeRouteBackup()))
    .then(() => dispatch(loginSuccess()));
};

export const logout = () => (dispatch, getState, { api }) => {
  const {
    subscriberInfo: { authMethod },
    config: {
      backendUrl,
      security: { logoutUrl }
    }
  } = getState();

  if (authMethod === "SSO") {
    window.location =
      (backendUrl || "") +
      "/saml/logout?action=" +
      encodeURIComponent(
        window.location.origin + window.location.pathname + "#login"
      );
    return Promise.resolve();
  }

  if (logoutUrl) {
    window.location = logoutUrl;
  }

  return api.auth
    .logout()
    .then(() => dispatch(resetUser()))
    .catch(error => {
      dispatch(
        Notificator.error(<FormattedMessage id={"error_during_logout"} />)
      );
      dispatch(logoutFailure(error));
    });
};

export const LOGIN_SUCCESS = "[AUTH] LOGIN_SUCCESS";
export const LOGIN_FAILURE = "[AUTH] LOGIN_FAILURE";
export const LOGOUT_FAILURE = "[AUTH] LOGOUT_FAILURE";
export const RESET_USER = "[AUTH] RESET_USER";
export const SET_SESSION_EXPIRED = "[AUTH] SET_SESSION_EXPIRED";
export const SET_BACKUP_ROUTE = "[HISTORY] SET_BACKUP_ROUTE";
export const REMOVE_BACKUP_ROUTE = "[HISTORY] REMOVE_BACKUP_ROUTE";

export const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS
  };
};
export const loginFailure = error => {
  return {
    type: LOGIN_FAILURE,
    payload: error
  };
};
export const logoutFailure = error => ({
  type: LOGOUT_FAILURE,
  payload: error
});
export const resetUser = () => ({
  type: RESET_USER
});
export const makeRouteBackup = route => ({
  type: SET_BACKUP_ROUTE,
  payload: { backupRoute: route }
});
export const removeRouteBackup = () => ({
  type: REMOVE_BACKUP_ROUTE
});
export const setSessionExpired = () => ({
  type: SET_SESSION_EXPIRED
});
