import { getResources } from "../../../../store/root.selectors";

const getSafety = state => getResources(state).safety;

export const getSafetyResource = state => getSafety(state).resource;
export const getSafetyToSave = state => getSafety(state).toSave;
export const isSafetyFetched = state => getSafety(state).isFetched;
export const getSafetyRequiredFields = state => getSafety(state).requiredFields;

const getSafetyValidation = state => getSafety(state).validation;

export const getFromSafetyValidation = key => state =>
  getSafetyValidation(state)[key];
