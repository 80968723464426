import { AxiosRequestConfig } from "axios";
import { Profile, SafeSearchService, Protection } from "@sportal/api";
import { UPDATE_PROFILE_IDS } from "./createProfiles.actions";

export const RENAME_PROFILE_SUCCESS = "[PROFILE] RENAME_PROFILE_SUCCESS";
export const RENAME_PROFILE_FAILURE = "[PROFILES] RENAME_PROFILE_FAILURE";
export const CHANGE_PROTECTION = "[PROFILE] CHANGE_PROTECTION";
export const CHANGE_SAFE_SEARCH_SERVICE =
  "[PROFILE] CHANGE_SAFE_SEARCH_SERVICE";
export const SAVE_PROFILE_SUCCESS = "[PROFILES] SAVE_PROFILE_SUCCESS";
export const SAVE_PROFILE_FAILURE = "[PROFILES] SAVE_PROFILE_FAILURE";

export type ProfileError = Error & { config?: AxiosRequestConfig };

export interface RenameProfileSuccessAction {
  type: typeof RENAME_PROFILE_SUCCESS;
  payload: {
    profile: string;
    newName: string;
    currentName: string;
  };
}

export interface RenameProfileFailureAction {
  type: typeof RENAME_PROFILE_FAILURE;
  payload: ProfileError;
}

export interface ChangeProtectionAction {
  type: typeof CHANGE_PROTECTION;
  payload: {
    profile: string;
    protection: Protection;
  };
}

export interface ChangeSafeSearchServiceAction {
  type: typeof CHANGE_SAFE_SEARCH_SERVICE;
  payload: {
    profile: string;
    service: SafeSearchService;
    enabled: boolean;
  };
}

export interface SaveProfileSuccessAction {
  type: typeof SAVE_PROFILE_SUCCESS;
}

export interface SaveProfileFailureAction {
  type: typeof SAVE_PROFILE_FAILURE;
  payload: ProfileError;
}

export enum ProfileType {
  Visitors = "visitors",
  Employees = "employees",
  Blocked = "blocked",
  Custom = "custom"
}

export type SBProfile = Profile & { type: ProfileType; hidden?: boolean };

export interface ProfilesSlice {
  keys: string[];
  list: {
    [key: string]: SBProfile;
  };
}

export interface ProfilesState {
  saved: ProfilesSlice;
  changed: ProfilesSlice;
  limit: number;
  selected: string;
}

export const GET_PROFILES_SUCCESS = "[PROFILES] GET_PROFILES_SUCCESS";
export const GET_PROFILES_FAILURE = "[PROFILES] GET_PROFILES_FAILURE";
export const SET_INITIAL_PROFILES = "[PROFILES] SET_INITIAL_PROFILES";
export const ADD_PROFILE = "[PROFILES] ADD_PROFILE";
export const CREATE_PROFILES_SUCCESS = "[PROFILES] CREATE_PROFILES_SUCCESS";
export const SET_SELECTED_PROFILE = "[PROFILES] SET_SELECTED_PROFILE";
export const REMOVE_PROFILE = "[PROFILES] REMOVE_PROFILE";
export const REMOVE_PROFILE_SUCCESS = "[PROFILES] REMOVE_PROFILE_SUCCESS";
export const RESET_ALL_PROFILES = "[PROFILES] RESET_ALL_PROFILES";
export const RESET_PROFILE = "[PROFILES] RESET_PROFILE";
export const ADD_PROFILE_FAILED = "[PROFILES] ADD_PROFILE_FAILED";

export interface GetProfilesSuccessAction {
  type: typeof GET_PROFILES_SUCCESS;
  payload: SBProfile[];
}

export interface GetProfilesFailureAction {
  type: typeof GET_PROFILES_FAILURE;
  payload: ProfileError;
}

export interface SetInitialProfilesAction {
  type: typeof SET_INITIAL_PROFILES;
  payload: SBProfile[];
}

export interface AddProfileAction {
  type: typeof ADD_PROFILE;
  payload: SBProfile;
}

export interface CreateProfilesSuccessAction {
  type: typeof CREATE_PROFILES_SUCCESS;
  payload: SBProfile[];
}

export interface SetSelectedProfileAction {
  type: typeof SET_SELECTED_PROFILE;
  payload: string;
}

export interface RemoveProfileAction {
  type: typeof REMOVE_PROFILE;
  payload: string;
}

export interface RemoveProfileSuccessAction {
  type: typeof REMOVE_PROFILE_SUCCESS;
  payload: {
    id: string;
    currentName: string;
    newName: string;
  };
}

export interface ResetProfilesAction {
  type: typeof RESET_ALL_PROFILES;
}

export interface ResetProfileAction {
  type: typeof RESET_PROFILE;
  payload: {
    id: string;
  };
}

export interface AddProfileFailedAction {
  type: typeof ADD_PROFILE_FAILED;
  payload: ProfileError;
}

export interface UpdateProfileIds {
  type: typeof UPDATE_PROFILE_IDS;
  payload: { map: { [oldId: string]: string } };
}
