import { NavLink } from "react-router-dom";
import { IntlShape } from "react-intl";

import { DevicesSets } from "../../helpers/devices.helpers";
import { TabItem } from "./tabControl/TabControl";

export interface DeviceTab {
  name: string;
  value: DevicesSets;
}

export interface DeviceLinkTab extends DeviceTab {
  url: string;
}

export function createDeviceTab(tab: DeviceTab, intl: IntlShape): TabItem {
  return {
    content: intl.formatMessage({
      id: tab.name,
      defaultMessage: tab.name
    }),
    id: tab.value
  };
}

export function createDeviceLinkTab(
  tab: DeviceLinkTab,
  url: string,
  intl: IntlShape
): TabItem {
  const regularTab = createDeviceTab(tab, intl);

  return {
    ...regularTab,
    config: {
      as: NavLink,
      to: `${url}/${tab.url}`,
      activeClassName: "tab-control--selected"
    }
  };
}
