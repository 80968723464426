import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isLoaded, shouldLoad } from "./withLoadingState.selectors";

export function useLoadableSlice(loadAction, selector) {
  const dispatch = useDispatch();
  const state = useSelector(selector);

  useEffect(() => {
    if (shouldLoad(state)) {
      dispatch(loadAction);
    }
  }, [state, loadAction, dispatch]);

  return isLoaded(state) ? state : null;
}
