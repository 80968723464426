import React from "react";
import { CommonModalHeader, ModalDialog } from "../../../components/modal";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../components/button/Button";
import { useDispatch } from "react-redux";
import { generateDeeplink } from "../../../components/deeplinkDialog/deeplinkDialog.actions";

import "./SpsonReminderDialog.scss";

export const SpsonReminderDialog = ({ modal }) => {
  const dispatch = useDispatch();

  const close = () => modal.reject();
  const submit = () => modal.resolve();
  const nextModal = () => dispatch(generateDeeplink(), { showLoader: false });

  return (
    <ModalDialog
      submitHandler={submit}
      rejectHandler={close}
      className="spson-reminder"
    >
      <CommonModalHeader>
        <FormattedMessage id="spson_reminder_title"/>
      </CommonModalHeader>
      <ModalDialog.Body>
        <FormattedMessage id="spson_reminder_description"/>
      </ModalDialog.Body>
      <ModalDialog.Footer>
        <Button size={"large"} onClick={submit} isOutline >
          <FormattedMessage id="activate_later"/>
        </Button>
        <Button size={"large"} onClick={nextModal}>
          <FormattedMessage id="activate_now"/>
        </Button>
      </ModalDialog.Footer>
    </ModalDialog>
  )
}
