import React, { Fragment } from "react";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { Checkbox } from "../../../../../components/checkbox/Checkbox";
import { changeSafeSearchService } from "../../../../../store/profiles/profile.actions";

const SAFE_SEARCH_ORDER = ["google", "bing", "youtube"];

const SubsectionHeader = ({ description, className, children }) => (
  <div className={classnames("subsection-header", className)}>
    <h4 className={"subsection-header__title"}>{children}</h4>
    <p className={"subsection-header__description"}>{description}</p>
  </div>
);

export const SafeSearch = ({ className, services, profileId }) => {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <SubsectionHeader
        description={<FormattedMessage id={"search_protection_description"} />}
      >
        <FormattedMessage id={"search_protection"} />
      </SubsectionHeader>
      {_.map(
        _.intersection(SAFE_SEARCH_ORDER, _.keys(services)),
        (option, key) => (
          <div className={className} key={key}>
            <Checkbox
              id={option}
              name={option}
              isChecked={services[option]}
              onChange={({ currentTarget: { checked } }) =>
                dispatch(changeSafeSearchService(profileId, option, checked))
              }
            >
              <FormattedMessage id={`${option}_request_fail`} />
            </Checkbox>
          </div>
        )
      )}
    </Fragment>
  );
};
