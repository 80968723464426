export enum UserMode {
  Household = "household",
  SubscriberSafety = "subscriber-safety",
  PISingle = "pi-single",
  PIProfile = "pi-profile",
  PerDevice = "per-device",
  SBSingle = "sb-single",
  SBProfile = "sb-profile"
}

export enum AuthMethod {
  SSO = "SSO",
  FormBased = "Form-based"
}

export interface InfoData {
  defaultTimezone: string;
  mode: UserMode;
  id: string;
  name: string;
  authMethod: AuthMethod;
}

export interface AuthInfo {
  authMethod: AuthMethod;
}

export interface InfoServerData {
  "default-timezone": string;
  id: string;
  mode: UserMode;
  name: string;
  timeout: number;
  timezones: string[];
  type: string; // TODO: create enum?
  version: number;
}
