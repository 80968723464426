import _ from "lodash";

import { ProfilesProvider } from "./profiles.provider";
import { failure, Result, success, UpdateResponseStatus } from "../shared";
import { AgeGroup, Profile } from "./profiles.types";
import { uuid } from "../../lib";

export enum ProfilesServiceErrorCode {
  GENERIC
}

export const AgeGroupsMap = {
  [AgeGroup.Kids]: "pi-ag1",
  [AgeGroup.Teens]: "pi-ag2",
  [AgeGroup.Adults]: "pi-ag3"
};

const rehydrate = (profile: Profile): Profile => ({
  id: uuid(),
  ...profile,
  ...(profile["age-group"] && {
    "age-group": _.findKey(
      AgeGroupsMap,
      value => value === profile["age-group"]
    ) as AgeGroup
  })
});

const dehydrate = profile => ({
  ..._.omit(profile, "id"),
  ...(profile["age-group"] && {
    "age-group": AgeGroupsMap[profile["age-group"]]
  })
});

export class ProfilesService {
  constructor(private profilesProvider: ProfilesProvider) {}

  public async get(subscriberId: string): Promise<Result<Profile[]>> {
    try {
      const { data } = await this.profilesProvider.get(subscriberId);

      const profiles = _.map(data.content, rehydrate);
      return success(profiles);
    } catch (error) {
      return failure(ProfilesServiceErrorCode.GENERIC);
    }
  }

  public async create(
    subscriberId: string,
    profiles: Profile[]
  ): Promise<Result<Profile[]>> {
    try {
      const { data } = await this.profilesProvider.create(subscriberId, _.map(
        profiles,
        dehydrate
      ) as Profile[]);
      return success(data);
    } catch (error) {
      return failure(ProfilesServiceErrorCode.GENERIC);
    }
  }

  public async delete(subscriberId: string, profileName: string) {
    try {
      await this.profilesProvider.delete(subscriberId, profileName);
      return success();
    } catch (error) {
      return failure(ProfilesServiceErrorCode.GENERIC);
    }
  }

  public async update(
    subscriberId: string,
    profileName: string,
    changedFields: object
  ) {
    try {
      const { data } = await this.profilesProvider.update(
        subscriberId,
        profileName,
        dehydrate(changedFields)
      );

      if (data.status === UpdateResponseStatus.PARTIAL_SUCCESS) {
        return failure(data.invalid);
      }

      return success();
    } catch (error) {
      return failure(ProfilesServiceErrorCode.GENERIC);
    }
  }
}
