import _ from "lodash";
import { useSelector } from "react-redux";

import {
  isMultipleUserLevel,
  isSPSONAvailable
} from "../../store/account/account.selectors";
import { isScheduleReportAvailable } from "../../store/config.selectors";
import { isSBReportingLicensed } from "../../store/settings/settings.selectors";
import { DrilldownRoutePage } from "../../pages/drilldown/drilldown.types";
import { useSelectedProfileId } from "../../pages/profiles/components/hooks";

export const MENU_ITEMS = {
  dashboard: {
    id: "dashboard",
    text: "dashboard",
    url: "/home",
    subMenu: [
      {
        id: DrilldownRoutePage.Webfilter,
        text: "blocked_activity",
        url: `/drilldown/${DrilldownRoutePage.Webfilter}`
      },
      {
        id: DrilldownRoutePage.Malware,
        text: "malware_and_phishing_blocked",
        url: `/drilldown/${DrilldownRoutePage.Malware}`
      },
      {
        id: DrilldownRoutePage.Botnet,
        text: "botnet_drilldown_title",
        url: `/drilldown/${DrilldownRoutePage.Botnet}`
      }
    ]
  },
  mobileDashboard: {
    id: "dashboard",
    text: "dashboard",
    url: "/home"
  },
  mobileDrilldown: {
    id: "drilldown",
    text: "blocked_activities",
    subMenu: [
      {
        id: DrilldownRoutePage.Webfilter,
        text: "blocked_activity",
        url: `/drilldown/${DrilldownRoutePage.Webfilter}`
      },
      {
        id: DrilldownRoutePage.Malware,
        text: "malware_and_phishing_blocked",
        url: `/drilldown/${DrilldownRoutePage.Malware}`
      },
      {
        id: DrilldownRoutePage.Botnet,
        text: "botnet_drilldown_title",
        url: `/drilldown/${DrilldownRoutePage.Botnet}`
      }
    ]
  },
  settings: {
    id: "settings",
    text: "settings",
    url: "/settings/profiles",
    subMenu: [
      {
        id: "profiles",
        text: "protection_preferences",
        url: "/settings/profiles",
        needsProfileId: true
      },
      {
        id: "devices",
        text: "devices",
        url: "/settings/devices",
        needsProfileId: true
      },
      {
        id: "url-filters",
        text: "url_filters",
        url: "/settings/url-filters",
        needsProfileId: true
      }
    ]
  },
  blockPage: {
    id: "block-page",
    text: "block_pages",
    url: "/settings/blocked-pages/branding",
    subMenu: [
      {
        id: "branding-block-page",
        text: "branding",
        url: "/settings/blocked-pages/branding"
      },
      {
        id: "webfilter-block-page",
        text: "web_filtering",
        url: "/settings/blocked-pages/webfilter"
      },
      {
        id: "malware-block-page",
        text: "malware_and_phishing",
        url: "/settings/blocked-pages/malware"
      }
    ]
  },
  domainLookup: {
    id: "domain-lookup",
    text: "domain_lookup",
    url: "/settings/domain-lookup"
  },
  scheduledReports: {
    id: "scheduled-reports",
    text: "scheduled_reports",
    url: "/settings/scheduled-reports"
  },
  settings_single: {
    id: "settings",
    text: "settings",
    url: "/settings/profiles"
  },
  globalFilters: {
    id: "global-filters",
    text: "global_url_filters",
    url: "/settings/global-url-filters"
  },
  spson: {
    id: "spson",
    text: "spson_short",
    url: "/settings/sps-remote"
  }
};

const MENU = {
  DESKTOP: {
    SINGLE: [
      MENU_ITEMS.dashboard,
      MENU_ITEMS.settings_single,
      MENU_ITEMS.spson,
      MENU_ITEMS.globalFilters,
      MENU_ITEMS.blockPage,
      MENU_ITEMS.domainLookup,
      MENU_ITEMS.scheduledReports
    ],
    MULTIPLE: [
      MENU_ITEMS.dashboard,
      MENU_ITEMS.settings,
      MENU_ITEMS.blockPage,
      MENU_ITEMS.domainLookup,
      MENU_ITEMS.scheduledReports
    ]
  },
  MOBILE: {
    SINGLE: [
      MENU_ITEMS.mobileDashboard,
      MENU_ITEMS.mobileDrilldown,
      MENU_ITEMS.settings_single,
      MENU_ITEMS.spson,
      MENU_ITEMS.globalFilters,
      MENU_ITEMS.blockPage,
      MENU_ITEMS.domainLookup,
      MENU_ITEMS.scheduledReports
    ],
    MULTIPLE: [
      MENU_ITEMS.mobileDashboard,
      MENU_ITEMS.mobileDrilldown,
      MENU_ITEMS.settings,
      MENU_ITEMS.blockPage,
      MENU_ITEMS.domainLookup,
      MENU_ITEMS.scheduledReports
    ]
  }
};

const getMenuItemsByScreen = (screenSize = "DESKTOP", profileId) => state => {
  const isMultiple = isMultipleUserLevel(state);
  const menuItems = MENU[screenSize][isMultiple ? "MULTIPLE" : "SINGLE"];
  const menuItemsWithProfileIds = menuItems.map(item => {
    const subMenu =
      item.subMenu &&
      _.map(item.subMenu, subItem => {
        return subItem.needsProfileId
          ? {
              id: subItem.id,
              text: subItem.text,
              baseUrl: subItem.url,
              url: `${subItem.url}/${profileId}`
            }
          : subItem;
      });

    return subMenu ? { ...item, subMenu } : item;
  });

  return filterMenuItems(state, menuItemsWithProfileIds);
};

const useMenuItems = screenSize => {
  const profileId = useSelectedProfileId();

  return useSelector(getMenuItemsByScreen(screenSize, profileId), _.isEqual);
};

export const useDesktopMenuItems = () => useMenuItems("DESKTOP");
export const useMobileMenuItems = () => useMenuItems("MOBILE");

const filterMenuItems = (state, menuItems) => {
  const showScheduleReports = isScheduleReportAvailable(state);
  const showReports = isSBReportingLicensed(state);
  const showSPSON = isSPSONAvailable(state);

  const removeScheduleReports = items =>
    _.reject(items, { id: "scheduled-reports" });
  const removeDashboard = items => _.reject(items, { id: "dashboard" });
  const removeSPSON = items => _.reject(items, { id: "spson" });

  const pairs = [
    [!showScheduleReports, removeScheduleReports],
    [!showReports, removeDashboard],
    [!showSPSON, removeSPSON]
  ];

  return _.reduce(
    pairs,
    (result, [predicate, action]) => (predicate ? action(result) : result),
    menuItems
  );
};
