import React from "react";
import { FormattedMessage } from "react-intl";

import { isFixed } from "../../store/profiles/fixedProfiles";

export const FormattedProfileName = ({ id }) => {
  if (isFixed(id)) {
    return <FormattedMessage id={id} />;
  }

  return id;
};

export const formatProfileNameInHook = (name, translate) => {
  if (isFixed(name)) {
    return translate(name);
  }

  return name;
};

export const formatProfileNameInClass = (name, intl) => {
  if (isFixed(name)) {
    return intl.formatMessage({ id: name });
  }

  return name;
};
