import { useState, useCallback } from "react";

import { useTimeout } from "./useTimeout";

function performLegacyCopy(text) {
  const textField = document.createElement("textarea");
  textField.textContent = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
}

function createCopyToClipboardHook() {
  let hidePrevTooltip = null;

  const useCopyToClipboard = timeoutMs => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);

    useTimeout(
      () => {
        setTooltipVisible(false);
        hidePrevTooltip = null;
      },
      isTooltipVisible ? timeoutMs : null
    );

    const copyToClipboard = useCallback(async text => {
      // navigator.clipboard is only supported for pages served over HTTPS
      // as a fallback we use legacy method of copying text
      if (window.navigator.clipboard) {
        await window.navigator.clipboard.writeText(text);
      } else {
        performLegacyCopy(text);
      }

      if (hidePrevTooltip) {
        hidePrevTooltip();
      }

      setTooltipVisible(true);
      hidePrevTooltip = () => setTooltipVisible(false);
    }, []);

    return [copyToClipboard, isTooltipVisible];
  };

  const cleanup = () => {
    hidePrevTooltip = null;
  };

  return { useCopyToClipboard, cleanup };
}

const { useCopyToClipboard, cleanup } = createCopyToClipboardHook();

export { useCopyToClipboard, cleanup };
