import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";

import { ProtectionEditor } from "../../webFilters/protectionEditor/ProtectionEditor";
import ProtectionSearch from "../../webFilters/protectionSearch/ProtectionSearch";
import { SectionHeader } from "../../sectionHeader/SectionHeader";
import { Mobile, NotMobile } from "../../../../../hocs/Responsive";
import { ProtectionEditorDialogContainer } from "../../webFilters/protectionEditorDialog/ProtectionEditorDialogContainer";

export const ScheduleCategories = ({
  type,
  profile,
  categories,
  schedules,
  protection,
  blockedByWebFilter,
  changeCategories: change,
  isActive
}) => {
  const blockedBySchedule = schedules[0] && schedules[0].categories;
  const blocked = _.filter(categories, cat =>
    _.includes(blockedBySchedule, cat.name)
  );

  return (
    <div className="schedule__categories">
      <Mobile>
        <ProtectionEditorDialogContainer
          categories={{
            blocked,
            allowed: _.difference(categories, blocked)
          }}
          blockedByWebFilter={blockedByWebFilter}
          protection={protection}
          blockedRequired={true}
          isActive={isActive}
          setBlocked={categories => change(type, profile, categories)}
        />
      </Mobile>
      <NotMobile>
        <div className="schedule__categories-header">
          <SectionHeader
            description={
              <FormattedMessage id={"web_filter_option_description"} />
            }
          >
            <h4>
              <FormattedMessage id={"web_filter_option"} />
            </h4>
          </SectionHeader>
          <ProtectionSearch
            inputId="schedules-categories-search"
            categories={{
              blocked,
              allowed: _.difference(categories, blocked)
            }}
            onBlock={category =>
              change(
                type,
                profile,
                _.union(blockedBySchedule, _.castArray(category))
              )
            }
            onAllow={category =>
              change(
                type,
                profile,
                _.without(blockedBySchedule, ..._.castArray(category))
              )
            }
            protection={protection}
            blockedByWebFilter={blockedByWebFilter}
          />
        </div>
        <ProtectionEditor
          categories={{
            blocked,
            allowed: _.difference(categories, blocked)
          }}
          onBlock={category =>
            change(
              type,
              profile,
              _.union(blockedBySchedule, _.castArray(category))
            )
          }
          onAllow={category =>
            change(
              type,
              profile,
              _.without(blockedBySchedule, ..._.castArray(category))
            )
          }
          protection={protection}
          blockedByWebFilter={blockedByWebFilter}
          blockedRequired={true}
        />
      </NotMobile>
    </div>
  );
};
