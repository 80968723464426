import React, { useRef } from "react";
import { useIntl } from "react-intl";
import * as d3 from "d3";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { getHours, getMinutes, add, sub, getUnixTime } from "date-fns";
import _ from "lodash";

import { formatIntlDateTime, SECONDS_IN_DAY } from "@sportal/lib";
import { getTimeFormat } from "../../../../../helpers/reports.helper";
import { useBarChartContext } from "../../BarChartProvider";
import { ReportsPeriod } from "../../../../../helpers/reports.helper.types";

const TICK_PADDING = 20;

export const XAxis = () => {
  const {
    bottomChartHeight,
    is24,
    period,
    xBandScale: scale,
    ticks,
    topOffset,
    tz
  } = useBarChartContext();

  const intl = useIntl();
  const wrapper = useRef(null);

  const axis = d3.select(wrapper.current);

  const tickValues = rangeInclusive(
    getFirstTick(period, _.first(ticks), tz),
    _.last(ticks),
    getAxisTicksStep(period)
  );

  axis.call(
    d3
      .axisBottom(scale)
      .tickSize(-topOffset)
      .tickValues(tickValues)
      .tickFormat(formatTick(period, is24, tz, intl))
      .tickPadding(bottomChartHeight - TICK_PADDING)
  );

  return (
    <g
      className={"x-axis"}
      transform={`translate(0,${topOffset})`}
      ref={wrapper}
    />
  );
};

export const getFirstTick = (duration, start, tz) => {
  if (duration !== ReportsPeriod.Day) {
    return start;
  }

  let tick = utcToZonedTime(start * 1000, tz);

  const hours = getHours(tick);
  const minutes = getMinutes(tick);

  const hoursInSlice = 6;

  const isExactHour = hours % hoursInSlice === 0 && minutes === 0;
  if (isExactHour) return start;

  const closestHour = hours + hoursInSlice - (hours % hoursInSlice);

  tick = add(tick, { hours: closestHour - hours });
  tick = sub(tick, { minutes });

  return getUnixTime(zonedTimeToUtc(tick, tz));
};

export const rangeInclusive = (start, end, step) => {
  //_.range and d3.range have default and non-changeable inclusivity: `[)`, but we need to have `[]`
  if (start === end) return [start];

  const result = [];
  let tick = start;

  while (tick <= end) {
    result.push(tick);
    tick += step;
  }

  return result;
};

export const formatTick = (duration, is24, tz, intl) => tick => {
  return formatIntlDateTime({
    time: tick * 1000,
    hour12: !is24,
    ...getTimeFormat(duration, is24, tz),
    locale: intl.locale
  });
};

export const getAxisTicksStep = duration => {
  // returns seconds

  switch (duration) {
    case ReportsPeriod.Week:
      return 2 * SECONDS_IN_DAY; // 2 days
    case ReportsPeriod.Month:
      return 7 * SECONDS_IN_DAY; // 7 days
    default:
      return 6 * 60 * 60; // 6h
  }
};
