import React from "react";
import { FormattedMessage } from "react-intl";

import Input from "../../../../components/input/Input";
import { getMaxLengthValidationProps } from "../../helpers/form.helper";

export const TaglineInput = ({ value, onChange, validation }) => {
  const maxLengthValidationProps = validation
    ? getMaxLengthValidationProps(validation, value)
    : {};

  return (
    <Input
      id="tagline-input"
      value={value || ""}
      label={<FormattedMessage id="label_title" />}
      className="branding-form__tagline__input"
      onChange={e => onChange(e.target.value)}
      onClear={() => onChange("")}
      {...maxLengthValidationProps}
    />
  );
};
