import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { ModalService } from "../../../modal";
import HistoryClearDialog from "./HistoryClearDialog";
import { clearHistory } from "./historyClear.actions";

export const useOpenHistoryClearModal = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    ModalService.show(modal => ({
      dialog: <HistoryClearDialog modal={modal} />
    }))
      .then(metrics => dispatch(clearHistory(metrics)))
      .catch(() => null);
  }, [dispatch]);
};
