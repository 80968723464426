import React from "react";
import { connect } from "react-redux";

import { Loader } from "./Loader";

const LoaderOrNothing = ({ isShown }) => isShown && <Loader />;

export const LoaderContainer = connect(({ loader }) => ({
  isShown: !!loader.loadersShown
}))(LoaderOrNothing);
