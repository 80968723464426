import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

import { Tooltip } from "../../../components/tooltip/Tooltip";
import { Icon } from "../../../components/icon/Icon";
import { Mobile, NotMobile } from "../../../hocs/Responsive";

export const widgetTitles = {
  webfilter: {
    short: "web_filters",
    long: "web_filter"
  },
  malware: {
    short: "malware_and_phishing",
    long: "malware_and_phishing_title"
  },
  botnet: {
    short: "botnet",
    long: "botnet"
  }
};

export const WidgetHeader = ({ id }) => {
  return (
    <Fragment>
      <Mobile>
        <FormattedMessage id={widgetTitles[id].short} />
      </Mobile>
      <NotMobile>
        <span>
          <FormattedMessage id={widgetTitles[id].long} />
        </span>
        <Tooltip
          content={<FormattedMessage id={`${id}_tooltip_description`} />}
        >
          <Icon icon="far fa-question-circle" />
        </Tooltip>
      </NotMobile>
    </Fragment>
  );
};
