import _ from "lodash";
import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";

import { CategoryItem } from "./CategoryItem";

import "./CategoriesList.scss";

export const CategoriesList = injectIntl(
  ({ className, list, protection, blockedByWebFilter, intl }) => {
    // TODO: would be nice to extract and make more generic
    const filteredCategories = _(list)
      .map(category => ({
        ...category,
        description: (
          <FormattedMessage id={`category_${category.name}.description`} />
        ),
        warning: _.includes(blockedByWebFilter, category.name) ? (
          <FormattedMessage
            id={`blocked_by`}
            values={{ protection: <FormattedMessage id={protection} /> }}
          />
        ) : null,
        displayName: intl.formatMessage({
          id: `category_${category.name}`,
          defaultMessage: category.name
        })
      }))
      .thru(categories =>
        _.sortBy(categories, ({ displayName }) => displayName)
      )
      .orderBy("displayName")
      .value();

    return (
      <div className={className}>
        {_.map(filteredCategories, category => (
          <CategoryItem
            category={category}
            icon={category.icon}
            key={category.name}
            changeAction={category.changeAction}
          />
        ))}
      </div>
    );
  }
);

// function getPlaceholder(categories) {
//   const empty = _.isEmpty(categories);
//
//   if (!empty) return "";
//   if (!categoriesRequired) return placeholder;
//
//   return (
//     <div className={"categories-list__error"}>
//       <FormattedMessage id={"please_select_categories"}/>
//     </div>
//   );
// }
