import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { List, ListItem } from "../../../../components/list";
import { punyToUnicode } from "../../../../utils/punyToUnicode";

function onClick(e) {
  let url = e.target.getAttribute("data-href"),
    newTab = window.open();

  newTab.opener = null;
  newTab.location = url;
}

export const UrlList = ({
  icon,
  name,
  items,
  onItemRemove,
  onClear,
  className
}) => (
  <List
    className={classnames("url-list", className)}
    title={name}
    clearText={<FormattedMessage id="remove_all" />}
    onClear={onClear}
    placeholder={<FormattedMessage id="no_urls" />}
  >
    {_.map(items, item => (
      <ListItem item={item} icon={icon} key={item} onRemove={onItemRemove}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="url-list-item__content"
          data-href={`http://${item}`}
          rel="noreferrer noopener"
          onClick={e => onClick(e)}
        >
          {punyToUnicode(item)}
        </a>
      </ListItem>
    ))}
  </List>
);
