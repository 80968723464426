import _ from "lodash";

import { ProfilesState } from "../../store/profiles/profiles.types";
import { RootState } from "../../store/root.reducer";
import {
  drilldownDataFields,
  DrilldownDataKeys,
  ReportsPeriod
} from "../../helpers/reports.helper.types";
import { DrilldownRoutePage } from "./drilldown.types";
import { getShowDrilldownPeriod } from "../../store/config.selectors";
import { getReportsPeriod } from "../../store/reports/reports.selectors";

const CSV_HIDDEN_SINGLE_FIELDS = ["group-name"];

export const getDrilldownReportsPeriod = (state: RootState): ReportsPeriod =>
  getShowDrilldownPeriod(state) ? getReportsPeriod(state) : ReportsPeriod.Day;

export const getCSVUrl = (page: DrilldownRoutePage) => (state: RootState) => {
  const { account, profiles, reports, config } = state;

  const baseUrl = `${config.backendUrl}/ssm`;
  const url = DrilldownDataKeys[page];
  const view = account.id;
  const period = getDrilldownReportsPeriod(state);

  const params = [
    `view=${view}`,
    getTimePeriod(reports, period),
    getGroupName(reports),
    getFields(page, profiles)
  ];
  page !== DrilldownRoutePage.Botnet &&
    params.push(`limit=${config.secureBlocksLimitCsv}`);

  const urlQuery = _.compact(params).join("&");

  return `${baseUrl}/series/${url}.csv?${urlQuery}`;
};

const getTimePeriod = (reports, period: string) => {
  const { start, end } = reports.zoom;

  if (start && end) {
    return `start_inclusive=${start}&end_exclusive=${end}`;
  }

  return `duration=P${period}D`;
};
const getGroupName = reports => {
  if (reports.profile === "all_profiles") return "";

  return `group_name=${reports.profile}`;
};
const getFields = (page: DrilldownRoutePage, profiles: ProfilesState) => {
  const isSingle = profiles.saved.keys.length < 2;
  const allFields = drilldownDataFields[page];
  const fields = isSingle
    ? _.difference(allFields, CSV_HIDDEN_SINGLE_FIELDS)
    : allFields;

  return `fields=${fields.join(",")}`;
};
