import React, { Fragment, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { Mobile, NotMobile } from "../../../../hocs/Responsive";
import { Select } from "../../../select";
import NativeSelect from "../../../select/NativeSelect";
import { patterns } from "../../../../helpers/validation.helper";
import { FormattedProfileName } from "../../../profiles/FormattedProfileName";
import { ProfilesNativeSelect } from "../../../profiles/ProfilesNativeSelect";
import { useIntlTranslate } from "../../../../hooks";

export const DevicesSelect = ({
  devices,
  selected,
  label = "merge_with",
  onChange
}) => {
  const translate = useIntlTranslate();

  const items = useMemo(() => {
    return devices.map(device => ({
      value: device.value,
      content: device.name
    }));
  }, [devices]);

  return (
    <Fragment>
      <NotMobile>
        <Select
          items={items}
          selected={selected}
          onChange={option => onChange(option, "device")}
          label={translate(label)}
        />
      </NotMobile>
      <Mobile>
        <NativeSelect
          options={devices}
          selected={selected}
          toggleView={"input"}
          onChange={option => onChange(option, "device")}
          formatSelection={formatSelectionForDevices}
          id="device-select"
          label={label}
        />
      </Mobile>
    </Fragment>
  );
};

export const ProfilesSelect = ({
  profiles,
  selected,
  label = "device_group",
  onChange
}) => {
  const translate = useIntlTranslate();

  const items = useMemo(() => {
    return profiles.map(device => ({
      value: device.value,
      content: device.name
    }));
  }, [profiles]);

  return (
    <Fragment>
      <NotMobile>
        <Select
          items={items}
          selected={selected}
          onChange={option => onChange(option)}
          ariaLabel={translate("device_group")}
          label={translate(label)}
        />
      </NotMobile>
      <Mobile>
        <ProfilesNativeSelect
          profiles={profiles}
          selected={selected}
          onChange={onChange}
          formatSelection={(id, value) => formatSelectionForProfiles(id, value)}
          ariaLabelMessage="device_group"
          label={label}
        />
      </Mobile>
    </Fragment>
  );
};

const formatSelectionForDevices = (id, value) => {
  const isMac = patterns.mac.test(value);

  if (value === "") {
    return (
      <div className={"selected-custom"}>
        <span className={"selected-custom__placeholder"}>
          <FormattedMessage id={"select_device"} />
        </span>
      </div>
    );
  }

  return (
    <div className={"selected-custom"}>
      <span className={"selected-custom__label"}>
        <FormattedMessage id={isMac ? "mac_address" : "phone_number"} />
      </span>
      <span className={"selected-custom__id"}>{value}</span>
      <span className={"selected-custom__info"}>
        {value !== id && `(${id})`}
      </span>
    </div>
  );
};
const formatSelectionForProfiles = (id, value) => {
  if (value === "") {
    return (
      <div className={"selected-custom"}>
        <span className={"selected-custom__placeholder"}>
          <FormattedMessage id={"select_dg"} />
        </span>
      </div>
    );
  }

  return (
    <div className={"selected-custom"}>
      <span className={"selected-custom__label"}>
        <FormattedMessage id={"device_group"} />
      </span>
      <span className={"selected-custom__id"}>
        <FormattedProfileName id={id} />
      </span>
    </div>
  );
};
