import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import RadioButton from "../../../../components/radioButton/RadioButton";
import { punyToUnicode } from "../../../../utils/punyToUnicode";

import "./UrlOption.scss";
import { URL_TYPES } from "../../../../common/utils/url_types";

const humanNames = {
  [URL_TYPES.FQDN]: "fully_qualified_domain_name",
  [URL_TYPES.CORE_DOMAIN]: "whole_website",
  [URL_TYPES.HOST_PATH]: "specific_url",
  [URL_TYPES.PUBLIC_SUFFIX]: "top_level_domain"
};

const UrlOption = ({ type, node, isChecked, onChange }) => (
  <RadioButton className="url-option" isChecked={isChecked} onChange={onChange}>
    <article className="url-option__website-details">
      <p className="url-option__website-rule">
        <FormattedMessage id={humanNames[type]} />
      </p>
      <a
        href={`http://${node}`}
        target="_blank"
        rel="noopener noreferrer"
        className="url-option__website-link"
      >
        {type === URL_TYPES.PUBLIC_SUFFIX && "."}
        {punyToUnicode(node)}
      </a>
    </article>
    <p className="text-caption">
      <FormattedMessage id={`${humanNames[type]}_description`} />
    </p>
  </RadioButton>
);

UrlOption.propTypes = {
  type: PropTypes.string.isRequired,
  node: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func
};

export default UrlOption;
