import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { CustomPicker } from "react-color";
import {
  EditableInput,
  Hue,
  Saturation
} from "react-color/lib/components/common";
import { toState } from "react-color/lib/helpers/color";
import _ from "lodash";

import { Mobile, NotMobile } from "../../../../hocs/Responsive";
import { isMobile } from "../../../../helpers/navigation.helper";

import "./ColorPicker.scss";

const getHex = color => toState(color).hex;
const CirclePointer = () => <div className="circle-pointer" />;
const BarPointer = orientation => (
  <div className={`bar-pointer--${orientation}`} />
);

class ColorPicker extends Component {
  componentDidMount() {
    const { ariaLabelledBy } = this.props;

    if (this.inputRef.current && ariaLabelledBy) {
      const inputElement = this.inputRef.current.querySelector("input");
      inputElement.setAttribute("aria-labelledby", ariaLabelledBy);
    }
  }

  inputRef = createRef();

  render() {
    const { rgb, hex, ariaLabelledBy } = this.props;

    return (
      <div className={"color-picker"}>
        <div ref={this.inputRef} className={"color-picker__input"}>
          <div
            data-testid="input-sample"
            className="color-picker__input__sample"
            style={{ background: getHex(rgb) }}
          />
          <EditableInput
            value={hex}
            onChange={this.handleColorChange}
            aria-labelledby={ariaLabelledBy}
          />
        </div>
        <div className="color-picker__sliders">
          <NotMobile>
            <div className="color-picker__sliders__saturation">
              <Saturation
                {...this.props}
                pointer={CirclePointer}
                onChange={this.handleColorChange}
              />
            </div>
          </NotMobile>
          <div className="color-picker__sliders__hue">
            <NotMobile>
              <Hue
                {...this.props}
                pointer={_.bind(BarPointer, null, "vertical")}
                onChange={this.handleColorChange}
                direction="vertical"
              />
            </NotMobile>
            <Mobile>
              <Hue
                {...this.props}
                pointer={_.bind(BarPointer, null, "horizontal")}
                onChange={this.handleColorChange}
              />
            </Mobile>
          </div>
        </div>
      </div>
    );
  }

  handleColorChange = color => {
    const saturateColor = color => ({ ...color, s: 1, l: 0.5 });
    const isHsl = color => !!color.l;
    const newColor = isMobile() && isHsl(color) ? saturateColor(color) : color;

    this.props.onColorChange(getHex(newColor));
  };

  static propTypes = {
    onColorChange: PropTypes.func.isRequired,
    // following comes from "react-color" library
    hex: PropTypes.string,
    hsl: PropTypes.object,
    rgb: PropTypes.object,
    onChange: PropTypes.func,
    ariaLabelledBy: PropTypes.string
  };
}

export default CustomPicker(ColorPicker);
