import React, { cloneElement, useRef } from "react";
import _ from "lodash";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useSelect } from "downshift";

import "./Dropdown.scss";

import { DropdownItem, DropdownItemPropTypes } from "./DropdownItem";
import { getDropdownReducer } from "./DropdownReducer";
import { useDropdownOrientation } from "../hooks";

const allowedEventTypes = [
  useSelect.stateChangeTypes.ItemClick,
  useSelect.stateChangeTypes.MenuKeyDownEnter,
  useSelect.stateChangeTypes.MenuKeyDownSpaceButton
];

export const Dropdown = ({
  className,
  label,
  items,
  toggleButton,
  onDropdownToggle,
  disableHorizontalPosition,
  menuAriaLabel,
  hiddenLabel
}) => {
  const stateReducer = getDropdownReducer(items);
  const {
    isOpen: isDropdownOpen,
    getToggleButtonProps,
    getMenuProps,
    getLabelProps,
    highlightedIndex,
    getItemProps,
    selectedItem
  } = useSelect({
    items,
    stateReducer,
    defaultHighlightedIndex: -1,
    onStateChange: changes => {
      if (!allowedEventTypes.includes(changes.type)) {
        return;
      }

      if (changes.selectedItem && changes.selectedItem.action) {
        changes.selectedItem.action();
        return;
      }

      selectedItem && selectedItem.action && selectedItem.action();
    },
    onIsOpenChange: ({ isOpen }) => onDropdownToggle && onDropdownToggle(isOpen)
  });
  const toggleButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  const styles = useDropdownOrientation(
    toggleButtonRef,
    dropdownRef,
    isDropdownOpen
  );

  const menuClasses = classnames("dropdown__menu", {
    "dropdown__menu--visible": isDropdownOpen,
    "dropdown__menu--with-label": Boolean(label)
  });

  return (
    <div className={classnames("dropdown", className)}>
      <div ref={toggleButtonRef} className="dropdown__toggle">
        {label && (
          <span
            {...getLabelProps()}
            className={classnames("dropdown__label", {
              "dropdown__label--hidden": hiddenLabel
            })}
          >
            {label}
          </span>
        )}
        {cloneElement(toggleButton, getToggleButtonProps())}
      </div>
      <ul
        {...getMenuProps({
          ref: dropdownRef,
          className: menuClasses,
          style: disableHorizontalPosition
            ? _.omit(styles, ["left", "right"])
            : styles,
          "aria-label": menuAriaLabel
        })}
      >
        {isDropdownOpen &&
          items.map((item, index) => (
            <DropdownItem
              item={item}
              key={index}
              index={index}
              highlightedIndex={highlightedIndex}
              getItemProps={getItemProps}
            />
          ))}
      </ul>
    </div>
  );
};

export const createDivider = () => ({
  className: "dropdown__item--divider",
  selectable: false
});

export const createTitle = (content, id) => ({
  className: "dropdown__item--title",
  selectable: false,
  content,
  id
});

Dropdown.defaultProps = {
  hiddenLabel: false
};

Dropdown.propTypes = {
  disableHorizontalPosition: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  items: PropTypes.arrayOf(DropdownItemPropTypes).isRequired,
  toggleButton: PropTypes.node.isRequired,
  className: PropTypes.string,
  onDropdownToggle: PropTypes.func,
  menuAriaLabel: PropTypes.string,
  hiddenLabel: PropTypes.bool
};
