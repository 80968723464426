import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import {
  addUrlsToList,
  addUrlToList,
  cancelCheckUrl,
  checkUrl,
  checkUrlChange,
  clearCheckUrl,
  clearCsvFile,
  fileParser
} from "../../urlFilters.actions";
import { NotMobile } from "../../../../hocs/Responsive";
import UploadCsvBlock from "../uploadCsvBlock/UploadCsvBlock";
import CheckUrlBlock from "../checkUrlBlock/CheckUrlBlock";

class AddUrlSection extends Component {
  state = {
    showUploadCsv: false,
    showActionButtons: false
  };

  render() {
    const { showUploadCsv } = this.state;
    const {
      profileId,
      allowedTypes,
      checkUrlState,
      checkUrlChange,
      checkUrl,
      clearCheckUrl,
      cancelCheckUrl,
      addUrlToList,
      uploadUrlsState,
      fileParser,
      clearCsvFile,
      addUrlsToList
    } = this.props;

    return (
      <Fragment>
        <NotMobile>
          {showUploadCsv && (
            <UploadCsvBlock
              toggleAddMethod={this.toggleAddMethod}
              uploadUrlsState={uploadUrlsState}
              fileParser={fileParser}
              clearUploadState={clearCsvFile}
              addUrlsToList={addUrlsToList}
              profileId={profileId}
            />
          )}
        </NotMobile>
        {!showUploadCsv && (
          <CheckUrlBlock
            allowedTypes={allowedTypes}
            toggleAddMethod={this.toggleAddMethod}
            checkUrlState={checkUrlState}
            checkUrlChange={checkUrlChange}
            checkUrl={checkUrl}
            clearCheckUrl={clearCheckUrl}
            cancelCheckUrl={cancelCheckUrl}
            addUrlToList={addUrlToList}
            profileId={profileId}
          />
        )}
      </Fragment>
    );
  }

  toggleAddMethod = () => {
    this.setState({ showUploadCsv: !this.state.showUploadCsv });
  };
}

export default connect(
  ({
    urlFilters,
    config: {
      urlFilters: { "url-types": urlTypes }
    }
  }) => ({
    allowedTypes: urlTypes,
    checkUrlState: urlFilters.checkUrlState,
    uploadUrlsState: urlFilters.uploadUrlsState
  }),
  {
    checkUrlChange,
    checkUrl,
    clearCheckUrl,
    cancelCheckUrl,
    addUrlToList,
    fileParser,
    clearCsvFile,
    addUrlsToList
  }
)(AddUrlSection);
