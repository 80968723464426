import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export const ConfigLink = ({ to: { isExternal, url }, ...props }) => {
  const externalProps = !props.target && {
    target: "_blank",
    rel: "noopener noreferrer"
  };

  return isExternal ? (
    <a href={url} {...props} {...externalProps} /> // eslint-disable-line jsx-a11y/anchor-has-content
  ) : (
    <Link to={url} {...props} />
  );
};

ConfigLink.propTypes = {
  to: PropTypes.shape({
    isExternal: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired
  })
};
