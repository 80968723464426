import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import _ from "lodash";
import classnames from "classnames";

import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import { Button } from "../../../../../components/button/Button";
import onOutsideClick from "../../../../../utils/outsideClick";
import { Icon } from "../../../../../components/icon/Icon";
import { ScheduleEditForm } from "../scheduleRulesAccordion/scheduleEditForm";
import { getAvailableName } from "../schedules.selectors";
import ScheduleRule from "../scheduleRule/ScheduleRule";

import "./RulePopup.scss";

class RulePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDays: props.selectedDays || [],
      timeStart: props.timeStart || "01:00",
      timeEnd: props.timeEnd || "01:01"
    };

    this.popupRef = null;
  }

  componentDidMount() {
    onOutsideClick(this, () => this.props.onTogglePopup(), this.popupRef);
  }

  render() {
    const { isEdit, style } = this.props;
    const classes = classnames("rule-popup", isEdit ? "edit" : "create");

    return (
      <div
        className={classes}
        style={style}
        ref={node => (this.popupRef = node)}
      >
        {this.renderHeader()}
        {this.renderEditForm()}
        {this.renderPreview()}
        {this.renderActions()}
      </div>
    );
  }

  renderHeader() {
    const { isEdit, onTogglePopup, intl } = this.props;
    const headerTextKey = isEdit ? "edit_rule" : "create_new_rule";

    return (
      <div className={"rule-popup__header"}>
        <span className={"rule-popup__header__title"}>
          <FormattedMessage id={headerTextKey} />
        </span>
        <UnstyledButton
          className="rule-popup__header__icon"
          onClick={() => onTogglePopup()}
          aria-label={intl.formatMessage({ id: "aria_close" })}
        >
          <Icon icon="far fa-times" />
        </UnstyledButton>
      </div>
    );
  }

  renderEditForm() {
    const { timeFormat24h, firstDayOfWeek } = this.props;
    const { timeStart, timeEnd, selectedDays } = this.state;

    return (
      <ScheduleEditForm
        days={this.props.days}
        firstDayOfWeek={firstDayOfWeek}
        timeFormat24h={timeFormat24h}
        schedule={{ timeStart, timeEnd, selectedDays }}
        onDaysChange={days => this.onDaysChange(days)}
        onTimePickerChange={(time, prop) => this.onTimePickerChange(time, prop)}
      />
    );
  }

  renderPreview() {
    const {
      intl,
      timeFormat24h,
      useShortNotations,
      firstDayOfWeek
    } = this.props;
    const { timeStart, timeEnd, selectedDays } = this.state;

    return (
      <div className={"rule-popup__preview"}>
        <ScheduleRule
          intl={intl}
          timeFormat24h={timeFormat24h}
          timeStart={timeStart}
          timeEnd={timeEnd}
          selectedDays={selectedDays}
          useShortNotations={useShortNotations}
          firstDayOfWeek={firstDayOfWeek}
        />
      </div>
    );
  }

  renderActions() {
    const { isEdit, id, onTogglePopup, allowDelete, onRuleRemove } = this.props;
    const submitTextKey = isEdit ? "save_changes" : "create_rule";

    return (
      <div className={"rule-popup__actions"}>
        <Button isOutline onClick={() => onTogglePopup()}>
          <FormattedMessage id={"cancel"} />
        </Button>
        <Button
          disabled={this.isSubmitDisabled()}
          onClick={() => this.handleSubmit()}
        >
          <FormattedMessage id={submitTextKey} />
        </Button>
        {isEdit && (
          <div className={"rule-popup__actions--right"}>
            <Button
              color={"destructive"}
              isOutline
              onClick={() => onRuleRemove({ id })}
              disabled={!allowDelete}
            >
              <Icon icon="far fa-trash color-danger" />
              <span>
                <FormattedMessage id={"delete_rule"} />
              </span>
            </Button>
          </div>
        )}
      </div>
    );
  }

  onDaysChange(days) {
    this.setState({ selectedDays: days });
  }

  onTimePickerChange(time, prop) {
    this.setState({ [prop]: time });
  }

  isSubmitDisabled() {
    return _.isEmpty(this.state.selectedDays);
  }

  handleSubmit() {
    const {
      isEdit,
      type,
      profile,
      schedules,
      addSchedule,
      onRuleChange,
      onTogglePopup
    } = this.props;

    const scheduleToSave = {
      type,
      profile,
      enabled: true,
      name: getAvailableName(type, _.map(schedules, "name")),
      day: this.state.selectedDays,
      timeStart: this.state.timeStart,
      timeEnd: this.state.timeEnd,
      categories: (schedules[0] && schedules[0].categories) || []
    };
    const changesToSave = {
      timeStart: this.state.timeStart,
      timeEnd: this.state.timeEnd,
      day: this.state.selectedDays
    };

    isEdit ? onRuleChange(changesToSave) : addSchedule(scheduleToSave);
    onTogglePopup();
  }
}

RulePopup.propTypes = {
  isEdit: PropTypes.bool
};

export default injectIntl(RulePopup);
