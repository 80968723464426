export enum DrilldownRoutePage {
  Webfilter = "webfilter",
  Malware = "malware",
  Botnet = "botnet"
}

export interface ChartConfig {
  tableDataEndpoint: string;
  chartDataEndpoint: string;
}
