import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { DropdownItemPropTypes } from "@sportal/cdk/dropdown/DropdownItem";
import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import { ModalDialog, ModalService, useMobileScreenCheck } from "../modal";
import { Icon } from "../icon/Icon";
import { useIntlTranslate } from "../../hooks";

import "./DeviceActionsDialog.scss";

/**
 * deviceName and ariaDeviceName could have different values
 * in some cases deviceName is a device address and we don't want that in aria-label
 */
export const DeviceActionsToggle = ({
  deviceName,
  menuItems,
  ariaDeviceName
}) => {
  const translate = useIntlTranslate();

  const ariaLabel = ariaDeviceName
    ? translate("aria_named_device_actions", {
        deviceName: ariaDeviceName
      })
    : translate("aria_device_actions");

  return (
    <UnstyledButton
      className="device-menu__toggle-button"
      onClick={() =>
        openModal(deviceName, menuItems)
          .then(() => null)
          .catch(() => null)
      }
      aria-label={ariaLabel}
    >
      <Icon icon="fas fa-ellipsis-v" />
    </UnstyledButton>
  );
};

const openModal = (deviceName, menuItems) =>
  ModalService.show(modal => ({
    dialog: (
      <DeviceActionsDialog
        deviceName={deviceName}
        menuItems={menuItems}
        modal={modal}
      />
    )
  }));

const DeviceActionsDialog = ({ deviceName, menuItems, modal }) => {
  const collapse = () => modal.reject();

  useMobileScreenCheck(collapse);

  return (
    <ModalDialog className="actions-modal device-actions-modal">
      <ModalDialog.Header>
        <h3 className={"header-text"}>{deviceName}</h3>
      </ModalDialog.Header>
      <ModalDialog.Body>
        <div className="items__container">
          {_.map(menuItems, item => (
            <div
              key={item.id}
              onClick={item.action}
              className={classnames({ "item--disabled": item.disabled })}
            >
              {item.content}
            </div>
          ))}
        </div>
      </ModalDialog.Body>
      <ModalDialog.Footer>
        <button
          type="button"
          className="unmodified__btn dismiss-button"
          onClick={collapse}
        >
          <FormattedMessage id="dismiss" />
        </button>
      </ModalDialog.Footer>
    </ModalDialog>
  );
};

DeviceActionsDialog.propTypes = {
  menuItems: PropTypes.arrayOf(DropdownItemPropTypes).isRequired,
  deviceName: PropTypes.string
};
