import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import { injectIntl } from "react-intl";

import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import { CharacterLimitInfo } from "./CharacterLimitInfo";
import { DescriptiveError } from "./DescriptiveError";
import { Icon } from "../icon/Icon";

import "./Input.scss";

class Input extends Component {
  componentDidMount() {
    this.props.focused && this.inputField.focus();
  }

  render() {
    const {
      id,
      className,
      value,
      onChange,
      placeholder,
      label,
      errorMessage,
      icon,
      onClear,
      showLimit,
      descriptiveError,
      validate,
      autoComplete,
      focused,
      limit,
      intl,
      ...attrs
    } = this.props;

    const maxCharId = `max-char-description-${id}`;
    if (descriptiveError) {
      attrs["aria-describedby"] = maxCharId;
    }

    const hostClassName = classnames(
      "input",
      {
        "has-error": validate ? validate() : !!errorMessage
      },
      className
    );

    return (
      <div className={hostClassName}>
        {this.renderLabel()}
        <div className={"input__container"}>
          {this.renderInputIcon()}
          <input
            id={id}
            type="text"
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            ref={ref => (this.inputField = ref)}
            autoComplete={autoComplete || "off"}
            maxLength={limit}
            {...attrs}
          />
          {this.renderInputClear()}
        </div>
        {this.renderMessages(maxCharId)}
      </div>
    );
  }

  renderLabel() {
    const { label, id } = this.props;

    return (
      label && (
        <label className="input__label" htmlFor={id}>
          {label}
        </label>
      )
    );
  }

  renderInputIcon() {
    const { icon } = this.props;

    return icon && <Icon icon={classnames("input__icon", icon)} />;
  }

  renderInputClear() {
    const { onClear, value, intl } = this.props;

    return (
      onClear &&
      !_.isEmpty(value) && (
        <UnstyledButton
          className="input__clear"
          onClick={onClear}
          aria-label={intl.formatMessage({ id: "aria_clear_input_data" })}
          data-testid="input-clear-button"
        >
          <Icon icon="far fa-times" />
        </UnstyledButton>
      )
    );
  }

  renderMessages(maxCharId) {
    const { errorMessage, descriptiveError, showLimit } = this.props;
    const isRender = !_.isEmpty(errorMessage) || descriptiveError || showLimit;

    return (
      isRender && (
        <div className={"input__messages"}>
          <div className={"input__messages__info"}>
            {descriptiveError && this.showDescriptiveError(maxCharId)}
            {showLimit && this.showLimit()}
          </div>
          {!_.isEmpty(errorMessage) && this.showError()}
        </div>
      )
    );
  }

  showError() {
    const { errorMessage } = this.props;

    return <div className="input__messages__error">{errorMessage}</div>;
  }

  showDescriptiveError(maxCharId) {
    const { descriptiveError, validate } = this.props;

    return (
      <DescriptiveError
        id={maxCharId}
        message={descriptiveError}
        validate={validate}
      />
    );
  }

  showLimit() {
    const { limit, value } = this.props;

    return <CharacterLimitInfo limit={limit} value={value} />;
  }
}

Input.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool
  ]),
  onClear: PropTypes.func,
  focused: PropTypes.bool,
  autoComplete: PropTypes.string,
  limit: PropTypes.number,
  //if no error – this is a hint under the input, in case of error - this hint is red
  descriptiveError: PropTypes.node,
  validate: PropTypes.func,
  showLimit: PropTypes.bool
};

export default injectIntl(Input);
