import _ from "lodash";

import { HttpService, HttpServiceResponse } from "../shared";
import {
  Attribute,
  AttributesData,
  AttributesMap,
  AttributesServerData,
  RawAttribute
} from "./attributes.types";

export class AttributesProvider {
  constructor(
    private http: HttpService,
    private baseUrl: string,
    private rawAttributesMap: AttributesMap
  ) {}

  public get(
    subscriberId: string
  ): Promise<HttpServiceResponse<AttributesData>> {
    return this.http
      .get<AttributesServerData>(this.url(subscriberId))
      .then<HttpServiceResponse<AttributesData>>(response => ({
        ...response,
        data: this.mapAttributes(response.data.content)
      }));
  }

  public update(
    subscriberId: string,
    attributeName: string,
    value: string | number | boolean
  ): Promise<HttpServiceResponse<AttributesData>> {
    const name = this.rawAttributesMap[attributeName];
    return this.http.put(`${this.url(subscriberId)}/${name}`, {
      name,
      value
    });
  }

  private mapAttributes(rawAttributes: RawAttribute[]): AttributesData {
    return _.mapValues(
      this.rawAttributesMap,
      (rawAttributeName, targetName) => {
        const rawAttribute = _.find(rawAttributes, {
          name: rawAttributeName as any
        });

        // Fix for stringified boolean value from BE
        if (rawAttribute && targetName === Attribute.TIME_FORMAT) {
          return JSON.parse(rawAttribute.value);
        }

        return rawAttribute && rawAttribute.value;
      }
    ) as AttributesData;
  }

  private url(subscriberId: string): string {
    return `${this.baseUrl}/ssm/account/${subscriberId}/attribute`;
  }
}
