import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { ScheduleTime } from "../scheduleTime/ScheduleTime";
import { ScheduleDays } from "../scheduleDays/ScheduleDays";
import { getDaysShifted } from "../schedules.selectors";
import _ from "lodash";
import { Mobile, NotMobile } from "../../../../../hocs/Responsive";

export class ScheduleEditForm extends Component {
  onDaysChange(checked, name) {
    let days = this.props.schedule.selectedDays;

    if (checked) {
      days = _.concat(days, name);
    } else {
      days = _.without(days, name);
    }

    this.props.onDaysChange(days);
  }

  render() {
    const daysProps = {
      days: getDaysShifted(this.props.firstDayOfWeek),
      selectedDays: this.props.schedule.selectedDays,
      onDaysChange: ({ target }) => {
        const { checked, name } = target;

        this.onDaysChange(checked, name);
      }
    };
    const timeProps = {
      timeFormat24h: this.props.timeFormat24h,
      timeStart: this.props.schedule.timeStart,
      timeEnd: this.props.schedule.timeEnd,
      onTimePickerChange: (time, prop) =>
        this.props.onTimePickerChange(time, prop)
    };

    return (
      <div className={"schedule__edit-form"}>
        <NotMobile>
          <ScheduleDays {...daysProps} />
          <ScheduleTime {...timeProps} />
        </NotMobile>
        <Mobile>
          <ScheduleTime {...timeProps} />
          <div className={"text-label"}>
            <FormattedMessage id={"schedule_days_on"} />:
          </div>
          <ScheduleDays {...daysProps} showFullNames />
        </Mobile>
      </div>
    );
  }
}
