import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  loadWebContent,
  changeWebContent,
  resetToSave,
  getWebFilteringPreview,
  changeShowMessageToSave,
  changeMessageToSave,
  changeAdminEmailToSave,
  changeAdminPhoneToSave,
  changeShowCodeToSave,
  changeShowNameToSave
} from "./webContent.actions";
import { TabControls } from "../../components/tabControls/TabControls";
import { withPromptDialog } from "../../../../hocs/withPrompt";
import { BlockingMessage } from "../../components/blockingMessage/BlockingMessage";
import { DeviceRegistration } from "./DeviceRegistration";
import { AdminInfo } from "../../components/adminInfo/AdminInfo";
import { useMount } from "@sportal/cdk/hooks";
import {
  getFromWebContentValidation,
  isWebContentFetched
} from "./webContent.selectors";
import { useWebFilteringForm } from "./useWebFilteringForm";
import { isMultipleUserLevel } from "../../../../store/account";
import { previewPage } from "../shared.actions";

const WebFilteringFormContent = withPromptDialog(
  ({ hasChanges, save, discard }) => {
    const dispatch = useDispatch();

    const isFetched = useSelector(isWebContentFetched);
    const isMultiple = useSelector(isMultipleUserLevel);
    const rules = useSelector(getFromWebContentValidation("rules"), _.isEqual);

    const { isValid, formValues, isEmailPatternValid } = useWebFilteringForm();

    useMount(() => {
      !isFetched && dispatch(loadWebContent());
    });

    const preview = async () =>
      await previewPage(dispatch, getWebFilteringPreview(), "web-filtering");

    return (
      <div className="web-filtering-form">
        <h2 className={"blocked-page__title"}>
          <FormattedMessage id={"web_filtering"} />
        </h2>
        <TabControls
          isFormValid={isValid}
          isFormUnchanged={!hasChanges}
          onReset={discard}
          onSave={save}
          onPreview={preview}
        />
        <BlockingMessage
          showMessage={formValues.showMessage}
          toggleMessage={() => dispatch(changeShowMessageToSave())}
          changeMessage={newMessage =>
            dispatch(changeMessageToSave(newMessage))
          }
          message={formValues.message}
          messageValidation={rules.message}
        />
        <AdminInfo
          email={{
            value: formValues.adminEmail,
            action: adminEmail => dispatch(changeAdminEmailToSave(adminEmail)),
            validation: rules.adminEmail,
            isEmailPatternValid
          }}
          phone={{
            value: formValues.adminPhone,
            action: adminPhone => dispatch(changeAdminPhoneToSave(adminPhone)),
            validation: rules.adminPhone
          }}
        />
        {isMultiple && (
          <DeviceRegistration
            showCode={formValues.showCode}
            showName={formValues.showName}
            changeShowCode={() => dispatch(changeShowCodeToSave())}
            changeShowName={() => dispatch(changeShowNameToSave())}
          />
        )}
      </div>
    );
  }
);

WebFilteringFormContent.propTypes = {
  hasChanges: PropTypes.bool,
  save: PropTypes.func,
  discard: PropTypes.func
};

export const WebFilteringForm = () => {
  const dispatch = useDispatch();
  const { hasChanges } = useWebFilteringForm();

  const save = () => dispatch(changeWebContent());
  const discard = () => dispatch(resetToSave());

  return (
    <WebFilteringFormContent
      save={save}
      discard={discard}
      hasChanges={hasChanges}
    />
  );
};
