import { getResources } from "../../../../store/root.selectors";
import { getPreviewParams } from "../brandingForm/branding.selectors";

const getWebContent = state => getResources(state).webContent;

export const getWebContentResource = state => getWebContent(state).resource;
export const getWebContentToSave = state => getWebContent(state).toSave;
export const isWebContentFetched = state => getWebContent(state).isFetched;
export const getWebContentRequiredFields = state =>
  getWebContent(state).requiredFields;

const getWebContentValidation = state => getWebContent(state).validation;

export const getFromWebContentValidation = key => state =>
  getWebContentValidation(state)[key];

export const getWebContentPreviewParams = state => {
  const originalResource = getWebContentResource(state);

  return getPreviewParams(originalResource)(state);
};
