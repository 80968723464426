import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-scroll";
import classnames from "classnames";

import "./HelpPageNavigation.scss";

import { isMobile } from "../../../helpers/navigation.helper";

export class HelpPageNavigation extends Component {
  get scrollConfig() {
    // TODO: would be nice to make offset the same and rid off the condition
    return {
      smooth: true,
      duration: 300,
      offset: isMobile() ? -140 : -80
    };
  }

  state = {
    activeSection: ""
  };

  render() {
    const { sections, className } = this.props;
    const tabs = getTabs(sections);

    return (
      <nav className={classnames("help-page-navigation", className)}>
        <ul className="help-page-navigation__list">
          {_.map(tabs, tab => this.renderTab(tab))}
        </ul>
      </nav>
    );
  }

  renderTab({ id, text, children }) {
    const { activeSection } = this.state;
    return (
      <li key={id} className="help-page-navigation__list-item">
        <Link
          to={id}
          spy={true}
          {...this.scrollConfig}
          activeClass="help-page-navigation__link--active"
          className="help-page-navigation__link"
          onSetActive={activeSection => this.changeActiveSection(activeSection)}
        >
          {text}
        </Link>
        {!_.isEmpty(children) &&
          this.renderNestedList(children, id === activeSection)}
      </li>
    );
  }

  renderNestedList(tabs, isActive) {
    const { activeSection } = this.state;
    const hasActiveChildren = _.some(tabs, { id: activeSection });
    return (
      <ul
        className="help-page-navigation__list--nested"
        hidden={!isActive && !hasActiveChildren}
      >
        {_.map(tabs, tab => this.renderTab(tab))}
      </ul>
    );
  }

  changeActiveSection(activeSection) {
    this.setState({ activeSection });
    const { onActiveSectionChange } = this.props;
    onActiveSectionChange && onActiveSectionChange();
  }
}

const sectionToTab = section => ({
  id: section.id,
  text: section.header.text,
  isHidden: section.isHidden
});

const getTabs = sections =>
  _.transform(
    sections,
    (tabs, section, index) => {
      if (section.level > 1) {
        return;
      }
      const children = _.takeWhile(
        sections.slice(index + 1),
        ({ level }) => level > 1
      );
      if (!section.isHidden || _.some(children, ({ isHidden }) => !isHidden)) {
        tabs.push({
          ...sectionToTab(section),
          children: _.reject(children, { isHidden: true }).map(sectionToTab)
        });
      }
    },
    []
  );
