import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { SectionHeader } from "../../../components/sectionHeader/SectionHeader";
import { LogoUpload } from "../../../components/logoUpload/LogoUpload";

export const Logo = ({ logo, onChange }) => (
  <section className="branding__logo col-md-5">
    <SectionHeader title={<FormattedMessage id={"add_logo"} />} />
    <LogoUpload logo={logo} onUploadSuccess={onChange} />
  </section>
);

Logo.propTypes = {
  logo: PropTypes.string,
  onChange: PropTypes.func.isRequired
};
