import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import { useTooltipContent } from "./useTooltipContent";
import { useBarChartContext } from "../../BarChartProvider";
import {
  Granularity,
  ReportTypes
} from "../../../../../helpers/reports.helper.types";
import { TimeRange } from "../../../../time/timeRange/TimeRange";
import { punyToUnicode } from "../../../../../utils/punyToUnicode";

import "./BarChartTooltip.scss";

export const BarChartTooltip = ({ container, showTopData = false }) => {
  const { period } = useBarChartContext();
  const tooltip = useTooltipContent(container);

  if (!tooltip) return null;

  const {
    activeSlice: time,
    activePayload: data,
    activeCoordinate: { x }
  } = tooltip;

  return (
    <div className="tooltip-reports" style={{ left: Math.ceil(x) }}>
      <TimeRange
        period={period}
        start={time}
        end={time + Granularity[period]}
      />
      {showTopData && (
        <div className={"report-tooltip__top"}>
          {renderTopActivity(data)}
          {renderTopBlocks(data)}
        </div>
      )}
    </div>
  );
};

const renderTopActivity = data =>
  renderTop(data[ReportTypes.ActivityTop], "top_visited");
const renderTopBlocks = data =>
  renderTop(data[ReportTypes.WebfilterTop], "top_blocked");

const renderTop = (data, translationKey) => {
  const top = _.fromPairs(data);

  if (_.isEmpty(top)) return null;

  return (
    <div className={"list"}>
      <div className={"list__header"}>
        <FormattedMessage id={translationKey} />
      </div>
      <div className={"list__content"}>
        {_.map(top, (count, node) => (
          <div key={node} className={"list__item"}>
            {punyToUnicode(node)}
          </div>
        ))}
      </div>
    </div>
  );
};
