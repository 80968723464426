import _ from "lodash";
import React, { useMemo } from "react";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

import "./ProfilePicker.scss";
import NativeSelect from "../select/NativeSelect";

export const ProfilePicker = ({
  tabs,
  onTabClick,
  className,
  activeTab: selectedProfileId,
  counter,
  sideSection
}) => {
  const intl = useIntl();
  const mappedTabs = useMemo(() => {
    return tabs.map(tab => ({
      ...tab,
      value: tab.id,
      name:
        tab.type === "custom" ? tab.name : intl.formatMessage({ id: tab.type })
    }));
  }, [tabs, intl]);
  const selectedTab = _.find(tabs, ({ id }) => id === selectedProfileId);

  return (
    <div className={classnames("profile-picker", className)}>
      <div className="profile-picker__header">
        <div className="profile-picker__header__controls">
          <div className={"profile-picker__header__label"}>
            {counter ? (
              counter
            ) : (
              <div className={"counter counter__label"}>
                <FormattedMessage id={"profiles"} />
              </div>
            )}

            <NativeSelect
              className={"profile-picker__header__controls__select"}
              options={mappedTabs}
              selected={selectedProfileId}
              onChange={onTabClick}
              toggleView="input"
              ariaLabelMessage="profiles"
            />
          </div>
          {sideSection && (
            <div className="profile-picker__header__controls__side-section">
              {sideSection}
            </div>
          )}
        </div>
      </div>

      {selectedTab && (
        <div className="profile-picker__content">{selectedTab.content}</div>
      )}
    </div>
  );
};
