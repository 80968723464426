import _ from "lodash";
import { withLoadingState } from "../shared";
import {
  EDIT_DEVICE_SUCCESS,
  EDIT_ROAMING_DEVICE_SUCCESS,
  FETCH_ALL_DEVICES,
  FETCH_ALL_DEVICES_FAILURE,
  FETCH_ALL_DEVICES_SUCCESS,
  LogicalDevicesActionTypes,
  MERGE_DEVICE_SUCCESS,
  REMOVE_DEVICE_SUCCESS,
  REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS,
  SAVE_DEVICE_SUCCESS,
  SBDeviceDetails,
  SBLogicalDevice
} from "./devices.types";
import {
  REMOVE_PROFILE_SUCCESS,
  RENAME_PROFILE_SUCCESS
} from "../profiles/profiles.types";

export interface LogicalDevicesReducerState {
  list: SBLogicalDevice[];
  detailsByIdentifier: Record<string, SBDeviceDetails>;
}

const reducer = (
  state: LogicalDevicesReducerState,
  action: LogicalDevicesActionTypes
): LogicalDevicesReducerState => {
  switch (action.type) {
    case FETCH_ALL_DEVICES_SUCCESS: {
      const { plainDevices, identifiers } = action.payload.all;

      return {
        ...state,
        list: plainDevices,
        detailsByIdentifier: identifiers
      };
    }
    case SAVE_DEVICE_SUCCESS: {
      const { plainDevices, identifiers } = action.payload;

      return {
        ...state,
        list: [...state.list, ...plainDevices],
        detailsByIdentifier: {
          ...state.detailsByIdentifier,
          ...identifiers
        }
      };
    }
    case REMOVE_DEVICE_SUCCESS: {
      const device = action.payload.device;
      const id = device.logicalDeviceId;
      const details = _.omitBy(
        state.detailsByIdentifier,
        ({ logicalDeviceId }) => logicalDeviceId === id
      );

      return {
        ...state,
        list: _.without(state.list, device),
        detailsByIdentifier: details
      };
    }
    case EDIT_DEVICE_SUCCESS: {
      const device = _.find(state.list, { name: action.payload.oldName });
      const updatedDevice = _.assign(device, action.payload.device);

      return {
        ...state,
        list: [..._.without(state.list, device), updatedDevice]
      };
    }
    case MERGE_DEVICE_SUCCESS: {
      const updated = action.payload.updatedDevices.plainDevices[0];
      const identifiers = action.payload.updatedDevices.identifiers;
      const removed = action.payload.removedDevice;

      const saved = _.find(state.list, {
        name: updated.name
      });

      return {
        ...state,
        list: [..._.without(state.list, saved, removed), updated],
        detailsByIdentifier: {
          ...state.detailsByIdentifier,
          ...identifiers
        }
      };
    }
    case RENAME_PROFILE_SUCCESS:
    case REMOVE_PROFILE_SUCCESS: {
      const { currentName, newName } = action.payload;

      return {
        ...state,
        list: _.map(state.list, device => {
          if (device.profile !== currentName) return device;

          return {
            ...device,
            profile: newName
          };
        })
      };
    }
    case EDIT_ROAMING_DEVICE_SUCCESS: {
      const { identifier } = action.payload;

      const changes: { name?: string; username?: string } = {};

      if (_.has(action.payload.changes, "name")) {
        changes.name = action.payload.changes.name;
      }

      if (_.has(action.payload.changes, "username")) {
        changes.username = action.payload.changes.username;
      }

      const roamingDevice = state.detailsByIdentifier[identifier];

      const updatedRoamingDevice = _.assign(roamingDevice, changes);

      const nextState = {
        ...state,
        detailsByIdentifier: {
          ...state.detailsByIdentifier,
          [identifier]: updatedRoamingDevice
        }
      };

      if (_.has(changes, "name")) {
        const logicalDevice = _.find(state.list, [
          "logicalDeviceId",
          roamingDevice.logicalDeviceId
        ]);
        const updatedLogicalDevice = _.assign(logicalDevice, {
          name: changes.name
        });

        nextState.list = [
          ..._.without(state.list, logicalDevice),
          updatedLogicalDevice
        ];
      }

      return nextState;
    }
    case REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS: {
      const { identifier: revokedDeviceIdentifier } = action.payload;

      const details = state.detailsByIdentifier[revokedDeviceIdentifier];

      const device = _.find(state.list, [
        "logicalDeviceId",
        details.logicalDeviceId
      ]);

      return {
        ...state,
        list: _.without(state.list, device),
        detailsByIdentifier: _.omit(
          state.detailsByIdentifier,
          revokedDeviceIdentifier
        )
      };
    }
    default:
      return state;
  }
};

export const logicalDevicesReducer = withLoadingState({
  loadActionType: FETCH_ALL_DEVICES,
  successActionType: FETCH_ALL_DEVICES_SUCCESS,
  failureActionType: FETCH_ALL_DEVICES_FAILURE
})(reducer);
