import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import _ from "lodash";

import { useMount } from "@sportal/cdk/hooks";
import { withLayout } from "../../components/layout/Layout";
import { BrandingForm } from "./tabs/brandingForm/BrandingForm";
import { WebFilteringForm } from "./tabs/webFilteringForm/WebFilteringForm";
import { MalwareForm } from "./tabs/malwareForm/MalwareForm";
import { loadResources } from "./resources.actions";
import { withPageName } from "../../components/layout/horizontalMenu/PageName";

import "./BlockPageContentPage.scss";

export const BlockPageContentPage = ({ match: { url } }) => {
  const dispatch = useDispatch();
  useMount(() => {
    dispatch(loadResources());
  });

  return (
    <div className="blocked-page col-lg-10 col-md-12">
      <Switch>
        <Route exact path={`${url}/branding`} component={BrandingForm} />
        <Route exact path={`${url}/webfilter`} component={WebFilteringForm} />
        <Route exact path={`${url}/malware`} component={MalwareForm} />
        <Redirect from={url} to={`${url}/branding`} />
      </Switch>
    </div>
  );
};

export default _.flowRight(
  withRouter,
  withLayout(),
  withPageName()
)(BlockPageContentPage);
