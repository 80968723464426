import _ from "lodash";
import React, { Component } from "react";
import Mark from "../../../node_modules/mark.js/dist/mark";

export class HelpSection extends Component {
  componentDidMount() {
    this.markInstance = new Mark(this.sectionRef);
    const images = this.sectionRef.querySelectorAll("img");
    this.imagesCount = images.length;
    if (this.imagesCount === 0) {
      this.emitLoad();
    } else {
      this.imagesLoaded = 0;
      images.forEach(image => {
        image.addEventListener("load", this.handleImageLoad);
        image.addEventListener("error", this.handleImageLoad);
      });
    }
  }

  handleImageLoad = () => {
    this.imagesLoaded++;
    if (this.imagesLoaded === this.imagesCount) {
      this.emitLoad();
    }
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      this.props.searchString !== nextProps.searchString || !!nextProps.after
    );
  }

  componentDidUpdate(prevProps) {
    const { id, searchString, onHighlightDone } = this.props;
    const shouldMark = !_.isEmpty(searchString);

    this.markInstance.unmark({
      done: () => {
        if (shouldMark) {
          this.markInstance.mark(searchString, {
            accuracy: "complementary",
            separateWordSearch: false,
            done: amount => onHighlightDone(id, amount)
          });
        } else {
          onHighlightDone(id, 0);
        }
      }
    });
  }

  componentWillUnmount() {
    const images = this.sectionRef.querySelectorAll("img");
    images.forEach(image => {
      image.removeEventListener("load", this.handleImageLoad);
      image.removeEventListener("error", this.handleImageLoad);
    });
  }

  render() {
    const { id, header, content, after } = this.props;

    return (
      <section
        className="help-section"
        id={id}
        ref={element => {
          this.sectionRef = element;
        }}
      >
        <header dangerouslySetInnerHTML={{ __html: header.html }} />
        <div dangerouslySetInnerHTML={{ __html: content.html }} />
        {after}
      </section>
    );
  }

  emitLoad() {
    this.props.onLoad(this.props.id);
  }
}
