import { toast } from "react-toastify";

export const NOTIFICATION_TYPES = {
  ...toast.TYPE,
  WEB_FILTER_OFF: "web-filter-off"
};
export const PRIORITIES = {
  high: 1
};

export const ADD_NOTIFICATION = "[NOTIFICATION] ADD_NOTIFICATION";
export const DISMISS_NOTIFICATION = "[NOTIFICATION] DISMISS_NOTIFICATION";
export const DISMISS_STATIC_NOTIFICATION =
  "[NOTIFICATION] DISMISS_STATIC_NOTIFICATION";
export const REMOVE_ALL_STANDARD_NOTIFICATIONS =
  "[NOTIFICATION] REMOVE_ALL_STANDARD_NOTIFICATIONS";

export const makeNotificationAction = (content, options, type) => ({
  type: ADD_NOTIFICATION,
  payload: { content, type, ...options }
});

export const success = (content, options = {}) =>
  makeNotificationAction(content, options, NOTIFICATION_TYPES.SUCCESS);
export const info = (content, options = {}) =>
  makeNotificationAction(content, options, NOTIFICATION_TYPES.INFO);
export const warning = (content, options = {}) =>
  makeNotificationAction(content, options, NOTIFICATION_TYPES.WARNING);
export const error = (content, options = {}) =>
  makeNotificationAction(content, options, NOTIFICATION_TYPES.ERROR);
export const webFilterOff = content =>
  makeNotificationAction(content, {}, NOTIFICATION_TYPES.WEB_FILTER_OFF);

export const remove = notificationId => ({
  type: DISMISS_NOTIFICATION,
  payload: notificationId
});

export const removeAllStandard = () => async (dispatch, getState) => {
  const {
    notifications: { list }
  } = getState();

  if (!list.length) {
    return;
  }

  const notDismissed = list.filter(notification => !notification.dismissed);

  if (!notDismissed.length) {
    return;
  }

  dispatch({
    type: REMOVE_ALL_STANDARD_NOTIFICATIONS
  });
};

export default {
  success,
  info,
  warning,
  error,
  webFilterOff,
  remove,
  removeAllStandard
};
