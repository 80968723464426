import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Icon } from "../../../../components/icon/Icon";

import "./SectionHeader.scss";

export const SectionHeader = ({ description, icon, className, children }) => (
  <div className={classnames("section-header", className)}>
    <div className={"section-header__title"}>
      {icon ? <Icon icon={classnames("section-header__icon", icon)} /> : null}
      {children}
    </div>
    <p>{description}</p>
  </div>
);

SectionHeader.propTypes = {
  description: PropTypes.node,
  iconClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

SectionHeader.defaultProps = {
  description: "",
  iconClassName: "",
  className: "",
  children: []
};
