import { ThreatsProvider } from "./threats.provider";
import { Result, success, failure } from "../shared";
import { ThreatsData } from "./threats.types";

export enum ThreatsServiceErrorCode {
  GENERIC
}

export class ThreatsService {
  constructor(private provider: ThreatsProvider) {}

  public async get(locale: string): Promise<Result<ThreatsData>> {
    try {
      const { data } = await this.provider.get(locale);
      return success(data.content);
    } catch (error) {
      return failure(ThreatsServiceErrorCode.GENERIC);
    }
  }
}
