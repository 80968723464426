import React, { memo, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { HorizontalMenuLink } from "./HorizontalMenuLink";
import { SubMenu } from "./SubMenu";

function isAnySubMenuActive(subMenu, pathname) {
  return !!_.chain(subMenu)
    .map(({ baseUrl, url }) => baseUrl || url)
    .find(url => pathname.includes(url))
    .value();
}

const MenuItemWithSubMenu = ({
  item,
  currentPathname,
  isOpen,
  changeOpenedSubMenuDropdown
}) => {
  const { id, text, url, subMenu } = item;

  const hasActiveSubMenu = isAnySubMenuActive(subMenu, currentPathname);

  const linkItemRef = useRef();

  const isNavLinkActive = useCallback(
    (_match, { pathname }) => pathname === url || hasActiveSubMenu,
    [url, hasActiveSubMenu]
  );

  const handleMouseOver = () => {
    changeOpenedSubMenuDropdown(id, true);
  };

  const handleMouseLeave = () => {
    changeOpenedSubMenuDropdown(id, false);
  };

  return (
    <li
      role="none"
      ref={linkItemRef}
      className={classnames("hm__item", {
        "hm__item--sub-menu-open": isOpen
      })}
      data-id={id}
      onMouseOver={!hasActiveSubMenu ? handleMouseOver : undefined}
      onMouseLeave={!hasActiveSubMenu ? handleMouseLeave : undefined}
    >
      {url ? (
        <HorizontalMenuLink to={url} isActive={isNavLinkActive} role="menuitem">
          <FormattedMessage id={text} />
        </HorizontalMenuLink>
      ) : (
        <span>
          <FormattedMessage id={text} />
        </span>
      )}
      <SubMenu
        wrapperRef={linkItemRef}
        items={subMenu}
        hasActiveItem={hasActiveSubMenu}
        isOpened={isOpen}
      />
    </li>
  );
};

const MenuItem = ({
  item,
  currentPathname,
  isSubMenuOpened,
  changeOpenedSubMenuDropdown
}) => {
  const { id, text, url, subMenu } = item;

  if (!subMenu) {
    return (
      <li role="none" className="hm__item" data-id={id}>
        <NavLink
          to={url}
          className="hm__item__link"
          activeClassName="hm__item__link--active"
          role="menuitem"
        >
          <FormattedMessage id={text} />
        </NavLink>
      </li>
    );
  }

  return (
    <MenuItemWithSubMenu
      item={item}
      currentPathname={currentPathname}
      isOpen={isSubMenuOpened}
      changeOpenedSubMenuDropdown={changeOpenedSubMenuDropdown}
    />
  );
};

MenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  currentPathname: PropTypes.string.isRequired,
  changeOpenedSubMenuDropdown: PropTypes.func.isRequired,
  isSubMenuOpened: PropTypes.bool.isRequired
};

export default memo(MenuItem);
