import _ from "lodash";
import { getLanguage } from "../store/root.selectors";
import normalizeLocale from "./normalizeLocale";

export const loadTranslation = () => (dispatch, getState, { api }) => {
  const state = getState();
  const language = getLanguage(state);
  const localeKey = !_.isEmpty(language) ? normalizeLocale(language.key) : "";

  if (!_.isEmpty(state.translation[localeKey]) || !localeKey) {
    return Promise.resolve();
  }

  return api.resources
    .getTranslation(localeKey)
    .then(translations =>
      dispatch(loadTranslationsSuccess(localeKey, translations))
    )
    .catch(error => {
      dispatch(loadTranslationsFailure(error));
    });
};

export const changeTranslation = localeKey => (dispatch, getState, { api }) => {
  const { translation } = getState();
  const locale = normalizeLocale(localeKey);

  if (!_.isEmpty(translation[locale])) {
    return Promise.resolve();
  }

  return api.resources
    .getTranslation(locale)
    .then(translations =>
      dispatch(loadTranslationsSuccess(locale, translations))
    )
    .catch(error => {
      dispatch(loadTranslationsFailure(error));
    });
};

export const LOAD_TRANSLATIONS_SUCCESS =
  "[TRANSLATIONS] LOAD_TRANSLATIONS_SUCCESS";
export const LOAD_TRANSLATIONS_FAILURE =
  "[TRANSLATIONS] LOAD_TRANSLATIONS_FAILURE";

export const loadTranslationsSuccess = (localeKey, translations) => ({
  type: LOAD_TRANSLATIONS_SUCCESS,
  payload: { [localeKey]: translations }
});
export const loadTranslationsFailure = error => ({
  type: LOAD_TRANSLATIONS_FAILURE,
  payload: error
});
