import _ from "lodash";
import React, { Component } from "react";

import "./HelpContent.scss";

import { HelpSection } from "./HelpSection";

export class HelpContent extends Component {
  constructor(props) {
    super(props);
    const { sections, onLoad } = props;
    this.handleLoad = _.after(sections.length, onLoad);
  }

  componentDidMount() {
    this.clearSearchResults();
  }

  componentDidUpdate() {
    this.clearSearchResults();
  }

  clearSearchResults() {
    this.searchResults = _.transform(
      this.props.sections,
      (result, section) => {
        result[section.id] = null;
      },
      {}
    );
  }

  render() {
    const { sections, searchString } = this.props;

    return (
      <div className="help-content">
        {_.map(sections, section => (
          <HelpSection
            key={section.id}
            {...section}
            searchString={searchString}
            onHighlightDone={(id, amount) =>
              this.handleHighlightDone(id, amount)
            }
            onLoad={this.handleLoad}
          />
        ))}
      </div>
    );
  }

  handleHighlightDone(id, amount) {
    this.searchResults[id] = amount;
    const isAllDone = _.every(this.searchResults, result => !_.isNull(result));

    if (isAllDone) {
      this.props.onHighlightDone(this.searchResults);
    }
  }
}
