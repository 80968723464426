import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { PLACEMENTS } from "@sportal/cdk/tooltip/usePopperAPI";
import { RegularTooltip } from "@sportal/cdk/tooltip/RegularTooltip";
import { MouseTooltip as CommonMouseTooltip } from "@sportal/cdk/tooltip/MouseTooltip";

import "./Tooltip.scss";

export const Tooltip = ({ theme = "light", autoWidth = false, ...props }) => {
  return (
    <RegularTooltip
      {...props}
      className={classNames(`tooltip--${theme}`, props.className, {
        "tooltip--auto-width": autoWidth
      })}
    />
  );
};

export const MouseTooltip = ({ theme = "light", ...props }) => {
  return (
    <CommonMouseTooltip
      {...props}
      className={classNames(`tooltip--${theme}`, props.className)}
    />
  );
};

Tooltip.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
  placement: PropTypes.oneOf(PLACEMENTS),
  modifiers: PropTypes.array,
  autoWidth: PropTypes.bool,
  showTooltip: PropTypes.bool,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  disableEvents: PropTypes.bool,
  children: PropTypes.node.isRequired
};

MouseTooltip.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
  placement: PropTypes.oneOf(PLACEMENTS),
  modifiers: PropTypes.array,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  children: PropTypes.node.isRequired
};
