import React, { FC } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { getBackendUrl } from "../../../../store/config.selectors";
import { Button } from "../../../../components/button/Button";

export const SsoLogin: FC = () => {
  const backendUrl = useSelector(getBackendUrl);

  const handleSSOLogin = () => {
    // todo: location is a Location interface and we're assigning it to a string, fix it while working on UT
    (window as any).location =
      (backendUrl || "") +
      "/saml/login?action=" +
      encodeURIComponent(
        window.location.origin + window.location.pathname + "#home"
      );
  };
  return (
    <div className={"login-text-block col-lg-4 col-md-6 col-sm-12"}>
      <h3 className={"login__header"}>
        <FormattedMessage id={"part_of_akamai"} />
      </h3>
      <div>
        <FormattedMessage id={"login_with_sso_description"} />
      </div>
      <div className={"login-text-block__button"}>
        <Button onClick={() => handleSSOLogin()}>
          <FormattedMessage id={"continue"} />
        </Button>
      </div>
    </div>
  );
};
