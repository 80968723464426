import _ from "lodash";
import React, { Component, Fragment } from "react";
import classnames from "classnames";
import { connect } from "react-redux";

import "./Schedule.scss";

import { FormattedMessage } from "react-intl";
import {
  addSchedule,
  changeSchedule,
  removeSchedule,
  changeEnabled,
  changeCategories,
  changeSchedules,
  toggleEditPopup
} from "./schedules.actions";
import { ScheduleRuleList } from "./scheduleRuleList/ScheduleRuleList";
import {
  getAvailableDays,
  getAvailableName,
  getDaysShifted,
  SCHEDULE_LIMIT
} from "./schedules.selectors";
import { ScheduleCategories } from "./scheduleCategories/ScheduleCategories";
import ScheduleTimeGrid from "./scheduleTimeGrid/ScheduleTimeGrid";
import Flipswitch from "../../../../components/flipswitch/Flipswitch";
import { ShowToggle } from "../../../../components/showToggle/ShowToggle";
import { NotMobile, Mobile } from "../../../../hocs/Responsive";
import { SettingsButton } from "../../../../components/settingsButton/SettingsButton";
import { ModalService } from "../../../../components/modal";
import { is24HourFormat } from "../../../../store/account";
import { ScheduleCreateDialog } from "./scheduleCreateDialog/scheduleCreateDialog";
import { CreateRuleButton } from "./createRuleButton/CreateRuleButton";
import RulePopup from "./rulePopup/RulePopup";
import { ScheduleRulesOverlay } from "./scheduleRulesOverlay/scheduleRulesOverlay";

export class ScheduleComponent extends Component {
  state = {
    isOpen: this.props.isActive && !this.props.disabled,
    isPopupOpened: false,
    activeRule: null
  };

  componentDidUpdate(prevProps) {
    const { schedules, addSchedule, isActive, disabled } = this.props;

    if (_.isEmpty(schedules)) {
      addSchedule(this.getRuleWithDefaults({ enabled: false }));
    }

    if (prevProps.isActive !== isActive || prevProps.disabled !== disabled) {
      this.setState({ isOpen: isActive && !disabled });
    }
  }

  handleActiveChange = checked => {
    const { type, changeEnabled, profile } = this.props;

    this.setState({ isOpen: checked });
    changeEnabled(type, profile, checked);
  };

  getRuleWithDefaults = (rule = {}) => {
    const { type, profile, schedules, firstDayOfWeek } = this.props;

    return {
      type,
      profile,
      enabled: true,
      name: getAvailableName(type, _.map(schedules, "name")),
      day: [getAvailableDays(schedules, firstDayOfWeek)[0]],
      timeStart: "01:00",
      timeEnd: "01:01",
      categories: (schedules[0] && schedules[0].categories) || [],
      ...rule
    };
  };

  render() {
    const { className, disabled } = this.props;

    const scheduleContainerStyles = classnames("schedule", className);
    const scheduleHeaderStyles = classnames("schedule__header", {
      "schedule__header--disabled": disabled
    });

    return (
      <div className={scheduleContainerStyles}>
        <NotMobile>{this.renderDesktop(scheduleHeaderStyles)}</NotMobile>
        <Mobile>{this.renderMobile(scheduleHeaderStyles)}</Mobile>
      </div>
    );
  }

  renderDesktop(scheduleHeaderStyles) {
    const { description, disabled } = this.props;

    return (
      <Fragment>
        <div className={scheduleHeaderStyles}>
          {this.renderOpenToggle()}
          {this.renderActiveToggle()}
          <div className="schedule__description">{description}</div>
          {disabled && (
            <div className="schedule__error">
              <FormattedMessage id={"homework_time_disabled_explanation"} />
            </div>
          )}
        </div>
        {this.state.isOpen && (
          <div>
            {this.renderCreateRule()}
            {this.renderDesktopContent()}
          </div>
        )}
      </Fragment>
    );
  }

  renderDesktopContent() {
    const { isActive, categories, firstDayOfWeek } = this.props;

    const scheduleContentStyles = classnames("schedule__content", {
      "schedule__content--disabled": !isActive
    });

    const ruleProps = {
      days: getDaysShifted(firstDayOfWeek),
      activeRule: this.state.activeRule,
      setActiveRule: id => this.setState({ activeRule: id })
    };

    return (
      <div>
        <div className={scheduleContentStyles}>
          <div className={"schedule__content__timetable"}>
            <ScheduleTimeGrid {...this.props} {...ruleProps} />
            <ScheduleRuleList
              {...this.props}
              {...ruleProps}
              getRuleWithDefaults={args => this.getRuleWithDefaults(args)}
            />
          </div>
          {!_.isEmpty(categories) && <ScheduleCategories {...this.props} />}
        </div>
      </div>
    );
  }

  renderActiveToggle() {
    const { name, type, isActive } = this.props;

    return (
      <Flipswitch
        id={type}
        className="schedule__enable-toggle"
        label={
          <h4>
            <span className="schedule__title">{name}</span>
          </h4>
        }
        isChecked={isActive}
        handleChange={() => this.handleActiveChange(!isActive)}
      />
    );
  }

  renderOpenToggle = () => (
    <ShowToggle
      isOpen={this.state.isOpen}
      toggleOpen={() => this.toggleOpen()}
    />
  );

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  renderCreateRule() {
    const { firstDayOfWeek, schedules, isActive, addSchedule } = this.props;

    return (
      <Fragment>
        <Mobile>
          <CreateRuleButton
            disabled={!isActive || schedules.length >= SCHEDULE_LIMIT}
            onClick={() =>
              ModalService.show(modal => ({
                dialog: <ScheduleCreateDialog modal={modal} {...this.props} />
              }))
                .then(schedule => addSchedule(schedule))
                .catch(() => null)
            }
          />
        </Mobile>
        <NotMobile>
          <CreateRuleButton
            disabled={!isActive || schedules.length >= SCHEDULE_LIMIT}
            onClick={() => this.togglePopup()}
          />
          {this.state.isPopupOpened && (
            <RulePopup
              days={getDaysShifted(firstDayOfWeek)}
              selectedDays={[]}
              popupClass={"create"}
              onTogglePopup={() => this.togglePopup()}
              useShortNotations={false}
              {...this.props}
            />
          )}
        </NotMobile>
      </Fragment>
    );
  }

  togglePopup() {
    this.setState({ isPopupOpened: !this.state.isPopupOpened });
  }

  renderMobile(scheduleHeaderStyles) {
    const { schedules, categories, description, isActive } = this.props;
    const btnProps = {
      isOutline: true,
      className: "schedule__settings-btn",
      size: "large",
      disabled: !isActive
    };

    return (
      <Fragment>
        <div className={scheduleHeaderStyles}>{this.renderActiveToggle()}</div>
        <div className="schedule__description">{description}</div>
        {this.renderCreateRule()}
        <SettingsButton
          {...btnProps}
          label={<FormattedMessage id={"schedules"} />}
          status={
            <Fragment>
              <FormattedMessage id={"rules"} />
              <span className={"schedule__settings-btn__counter"}>
                {" "}
                {schedules.length}/{SCHEDULE_LIMIT}
              </span>
            </Fragment>
          }
          onClick={() =>
            ModalService.show(modal => ({
              dialog: <ScheduleRulesOverlay modal={modal} {...this.props} />
            })).catch(() => null)
          }
        />
        {!_.isEmpty(categories) && <ScheduleCategories {...this.props} />}
      </Fragment>
    );
  }
}

export const Schedule = connect(
  state => {
    const {
      config: { firstDayOfWeek },
      schedules: { editPopup }
    } = state;

    return {
      timeFormat24h: is24HourFormat(state),
      firstDayOfWeek,
      editPopup
    };
  },
  {
    addSchedule,
    changeSchedule,
    changeSchedules,
    removeSchedule,
    changeEnabled,
    changeCategories,
    toggleEditPopup
  }
)(ScheduleComponent);
