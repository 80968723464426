import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { Table } from "../../../../components/table/Table";
import { ReportTypes } from "../../../../helpers/reports.helper.types";
import { getBarchartReports } from "../../../../store/reports/reports.selectors";
import {
  COLUMNS_MULTIPLE,
  COLUMNS_ORDER,
  COLUMNS_SINGLE,
  initialSort,
  SEARCH_KEYS
} from "./DrilldownTable.constants";
import { CsvDownload } from "../csvDownload/CsvDownload";
import { DrilldownRoutePage } from "../../drilldown.types";
import { Desktop, useIsDesktop } from "../../../../hocs/Responsive";
import { getSecureBlocksLimit } from "../../../../store/config.selectors";

const GlobalFooter = ({ page }: { page: DrilldownRoutePage }) => (
  <div className={"drilldowns__table__footer-content"}>
    <div className={"bold"}>
      <FormattedMessage id={"additional_data_available_in_csv"} />
    </div>
    <CsvDownload page={page} />
  </div>
);

function getColumns(page, isMultiple) {
  const COLUMNS = isMultiple ? COLUMNS_MULTIPLE : COLUMNS_SINGLE;
  const cols = COLUMNS[page];
  const order = COLUMNS_ORDER[page];

  return _.sortBy(cols, col => order.indexOf(col.accessor));
}

interface DrilldownSubHeader {
  limit: number;
}

const DrilldownSubHeader: FC<DrilldownSubHeader> = ({ limit }) => {
  return (
    <FormattedMessage
      id={"drilldown_table_row_limit_additional_data"}
      values={{ limit }}
    />
  );
};

interface DrilldownTableDesktop {
  page: DrilldownRoutePage;
  tableCount: number;
  isMultiple: boolean;
  data: any;
}

export const DrilldownTableDesktop: FC<DrilldownTableDesktop> = ({
  page,
  tableCount,
  isMultiple,
  data
}) => {
  const barchart = useSelector(getBarchartReports, _.isEqual);
  const limit = useSelector(getSecureBlocksLimit);

  const isDesktop = useIsDesktop();
  const isNotBotnet = page !== DrilldownRoutePage.Botnet;

  const max = _.reduce(
    barchart,
    (sum, item) =>
      sum + (item[ReportTypes.Malware] || item[ReportTypes.Webfilter]),
    0
  );

  const columns = useMemo(() => getColumns(page, isMultiple), [
    page,
    isMultiple
  ]);

  const renderGlobalFooter = ({ pageCount, state: { pageIndex } }) =>
    pageCount - 1 === pageIndex ? (
      <Desktop>
        <GlobalFooter page={page} />
      </Desktop>
    ) : null;

  return (
    <Table
      columns={columns}
      data={data}
      defaultSorted={[initialSort]}
      renderGlobalFooter={isNotBotnet && tableCount < max && renderGlobalFooter}
      subHeader={
        isDesktop && isNotBotnet && <DrilldownSubHeader limit={limit} />
      }
      searchAriaLabelMessage="aria_search_for_drilldown_item"
      showPagination
      searchable
      searchKeys={SEARCH_KEYS}
    />
  );
};
