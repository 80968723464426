import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router";
import _ from "lodash";

import {
  isMultipleUserLevel,
  isSPSONAvailable
} from "../../store/account/account.selectors";

import { withLayout } from "../../components/layout/Layout";
import { withPageName } from "../../components/layout/horizontalMenu/PageName";

import { SpsonPanel } from "./spsonPanel/SpsonPanel";
import { Desktop, TabletMobile } from "../../hocs/Responsive";
import { SingleRoamingDevicesTable } from "./singleRoamingDevicesTable/SingleRoamingDevicesTable";

import "./SpsonPage.scss";

const SpsonPageContent = () => {
  const isMultiple = useSelector(isMultipleUserLevel);
  const SPSONAvailable = useSelector(isSPSONAvailable);

  return (
    <Fragment>
      {(isMultiple || !SPSONAvailable) && <Redirect to={"/home"} />}
      <div className={"spson-page"}>
        <h2 className={"spson-page__title"}>
          <FormattedMessage id={"spson"} />
        </h2>
        <div className={"spson-page__content"}>
          <Desktop>
            <div className={"spson-page__content--left"}>
              <SpsonDescription />
              <SingleRoamingDevicesTable />
            </div>
            <div className={"spson-page__content--right"}>
              <SpsonPanel className={"spson-page_panel"} />
            </div>
          </Desktop>
          <TabletMobile>
            <SpsonDescription />
            <SpsonPanel className={"spson-page__panel"} />
            <SingleRoamingDevicesTable />
          </TabletMobile>
        </div>
      </div>
    </Fragment>
  );
};

const SpsonPageTitle = () => {
  return (
    <h2>
      <FormattedMessage id={"spson"} />
    </h2>
  );
};

const SpsonDescription = () => {
  return (
    <div className={"spson-page__description"}>
      <p>
        <FormattedMessage id={"spson_description_1"} />
      </p>
      <p>
        <FormattedMessage id={"spson_description_2"} />
      </p>
    </div>
  );
};

export const SpsonPage = _.flow(
  withLayout(),
  withPageName(SpsonPageTitle())
)(SpsonPageContent);
