import _ from "lodash";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import "./ProtectionEditor.scss";

import { CategoriesList } from "./CategoriesList";
import { Button } from "../../../../../components/button/Button";
import { CheckboxButton } from "../checkboxButton/CheckboxButton";
import { getMapList } from "../webFilters.selectors";
import { Icon } from "../../../../../components/icon/Icon";

const listConfig = {
  blocked: {
    id: "blocked",
    icon: "fa-ban color-danger",
    actionName: "onAllow"
  },
  allowed: {
    id: "allowed",
    icon: "fa-check color-success",
    actionName: "onBlock"
  }
};

export class ProtectionEditor extends Component {
  state = {
    filter: "" //allowed/blocked
  };

  render() {
    const { protection, blockedByWebFilter } = this.props;

    return (
      <div className="protection-editor">
        <div className={"protection-editor__list-container"}>
          <CategoriesList
            className="categories-list"
            list={this.getFilteredList()}
            blockedByWebFilter={blockedByWebFilter}
            protection={protection}
          />
        </div>
        <div className={"protection-editor__options"}>
          {this.renderEditorOptions()}
        </div>
      </div>
    );
  }

  renderEditorOptions() {
    const { categories, onAllow, onBlock } = this.props;
    const { filter } = this.state;

    return (
      <Fragment>
        <div className={"filter-section"}>
          <span className="filter-section__title">
            <FormattedMessage id="categories" />:
          </span>

          <CheckboxButton
            isOutline={filter !== "allowed"}
            onClick={() => this.filterChange("allowed")}
          >
            <span>
              <FormattedMessage id="allowed" />
            </span>
            <span className="categories-count">
              &nbsp; {categories.allowed.length}
            </span>
          </CheckboxButton>
          <CheckboxButton
            isOutline={filter !== "blocked"}
            onClick={() => this.filterChange("blocked")}
          >
            <span>
              <FormattedMessage id="blocked" />
            </span>
            <span className="categories-count">
              &nbsp; {categories.blocked.length}
            </span>
          </CheckboxButton>
        </div>
        <div className={"action-section"}>
          <Button
            className="action-section__button"
            isOutline
            onClick={() =>
              onBlock(_.map(categories.allowed, ({ name }) => name))
            }
            disabled={_.isEmpty(categories.allowed)}
          >
            <Icon icon="far fa-ban color-danger" />
            <span>
              <FormattedMessage id="block_all" />
            </span>
          </Button>
          <Button
            className="action-section__button"
            isOutline
            onClick={() =>
              onAllow(_.map(categories.blocked, ({ name }) => name))
            }
            disabled={_.isEmpty(categories.blocked)}
          >
            <Icon icon="far fa-check color-success" />
            <span>
              <FormattedMessage id="allow_all" />
            </span>
          </Button>
        </div>
      </Fragment>
    );
  }

  getFilteredList = () => {
    const { filter } = this.state;
    const blocked = getMapList(this.props, listConfig.blocked);
    const allowed = getMapList(this.props, listConfig.allowed);

    switch (filter) {
      case "allowed":
        return allowed;
      case "blocked":
        return blocked;
      default:
        return _.concat(allowed, blocked);
    }
  };

  filterChange = option => {
    if (this.state.filter === option) return this.setState({ filter: "" });

    return this.setState({ filter: option });
  };
}

ProtectionEditor.propTypes = {
  categories: PropTypes.shape({
    blocked: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string
      })
    ),
    allowed: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string
      })
    )
  }),
  onBlock: PropTypes.func,
  onAllow: PropTypes.func,
  protection: PropTypes.string,
  blockedByWebFilter: PropTypes.arrayOf(PropTypes.string)
};
