import _ from "lodash";

export const containsSubString = (
  value: any,
  searchedValue: string
): boolean => {
  if (!_.isString(value) && !_.isNumber(value)) return false;

  return (
    String(value)
      .toUpperCase()
      .indexOf(searchedValue.toUpperCase()) !== -1
  );
};

export const searchInArray = (
  values: any[],
  searchedValue: string
): boolean => {
  // this covers a case for - identifiers | flatInfections
  return values.some(value => containsSubString(value, searchedValue));
};
