import React, { memo, useMemo } from "react";

import { Select } from "../../../../components/select";
import { useIntlTranslate } from "../../../../hooks";

const LanguagePicker = ({ languages, selected, onChange }) => {
  const translate = useIntlTranslate();

  const items = useMemo(() => {
    return languages.map(language => ({
      value: language.value,
      content: language.name
    }));
  }, [languages]);

  return (
    <Select
      items={items}
      onChange={onChange}
      selected={selected}
      label={translate("language_description")}
    />
  );
};

export default memo(LanguagePicker);
