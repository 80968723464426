import React, { Component } from "react";
import classNames from "classnames";

import "./ButtonLink.scss";

class ButtonLink extends Component {
  render() {
    const { children, className, ...attrs } = this.props;
    const buttonStyles = classNames("button-link", className);

    return (
      <button className={buttonStyles} {...attrs}>
        {children}
      </button>
    );
  }
}

export default ButtonLink;
