export const setCookie = (key, value, liveTime) => {
  const expirationDate = new Date(),
    cookieKey = key;

  if (liveTime) {
    expirationDate.setMilliseconds(expirationDate.getMilliseconds() + liveTime);
  } else {
    expirationDate.setFullYear(expirationDate.getFullYear() + 3);
  }

  document.cookie =
    cookieKey +
    "=" +
    value +
    ";path=/; expires=" +
    expirationDate.toGMTString();
};

export const getCookie = key => {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" + key.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1") + "=([^;]*)"
    )
  );

  return matches ? decodeURIComponent(matches[1]) : null;
};
