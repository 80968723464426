import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import _ from "lodash";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { DevicesSets } from "../../../../helpers/devices.helpers";
import "../../../../components/devices/TableDevices.scss";
import { isSPSONAvailable } from "../../../../store/account";
import { ProfileNotRoamingDevicesTable } from "../shared/ProfileNotRoamingDevicesTable";
import { ProfileRoamingDevicesTable } from "../shared/ProfileRoamingDevicesTable";
import { hasProfileReachedLimit } from "../../../../store/profiles/profiles.selectors";
import { TabList, createDeviceLinkTab } from "../../../../components/tabList";
import { Mobile, NotMobile } from "../../../../hocs/Responsive";
import { HorizontalScrollTabs } from "../../../../components/horizontalScrollTabs/HorizontalScrollTabs";

import "./NonDefaultProfileContent.scss";

// LinkTab[]
const TABS = [
  { value: DevicesSets.NotRoaming, name: "all", url: "all" },
  { value: DevicesSets.Roaming, name: "roaming", url: "roaming" }
];

export const NonDefaultProfileContent = ({ profile }) => {
  const intl = useIntl();
  const showSPSON = useSelector(isSPSONAvailable);
  const isLimitReached = useSelector(hasProfileReachedLimit(profile.name));
  const { url } = useRouteMatch();

  const tabs = useMemo(() => {
    const filteredTabs = showSPSON
      ? TABS
      : _.reject(TABS, {
          value: DevicesSets.Roaming
        });

    // in case tabs array contains only one tab ("all") we aren't showing it
    if (filteredTabs.length === 1) {
      return [];
    }

    return filteredTabs.map(tab => createDeviceLinkTab(tab, url, intl));
  }, [showSPSON, intl, url]);

  return (
    <Fragment>
      <h4>
        <FormattedMessage id={"devices"} />
      </h4>
      <div className={"settings-page__description"}>
        <p>{getDescription(profile)}</p>
        {isLimitReached && (
          <p className="color-danger">
            <FormattedMessage id="profile_limit_reached" />
          </p>
        )}
      </div>
      <div className={"devices__layout devices--per-profile"}>
        <NotMobile>
          <TabList items={tabs} />
        </NotMobile>
        <Mobile>
          <HorizontalScrollTabs tabs={tabs} />
        </Mobile>
        <Switch>
          <Route
            exact
            path={`${url}/all`}
            render={props => (
              <ProfileNotRoamingDevicesTable
                {...props}
                profileName={profile.name}
              />
            )}
          />
          {showSPSON && (
            <Route
              exact
              path={`${url}/roaming`}
              render={props => (
                <ProfileRoamingDevicesTable
                  {...props}
                  profileName={profile.name}
                />
              )}
            />
          )}
          <Redirect to={`${url}/all`} />
        </Switch>
      </div>
    </Fragment>
  );
};

const getDescription = profile => {
  const isEmployees = profile.type === "employees";
  const messageId = isEmployees
    ? "employees_full_description"
    : "custom_full_description";

  return <FormattedMessage id={messageId} />;
};
