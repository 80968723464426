import React, { Fragment } from "react";

import { useBlockDeviceMenuItem } from "../../../components/devices/hooks";
import { DeviceDropdownMenu } from "../../../components/devices/DeviceDropdownMenu";
import { NotMobile, Mobile } from "../../../hocs/Responsive";
import { DeviceActionsToggle } from "../../../components/devices/DeviceActionsDialog";

export const InfectedDevicesActionsDropdown = ({ device }) => {
  const menuItems = [useBlockDeviceMenuItem(device)];

  return (
    <Fragment>
      <Mobile>
        <DeviceActionsToggle deviceName={device.name} menuItems={menuItems} />
      </Mobile>
      <NotMobile>
        <DeviceDropdownMenu menuItems={menuItems} />
      </NotMobile>
    </Fragment>
  );
};
