import React from "react";
import { AnyAction } from "redux";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import * as base32 from "hi-base32";

import Notificator from "../../components/notification/notification.actions";
import { fetchThreats } from "../threats/threats.actions";
import {
  isDeviceAddressExist,
  isDeviceNameBusy
} from "../../helpers/validation.helper";
import {
  getIdentifiers,
  getNewDeviceName,
  isAddressInList
} from "../../helpers/devices.helpers";
import {
  getLogicalDevices,
  getLogicalDevicesList,
  getLines,
  getRoamingLimit
} from "./devices.selectors";
import { shouldLoad } from "../shared";
import { uuid } from "../../utils/uuid";
import {
  CHECK_CODE_FAILURE,
  DevicesActionTypes,
  DevicesFailureActionTypes,
  EDIT_DEVICE_FAILURE,
  EDIT_DEVICE_SUCCESS,
  EDIT_ROAMING_DEVICE_FAILURE,
  EDIT_ROAMING_DEVICE_SUCCESS,
  EditRoamingDeviceChangesPayload,
  FETCH_ALL_DEVICES,
  FETCH_ALL_DEVICES_FAILURE,
  FETCH_ALL_DEVICES_SUCCESS,
  FETCH_DISCOVERED,
  FETCH_DISCOVERED_FAILURE,
  FETCH_DISCOVERED_SUCCESS,
  FETCH_INFECTED,
  FETCH_INFECTED_FAILURE,
  FETCH_INFECTED_SUCCESS,
  FETCH_LINES,
  FETCH_LINES_FAILURE,
  FETCH_LINES_SUCCESS,
  FETCH_REQUESTS_NAME,
  FETCH_REQUESTS_NAME_FAILURE,
  FETCH_REQUESTS_NAME_SUCCESS,
  FETCH_ROAMING_LIMIT_SUCCESS,
  FETCH_ROAMING_LIMIT_FAILURE,
  LogicalDevicesPayload,
  LogicalDeviceUpdates,
  MERGE_DEVICE_FAILURE,
  MERGE_DEVICE_SUCCESS,
  REMOVE_DEVICE_FAILURE,
  REMOVE_DEVICE_SUCCESS,
  REVOKE_ROAMING_DEVICE_ACCESS_FAILURE,
  REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS,
  SAVE_DEVICE_FAILURE,
  SAVE_DEVICE_SUCCESS,
  SBDeviceDetails,
  SBDiscoveredDevice,
  SBLogicalDevice,
  SBLogicalDeviceServerData,
  SBRequestWithName,
  UNMERGE_DEVICE_FAILURE,
  UNMERGE_DEVICE_SUCCESS
} from "./devices.types";
import { ThunkApi } from "../../api/api";
import {
  InfectedDeviceServerData,
  LineServerData,
  LogicalDeviceUpdate,
  Manufacturer
} from "@sportal/api";
import { AddNotificationAction } from "../../components/notification/notification.types";
import { ActionError, SBThunkAction } from "../redux.types";
import { getSubscriberInfo } from "../root.selectors";

export const fetchDiscovered = (initialLoad = false) => (
  dispatch,
  getState,
  { api }: ThunkApi
) => {
  const savedDevices = getLogicalDevicesList(getState());

  if (initialLoad) {
    dispatch({ type: FETCH_DISCOVERED });
  }

  return api.ssm.devices
    .getDiscovered(getState().subscriberInfo.id)
    .then(data => {
      let discovered = _.chain(data)
        .flatMap("value")
        .map(val => _.zipObject(["address", "lastseen"], [val[0], val[1]]))
        .filter(device => !isDeviceAddressExist(savedDevices, device.address))
        .groupBy("address")
        .mapValues(value => _.maxBy(value, "lastseen"))
        .values()
        .sortBy("lastseen")
        .value();

      if (_.isEmpty(discovered)) {
        dispatch(fetchDiscoveredSuccess([]));
      } else {
        api.ssm.devices
          .getManufacturers(mapAddresses(discovered))
          .then(data => {
            discovered = updateManufacturers(discovered, data);
          })
          .catch(() => {
            dispatch(
              Notificator.error(
                <FormattedMessage id={"error_failed_to_load_manufacturers"} />
              )
            );
          })
          .finally(() => {
            dispatch(
              fetchDiscoveredSuccess(discovered as SBDiscoveredDevice[])
            );
          });
      }
    })
    .catch(error => {
      dispatch(
        Notificator.error(<FormattedMessage id={"failed_to_load_discovered"} />)
      );
      dispatch(generalFailure(error, FETCH_DISCOVERED_FAILURE));

      return Promise.reject();
    });
};

export const fetchLines = (): SBThunkAction<
  Promise<void>,
  DevicesActionTypes | AddNotificationAction
> => async (dispatch, getState, { api }) => {
  const lines = getLines(getState());

  if (!shouldLoad(lines)) return;

  dispatch({ type: FETCH_LINES });

  try {
    const lines = await api.ssm.devices.getLines(getState().subscriberInfo.id);
    const withoutFixedLines = _.filter(lines.content, { "is-device": true });
    dispatch(fetchLinesSuccess(withoutFixedLines));
  } catch (error) {
    dispatch(
      Notificator.error(<FormattedMessage id={"failed_to_load_lines"} />)
    );
    dispatch(generalFailure(error, FETCH_LINES_FAILURE));
  }
};

export const fetchRequestsWithName = (initialLoad = false) => (
  dispatch,
  getState,
  { api }: ThunkApi
) => {
  if (initialLoad) {
    dispatch({ type: FETCH_REQUESTS_NAME });
  }

  return api.ssm.devices
    .getRequestsWithName(getState().subscriberInfo.id)
    .then(data => {
      let requested = _.chain(data)
        .flatMap("value")
        .map(val =>
          _.zipObject(
            ["address", "name", "lastseen"],
            [val[0], decodeName(val[1]), val[2]]
          )
        )
        .filter(device => device.name)
        .value();

      if (_.isEmpty(requested)) {
        dispatch(
          fetchRequestsWithNameSuccess(requested as SBRequestWithName[])
        );
      } else {
        return api.ssm.devices
          .getManufacturers(mapAddresses(requested))
          .then(data => {
            requested = updateManufacturers(requested, data);
          })
          .catch(() => {
            dispatch(
              Notificator.error(
                <FormattedMessage id={"error_failed_to_load_manufacturers"} />
              )
            );
          })
          .finally(() => {
            dispatch(
              fetchRequestsWithNameSuccess(requested as SBRequestWithName[])
            );
          });
      }
    })
    .catch(error => {
      dispatch(
        Notificator.error(<FormattedMessage id={"failed_to_load_requests"} />)
      );
      dispatch(generalFailure(error, FETCH_REQUESTS_NAME_FAILURE));

      return Promise.reject();
    });
};

export const fetchInfected = () => (dispatch, getState, { api }: ThunkApi) => {
  const { subscriberInfo } = getState();

  dispatch({ type: FETCH_INFECTED });

  return api.ssm.devices
    .getInfected(subscriberInfo.id)
    .then(infectedDevices => {
      dispatch(fetchInfectedSuccess(infectedDevices));
    })
    .then(() => dispatch(fetchThreats()))
    .catch(error => {
      dispatch(
        Notificator.error(<FormattedMessage id={"failed_to_load_infected"} />)
      );
      dispatch(generalFailure(error, FETCH_INFECTED_FAILURE));

      return Promise.reject();
    });
};

export const fetchLogicalDevices = () => async (dispatch, getState, deps) => {
  const { subscriberInfo } = getState();
  const { id } = subscriberInfo;
  const { api }: ThunkApi = deps;

  if (!shouldLoad(getLogicalDevices(getState()))) {
    return;
  }

  dispatch({ type: FETCH_ALL_DEVICES });

  let devices;
  try {
    devices = await api.ssm.devices.getLogicalDevices(id);
  } catch (error) {
    dispatch(
      Notificator.error(
        <FormattedMessage id={"error_failed_to_load_devices"} />
      )
    );
    dispatch(generalFailure(error, FETCH_ALL_DEVICES_FAILURE));
    throw Error();
  }

  const limit = devices.limit;
  let allDevices = devices.content;

  if (_.isEmpty(allDevices)) {
    dispatch(
      fetchLogicalDevicesSuccess({
        all: processDevices([]),
        limit
      })
    );
    return;
  }

  try {
    const manufacturers = await api.ssm.devices.getManufacturers(
      mapIdentifiers(allDevices)
    );
    allDevices = updateManufacturers(allDevices, manufacturers);
  } catch (error) {
    dispatch(
      Notificator.error(
        <FormattedMessage id={"error_failed_to_load_manufacturers"} />
      )
    );
  }

  dispatch(
    fetchLogicalDevicesSuccess({
      all: processDevices(allDevices),
      limit
    })
  );
};

export const fetchNewDevices = () => async dispatch => {
  await dispatch(fetchDiscovered());
  await dispatch(fetchRequestsWithName());
};

interface CreateSaveDeviceConfig {
  createSuccessNotification: () => AddNotificationAction;
  createErrorNotification: () => AddNotificationAction;
}

const createSaveDeviceActionCreator = ({
  createSuccessNotification,
  createErrorNotification
}: CreateSaveDeviceConfig) => (
  device: LogicalDeviceUpdate
): SBThunkAction<void, DevicesActionTypes | AddNotificationAction> => (
  dispatch,
  getState,
  { api }
) => {
  const devices = getLogicalDevicesList(getState());
  const deviceName = _.trim(device.name);
  const newDeviceName = isDeviceNameBusy(devices, deviceName)
    ? getNewDeviceName(devices, deviceName)
    : "";
  const deviceToSave = { ...device, name: newDeviceName || deviceName };

  return api.ssm.devices
    .saveDevice(getState().subscriberInfo.id, deviceToSave)
    .then(({ succeeded }) => {
      let withManufacturers;

      dispatch(createSuccessNotification());

      return api.ssm.devices
        .getManufacturers(mapIdentifiers(succeeded))
        .then(manufacturers => {
          withManufacturers = updateManufacturers(succeeded, manufacturers);
        })
        .catch(() => {
          dispatch(
            Notificator.error(
              <FormattedMessage id="error_failed_to_load_manufacturers" />
            )
          );
        })
        .finally(() => {
          dispatch(
            saveDeviceSuccess(processDevices(withManufacturers || succeeded))
          );
        });
    })
    .catch(error => {
      dispatch(createErrorNotification());
      dispatch(generalFailure(error, SAVE_DEVICE_FAILURE));

      return Promise.reject();
    });
};

export const saveDevice = createSaveDeviceActionCreator({
  createSuccessNotification: () =>
    Notificator.success(<FormattedMessage id="device_added" />),
  createErrorNotification: () =>
    Notificator.error(<FormattedMessage id="cant_add_device" />)
});

const saveDeviceToBlock = createSaveDeviceActionCreator({
  createSuccessNotification: () =>
    Notificator.success(<FormattedMessage id="device_blocked" />),
  createErrorNotification: () =>
    Notificator.error(<FormattedMessage id="device_was_not_blocked" />)
});

export const blockDevice = (
  device: LogicalDeviceUpdate
): SBThunkAction<void, AnyAction> => (dispatch, getState) => {
  const state = getState();
  const devices = getLogicalDevicesList(state);

  const isDeviceSaved = d =>
    _.includes(getIdentifiers(devices), d.identifiers[0]);
  const getSavedDevice = () => _.find(devices, d => d.name === device.name);

  const isSaved = isDeviceSaved(device);
  const savedDevice = isSaved ? getSavedDevice() : null;

  return savedDevice
    ? dispatch(
        editDeviceToBlock({ ...device, identifiers: savedDevice.identifiers })
      )
    : dispatch(saveDeviceToBlock(device));
};

export const removeDevice = (deviceName: string) => (
  dispatch,
  getState,
  { api }: ThunkApi
) => {
  const device = _.find(getLogicalDevicesList(getState()), {
    name: deviceName
  });
  const id = getState().subscriberInfo.id;

  return api.ssm.devices
    .removeDevice(id, deviceName)
    .then(() => {
      dispatch(removeDeviceSuccess(device));
    })
    .catch(error => {
      dispatch(
        Notificator.error(<FormattedMessage id={"couldnt_save_changes"} />)
      );
      dispatch(generalFailure(error, REMOVE_DEVICE_FAILURE));
      return Promise.reject();
    });
};

interface CreateEditDeviceConfig {
  createSuccessNotification: () => AddNotificationAction;
  createErrorNotification: () => AddNotificationAction;
}

const createEditDeviceActionCreator = ({
  createSuccessNotification,
  createErrorNotification
}: CreateEditDeviceConfig) => (
  changes: LogicalDeviceUpdates,
  oldName: string = changes.name
): SBThunkAction<void, AddNotificationAction | DevicesActionTypes> => (
  dispatch,
  getState,
  { api }
) => {
  const id = getState().subscriberInfo.id;
  const device = _.find(getLogicalDevicesList(getState()), { name: oldName });

  return api.ssm.devices
    .editDevice(id, oldName, { ...device, ...changes })
    .then(() => {
      dispatch(createSuccessNotification());
      return dispatch(editDeviceSuccess(changes, oldName));
    })
    .catch(error => {
      dispatch(createErrorNotification());
      dispatch(generalFailure(error, EDIT_DEVICE_FAILURE));

      return Promise.reject();
    });
};

export const editDevice = createEditDeviceActionCreator({
  createSuccessNotification: () =>
    Notificator.success(<FormattedMessage id="device_changed" />),
  createErrorNotification: () =>
    Notificator.error(<FormattedMessage id="couldnt_save_changes" />)
});

const editDeviceToBlock = createEditDeviceActionCreator({
  createSuccessNotification: () =>
    Notificator.success(<FormattedMessage id="device_blocked" />),
  createErrorNotification: () =>
    Notificator.error(<FormattedMessage id="device_was_not_blocked" />)
});

export const mergeDevice = (device: LogicalDeviceUpdates, address: string) => (
  dispatch,
  getState,
  { api }: ThunkApi
) => {
  const id = getState().subscriberInfo.id;

  const savedDevices = getLogicalDevicesList(getState());
  const deviceToRemove = _.find(savedDevices, d =>
    _.includes(d.identifiers, address)
  );
  const updatedDevice = {
    ...device,
    identifiers: [...device.identifiers, address]
  };

  return api.ssm.devices
    .editDevice(id, updatedDevice.name, updatedDevice)
    .then(({ succeeded }) => {
      dispatch(Notificator.success(<FormattedMessage id={"device_changed"} />));
      return dispatch(
        mergeDeviceSuccess(
          processDevices(
            succeeded as any /* TODO: manufacturer is missing here*/
          ),
          deviceToRemove
        )
      );
    })
    .catch(error => {
      dispatch(
        Notificator.error(<FormattedMessage id={"couldnt_save_changes"} />)
      );
      dispatch(generalFailure(error, MERGE_DEVICE_FAILURE));

      return Promise.reject();
    });
};

export const unmergeDevice = (
  device: LogicalDeviceUpdates,
  idToUnmerge: string
) => dispatch => {
  const newDevice = {
    name: idToUnmerge,
    identifiers: [idToUnmerge],
    profile: device.profile
  };
  const savedDevice = {
    ...device,
    identifiers: _.without(device.identifiers, idToUnmerge)
  };

  return dispatch(editDevice(savedDevice))
    .then(() => dispatch(saveDevice(newDevice)))
    .then(() => {
      dispatch(Notificator.success(<FormattedMessage id={"changes_saved"} />));
      return dispatch(unmergeDeviceSuccess(device, newDevice));
    })
    .catch(error => {
      dispatch(
        Notificator.error(<FormattedMessage id={"couldnt_save_changes"} />)
      );
      dispatch(generalFailure(error, UNMERGE_DEVICE_FAILURE));

      return Promise.reject();
    });
};

export const checkCode = (code: string) => (
  dispatch,
  getState,
  { api }: ThunkApi
) => {
  return api.ssm.devices
    .getRequestsByCode(getState().subscriberInfo.id)
    .then(data => {
      const getCode = fullCode =>
        fullCode.replace(".bb.nom-proxy", "").slice(-4);

      const values = _.flatMap(data, "value");
      const mappedCodes = _.reduce(
        values,
        (memo, value) => ({
          ...memo,
          [getCode(value[1])]: value[0]
        }),
        {}
      );

      return _.has(mappedCodes, code) ? mappedCodes[code] : "";
    })
    .catch(error => {
      dispatch(generalFailure(error, CHECK_CODE_FAILURE));
      return Promise.reject();
    });
};

export const editRoamingDevice = (
  identifier: string,
  changes: Partial<EditRoamingDeviceChangesPayload>
) => (dispatch, getState, { api }: ThunkApi) => {
  const { id: subscriberId } = getState().subscriberInfo;
  const attributes = _.pick(changes, ["name", "username"]);

  return api.ssm.devices
    .updateSpsonDevice(subscriberId, identifier, attributes)
    .then(() => {
      dispatch(
        Notificator.success(<FormattedMessage id="successfully_saved" />)
      );
      dispatch(editRoamingDeviceSuccess(identifier, attributes));
    })
    .catch(error => {
      dispatch(
        Notificator.error(
          <FormattedMessage id="could_not_update_roaming_device" />
        )
      );
      dispatch(generalFailure(error, EDIT_ROAMING_DEVICE_FAILURE));

      return Promise.reject();
    });
};

export const editRoamingDeviceSuccess = (
  identifier: string,
  changes: Partial<EditRoamingDeviceChangesPayload>
): DevicesActionTypes => ({
  type: EDIT_ROAMING_DEVICE_SUCCESS,
  payload: { identifier, changes }
});

export const revokeRoamingDeviceAccess = identifier => async (
  dispatch,
  getState,
  { api }: ThunkApi
) => {
  const { id: subscriberId } = getState().subscriberInfo;

  return api.ssm.devices
    .removeSpsonDevice(subscriberId, identifier)
    .then(() => {
      dispatch(revokeRoamingDeviceAccessSuccess(identifier));
    })
    .catch(error => {
      dispatch(
        Notificator.error(
          <FormattedMessage id="could_not_revoke_device_access" />
        )
      );
      dispatch(generalFailure(error, REVOKE_ROAMING_DEVICE_ACCESS_FAILURE));

      return Promise.reject();
    });
};

export const revokeRoamingDeviceAccessSuccess = (
  identifier: string
): DevicesActionTypes => ({
  type: REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS,
  payload: { identifier }
});

export const fetchRoamingLimit = () => async (dispatch, getState, { api }) => {
  const state = getState();
  const subscriberInfo = getSubscriberInfo(state);
  const currentLimit = getRoamingLimit(state);

  if (currentLimit) return;

  try {
    const limit = await api.ssm.account.getSpsonLimit(subscriberInfo.id);

    dispatch(fetchRoamingLimitSuccess(limit));
  } catch (e) {
    dispatch(
      Notificator.error(<FormattedMessage id={"fetch_roaming_limit_error"} />)
    );
    dispatch(generalFailure(e, FETCH_ROAMING_LIMIT_FAILURE));
  }
};

export const fetchRoamingLimitSuccess = (
  limit: number
): DevicesActionTypes => ({
  type: FETCH_ROAMING_LIMIT_SUCCESS,
  payload: { limit }
});

export const fetchDiscoveredSuccess = (
  devices: SBDiscoveredDevice[]
): DevicesActionTypes => ({
  type: FETCH_DISCOVERED_SUCCESS,
  payload: devices
});
export const fetchLinesSuccess = (
  lines: (LineServerData & { "is-device": boolean })[]
): DevicesActionTypes => ({
  type: FETCH_LINES_SUCCESS,
  payload: lines
});
export const fetchRequestsWithNameSuccess = (
  devices: SBRequestWithName[]
): DevicesActionTypes => ({
  type: FETCH_REQUESTS_NAME_SUCCESS,
  payload: devices
});
export const fetchInfectedSuccess = (
  devices: InfectedDeviceServerData
): DevicesActionTypes => ({
  type: FETCH_INFECTED_SUCCESS,
  payload: devices
});
export const fetchLogicalDevicesSuccess = (devices: {
  limit: number;
  all: LogicalDevicesPayload;
}): DevicesActionTypes => ({
  type: FETCH_ALL_DEVICES_SUCCESS,
  payload: devices
});
export const saveDeviceSuccess = (
  devices: LogicalDevicesPayload
): DevicesActionTypes => ({
  type: SAVE_DEVICE_SUCCESS,
  payload: devices
});
export const removeDeviceSuccess = (
  device: SBLogicalDevice
): DevicesActionTypes => ({
  type: REMOVE_DEVICE_SUCCESS,
  payload: { device }
});
export const editDeviceSuccess = (
  device: LogicalDeviceUpdates,
  oldName: string
): DevicesActionTypes => ({
  type: EDIT_DEVICE_SUCCESS,
  payload: { device, oldName }
});
export const mergeDeviceSuccess = (
  updatedDevices: LogicalDevicesPayload,
  removedDevice: SBLogicalDevice
): DevicesActionTypes => ({
  type: MERGE_DEVICE_SUCCESS,
  payload: { updatedDevices, removedDevice }
});
export const unmergeDeviceSuccess = (
  updatedDevice: LogicalDeviceUpdates,
  newDevice: LogicalDeviceUpdates
): DevicesActionTypes => ({
  type: UNMERGE_DEVICE_SUCCESS,
  payload: { updatedDevice, newDevice }
});

export const generalFailure = (
  error: ActionError,
  type: DevicesFailureActionTypes
) => ({
  type,
  payload: error
});

const mapIdentifiers = (
  devices: SBLogicalDeviceServerData[]
): Manufacturer[] => {
  const identifiers = _.flatMap(devices, "identifiers");

  return _.map(identifiers, id => ({ id }));
};
const mapAddresses = (
  devices: Partial<SBLogicalDevice & { address: string }>[]
): Manufacturer[] => {
  return _.map(devices, device => ({ id: device.address }));
};
const updateManufacturers = (devices, manufacturers): SBLogicalDevice[] => {
  return _.map(devices, device => {
    const matchingRecords = _.filter(manufacturers, manufacturer =>
      _.has(device, "identifiers")
        ? isAddressInList(device.identifiers, manufacturer.id)
        : device.address === manufacturer.id
    );
    const withManufacturerDefined = _.filter(matchingRecords, record =>
      _.has(record, "name")
    );

    const manufacturer = _.isEmpty(withManufacturerDefined)
      ? ""
      : _.head(withManufacturerDefined).name;

    return {
      ...device,
      manufacturer
    };
  });
};
const decodeName = (name: string): string => {
  try {
    const encUriRegex = /\?enc=uri$/;

    if (encUriRegex.test(name)) {
      return decodeURIComponent(name.replace(encUriRegex, "") || "");
    } else {
      return base32.decode((name || "").toUpperCase());
    }
  } catch (e) {
    return name;
  }
};
const getPlainDevices = (
  devices: (SBLogicalDevice & { "device-details": SBDeviceDetails[] })[]
): Omit<SBLogicalDevice, "device-details">[] => {
  return _.map(devices, device => _.omit(device, "device-details"));
};
const getIdentifiersMap = (
  devices: (SBLogicalDevice & { "device-details": SBDeviceDetails[] })[]
) => {
  return _.keyBy(
    _.flatMapDeep(devices, device =>
      _.values(_.pick(device, "device-details"))
    ),
    "identifier"
  );
};
const enhanceWithUnixLastSeen = (
  devices: (SBLogicalDeviceServerData & { manufacturer: string })[]
): Omit<SBLogicalDevice, "logicalDeviceId">[] => {
  return _.map(devices, device => {
    const mappedDevice = {
      ...device,
      lastseen: device["last-seen"]
        ? new Date(device["last-seen"]).getTime()
        : null
    };

    delete mappedDevice["last-seen"];

    return mappedDevice;
  });
};
const extendDevicesWithId = (
  devices: Omit<SBLogicalDevice, "logicalDeviceId">[]
): (SBLogicalDevice & { "device-details": SBDeviceDetails[] })[] => {
  return _.map(devices, device => {
    const id = uuid();
    const details = _.map(device["device-details"], detail => ({
      ...detail,
      logicalDeviceId: id
    }));

    return {
      ...device,
      "device-details": details,
      logicalDeviceId: id
    };
  });
};

const processDevices = (
  devices: (SBLogicalDeviceServerData & { manufacturer: string })[]
): LogicalDevicesPayload => {
  if (_.isEmpty(devices)) {
    return {
      plainDevices: [],
      identifiers: {}
    };
  }

  const devicesWithUnixLastSeen = enhanceWithUnixLastSeen(devices);

  const devicesWithId = extendDevicesWithId(devicesWithUnixLastSeen);
  const plainDevices = getPlainDevices(devicesWithId);
  const identifiers = getIdentifiersMap(devicesWithId);

  return {
    plainDevices,
    identifiers
  };
};
