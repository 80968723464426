import React, { Fragment } from "react";
import classnames from "classnames";
import { toast } from "react-toastify";

import { Icon } from "../icon/Icon";

const NOTIFICATION_ICONS = {
  [toast.TYPE.SUCCESS]: "fas fa-check-circle",
  [toast.TYPE.INFO]: "fas fa-info-square",
  [toast.TYPE.ERROR]: "fas fa-ban",
  [toast.TYPE.WARNING]: "fas fa-exclamation-triangle"
};

const showNotification = (type, content, options) => {
  toast.dismiss();

  const icon = NOTIFICATION_ICONS[type];

  const notification = (
    <Fragment>
      <Icon
        icon={classnames(icon, "notification__icon notification__icon--button")}
      />
      <div className="notification__content">
        {options.title && (
          <div className="notification__title">{options.title}</div>
        )}
        <div className="notification__text">{content}</div>
      </div>
    </Fragment>
  );

  return toast(notification, {
    type,
    ...options
  });
};

export default {
  success(content, options) {
    return showNotification(toast.TYPE.SUCCESS, content, options);
  },
  info(content, options) {
    return showNotification(toast.TYPE.INFO, content, options);
  },
  warning(content, options) {
    return showNotification(toast.TYPE.WARNING, content, options);
  },
  error(content, options) {
    return showNotification(toast.TYPE.ERROR, content, options);
  },
  show(content, options) {
    return showNotification(toast.TYPE.DEFAULT, content, options);
  },
  isActive(notificationId) {
    return toast.isActive(notificationId);
  },
  dismissAll() {
    return toast.dismiss();
  }
};
