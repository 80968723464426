import _ from "lodash";

import { Profile, Protection } from "@sportal/api";
import { SERVICES } from "../../common/utils/permissions";
import { FIXED_PROFILES, ProfileGroups } from "./fixedProfiles";
import { uuid } from "../../utils/uuid";
import { RootState } from "../root.reducer";
import {
  ProfilesSlice,
  ProfilesState,
  ProfileType,
  SBProfile
} from "./profiles.types";
import { SSProfilesSlice } from "../../pages/profiles/components/internetSecurity/subscriberSafety/ssProfiles.types";

const profilesOrder = [ProfileGroups.Visitors, ProfileGroups.Employees];
const isNotDefaultProfile = profile => !profilesOrder.includes(profile.name);

export function sortProfiles(profiles) {
  return [
    _.find(profiles, { name: profilesOrder[0] }),
    _.find(profiles, { name: profilesOrder[1] }),
    ..._.chain(profiles)
      .filter(isNotDefaultProfile)
      .sortBy(profile => profile.name.toUpperCase())
      .value()
  ].filter(Boolean);
}

// TODO: it can overwrite existing items and it is used atm
//  since it is not obvious would be nice to do something with it
export const addItemsToList = (items = [], state = { keys: [], list: {} }) => {
  // const itemsWithId = _.map(items, item => _.extend({ id: uuid() }, item));

  return {
    keys: _.uniq([...state.keys, ..._.map(items, "id")]),
    list: { ...state.list, ..._.keyBy(items, "id") }
  };
};

const storedFields = [
  "name",
  "default",
  "internet-access",
  "protection",
  "safe-search",
  "safe-search-services"
] as const;

type DehydratedProfile = Pick<Profile, typeof storedFields[number]>;

export const dehydrate = (profile: Profile): DehydratedProfile =>
  _.pick(profile, storedFields);

export const rehydrate = (
  partialProfile: Partial<Profile>,
  state: RootState
) => {
  const fixedProfile =
    _.find(FIXED_PROFILES, { name: partialProfile.name }) || {};
  const profile = construct(
    {
      ...fixedProfile,
      ...partialProfile
    },
    state
  );

  // If protection from BE is disabled via appConfig
  // it should be replaced with the default protection for corresponding group
  const { protections = {}, groupDefaults = {} } = state.config;
  const configDefaults = groupDefaults[profile.type] || {};

  return {
    ...profile,
    protection: findFirstAllowedProtection(
      [partialProfile.protection, configDefaults.protection],
      protections
    )
  };
};

export const construct = (partialProfile: any, state: RootState) => {
  const { config, settings } = state;
  const { protections = {}, groupDefaults = {} } = config;

  const type = partialProfile.type || ProfileType.Custom;

  //TODO: rework
  const configDefaults = groupDefaults[type] || {};
  const protection = findFirstAllowedProtection(
    [configDefaults.protection, partialProfile.protection],
    protections
  );

  const serviceSettings = _.find(settings.services, {
    service: SERVICES.SB
  } as any);
  // todo: fix "any" casting
  const allowedSearchServices = serviceSettings["safe-search"];

  return {
    id: uuid(),
    type,
    ...configDefaults,
    "safe-search-services": _.pick(
      configDefaults["safe-search-services"],
      allowedSearchServices
    ),
    ..._.omit(partialProfile, "subscriber-safety"),
    protection
  };
};

const findFirstAllowedProtection = (
  protections: Protection[],
  allowed: { [key in Protection]: boolean }
): Protection =>
  _.find(
    protections,
    protection =>
      _.get(allowed, protection, true) || protection === Protection.Custom // Custom protection cannot be disabled through appConfig
  ) || Protection.Custom;

// TODO: extract, also consider comparing dehydrated entities so that
//  there is no need in customized intersectors
const intersect = (value: SBProfile, other: SBProfile) =>
  _.transform(
    storedFields as any,
    (diff, field: typeof storedFields[number]) => {
      if (!_.isEqual(value[field], other[field])) {
        diff[field] = value[field];
      }
    },
    {}
  );

// TODO: the function looks related to { saved, changed } nLists structure
export const getChangedFields = (
  id: string,
  { saved, changed }: ProfilesState
) => {
  const value = changed.list[id];
  const other = saved.list[id];

  return intersect(value, other);
};

export const updateProfileIdsInList = (
  slice: ProfilesSlice | SSProfilesSlice,
  idsMap: { [key: string]: string }
) => {
  return {
    keys: _.map(slice.keys, key => idsMap[key] || key),
    list: _.reduce(
      slice.list,
      (acc, profile, id) => ({
        ...acc,
        [idsMap[id] || id]: {
          ...profile,
          id: idsMap[id] || id
        }
      }),
      {}
    )
  };
};
