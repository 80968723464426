import React from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { Checkbox } from "../../../../../components/checkbox/Checkbox";

import "./scheduleDays.scss";

const DAYS = {
  MO: "mon",
  TU: "tue",
  WE: "wed",
  TH: "thu",
  FR: "fri",
  SA: "sat",
  SU: "sun"
};

export const ScheduleDays = props => {
  const { days, selectedDays, onDaysChange, showFullNames = false } = props;
  const isChecked = day => _.includes(selectedDays, day);

  return (
    <div className={"schedule-days"}>
      {_.map(days, day => (
        <Checkbox
          onChange={onDaysChange}
          isChecked={isChecked(day)}
          key={day}
          name={day}
        >
          <FormattedMessage id={showFullNames ? day : DAYS[day]} />
        </Checkbox>
      ))}
    </div>
  );
};
