import { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { getRoamingDevices } from "../../../store/devices/devices.selectors";
import { useGetLastSeen } from "../../../components/devices/useGetLastSeen";

export const useRoamingDevices = () => {
  const roamingDevices = useSelector(getRoamingDevices, _.isEqual);

  return useRoamingDevicesEnhancer(roamingDevices);
};

export const useRoamingDevicesEnhancer = devices => {
  const getLastSeen = useGetLastSeen();

  return useMemo(() => {
    return _.map(devices, device => ({
      ...device,
      unix: device.lastseen,
      lastseen: getLastSeen(device.lastseen)
    }));
  }, [devices, getLastSeen]);
};
