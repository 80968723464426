import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { Table } from "../../../components/table/Table";
import { getBlockedDevicesForTable } from "../../../store/devices/devices.selectors";
import { InfectionsList } from "../../../components/infectionsList/InfectionsList";
import { DeviceWithType } from "../../../components/devices/DeviceWithType";
import { Manufacturer } from "../../../components/manufacturer/Manufacturer";
import { TableExpandableCard } from "../../../components/table/components/TableExpandableCard";
import { ClickAbsorber } from "../../../components/clickAbsorber/ClickAbsorber";
import {
  multipleDeviceIdSort,
  multipleThreatsSort
} from "../../../components/table/helpers/sorting.helper";
import { Mobile, NotMobile } from "../../../hocs/Responsive";
import { InfectionsSummary } from "./InfectionsSummary";
import { BlockedDevicesActionsDropdown } from "./BlockedDevicesActionsDropdown";
import {
  DeviceNameCell,
  DeviceNameCellMobile
} from "../../../components/table";

const COLUMNS = [
  {
    Header: <FormattedMessage id={"device_name"} />,
    accessor: "name",
    sortable: true,
    Cell: ({
      row: {
        original: { name }
      }
    }) => <DeviceNameCell name={name} />
  },
  {
    Header: <FormattedMessage id={"device_id"} />,
    accessor: "identifiers",
    sortable: true,
    sortType: multipleDeviceIdSort,
    Cell: ({
      row: {
        original: { details }
      }
    }) => {
      return (
        <div>
          {_.map(details, ({ type, identifier }) => (
            <DeviceWithType
              type={type}
              key={identifier}
              identifier={identifier}
            />
          ))}
        </div>
      );
    }
  },
  {
    Header: <FormattedMessage id={"manufacturer"} />,
    accessor: "manufacturer",
    sortable: true,
    cellClassName: "color-secondary",
    Cell: ({
      row: {
        original: { manufacturer }
      }
    }) => <Manufacturer manufacturer={manufacturer} />
  },
  {
    Header: <FormattedMessage id={"infections"} />,
    accessor: "flatInfections",
    sortable: true,
    sortType: multipleThreatsSort,
    Cell: ({
      row: {
        original: { infections }
      }
    }) => <InfectionsList infections={infections} />
  },
  {
    id: "actions",
    sortable: false,
    headerClassName: "table__cell--small-cell",
    Header: <FormattedMessage id={"actions"} />,
    cellClassName: "table__cell--center table__cell--overflow",
    Cell: ({
      row: {
        original: { name, identifiers, manufacturer }
      }
    }) => (
      <BlockedDevicesActionsDropdown
        device={{
          name,
          identifiers,
          manufacturer
        }}
        validateImmediately={false}
        isSavedDevice={true}
      />
    )
  }
];

const COLUMNS_MOBILE = [
  {
    accessor: "name",
    cellClassName: "table__cell--overflow",
    SortingLabel: <FormattedMessage id={"device_name"} />,
    Cell: ({
      row: {
        isExpanded,
        original: { details, manufacturer, name, infections, identifiers }
      }
    }) => (
      <TableCard
        isExpanded={isExpanded}
        details={details}
        manufacturer={manufacturer}
        name={name}
        infections={infections}
        identifiers={identifiers}
      />
    )
  },
  {
    accessor: "identifiers",
    sortType: multipleDeviceIdSort,
    SortingLabel: <FormattedMessage id={"device_id"} />
  },
  {
    accessor: "manufacturer",
    SortingLabel: <FormattedMessage id={"manufacturer"} />
  },
  {
    accessor: "flatInfections",
    sortType: multipleThreatsSort,
    SortingLabel: <FormattedMessage id={"infections"} />
  }
];

const SEARCH_KEYS = ["flatInfections", "identifiers", "manufacturer", "name"];

const initialSort = [{ id: "flatInfections", desc: true }];

export const BlockedDevicesTable = () => {
  const devices = useSelector(getBlockedDevicesForTable, _.isEqual);

  return (
    <Fragment>
      <Mobile>
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          showMobileSort
          visibleHeader={false}
          data={devices}
          columns={COLUMNS_MOBILE}
          hiddenColumns={["identifiers", "flatInfections", "manufacturer"]}
          renderRowSubComponent={renderRowSubComponent}
          defaultSorted={initialSort}
          searchAriaLabelMessage="aria_search_for_device"
        />
      </Mobile>
      <NotMobile>
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          columns={COLUMNS}
          data={devices}
          defaultSorted={initialSort}
          searchAriaLabelMessage="aria_search_for_device"
        />
      </NotMobile>
    </Fragment>
  );
};

const TableCard = ({
  isExpanded,
  details,
  manufacturer,
  name,
  identifiers,
  infections
}) => {
  return (
    <TableExpandableCard
      isExpanded={isExpanded}
      content={
        <Fragment>
          <DeviceNameCellMobile name={name} />
          <div>
            {_.map(details, ({ type, identifier }) => (
              <DeviceWithType
                key={identifier}
                type={type}
                identifier={identifier}
              />
            ))}
          </div>
          <InfectionsSummary infections={infections} />
        </Fragment>
      }
      aside={
        <ClickAbsorber>
          <BlockedDevicesActionsDropdown
            device={{ name, identifiers, manufacturer }}
            validateImmediately={false}
            isSavedDevice={true}
          />
        </ClickAbsorber>
      }
    />
  );
};

const renderRowSubComponent = ({
  row: {
    original: { infections, manufacturer }
  }
}) => {
  return (
    <div className={"sub-row-content"} data-testid="blocked-device-sub-row">
      <div className={"sub-row-content__item"}>
        <div className={"sub-row-content__header"}>
          <FormattedMessage id={"manufacturer"} />
        </div>
        <Manufacturer manufacturer={manufacturer} />
      </div>
      <InfectionsList infections={infections} />
    </div>
  );
};
