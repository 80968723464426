import _ from "lodash";

import { ellipsisInTheMiddle } from "./ellipsis.helper";
import { normalizeMacAddress } from "./validation.helper";
import { DeviceTab } from "../components/tabList";

export enum DevicesSets {
  Discovered = "discovered",
  Infected = "infected",
  Blocked = "blocked",
  Roaming = "roaming",
  NotRoaming = "not-roaming", // devices with device type Mobile and Wifi
  Requests = "new-requests"
}

export enum DevicesTypes {
  Mobile = "line",
  Wifi = "hardware-address",
  Roaming = "roaming"
}

export const TABS: DeviceTab[] = [
  { value: DevicesSets.Discovered, name: "online" },
  { value: DevicesSets.Infected, name: "infected" },
  { value: DevicesSets.Blocked, name: "blocked" },
  { value: DevicesSets.Roaming, name: "roaming" }
];

const rejectTabItem = type => tabs => _.reject(tabs, { value: type });
export const rejectRoaming = tabs => rejectTabItem(DevicesSets.Roaming)(tabs);
export const rejectRequests = tabs => rejectTabItem(DevicesSets.Requests)(tabs);
export const rejectUnassigned = tabs =>
  rejectTabItem(DevicesSets.NotRoaming)(tabs);

export const getTypeForIdentifier = (identifier, lines, details) => {
  const associatedLine = _.find(lines, { name: identifier });
  const deviceDetails = _.get(details, identifier);

  return getDeviceType(associatedLine, deviceDetails);
};

export const getIdentifiers = devices => {
  return _.chain(devices)
    .map("identifiers")
    .reduce((arr, id) => arr.concat(id), [])
    .value();
};

export const getDeviceById = (devices, id) => {
  return _.find(devices, device => {
    if (_.has(device, "identifiers")) {
      return isAddressInList(device.identifiers, id);
    }

    return device.address === id;
  });
};

export const getNewDeviceName = (devices, deviceName) => {
  let name = deviceName;
  let index = 1;

  while (_.some(devices, { name: name })) {
    name = `${name} (${index++})`;
  }

  return name;
};

export const getInfectedDeviceName = (device, namedRequests, savedDevices) => {
  const savedDevice = _.find(savedDevices, d =>
    isAddressInList(d.identifiers, device.id)
  );

  if (savedDevice) {
    return savedDevice.name;
  }

  const requestedDevice = _.find(namedRequests, d =>
    isAddressInList([d.address], device.id)
  );

  if (requestedDevice) {
    return requestedDevice.name;
  }

  return device.id;
};

export const getDeviceType = (line, details) => {
  if (!_.isNil(details) && details.type === DevicesTypes.Roaming) {
    return DevicesTypes.Roaming;
  }

  if (!_.isNil(line) && line["is-device"]) {
    return DevicesTypes.Mobile;
  }

  return DevicesTypes.Wifi;
};

const UUID_MAX_LENGTH = 15;

export const ellipsifyUUID = uuid => ellipsisInTheMiddle(uuid, UUID_MAX_LENGTH);

export const isAddressInList = (list, address) => {
  const normalizedList = _.map(list, id => normalizeMacAddress(id));
  const normalizedAddress = normalizeMacAddress(address);

  return _.includes(normalizedList, normalizedAddress);
};
