import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./THead.scss";

export const THead = ({
  headers,
  subHeader,
  visibleHeader,
  visibleColumns
}) => (
  <thead className="header">
    {visibleHeader && (
      <tr className="header-row">
        {headers.map((column, index) => {
          const {
            headerClassName,
            isSorted,
            isSortedDesc,
            sortable = true
          } = column;

          const columnProps = sortable
            ? column.getHeaderProps(
                _.omit(column.getSortByToggleProps(), "title")
              )
            : column.getHeaderProps();

          const sortingClasses = classnames({
            sorted: sortable,
            "sorted--asc": isSorted && !isSortedDesc,
            "sorted--desc": isSorted && isSortedDesc
          });

          // key for each element will be overriden by 'columnProps'. Array index is only for unit tests
          return (
            <th
              key={index}
              className={classnames(headerClassName, {
                "column-sorted": sortable
              })}
              {...columnProps}
            >
              <div className={sortingClasses}>{column.render("Header")}</div>
            </th>
          );
        })}
      </tr>
    )}

    {subHeader && (
      <tr className="sub-header-row">
        <th colSpan={visibleColumns.length}>
          <div className="table__sub-header" data-testid="table-sub-header">
            {subHeader}
          </div>
        </th>
      </tr>
    )}
  </thead>
);

THead.defaultProps = {
  visibleColumns: []
};

THead.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      headerClassName: PropTypes.string,
      isSorted: PropTypes.bool,
      isSortedDesc: PropTypes.bool,
      sortable: PropTypes.bool,
      getHeaderProps: PropTypes.func.isRequired,
      getSortByToggleProps: PropTypes.func,
      render: PropTypes.func
    })
  ),
  visibleHeader: PropTypes.bool,
  subHeader: PropTypes.node,
  visibleColumns: PropTypes.array
};
