import _ from "lodash";
import React, { Fragment, memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { InternetSecurity } from "./components/internetSecurity/InternetSecurity";
import { WebFilters } from "./components/webFilters/WebFilters";
import { ProfileTab } from "./components/profileTab/ProfileTab";
import { ScheduleContainer } from "./components/schedule/ScheduleContainer";
import { Button } from "../../components/button/Button";
import { SettingsSidebarNavigation } from "../../components/settingsSidebarNavigation/SettingsSidebarNavigation";
import { Counter } from "../../components/counter/Counter";
import {
  useAddProfileAction,
  useDeleteProfileItem,
  useEditProfileItem,
  useSelectedProfileId
} from "./components/hooks";
import {
  getProfileById,
  getVisibleProfiles,
  getVisibleProfilesWithKeys
} from "../../store/profiles/profiles.selectors";

import "./Profiles.scss";

export const ActionsSection = ({ profileLimit, addDialogWillShow }) => {
  const profilesLength = useSelector(state => getVisibleProfiles(state).length);
  const selectedProfileId = useSelectedProfileId();
  const selectedProfile = useSelector(
    getProfileById(selectedProfileId),
    _.isEqual
  );

  const actionItems = [
    useEditProfileItem(selectedProfile),
    useDeleteProfileItem(selectedProfile)
  ];
  const addProfileItem = useAddProfileAction();

  const handleAddClick = action => {
    if (addDialogWillShow) {
      addDialogWillShow(action);
    } else {
      action();
    }
  };

  return (
    <Fragment>
      {selectedProfile.type === "custom"
        ? _.map(actionItems, ({ id, mobileContent, action }) => (
            <Button key={id} isOutline size={"large"} onClick={action}>
              {mobileContent}
            </Button>
          ))
        : null}
      <Button
        isOutline
        size={"large"}
        disabled={profilesLength >= profileLimit}
        onClick={() => handleAddClick(addProfileItem.action)}
      >
        {addProfileItem.mobileContent}
      </Button>
    </Fragment>
  );
};

const ProfilesTab = ({ profile }) => {
  const menuItems = [
    useEditProfileItem(profile),
    useDeleteProfileItem(profile)
  ];

  return (
    <ProfileTab
      type={profile.type}
      menu={profile.type === "custom" ? menuItems : null}
      profileId={profile.id}
      title={profile.type !== "custom" ? profile.type : profile.name}
      to={`/settings/profiles/${profile.id}`}
      as={NavLink}
    />
  );
};

export const ProfileContent = () => (
  <div className={"profiles__content"}>
    <WebFilters className="profiles__section" />
    <ScheduleContainer className="profiles__section" />
    <InternetSecurity className="profiles__section" />
  </div>
);

export const MenuHeader = memo(
  ({ profileLimit, profilesLength, currentAmount, addDialogWillShow }) => {
    const addProfileItem = useAddProfileAction();
    const handleAddClick = () => {
      if (addDialogWillShow) {
        addDialogWillShow(addProfileItem);
      } else {
        addProfileItem.action();
      }
    };

    return (
      <div className={"sidebar__header"}>
        <h2 className={"sidebar__header--main"}>
          <FormattedMessage id={"protection_preferences"} />
        </h2>
        <div className={"sidebar__add-group"}>
          <Counter
            className={"sidebar__counter"}
            label={
              <h4>
                <FormattedMessage id={"profiles"} />
              </h4>
            }
            amount={currentAmount}
            limit={profileLimit}
          />
          <Button
            isOutline
            disabled={profilesLength >= profileLimit}
            onClick={handleAddClick}
          >
            {addProfileItem.content}
          </Button>
        </div>
      </div>
    );
  }
);

export const Profiles = ({
  limit,
  addDialogWillShow,
  customProfilesAmount
}) => {
  const profiles = useSelector(
    getVisibleProfilesWithKeys(["id", "name", "type"]),
    _.isEqual
  );

  const profileLimit = limit - 1; // 1 means hidden group for blocked devices
  const currentAmount = customProfilesAmount + 2; //TODO: 2 means default groups visitors and employees

  if (profiles.length === 1) {
    return (
      <div className="profiles">
        <h2 className={"profiles__title--single"}>
          <FormattedMessage id={"protection_preferences"} />
        </h2>
        <ProfileContent />
      </div>
    );
  }

  const prepareTabs = () =>
    _.map(profiles, profile => {
      return {
        id: profile.id,
        header: <ProfilesTab profile={profile} />,
        content: <ProfileContent />,
        name: profile.name,
        type: profile.type
      };
    });

  return (
    <div className="profiles profiles--multiple">
      <SettingsSidebarNavigation
        tabs={prepareTabs()}
        activeTabClass={ProfileTab.activeClass}
        sidebarMenuHeader={
          <MenuHeader
            profileLimit={profileLimit}
            profilesLength={profiles.length}
            currentAmount={currentAmount}
            addDialogWillShow={addDialogWillShow}
          />
        }
        counter={
          <Counter
            className={"sidebar__counter"}
            label={<FormattedMessage id={"profiles"} />}
            amount={currentAmount}
            limit={profileLimit}
          />
        }
        sideSection={
          <ActionsSection
            profileLimit={profileLimit}
            addDialogWillShow={addDialogWillShow}
          />
        }
      />
    </div>
  );
};

Profiles.propTypes = {
  // Awkward name points to the fact that this is doubtful solution
  addDialogWillShow: PropTypes.func
};
