import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import "./UrlFiltersInfobar.scss";
import { NotMobile } from "../../../../hocs/Responsive";
import { HelpLink } from "../../../../components/helpLink/HelpLink";

export const UrlFiltersInfobar = ({ className, children }) => (
  <div className={classnames("url-filters-infobar", className)}>
    <div className="infobar__tip">
      <p>
        <FormattedMessage id="url_filters_description" />
      </p>
      <HelpLink section={"blocking-content-for-user-groups"}>
        <FormattedMessage id="online_help" />
      </HelpLink>
    </div>
    <NotMobile>
      <div className="infobar__content">{children}</div>
    </NotMobile>
  </div>
);
