import React, { Fragment } from "react";

import { TableExpandableCard } from "../../../../components/table/components/TableExpandableCard";

interface Props {
  showExpand?: boolean;
  isExpanded?: boolean;
  url: string;
  content: string;
  time: string;
  count: number;
}

export const CardWithCount = ({
  showExpand,
  isExpanded,
  url,
  content,
  time,
  count
}: Props) => {
  return (
    <TableExpandableCard
      showExpand={showExpand}
      isExpanded={isExpanded}
      content={
        <Fragment>
          <span>{url}</span>
          <span>{content}</span>
          <span className="placeholder">{time}</span>
        </Fragment>
      }
      aside={<div className={"count"}>{count}</div>}
    />
  );
};

CardWithCount.defaultProps = {
  showExpand: true,
  isExpanded: false
};
