import React, { Fragment } from "react";
import PropTypes from "prop-types";

import "./Layout.scss";

import HorizontalMenu from "./horizontalMenu/HorizontalMenu";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { LoaderContainer } from "../loader";
import { StaticNotifications } from "../notification/staticNotifications/StaticNotifications";
import { EulaFooter, EulaHeader } from "../eula";
import { useLayoutContext } from "./LayoutContext";

const Layout = ({
  showUserInfo,
  showNavigation,
  showHeaderUnderline,
  showHelpLink,
  showLogOutLink,
  customHeader,
  children
}) => {
  const { eulaHeaderHeight, eulaFooterHeight } = useLayoutContext();

  return (
    <Fragment>
      <div
        className="layout"
        style={{
          paddingTop: `${eulaHeaderHeight}px`,
          paddingBottom: `${eulaFooterHeight}px`
        }}
      >
        {showUserInfo && <StaticNotifications />}
        {customHeader ? (
          customHeader()
        ) : (
          <Header
            showUserInfo={showUserInfo}
            showHeaderUnderline={showHeaderUnderline}
            showLogOutLink={showLogOutLink}
            showHelpLink={showHelpLink}
          />
        )}
        {showNavigation && <HorizontalMenu />}
        <div className="layout-container">{children}</div>
        <Footer />
      </div>
      <LoaderContainer />
    </Fragment>
  );
};

Layout.propTypes = {
  showNavigation: PropTypes.bool,
  showUserInfo: PropTypes.bool,
  showHeaderUnderline: PropTypes.bool,
  showHelpLink: PropTypes.bool,
  showLogOutLink: PropTypes.bool,
  customHeader: PropTypes.func
};

Layout.defaultProps = {
  showNavigation: true,
  showUserInfo: true,
  showHeaderUnderline: false,
  showHelpLink: false,
  showLogOutLink: false
};

export const withLayout = layoutConfig => Page => props => {
  const { showEulaHeader, showEulaFooter } = useLayoutContext();

  return (
    <Fragment>
      {showEulaHeader && <EulaHeader />}
      <Layout {...layoutConfig}>
        <Page {...props} />
      </Layout>
      {showEulaFooter && <EulaFooter />}
    </Fragment>
  );
};
