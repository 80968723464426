export class StaticResourceProvider {
  constructor(config) {
    this._http = config.http;
  }

  getHelpContent(language) {
    return this._http.get(`./translations/help/${language}/helpContent.md`);
  }

  getTimezones() {
    return this._http.get("./timezones.json");
  }

  getTranslation(localeKey) {
    return this._http.get(`./translations/${localeKey}.json`);
  }
}
