import React, { Fragment } from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import { IMAGE_VALIDATION_ERRORS } from "../imageUpload/ImageUpload";
import { getAttributesFromFile } from "../../../../helpers/files.helper";
import {
  ACCEPTED_FILE_TYPES,
  MAX_FILE_SIZE_KB
} from "../../blockPage.constants";
import {
  UploadValidationErrors,
  UploadErrorType,
  UploadValidationError
} from "../imageUpload/upload.types";

export const LOGO_VALIDATION_ERRORS: UploadValidationErrors = {
  MAX_FILE_SIZE: {
    type: UploadErrorType.FileIsTooLarge,
    message: (
      <Fragment>
        <FormattedMessage id="image_file_too_large" /> &nbsp;
        <FormattedMessage id="maximum_allowed_size_is" />
        {MAX_FILE_SIZE_KB}
        <FormattedMessage id="kilobytes" />
      </Fragment>
    ),
    shortMessage: (
      <Fragment>
        <FormattedMessage id="file_exceeds_size" />
        {MAX_FILE_SIZE_KB}
        KB
      </Fragment>
    )
  },
  MISSING_SVG_ATTRIBUTES: {
    type: UploadErrorType.MissingSvgAttributes,
    message: <FormattedMessage id="error_missing_svg_attributes" />,
    shortMessage: <FormattedMessage id="missing_svg_attrs" />
  },
  SVG_CONTAINS_SCRIPT: {
    type: UploadErrorType.SvgContainsScript,
    message: <FormattedMessage id="error_svg_contains_script" />,
    shortMessage: <FormattedMessage id="error_svg_contains_script" />
  },
  ...IMAGE_VALIDATION_ERRORS
};

export const validateLogo = async (
  logo: File
): Promise<UploadValidationError> => {
  if (logo.size > MAX_FILE_SIZE_KB * 1000) {
    return LOGO_VALIDATION_ERRORS.MAX_FILE_SIZE;
  }

  if (!Object.values(ACCEPTED_FILE_TYPES).find(type => type === logo.type)) {
    return LOGO_VALIDATION_ERRORS.IMAGE_TYPE;
  }

  if (logo.type === ACCEPTED_FILE_TYPES.SVG) {
    try {
      const mandatoryAttributes = [
          "width",
          "height",
          "viewBox",
          "preserveAspectRatio"
        ],
        logoAttributes = await getAttributesFromFile(logo),
        missingAttributes = mandatoryAttributes.filter(
          attr => !_.includes(logoAttributes, attr)
        );

      if (!missingAttributes.length) return null;

      return {
        ...LOGO_VALIDATION_ERRORS.MISSING_SVG_ATTRIBUTES,
        message: (
          <Fragment>
            {LOGO_VALIDATION_ERRORS.MISSING_SVG_ATTRIBUTES.message}
            {missingAttributes.map(name => `"${name}"`).join(", ")}
          </Fragment>
        )
      };
    } catch (e) {
      return LOGO_VALIDATION_ERRORS.SVG_CONTAINS_SCRIPT;
    }
  }
};
