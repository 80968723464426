import { HttpService, HttpServiceResponse, mapDataKeys } from "../shared";
import { Service } from "../settings";
import { AccountData, BypassPinUpdates } from "./account.types";

const accountDataKeysMapper = mapDataKeys({
  "time-zone": "timezone"
});

export class AccountProvider {
  constructor(private http: HttpService, private baseUrl: string) {}

  private get url(): string {
    return `${this.baseUrl}/ssm/account`;
  }

  public get(subscriberId: string): Promise<HttpServiceResponse<AccountData>> {
    return this.http
      .get(`${this.url}/${subscriberId}`)
      .then(accountDataKeysMapper);
  }

  public toggleService(
    subscriberId: string,
    service: Service,
    enabled: boolean
  ) {
    return this.http.patch(`${this.url}/${subscriberId}`, {
      [service]: enabled
    });
  }

  public updateTimezone(subscriberId: string, timezone: string) {
    return this.http.patch(`${this.url}/${subscriberId}`, {
      "time-zone": timezone
    });
  }

  public updateBypassPin(subscriberId: string, updates: BypassPinUpdates) {
    return this.http.patch(`${this.url}/${subscriberId}/bypass-pin`, updates);
  }
}
