import React, { FC, useMemo } from "react";
import classNames from "classnames";

import { TabItem } from "../../tabList";
import { Select } from "../../select";
import { useIntlTranslate } from "../../../hooks";

import "./HiddenOptions.scss";

interface HiddenOptionsProps {
  items: TabItem[];
  selected: string;
  onClick: (id: string) => void;
}

export const HiddenOptions: FC<HiddenOptionsProps> = ({
  items,
  selected,
  onClick
}) => {
  const translate = useIntlTranslate();

  const options = useMemo(() => {
    return items.map(item => ({
      value: item.id,
      content: item.content
    }));
  }, [items]);

  const hiddenSelected = useMemo(
    () => options.some(option => option.value === selected),
    [options, selected]
  );

  return (
    <Select
      className={classNames("hidden-options", {
        "hidden-options--selected": hiddenSelected
      })}
      items={options}
      selected={selected}
      onChange={selected => onClick(selected)}
      ariaLabel={translate("aria_see_other_available_groups")}
    />
  );
};
