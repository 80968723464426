import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";

import { prepareFormValues } from "../../helpers/form.helper";
import {
  getFromSafetyValidation,
  getSafetyRequiredFields,
  getSafetyResource,
  getSafetyToSave
} from "./safety.selectors";

export const useMalwareForm = () => {
  const toSave = useSelector(getSafetyToSave, shallowEqual);
  const resource = useSelector(getSafetyResource, shallowEqual);
  const requiredFields = useSelector(getSafetyRequiredFields, shallowEqual);

  const rules = useSelector(getFromSafetyValidation("rules"), _.isEqual);
  const fieldsToValidate = useSelector(
    getFromSafetyValidation("fieldsToValidate"),
    shallowEqual
  );
  const scheme = useSelector(getFromSafetyValidation("scheme"), _.isEqual);

  return prepareFormValues(
    toSave,
    resource,
    requiredFields,
    rules,
    fieldsToValidate,
    scheme
  );
};
