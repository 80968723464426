import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Truncate from "react-truncate";

import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import { isSBReportingLicensed } from "../../../../store/settings/settings.selectors";
import {
  Dropdown,
  createDivider,
  createTitle
} from "../../../dropdown/Dropdown";
import { useOpenHistoryClearModal } from "./useOpenHistoryClearModal";
import { useIntlTranslate } from "../../../../hooks";
import { useOpenHelp } from "../../../helpLink/HelpLink";
import { Icon } from "../../../icon/Icon";

import "./UserMenu.scss";

const UserMenu = ({ portalName, logout, subscriberName }) => {
  const translate = useIntlTranslate();
  const history = useHistory();

  const isReportingLicensed = useSelector(isSBReportingLicensed);

  const pushHistoryItem = useCallback(
    url => {
      history.push(url);
    },
    [history]
  );

  const [openHelp] = useOpenHelp();
  const openHistoryClearModal = useOpenHistoryClearModal();

  const menuList = useMemo(
    () =>
      [
        {
          id: "menu_help",
          content: <FormattedMessage id="help" />,
          action: () => openHelp()
        },
        isReportingLicensed && {
          id: "menu_clear-data",
          content: <FormattedMessage id="clear_data" />,
          className: "warning",
          action: () => openHistoryClearModal()
        },
        {
          id: "menu_account",
          content: <FormattedMessage id="account" />,
          action: () => pushHistoryItem("/settings/account")
        },
        createDivider(),
        portalName &&
          createTitle(
            <Truncate lines={3} width={160} trimWhitespace={true}>
              {portalName}
            </Truncate>,
            "menu_business-name"
          ),
        {
          id: "menu_logout",
          content: <FormattedMessage id="logout" />,
          action: () => logout()
        }
      ].filter(Boolean),
    [
      openHelp,
      isReportingLicensed,
      openHistoryClearModal,
      portalName,
      logout,
      pushHistoryItem
    ]
  );

  return (
    <Dropdown
      className="user-menu"
      menuAriaLabel={translate("aria_primary_navigation")}
      toggleButton={
        <UnstyledButton className="user-menu__toggle">
          {subscriberName} <Icon icon="fas fa-caret-down" />
        </UnstyledButton>
      }
      items={menuList}
      disableHorizontalPosition
    />
  );
};

export default UserMenu;
