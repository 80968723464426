import React from "react";
import PropTypes from "prop-types";

export const TFoot = ({ renderGlobalFooter, tableProps }) => {
  const { visibleColumns } = tableProps;

  //TODO: May be better provide to renderGlobalFooter only needed props,
  // because now we are using this functionality only in this way for now.

  return (
    <tfoot>
      <tr className="table-footer">
        <td colSpan={visibleColumns.length}>
          {renderGlobalFooter(tableProps)}
        </td>
      </tr>
    </tfoot>
  );
};

TFoot.propTypes = {
  renderGlobalFooter: PropTypes.func.isRequired,
  tableProps: PropTypes.shape({
    visibleColumns: PropTypes.array.isRequired
  }).isRequired
};
