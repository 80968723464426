import _ from "lodash";
import {
  LOAD_ATTRIBUTES_SUCCESS,
  CHANGE_LANGUAGE_SUCCESS,
  CHANGE_TIME_FORMAT_SUCCESS,
  CHANGE_TIMEZONE_SUCCESS,
  CHANGE_ATTRIBUTE,
  LOAD_TIMEZONES_SUCCESS,
  SAVE_ATTRIBUTE_SUCCESS,
  DISCARD_SCHEDULED_REPORTS_SETTINGS
} from "./attributes.actions";
import { DEFAULT_FREQUENCY, DEFAULT_TIME_ZONE } from "./attributes.constants";
import { GET_ACCOUNT_SUCCESS } from "../account/account.types";

/*
  interface AccountSettings {
    language: string | null;
    is24hourFormat: boolean;
    timezone: string;
    email: ScheduledReports;
    toSave: {
        email: ScheduledReports;
    }
    timezones: any[];
  }
*/

/*
  interface ScheduledReports {
    address: string;
    frequency: string | null;
  }
*/

const initialState = {
  language: null,
  is24hourFormat: false,
  timezone: DEFAULT_TIME_ZONE,
  email: {
    address: "",
    frequency: DEFAULT_FREQUENCY
  },
  toSave: {
    email: {
      address: "",
      frequency: DEFAULT_FREQUENCY
    }
  },
  timezones: []
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ATTRIBUTES_SUCCESS:
      const {
        attributes: {
          address,
          frequency,
          language,
          is24hourFormat,
          businessName
        }
      } = action.payload;

      return {
        ...state,
        language,
        is24hourFormat,
        email: { address, frequency },
        toSave: {
          email: { address, frequency }
        },
        businessName
      };
    case GET_ACCOUNT_SUCCESS: {
      const { timezone } = action.payload;
      return _.isEmpty(timezone) ? state : { ...state, timezone };
    }
    case LOAD_TIMEZONES_SUCCESS:
      return { ...state, timezones: action.payload };
    case CHANGE_TIMEZONE_SUCCESS:
      return { ...state, timezone: action.payload };
    case CHANGE_TIME_FORMAT_SUCCESS:
      return { ...state, is24hourFormat: action.payload };
    case CHANGE_LANGUAGE_SUCCESS:
      return { ...state, language: action.payload };
    // TODO: use as a generic action for all attributes
    //  requires state shape changes
    case CHANGE_ATTRIBUTE: {
      return {
        ...state,
        toSave: {
          email: {
            ...state.toSave.email,
            ...action.payload
          }
        }
      };
    }
    case SAVE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        email: {
          ...state.email,
          ...action.payload.attribute
        }
      };
    case DISCARD_SCHEDULED_REPORTS_SETTINGS: {
      return {
        ...state,
        toSave: {
          email: {
            ...state.email
          }
        }
      };
    }
    default:
      return state;
  }
};
