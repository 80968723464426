import _ from "lodash";
import React, { Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouteMatch } from "react-router-dom";

import { Icon } from "../../../components/icon/Icon";
import { openEditProfileModal } from "../../../store/profiles/profile.actions";
import {
  openAddDialog,
  openConfirmationDialog
} from "../../../store/profiles/profiles.actions";
import {
  getWizardSelectedProfileId,
  getVisibleProfilesWithKeys,
  isValidProfileId
} from "../../../store/profiles/profiles.selectors";
import { Protection } from "@sportal/api";

export const useGetProfileNames = () => {
  const intl = useIntl();
  const profiles = useSelector(
    getVisibleProfilesWithKeys(["name", "type"]),
    _.isEqual
  );

  return useMemo(
    () =>
      _.map(profiles, profile =>
        profile.type === Protection.Custom
          ? profile.name
          : intl.formatMessage({ id: profile.type })
      ),
    [profiles, intl]
  );
};

export const useEditProfileItem = profile => {
  const dispatch = useDispatch();
  const profileNames = useGetProfileNames();

  return {
    id: "menu_rename",
    mobileContent: <Icon icon="far fa-pencil" />,
    content: (
      <Fragment>
        <Icon icon="far fa-pencil" />
        <FormattedMessage id="rename" />
      </Fragment>
    ),
    action: () => dispatch(openEditProfileModal(profileNames, profile))
  };
};

export const useDeleteProfileItem = profile => {
  const dispatch = useDispatch();

  return {
    id: "menu_delete",
    mobileContent: <Icon icon="far fa-trash" />,
    content: (
      <Fragment>
        <Icon icon="far fa-trash" />
        <FormattedMessage id="delete" />
      </Fragment>
    ),
    action: () => dispatch(openConfirmationDialog(profile))
  };
};

export const useAddProfileAction = () => {
  const dispatch = useDispatch();
  const profileNames = useGetProfileNames();

  return {
    id: "menu_add",
    mobileContent: (
      <Fragment>
        <Icon icon="far fa-plus-circle" />
        <span>
          <FormattedMessage id={"create_new"} />
        </span>
      </Fragment>
    ),
    content: <FormattedMessage id={"create_new"} />,
    action: () => dispatch(openAddDialog(profileNames))
  };
};

export const useSelectedProfileId = () => {
  const match = useRouteMatch({
    path: "/settings/:page/:profileId"
  });
  const profileIdFromUrl = match && match.params.profileId;
  const isValidId = useSelector(isValidProfileId(profileIdFromUrl));
  const wizardProfileId = useSelector(state =>
    getWizardSelectedProfileId(state)
  );

  return isValidId ? profileIdFromUrl : wizardProfileId;
};
