import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";

import { useIntlTranslate } from "../../../../hooks";

import { Mobile, NotMobile } from "../../../../hocs/Responsive";
import { Table } from "../../../../components/table/Table";
import {
  COLUMNS_MOBILE,
  COLUMNS_MOBILE_SINGLE,
  COLUMNS_NOT_MOBILE,
  COLUMNS_NOT_MOBILE_SINGLE
} from "./DomainLookupTable.constants";
import { getTableData } from "../../store";
import { isMultipleUserLevel } from "../../../../store/account";
import { isFixed } from "../../../../store/profiles/fixedProfiles";

export const DomainLookupTable = ({ className }) => {
  const isMultiple = useSelector(isMultipleUserLevel);
  const data = useSelector(getTableData, _.isEqual);
  const translate = useIntlTranslate();

  if (_.isNil(data)) return null;

  const mappedData = mapTableData(data, translate);

  return (
    <div className={className}>
      <Mobile>
        <Table
          data={mappedData}
          visibleHeader={false}
          noDataMessageID={"website_not_categorized"}
          columns={isMultiple ? COLUMNS_MOBILE : COLUMNS_MOBILE_SINGLE}
        />
      </Mobile>
      <NotMobile>
        <Table
          data={mappedData}
          noDataMessageID={"website_not_categorized"}
          columns={isMultiple ? COLUMNS_NOT_MOBILE : COLUMNS_NOT_MOBILE_SINGLE}
        />
      </NotMobile>
    </div>
  );
};

const mapTableData = (tableData, translate) => {
  return _.map(tableData, data => ({
    ...data,
    name: translate(`category_${data.category.name}`),
    associatedProfiles: _.map(data.associatedProfiles, ({ name }) => {
      return isFixed(name) ? translate(name) : name;
    })
  }));
};

DomainLookupTable.propTypes = {
  className: PropTypes.string
};
