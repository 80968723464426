import _ from "lodash";
import {
  getAddedItems,
  getRemovedItems
} from "../../../../store/profiles/profiles.selectors";

export const DAYS = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

export const SCHEDULE_TYPES = {
  HOMEWORK: "homework",
  INTERNET_OFF: "internet-off"
};

export const SCHEDULE_LIMIT = 7;

const STORED_FIELDS = [
  "name",
  "type",
  "enabled",
  "categories",
  "day",
  "timeEnd",
  "timeStart"
];

export const getSelectedDays = (schedules = []) =>
  _.reduce(schedules, (acc, s) => [...acc, ...s.day], []);

export const getAvailableDays = (schedules = [], firstDayOfWeek = DAYS[0]) =>
  _.difference(getDaysShifted(firstDayOfWeek), getSelectedDays(schedules));

export const getDaysShifted = firstDayOfWeek => {
  const shiftedDays = [...DAYS],
    index = shiftedDays.indexOf(firstDayOfWeek),
    secondHalf = shiftedDays.splice(0, index);

  return [...shiftedDays, ...secondHalf];
};

export const getAvailableName = (type, existingNames, index = 0) => {
  if (_.includes(existingNames, `${type}${index}`)) {
    return getAvailableName(type, existingNames, ++index);
  }
  return `${type}${index}`;
};

//get all profile schedules of specific type
export const getProfileSchedules = (schedules, profile, type) => {
  const profileSchedules = _.filter(
    _.values(getCurrentSchedules(schedules).list),
    { profile }
  );

  return !type ? profileSchedules : _.filter(profileSchedules, { type });
};

//determine if schedules of specific types are active
export const getScheduleTypeActive = (schedules, profile, type) => {
  const items = getProfileSchedules(schedules, profile, type);

  return (
    !_.isEmpty(items) &&
    _.reduce(items, (acc, item) => acc && item.enabled, true)
  );
};

export const getCurrentSchedules = schedules => _.cloneDeep(schedules.changed);

export const dehydrateSchedule = schedule => _.pick(schedule, STORED_FIELDS);

//ignore a new schedule during save if:
//1) it is disabled
//2) there are no schedules with the same type and profile among saved
//these schedules are needed to display them on UI when there are no schedules of specific type and profile
export const getIgnoredScheduleIds = ({ saved, changed }) => {
  const ignored = _.filter(
    getAddedItems({ saved, changed }),
    ({ type, profile, enabled }) =>
      !enabled && !_.some(saved.list, { type, profile })
  );

  return _.map(ignored, "id");
};
// TODO: following 2 functions are similar to the ones in profiles,
//  so it would be nice to extract this functionality
const getChangedItems = ({ saved, changed }, stored) =>
  _.reduce(
    changed.list,
    (acc, item) => {
      if (
        saved.list[item.id] &&
        !_.isEmpty(getChangedFields(item.id, { saved, changed }, stored))
      ) {
        acc.push(item);
      }
      return acc;
    },
    []
  );

const getChangedFields = (id, { saved, changed }, stored) => {
  const value = changed.list[id];
  const other = saved.list[id];

  return _.transform(
    stored,
    (diff, field) => {
      if (!_.isEqual(value[field], other[field])) {
        diff[field] = value[field];
      }
    },
    {}
  );
};

//according to existing logic we remove schedules without days
export const getChangedSchedules = schedules =>
  _.filter(
    getChangedItems(schedules, STORED_FIELDS),
    ({ day }) => !_.isEmpty(day)
  );

export const getRemovedSchedules = schedules => {
  const ignored = getIgnoredScheduleIds(schedules);
  const schedulesWithoutDays = _.filter(schedules.changed.list, ({ day }) =>
    _.isEmpty(day)
  );
  const removed = _.union(getRemovedItems(schedules), schedulesWithoutDays);

  return _.filter(removed, ({ id }) => !_.includes(ignored, id));
};

export const getAddedSchedules = ({ saved, changed }) => {
  const added = getAddedItems({ saved, changed });
  const ignored = getIgnoredScheduleIds({ saved, changed });

  return _.filter(added, ({ id }) => !_.includes(ignored, id));
};

export const schedulesChanged = (id, schedules) => {
  const added = getAddedSchedules(schedules);
  const changed = getChangedSchedules(schedules);
  const removed = getRemovedSchedules(schedules);

  return !_.isEmpty(changed) || !_.isEmpty(added) || !_.isEmpty(removed);
};

export const getValidationErrors = ({ changed }) => {
  const errors = [];
  const enabledWithoutDays = _.find(
    changed.list,
    ({ enabled, day }) => enabled && _.isEmpty(day)
  );
  const enabledWithoutCategories = _.find(
    changed.list,
    ({ enabled, categories, type }) =>
      enabled && _.isEmpty(categories) && type === SCHEDULE_TYPES.HOMEWORK
  );

  if (enabledWithoutDays) {
    errors.push({
      type: "validation",
      message:
        enabledWithoutDays.type === SCHEDULE_TYPES.INTERNET_OFF
          ? "invalid_internet_off_schedule"
          : "invalid_restrict_content_schedule"
    });
  }

  if (enabledWithoutCategories) {
    errors.push({
      type: "validation",
      message: "content_restriction_categories_error"
    });
  }

  return errors;
};
