import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { useIntlTranslate } from "../../../hooks";
import { Icon } from "../../../components/icon/Icon";
import { Button } from "../../../components/button/Button";

export const SingleUserChoice = () => {
  const translate = useIntlTranslate();

  return (
    <div className="col-xs-12 col-md-6 profile-container">
      <div className="image-container profile-single-ico bg-color-primary">
        <Icon icon="fas fa-suitcase" />
      </div>
      <div className="description-container">
        <div className="option-title">
          <FormattedMessage id={"single_profile"} />
        </div>
        <div className="option-subtitle">
          <FormattedMessage id={"single_profile_info"} />
        </div>
        <div className="option-description">
          <FormattedMessage id={"single_profile_description"} />
        </div>
      </div>
      <Button
        as={Link}
        to={"/wizard/single"}
        size={"large"}
        aria-label={translate("aria_proceed_to_single")}
      >
        <FormattedMessage id={"lets_go"} />
      </Button>
    </div>
  );
};
