import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";

import { TabletMobile, Desktop } from "../../../hocs/Responsive";
import { Select } from "../../select";
import {
  TimePeriodSegmentedButtons,
  periods
} from "../../timePeriodSegmentedButtons/TimePeriodSegmentedButtons";
import { TimeRange } from "../../time/timeRange/TimeRange";
import { useIntlTranslate } from "../../../hooks";

import "./TimePeriodSelector.scss";

const TimePeriodSelector = ({ showSelector, period, onChange }) => {
  const translate = useIntlTranslate();

  const items = useMemo(() => {
    return periods.map(period => ({
      content: translate(period.name),
      value: period.value
    }));
  }, [translate]);

  return (
    <div className="time-period">
      {showSelector && (
        <Fragment>
          <TabletMobile>
            <TimePeriodSegmentedButtons
              selectedValue={period}
              onChange={e => onChange(e.target.value)}
            />
          </TabletMobile>
          <Desktop>
            <Select
              items={items}
              selected={period}
              onChange={onChange}
              ariaLabel={translate("aria_time_period")}
              small
            />
          </Desktop>
        </Fragment>
      )}
      <TimeRange className="time-period__info" period={period} />
    </div>
  );
};

TimePeriodSelector.propTypes = {
  showSelector: PropTypes.bool,
  period: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default TimePeriodSelector;
