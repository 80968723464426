import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import _ from "lodash";

import "./AccountSettingsPage.scss";

import { withLayout } from "../../components/layout/Layout";
import ProgressButton from "../../components/progressButton/ProgressButton";
import { Checkbox } from "../../components/checkbox/Checkbox";
import TimezonePicker from "./components/timezonePicker/TimezonePicker";
import LanguagePicker from "./components/languagePicker/LanguagePicker";
import { error } from "../../components/notification/notification.actions";
import { SectionHeader } from "../profiles/components/sectionHeader/SectionHeader";
import {
  changeLanguage,
  changeTimeFormat,
  changeTimezone,
  getTimezones
} from "../../store/attributes/attributes.actions";
import { remap } from "../../utils/remap";

class AccountSettingsPage extends Component {
  state = {
    selectedTimezone: null,
    is24hourFormat: false
  };

  componentDidMount() {
    this.props.getTimezones();
    const { accountSettings } = this.props;

    const selectedLanguage = accountSettings.language;
    const selectedTimezone = accountSettings.timezone;
    const is24hourFormat =
      accountSettings.is24hourFormat || this.state.is24hourFormat;

    this.setState({ selectedLanguage, selectedTimezone, is24hourFormat });
  }

  componentDidUpdate(prevProps) {
    const { timezone, is24hourFormat, language } = this.props.accountSettings;

    if (is24hourFormat !== prevProps.accountSettings.is24hourFormat) {
      this.setState({ is24hourFormat });
    }

    if (timezone !== prevProps.accountSettings.timezone) {
      this.setState({ selectedTimezone: timezone });
    }

    if (!this.state.selectedLanguage && language) {
      this.setState({ selectedLanguage: language });
    }
  }

  handleTimezoneChange = selectedTimezone => {
    this.setState({ selectedTimezone });
  };

  handleLanguageChange = selectedLanguage => {
    this.setState({ selectedLanguage });
  };

  handleTimeFormatChange = () => {
    this.setState({
      is24hourFormat: !this.state.is24hourFormat
    });
  };

  save = () => {
    let promise = Promise.resolve();
    const { changeTimezone, changeLanguage, changeTimeFormat } = this.props;
    const { selectedTimezone, selectedLanguage, is24hourFormat } = this.state;

    if (this.isTimezoneChanged()) {
      promise = promise.then(() => changeTimezone(selectedTimezone));
    }
    if (this.isLanguageChanged()) {
      promise = promise.then(() => changeLanguage(selectedLanguage));
    }
    if (this.isFormatChanged()) {
      promise = promise.then(() => changeTimeFormat(is24hourFormat));
    }

    return promise;
  };

  isTimezoneChanged = () =>
    this.props.accountSettings.timezone !== this.state.selectedTimezone;
  isLanguageChanged = () =>
    this.props.accountSettings.language !== this.state.selectedLanguage;
  isFormatChanged = () =>
    this.props.accountSettings.is24hourFormat !== this.state.is24hourFormat;
  isFormChanged = () =>
    this.isTimezoneChanged() ||
    this.isLanguageChanged() ||
    this.isFormatChanged();

  errorMessage = message => {
    return this.props.error(
      <Fragment>
        <span className="bold">
          <FormattedMessage id={"couldnt_save_changes"} />
        </span>
        <br />
        <FormattedMessage id={message} />
      </Fragment>
    );
  };

  render() {
    return (
      <div className="user-settings-page row">
        <div className="col-12 col-md-6">
          <h2 className="user-settings-page__header">
            <FormattedMessage id={"account"} />
          </h2>

          <section className="time-section">
            <SectionHeader
              description={<FormattedMessage id={"timezone_description"} />}
            >
              <h3>
                <FormattedMessage id={"time"} />
              </h3>
            </SectionHeader>

            <TimezonePicker
              onChange={this.handleTimezoneChange}
              selected={this.state.selectedTimezone}
              timezones={this.props.accountSettings.timezones}
            />

            <Checkbox
              onChange={this.handleTimeFormatChange}
              isChecked={this.state.is24hourFormat}
            >
              <FormattedMessage id={"24_hour_format"} />
            </Checkbox>
          </section>

          <section className="language-section">
            <SectionHeader>
              <h3>
                <FormattedMessage id={"language"} />
              </h3>
            </SectionHeader>

            <LanguagePicker
              languages={this.props.languages}
              selected={this.state.selectedLanguage}
              onChange={this.handleLanguageChange}
            />
          </section>

          <ProgressButton
            className="user-settings-page__form__send-button"
            label={<FormattedMessage id={"save"} />}
            onClick={this.save}
            disabled={!this.isFormChanged()}
          />
        </div>
      </div>
    );
  }
}

export default _.flowRight(
  withLayout(),
  connect(
    ({ config, accountSettings }) => ({
      languages: _.map(config.languages.list, language =>
        remap(language, { key: "value" })
      ),
      accountSettings
    }),
    {
      error,
      changeLanguage,
      changeTimezone,
      changeTimeFormat,
      getTimezones
    }
  )
)(AccountSettingsPage);
