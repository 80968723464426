import React, { memo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { isIPad } from "../../../../helpers/operatingSystems.helper";
import { Button } from "../../../../components/button/Button";
import { Icon } from "../../../../components/icon/Icon";
import { getCSVUrl } from "../../drilldown.selectors";
import { DrilldownRoutePage } from "../../drilldown.types";
import { useIntlTranslate } from "../../../../hooks";

function getAttributes() {
  if (isIPad()) {
    return {
      target: "_blank"
    };
  }

  return {
    download: true
  };
}

interface Props {
  page: DrilldownRoutePage;
}

function CsvDownloadContent({ page }: Props) {
  const translate = useIntlTranslate();
  const href = useSelector(getCSVUrl(page));

  return (
    <Button
      as={"a"}
      href={href}
      {...getAttributes()}
      isOutline
      size={"large"}
      aria-label={translate("aria_export_all_data")}
    >
      <Icon icon={"far fa-download color-secondary"} />
      <span>
        <FormattedMessage id={"export_raw_data"} />
      </span>
    </Button>
  );
}

export const CsvDownload = memo(CsvDownloadContent);

CsvDownloadContent.propTypes = {
  page: PropTypes.string.isRequired
};
