import { BaseApiService } from "../baseApiService";

export class ThreatsService extends BaseApiService {
  constructor(config) {
    super(config);

    this.threatsUrl = `${config.backendUrl}/ssm/threat`;
  }

  getThreats(locale) {
    return this._http
      .get(`${this.threatsUrl}?locale=${locale}`)
      .then(({ data }) => data);
  }
}
