import {
  LOAD_INFO_SUCCESS,
  SET_AUTH_METHOD,
  InfoActionTypes
} from "./info.types";
import { AuthMethod, InfoServerData } from "@sportal/api";

export interface InfoState extends InfoServerData {
  authMethod: AuthMethod;
}

export const initialState = {} as InfoState;

export const reducer = (
  state: InfoState = initialState,
  action: InfoActionTypes
): InfoState => {
  switch (action.type) {
    case LOAD_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case SET_AUTH_METHOD:
      return {
        ...state,
        authMethod: action.payload
      };
    default:
      return state;
  }
};
