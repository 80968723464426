import { HttpServiceError, SBSharedProtection } from "@sportal/api";

export const LOAD_SHARED_PROTECTIONS_SUCCESS =
  "[PROTECTIONS] LOAD_SHARED_PROTECTIONS_SUCCESS";
export const LOAD_SHARED_PROTECTIONS_FAILURE =
  "[PROTECTIONS] LOAD_SHARED_PROTECTIONS_FAILURE";

export type SharedProtectionsSuccessPayload = SBSharedProtection[];

interface LoadSharedProtectionsSuccess {
  type: typeof LOAD_SHARED_PROTECTIONS_SUCCESS;
  payload: SharedProtectionsSuccessPayload;
}

interface LoadSharedProtectionsFailure {
  type: typeof LOAD_SHARED_PROTECTIONS_FAILURE;
  payload: HttpServiceError;
}

export type ProtectionsActionTypes =
  | LoadSharedProtectionsSuccess
  | LoadSharedProtectionsFailure;
