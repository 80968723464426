import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import _ from "lodash";

import { getInfectedDevicesForTable } from "../../../store/devices/devices.selectors";
import { Table } from "../../../components/table/Table";
import { DeviceWithType } from "../../../components/devices/DeviceWithType";
import { InfectionsList } from "../../../components/infectionsList/InfectionsList";
import { TableExpandableCard } from "../../../components/table/components/TableExpandableCard";
import { NotMobile, Mobile } from "../../../hocs/Responsive";
import {
  multipleThreatsSort,
  singleDeviceIdSort
} from "../../../components/table/helpers/sorting.helper";
import { ClickAbsorber } from "../../../components/clickAbsorber/ClickAbsorber";
import { InfectionsSummary } from "./InfectionsSummary";
import { InfectedDevicesActionsDropdown } from "./InfectedDevicesActionsDropdown";
import {
  DeviceNameCell,
  DeviceNameCellMobile
} from "../../../components/table";

const COLUMNS = [
  {
    Header: <FormattedMessage id={"device_name"} />,
    accessor: "name",
    Cell: ({
      row: {
        original: { name }
      }
    }) => <DeviceNameCell name={name} />
  },
  {
    Header: <FormattedMessage id={"device_id"} />,
    accessor: "id",
    Cell: ({
      row: {
        original: { type, id }
      }
    }) => {
      return <DeviceWithType type={type} identifier={id} />;
    },
    sortType: singleDeviceIdSort
  },
  {
    Header: <FormattedMessage id={"infections"} />,
    sortType: multipleThreatsSort,
    accessor: "flatInfections",
    Cell: ({
      row: {
        original: { infections }
      }
    }) => <InfectionsList infections={infections} />
  },
  {
    id: "actions",
    sortable: false,
    headerClassName: "table__cell--small-cell",
    Header: <FormattedMessage id={"actions"} />,
    cellClassName: "table__cell--center table__cell--overflow",
    Cell: ({
      row: {
        original: { type, id, manufacturer, name }
      }
    }) => (
      <InfectedDevicesActionsDropdown
        device={{ type, manufacturer, name, identifiers: [id] }}
      />
    )
  }
];

const COLUMNS_MOBILE = [
  {
    accessor: "name",
    cellClassName: "table__cell--overflow",
    SortingLabel: <FormattedMessage id={"device_name"} />,
    Cell: ({
      row: {
        isExpanded,
        original: { type, manufacturer, name, id, infections }
      }
    }) => (
      <TableCard
        isExpanded={isExpanded}
        type={type}
        manufacturer={manufacturer}
        name={name}
        id={id}
        infections={infections}
      />
    ),
    sortType: singleDeviceIdSort
  },
  {
    accessor: "id",
    SortingLabel: <FormattedMessage id={"device_id"} />
  },
  {
    accessor: "flatInfections",
    sortType: multipleThreatsSort,
    SortingLabel: <FormattedMessage id={"infections"} />
  }
];

const SEARCH_KEYS = ["flatInfections", "name", "id"];

const initialSort = [{ id: "flatInfections", desc: true }];

const SUB_HEADER = <FormattedMessage id={"showing_data_for_the_past_24"} />;

export const InfectedDevicesTable = () => {
  const devices = useSelector(getInfectedDevicesForTable, _.isEqual);

  return (
    <Fragment>
      <Mobile>
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          showMobileSort
          visibleHeader={false}
          data={devices}
          columns={COLUMNS_MOBILE}
          hiddenColumns={["id", "flatInfections"]}
          renderRowSubComponent={renderRowSubComponent}
          defaultSorted={initialSort}
          subHeader={SUB_HEADER}
          searchAriaLabelMessage="aria_search_for_device"
        />
      </Mobile>
      <NotMobile>
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          columns={COLUMNS}
          data={devices}
          subHeader={SUB_HEADER}
          defaultSorted={initialSort}
          searchAriaLabelMessage="aria_search_for_device"
        />
      </NotMobile>
    </Fragment>
  );
};

const TableCard = ({
  isExpanded,
  type,
  manufacturer,
  name,
  id,
  infections
}) => {
  return (
    <TableExpandableCard
      isExpanded={isExpanded}
      content={
        <Fragment>
          <DeviceNameCellMobile name={name} />
          <DeviceWithType type={type} identifier={id} />
          <InfectionsSummary infections={infections} />
        </Fragment>
      }
      aside={
        <ClickAbsorber>
          <InfectedDevicesActionsDropdown
            device={{ type, manufacturer, name, identifiers: [id] }}
          />
        </ClickAbsorber>
      }
    />
  );
};

const renderRowSubComponent = ({
  row: {
    original: { infections }
  }
}) => {
  return (
    <div className={"sub-row-content"} data-testid="infected-device-sub-row">
      <InfectionsList infections={infections} />
    </div>
  );
};
