import { ResourceProvider, ResourceType } from "./resource.provider";
import { failure, Result, success } from "../shared";

export enum TranslationServiceErrorCodes {
  GENERIC
}

type Translations = { [key: string]: string };

export class TranslationService {
  constructor(private provider: ResourceProvider) {}

  public async get(localeKey: string): Promise<Result<Translations>> {
    try {
      const { data } = await this.provider.get<Translations>(
        `translations/${localeKey}`,
        ResourceType.JSON
      );
      return success(data);
    } catch (error) {
      return failure(TranslationServiceErrorCodes.GENERIC);
    }
  }
}
