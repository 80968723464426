import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { getProfileByName } from "../../store/profiles/profiles.selectors";

export const FormattedProfileName = ({ profileName }) => {
  const profile = useSelector(getProfileByName(profileName), _.isEqual);

  if (_.isEmpty(profile)) return "-";

  return profile.type !== "custom" ? (
    <FormattedMessage id={profile.type} />
  ) : (
    profile.name
  );
};
