import React, { Fragment, useCallback } from "react";
import _ from "lodash";

import { Table } from "../../../../components/table/Table";
import { DrilldownRoutePage } from "../../drilldown.types";
import { initialSort, SEARCH_KEYS } from "./DrilldownTable.constants";
import {
  MOBILE_COLUMNS_MULTIPLE,
  MOBILE_COLUMNS_SINGLE,
  MOBILE_SUBROWS,
  MOBILE_SUBROWS_SINGLE,
  MOBILE_HIDDEN_COLUMNS
} from "./DrilldownMobileTable.constants";

function getColumns(page, isMultiple) {
  const COLUMNS = isMultiple ? MOBILE_COLUMNS_MULTIPLE : MOBILE_COLUMNS_SINGLE;

  return COLUMNS[page];
}

export const DrilldownTableMobile = ({ page, isMultiple, data }) => {
  const columns = getColumns(page, isMultiple);

  const subRowItems = isMultiple
    ? MOBILE_SUBROWS[page]
    : MOBILE_SUBROWS_SINGLE[page];

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <div className={"sub-row-content"}>
        {_.map(subRowItems, item => (
          <Fragment key={item.accessor}>
            <div className={"sub-row-content__header"}>{item.header}</div>
            <div className="sub-row-content__data">
              {_.get(row.original, item.accessor) || "-"}
            </div>
          </Fragment>
        ))}
      </div>
    ),
    [subRowItems]
  );

  const isExpandable = page === DrilldownRoutePage.Webfilter || isMultiple;

  return (
    <Table
      columns={columns}
      data={data}
      defaultSorted={[initialSort]}
      hiddenColumns={MOBILE_HIDDEN_COLUMNS[page]}
      renderRowSubComponent={isExpandable && renderRowSubComponent}
      searchable
      searchKeys={SEARCH_KEYS}
      showMobileSort
      showPagination
      visibleHeader={false}
      searchAriaLabelMessage="aria_search_for_drilldown_item"
    />
  );
};
