import React from "react";
import PropTypes from "prop-types";

import { Tooltip } from "./Tooltip";
import { useTooltipEvents } from "./useTooltipEvents";
import { PLACEMENTS, usePopperAPI } from "./usePopperAPI";

export const RegularTooltip = ({
  placement,
  modifiers,
  showTooltip,
  className,
  content,
  disableEvents,
  children,
  ...attrs
}) => {
  const [isTooltipVisible, toggleStateEvents] = useTooltipEvents();
  const {
    setReferenceElement,
    setPopperElement,
    setArrowElement,
    styles,
    attributes
  } = usePopperAPI(placement, modifiers);

  const events = disableEvents ? {} : toggleStateEvents;

  return (
    <div
      className="tooltip-referrer"
      {...events}
      {...attrs}
      ref={setReferenceElement}
    >
      {children}

      {(isTooltipVisible || showTooltip) && (
        <Tooltip
          className={className}
          setPopperElement={setPopperElement}
          setArrowElement={setArrowElement}
          styles={styles}
          attributes={attributes}
        >
          {content}
        </Tooltip>
      )}
    </div>
  );
};

RegularTooltip.propTypes = {
  placement: PropTypes.oneOf(PLACEMENTS),
  modifiers: PropTypes.array,
  showTooltip: PropTypes.bool,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  disableEvents: PropTypes.bool,
  children: PropTypes.node.isRequired
};
