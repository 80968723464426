import React, { useCallback, useRef, useEffect } from "react";
import * as d3 from "d3";

import { useBarchartTooltip } from "../BarChartTooltip";
import { useBarChartContext } from "../../BarChartProvider";

export const HEIGHT_TRANSITION_DURATION = 1000;

export const Bars = ({ data, type }) => {
  const { topOffset } = useBarChartContext();
  const { select, unselect } = useBarchartTooltip(state => ({
    select: state.select,
    unselect: state.unselect
  }));

  const wrapper = useRef(null);

  const removeBars = useCallback(barsWrapper => {
    barsWrapper.selectAll("rect").remove();
  }, []);

  const drawBars = useCallback(
    barsWrapper => {
      barsWrapper
        .selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("class", (d, i) => `bar rect-stack${i}`)
        .attr("x", d => d.position.x)
        .attr("y", topOffset)
        .attr("width", d => d.position.width)
        .attr("height", 0)
        .on("mousedown", e => {
          const { target } = e;
          //TODO: This function is for passing MouseEvent to the next layer svg, for now it's done for
          // brush (we need to be aware that if we add one more layer between this component and brush,
          // brush will be broken)
          target.setAttribute("pointer-events", "none");

          const brushLayer = document.elementFromPoint(e.x, e.y);
          const mouseEvent = new MouseEvent(e.type, e);

          brushLayer.dispatchEvent(mouseEvent);
          target.setAttribute("pointer-events", "all");
        })
        .on("mouseover", function() {
          const i = getBarIndex.call(this, barsWrapper);
          select(i);
          d3.selectAll(`.rect-stack${i}`).classed("rect-stack--hover", true);
        })
        .on("mouseout", function() {
          const i = getBarIndex.call(this, barsWrapper);
          unselect();
          d3.selectAll(`.rect-stack${i}`).classed("rect-stack--hover", false);
        });
    },
    [data, topOffset, select, unselect]
  );

  const animateBars = useCallback(
    barsWrapper => {
      barsWrapper
        .selectAll("rect")
        .data(data)
        .transition()
        .duration(HEIGHT_TRANSITION_DURATION)
        .ease(d3.easeCubicOut)
        .attr("y", d => d.position.y[type])
        .attr("height", d => d.position.height[type]);
    },
    [data, type]
  );

  const interruptAnimation = useCallback(barsWrapper => {
    return () => barsWrapper.selectAll("rect").interrupt();
  }, []);

  useEffect(() => {
    const barsWrapper = d3.select(wrapper.current);

    removeBars(barsWrapper);
    drawBars(barsWrapper);
    animateBars(barsWrapper);

    return interruptAnimation(barsWrapper);
  }, [animateBars, drawBars, interruptAnimation, removeBars]);

  return (
    <g className={`${type}-group`} role="graphics-datagroup" ref={wrapper} />
  );
};

function getBarIndex(selection) {
  const { childNodes } = selection.nodes()[0];
  const barNode = this;

  return Array.from(childNodes).indexOf(barNode);
}
