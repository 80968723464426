import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useMount } from "@sportal/cdk/hooks";
import { Icon } from "../icon/Icon";
import { Tooltip } from "../tooltip/Tooltip";

import {
  getRoamingDevicesCount,
  getRoamingLimit
} from "../../store/devices/devices.selectors";
import { fetchRoamingLimit } from "../../store/devices/devices.actions";
import { useIntlTranslate } from "../../hooks";

import "./RoamingCounter.scss";

export function RoamingCounter() {
  const count = useSelector(getRoamingDevicesCount);
  const limit = useSelector(getRoamingLimit);
  const dispatch = useDispatch();
  useMount(() => {
    dispatch(fetchRoamingLimit());
  });
  const translate = useIntlTranslate();

  if (!limit) return null;

  const tooltipText = translate("roaming_counter_tooltip");
  const ariaText = translate("roaming_counter_tooltip_aria", {
    count,
    limit
  });

  return (
    <div className="roaming-counter" aria-label={ariaText}>
      <Tooltip theme="dark" content={tooltipText} tabIndex={0} role="tooltip">
        <RoamingCounterIcon />
        <span aria-hidden="true">
          {count}/{limit}
        </span>
      </Tooltip>
    </div>
  );
}

function RoamingCounterIcon() {
  return (
    <span className="roaming-counter-icon" aria-hidden="true">
      <Icon icon="far fa-signal roaming-counter-icon__bars" />
    </span>
  );
}
