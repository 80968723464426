import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import _ from "lodash";

import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog
} from "../../../../components/modal";
import { AddDeviceByMac } from "./AddDeviceByMac";
import { AddDeviceByCode } from "./AddDeviceByCode";
import { TabList, createDeviceTab } from "../../../../components/tabList";

import "./AddDeviceDialog.scss";

const TABS = [
  { value: "code", name: "using_code" },
  { value: "mac", name: "using_mac" }
];

const getSelectedProfile = (profiles, current) =>
  current || _.find(profiles, ({ isDisabled }) => !isDisabled).value;

class AddDeviceDialog extends Component {
  constructor(props) {
    super(props);

    const { profiles, currentProfile } = props;

    this.state = {
      tab: "code",
      errorName: "",
      errorAddress: "",
      profile: getSelectedProfile(profiles, currentProfile),
      name: "",
      address: ""
    };
  }

  render() {
    const { modal, intl } = this.props;

    return (
      <ModalDialog
        className="add-device-modal"
        submitHandler={this.onSubmit}
        rejectHandler={() => modal.reject()}
        disabled={this.isSubmitDisabled()}
      >
        <CommonModalHeader>
          <FormattedMessage id="add_device" />
        </CommonModalHeader>
        <ModalDialog.Body>
          <TabList
            items={TABS.map(tab => createDeviceTab(tab, intl))}
            selected={this.state.tab}
            onClick={tab => this.handleTabChange(tab)}
          />
          {this.renderBody()}
        </ModalDialog.Body>
        <CommonModalFooter
          submitMessage={<FormattedMessage id="add_device" />}
        />
      </ModalDialog>
    );
  }

  renderBody() {
    const props = {
      profiles: this.props.profiles,
      intl: this.props.intl,
      devices: this.props.allDevices,
      onProfileChange: val => this.handleProfileChange(val),
      onNameChange: (val, error) => this.handleNameChange(val, error),
      onAddressChange: (val, error) => this.handleAddressChange(val, error),
      ...this.state
    };

    if (this.state.tab === "mac") {
      return <AddDeviceByMac className={"add-device-modal__mac"} {...props} />;
    }

    return <AddDeviceByCode className={"add-device-modal__code"} {...props} />;
  }

  handleTabChange(tab) {
    this.setState({
      tab,
      errorName: "",
      errorAddress: "",
      name: "",
      address: ""
    });
  }

  handleProfileChange(profile) {
    if (profile !== this.state.profile) {
      this.setState({ profile });
    }
  }

  handleNameChange(name, error) {
    if (name === this.state.name) return;

    this.setError("errorName", error);
    this.setState({ name });
  }

  handleAddressChange(address, error) {
    if (address === this.state.address) return;

    this.setError("errorAddress", error);
    this.setState({ address });
  }

  setError(field, error) {
    if (error) {
      this.setState({ [field]: this.props.intl.formatMessage({ id: error }) });
    } else {
      this.setState({ [field]: "" });
    }
  }

  onSubmit = () => {
    if (this.isSubmitDisabled()) return;

    const { modal } = this.props;
    const { address, name, profile } = this.state;

    return modal.resolve({
      identifiers: [address],
      name: _.trim(name),
      profile
    });
  };

  isSubmitDisabled = () => {
    const { address, errorAddress, name, errorName } = this.state;

    return !address || !name || errorAddress || errorName;
  };
}

AddDeviceDialog.propTypes = {
  profiles: PropTypes.array,
  profilesAvailable: PropTypes.array,
  currentProfile: PropTypes.string,
  allDevices: PropTypes.array,
  modal: PropTypes.object
};

export default injectIntl(AddDeviceDialog);
