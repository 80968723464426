import _ from "lodash";
import React, { Component, Fragment, useMemo } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import "./WebFilters.scss";

import { Protection } from "@sportal/api";
import { SectionHeader } from "../sectionHeader/SectionHeader";
import { ProtectionEditor } from "./protectionEditor/ProtectionEditor";
import { allow, block, load, setBlocked } from "./webFilters.actions";
import { getBlockedCategories } from "./webFilters.selectors";
import { changeProtection } from "../../../../store/profiles/profile.actions";
import ProtectionSearch from "./protectionSearch/ProtectionSearch";
import { ShowToggle } from "../../../../components/showToggle/ShowToggle";
import { Mobile, NotMobile } from "../../../../hocs/Responsive";
import NativeSelect from "../../../../components/select/NativeSelect";
import { ProtectionEditorDialogContainer } from "./protectionEditorDialog/ProtectionEditorDialogContainer";
import { Icon } from "../../../../components/icon/Icon";
import {
  getProfileById,
  getProfileCustomProtections
} from "../../../../store/profiles/profiles.selectors";
import {
  getCategories,
  getProtections
} from "../../../../store/root.selectors";
import { useSelectedProfileId } from "../hooks";
import { TabsSelect } from "../../../../components/tabsSelect/TabsSelect";

class WebFiltersComponent extends Component {
  state = {
    isOpen: true
  };

  componentDidMount() {
    this.props.load();
  }

  renderOpenToggle = () => (
    <ShowToggle
      isOpen={this.state.isOpen}
      toggleOpen={() => this.toggleOpen()}
    />
  );

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    return (
      <div className={classnames("web-filters", this.props.className)}>
        {/*there was a SectionHeader component; it was used for two totally different headers – main one and inner header (h1 and h3)*/}
        {/*component was replaced with simple html*/}
        <h3 className="web-filters__header">
          <Icon icon="fas fa-shield-alt" />
          <span>
            <FormattedMessage id={"web_filters"} />
          </span>
        </h3>
        <Mobile>{this.renderMobile()}</Mobile>
        <NotMobile>{this.renderDesktop()}</NotMobile>
      </div>
    );
  }

  renderMobile() {
    const {
      changeProtection,
      protections,
      selectedProtectionName,
      customProtections,
      profileId,
      blocked,
      allowed
    } = this.props;

    return (
      <Fragment>
        <div className="web-filters__tip">
          <FormattedMessage id={"protection_level_subtitle"} />
        </div>
        <NativeSelect
          id="protection-level-label"
          className="web-filters__protection-select"
          label="protection_level"
          selected={selectedProtectionName}
          onChange={protection => changeProtection(profileId, protection)}
          options={protections.map(option => ({
            ...option,
            isDisabled:
              option.name === Protection.Custom && _.isEmpty(customProtections),
            name: this.props.intl.formatMessage({ id: option.name })
          }))}
          toggleView="input"
          isOutline={true}
        />
        <ProtectionEditorDialogContainer
          categories={{ blocked, allowed }}
          {...this.props}
        />
      </Fragment>
    );
  }

  renderDesktop() {
    const {
      changeProtection,
      protections,
      selectedProtectionName,
      blocked,
      allowed,
      block,
      allow,
      customProtections,
      profileId
    } = this.props;

    return (
      <Fragment>
        <SectionHeader
          className=""
          description={<FormattedMessage id={"protection_level_subtitle"} />}
        >
          {this.renderOpenToggle()}
          <h4>
            <FormattedMessage id={"protection_level"} />
          </h4>
        </SectionHeader>
        {this.state.isOpen && (
          <Fragment>
            <div className="protection__tool-bar">
              <TabsSelect
                items={protections.map(option => ({
                  content: this.props.intl.formatMessage({ id: option.name }),
                  id: option.name,
                  config: {
                    disabled:
                      option.name === Protection.Custom &&
                      _.isEmpty(customProtections)
                  }
                }))}
                selected={selectedProtectionName}
                onClick={protection => changeProtection(profileId, protection)}
              />

              <ProtectionSearch
                inputId="categories-search"
                categories={{ blocked, allowed }}
                onBlock={block}
                onAllow={allow}
              />
            </div>
            <ProtectionEditor
              categories={{ blocked, allowed }}
              onBlock={block}
              onAllow={allow}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export const WebFilters = ({ className }) => {
  // todo: temporary solution, definitely needs further refactoring

  const dispatch = useDispatch();
  const intl = useIntl();
  const profileId = useSelectedProfileId();
  const customProtection = useSelector(
    getProfileCustomProtections(profileId),
    _.isEqual
  );
  const protections = useSelector(
    state => getProtections(state).keys,
    _.isEqual
  );
  const categories = useSelector(getCategories, _.isEqual);
  const profile = useSelector(getProfileById(profileId));
  const profileProtection = profile && profile.protection;

  const protection = useMemo(
    () =>
      _.includes(protections, profileProtection)
        ? profileProtection
        : Protection.Custom,
    [protections, profileProtection]
  );

  const blockedCategories = useSelector(
    getBlockedCategories(profileId, profileProtection),
    _.isEqual
  );

  const [blocked, allowed] = useMemo(
    () =>
      _.partition(categories, ({ name }) =>
        _.includes(blockedCategories, name)
      ),
    [categories, blockedCategories]
  );

  const mappedProtections = useMemo(
    () => _.map(protections, name => ({ name, value: name })),
    [protections]
  );

  return (
    <WebFiltersComponent
      protections={mappedProtections}
      selectedProtectionName={protection}
      customProtections={customProtection}
      blocked={blocked}
      allowed={allowed}
      profileId={profileId}
      protection={protection}
      load={() => dispatch(load())}
      block={categoriesNames => dispatch(block(categoriesNames, profileId))}
      allow={categoriesNames => dispatch(allow(categoriesNames, profileId))}
      setBlocked={categoriesNames =>
        dispatch(setBlocked(categoriesNames, profileId))
      }
      changeProtection={(profileId, protection) =>
        dispatch(changeProtection(profileId, protection))
      }
      intl={intl}
      className={className}
    />
  );
};
