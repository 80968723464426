import { Category, HttpServiceError } from "@sportal/api";

export const LOAD_CATEGORIES_SUCCESS = "[CATEGORIES] LOAD_CATEGORIES_SUCCESS";
export const LOAD_CATEGORIES_FAILURE = "[CATEGORIES] LOAD_CATEGORIES_FAILURE";

interface LoadCategoriesSuccess {
  type: typeof LOAD_CATEGORIES_SUCCESS;
  payload: Category[];
}

interface LoadCategoriesFailure {
  type: typeof LOAD_CATEGORIES_FAILURE;
  payload: HttpServiceError;
}

export type CategoriesActionTypes =
  | LoadCategoriesSuccess
  | LoadCategoriesFailure;
