import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getSelectedReportsProfile } from "../../../store/reports/reports.selectors";
import { getDrilldownReportsPeriod } from "../drilldown.selectors";
import { getSecureBlocksLimit } from "../../../store/config.selectors";
import { isMultipleUserLevel } from "../../../store/account";
import { chartProps } from "../drilldown.constants";
import { DrilldownRoutePage } from "../drilldown.types";
import {
  load,
  changeProfile,
  cancelZoom,
  changePeriod
} from "../../../store/reports/reports.actions";
import { DrilldownProfileSelect } from "./drilldownProfileSelect";
import { DrilldownTimePeriod } from "./drilldownTimePeriod";
import { ChartSliceInfo } from "./chartSliceInfo";
import { ReportsPeriod } from "../../../helpers/reports.helper.types";
import { DrilldownBarchart } from "./drilldownBarchart/DrilldownBarchart";

interface ReportRequestOptions {
  type?: string;
  withGranularity?: boolean;
  profile?: string;
  period?: ReportsPeriod;
  limit?: number;
}

interface ReportRequest {
  endpoints: [string];
  options: ReportRequestOptions;
}

const getDrilldownReports = (
  page: DrilldownRoutePage,
  options: ReportRequestOptions = {}
): ReportRequest[] => {
  const { chartDataEndpoint, tableDataEndpoint } = chartProps[page];
  const { limit, ...additionalOptions } = options;

  return [
    {
      endpoints: [chartDataEndpoint],
      options: {
        type: "barchart",
        ...additionalOptions
      }
    },
    {
      endpoints: [tableDataEndpoint],
      options: {
        type: "table",
        withGranularity: true,
        ...additionalOptions,
        ...(page !== DrilldownRoutePage.Botnet && { limit })
      }
    }
  ];
};

interface DrilldownChartProps {
  page: DrilldownRoutePage;
}

export const DrilldownChart: FC<DrilldownChartProps> = ({ page }) => {
  const dispatch = useDispatch();

  const isMultiple = useSelector(isMultipleUserLevel);
  const profile = useSelector(getSelectedReportsProfile);
  const period = useSelector(getDrilldownReportsPeriod);
  const limit = useSelector(getSecureBlocksLimit);

  const resetTimeRange = (): void => {
    dispatch(cancelZoom());
  };

  const loadReports = (profile: string, period: ReportsPeriod): void => {
    const reports = getDrilldownReports(page, { profile, period, limit });

    reports.forEach(({ endpoints, options }) =>
      dispatch(load(endpoints, options))
    );
  };

  const handleProfileChange = (profile: string): void => {
    dispatch(changeProfile(profile));
    loadReports(profile, period);
  };

  const handleReportPeriodChange = (period: ReportsPeriod): void => {
    dispatch(changePeriod(period));
    loadReports(profile, period);
    resetTimeRange();
  };

  const showProfiles: boolean =
    page !== DrilldownRoutePage.Botnet && isMultiple;

  return (
    <div className="reports-wrapper drilldowns__chart">
      <DrilldownTimePeriod
        period={period}
        onTimePeriodChange={handleReportPeriodChange}
      />
      {showProfiles && (
        <DrilldownProfileSelect onProfileChange={handleProfileChange} />
      )}
      <DrilldownBarchart page={page} />
      <ChartSliceInfo onCancelZoom={resetTimeRange} period={period} />
    </div>
  );
};
