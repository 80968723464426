import {
  RENAME_PROFILE_SUCCESS,
  RenameProfileSuccessAction,
  CHANGE_PROTECTION,
  ChangeProtectionAction,
  CHANGE_SAFE_SEARCH_SERVICE,
  ChangeSafeSearchServiceAction,
  SBProfile
} from "./profiles.types";

type ProfileActionTypes =
  | RenameProfileSuccessAction
  | ChangeProtectionAction
  | ChangeSafeSearchServiceAction;

export const reducer = (state: SBProfile, action: ProfileActionTypes) => {
  switch (action.type) {
    case CHANGE_SAFE_SEARCH_SERVICE: {
      return {
        ...state,
        "safe-search-services": {
          ...state["safe-search-services"],
          [action.payload.service]: action.payload.enabled
        }
      };
    }
    case CHANGE_PROTECTION: {
      return {
        ...state,
        protection: action.payload.protection
      };
    }
    case RENAME_PROFILE_SUCCESS: {
      return {
        ...state,
        name: action.payload.newName
      };
    }
    default: {
      return state;
    }
  }
};
