import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { isSBReportingLicensed } from "../../../store/settings/settings.selectors";
import { isMultipleUserLevel } from "../../../store/account";
import { AddDevice } from "../../../pages/devices/components/addDevice/AddDevice";
import { HelpLink } from "../../helpLink/HelpLink";
import { useOpenHistoryClearModal } from "../header/userMenu/useOpenHistoryClearModal";
import { useMobileMenuItems } from "../menu.config";
import { PortalName } from "./portalName/PortalName";
import { WebFilterToggle } from "./webFilterToggle/WebFilterToggle";

export const useMenuItems = ({
  subscriberName,
  portalName,
  isSbEnabled,
  updateWebFilter,
  logout
}) => {
  const navigation = useMobileMenuItems();
  const showClearData = useSelector(isSBReportingLicensed);
  const isMultiple = useSelector(isMultipleUserLevel);

  const openHistoryClearModal = useOpenHistoryClearModal();

  const userSubMenu = [
    {
      id: "account",
      text: "account",
      url: "/settings/account"
    },
    {
      id: "help",
      text: "help",
      url: (
        <HelpLink
          className="menu__item"
          children={<FormattedMessage id="help" />}
        />
      )
    },
    showClearData && {
      id: "history-clear",
      text: "clear_data",
      className: "history-clear-link",
      action: () => openHistoryClearModal()
    },
    {
      id: "logout",
      className: "button-link--inherit",
      content: <FormattedMessage id="logout" />,
      action: () => logout()
    }
  ].filter(Boolean);

  return [
    {
      id: "webFilter",
      staticContent: (
        <WebFilterToggle on={isSbEnabled} onChange={updateWebFilter} />
      )
    },
    portalName && {
      id: "portalName",
      staticContent: <PortalName name={portalName} />
    },
    {
      id: "userMenu",
      subscriberName: subscriberName,
      subMenu: userSubMenu
    },
    ...navigation,
    isMultiple && {
      id: "add-device",
      modal: <AddDevice className="button-link--inherit" />
    }
  ].filter(Boolean);
};
