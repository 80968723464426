import _ from "lodash";

/**
 * Transforms an array of objects to the normalized form
 * @param {Array} [array] Array to normalize
 * @param {string} [keyField="id"] Name of the field that should be used as id
 * @returns {{list: {}, keys: string[]}}
 */
export function normalize(array, keyField = "id") {
  return _.transform(
    array,
    ({ list, keys }, item) => {
      const id = item[keyField];
      list[id] = item;
      keys.push(id);
    },
    {
      list: {},
      keys: []
    }
  );
}
