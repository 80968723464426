import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { SegmentedButtonsGroup } from "../segmentedButtonsGroup/SegmentedButtonsGroup";

export const periods = [
  { value: "1", name: "24_hours", mobileName: "day_noun" },
  { value: "7", name: "7_days", mobileName: "week_noun" },
  { value: "30", name: "30_days", mobileName: "month_noun" }
];
const buttons = periods.map(({ value, mobileName }) => ({
  value,
  node: <FormattedMessage id={mobileName} />
}));

export const TimePeriodSegmentedButtons = ({ selectedValue, onChange }) => (
  <SegmentedButtonsGroup
    buttonSize="medium"
    label={<FormattedMessage id="show_results_period" />}
    groupName="time-period"
    selectedValue={selectedValue}
    buttons={buttons}
    onChange={onChange}
  />
);

TimePeriodSegmentedButtons.propTypes = {
  selectedValue: PropTypes.string,
  onChange: PropTypes.func.isRequired
};
