import {
  CategoriesActionTypes,
  LOAD_CATEGORIES_SUCCESS
} from "./categories.types";
import { Category } from "@sportal/api";

export type CategoriesState = Category[];

const initialState = [];

export const reducer = (
  state: CategoriesState = initialState,
  action: CategoriesActionTypes
): CategoriesState => {
  switch (action.type) {
    case LOAD_CATEGORIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
