import _ from "lodash";
import { useSelect } from "downshift";

const EVENT_TYPES = useSelect.stateChangeTypes;

export const getDropdownReducer = items => {
  // main purpose of this reducer is to ignore disabled and non selectable (divider, title) items
  const selectableItems = items
    .map((item, index) => item.selectable !== false && !item.disabled && index)
    .filter(val => typeof val == "number");
  const getNextItemIndex = currentIndex => {
    const selectableIndex = selectableItems.indexOf(currentIndex);
    const nextIndex = selectableItems[selectableIndex + 1];

    return nextIndex >= 0 ? nextIndex : _.last(selectableItems);
  };
  const getPreviousItemIndex = currentIndex => {
    const selectableIndex = selectableItems.indexOf(currentIndex);
    const previousIndex = selectableItems[selectableIndex - 1];

    return previousIndex || _.first(selectableItems);
  };

  function stateReducer(state, actionAndChanges) {
    const { changes, type } = actionAndChanges;

    switch (type) {
      case EVENT_TYPES.ToggleButtonKeyDownArrowDown:
      case EVENT_TYPES.MenuKeyDownArrowDown:
        return {
          ...changes,
          highlightedIndex: getNextItemIndex(state.highlightedIndex)
        };
      case EVENT_TYPES.ToggleButtonKeyDownArrowUp:
      case EVENT_TYPES.MenuKeyDownArrowUp:
        return {
          ...changes,
          highlightedIndex: getPreviousItemIndex(state.highlightedIndex)
        };
      default:
        return {
          ...changes
        };
    }
  }

  return stateReducer;
};
