export enum ATTRIBUTES {
  TIME_FORMAT = "nom.smb.format24h",
  LANGUAGE = "nom.smb.language",
  EMAIL = "nom.smb.email",
  EMAIL_FREQUENCY = "nom.smb.email.frequency",
  BUSINESS_NAME = "nom.smb.businessname"
}

export const LANGUAGE_COOKIE_KEY = "sb-nom_pi_lang";

export const FREQUENCIES = {
  NEVER: "off",
  DAILY: "P1D",
  WEEKLY: "P1W",
  MONTHLY: "P1M"
};

export const DEFAULT_FREQUENCY = FREQUENCIES.NEVER;
export const DEFAULT_TIME_ZONE = "Etc/GMT";
