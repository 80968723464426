import _ from "lodash";

import {
  LOAD_CATEGORIES_SUCCESS,
  LOAD_CATEGORIES_FAILURE,
  CategoriesActionTypes
} from "./categories.types";
import { ThunkApi } from "../../api/api";
import {
  Category,
  HttpServiceError,
  ProtectionCategoriesServerData
} from "@sportal/api";
import { SBThunkAction } from "../redux.types";

export const loadCategories = (): SBThunkAction<
  Promise<void>,
  CategoriesActionTypes
> => (dispatch, getState, { api }: ThunkApi) => {
  const { categories } = getState();

  if (!_.isEmpty(categories)) {
    return;
  }

  return api.ssm.protection
    .getCategories()
    .then(({ content }: ProtectionCategoriesServerData) => {
      dispatch(loadCategoriesSuccess(content));
    })
    .catch(error => {
      dispatch(loadCategoriesFailure(error));
      return Promise.reject();
    });
};

export const loadCategoriesSuccess = (
  categories: Category[]
): CategoriesActionTypes => ({
  type: LOAD_CATEGORIES_SUCCESS,
  payload: categories
});

export const loadCategoriesFailure = (
  error: HttpServiceError
): CategoriesActionTypes => ({
  type: LOAD_CATEGORIES_FAILURE,
  payload: error
});
