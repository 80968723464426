import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export const DropdownItem = ({
  item,
  index,
  highlightedIndex,
  getItemProps
}) => (
  <li
    className={classnames("dropdown__item", item.className, {
      "dropdown__item--disabled": item.disabled,
      "dropdown__item--highlighted": highlightedIndex === index
    })}
    {...getItemProps({
      item,
      index,
      disabled: item.selectable === false || item.disabled
    })}
    id={item.id} // for qa
  >
    {item.content}
  </li>
);

export const DropdownItemPropTypes = PropTypes.shape({
  id: PropTypes.string,
  selectable: PropTypes.bool,
  content: PropTypes.node,
  action: PropTypes.func,
  disabled: PropTypes.bool
});

DropdownItem.propTypes = {
  item: DropdownItemPropTypes,
  index: PropTypes.number,
  highlightedIndex: PropTypes.number,
  getItemProps: PropTypes.func
};
