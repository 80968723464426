import _ from "lodash";
import React, { memo } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { ModalService, ModalDialog, useMobileScreenCheck } from "../../modal";

import "./MobileSort.scss";

const sortingClasses = (desc, showSortingArrows = true) =>
  classnames({
    sorted: showSortingArrows,
    "sorted--asc": !_.isUndefined(desc) && !desc,
    "sorted--desc": desc
  });

export const MobileSort = memo(function MobileSort({
  allColumns,
  toggleSortBy,
  sortBy
}) {
  const accessors = _.map(allColumns, ({ id, SortingLabel }) => ({
    id,
    SortingLabel,
    desc:
      !_.isEmpty(sortBy) && _.some(sortBy, { id: id })
        ? sortBy[0].desc
        : undefined
  }));

  //If table dont have default sorting in initial state we need to take just first column accessor
  const currentAccessor = _.isEmpty(sortBy)
    ? accessors[0]
    : _.find(accessors, { id: sortBy[0].id });

  return (
    <button
      type="button"
      className={classnames(
        "mobile-sorting__btn",
        sortingClasses(currentAccessor.desc)
      )}
      onClick={() =>
        openModal(accessors, currentAccessor)
          //toggleSortBy(ColumnId: String, descending: Bool, isMulti: Bool)
          .then(({ id }) => toggleSortBy(id))
          .catch(() => null)
      }
      data-testid="sorting-button"
    >
      {currentAccessor.SortingLabel}
    </button>
  );
});

const openModal = (accessors, currentAccessor) =>
  ModalService.show(modal => ({
    dialog: (
      <SortByDialog
        accessors={accessors}
        currentAccessor={currentAccessor}
        modal={modal}
      />
    )
  }));

export const SortByDialog = ({ accessors, modal }) => {
  const collapse = () => modal.reject();

  useMobileScreenCheck(collapse);

  return (
    <ModalDialog className="actions-modal sort-by-modal">
      <ModalDialog.Header>
        <h3 className={"header-text"}>
          <FormattedMessage id="sort_by" />
        </h3>
      </ModalDialog.Header>
      <ModalDialog.Body>
        <div className="items__container">
          {_.map(accessors, item => (
            <div
              key={item.id}
              className={sortingClasses(item.desc, !_.isUndefined(item.desc))}
            >
              <button
                className="unmodified__btn"
                onClick={() => modal.resolve({ id: item.id })}
                data-testid="accessor-item"
              >
                {item.SortingLabel}
              </button>
            </div>
          ))}
        </div>
      </ModalDialog.Body>
      <ModalDialog.Footer>
        <button
          type="button"
          className="unmodified__btn dismiss-button"
          onClick={collapse}
          data-testid="dismiss-dialog"
        >
          <FormattedMessage id="dismiss" />
        </button>
      </ModalDialog.Footer>
    </ModalDialog>
  );
};
