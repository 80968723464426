import _ from "lodash";
import { getCookie } from "../common/utils/cookie";
import { LANGUAGE_COOKIE_KEY } from "./attributes/attributes.constants";
import { RootState } from "./root.reducer";

export function getLanguageKey(state: RootState) {
  const { config, accountSettings } = state;
  const langs = _.map(config.languages.list, "key");

  const cookieLang = getCookie(LANGUAGE_COOKIE_KEY);
  if (cookieLang && _.includes(langs, cookieLang)) {
    return cookieLang;
  }

  const settingsLang = accountSettings.language;
  if (settingsLang) {
    return settingsLang;
  }

  const defaultLang = config.languages.default;
  if (defaultLang && _.includes(langs, defaultLang)) {
    return defaultLang;
  }

  return _.first(langs);
}

export function getLanguage(state: RootState) {
  const { config } = state;
  return _.find(config.languages.list, { key: getLanguageKey(state) });
}

export const getDevices = (state: RootState) => state.devices;
export const getAccount = (state: RootState) => state.account;
export const getAccountSettings = (state: RootState) => state.accountSettings;
export const getSettings = (state: RootState) => state.settings;
export const getReports = (state: RootState) => state.reports;
export const getConfig = (state: RootState) => state.config;
export const getDomainLookup = (state: RootState) => state.domainLookup;
export const getWebFilters = (state: RootState) => state.webFilters;
export const getProtections = (state: RootState) => state.protections;
export const getCategories = (state: RootState) => state.categories;
export const getSubscriberInfo = (state: RootState) => state.subscriberInfo;
export const getResources = (state: RootState) => state.resources;
export const getNotifications = (state: RootState) => state.notifications;
export const getThreats = (state: RootState) => state.threats;
export const getDeepLink = (state: RootState) => state.deepLink;
