import _ from "lodash";
import {
  dehydrateSchedule,
  getChangedSchedules,
  getAddedSchedules,
  getRemovedSchedules
} from "./schedules.selectors";

export const saveSchedules = () => (dispatch, getState, { api }) => {
  const scheduleApi = api.ssm.schedules;
  const {
    subscriberInfo: { id },
    profiles: {
      changed: { list: profileList }
    },
    schedules
  } = getState();

  const added = getAddedSchedules(schedules);
  const removed = getRemovedSchedules(schedules);
  const changed = getChangedSchedules(schedules);

  return buildChain(
    preparePayload(removed),
    scheduleApi.remove.bind(scheduleApi)
  )
    .then(() =>
      buildChain(preparePayload(added), scheduleApi.create.bind(scheduleApi))
    )
    .then(() =>
      buildChain(preparePayload(changed), scheduleApi.save.bind(scheduleApi))
    )
    .then(() => dispatch(saveSchedulesSuccess({ added, changed, removed })))
    .catch(error => {
      dispatch(saveSchedulesError(error));
      return Promise.reject();
    });

  function buildChain(schedules = [], method) {
    return _.reduce(
      schedules,
      (acc, schedule) =>
        acc.then(() => method(id, schedule.profileName, schedule.schedule)),
      Promise.resolve()
    );
  }

  function preparePayload(schedules = []) {
    return _.map(schedules, schedule => ({
      profileName: profileList[schedule.profile].name,
      schedule: dehydrateSchedule(schedule)
    }));
  }
};

export const GET_SCHEDULES_SUCCESS = "[SCHEDULES] GET_SCHEDULES_SUCCESS";
export const ADD_SCHEDULE = "[SCHEDULES] ADD_SCHEDULE";
export const CHANGE_ENABLED = "[SCHEDULES] CHANGE_ENABLED";
export const CHANGE_CATEGORIES = "[SCHEDULES] CHANGE_CATEGORIES";
export const CHANGE_SCHEDULE = "[SCHEDULES] CHANGE_SCHEDULE";
export const CHANGE_SCHEDULES = "[SCHEDULES] CHANGE_SCHEDULES";
export const REMOVE_SCHEDULE = "[SCHEDULES] REMOVE_SCHEDULE";
export const SAVE_SCHEDULES_SUCCESS = "[SCHEDULES] SAVE_SCHEDULES_SUCCESS";
export const SAVE_SCHEDULES_ERROR = "[SCHEDULES] SAVE_SCHEDULES_ERROR";
export const TOGGLE_EDIT_POPUP = "[SCHEDULES] TOGGLE_EDIT_POPUP";

export const getSchedulesSuccess = (profiles = []) => ({
  type: GET_SCHEDULES_SUCCESS,
  payload: profiles
});
export const addSchedule = schedule => ({
  type: ADD_SCHEDULE,
  payload: schedule
});

export const changeSchedules = schedules => ({
  type: CHANGE_SCHEDULES,
  payload: schedules
});

export const changeEnabled = (type, profile, enabled) => ({
  type: CHANGE_ENABLED,
  payload: { type, profile, enabled }
});
export const changeCategories = (type, profile, categories) => ({
  type: CHANGE_CATEGORIES,
  payload: { type, profile, categories }
});
export const changeSchedule = schedule => ({
  type: CHANGE_SCHEDULE,
  payload: schedule
});
export const removeSchedule = scheduleId => ({
  type: REMOVE_SCHEDULE,
  payload: scheduleId
});
export const saveSchedulesSuccess = schedules => ({
  type: SAVE_SCHEDULES_SUCCESS,
  payload: schedules
});
export const saveSchedulesError = error => ({
  type: SAVE_SCHEDULES_ERROR,
  payload: error
});

export const toggleEditPopup = id => ({
  type: TOGGLE_EDIT_POPUP,
  payload: { id }
});
