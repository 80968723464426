import React from "react";

import "./CharacterLimitInfo.scss";
import classnames from "classnames";

const ariaLive = (length, limit) => {
  const limitExhaustion = (length / limit).toFixed(2);

  if (limitExhaustion < 0.9) {
    return "off";
  } else if (limitExhaustion < 0.99) {
    return "polite";
  } else {
    return "assertive";
  }
};

export const CharacterLimitInfo = ({ limit, value }) => {
  const length = value.trim().length;
  const classes = classnames("character-limit-info", {
    "color-danger": length > limit
  });

  return (
    <div
      aria-relevant="text"
      aria-live={ariaLive(length, limit)}
      className={classes}
    >{`${length}/${limit}`}</div>
  );
};
