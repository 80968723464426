import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";

import "./Sidebar.scss";

// TODO: Currently Sidebar (and Accordion) only gets a node
//  as a header and content and it can be the same component for every
//  tab so we actually do not need several instances.
//  It can make sense to pass a Class or a function in such cases and optimize
//  rendering inside

export const Sidebar = ({
  tabs,
  className,
  activeTab,
  contentClassName,
  sidebarMenu
}) => {
  return (
    <div className={classnames("sidebar", className)}>
      <div className="row">
        {sidebarMenu}
        {_.map(tabs, ({ id, content }) => (
          <div
            key={id}
            data-id={id}
            className={classnames(
              contentClassName || "col-9",
              "sidebar__content",
              {
                "sidebar__content--show": activeTab === id
              }
            )}
          >
            {activeTab === id && content}
          </div>
        ))}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      header: PropTypes.node.isRequired,
      content: PropTypes.node
    })
  ).isRequired,
  activeTab: PropTypes.string,
  contentClassName: PropTypes.string
};
