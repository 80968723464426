import * as d3 from "d3";
import _ from "lodash";

export const xBandScale = d3.scaleBand().paddingInner(0.2);
export const yPowScale = d3.scalePow().exponent(0.4);
export const yLinearScale = d3.scaleLinear().range([25, 0]);

// 8 is for adding small space between the maximum height of bar and the border of chart
const BAR_PADDING_TOP = 8;

//xScale should be updated once container is rendered
//it's needed to define ticks allocation properly
export const updateXScale = (ticks, width = 0) => {
  if (_.isEmpty(ticks)) return;

  xBandScale.domain(ticks).range([0, width]);
};

//yScale should be updated when data is available
export const updateYScales = (rawData, reportHeight, { dataSelection }) => {
  if (_.isEmpty(rawData) || !reportHeight || _.isEmpty(dataSelection)) return;

  dataSelection.top &&
    yPowScale
      // @ts-ignore
      .domain([0, d3.max(rawData, dataSelection.top)])
      .range([reportHeight, BAR_PADDING_TOP]);

  dataSelection.bottom &&
    // @ts-ignore
    yLinearScale.domain([0, d3.max(rawData, dataSelection.bottom)]);
};
