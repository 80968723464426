import React, { useLayoutEffect, useEffect, useState } from "react";
import { connect } from "react-redux";

import { IntlProvider } from "react-intl";
import normalizeLocale from "./normalizeLocale";

import { getLanguage } from "../store/root.selectors";

const needsRelativeTimeFormatPolyfill = !Intl.RelativeTimeFormat;

if (needsRelativeTimeFormatPolyfill) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  require("@formatjs/intl-locale/polyfill");
}

function WithIntl(props) {
  const { locale, children, isInverse, messages } = props;

  const [isReady, setReady] = useState(!needsRelativeTimeFormatPolyfill);

  useEffect(() => {
    if (needsRelativeTimeFormatPolyfill) {
      const localePrefix = locale.slice(0, locale.indexOf("-"));

      import(
        `@formatjs/intl-relativetimeformat/locale-data/${localePrefix}`
      ).finally(() => {
        setReady(true);
      });
    }
  }, [locale]);

  useLayoutEffect(() => {
    document.body.classList.remove(isInverse ? "ltr" : "rtl");
    document.body.classList.add(isInverse ? "rtl" : "ltr");
  });

  if (!isReady) {
    return null;
  }

  return (
    <IntlProvider key={locale} locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}

/*
    Intl accepts locale as BCP-47 language tag: en-US, not en_US
    Let's transform our languages (that are set in appConfig.json) in order to provide backward compatibility
    It may make sense to move this transformation to the store if we want use this value across an app
    but for now it is the only place where it is used
*/
// TODO: need to decide how to deal with this incorrect locales keys that we've been using for a while
export default connect(state => {
  const language = getLanguage(state),
    locale = normalizeLocale(language.key);

  return {
    locale: locale,
    isInverse: language.inversedDirection,
    messages: state.translation[locale] || {}
  };
})(WithIntl);
