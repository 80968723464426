import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";

import { StickySidebar } from "./StickySidebar";

export const WizardSidebarMenu = ({
  menuClassName,
  sidebarMenuHeader,
  tabs,
  activeTab,
  activeTabClass,
  onTabClick,
  isInteractive
}) => (
  <StickySidebar
    menuClassName={menuClassName}
    sidebarMenuHeader={sidebarMenuHeader}
    activeTab={activeTab}
    tabs={tabs}
  >
    {_.map(tabs, ({ id, header, selectable = true }) => (
      <div
        key={id}
        onClick={
          isInteractive && selectable && activeTab !== id
            ? () => onTabClick(id)
            : null
        }
        data-id={id}
        className={classnames(
          "sidebar__tab-header",
          isInteractive &&
            activeTab === id && ["sidebar__tab-header--active", activeTabClass]
        )}
      >
        {header}
      </div>
    ))}
  </StickySidebar>
);

WizardSidebarMenu.propTypes = {
  menuClassName: PropTypes.string,
  sidebarMenuHeader: PropTypes.node,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      header: PropTypes.node.isRequired,
      content: PropTypes.node
    })
  ).isRequired,
  onTabClick: PropTypes.func,
  activeTab: PropTypes.string,
  activeTabClass: PropTypes.string,
  isInteractive: PropTypes.bool.isRequired
};
