import { AnyAction } from "redux";

import {
  changePeriod,
  changeProfile,
  load
} from "../../store/reports/reports.actions";
import {
  getReportsPeriod,
  getSelectedReportsProfile
} from "../../store/reports/reports.selectors";
import { getSecureBlocksLimit } from "../../store/config.selectors";
import {
  barchartKeys,
  ReportsPeriod,
  usageStatisticKeys,
  widgetKeys
} from "../../helpers/reports.helper.types";
import { SBThunkAction } from "../../store/redux.types";
import { ReportsSection } from "../../store/reports/reports.types";

const getReportKeys = (period: ReportsPeriod) =>
  period === ReportsPeriod.Day ? usageStatisticKeys : barchartKeys;

export const changeReportsProfile = (
  profile
): SBThunkAction<void, AnyAction> => (dispatch, getState) => {
  dispatch(changeProfile(profile));

  const state = getState();
  const period = getReportsPeriod(state);
  const limit = getSecureBlocksLimit(state);

  dispatch(
    load(getReportKeys(period), {
      period,
      type: ReportsSection.Barchart,
      profile,
      withGranularity: false,
      limit
    })
  );
};

export const changeReportsTimePeriod = (
  period: ReportsPeriod
): SBThunkAction<void, AnyAction> => (dispatch, getState) => {
  dispatch(changePeriod(period));

  const profile = getSelectedReportsProfile(getState());

  dispatch(
    load(getReportKeys(period), {
      period,
      profile,
      type: ReportsSection.Barchart
    })
  );
  dispatch(load(widgetKeys, { period, type: ReportsSection.Widgets }));
};

export const setupDashboard = (): SBThunkAction<void, AnyAction> => (
  dispatch,
  getState
) => {
  const state = getState();
  const period = getReportsPeriod(state);

  dispatch(changeProfile("all_profiles"));
  dispatch(
    load(widgetKeys, {
      period,
      type: ReportsSection.Widgets
    })
  );
  dispatch(
    load(getReportKeys(period), {
      period,
      type: ReportsSection.Barchart
    })
  );
};
