import { AnyAction } from "redux";

import { SBThunkAction } from "../../store/redux.types";
import { fetchThreats } from "../../store/threats/threats.actions";
import { changeProfile, load } from "../../store/reports/reports.actions";
import { getDrilldownReportsPeriod } from "./drilldown.selectors";
import { DrilldownRoutePage } from "./drilldown.types";
import { chartProps } from "./drilldown.constants";
import { ReportsSection } from "../../store/reports/reports.types";
import { getSecureBlocksLimit } from "../../store/config.selectors";

export const setupDrilldown = (
  page: DrilldownRoutePage
): SBThunkAction<void, AnyAction> => (dispatch, getState) => {
  const { tableDataEndpoint, chartDataEndpoint } = chartProps[page];

  if (page === DrilldownRoutePage.Botnet) {
    dispatch(fetchThreats());
  }

  const state = getState();
  const period = getDrilldownReportsPeriod(state);
  const limit = getSecureBlocksLimit(state);

  dispatch(changeProfile("all_profiles"));
  dispatch(
    load([tableDataEndpoint], {
      type: ReportsSection.Table,
      period,
      limit: page !== DrilldownRoutePage.Botnet ? limit : undefined,
      withGranularity: true
    })
  );
  dispatch(
    load([chartDataEndpoint], {
      type: ReportsSection.Barchart,
      period
    })
  );
};
