import React, { FC, MutableRefObject } from "react";

import { TabItem, TabControl } from "../tabControl/TabControl";

import "./TabList.scss";

interface TabListProps {
  items: TabItem[];
  selected?: string;
  onClick?: (id: string) => void;
  tabsRef?: MutableRefObject<HTMLUListElement>;
}

export const TabList: FC<TabListProps> = ({
  items = [],
  tabsRef,
  selected,
  onClick
}) => {
  return (
    <ul className="tab-list" ref={tabsRef}>
      {items.map(item => (
        <li className="tab-item" key={item.id}>
          <TabControl
            item={item}
            selected={selected === item.id}
            onClick={onClick}
          />
        </li>
      ))}
    </ul>
  );
};
