import React from "react";
import PropTypes from "prop-types";

import { Tooltip } from "./Tooltip";
import { useTooltipEvents } from "./useTooltipEvents";
import { PLACEMENTS, usePopperAPI } from "./usePopperAPI";
import { useEventListener } from "../hooks";

function generateGetBoundingClientRect(x = 0, y = 0) {
  return () => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x
  });
}

const virtualElement = {
  getBoundingClientRect: generateGetBoundingClientRect()
};

const FollowCursor = ({ placement, modifiers, className, content }) => {
  const {
    setReferenceElement,
    setPopperElement,
    setArrowElement,
    styles,
    attributes
  } = usePopperAPI(placement, modifiers, virtualElement);

  useEventListener("mousemove", ({ clientX: x, clientY: y }) => {
    const cursor = {
      getBoundingClientRect: generateGetBoundingClientRect(x, y)
    };

    setReferenceElement(cursor);
  });

  return (
    <Tooltip
      className={className}
      setPopperElement={setPopperElement}
      setArrowElement={setArrowElement}
      styles={styles}
      attributes={attributes}
    >
      {content}
    </Tooltip>
  );
};

export const MouseTooltip = ({
  placement,
  modifiers,
  className,
  content,
  children,
  ...attrs
}) => {
  const [isTooltipVisible, toggleStateEvents] = useTooltipEvents();

  return (
    <div className="tooltip-referrer" {...toggleStateEvents} {...attrs}>
      {children}

      {isTooltipVisible && (
        <FollowCursor
          placement={placement}
          modifiers={modifiers}
          className={className}
          content={content}
        />
      )}
    </div>
  );
};

MouseTooltip.propTypes = {
  placement: PropTypes.oneOf(PLACEMENTS),
  modifiers: PropTypes.array,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  children: PropTypes.node.isRequired
};
