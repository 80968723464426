import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { withLayout } from "../../components/layout/Layout";

import { isMultipleUserLevel } from "../../store/account";
import { isSBReportingLicensed } from "../../store/settings/settings.selectors";

import { Dashboard } from "./Dashboard";
import { Devices } from "./devices/DevicesOnHome";

const HomePageContent = () => {
  const isMultiple = useSelector(isMultipleUserLevel);
  const isLicensed = useSelector(isSBReportingLicensed);

  if (!isLicensed && !isMultiple) return <Redirect to="/settings" />;

  return (
    <div className="home-page">
      {isLicensed && <Dashboard />}
      {isMultiple && <Devices />}
    </div>
  );
};

export const HomePage = withLayout()(HomePageContent);
