import React from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "../../../../../components/button/Button";
import { Icon } from "../../../../../components/icon/Icon";

export const CreateRuleButton = props => {
  const { disabled, onClick } = props;

  return (
    <div className={"create-rule"}>
      <Button
        isOutline
        onClick={onClick}
        disabled={disabled}
        size={"large"}
        role="button"
      >
        <Icon icon={"far fa-plus-circle color-secondary"} />
        <span>
          <FormattedMessage id={"create_rule"} />
        </span>
      </Button>
    </div>
  );
};
