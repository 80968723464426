import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Input from "../../../../components/input/Input";
import { isCodeSymbolsValid } from "../../../../helpers/validation.helper";
import { checkCode } from "../../../../store/devices/devices.actions";
import { Mobile, NotMobile } from "../../../../hocs/Responsive";
import { Icon } from "../../../../components/icon/Icon";

const SEARCH_TIME_LIMIT = 60 * 1000;
const SEARCHING_IDLE = 5 * 1000;

class CheckCodeField extends Component {
  state = {
    code: "",
    hasError: false,
    searching: false,
    macFound: false,
    macNotFound: false,
    address: "",
    timeRemaining: 0
  };

  componentWillUnmount() {
    this.remainingTimeTick && clearInterval(this.remainingTimeTick);
    this.delaySearchTimeout && clearTimeout(this.delaySearchTimeout);
  }

  render() {
    const { searching, macFound, macNotFound } = this.state;
    const isInfoVisible = searching || macFound || macNotFound;
    const className = classnames("input-with-search", {
      searching: this.state.searching
    });
    const commonProps = {
      value: this.state.code,
      label: <FormattedMessage id={"label_code"} />,
      errorMessage: this.state.hasError && (
        <FormattedMessage id="transaction_required" />
      ),
      onChange: e => this.handleInputChange(e)
    };

    return (
      <Fragment>
        <div className={className}>
          <Mobile>
            <Input {...commonProps} />
          </Mobile>
          <NotMobile>
            <Input {...commonProps} autoFocus />
          </NotMobile>
          <Icon icon="fas fa-spinner fa-pulse fa-2x color-primary" />
        </div>
        {isInfoVisible && this.renderInfoPanel()}
      </Fragment>
    );
  }

  renderInfoPanel() {
    const infoPanel = () => {
      if (this.state.searching) {
        return this.renderProgress();
      } else if (this.state.macFound) {
        return this.renderSuccess();
      } else {
        return this.renderFailure();
      }
    };
    return <div className={"add-device-modal__code-info"}>{infoPanel()}</div>;
  }

  renderProgress() {
    return (
      <div>
        <FormattedMessage id={"search_in_progress_wait"} />
        ... {this.state.timeRemaining}
      </div>
    );
  }

  renderFailure() {
    return (
      <div className={"add-device-modal__code-info--not-found"}>
        <FormattedMessage id={"no_device_found"} />
      </div>
    );
  }

  renderSuccess() {
    const { errorAddress } = this.props;

    return (
      <Fragment>
        <div>{this.state.address}</div>
        {errorAddress ? (
          <div className={"add-device-modal__code-info--exists"}>
            <Icon icon={"far fa-times"} />
            <span>{errorAddress}</span>
          </div>
        ) : (
          <div className={"add-device-modal__code-info--found"}>
            <Icon icon={"far fa-check"} />
            <span>
              <FormattedMessage id={"device_found"} />
            </span>
          </div>
        )}
      </Fragment>
    );
  }

  handleInputChange(e) {
    const code = e.target.value;
    const isValid = isCodeSymbolsValid(code);
    const isFull = code.length === 4;

    if (isValid) {
      this.setState({
        searching: false,
        macFound: false,
        macNotFound: false,
        code,
        hasError: !code
      });

      isFull && this.startSearch(code);
    }
  }

  startSearch(code) {
    this.setState({ searching: true });
    this.searchStarted = Date.now();
    this.findDevice(code);
  }

  findDevice(code) {
    const normalize = value => value.trim().toLocaleLowerCase();
    const tail = normalize(code);

    this.props.check(tail).then(address => {
      const currentValue = normalize(this.state.code);

      if (currentValue !== tail) {
        // value has changed, request can be abandoned
        this.setState({
          searching: false,
          macFound: false,
          macNotFound: false
        });

        return;
      }

      if (!address) {
        this.delaySearch(tail);
        return;
      }

      this.setState({
        address,
        macFound: true,
        macNotFound: false,
        searching: false,
        timeRemaining: 0
      });
      this.props.onAddressChange(address);
      clearInterval(this.remainingTimeTick);
    });
  }

  delaySearch(code) {
    const timeRemaining = this.searchStarted + SEARCH_TIME_LIMIT - Date.now();

    if (timeRemaining > 0) {
      this.setRemainingTime(timeRemaining);
      this.delaySearchTimeout = setTimeout(() => {
        this.findDevice(code);
        clearTimeout(this.delaySearchTimeout);
      }, SEARCHING_IDLE);
    } else {
      this.setState({
        searching: false,
        macFound: false,
        macNotFound: true
      });
    }
  }

  setRemainingTime(timeRemaining) {
    const timeout = 100;

    this.remainingTimeTick && clearInterval(this.remainingTimeTick);
    this.remainingTimeTick = setInterval(() => {
      this.setState({ timeRemaining: (timeRemaining / 1000).toFixed(0) });
      timeRemaining -= timeout;
      if (timeRemaining < 0) {
        clearInterval(this.remainingTimeTick);
      }
    }, timeout);
  }
}

CheckCodeField.propTypes = {
  errorAddress: PropTypes.string,
  onAddressChange: PropTypes.func
};

export default connect(
  null,
  dispatch => ({
    check: code => dispatch(checkCode(code), { showLoader: false })
  })
)(CheckCodeField);
