import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { useForm } from "@sportal/cdk/form/Form";

import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog,
  ModalFormControl
} from "../../../modal";
import { IdentifiersStub, ProfileSelect } from "../../commonComponents";

const CLASS_NAME = "move-device-modal";

export const MoveDeviceDialog = ({ modal, device, profiles }) => {
  const formProps = useForm({ profile: device.profile });

  const close = () => modal.reject();
  const submit = () => modal.resolve(formProps.values);

  const createSelectChangeHandler = name => value => {
    formProps.set(name, value);
  };

  return (
    <ModalDialog
      className={CLASS_NAME}
      submitHandler={submit}
      rejectHandler={close}
      disabled={!formProps.didFormChange()}
    >
      <CommonModalHeader>
        <FormattedMessage id={"assign_to_group"} />
      </CommonModalHeader>
      <ModalDialog.Body>
        <ModalFormControl>
          <IdentifiersStub
            className={CLASS_NAME}
            value={device.identifiers.join(", ")}
          />
        </ModalFormControl>
        <ModalFormControl>
          <ProfileSelect
            profiles={profiles}
            profile={formProps.values.profile}
            handleChange={createSelectChangeHandler("profile")}
            className={CLASS_NAME}
          />
        </ModalFormControl>
      </ModalDialog.Body>
      <CommonModalFooter submitMessage={<FormattedMessage id="assign" />} />
    </ModalDialog>
  );
};

MoveDeviceDialog.propTypes = {
  device: PropTypes.object,
  devices: PropTypes.array,
  profiles: PropTypes.array,
  modal: PropTypes.object
};
