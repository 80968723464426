import React from "react";
import { FormattedMessage } from "react-intl";

import { useForm } from "@sportal/cdk/form/Form";
import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog,
  ModalFormControl
} from "../../../modal";
import { InputStub, ProfileSelect } from "../../commonComponents";

export const AssignRoamingDeviceDialog = ({
  profiles,
  modal,
  name,
  profile
}) => {
  const formProps = useForm({
    profile
  });

  const close = () => modal.reject();
  const submit = () => modal.resolve(formProps.values);

  return (
    <ModalDialog
      submitHandler={submit}
      rejectHandler={close}
      className="assign-roaming-device-modal"
      disabled={!formProps.didFormChange()}
    >
      <CommonModalHeader>
        <FormattedMessage id="assign_to_group" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <ModalFormControl>
          <InputStub
            label={<FormattedMessage id="device_name" />}
            value={name}
          />
        </ModalFormControl>
        <ModalFormControl>
          <ProfileSelect
            className="roaming-device"
            profiles={profiles}
            profile={formProps.values.profile}
            handleChange={profile => formProps.set("profile", profile)}
          />
        </ModalFormControl>
      </ModalDialog.Body>
      <CommonModalFooter submitMessage={<FormattedMessage id="assign" />} />
    </ModalDialog>
  );
};
