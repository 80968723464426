import React from "react";
import classnames from "classnames";

import { Icon } from "../../../../components/icon/Icon";

import "./UrlListInfo.scss";

export const UrlListInfo = ({ icon, name, info }) => (
  <div className="url-list-info">
    <Icon icon={classnames("url-list-info__icon", "fas", "fa-fw", icon)} />
    <div className="url-list-info__description">
      <h3>{name}</h3>
      <div>{info}</div>
    </div>
  </div>
);
