import { failure, Result, success, statusCodeMatches } from "../shared";
import { AccountProvider } from "./account.provider";
import { AccountData, BypassPinUpdates } from "./account.types";
import { ControlProvider } from "../control";
import { ServicesActivationCommand } from "./activationCommand.types";
import { Service } from "../settings";

export enum AccountServiceErrorCode {
  GENERIC,
  CANNOT_CREATE_ACCOUNT,
  CANNOT_ACTIVATE,
  CANNOT_UPDATE
}

export type CreateAccountData = Pick<AccountData, "id" | "timezone">;

export class AccountService {
  constructor(
    private accountProvider: AccountProvider,
    private controlProvider: ControlProvider
  ) {}

  public async get(
    subscriberId: string,
    timezone: string
  ): Promise<Result<AccountData | CreateAccountData>> {
    try {
      const { data } = await this.accountProvider.get(subscriberId);
      return success(data);
    } catch (error) {
      if (statusCodeMatches(error, 404)) {
        return await this.create(subscriberId, timezone);
      }

      return failure(AccountServiceErrorCode.GENERIC);
    }
  }

  private async create(
    subscriberId: string,
    timezone: string
  ): Promise<Result<CreateAccountData>> {
    try {
      await this.controlProvider.createAccount(subscriberId, timezone);
      return success({ id: subscriberId, timezone });
    } catch (error) {
      return failure(AccountServiceErrorCode.CANNOT_CREATE_ACCOUNT);
    }
  }

  public async updateTimezone(
    subscriberId: string,
    timezone: string
  ): Promise<Result<string>> {
    try {
      await this.accountProvider.updateTimezone(subscriberId, timezone);
      return success(timezone);
    } catch (error) {
      return failure(AccountServiceErrorCode.CANNOT_UPDATE);
    }
  }

  public async activate(
    subscriberId: string,
    activationCommand: ServicesActivationCommand
  ): Promise<Result<AccountData>> {
    try {
      await this.runActivationCommand(subscriberId, activationCommand);
      const { data } = await this.accountProvider.get(subscriberId);
      return success(data);
    } catch (error) {
      return failure(AccountServiceErrorCode.CANNOT_ACTIVATE);
    }
  }

  private async runActivationCommand(
    subscriberId: string,
    activationCommand: ServicesActivationCommand
  ): Promise<void> {
    for (const serviceCommand of activationCommand) {
      const { service, serviceProfile } = serviceCommand;
      await this.controlProvider.activateService(
        subscriberId,
        service,
        serviceProfile
      );
      if ("enabled" in serviceCommand) {
        await this.accountProvider.toggleService(
          subscriberId,
          service,
          serviceCommand.enabled
        );
      }
    }
  }

  public async updateBypassPin(
    subscriberId: string,
    updates: BypassPinUpdates
  ): Promise<Result<BypassPinUpdates>> {
    try {
      await this.accountProvider.updateBypassPin(subscriberId, updates);
      return success(updates);
    } catch (error) {
      return failure(AccountServiceErrorCode.CANNOT_UPDATE);
    }
  }

  private async toggleService(
    subscriberId: string,
    service: Service,
    enabled: boolean
  ): Promise<Result<boolean>> {
    try {
      await this.accountProvider.toggleService(subscriberId, service, enabled);
      return success(enabled);
    } catch (error) {
      return failure(AccountServiceErrorCode.CANNOT_UPDATE);
    }
  }

  public async togglePI(
    subscriberId: string,
    enabled: boolean
  ): Promise<Result<boolean>> {
    return this.toggleService(subscriberId, Service.PersonalInternet, enabled);
  }

  public async toggleSS(
    subscriberId: string,
    enabled: boolean
  ): Promise<Result<boolean>> {
    return this.toggleService(subscriberId, Service.SubscriberSafety, enabled);
  }

  public async activateSS(subscriberId: string): Promise<Result<boolean>> {
    try {
      await this.controlProvider.activateService(
        subscriberId,
        Service.SubscriberSafety
      );
      return success(true);
    } catch (error) {
      return failure(AccountServiceErrorCode.CANNOT_UPDATE);
    }
  }
}
