import React, { memo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { NavLink, useParams } from "react-router-dom";

import { ProfileTab } from "../profiles/components/profileTab/ProfileTab";
import {
  getLogicalDevicesList,
  getDevicesByProfile
} from "../../store/devices/devices.selectors";
import { DefaultProfileContent } from "./components/defaultProfileContent/DefaultProfileContent";
import { NonDefaultProfileContent } from "./components/nonDefaultProfileContent/NonDefaultProfileContent";
import { SettingsSidebarNavigation } from "../../components/settingsSidebarNavigation/SettingsSidebarNavigation";
import { DEFAULT_PROFILE } from "../../store/profiles/fixedProfiles";
import { getVisibleProfiles } from "../../store/profiles/profiles.selectors";

import "./Devices.scss";

export const Devices = () => {
  const { profileId: selected } = useParams();
  const saved = useSelector(getLogicalDevicesList, _.isEqual);
  const profiles = useSelector(getVisibleProfiles, _.isEqual);

  const [defaultProfile, ...otherProfiles] = profiles;

  return (
    <div className="devices">
      <SettingsSidebarNavigation
        tabs={[
          defaultTabConfig(defaultProfile),
          ..._.map(otherProfiles, profile => profileTabConfig(profile))
        ]}
        activeTab={selected}
        activeTabClass={ProfileTab.activeClass}
        sidebarMenuHeader={
          <div className={"sidebar__header"}>
            <h2 className={"sidebar__header--main"}>
              <FormattedMessage id={"manage_devices"} />
            </h2>
            <h4>
              <FormattedMessage id={"profiles"} />
            </h4>
          </div>
        }
        sideSection={
          <DescriptionMessage
            profiles={profiles}
            selectedTab={selected}
            savedDevices={saved}
          />
        }
      />
    </div>
  );
};

const defaultTabConfig = profile => ({
  id: profile.id,
  name: profile.name,
  type: profile.type,
  header: (
    <ProfileTab
      title={profile.type}
      type={profile.type}
      to={`/settings/devices/${profile.id}`}
      as={NavLink}
    />
  ),
  content: <DefaultProfileContent />
});

const DescriptionMessage = ({ profiles, savedDevices, selectedTab }) => {
  const profile = _.find(profiles, ({ id }) => id === selectedTab);
  const devices = _.filter(savedDevices, { profile: profile.name });

  if (profile.type === DEFAULT_PROFILE.type) {
    return (
      <div className={"tab-description-message"}>
        <FormattedMessage id={`visitors_description`} />
      </div>
    );
  }

  return (
    <div className={"tab-description-message"}>
      {devices.length !== 0 ? (
        <FormattedMessage
          id={"assigned_devices"}
          values={{ count: devices.length }}
        />
      ) : (
        <FormattedMessage id={"no_devices"} />
      )}
    </div>
  );
};

const ProfileDevicesCount = memo(({ profileName }) => {
  const devicesCount = useSelector(
    state => getDevicesByProfile(profileName)(state).length
  );

  if (devicesCount === 0) {
    return <FormattedMessage id={"no_devices"} />;
  }

  return (
    <FormattedMessage
      id={"assigned_devices"}
      values={{ count: devicesCount }}
    />
  );
});

const profileTabConfig = profile => {
  return {
    id: profile.id,
    name: profile.name,
    type: profile.type,
    header: (
      <ProfileTab
        title={profile.type !== "custom" ? profile.type : profile.name}
        type={profile.type}
        description={<ProfileDevicesCount profileName={profile.name} />}
        to={`/settings/devices/${profile.id}`}
        as={NavLink}
      />
    ),
    content: <NonDefaultProfileContent profile={profile} />
  };
};
