import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { useMount } from "@sportal/cdk/hooks";

import { Mobile, NotMobile } from "../../../hocs/Responsive";
import { Table } from "../../../components/table/Table";
import { fetchLogicalDevices } from "../../../store/devices/devices.actions";
import {
  RoamingDeviceNameCard,
  RoamingDeviceNameMobileCard
} from "../roamingTables/RoamingDeviceNameCard";
import { ClickAbsorber } from "../../../components/clickAbsorber/ClickAbsorber";
import { ProtectionStatus } from "../roamingTables/ProtectionStatus";
import {
  RoamingRowSubComponent,
  RoamingRowSubComponentMobile
} from "../roamingTables/RoamingRowSubComponent";
import { timeSort } from "../../../components/table/helpers/sorting.helper";
import { useRoamingDevices } from "../roamingTables/useRoamingDevices";
import { SingleRoamingDevicesDropdownActions } from "./SingleRoamingDevicesDropdownActions";
import { ellipsifyUUID } from "../../../helpers/devices.helpers";
import { RoamingCounter } from "../../../components/roamingCounter/RoamingCounter";

import "./SingleRoamingDevicesTable.scss";

const ROAMING_COLUMNS = [
  {
    Header: <FormattedMessage id={"device_name"} />,
    accessor: "name",
    cellClassName: "table__cell--overflow",
    SortingLabel: <FormattedMessage id={"device_name"} />,
    Cell: ({
      row: {
        isExpanded,
        original: { name }
      }
    }) => <RoamingDeviceNameCard isExpanded={isExpanded} name={name} />
  },
  {
    Header: <FormattedMessage id={"protection_status"} />,
    accessor: "protectionStatus",
    cellClassName: "table__cell--center",
    headerClassName: "table__cell--center",
    Cell: ({ row }) => (
      <ProtectionStatus status={row.original.protectionStatus} />
    )
  },
  {
    Header: <FormattedMessage id={"last_seen"} />,
    accessor: "lastseen",
    sortType: timeSort,
    cellClassName: "color-secondary",
    Cell: ({ row }) => <div>{row.original.lastseen}</div>
  },
  {
    Header: <FormattedMessage id={"actions"} />,
    id: "actions",
    sortable: false,
    headerClassName: "table__cell--small-cell",
    cellClassName: "table__cell--center table__cell--overflow",
    Cell: ({
      row: {
        original: { identifier, fullName, name }
      }
    }) => (
      <ClickAbsorber>
        <SingleRoamingDevicesDropdownActions
          identifier={identifier}
          fullName={fullName}
          name={name}
        />
      </ClickAbsorber>
    )
  }
];

const ROAMING_COLUMNS_MOBILE = [
  {
    accessor: "name",
    SortingLabel: <FormattedMessage id={"device_name"} />,
    cellClassName: "table__cell--overflow",
    Cell: ({
      row: {
        isExpanded,
        original: { name, protectionStatus, lastseen, identifier }
      }
    }) => (
      <RoamingDeviceNameMobileCard
        isExpanded={isExpanded}
        name={name}
        lastseen={lastseen}
        protectionStatus={protectionStatus}
        aside={
          <SingleRoamingDevicesDropdownActions
            identifier={identifier}
            fullName={name || ellipsifyUUID(identifier)}
            name={name}
          />
        }
      />
    )
  },
  {
    SortingLabel: <FormattedMessage id={"protection_status"} />,
    accessor: "protectionStatus"
  },
  {
    accessor: "lastseen",
    sortType: timeSort,
    SortingLabel: <FormattedMessage id={"last_seen"} />
  }
];

const SEARCH_KEYS = [
  "appStatus",
  "fullName",
  "identifier",
  "lastseen",
  "model",
  "name",
  "os",
  "softwareVersion"
];

const initialSort = [{ id: "lastseen", desc: true }];

export const SingleRoamingDevicesTable = () => {
  const dispatch = useDispatch();
  useMount(() => {
    dispatch(fetchLogicalDevices());
  });
  const roamingDevices = useRoamingDevices();

  const subHeader = _.isEmpty(roamingDevices) && (
    <FormattedMessage id="roaming_devices_table_description" />
  );

  return (
    <div className="roaming-table">
      <Mobile>
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          visibleHeader={false}
          showMobileSort
          data={roamingDevices}
          hiddenColumns={["protectionStatus", "lastseen"]}
          columns={ROAMING_COLUMNS_MOBILE}
          renderRowSubComponent={RoamingRowSubComponentMobile}
          defaultSorted={initialSort}
          noDataMessageID={"no_devices_found"}
          subHeader={subHeader}
          searchAriaLabelMessage="aria_search_for_device"
          mobileSortAside={<RoamingCounter />}
        />
      </Mobile>
      <NotMobile>
        <div className="roaming-table__title">
          <FormattedMessage id="roaming_devices" />
        </div>
        <RoamingCounter />
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          data={roamingDevices}
          columns={ROAMING_COLUMNS}
          renderRowSubComponent={RoamingRowSubComponent}
          defaultSorted={initialSort}
          noDataMessageID={"no_devices_found"}
          subHeader={subHeader}
          searchAriaLabelMessage="aria_search_for_device"
        />
      </NotMobile>
    </div>
  );
};
