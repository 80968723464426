import React, { FC, Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { login } from "../../login.actions";
import Input from "../../../../components/input/Input";
import { Button } from "../../../../components/button/Button";

export const CommonLogin: FC = () => {
  const [username, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = e => {
    e.preventDefault();

    if (username) {
      dispatch(login(username, password, history));
    }
  };

  return (
    <form
      className="login-form col-md-6 col-lg-4"
      method="post"
      onSubmit={handleSubmit}
    >
      <h4 className="login__header">
        <FormattedMessage id="account_login" />
      </h4>
      <Input
        id={"inputUsername"}
        onChange={e => setUserName(e.target.value)}
        value={username}
        label={
          <Fragment>
            <FormattedMessage id={"username"} />:
          </Fragment>
        }
      />
      <Input
        id={"inputPassword"}
        type={"password"}
        onChange={e => setPassword(e.target.value)}
        value={password}
        label={
          <Fragment>
            <FormattedMessage id={"password"} />:
          </Fragment>
        }
      />
      <Button type="submit" className="login-form__button" size="large">
        <FormattedMessage id="login" />
      </Button>
    </form>
  );
};
