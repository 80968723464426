import _ from "lodash";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { FormattedMessage } from "react-intl";

import "./GlobalUrlFiltersPage.scss";

import { UrlListInfo } from "../urlListInfo/UrlListInfo";
import { UrlFiltersInfobar } from "../urlFiltersInfobar/UrlFiltersInfobar";
import { withLayout } from "../../../../components/layout/Layout";

import { NotMobile } from "../../../../hocs/Responsive";
import UrlFiltersContent from "./UrlFiltersContent";
import { GLOBAL_URL_FILTER_ID } from "../../urlFilters.constants";

const listsConfig = [
  {
    name: "black_list_blocked",
    key: "blacklist",
    icon: "fa-ban color-danger"
  },
  {
    name: "white_list_allowed",
    key: "whitelist",
    icon: "fa-check color-success"
  }
];

class GlobalUrlFiltersPage extends Component {
  render() {
    const { lists, isSingle } = this.props;

    return (
      <Fragment>
        {!isSingle && <Redirect to={"/home"} />}
        <div className="global-url-filters-page">
          <NotMobile>
            <h2 className="global-url-filters-page__header">
              <FormattedMessage id="global_url_filters" />
            </h2>
          </NotMobile>
          <div className="global-url-filters-page__container row">
            <UrlFiltersInfobar className="url-filters-page__sidebar col-xs-12 col-md-4">
              {_.map(listsConfig, ({ name, icon, key }) => (
                <UrlListInfo
                  key={key}
                  name={<FormattedMessage id={name} />}
                  icon={icon}
                  info={this.getListInfo(name, _.get(lists, `${key}.total`, 0))}
                />
              ))}
            </UrlFiltersInfobar>
            <div className="global-url-filters-page __content col-xs-12 col-md-8">
              <UrlFiltersContent profileId={GLOBAL_URL_FILTER_ID} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  getListInfo(listName, total) {
    return (
      <Fragment>
        <FormattedMessage id={listName}>
          {message => (
            <FormattedMessage
              id={"black_white_addresses"}
              values={{
                total: total,
                listName: message
              }}
            />
          )}
        </FormattedMessage>
      </Fragment>
    );
  }
}

export default _.flowRight(
  withLayout(),
  connect(
    ({
      profiles: {
        saved: { keys: profileIds }
      },
      urlFilters
    }) => ({
      isSingle: profileIds.length === 1,
      lists: urlFilters[GLOBAL_URL_FILTER_ID]
    }),
    null
  )
)(GlobalUrlFiltersPage);
