import React, { Fragment } from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { ContactInfoFooter } from "./ContactInfoFooter";
import { ConfigLink } from "../../configLink/ConfigLink";

import "./Footer.scss";

function FooterComponent(props) {
  const { footerCompanyLink, footerPhoneNumbers, footerSupportLink } = props;

  return (
    <Fragment>
      {(footerSupportLink.url || !_.isEmpty(footerPhoneNumbers)) && (
        <ContactInfoFooter {...props} />
      )}
      <div className="footer">
        <div className="footer_content">
          <div className="footer_content__block">
            &copy;
            <FormattedMessage id="years" />
            &nbsp;
            <ConfigLink to={footerCompanyLink}>
              <FormattedMessage id={"company_name"} />
            </ConfigLink>
            . &nbsp;
          </div>
          <div className="footer_content__block">
            <FormattedMessage id="rights" />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default connect(
  ({
    config: {
      footerIcons,
      footerPhoneNumbers,
      footerSupportLink,
      footerCompanyLink
    }
  }) => ({
    footerIcons,
    footerPhoneNumbers,
    footerSupportLink,
    footerCompanyLink
  })
)(FooterComponent);
