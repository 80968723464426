import * as _ from "lodash";
import { AppConfigProvider } from "./appConfig.provider";
import { failure, Result, success } from "../shared";

export interface BaseAppConfig {
  backendUrl: string;
}

type AppConfigServiceResult<T> = Result<T>;

export enum AppConfigServiceErrorCodes {
  GENERIC,
  CANNOT_LOAD_FILE,
  CANNOT_PARSE_JSON
}

export class AppConfigService<T extends BaseAppConfig> {
  private readonly defaults: Partial<T>;

  constructor(
    defaults: Partial<T>,
    private customizers,
    private provider: AppConfigProvider
  ) {
    this.defaults = _.cloneDeep(defaults);
  }

  public async get(): Promise<AppConfigServiceResult<T>> {
    try {
      const { data } = await this.provider.get();

      return success(this.mergeDefaults(data));
    } catch (error) {
      if (error.hasOwnProperty("response")) {
        return failure(AppConfigServiceErrorCodes.CANNOT_LOAD_FILE);
      }
      if (error instanceof SyntaxError) {
        return failure(AppConfigServiceErrorCodes.CANNOT_PARSE_JSON);
      }

      return failure(AppConfigServiceErrorCodes.GENERIC);
    }
  }

  private mergeDefaults(config): T {
    return _.mergeWith(
      {},
      this.defaults,
      config,
      (defaultValue, configValue, key) =>
        this.customizers[key] &&
        this.customizers[key](defaultValue, configValue)
    );
  }
}
