import React, { Fragment } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
  openMergeModal,
  openAssignModal,
  openAssignRoamingModal,
  openBlockModal,
  openUnblockModal,
  openUnmergeModal,
  openMoveModal,
  openRemoveModal,
  openEditRoamingDeviceModal,
  openRevokeRoamingDeviceAccessModal,
  openRenameModal
} from "./devices.actions";
import {
  getDevicesForMerge,
  getRoamingDetailsByIdentifier,
  isBlockActionDisabled
} from "../../store/devices/devices.selectors";
import { Icon } from "../icon/Icon";
import { isAssignActionDisabled } from "../../store/profiles/profiles.selectors";

export const useAssignAction = (
  device,
  isDeviceExists = false,
  validateImmediately = false
) => {
  const dispatch = useDispatch();

  return () =>
    dispatch(openAssignModal(device, isDeviceExists, validateImmediately));
};

/**
 * @param {*} device { identifiers: [address], type, name?, manufacturer? }
 * @param {string} messageId
 * @param {Boolean} isSavedDevice?
 * @param {Boolean} validateImmediately?
 */
export const useAssignDeviceMenuItem = (
  device,
  messageId,
  isSavedDevice = false,
  validateImmediately = false
) => {
  const assign = useAssignAction(device, isSavedDevice, validateImmediately);
  const isAssignDisabled = useSelector(isAssignActionDisabled);

  return {
    id: "assign",
    content: (
      <Fragment>
        <Icon icon="far fa-plus" />
        <span>
          <FormattedMessage id={messageId} />
        </span>
      </Fragment>
    ),
    disabled: isAssignDisabled,
    action: assign
  };
};

/**
 * @param {*} deviceToMerge { address, type, name? }
 */
export const useMergeDeviceMenuItem = deviceToMerge => {
  const dispatch = useDispatch();
  const devicesForMerge = useSelector(
    getDevicesForMerge(deviceToMerge),
    _.isEqual
  );
  const isMergeDisabled = devicesForMerge.length === 0;

  return {
    id: "merge",
    content: (
      <Fragment>
        <Icon icon="far fa-link" />
        <span>
          <FormattedMessage id="merge_devices" />
        </span>
      </Fragment>
    ),
    disabled: isMergeDisabled,
    action: () => dispatch(openMergeModal(deviceToMerge, devicesForMerge))
  };
};

/**
 * @param {*} device { identifiers: [address], type, manufacturer }
 */
export const useBlockDeviceMenuItem = device => {
  const dispatch = useDispatch();

  const isBlockDisabled = useSelector(isBlockActionDisabled);

  return {
    id: "block",
    content: (
      <Fragment>
        <Icon icon="fas fa-ban" />
        <span>
          <FormattedMessage id="block" />
        </span>
      </Fragment>
    ),
    disabled: isBlockDisabled,
    action: () => dispatch(openBlockModal(device))
  };
};

/**
 * @param {*} device { identifiers, name, profile }
 */
export const useUnmergeDeviceMenuItem = device => {
  const dispatch = useDispatch();

  return {
    id: "unmerge",
    content: (
      <Fragment>
        <Icon icon="fas fa-unlink" />
        <span>
          <FormattedMessage id="unmerge" />
        </span>
      </Fragment>
    ),
    action: () => dispatch(openUnmergeModal(device))
  };
};

export const useMoveDeviceMenuItem = deviceName => {
  const dispatch = useDispatch();

  return {
    id: "move",
    content: (
      <Fragment>
        <Icon icon="far fa-plus" />
        <span>
          <FormattedMessage id="assign_to_group" />
        </span>
      </Fragment>
    ),
    action: () => dispatch(openMoveModal(deviceName))
  };
};

export const useRemoveDeviceMenuItem = deviceName => {
  const dispatch = useDispatch();

  return {
    id: "remove",
    content: (
      <Fragment>
        <Icon icon="far fa-times" />
        <span>
          <FormattedMessage id="remove" />
        </span>
      </Fragment>
    ),
    action: () => dispatch(openRemoveModal(deviceName))
  };
};

export const useUnblockDeviceMenuItem = device => {
  const dispatch = useDispatch();

  return {
    id: "unblock",
    content: (
      <Fragment>
        <Icon icon="fas fa-unlock" />
        <span>
          <FormattedMessage id="unblock" />
        </span>
      </Fragment>
    ),
    action: () => dispatch(openUnblockModal(device))
  };
};

export const useRenameDeviceMenuItem = device => {
  const dispatch = useDispatch();

  return {
    id: "rename",
    content: (
      <Fragment>
        <Icon icon="far fa-pencil" />
        <span>
          <FormattedMessage id="rename" />
        </span>
      </Fragment>
    ),
    action: () => dispatch(openRenameModal(device))
  };
};

export const useEditRoamingDeviceMenuItem = identifier => {
  const dispatch = useDispatch();

  return {
    id: "move-roaming-device",
    content: (
      <Fragment>
        <Icon icon="far fa-pencil" />
        <span>
          <FormattedMessage id="edit_info" />
        </span>
      </Fragment>
    ),
    disabled: false,
    action: () => {
      dispatch(openEditRoamingDeviceModal(identifier));
    }
  };
};

export const useRevokeRoamingDeviceAccessMenuItem = identifier => {
  const dispatch = useDispatch();

  return {
    id: "revoke-roaming-device",
    content: (
      <Fragment>
        <Icon icon="far fa-times" />
        <span>
          <FormattedMessage id="revoke_access" />
        </span>
      </Fragment>
    ),
    disabled: false,
    action: () => {
      dispatch(openRevokeRoamingDeviceAccessModal(identifier));
    }
  };
};

export const useAssignRoamingDeviceMenuItem = identifier => {
  const dispatch = useDispatch();
  const device = useSelector(
    state => getRoamingDetailsByIdentifier(state, identifier),
    _.isEqual
  );
  const isActionDisabled = useSelector(state =>
    isAssignActionDisabled(state, device.profile)
  );

  return {
    id: "assign",
    content: (
      <Fragment>
        <Icon icon="far fa-plus" />
        <span>
          <FormattedMessage id="assign_to_group" />
        </span>
      </Fragment>
    ),
    disabled: isActionDisabled,
    action: () => dispatch(openAssignRoamingModal(device))
  };
};
