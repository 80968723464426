import { combineReducers } from "redux";
import { brandingReducer } from "./tabs/brandingForm/branding.reducer";
import { webContentReducer } from "./tabs/webFilteringForm/webContent.reducer";
import { safetyReducer } from "./tabs/malwareForm/safety.reducer";

export const reducer = combineReducers({
  branding: brandingReducer,
  webContent: webContentReducer,
  safety: safetyReducer
});
