import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { CrossButton } from "@sportal/cdk/crossButton/CrossButton";
import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import { useIntlTranslate } from "../../../../../hooks";
import { ModalDialog, ModalService } from "../../../../../components/modal";
import { Counter } from "../../../../../components/counter/Counter";
import ButtonLink from "../../../../../components/buttonLink/ButtonLink";
import { Icon } from "../../../../../components/icon/Icon";
import { Button } from "../../../../../components/button/Button";
import { ScheduleCreateDialog } from "../scheduleCreateDialog/scheduleCreateDialog";
import { SCHEDULE_LIMIT } from "../schedules.selectors";
import ScheduleRule from "../scheduleRule/ScheduleRule";
import { ScheduleRuleHint } from "../scheduleRuleHint/ScheduleRuleHint";

import "./scheduleRulesOverlay.scss";

export const ScheduleRulesOverlay = props => {
  const translate = useIntlTranslate();

  const [schedules, setSchedules] = useState(() => props.schedules);
  const {
    type,
    removeSchedule,
    timeFormat24h,
    firstDayOfWeek,
    changeSchedules,
    modal
  } = props;

  const closeOverlay = () => {
    changeSchedules(schedules);
    return modal.reject();
  };

  return (
    <ModalDialog
      rejectHandler={() => closeOverlay()}
      className={"schedule-rules-overlay"}
    >
      <ModalDialog.Header>
        <UnstyledButton
          className="schedule-rules-overlay__back"
          onClick={() => closeOverlay()}
          data-testid={"go-back-button"}
        >
          <Icon icon="far" />
        </UnstyledButton>
        <div className={"schedule-rules-overlay__title"}>
          <div>
            <h2>
              <FormattedMessage id={`${type}_rules`} />
            </h2>
          </div>
        </div>
        <CrossButton
          className="modal__header__cross-button"
          size="large"
          weight="regular"
          onClick={() => closeOverlay()}
          aria-label={translate("aria_close_dialog")}
          data-testid={"close-modal-button"}
        />
      </ModalDialog.Header>
      <ModalDialog.Body>
        <Counter
          className="schedule-rules-overlay__counter"
          label={<FormattedMessage id={"rules"} />}
          amount={schedules.length}
          limit={SCHEDULE_LIMIT}
        />
        {_.map(schedules, (schedule, key) => (
          <div className={"rule"} key={key}>
            <ScheduleRule
              {...schedule}
              selectedDays={schedule.day}
              useShortNotations={true}
              timeFormat24h={timeFormat24h}
              firstDayOfWeek={firstDayOfWeek}
            />
            <div className={"rule__actions"}>
              <UnstyledButton
                className="edit-schedule rule__actions__button "
                onClick={() => openEditModal(schedule, schedules, props)}
                aria-label={translate("aria_edit_rule")}
                data-testid={"edit-schedule-button"}
              >
                <Icon icon="far fa-pencil" />
              </UnstyledButton>
              {schedules.length > 1 && (
                <ButtonLink
                  className="remove-schedule rule__actions__button"
                  onClick={() => {
                    setSchedules(prevSchedules => {
                      return _.reject(prevSchedules, {
                        id: schedule.id
                      });
                    });
                    removeSchedule(schedule.id);
                  }}
                  aria-label={translate("aria_delete_rule")}
                  data-testid={"delete-rule-button"}
                >
                  <Icon icon="far fa-trash" />
                </ButtonLink>
              )}
            </div>
          </div>
        ))}
        {schedules.length === 1 && <ScheduleRuleHint />}
      </ModalDialog.Body>
      <ModalDialog.Footer>
        <ModalDialog.Control>
          <Button
            data-testid={"cancel-button"}
            isOutline
            size="large"
            onClick={() => closeOverlay()}
          >
            <FormattedMessage id="cancel" />
          </Button>
        </ModalDialog.Control>
      </ModalDialog.Footer>
    </ModalDialog>
  );
};

const openEditModal = (schedule, schedules, props) =>
  ModalService.show(modal => ({
    dialog: (
      <ScheduleCreateDialog
        {...props}
        {...schedule}
        isEdit={true}
        modal={modal}
        schedules={schedules}
      />
    )
  }));
