import React from "react";
import classnames from "classnames";

import "./SeparatedLists.scss";

export const SeparatedLists = ({ className, children }) => (
  <div className={classnames("separated-lists", className)}>
    {React.Children.map(children, child =>
      React.cloneElement(child, {
        className: classnames(child.props.className, "separated-lists__item")
      })
    )}
  </div>
);
