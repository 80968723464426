import { HttpServiceResponse } from "../../shared";

export interface LogicalDevicesServerData {
  content: LogicalDeviceServerData[];
  limit: number;
  total: number;
}

export interface LogicalDeviceServerData {
  profile: string;
  name: string;
  identifiers: string[];
  "last-seen": string | null; // e.g. "2019-12-19T14:10:09.000Z"
  "device-details": LogicalDeviceDetails[];
}

export type LogicalDeviceDetails =
  | LineDeviceDetails
  | MacDeviceDetails
  | RoamingDeviceDetails;

export enum LogicalDeviceDetailsTypes {
  Line = "line",
  Mac = "hardware-address",
  Roaming = "roaming"
}

export interface DeviceDetails {
  identifier: string;
  name: string;
  type: LogicalDeviceDetailsTypes;
}

interface LineDeviceDetails extends DeviceDetails {
  type: LogicalDeviceDetailsTypes.Line;
}

interface MacDeviceDetails extends DeviceDetails {
  type: LogicalDeviceDetailsTypes.Mac;
}

interface RoamingDeviceDetails extends DeviceDetails {
  type: LogicalDeviceDetailsTypes.Roaming;
  model: string;
  os: string;
  "software-version": string;
  status: "enabled" | "disabled";
  username: string;
}
export type LogicalDeviceUpdate = Omit<
  LogicalDeviceServerData,
  "last-seen" | "device-details"
>;

export interface LogicalDevicesData {
  content: LogicalDevice[];
  limit: number;
}

export interface LogicalDevice {
  profile: string;
  name: string;
  /** Identifier may be a MAC address or a phone number or a UUID */
  identifiers: string[];
  manufacturer?: string;
  lastSeen?: number | null; // timestamp in milliseconds
}

export interface ILogicalDevicesProvider {
  get: (
    subscriberId: string
  ) => Promise<HttpServiceResponse<LogicalDevicesServerData>>;
  create: (
    subscriberId: string,
    { name, id, profile }: { name: string; id: string; profile: string }
  ) => Promise<HttpServiceResponse<LogicalDeviceServerData>>;
  remove: (
    subscriberId: string,
    deviceName: string
  ) => Promise<HttpServiceResponse<undefined>>;
  update: (
    subscriberId: string,
    deviceName: string,
    updates: LogicalDeviceUpdate
  ) => Promise<HttpServiceResponse<LogicalDeviceServerData>>;
}
