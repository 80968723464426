const INCLUSIVITIES = ["()", "[]", "(]", "[)"];

export function inRange(
  number: number,
  { start, end }: { start: number; end: number },
  inclusivity = "[]"
) {
  if (!INCLUSIVITIES.includes(inclusivity)) {
    throw new Error(
      `Inclusivity parameter must be one of ${INCLUSIVITIES.join(", ")}`
    );
  }

  switch (inclusivity) {
    case "()": {
      return start < number && number < end;
    }
    case "[)": {
      return start <= number && number < end;
    }
    case "(]": {
      return start < number && number <= end;
    }
    default: {
      return start <= number && number <= end;
    }
  }
}
