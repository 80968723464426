import { useState, useCallback } from "react";

interface UseToggleCallback {
  (nextValue?): void;
}

type UseToggleResult = [boolean, UseToggleCallback];

export const useToggle = (initialValue): UseToggleResult => {
  const [value, setValue] = useState(() => Boolean(initialValue));

  const toggle = useCallback<UseToggleCallback>(
    nextValue => {
      if (typeof nextValue === "boolean") {
        setValue(nextValue);
      } else {
        setValue(currentValue => !currentValue);
      }
    },
    [setValue]
  );

  return [value, toggle];
};
