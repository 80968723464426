import React from "react";
import { FormattedMessage } from "react-intl";

import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog
} from "../../../modal";

import "./RevokeRoamingDeviceAccessDialog.scss";

export const RevokeRoamingDeviceAccessDialog = ({ modal, device }) => {
  const submit = () => modal.resolve();
  const close = () => modal.reject();

  return (
    <ModalDialog
      className="revoke-device-access-modal"
      submitHandler={submit}
      rejectHandler={close}
    >
      <CommonModalHeader>
        <FormattedMessage id="revoke_access" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <div className="revoke-device-access-modal__description">
          <FormattedMessage id="revoke_device_access_description" />
        </div>
        <div className="revoke-device-access-modal__note">
          <FormattedMessage id="revoke_device_access_note" />
        </div>
        <div className="revoke-device-access-modal__confirm-message">
          <FormattedMessage id="revoke_device_access_confirm_message" />
        </div>
        <div className="device-name">{device.name}</div>
      </ModalDialog.Body>
      <CommonModalFooter
        submitMessage={<FormattedMessage id="revoke_access" />}
        submitButtonType="destructive"
      />
    </ModalDialog>
  );
};
