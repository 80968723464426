import React from "react";
import _ from "lodash";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { getDefaultProfile } from "../../store/profiles/profiles.selectors";
import { normalizeUrl } from "../../helpers/strings.helper";
import { useValidIdForSettingsPaths } from "./useValidIdForSettingsPaths";

export const withSettingsGuard = (Component, allowedId) => () => {
  const { profileId } = useParams();
  const defaultProfileId = useSelector(state => getDefaultProfile(state).id);
  const validId = useValidIdForSettingsPaths(
    profileId,
    defaultProfileId,
    allowedId
  );
  const location = useLocation();
  const { pathname: url } = location;

  if (_.isEmpty(profileId))
    return <Redirect to={`${normalizeUrl(url)}/${defaultProfileId}`} />;

  if (validId !== profileId)
    return <Redirect to={url.replace(profileId, validId)} />;

  return <Component location={location} />;
};
