import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { getVisibleProfiles } from "../../../../../store/profiles/profiles.selectors";
import Notificator from "../../../../../components/notification/notification.actions";
import {
  ADD_SS_PROFILE,
  REMOVE_SS_PROFILE,
  SET_SUBSCRIBER_SAFETY,
  GET_SS_PROFILES_SUCCESS,
  GET_SS_PROFILES_FAILURE,
  SAVE_SS_PROFILE_SUCCESS,
  SAVE_SS_PROFILES_SUCCESS,
  ADD_SS_PROFILES,
  AddSsProfileAction,
  RemoveSsProfileAction,
  SetSubscriberSafetyAction,
  GetSsProfilesSuccessAction,
  GetSsProfilesFailureAction,
  SaveSsProfileSuccessAction,
  SSProfile,
  SaveSsProfilesSuccessAction,
  AddSsProfilesAction,
  SaveSsProfilesFailureAction,
  SAVE_SS_PROFILES_FAILURE
} from "./ssProfiles.types";
import { ActionError, SBThunkAction } from "../../../../../store/redux.types";
import { AddNotificationAction } from "../../../../../components/notification/notification.types";

export const addSsProfileRequest = (
  profileId: string
): SBThunkAction<
  Promise<void>,
  SaveSsProfilesSuccessAction | SaveSsProfilesFailureAction
> => (dispatch, getState, { api }) => {
  const {
    config: { groupDefaults: defaults = {} },
    profiles: {
      changed: { list }
    },
    subscriberInfo: { id: subscriberId }
  } = getState();
  const { name, type } = list[profileId];
  const enabled = defaults[type] && defaults[type]["subscriber-safety"];

  return api.ssm.safety
    .saveProfile(subscriberId, name, enabled)
    .then(() => {
      dispatch(addSsProfiles());
      dispatch(saveSsProfilesSuccess([{ id: profileId, enabled }]));
    })
    .catch(error => {
      dispatch({ type: SAVE_SS_PROFILES_FAILURE, payload: error });
      return Promise.reject();
    });
};

export const addSsProfiles = (): SBThunkAction<void, AddSsProfilesAction> => (
  dispatch,
  getState
) => {
  const {
    profiles,
    config: { groupDefaults: defaults = {} }
  } = getState();
  const list = getVisibleProfiles({ profiles }),
    ssProfiles = _.map(list, ({ id, type }) => ({
      id,
      enabled: defaults[type] && defaults[type]["subscriber-safety"]
    }));

  dispatch({
    type: ADD_SS_PROFILES,
    payload: ssProfiles
  });
};

export const addSsProfileWithDefault = (
  profileId: string
): SBThunkAction<void, AddSsProfileAction> => (dispatch, getState) => {
  const {
    config: { groupDefaults: defaults = {} },
    profiles: {
      changed: { list }
    }
  } = getState();
  const { type } = list[profileId];
  const enabled = defaults[type] && defaults[type]["subscriber-safety"];

  dispatch(addSsProfile({ id: profileId, enabled }));
};

export const getSsProfiles = (): SBThunkAction<
  Promise<void>,
  | GetSsProfilesSuccessAction
  | GetSsProfilesFailureAction
  | AddNotificationAction
> => (dispatch, getState, { api }) => {
  const {
    profiles: {
      saved: { list: profileList }
    },
    ssProfiles
  } = getState();

  if (_.isEmpty(ssProfiles.changed.list)) {
    return api.ssm.safety
      .get(getState().subscriberInfo.id)
      .then(({ profiles: data = [] }) => {
        dispatch(
          getSsProfilesSuccess(
            _.map(data, ssProfile => {
              const profile = _.find(_.values(profileList), {
                name: ssProfile.name
              });

              return {
                id: profile.id,
                enabled: ssProfile.enabled
              };
            })
          )
        );
      })
      .catch(error => {
        dispatch(getSsProfilesFailure(error));
        dispatch(
          Notificator.error(
            <FormattedMessage id={"failed_to_fetch_ss_profiles"} />
          )
        );
        return Promise.reject();
      });
  }

  return Promise.resolve();
};

export const saveSsProfile = (
  profileId: string
): SBThunkAction<
  Promise<void>,
  SaveSsProfilesSuccessAction | SaveSsProfilesFailureAction
> => (dispatch, getState, { api }) => {
  const {
    subscriberInfo: { id },
    profiles: {
      saved: { list }
    },
    ssProfiles: {
      changed: { list: ssList },
      saved: { list: savedSsList }
    }
  } = getState();
  const { name } = list[profileId];
  const { enabled } = ssList[profileId];

  if (enabled === savedSsList[profileId].enabled) {
    return Promise.resolve();
  }

  return api.ssm.safety
    .saveProfile(id, name, enabled)
    .then(() => dispatch(saveSsProfilesSuccess([{ id: profileId, enabled }])))
    .catch(error => {
      dispatch({ type: SAVE_SS_PROFILES_FAILURE, payload: error });
      return Promise.reject();
    });
};

export const saveSsProfiles = (
  profiles: SSProfile[]
): SBThunkAction<
  Promise<void>,
  | SaveSsProfilesSuccessAction
  | GetSsProfilesFailureAction
  | AddNotificationAction
> => (dispatch, getState, { api }) => {
  const {
    subscriberInfo: { id },
    profiles: {
      changed: { list }
    },
    ssProfiles: {
      changed: { list: ssList },
      saved: { list: savedSsList }
    }
  } = getState();
  const payload = _.filter(
    ssList,
    ({ id, enabled }) =>
      _.some(profiles, { id }) &&
      (!savedSsList[id] || savedSsList[id].enabled !== enabled)
  ).map(({ id, enabled }) => ({
    name: list[id].name,
    enabled: !!enabled
  }));

  return api.ssm.safety
    .saveProfiles(id, payload)
    .then(() => dispatch(saveSsProfilesSuccess(_.values(ssList))))
    .catch(error => {
      dispatch({ type: SAVE_SS_PROFILES_FAILURE, payload: error });
      dispatch(
        Notificator.error(
          <FormattedMessage id={"failed_to_save_ss_profiles"} />
        )
      );
      return Promise.reject();
    });
};

export const addSsProfile = ({
  id,
  enabled
}: {
  id: string;
  enabled: boolean;
}): AddSsProfileAction => ({
  type: ADD_SS_PROFILE,
  payload: { id, enabled }
});
export const removeSsProfile = (id: string): RemoveSsProfileAction => ({
  type: REMOVE_SS_PROFILE,
  payload: id
});
export const setSubscriberSafety = (
  id: string,
  enabled: boolean
): SetSubscriberSafetyAction => ({
  type: SET_SUBSCRIBER_SAFETY,
  payload: { enabled, id }
});
export const getSsProfilesSuccess = (
  ssProfiles: SSProfile[] = []
): GetSsProfilesSuccessAction => ({
  type: GET_SS_PROFILES_SUCCESS,
  payload: ssProfiles
});
export const getSsProfilesFailure = (
  error: ActionError
): GetSsProfilesFailureAction => ({
  type: GET_SS_PROFILES_FAILURE,
  payload: error
});
export const saveSsProfileSuccess = (
  ssProfile: SSProfile
): SaveSsProfileSuccessAction => ({
  type: SAVE_SS_PROFILE_SUCCESS,
  payload: ssProfile
});
export const saveSsProfilesSuccess = (
  ssProfiles: SSProfile[] = []
): SaveSsProfilesSuccessAction => ({
  type: SAVE_SS_PROFILES_SUCCESS,
  payload: ssProfiles
});
