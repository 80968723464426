import _ from "lodash";
import {
  Granularity,
  ReportSlice,
  ReportsPeriod,
  TimeFormat
} from "./reports.helper.types";
import { SECONDS_IN_DAY } from "@sportal/lib";

export function processWidgetData(
  data: ReportSlice[],
  period: ReportsPeriod,
  barTimeSliceMultiplier: number = 4
): ReportSlice[] {
  const start = getTimeStart(period);
  const end = getTimeEnd(period);

  const modified = fillZeroData(data, start, end);
  const adaptedIntervals = _adaptIntervals(
    modified,
    Granularity[period] * barTimeSliceMultiplier
  );

  return _.orderBy(adaptedIntervals, "time");
}

export function getTimeFormat(
  period: ReportsPeriod,
  is24: boolean,
  tz: string
): TimeFormat {
  switch (period) {
    case "7":
      return {
        timeZone: tz,
        hour12: !is24,
        weekday: "short",
        day: "numeric"
      };
    case "30":
      return {
        timeZone: tz,
        day: "numeric",
        month: "short"
      };
    default:
      return {
        timeZone: tz,
        hour12: !is24,
        hour: "2-digit",
        minute: "2-digit"
      };
  }
}

export function getTimeBounds(
  period: ReportsPeriod
): { start: number; end: number } {
  const end = getTimeEnd(period);
  const start = getTimeStart(period);

  return { start, end };
}

export function getTimeStart(period: ReportsPeriod): number {
  const endDate = getTimeEnd(period);

  return endDate - Number(period) * SECONDS_IN_DAY;
}

export function getTimeEnd(period: ReportsPeriod): number {
  const granularity = Granularity[period];
  const nowInSeconds = Math.trunc(Date.now() / 1000);
  const rest = nowInSeconds % granularity;

  return nowInSeconds + granularity - rest; // end of the current slice (start of the next one)
}

function _adaptIntervals(array, period: number) {
  let amount = period / 900;
  let temp = [];

  for (let i = 0; i < array.length; i += amount) {
    temp.push(_mergeObjects(array.slice(i, i + amount)));
  }

  return temp;
}

function _mergeObjects(objects) {
  const time = objects[0].time;
  const keys = _.without(_.keys(objects[0]), "time");
  const tmp = _.zipObject(keys, _.times(keys.length, _.constant(0)));

  const rtn = _.reduce(
    objects,
    (memo, object) => {
      memo = _.mapValues(memo, (val, key) => val + (object[key] || 0));

      return memo;
    },
    tmp
  );

  return Object.assign(rtn, { time });
}

function fillZeroData(
  reports: ReportSlice[],
  timeStart: number,
  timeEnd: number
): ReportSlice[] {
  const timeSliceData = _.filter(
    reports,
    ({ time }) => time >= timeStart && time <= timeEnd
  );

  const step = 900;
  const result: ReportSlice[] = [];

  let time = timeStart;
  while (time <= timeEnd) {
    const item = _.find(timeSliceData, { time });

    if (item) {
      result.push(_.clone(item));
    } else {
      result.push({ time, value: 0 });
    }

    time += step;
  }

  return result;
}
