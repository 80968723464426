import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import _ from "lodash";
import { NavLink } from "react-router-dom";

import UrlFiltersContent from "./UrlFiltersContent";
import { ProfileTab } from "../../../profiles/components/profileTab/ProfileTab";
import { getVisibleProfiles } from "../../../../store/profiles/profiles.selectors";
import { SettingsSidebarNavigation } from "../../../../components/settingsSidebarNavigation/SettingsSidebarNavigation";
import { GLOBAL_URL_FILTER_ID } from "../../urlFilters.constants";

const globalTab = {
  id: GLOBAL_URL_FILTER_ID,
  name: "global",
  type: "global",
  header: (
    <ProfileTab
      title="global"
      type="global"
      description="global_description"
      to={`/settings/url-filters/${GLOBAL_URL_FILTER_ID}`}
      as={NavLink}
    />
  ),
  content: (
    <Fragment>
      <section className={"settings-page__description"}>
        <h2>
          <FormattedMessage id={"url_filters"} />
        </h2>
        <span>
          <FormattedMessage id={"bwlists_description_global"} />
        </span>
      </section>
      <UrlFiltersContent profileId={GLOBAL_URL_FILTER_ID} />
    </Fragment>
  )
};

const getTab = profile => ({
  id: profile.id,
  name: profile.name,
  type: profile.type,
  header: (
    <ProfileTab
      title={profile.type !== "custom" ? profile.type : profile.name}
      type={profile.type}
      description={`${profile.type}_description`}
      to={`/settings/url-filters/${profile.id}`}
      as={NavLink}
    />
  ),
  content: (
    <Fragment>
      <h4>
        <FormattedMessage id={"url_check"} />
      </h4>
      <div className={"url-filters__content"}>
        <section className={"settings-page__description"}>
          <span>
            <FormattedMessage id={"bwlists_description"} />
          </span>
        </section>
        <UrlFiltersContent profileId={profile.id} />
      </div>
    </Fragment>
  )
});

export const UrlFiltersSettingPage = () => {
  const profiles = useSelector(getVisibleProfiles, _.isEqual);

  return (
    <div className="url-filters">
      <SettingsSidebarNavigation
        tabs={[globalTab, ...profiles.map(getTab)]}
        activeTabClass={ProfileTab.activeClass}
        sidebarMenuHeader={
          <div className={"sidebar__header"}>
            <h2 className={"sidebar__header--main"}>
              <FormattedMessage id={"url_filters"} />
            </h2>
            <h4>
              <FormattedMessage id={"profiles"} />
            </h4>
          </div>
        }
      />
    </div>
  );
};
