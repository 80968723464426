import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  getShowEulaFooter,
  getShowEulaHeader
} from "../../../store/config.selectors";

export interface UseEulaContextResult {
  showEulaHeader: boolean;
  showEulaFooter: boolean;
  eulaHeaderHeight: number;
  eulaFooterHeight: number;
  setEulaHeaderHeight: (height: number) => void;
  setEulaFooterHeight: (height: number) => void;
}

const MIN_HEIGHT = 30;

function getDefaultHeight(showNode?: boolean): number {
  return showNode ? MIN_HEIGHT : 0;
}

export const useEulaContext = (): UseEulaContextResult => {
  const showEulaHeader = useSelector(getShowEulaHeader);
  const showEulaFooter = useSelector(getShowEulaFooter);

  const [headerHeight, setHeaderHeight] = useState<number>(
    getDefaultHeight(showEulaHeader)
  );
  const [footerHeight, setFooterHeight] = useState<number>(
    getDefaultHeight(showEulaFooter)
  );

  return useMemo(
    () => ({
      showEulaHeader,
      showEulaFooter,
      eulaHeaderHeight: headerHeight,
      eulaFooterHeight: footerHeight,
      setEulaHeaderHeight: setHeaderHeight,
      setEulaFooterHeight: setFooterHeight
    }),
    [showEulaHeader, showEulaFooter, headerHeight, footerHeight]
  );
};
