import React, { Component } from "react";
import classnames from "classnames";

import { CharacterLimitInfo } from "../input/CharacterLimitInfo";
import { DescriptiveError } from "../input/DescriptiveError";
import { validateLength } from "../../pages/blockPageContent/helpers/form.helper";

import "./Textarea.scss";

export class Textarea extends Component {
  render() {
    const {
      className,
      onChange,
      value,
      placeholder,
      validation,
      ...attrs
    } = this.props;
    const maxCharId = `max-char-description-${attrs.id}`;

    const isInvalid =
      validation && validation.limit && validateLength(value, validation.limit);

    return (
      <div
        className={classnames("textarea", className, {
          "has-error": isInvalid
        })}
      >
        <textarea
          {...attrs}
          value={value}
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder}
          {...(validation && {
            maxLength: validation.limit,
            "aria-describedby": maxCharId
          })}
        />
        {validation && this.renderInfo(validation, value, isInvalid, maxCharId)}
      </div>
    );
  }

  renderInfo = (
    { descriptiveError, limit, showLimit },
    value,
    isInvalid,
    maxCharId
  ) => (
    <div className="textarea__info">
      <DescriptiveError
        id={maxCharId}
        validate={() => isInvalid}
        message={descriptiveError}
      />
      {showLimit && <CharacterLimitInfo limit={limit} value={value} />}
    </div>
  );
}
