import React from "react";
import _ from "lodash";

import { SeverityIndicator } from "../severityIndicator/SeverityIndicator";
import { HelpLink } from "../helpLink/HelpLink";
import { Mobile, NotMobile } from "../../hocs/Responsive";
import { SeverityTooltip } from "../severityTooltip/SeverityTooltip";
import { Tooltip } from "../tooltip/Tooltip";

import "./InfectionsList.scss";

export const InfectionsList = ({ infections }) => {
  if (_.isEmpty(infections)) {
    return <NotMobile>{"—"}</NotMobile>;
  }

  return (
    <div className={"infections-list"}>
      {_.map(infections, renderSeverityIndicator)}
    </div>
  );
};

const SeverityLink = ({ id, name }) => (
  <HelpLink section={`threat-list-${id}`}>{name}</HelpLink>
);

const renderSeverityIndicator = ({
  id,
  severity,
  name,
  description,
  notFullThreat = false
}) => {
  if (notFullThreat) {
    return (
      <div key={id} className={"threat-id"}>
        {id}
      </div>
    );
  }

  return (
    <SeverityIndicator key={id} severity={severity}>
      <NotMobile>
        <Tooltip
          content={
            <SeverityTooltip severity={severity} description={description} />
          }
        >
          <SeverityLink id={id} name={name} />
        </Tooltip>
      </NotMobile>

      <Mobile>
        <SeverityLink id={id} name={name} />
      </Mobile>
    </SeverityIndicator>
  );
};
