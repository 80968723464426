import React, { Component } from "react";
import classnames from "classnames";
import { FormattedMessage, injectIntl } from "react-intl";
import Truncate from "react-truncate";

import "./PortalTitle.scss";

import { Mobile, NotMobile } from "../../../hocs/Responsive";
import { ConfigLink } from "../../configLink/ConfigLink";

class PortalTitleContent extends Component {
  constructor(props) {
    super(props);
    this.portalNameRef = React.createRef();

    this.state = {
      portalNameWidth: 0
    };
  }

  render() {
    const { className, link, intl } = this.props;
    const { portalNameWidth } = this.state;

    return (
      <div className={classnames("portal-title", className)}>
        <ConfigLink
          to={link}
          aria-label={intl.formatMessage({ id: "aria_go_to_home" })}
        >
          <img
            className="portal-title__logo"
            src="logo_header.svg"
            alt="Portal Logo"
          />
        </ConfigLink>
        <div className="portal-title__name" ref={this.portalNameRef}>
          <Mobile>
            <Truncate
              lines={2}
              width={portalNameWidth}
              onTruncate={this.truncate}
            >
              <h1>{this.renderTittle()}</h1>
            </Truncate>
          </Mobile>

          <NotMobile>
            <div className="portal-title__name--one-liner">
              <h1>{this.renderTittle()}</h1>
            </div>
          </NotMobile>
        </div>
      </div>
    );
  }

  renderTittle() {
    return this.props.title || <FormattedMessage id="subscriber_portal" />;
  }

  truncate = () => {
    const newWidth = this.getPortalNameWidth();

    if (newWidth === this.state.portalNameWidth) {
      return;
    }

    this.setState({ portalNameWidth: newWidth });
  };

  getPortalNameWidth = () => {
    const width =
      this.portalNameRef.current && this.portalNameRef.current.clientWidth;

    if (!width) {
      return;
    }

    if (this.state.portalNameWidth === 0) {
      return width - 100;
    }

    return width - 50;
  };
}

export const PortalTitle = injectIntl(PortalTitleContent);
