import React from "react";
import { useSelector } from "react-redux";

import { MultipleUserChoice } from "./MultipleUserChoice";
import { SingleUserChoice } from "./SingleUserChoice";
import { isMultipleUserLevel } from "../../../store/account";

export const ProtectionChoicePageForProvisioned = () => {
  const isProvisionedAsMultiple = useSelector(isMultipleUserLevel);

  return isProvisionedAsMultiple ? (
    <MultipleUserChoice />
  ) : (
    <SingleUserChoice />
  );
};
