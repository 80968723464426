import React, { Component, Fragment } from "react";

import "./TimePicker.scss";

class TimePickerInput extends Component {
  render() {
    const { value, name, inputRef, focusTargetInput, ...attrs } = this.props;

    return (
      <Fragment>
        <input
          className="time-picker__input"
          name={name}
          value={value}
          ref={inputRef}
          maxLength="3"
          autoComplete="off"
          {...attrs}
        />
        <div className="time-picker__input-value" onMouseUp={focusTargetInput}>
          {value}
        </div>
      </Fragment>
    );
  }
}

export default TimePickerInput;
