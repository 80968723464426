import { MutableRefObject, useLayoutEffect } from "react";

export function useResizeObserver(
  elementRef: MutableRefObject<HTMLElement>,
  callback: ResizeObserverCallback
): void {
  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(callback);
    const node = elementRef.current;

    resizeObserver.observe(node);

    return () => resizeObserver.unobserve(node);
  }, [elementRef, callback]);
}
