import React, { useEffect, useReducer, useRef } from "react";
import _ from "lodash";
import classnames from "classnames";
import scrollIntoView from "scroll-into-view-if-needed";

import {
  useEventListener,
  usePrevious,
  useResizeObserver
} from "@sportal/cdk/hooks";
import { getFooterHeight } from "../../helpers/navigation.helper";
import { useLayoutContext } from "../layout";

import "./StickySidebar.scss";

const DEFAULT_BOTTOM_OFFSET = 16;
const SIDEBAR_RIGHT_PADDING = 15;

const getSidebarHeader = sidebar =>
  sidebar.querySelector(".sticky-sidebar__header");

const getSidebarHeaderHeight = sidebar =>
  getSidebarHeader(sidebar).clientHeight;

const getSidebarHeaderMarginBottom = sidebar =>
  parseInt(window.getComputedStyle(getSidebarHeader(sidebar)).marginBottom);

const getTotalSidebarHeaderHeight = sidebar =>
  getSidebarHeaderHeight(sidebar) + getSidebarHeaderMarginBottom(sidebar);

const getSidebarTop = sidebar => sidebar.getBoundingClientRect().top;

const getSidebarWidth = sidebar => sidebar.offsetWidth - SIDEBAR_RIGHT_PADDING;

const isSticky = (sidebar, eulaHeaderHeight) => {
  return window.scrollY > sidebar.offsetTop - eulaHeaderHeight;
};

const getAllTabsHeight = (
  isSticky,
  sidebar,
  eulaHeaderHeight,
  eulaFooterHeight
) => {
  const sidebarHeaderHeight = getSidebarHeaderHeight(sidebar);
  const sidebarTop = getSidebarTop(sidebar);
  const sidebarOffsetTop = isSticky ? 0 : sidebarTop;
  const eulaHeader = isSticky ? eulaHeaderHeight : 0;

  return (
    window.innerHeight -
    eulaHeader -
    sidebarHeaderHeight -
    sidebarOffsetTop -
    DEFAULT_BOTTOM_OFFSET -
    getFooterHeight() -
    eulaFooterHeight
  );
};

const sidebarMenuReducer = (state, nextState) => {
  if (_.isEqual(state, nextState)) {
    return state;
  }

  return nextState;
};

export const StickySidebar = ({
  menuClassName,
  children,
  sidebarMenuHeader,
  activeTab,
  tabs
}) => {
  const sidebarRef = useRef(null);
  const [state, dispatch] = useReducer(sidebarMenuReducer, {
    sticky: false,
    stickySidebarWidth: "auto",
    tabsHeight: "inherit",
    topOffset: ""
  });
  const prevTabs = usePrevious(tabs);
  const activeTabName = _.find(tabs, {id: activeTab}).name;
  const isTabsChanged = prevTabs && !_.isEmpty(_.xorBy(prevTabs, tabs, "name"));

  useEffect(() => {
    const node = sidebarRef.current;

    scrollIntoView(node.querySelector(`[data-id="${activeTab}"]`), {
      scrollMode: "if-needed",
      block: "nearest",
      boundary: node
    });
  }, [activeTab, isTabsChanged, activeTabName]);

  const { eulaHeaderHeight, eulaFooterHeight } = useLayoutContext();

  const handleScrollOrResize = () => {
    const sidebar = sidebarRef.current;
    if (!sidebar) {
      return;
    }

    const sticky = isSticky(sidebar, eulaHeaderHeight);

    dispatch({
      sticky,
      stickySidebarWidth: sticky ? `${getSidebarWidth(sidebar)}px` : "auto",
      tabsHeight: `${getAllTabsHeight(
        sticky,
        sidebar,
        eulaHeaderHeight,
        eulaFooterHeight
      )}px`,
      topOffset: sticky ? 0 : `${getTotalSidebarHeaderHeight(sidebar)}px`
    });
  };

  useResizeObserver(sidebarRef, handleScrollOrResize);
  useEventListener("scroll", handleScrollOrResize);
  useEventListener("resize", handleScrollOrResize);

  return (
    <div
      ref={sidebarRef}
      className={classnames("sticky-sidebar", menuClassName || "col-3")}
    >
      <div
        className={classnames("sticky-sidebar__container", {
          "sticky-sidebar__container--sticky": state.sticky
        })}
        style={{
          width: state.stickySidebarWidth,
          top: `${eulaHeaderHeight}px`
        }}
      >
        <div className={"sticky-sidebar__header"}>{sidebarMenuHeader}</div>
        <div
          className={"sticky-sidebar__tabs"}
          style={{ height: state.tabsHeight, top: state.topOffset }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
