import React from "react";
import classnames from "classnames";

import "./SectionHeader.scss";

export const SectionHeader = ({ children, title, className }) => (
  <div className={classnames("section-header", className)}>
    <h3 className={"section-header__title"}>{title}</h3>
    {children && (
      <div className={"section-header__description"}>{children}</div>
    )}
  </div>
);
