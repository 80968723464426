import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { SectionHeader } from "../../components/sectionHeader/SectionHeader";
import { Mobile } from "../../../../hocs/Responsive";
import { CheckboxElement } from "../../components/checkboxElement/CheckboxElement";

export const DeviceRegistration = ({
  showCode,
  showName,
  changeShowCode,
  changeShowName
}) => (
  <div className="form-section">
    <SectionHeader title={<FormattedMessage id={"device_registration"} />}>
      <FormattedMessage id={"device_registration_description"} />
    </SectionHeader>

    <Mobile>
      <div className="web-filtering-form__device-registration__controls__label">
        <FormattedMessage id="registration_options" />
      </div>
    </Mobile>

    <CheckboxElement
      data-testid="show-code"
      onChange={changeShowCode}
      isChecked={showCode}
      title="device_registration_code"
      description="device_registration_code_description"
    />
    <CheckboxElement
      data-testid="show-name"
      onChange={changeShowName}
      isChecked={showName}
      title="device_registration_name"
      description="device_registration_name_description"
    />
  </div>
);

DeviceRegistration.propTypes = {
  showCode: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  changeShowCode: PropTypes.func.isRequired,
  changeShowName: PropTypes.func.isRequired
};
