import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import Notificator from "../../../../components/notification/notification.actions";
import { getWebContentPreviewParams } from "../webFilteringForm/webContent.selectors";
import { ResourceName } from "../../resources.types";

export const loadLogo = () => async (dispatch, getState, { api }) => {
  const {
    subscriberInfo: { id }
  } = getState();

  try {
    const logo = await api.ssm.resources.getLogo(id);
    dispatch(loadLogoSuccess(logo.size > 0 ? URL.createObjectURL(logo) : null));
  } catch (e) {
    dispatch(
      Notificator.error(<FormattedMessage id={"error_failed_to_load_data"} />)
    );
    dispatch(generalFailure(e, LOAD_LOGO_FAILURE));
  }
};

export const saveBranding = () => (dispatch, getState) => {
  const {
    resources: {
      branding: { toSave, tagline, color }
    }
  } = getState();
  const palette = {
    color: toSave.color || color,
    tagline: _.isNull(toSave.tagline) ? tagline : toSave.tagline.trim()
  };

  return dispatch(changeLogo(toSave.logo)).finally(() =>
    dispatch(changePalette(palette))
  );
};

export const changePalette = palette => (dispatch, getState, { api }) => {
  const {
    subscriberInfo: { id },
    resources: {
      branding: { color, tagline }
    }
  } = getState();

  if (
    (!palette.color && _.isNull(palette.tagline)) ||
    _.isEqual(palette, { color, tagline })
  ) {
    return Promise.resolve();
  }

  return api.ssm.resources
    .updateResource(id, ResourceName.WebContent, palette)
    .then(() => dispatch(changePaletteSuccess(palette.color, palette.tagline)))
    .catch(error => {
      dispatch(
        Notificator.error(<FormattedMessage id={"couldnt_save_changes"} />)
      );
      dispatch(generalFailure(error, CHANGE_PALETTE_FAILURE));
      return Promise.reject();
    });
};

export const changeLogo = updatedLogo => (dispatch, getState, { api }) => {
  const {
    subscriberInfo: { id },
    resources: {
      branding: { logo }
    }
  } = getState();

  if (updatedLogo === logo) return Promise.resolve();

  return api.ssm.resources
    .updateLogo(id, updatedLogo)
    .then(() => {
      dispatch(changeLogoSuccess(updatedLogo));
    })
    .catch(error => {
      dispatch(
        Notificator.error(<FormattedMessage id={"couldnt_save_logo"} />)
      );
      dispatch(generalFailure(error, CHANGE_LOGO_FAILURE));
      return Promise.reject();
    });
};

export const getWebContentPreview = () => async (
  dispatch,
  getState,
  { api }
) => {
  const state = getState();
  const { id } = state.subscriberInfo;
  const previewParams = getWebContentPreviewParams(state);

  try {
    const { data } = await api.ssm.resources.getPreview(
      id,
      ResourceName.WebContent,
      previewParams
    );
    localStorage.setItem("web-filtering", data);
  } catch (e) {
    dispatch(
      Notificator.error(<FormattedMessage id={"error_failed_to_load_data"} />)
    );
    dispatch(generalFailure(e, LOAD_PREVIEW_FAILURE));
    return Promise.reject();
  }
};

export const LOAD_LOGO_SUCCESS = "[RESOURCES] [WEB_CONTENT] LOAD_LOGO_SUCCESS";
export const LOAD_LOGO_FAILURE = "[RESOURCES] [WEB_CONTENT] LOAD_LOGO_FAILURE";
export const LOAD_PREVIEW_FAILURE =
  "[RESOURCES] [WEB_CONTENT] LOAD_PREVIEW_FAILURE";
export const RESET_TO_SAVE = "[RESOURCES] [WEB_CONTENT] RESET_TO_SAVE";
export const CHANGE_PALETTE_SUCCESS =
  "[RESOURCES] [WEB_CONTENT] CHANGE_PALETTE_SUCCESS";
export const CHANGE_PALETTE_FAILURE =
  "[RESOURCES] [WEB_CONTENT] CHANGE_PALETTE_FAILURE";
export const CHANGE_CUSTOM_COLOR =
  "[RESOURCES] [WEB_CONTENT] CHANGE_CUSTOM_COLOR";
export const CHANGE_TAGLINE_TO_SAVE =
  "[RESOURCES] [WEB_CONTENT] CHANGE_TAGLINE_TO_SAVE";
export const CHANGE_COLOR_TO_SAVE =
  "[RESOURCES] [WEB_CONTENT] CHANGE_COLOR_TO_SAVE";
export const CHANGE_LOGO_TO_SAVE = "[RESOURCES] CHANGE_LOGO_TO_SAVE";
export const CHANGE_LOGO_SUCCESS =
  "[RESOURCES] [WEB_CONTENT] CHANGE_LOGO_SUCCESS";
export const CHANGE_LOGO_FAILURE =
  "[RESOURCES] [WEB_CONTENT] CHANGE_LOGO_FAILURE";

export const loadLogoSuccess = logo => ({
  type: LOAD_LOGO_SUCCESS,
  payload: { logo }
});
export const resetToSave = () => ({
  type: RESET_TO_SAVE
});
export const changePaletteSuccess = (color, tagline) => ({
  type: CHANGE_PALETTE_SUCCESS,
  payload: { color, tagline }
});
export const changeCustomColor = customColor => ({
  type: CHANGE_CUSTOM_COLOR,
  payload: customColor.toUpperCase()
});
export const changeTaglineToSave = tagline => ({
  type: CHANGE_TAGLINE_TO_SAVE,
  payload: tagline
});
export const changeColorToSave = color => ({
  type: CHANGE_COLOR_TO_SAVE,
  payload: color.toUpperCase()
});
export const changeLogoToSave = logo => ({
  type: CHANGE_LOGO_TO_SAVE,
  payload: { logo }
});
export const changeLogoSuccess = logo => ({
  type: CHANGE_LOGO_SUCCESS,
  payload: { logo }
});

export const generalFailure = (error, type) => ({
  type,
  payload: error
});
