import _ from "lodash";

import { useBarchartTooltip } from "./useBarchartTooltip";
import { useBarChartContext } from "../../BarChartProvider";

const TOOLTIP_WIDTH = 200;

export function useTooltipContent(container) {
  const { data } = useBarChartContext();

  const index = useBarchartTooltip(state => state.index);

  //prepare x axis coordinate for positioning the tooltip
  const getTooltipCoordinate = (barXPos, barWidth) => {
    if (!container.current) return { x: barXPos };
    const box = container.current.getBoundingClientRect();
    const tooltipOffset = 2.3 * barWidth;

    if (box.right - barXPos - barWidth - tooltipOffset < TOOLTIP_WIDTH) {
      return { x: barXPos - tooltipOffset - TOOLTIP_WIDTH };
    }

    return { x: barXPos + tooltipOffset };
  };

  //prepare data for the tooltip content
  if (_.isNil(index) || _.isEmpty(data)) {
    return null;
  }

  const {
    time,
    data: sliceData,
    position: { x, width }
  } = data[index];

  return {
    activeSlice: time,
    activePayload: sliceData,
    activeCoordinate: getTooltipCoordinate(x, width)
  };
}
