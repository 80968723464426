import React from "react";

import { useIntlTranslate } from "../../../../../hooks";

export const ChartNoDataMessage = () => {
  const translate = useIntlTranslate();

  return (
    <g className={"no-data-message"}>
      <text x="50%" y="40%" textAnchor="middle">
        {translate("no_available_data")}
      </text>
    </g>
  );
};
