import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { getSBServiceProfilesLimit } from "../../../store/settings/settings.selectors";
import { useIntlTranslate } from "../../../hooks";
import { Icon } from "../../../components/icon/Icon";
import { Button } from "../../../components/button/Button";

export const MultipleUserChoice = () => {
  const profilesLimit = useSelector(getSBServiceProfilesLimit);
  const translate = useIntlTranslate();

  return (
    <Fragment>
      <div className="col-xs-12 col-md-6 profile-container">
        <div className="image-container profile-multiple-ico">
          <div className="img-wrapper">
            <Icon icon="far fa-id-card" />
          </div>
          <div className="img-wrapper">
            <Icon icon="fas fa-users" />
          </div>
          <div className="img-wrapper bg-color-primary">
            <Icon icon="fas fa-cog" />
          </div>
        </div>
        <div className="description-container">
          <div className="option-title">
            <FormattedMessage id={"multiple_profiles"} />
          </div>
          <div className="option-subtitle">
            <FormattedMessage
              id={"multiple_profiles_info"}
              values={{ number: profilesLimit - 1 }}
            />
          </div>
          <div className="option-description">
            <FormattedMessage id={"multiple_profiles_description"} />
          </div>
        </div>
        <Button
          as={Link}
          to={"/wizard/multiple"}
          size={"large"}
          aria-label={translate("aria_proceed_to_multiple")}
        >
          <FormattedMessage id={"lets_go"} />
        </Button>
      </div>
    </Fragment>
  );
};
