import { sizes } from "../../hocs/Responsive";
import { useEventListener } from "@sportal/cdk/hooks";

import "./Modal.scss";

export const useMobileScreenCheck = collapse => {
  useEventListener("resize", () => {
    if (window.innerWidth > sizes.mobileMax) {
      collapse();
    }
  });
};

export { Modal } from "@sportal/cdk/modal/Modal";
export {
  ModalDialog,
  useModalDialogContext
} from "@sportal/cdk/modal/ModalDialog";
export { ModalHost } from "@sportal/cdk/modal/ModalHost";
export { ModalService } from "@sportal/cdk/modal/modal.service";
