import { HttpService, HttpServiceResponse } from "../shared";

export enum ResourceType {
  JSON = "json"
}

export class ResourceProvider {
  constructor(private http: HttpService) {}

  private validators = {
    [ResourceType.JSON]: ResourceProvider.validateJSON
  };

  public get<T>(relativePath: string, type: ResourceType) {
    return this.http
      .get<T>(`./${relativePath}.${type}`)
      .then(response => this.validate(response, type));
  }

  private validate<T extends HttpServiceResponse>(
    response: T,
    type: ResourceType
  ): T {
    const validator = this.validators[type];
    if (validator) {
      return validator(response);
    }
    return response;
  }

  static validateJSON<T extends HttpServiceResponse>(response: T): T {
    // axios shallows invalid json error so we need a workaround
    if (typeof response.data === "string") {
      JSON.parse(response.data); // It should always throws
    }
    return response;
  }
}
