import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  getGoBackOnLoginErrorLink,
  getGoBackOnLoginErrorLinkText
} from "../../../../store/config.selectors";

export const NoLogin: FC = () => {
  const goBackOnLoginErrorLink = useSelector(getGoBackOnLoginErrorLink);
  const goBackOnLoginErrorLinkText = useSelector(getGoBackOnLoginErrorLinkText);

  return (
    <div className={"login-text-block col-lg-4 col-md-6 col-sm-12"}>
      <h3 className={"login__header"}>
        <FormattedMessage id={"access_denied"} />
      </h3>
      <div>
        <FormattedMessage id={"no_login_warning"} />
        <a href={goBackOnLoginErrorLink}>{goBackOnLoginErrorLinkText}</a>.
      </div>
    </div>
  );
};
