import _ from "lodash";
import {
  SET_SEARCH_STRING,
  SET_CUSTOM_PROTECTION,
  GET_CUSTOM_PROTECTION_SUCCESS,
  SAVE_CUSTOM_PROTECTION_SUCCESS,
  WIPE_CUSTOM_PROTECTION
} from "./webFilters.actions";
import { RESET_ALL_PROFILES } from "../../../../store/profiles/profiles.types";
import { remap } from "../../../../utils/remap";
import { UPDATE_PROFILE_IDS } from "../../../../store/profiles/createProfiles.actions";

/*
   interface WebFiltersState {
     customProtections: {[profileId: string]: string[]},
     // Last synced with BE protections
     _customProtections: {[profileId: string]: string[]},
     searchString: string
   }
 */

const initialState = {
  customProtections: {},
  _customProtections: {},
  searchString: ""
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case WIPE_CUSTOM_PROTECTION: {
      const profileId = action.payload;
      return {
        ...state,
        customProtections: { ...state.customProtections, [profileId]: [] },
        _customProtections: { ...state._customProtections, [profileId]: [] }
      };
    }
    case GET_CUSTOM_PROTECTION_SUCCESS: {
      const customProtections = setCustomProtection(
        state.customProtections,
        action.payload
      );
      return {
        ...state,
        customProtections,
        _customProtections: _.cloneDeep(customProtections)
      };
    }
    case SAVE_CUSTOM_PROTECTION_SUCCESS: {
      const profileId = action.payload;
      return {
        ...state,
        _customProtections: {
          ...state._customProtections,
          [profileId]: state.customProtections[profileId]
        }
      };
    }
    case SET_CUSTOM_PROTECTION: {
      const customProtections = setCustomProtection(
        state.customProtections,
        action.payload
      );
      return {
        ...state,
        customProtections
      };
    }
    case SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload
      };
    case RESET_ALL_PROFILES:
      return {
        ...state,
        customProtections: _.cloneDeep(state._customProtections)
      };
    case UPDATE_PROFILE_IDS:
      return {
        ...state,
        customProtections: remap(state.customProtections, action.payload.map),
        _customProtections: remap(state._customProtections, action.payload.map)
      };
    default:
      return state;
  }
};

const setCustomProtection = (protections, { categories, profileId }) => ({
  ...protections,
  [profileId]: categories
});
