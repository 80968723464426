import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { SectionHeader } from "../sectionHeader/SectionHeader";
import { getMaxLengthValidationProps } from "../../helpers/form.helper";
import Input from "../../../../components/input/Input";
import { useIntlTranslate } from "../../../../hooks";

import "./AdminInfo.scss";

export const AdminInfo = ({ email, phone }) => {
  const translate = useIntlTranslate();

  return (
    <div className="form-section">
      <SectionHeader title={<FormattedMessage id={"admin_contacts"} />}>
        <FormattedMessage id={"provide_admin_contacts_description"} />
      </SectionHeader>
      <div className="admin-info">
        <Input
          id="admin-email-input"
          data-testid="admin-email"
          className="col-12 admin-info__field"
          icon="fas fa-envelope color-primary"
          onChange={e => email.action(e.target.value)}
          onClear={() => email.action("")}
          value={email.value || ""}
          placeholder={translate("enter_email")}
          label={<FormattedMessage id="admin_email" />}
          errorMessage={
            !email.isEmailPatternValid && <FormattedMessage id="email_error" />
          }
          {...getMaxLengthValidationProps(email.validation, email.value)}
        />
        <Input
          id="admin-phone-input"
          data-testid="admin-phone"
          className="col-12 admin-info__field"
          icon="fas fa-phone-square color-primary"
          onChange={e => phone.action(e.target.value)}
          onClear={() => phone.action("")}
          value={phone.value || ""}
          placeholder={translate("enter_phone_number")}
          label={<FormattedMessage id="admin_phone_number" />}
          {...getMaxLengthValidationProps(phone.validation, phone.value)}
        />
      </div>
    </div>
  );
};

AdminInfo.propTypes = {
  email: PropTypes.shape({
    value: PropTypes.string,
    action: PropTypes.func.isRequired,
    validation: PropTypes.object,
    isEmailPatternValid: PropTypes.bool
  }).isRequired,
  phone: PropTypes.shape({
    value: PropTypes.string,
    action: PropTypes.func.isRequired,
    validation: PropTypes.object
  }).isRequired
};
