import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { scroller, animateScroll as scroll } from "react-scroll";

import "./HelpPage.scss";

import { withLayout } from "../../components/layout/Layout";
import { HelpPageSidebar } from "./HelpPageSidebar/HelpPageSidebar";
import { ThreatList } from "./ThreatList/ThreatList";
import { HelpHeader } from "./HelpHeader/HelpHeader";
import { HelpContent } from "./HelpContent";
import { Mobile, NotMobile } from "../../hocs/Responsive";
import { loadHelp, setSearchResult } from "./help.actions";
import { fetchThreats } from "../../store/threats/threats.actions";
import { getHelp } from "./help.reducer";
import { HelpSearch } from "./HelpSearch/HelpSearch";
import { isMobile } from "../../helpers/navigation.helper";
import { getOffset } from "../../utils/getOffset";

const MOBILE_OFFSET = -140;
const NOT_MOBILE_OFFSET = -80;

export class HelpPageComponent extends Component {
  get scrollConfig() {
    const { eulaHeaderHeight } = this.props;
    const basicOffset = isMobile() ? MOBILE_OFFSET : NOT_MOBILE_OFFSET;

    return {
      smooth: true,
      duration: 300,
      offset: basicOffset - eulaHeaderHeight
    };
  }

  state = {
    searchString: "", // value used for search
    searchInputValue: "" // value in search input
  };

  componentDidMount() {
    const {
      loadHelp,
      fetchThreats,
      isUserAuthenticated,
      setSearchResult
    } = this.props;

    loadHelp();
    if (isUserAuthenticated) {
      fetchThreats();
    }
    setSearchResult({});
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.searchString, this.state.searchString)) {
      this.scrollWalker = this.makeScrollWalker();
      this.scrollWalker.next(1);
    }
  }

  handleContentLoad = () => {
    const {
      match: {
        params: { section }
      }
    } = this.props;
    if (section) {
      scroller.scrollTo(section, this.scrollConfig);
    }
  };

  handleContentClick = ({ target }) => {
    if (target.nodeName === "A" && _.startsWith(target.hash, "#")) {
      scroller.scrollTo(_.last(_.split(target.hash, "/")), this.scrollConfig);
    }
  };

  render() {
    const {
      help: { sections, error, hasNoMatches },
      match,
      setSearchResult
    } = this.props;
    const { section } = match.params;
    // Kludge for rendering ThreatList inside of section
    // so that scrollspy can handle it properly
    (
      _.find(sections, { id: "threat-list" }) || {}
    ).after = this.renderThreatList();

    return (
      <div className="help-page">
        <NotMobile>
          <aside className="help-page__sidebar">
            <HelpPageSidebar
              sections={sections}
              activeSection={section}
              onSearchChange={this.handleSearchChange}
              onDoSearch={this.handleDoSearch}
              hasNoMatches={hasNoMatches}
              searchValue={this.state.searchInputValue}
            />
          </aside>
        </NotMobile>
        <Mobile>
          <HelpSearch
            hasNoMatches={hasNoMatches}
            onChange={this.handleSearchChange}
            onGoToNext={this.handleGoToNext}
            onGoToPrevious={this.handleGoToPrevious}
            onSearchChange={this.handleSearchChange}
            onDoSearch={this.handleDoSearch}
            value={this.state.searchInputValue}
          />
        </Mobile>
        <article
          className="help-page__content"
          onClick={this.handleContentClick}
        >
          {error
            ? this.renderError(error.key)
            : !_.isEmpty(sections) && (
                <HelpContent
                  className="help-page__content-section"
                  sections={sections}
                  searchString={this.state.searchString}
                  onHighlightDone={setSearchResult}
                  onLoad={this.handleContentLoad}
                />
              )}
        </article>
      </div>
    );
  }

  renderThreatList() {
    const { threats, useFallbackLanguage, isUserAuthenticated } = this.props;

    if (!isUserAuthenticated) {
      return this.renderError("please_login_to_see_threat_list");
    }

    if (_.isEmpty(threats)) {
      return this.renderError("cannot_load_threat_list");
    }

    return (
      <ThreatList threats={threats} useFallbackLanguage={useFallbackLanguage} />
    );
  }

  renderError(key) {
    return (
      <div className="help-page__error help-section">
        <FormattedMessage id={key} />
      </div>
    );
  }

  handleDoSearch = searchString => {
    this.setState({ searchString });
  };

  handleSearchChange = searchInputValue => {
    this.setState({ searchInputValue });
  };

  handleGoToNext = () => {
    this.scrollWalker.next(1);
  };

  handleGoToPrevious = () => {
    this.scrollWalker.next(-1);
  };

  *makeScrollWalker() {
    const highlighted = document.querySelectorAll("mark");
    const max = highlighted.length;
    if (max === 0) {
      return;
    }

    let current = 0;
    while (true) {
      const offsetTop =
        getOffset(highlighted[current]).top + this.scrollConfig.offset - 20;
      scroll.scrollTo(offsetTop, this.scrollConfig);
      const offset = yield;
      current = (((current + offset) % max) + max) % max;
    }
  }
}

export const HelpPage = withLayout({
  customHeader: () => <HelpHeader />,
  showCustomHeader: true,
  showNavigation: false,
  showUserInfo: false
})(
  connect(
    state => ({
      help: getHelp(state),
      threats: _.orderBy(state.threats.list, "name"),
      useFallbackLanguage: state.config.useFallbackLanguage,
      isUserAuthenticated: _.has(state.subscriberInfo, "id")
    }),
    {
      loadHelp,
      fetchThreats,
      setSearchResult
    }
  )(HelpPageComponent)
);
