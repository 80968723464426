import React from "react";

import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import { useIntlTranslate } from "../../hooks";
import { Icon } from "../icon/Icon";

import "./CloseButton.scss";

export default ({ closeToast }) => {
  const translate = useIntlTranslate();

  return (
    <UnstyledButton
      className="notification__close-icon notification__icon--button"
      onClick={closeToast}
      aria-label={translate("aria_close")}
    >
      <Icon icon="far fa-times" />
    </UnstyledButton>
  );
};
