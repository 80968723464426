export enum MandatoryPinService {
  PI = "PI",
  SS = "SS"
}

export enum APP_CODE {
  PI = "pi",
  SB = "sb",
  SS = "ss"
}
