import React, { PureComponent } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import _ from "lodash";
import classNames from "classnames";

import "./ProtectionSearch.scss";
import Input from "../../../../../components/input/Input";
import { CategoryItem } from "../protectionEditor/CategoryItem";
import onOutsideClick from "../../../../../utils/outsideClick";
import { getMapList } from "../webFilters.selectors";

const listConfig = {
  blocked: {
    id: "blocked",
    icon: "fa-ban color-danger",
    actionName: "onAllow"
  },
  allowed: {
    id: "allowed",
    icon: "fa-check color-success",
    actionName: "onBlock"
  }
};

class ProtectionSearch extends PureComponent {
  state = {
    searchField: "",
    open: false
  };

  searchRef = null;

  componentDidMount() {
    onOutsideClick(this, () => this.setState({ open: false }), this.searchRef);
  }

  render() {
    const { inputId, intl } = this.props;

    return (
      <div className="search-section" ref={node => (this.searchRef = node)}>
        <Input
          id={inputId}
          icon="fas fa-fw fa-search"
          className={"web-filters__search"}
          placeholder={intl.formatMessage({ id: "find_category" })}
          value={this.state.searchField}
          onClear={() => this.setState({ searchField: "" })}
          onChange={this.handleChange}
          autoComplete="off"
          onFocus={() => this.setState({ open: true })}
          aria-label={intl.formatMessage({ id: "aria_find_category" })}
        />
        {!_.isEmpty(this.state.searchField) && this.renderDropdown()}
      </div>
    );
  }

  renderDropdown() {
    const list = this.getFilteredList();

    if (_.isEmpty(list)) return;

    return (
      <ul
        className={classNames("dropdown-menu", {
          "dropdown-menu--open": this.state.open
        })}
      >
        {_.map(list, (item, key) => (
          <li key={key} className="dropdown-menu__item">
            <CategoryItem
              category={item}
              icon={item.icon}
              key={item.name}
              changeAction={item.changeAction}
            />
          </li>
        ))}
      </ul>
    );
  }

  getFilteredList = () => {
    const searchField = this.state.searchField.trim();

    if (!searchField) return;

    const { blockedByWebFilter, protection } = this.props;
    const blocked = getMapList(this.props, listConfig.blocked);
    const allowed = getMapList(this.props, listConfig.allowed);
    const list = _([...blocked, ...allowed])
      .map(listItem => ({
        ...listItem,
        displayName: this.props.intl.formatMessage({
          id: `category_${listItem["name"]}`,
          defaultMessage: listItem["name"]
        }),
        warning: _.includes(blockedByWebFilter, listItem["name"]) ? (
          <FormattedMessage
            id={`blocked_by`}
            values={{ protection: <FormattedMessage id={protection} /> }}
          />
        ) : null
      }))
      .orderBy("displayName")
      .value();

    return _.sortBy(
      _.filter(list, option => {
        const pattern = new RegExp(_.escapeRegExp(searchField), "gi");

        return pattern.test(option.displayName);
      }),
      ({ displayName }) => displayName
    );
  };

  handleChange = event => {
    this.setState({
      searchField: event.target.value
    });
  };
}

export default injectIntl(ProtectionSearch);
