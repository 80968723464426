import React, { Component, Fragment } from "react";
import { SettingsButton } from "../../../../../components/settingsButton/SettingsButton";
import { FormattedMessage } from "react-intl";
import { ModalService } from "../../../../../components/modal";
import ProtectionEditorDialog from "./ProtectionEditorDialog";

import "./ProtectionEditorDialog.scss";

export class ProtectionEditorDialogContainer extends Component {
  render() {
    const {
      categories,
      blockedByWebFilter,
      protection,
      blockedRequired,
      setBlocked,
      isActive
    } = this.props;

    const btnProps = {
      isOutline: true,
      size: "large",
      className: "protection-editor__settings-btn",
      disabled: !isActive
    };

    const { blocked, allowed } = categories;

    return (
      <Fragment>
        <SettingsButton
          {...btnProps}
          label={<FormattedMessage id={"categories"} />}
          status={
            <Fragment>
              <FormattedMessage id={"blocked"} />
              <span className={"settings-btn__counter"}>
                {" "}
                {blocked.length}/{blocked.length + allowed.length}
              </span>
            </Fragment>
          }
          onClick={() =>
            openModal(
              categories,
              blockedByWebFilter,
              blockedRequired,
              protection
            )
              .then(setBlocked)
              .catch(() => null)
          }
        />
      </Fragment>
    );
  }
}

ProtectionEditorDialogContainer.defaultProps = {
  isActive: true
};

const openModal = (
  categories,
  blockedByWebFilter,
  blockedRequired,
  protection
) =>
  ModalService.show(modal => ({
    dialog: (
      <ProtectionEditorDialog
        categories={categories}
        blockedByWebFilter={blockedByWebFilter}
        blockedRequired={blockedRequired}
        protection={protection}
        modal={modal}
      />
    )
  }));
