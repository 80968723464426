import { BaseApiService } from "../baseApiService";

export class ProtectionService extends BaseApiService {
  constructor(config) {
    super(config);
    this.baseUrl = `${config.backendUrl}/ssm/service/${config.mode}`;
  }

  get() {
    return this._http
      .get(`${this.baseUrl}/protection-level`)
      .then(({ data }) => data);
  }

  getCategories() {
    return this._http
      .get(`${this.baseUrl}/protection`)
      .then(({ data }) => data);
  }

  getCategoriesByUrl(url) {
    return this._http.post(`${this.baseUrl}/protection`, url);
  }
}
