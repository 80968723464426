import React, { FC } from "react";
import classNames from "classnames";
import {
  GetInputPropsOptions,
  GetToggleButtonPropsOptions,
  GetLabelPropsOptions,
  GetMenuPropsOptions,
  GetItemPropsOptions,
  UseComboboxGetComboboxPropsOptions
} from "downshift";

import {
  SelectMenu,
  SelectSearch,
  SelectButton,
  SelectLabel,
  ISelectItem
} from "./components";

import "./Select.scss";

interface DownshiftHookProps {
  isOpen: boolean;
  selectedItem: ISelectItem;
  getInputProps?: (options?: GetInputPropsOptions) => any;
  getComboboxProps?: (options?: UseComboboxGetComboboxPropsOptions) => any;
  getToggleButtonProps: (options?: GetToggleButtonPropsOptions) => any;
  getLabelProps: (options?: GetLabelPropsOptions) => any;
  getMenuProps: (options?: GetMenuPropsOptions) => any;
  getItemProps: (options?: GetItemPropsOptions<ISelectItem>) => any;
  highlightedIndex: number;
}

export interface BaseSelectContentProps {
  items: ISelectItem[];
  className?: string;
  label?: string;
  ariaLabel?: string;
  disabled?: boolean;
  searchable?: boolean;
  searchInputPlaceholder?: string;
  noResultsMessage?: string;
}

interface SelectContentProps
  extends DownshiftHookProps,
    BaseSelectContentProps {}

export const SelectContent: FC<SelectContentProps> = ({
  items,
  className,
  label,
  ariaLabel,
  disabled,
  searchable,
  searchInputPlaceholder,
  noResultsMessage,
  isOpen,
  selectedItem,
  getInputProps,
  getComboboxProps,
  getToggleButtonProps,
  getLabelProps,
  getMenuProps,
  getItemProps,
  highlightedIndex
}) => {
  return (
    <div className={classNames("select-wrapper", className)}>
      <SelectLabel
        getLabelProps={getLabelProps}
        ariaLabel={ariaLabel}
        value={label}
      />

      <div
        className={classNames("select", {
          "select--opened": isOpen,
          "select--disabled": disabled
        })}
        {...(searchable && getComboboxProps())}
      >
        <SelectButton
          getToggleButtonProps={getToggleButtonProps}
          disabled={disabled}
          selectedItem={selectedItem}
        />

        <div className="select__menu-wrapper">
          {searchable && (
            <SelectSearch
              isOpen={isOpen}
              getInputProps={getInputProps}
              placeholder={searchInputPlaceholder}
            />
          )}

          <SelectMenu
            isOpen={isOpen}
            menuProps={getMenuProps()}
            getItemProps={getItemProps}
            items={items}
            selectedItem={selectedItem}
            highlightedIndex={highlightedIndex}
            searchable={searchable}
            noResultsMessage={noResultsMessage}
          />
        </div>
      </div>
    </div>
  );
};
