import { Service, ServiceData } from "./settings.types";

export type ServiceConfiguration = Readonly<
  Pick<ServiceData, "service"> & Partial<ServiceData>
>;

type ServiceConfigurationMap = Readonly<{
  [key: string]: ServiceConfiguration;
}>;

const SubscriberSafety: ServiceConfigurationMap = {
  Standard: { service: Service.SubscriberSafety, "standard-support": true }
};

const PersonalInternet: ServiceConfigurationMap = {
  Single: { service: Service.PersonalInternet, "household-support": true },
  Multiple: { service: Service.PersonalInternet, "perdevice-support": true }
};

export const Configuration = {
  SubscriberSafety,
  PersonalInternet
};
