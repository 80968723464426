import classnames from "classnames";
import React from "react";

import "./AppStatus.scss";

export const AppStatus = ({ status }) => {
  if (!status) {
    return "—";
  }

  const disabled = status === "disabled";
  const classes = classnames("app-status", {
    "app-status--disabled": disabled
  });
  return <span className={classes}>{status}</span>;
};
