import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import "./Flipswitch.scss";

const Flipswitch = ({
  isDisabled,
  isChecked,
  handleChange,
  name,
  id,
  label
}) => {
  return (
    <React.Fragment>
      <div className="flip-switch_text">{label}</div>
      <div className="flip-switch flip-switch_primary">
        <input
          id={id}
          type="checkbox"
          className="flip-switch__input"
          name={name}
          checked={isChecked}
          disabled={isDisabled}
          onChange={handleChange}
        />
        <label
          htmlFor={id}
          className={`flip-switch__label flip-switch__label_${
            isChecked ? "on" : "off"
          }`}
        >
          <span data-type="on">
            <FormattedMessage id="on" />
          </span>
          <span data-type="off">
            <FormattedMessage id="off" />
          </span>
        </label>
      </div>
    </React.Fragment>
  );
};

Flipswitch.defaultProps = {
  handleChange: () => {}
};

Flipswitch.propTypes = {
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default Flipswitch;
