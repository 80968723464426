import { toUnicode } from "punycode";

const URL_SEPARATOR = "/";
/**
 * Converts punycode URL to unicode
 * @param url string URL in form used by SSM: without protocol and credentials
 */
export function punyToUnicode(url = "") {
  let [hostname, ...rest] = url.split(URL_SEPARATOR);
  try {
    hostname = toUnicode(hostname);
  } catch (e) {}

  return [hostname, ...rest].join(URL_SEPARATOR);
}
