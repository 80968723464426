import * as _ from "lodash";

import { failure, Result, success } from "../../shared";
import {
  LogicalDevice,
  LogicalDevicesData,
  LogicalDeviceServerData,
  LogicalDeviceUpdate,
  ILogicalDevicesProvider
} from "./logicalDevices.types";
import { IManufacturerService } from "../manufacturer";

export enum LogicalDevicesServiceErrorCode {
  GENERIC
}

export class LogicalDevicesService {
  constructor(
    private logicalDevicesProvider: ILogicalDevicesProvider,
    private manufacturerService: IManufacturerService,
    private stripOutProfile: boolean
  ) {}

  public async get(subscriberId: string): Promise<Result<LogicalDevicesData>> {
    try {
      const {
        data: { content: devices, limit }
      } = await this.logicalDevicesProvider.get(subscriberId);

      const { data: manufacturers } = await this.manufacturerService.get(
        _.flatMap(devices, ({ identifiers }) => identifiers)
      );

      return success({
        content: devices.map(device =>
          this.rehydrateDevice(device, manufacturers)
        ),
        limit
      });
    } catch (error) {
      return failure(LogicalDevicesServiceErrorCode.GENERIC);
    }
  }

  public async create(
    subscriberId: string,
    device: { name: string; id: string; profile: string }
  ): Promise<Result<LogicalDevice>> {
    try {
      const { data: savedDevice } = await this.logicalDevicesProvider.create(
        subscriberId,
        this.preparePayload(device)
      );
      const { data: manufacturers } = await this.manufacturerService.get(
        savedDevice.identifiers
      );

      const rehydratedDevice = this.rehydrateDevice(savedDevice, manufacturers);
      const result = this.assignProfile(rehydratedDevice, device.profile);

      return success(result);
    } catch (error) {
      return failure(LogicalDevicesServiceErrorCode.GENERIC);
    }
  }

  public async update(
    subscriberId: string,
    device: { name: string },
    updates: LogicalDeviceUpdate
  ): Promise<Result<LogicalDevice>> {
    try {
      const { data: updatedDevice } = await this.logicalDevicesProvider.update(
        subscriberId,
        device.name,
        this.preparePayload(updates)
      );
      const { data: manufacturers } = await this.manufacturerService.get(
        updatedDevice.identifiers
      );

      const rehydratedDevice = this.rehydrateDevice(
        updatedDevice,
        manufacturers
      );
      const result = this.assignProfile(rehydratedDevice, updates.profile);

      return success(result);
    } catch (error) {
      return failure(LogicalDevicesServiceErrorCode.GENERIC);
    }
  }

  public async remove(
    subscriberId: string,
    device: { name: string }
  ): Promise<Result<undefined>> {
    try {
      await this.logicalDevicesProvider.remove(subscriberId, device.name);
      return success();
    } catch (error) {
      return failure(LogicalDevicesServiceErrorCode.GENERIC);
    }
  }

  private rehydrateDevice(
    device: LogicalDeviceServerData,
    manufacturers: { [mac: string]: string }
  ): LogicalDevice {
    return {
      name: device.name,
      identifiers: device.identifiers,
      profile: device.profile,
      lastSeen: device["last-seen"] && Date.parse(device["last-seen"]),
      manufacturer: this.findManufacturer(manufacturers, device.identifiers)
    };
  }

  private findManufacturer(
    manufacturers: { [mac: string]: string },
    identifiers: string[]
  ): string | undefined {
    const key = Object.keys(manufacturers).find(mac =>
      identifiers.includes(mac)
    );
    return manufacturers[key];
  }

  // When SS mode is on we shouldn't send "profile" to BE
  private preparePayload(payload) {
    if (this.stripOutProfile) {
      return _.omit(payload, "profile");
    }

    return payload;
  }

  // When we don't include "profile" in request, BE doesn't include it in response either
  // So, we are manually attaching profile value to result
  private assignProfile(payload, profile: string) {
    if (this.stripOutProfile) {
      return _.assign(payload, { profile });
    }

    return payload;
  }
}
