import React from "react";
import { FormattedMessage } from "react-intl";
import { Checkbox } from "../../../../components/checkbox/Checkbox";

export const CheckboxElement = ({
  onChange,
  isChecked,
  title,
  description,
  ...attrs
}) => (
  <div className="checkbox-wrapper">
    <Checkbox
      {...attrs}
      onChange={() => onChange()}
      isChecked={!!isChecked}
      description={description && <FormattedMessage id={description} />}
    >
      <FormattedMessage id={title} />
    </Checkbox>
  </div>
);
