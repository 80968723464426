import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import _ from "lodash";

import { getTimeStart, getTimeEnd } from "../../../helpers/reports.helper";
import {
  dateTimeFormat24h,
  dateTimeFormat12h,
  dateTimeFormatConfigs,
  TimePeriodComponentProps,
  TimePeriodFunctionArguments
} from "../time.types";
import { getTimeZone, is24HourFormat } from "../../../store/account";
import { formatIntlDateTime, SECONDS_IN_DAY } from "@sportal/lib";

//todo: convert TimeRange to hook when all target components will be refactored to functional ones
//CustomTooltip; TimePeriodSelector; DrilldownPage;

const isLessThanDay = (start: number, end: number): boolean => {
  return start > end - SECONDS_IN_DAY * 1000;
};

const getConfigs = (
  start: number,
  end: number,
  fullNotation: boolean,
  is24: boolean
): dateTimeFormatConfigs => {
  const baseConfig = is24 ? dateTimeFormat24h : dateTimeFormat12h;

  if (fullNotation) {
    return {
      start: baseConfig,
      end: baseConfig
    };
  }

  if (isLessThanDay(start, end)) {
    return {
      start: baseConfig,
      end: _.omit(baseConfig, ["year", "month", "day"])
    };
  }

  return {
    start: _.omit(baseConfig, ["year", "hour", "minute"]),
    end: baseConfig
  };
};

const convertTime = (timeStampStart, timeStampEnd, period) => {
  const start = timeStampStart || getTimeStart(period);
  const end = timeStampEnd || getTimeEnd(period);

  return {
    start: start * 1000,
    end: end * 1000
  };
};

export const TimeRange = ({
  className,
  start,
  end,
  period,
  fullNotation = false
}: TimePeriodComponentProps) => {
  const intl = useIntl();

  const is24 = useSelector(is24HourFormat);
  const tz = useSelector(getTimeZone);

  const time = convertTime(start, end, period);
  const config = getConfigs(time.start, time.end, fullNotation, is24);
  const sharedConfig = { hour12: !is24, timeZone: tz, locale: intl.locale };

  const formattedStart = formatIntlDateTime(
    Object.assign({}, config.start, sharedConfig, { time: time.start })
  );
  const formattedEnd = formatIntlDateTime(
    Object.assign({}, config.end, sharedConfig, { time: time.end })
  );

  return (
    <div className={className}>{`${formattedStart} – ${formattedEnd}`}</div>
  );
};

export const getTimeRange = ({
  start,
  end,
  period,
  is24,
  tz,
  locale
}: TimePeriodFunctionArguments): string => {
  const time = convertTime(start, end, period);
  const config = getConfigs(time.start, time.end, false, is24);
  const sharedConfig = { hour12: !is24, timeZone: tz, locale };
  const formattedStart = formatIntlDateTime(
    Object.assign({}, config.start, sharedConfig, { time: time.start })
  );
  const formattedEnd = formatIntlDateTime(
    Object.assign({}, config.end, sharedConfig, { time: time.end })
  );

  return `${formattedStart} – ${formattedEnd}`;
};
