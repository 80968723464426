import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
  generateDeeplink,
} from "../../../components/deeplinkDialog/deeplinkDialog.actions";
import { HelpLink } from "../../../components/helpLink/HelpLink";
import { Icon } from "../../../components/icon/Icon";
import { Button } from "../../../components/button/Button";
import { Tooltip } from "../../../components/tooltip/Tooltip";

import "./SpsonBanner.scss";

export const SpsonBanner = () => {
  const dispatch = useDispatch();

  function handleGenerateLinkClick() {
    dispatch(generateDeeplink());
  }


  return (<div className="spson-banner">
    <div className="spson-banner__content">
      <div className="spson-banner__title">
        <Icon icon="fas fa-shield-check icon--spson"/>
        <h3 className="text-highlighted">
          <FormattedMessage id="spson_banner_title"/>
        </h3>
      </div>
      <div className="spson-banner__description">
        <FormattedMessage id="spson_banner_description"/>
      </div>
    </div>
    <div className="spson-banner__actions">
      <Button
        size={"large"}
        className={"spson-banner__button"}
        onClick={handleGenerateLinkClick}
      >
        <FormattedMessage id={"get_started"}/>
      </Button>
      <Tooltip
        content={
          <FormattedMessage id="spson_banner_help_tooltip"/>
        }>
        <HelpLink
          className={"spson-banner__extra-info"}
          section={"sps-remote-client"}
        >
          <Icon icon={"far fa-question-circle"}/>
        </HelpLink>
      </Tooltip>
    </div>
  </div>);
};
