import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Mobile } from "../../../hocs/Responsive";
import { HelpPageNavigation } from "../HelpPageSidebar/HelpPageNavigation";
import { MobileMenu } from "../../../components/mobileMenu/MobileMenu";
import { PortalTitle } from "../../../components/layout/portalTitle/PortalTitle";
import { getHelp } from "../help.reducer";
import { useLayoutContext } from "../../../components/layout";

import "./HelpHeader.scss";

const HelpHeaderComponent = ({ sections, businessName, logoLink, match }) => {
  const { eulaHeaderHeight } = useLayoutContext();

  const { section } = match.params;

  return (
    <div
      className="header header--bordered help-header"
      style={{ top: `${eulaHeaderHeight}px` }}
    >
      <div className={"header__content"}>
        <PortalTitle title={businessName} link={logoLink} />
        <Mobile>
          <MobileMenu>
            {({ onToggleMenu }) => (
              <HelpPageNavigation
                className="help-header__navigation"
                sections={sections}
                activeSection={section}
                onToggleMenu={onToggleMenu}
              />
            )}
          </MobileMenu>
        </Mobile>
      </div>
    </div>
  );
};

export const HelpHeader = connect(state => ({
  sections: getHelp(state).sections,
  businessName: state.accountSettings.businessName,
  logoLink: state.config.logoLink
}))(withRouter(HelpHeaderComponent));
