import React, { Fragment } from "react";

import {
  useAssignDeviceMenuItem,
  useBlockDeviceMenuItem
} from "../../../components/devices/hooks";
import { DeviceDropdownMenu } from "../../../components/devices/DeviceDropdownMenu";
import { DeviceActionsToggle } from "../../../components/devices/DeviceActionsDialog";
import { Mobile, NotMobile } from "../../../hocs/Responsive";

export const DiscoveredDevicesDropdownActions = ({ device }) => {
  const menuItems = [
    useAssignDeviceMenuItem(device, "assign_to_group"),
    useBlockDeviceMenuItem(device)
  ];

  return (
    <Fragment>
      <Mobile>
        <DeviceActionsToggle
          deviceName={device.identifiers[0]}
          menuItems={menuItems}
        />
      </Mobile>
      <NotMobile>
        <DeviceDropdownMenu menuItems={menuItems} />
      </NotMobile>
    </Fragment>
  );
};
