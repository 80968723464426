import React, { FC } from "react";
import classnames from "classnames";

interface ModalBodyProps {
  className?: string;
}

export const ModalBody: FC<ModalBodyProps> = ({ children, className }) => (
  <div className={classnames("modal__body", className)}>{children}</div>
);
