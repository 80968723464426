import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { setSubscriberSafety } from "./ssProfiles.actions";
import Flipswitch from "../../../../../components/flipswitch/Flipswitch";

class SubscriberSafety extends Component {
  render() {
    const { ssProfile, profileId } = this.props;

    return (
      <Fragment>
        <div className="subscriber-safety">
          <Flipswitch
            id="subscriber-safety-switch"
            label={this.renderSwitchLabel()}
            isChecked={ssProfile.enabled}
            handleChange={({ currentTarget: { checked } }) =>
              this.props.onChange(profileId, checked)
            }
          />
        </div>
        <div className="security-description">
          <FormattedMessage id={"malware_and_phishing_tip"} />
        </div>
      </Fragment>
    );
  }

  renderSwitchLabel = () => (
    <h4>
      <FormattedMessage id={"malware_and_phishing_protection"} />
    </h4>
  );
}

export default connect(
  (
    {
      ssProfiles: {
        changed: { list }
      }
    },
    { profileId }
  ) => ({
    ssProfile: list[profileId] || {}
  }),
  dispatch => ({
    onChange: (profileId, enabled) =>
      dispatch(setSubscriberSafety(profileId, enabled))
  })
)(SubscriberSafety);
