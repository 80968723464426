import { NavLink } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export const HorizontalMenuLink = ({
  className,
  activeClassName,
  to,
  children,
  isActive
}) => (
  <NavLink
    to={to}
    className={classnames(className, "hm__item__link")}
    activeClassName={classnames(activeClassName, "hm__item__link--active")}
    isActive={isActive}
  >
    {children}
  </NavLink>
);

HorizontalMenuLink.propTypes = {
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isActive: PropTypes.func
};
