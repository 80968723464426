import React, { FC, HTMLAttributes } from "react";
import classNames from "classnames";

import "./TabControl.scss";

interface RouterLink {
  to?: string;
  activeClassName?: string;
}

interface TabConfig extends HTMLAttributes<HTMLButtonElement>, RouterLink {
  as?: any;
  disabled?: boolean;
  type?: string;
}

export interface TabItem {
  content: string;
  id: string;
  config?: TabConfig;
}

interface TabControlProps {
  item: TabItem;
  selected?: boolean;
  onClick?: (id: string) => void;
}

export const TabControl: FC<TabControlProps> = ({
  item,
  selected,
  onClick
}) => {
  const { content, id, config = {} as TabConfig } = item;
  const { as, ...props } = config;

  const Component = as || "button";

  const additionalConfig = {} as TabConfig;

  if (onClick) {
    additionalConfig.onClick = () => onClick(id);
  }

  if (Component === "button") {
    additionalConfig.type = "button";
  }

  if (selected) {
    additionalConfig.tabIndex = -1;
  }

  return (
    <Component
      {...props}
      {...additionalConfig}
      className={classNames("tab-control", props.className, {
        "tab-control--selected": selected,
        "tab-control--disabled": props.disabled
      })}
    >
      {content}
    </Component>
  );
};
