import { getConfig } from "./root.selectors";
import { RootState } from "./root.reducer";

export const isScheduleReportAvailable = (state: RootState) =>
  getConfig(state).showScheduledReports;

export const getSecureBlocksLimit = (state: RootState) =>
  getConfig(state).secureBlocksLimit;

export const getShowDrilldownPeriod = (state: RootState) =>
  getConfig(state).showDrilldownPeriod;

export const getShowEulaHeader = (state: RootState) =>
  getConfig(state).showEulaHeader;

export const getShowEulaFooter = (state: RootState) =>
  getConfig(state).showEulaFooter;

export const getBackendUrl = (state: RootState) => getConfig(state).backendUrl;

export const getShowEulaDialog = (state: RootState) =>
  getConfig(state).showEulaDialog;

export const getEulaCancelStrategy = (state: RootState) =>
  getConfig(state).eulaCancelStrategy;

export const getEulaCancelRedirectUrl = (state: RootState) =>
  getConfig(state).eulaCancelRedirectUrl;

const getConfigSecurity = (state: RootState) => getConfig(state).security;

export const getNoLoginPage = (state: RootState) =>
  getConfigSecurity(state).noLoginPage;

export const getGoBackOnLoginErrorLink = (state: RootState) =>
  getConfigSecurity(state).goBackOnLoginErrorLink;

export const getGoBackOnLoginErrorLinkText = (state: RootState) =>
  getConfigSecurity(state).goBackOnLoginErrorLinkText;
