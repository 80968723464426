import _ from "lodash";
import { sizes } from "../hocs/Responsive";
import { horizontalMenuClassName } from "../components/layout/horizontalMenu/HorizontalMenu";
import { headerClassName } from "../components/layout/header/Header";
import { staticNotificationClassName } from "../components/notification/staticNotifications/StaticNotifications";

const MOBILE_BREAKPOINT = sizes.tabletMin;
const DEFAULT_NOTIFICATION_OFFSET = 12;

function getHeaderAndMenuElements() {
  return {
    headerEl: document.getElementsByClassName(headerClassName)[0],
    menuEl: document.getElementsByClassName(horizontalMenuClassName)[0],
    staticNotificationEl: document.getElementsByClassName(
      staticNotificationClassName
    )[0]
  };
}

export function isMobile() {
  return window.innerWidth < MOBILE_BREAKPOINT;
}

export function getTopOffset(eulaHeaderHeight) {
  let { headerEl, menuEl, staticNotificationEl } = getHeaderAndMenuElements();

  if (!headerEl) {
    return;
  }

  if (isMobile()) {
    const staticNotificationHeight = staticNotificationEl
      ? staticNotificationEl.clientHeight
      : 0;

    return eulaHeaderHeight + staticNotificationHeight;
  }

  const heightSum =
    [headerEl, menuEl, staticNotificationEl]
      .map(el => (el ? el.clientHeight : 0))
      .reduce((prev, cur) => prev + cur) + eulaHeaderHeight;

  const underTheMenuOffset = heightSum - window.scrollY;
  const defaultOffset = DEFAULT_NOTIFICATION_OFFSET + eulaHeaderHeight;

  return underTheMenuOffset < defaultOffset
    ? defaultOffset
    : underTheMenuOffset;
}

export function getNavigationHeight() {
  const elements = _.values(getHeaderAndMenuElements());

  return getVisibleHeight(elements.filter(el => !!el));
}

export function getFooterHeight() {
  const footerEl = document.getElementsByClassName("footer")[0];
  const contactFooter = document.getElementsByClassName(
    "contact-info-footer"
  )[0];

  return getVisibleHeight(footerEl) + getVisibleHeight(contactFooter);
}

function getVisibleHeight(el) {
  if (!el) {
    return 0;
  }

  if (_.isArray(el)) {
    return el
      .map(el => getVisibleHeight(el) || 0)
      .reduce((prev, cur) => prev + cur);
  }

  const elementHeight = el.offsetHeight,
    windowHeight = window.innerHeight || document.documentElement.clientHeight,
    { top, bottom } = el.getBoundingClientRect();

  return Math.max(
    0,
    top > 0
      ? Math.min(elementHeight, windowHeight - top)
      : Math.min(bottom, windowHeight)
  );
}
