import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import { DevicesTypes, ellipsifyUUID } from "../../helpers/devices.helpers";
import { Icon } from "../icon/Icon";

import "./DeviceWithType.scss";
import { getRoamingDetailsByIdentifier } from "../../store/devices/devices.selectors";
import { ProtectionStatus } from "../../pages/spson/roamingTables/ProtectionStatus";

const DeviceIcon = ({ type }) => {
  let icon;

  switch (type) {
    case DevicesTypes.Mobile: {
      icon = "mobile-android-alt";
      break;
    }
    case DevicesTypes.Roaming: {
      icon = "satellite-dish";
      break;
    }
    default: {
      icon = "wifi";
    }
  }

  return <Icon icon={`fas fa-${icon}`} />;
};

const RoamingDeviceWithProtectionStatus = ({ identifier }) => {
  const details = useSelector(
    state => getRoamingDetailsByIdentifier(state, identifier),
    _.isEqual
  );

  return (
    <Fragment>
      <ProtectionStatus status={details.protectionStatus} />
      <span className="device-identifier">{ellipsifyUUID(identifier)}</span>
    </Fragment>
  );
};

function renderContent(type, identifier) {
  switch (type) {
    case DevicesTypes.Roaming: {
      return <RoamingDeviceWithProtectionStatus identifier={identifier} />;
    }
    case DevicesTypes.Mobile:
    case DevicesTypes.Wifi:
    default: {
      return (
        <Fragment>
          <DeviceIcon type={type} />
          <span>{identifier}</span>
        </Fragment>
      );
    }
  }
}

export const DeviceWithType = ({ identifier, type }) => {
  return (
    <div className={"device-with-type"}>{renderContent(type, identifier)}</div>
  );
};

DeviceWithType.propTypes = {
  identifier: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    DevicesTypes.Mobile,
    DevicesTypes.Wifi,
    DevicesTypes.Roaming
  ]).isRequired
};
