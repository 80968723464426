import _ from "lodash";
import {
  RESET_ALL_PROFILES,
  ResetProfilesAction,
  UpdateProfileIds
} from "../../../../../store/profiles/profiles.types";
import {
  ADD_SS_PROFILE,
  ADD_SS_PROFILES,
  AddSsProfileAction,
  AddSsProfilesAction,
  GET_SS_PROFILES_SUCCESS,
  GetSsProfilesSuccessAction,
  REMOVE_SS_PROFILE,
  RemoveSsProfileAction,
  SAVE_SS_PROFILE_SUCCESS,
  SAVE_SS_PROFILES_SUCCESS,
  SaveSsProfilesSuccessAction,
  SaveSsProfileSuccessAction,
  SET_SUBSCRIBER_SAFETY,
  SetSubscriberSafetyAction,
  SSProfilesState
} from "./ssProfiles.types";
import {
  addItemsToList,
  updateProfileIdsInList
} from "../../../../../store/profiles/profiles.helpers";
import { removeItemsFromList } from "../../../../../store/profiles/profiles.selectors";
import { UPDATE_PROFILE_IDS } from "../../../../../store/profiles/createProfiles.actions";

const initialState: SSProfilesState = {
  saved: {
    list: {},
    keys: []
  },
  changed: {
    list: {},
    keys: []
  }
};

type ActionTypes =
  | AddSsProfileAction
  | AddSsProfilesAction
  | SetSubscriberSafetyAction
  | GetSsProfilesSuccessAction
  | SaveSsProfileSuccessAction
  | SaveSsProfilesSuccessAction
  | RemoveSsProfileAction
  | ResetProfilesAction
  | UpdateProfileIds;

export const reducer = (
  state = initialState,
  action: ActionTypes
): SSProfilesState => {
  switch (action.type) {
    case ADD_SS_PROFILE:
    case ADD_SS_PROFILES: {
      return {
        ...state,
        changed: addItemsToList(_.castArray(action.payload), state.changed)
      };
    }
    case SET_SUBSCRIBER_SAFETY: {
      return {
        ...state,
        changed: {
          ...state.changed,
          list: {
            ...state.changed.list,
            [action.payload.id]: action.payload
          }
        }
      };
    }
    case GET_SS_PROFILES_SUCCESS: {
      return {
        ...state,
        saved: addItemsToList(action.payload, state.saved),
        changed: addItemsToList(action.payload, state.changed)
      };
    }
    case SAVE_SS_PROFILE_SUCCESS:
    case SAVE_SS_PROFILES_SUCCESS: {
      return {
        ...state,
        saved: addItemsToList(_.castArray(action.payload), state.saved)
      };
    }
    case REMOVE_SS_PROFILE: {
      return {
        ...state,
        changed: removeItemsFromList([action.payload], state.changed),
        saved: removeItemsFromList([action.payload], state.saved)
      };
    }
    case RESET_ALL_PROFILES: {
      return {
        ...state,
        changed: _.cloneDeep(state.saved)
      };
    }
    case UPDATE_PROFILE_IDS:
      return {
        ...state,
        saved: updateProfileIdsInList(state.saved, action.payload.map),
        changed: updateProfileIdsInList(state.changed, action.payload.map)
      };
    default: {
      return state;
    }
  }
};

export const ssProfileChanged = (
  id: string,
  ssProfiles: SSProfilesState
): boolean => {
  const {
    saved: { list: savedList },
    changed: { list: changedList }
  } = ssProfiles;

  return savedList[id] && savedList[id].enabled !== changedList[id].enabled;
};
