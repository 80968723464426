import { createContext, useContext } from "react";

import { UseEulaContextResult } from "./hooks";

const DEFAULT_LAYOUT_CONTEXT: UseEulaContextResult = {
  showEulaHeader: false,
  showEulaFooter: false,
  eulaHeaderHeight: 0,
  eulaFooterHeight: 0,
  setEulaHeaderHeight: () => {},
  setEulaFooterHeight: () => {}
};

export const LayoutContext = createContext<UseEulaContextResult>(
  DEFAULT_LAYOUT_CONTEXT
);

export const useLayoutContext = () => useContext(LayoutContext);
