import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Flipswitch from "../../../flipswitch/Flipswitch";

import "./WebFilterToggle.scss";

export const WebFilterToggle = ({ on, onChange }) => {
  return (
    <div className="web-filter-toggle">
      <Flipswitch
        id="web-filter-switch"
        label={<FormattedMessage id="web_filters" />}
        isChecked={on}
        handleChange={event => onChange(event.target.checked)}
      />
    </div>
  );
};

WebFilterToggle.propTypes = {
  on: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};
