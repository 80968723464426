import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { injectIntl } from "react-intl";

import TimePickerInput from "./TimePickerInput";
import {
  isLeftArrowPressed,
  isRightArrowPressed,
  isLeftOrRightArrowPressed
} from "../../helpers/keyboardEvents.helper";
import { Select } from "../select";

import "./TimePicker.scss";

const AMPM_OPTIONS = [
  {
    content: "AM",
    value: "am"
  },
  {
    content: "PM",
    value: "pm"
  }
];

class TimePickerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPickerFocused: false,
      lastEditedField: null,
      lastPressedIsNill: false
    };

    this.timePickerRef = null;
    this.hoursRef = null;
    this.minutesRef = null;
  }

  render() {
    const {
      timeFormat24h,
      hours,
      minutes,
      ampm,
      intl,
      ariaLabelledBy
    } = this.props;
    const { isPickerFocused } = this.state;

    const timePickerStyles = classnames("time-picker", {
      "time-picker--focused": isPickerFocused
    });

    return (
      <div
        className={"time-picker-wrapper"}
        role="group"
        aria-labelledby={ariaLabelledBy}
      >
        <div className={timePickerStyles} ref={el => (this.timePickerRef = el)}>
          <TimePickerInput
            name={"hours"}
            value={hours}
            type="tel"
            pattern="[0-9]*"
            inputRef={el => (this.hoursRef = el)}
            onKeyDown={this.handleKeyDown}
            focusTargetInput={() => this.focusTargetInput(this.hoursRef)}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleInputChange}
            aria-label={intl.formatMessage({ id: "aria_hours" })}
          />
          <div className="time-picker__divider">:</div>
          <TimePickerInput
            name={"minutes"}
            value={minutes}
            type="tel"
            pattern="[0-9]*"
            inputRef={el => (this.minutesRef = el)}
            onKeyDown={this.handleKeyDown}
            focusTargetInput={() => this.focusTargetInput(this.minutesRef)}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleInputChange}
            aria-label={intl.formatMessage({ id: "aria_minutes" })}
          />
        </div>
        {!timeFormat24h && (
          <Select
            selected={ampm}
            onChange={this.handleSelectChange}
            ariaLabel={this.props.intl.formatMessage({
              id: "aria_select_am_or_pm_time"
            })}
            items={AMPM_OPTIONS}
            small
          />
        )}
      </div>
    );
  }

  handleKeyDown = event => {
    if (!isLeftOrRightArrowPressed(event)) return;

    this.onNavigate(event);
  };

  handleFocus = () => {
    this.setState({
      isPickerFocused: true,
      lastEditedField: null,
      lastPressedIsNill: false
    });
  };

  handleBlur = () => {
    !this.timePickerRef.contains(document.activeElement) &&
      this.setState({ isPickerFocused: false });
  };

  focusTargetInput = inputRef => {
    inputRef.focus();
  };

  handleSelectChange = value => {
    this.props.onInputChange("ampm", value);
  };

  handleInputChange = event => {
    const { lastEditedField, lastPressedIsNill } = this.state;
    const target = event.target,
      name = target.name,
      prevValidValue = this.props[name],
      cursorPos = this._getCaretPosition(event.currentTarget),
      newSymbol = event.currentTarget.value[cursorPos - 1];

    let value;

    //if key is not \w set valid value
    if (!newSymbol || !newSymbol.match(/^[\w]?$/i)) {
      return;
    }

    if (name === "hours" || name === "minutes") {
      //if key is not \d set valid value
      if (!newSymbol.match(/^\d?$/)) {
        return;
      }

      //if key on this input pressed first time - take new value, if not - prev + new
      value =
        lastEditedField === null
          ? newSymbol
          : prevValidValue[prevValidValue.length - 1] + newSymbol;

      if (lastPressedIsNill) {
        value = "0" + newSymbol;
      }
      if (newSymbol === "0" && !lastEditedField) {
        this.setState({ lastPressedIsNill: true });
        value = "00";
      }
    } else {
      value = newSymbol + "M";
    }

    if (!this.props.preValidate(name, value)) {
      if (!this.props.preValidate(name, newSymbol)) {
        this._focusNextInput();
        return;
      }

      if (!lastEditedField || lastEditedField !== name) {
        value = newSymbol;
      } else {
        this._focusNextInput();
        return;
      }
    }

    this.props.onInputChange(name, value);
    lastEditedField === name && this._focusNextInput();
    this.setState({ lastEditedField: name });
  };

  onNavigate = event => {
    event.preventDefault();

    if (isRightArrowPressed(event)) {
      this._focusNextInput();
      return;
    }

    isLeftArrowPressed(event) && this._focusPrevInput();
  };

  _focusNextInput = () => {
    switch (document.activeElement && document.activeElement.name) {
      case "hours":
        return this.minutesRef.focus();
      default:
        return;
    }
  };

  _focusPrevInput = () => {
    switch (document.activeElement && document.activeElement.name) {
      case "minutes":
        return this.hoursRef.focus();
      case "ampm":
        return this.minutesRef.focus();
      default:
        return;
    }
  };

  _getCaretPosition = field => {
    // Initialize
    let iCaretPos = 0;

    // IE Support
    if (document.selection) {
      // Set focus on the element
      field.focus();

      // To get cursor position, get empty selection range
      const sel = document.selection.createRange();

      // Move selection start to 0 position
      sel.moveStart("character", -field.value.length);

      // The caret position is selection length
      iCaretPos = sel.text.length;
    } else if (field.selectionStart || field.selectionStart === "0") {
      // Firefox support
      iCaretPos = field.selectionStart;
    }

    // Return results
    return iCaretPos;
  };
}

TimePickerComponent.propTypes = {
  timeFormat24h: PropTypes.bool,
  hours: PropTypes.string,
  minutes: PropTypes.string,
  ampm: PropTypes.string,
  onInputChange: PropTypes.func,
  preValidate: PropTypes.func,
  ariaLabelledBy: PropTypes.string
};

export default injectIntl(TimePickerComponent);
