import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";

import "./SettingsPage.scss";

import { withLayout } from "../../components/layout/Layout";
import { withPageName } from "../../components/layout/horizontalMenu/PageName";
import { ProfilesSettings } from "./ProfilesSettings";
import { Devices } from "../devices/DevicesOnSettings";
import { UrlFiltersSettingPage } from "../urlFilters/components/urlFiltersPage/UrlFiltersSettingPage";
import { isMultipleUserLevel } from "../../store/account";
import { withSettingsGuard } from "../settings/withSettingsGuard";
import { GLOBAL_URL_FILTER_ID } from "../urlFilters/urlFilters.constants";

const ROUTES = {
  PROFILES: "profiles",
  URL_FILTERS: "url-filters",
  DEVICES: "devices"
};

const MultipleSettingsPageContent = () => (
  <Switch>
    <Route
      path={`/settings/${ROUTES.PROFILES}/:profileId?`}
      component={withSettingsGuard(ProfilesSettings)}
    />
    <Route
      path={`/settings/${ROUTES.DEVICES}/:profileId?`}
      component={withSettingsGuard(Devices)}
    />
    <Route
      path={`/settings/${ROUTES.URL_FILTERS}/:profileId?`}
      component={withSettingsGuard(UrlFiltersSettingPage, GLOBAL_URL_FILTER_ID)}
    />
    <Redirect to={`/settings/${ROUTES.PROFILES}`} />
  </Switch>
);

const SingleSettingsPageContent = () => (
  <Switch>
    <Route
      exact
      path={`/settings/${ROUTES.PROFILES}`}
      component={ProfilesSettings}
    />
    <Redirect to={`/settings/${ROUTES.PROFILES}`} />
  </Switch>
);

const SettingsPageContent = () => {
  const isMultiple = useSelector(isMultipleUserLevel);

  return (
    <div className="settings-page">
      {isMultiple ? (
        <MultipleSettingsPageContent />
      ) : (
        <SingleSettingsPageContent />
      )}
    </div>
  );
};

export const SettingsPage = _.flowRight(
  withLayout(),
  withPageName()
)(SettingsPageContent);
