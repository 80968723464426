export const validationRules = [
  function validateRequired(name) {
    return !name.length ? "name_required" : "";
  },
  function validateLength(name) {
    return name.length > 30 ? "name_exceed_length" : "";
  },
  function validateCharacters(name) {
    return !/^[^:/?#[\]@!$&'%()*+,;=.\\`^|"]*$/.test(name)
      ? "forbidden_characters"
      : "";
  }
];
