import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { SectionHeader } from "../../../components/sectionHeader/SectionHeader";
import { TaglineInput } from "../../../components/taglineInput/TaglineInput";

export const Tagline = ({ tagline, onChange, validation }) => (
  <section className="branding__tagline">
    <SectionHeader title={<FormattedMessage id={"tagline"} />} />
    <TaglineInput value={tagline} onChange={onChange} validation={validation} />
  </section>
);

Tagline.propTypes = {
  tagline: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validation: PropTypes.object
};
