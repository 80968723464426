import { MutableRefObject, useLayoutEffect, useRef } from "react";
import * as d3 from "d3";

import {
  animateAppear,
  animateDataTransition,
  animateWalk
} from "./animations";

const processAnimation = (
  animation: (...args) => void,
  node: SVGPathElement
): () => void => {
  animation();

  return () => {
    d3.select(node).interrupt();
  }
};

interface UseInitialAnimationResult {
  isFinished: boolean;
}

const useInitialAnimation = (
  animation: (...args) => void
): UseInitialAnimationResult => {
  const isFinished = useRef<boolean>(false);
  const setFinished = () => {
    isFinished.current = true;
  };

  useLayoutEffect(() => {
    if (isFinished.current) {
      return;
    }

    return animation(setFinished);
  });

  return {
    isFinished: isFinished.current
  };
};

export const useWalkAnimation = (
  nodeRef: MutableRefObject<SVGPathElement>
): UseInitialAnimationResult => {
  return useInitialAnimation((onComplete: () => void) => {
    const node = nodeRef.current;
    const animation = () => animateWalk(node, onComplete);

    return processAnimation(animation, node);
  });
};

export const useAppearAnimation = (
  nodeRef: MutableRefObject<SVGPathElement>
): UseInitialAnimationResult => {
  return useInitialAnimation((onComplete: () => void) => {
    const node = nodeRef.current;
    const animation = () => animateAppear(node, onComplete);

    return processAnimation(animation, node);
  });
};

export const useDataTransitionAnimation = (
  nodeRef: MutableRefObject<SVGPathElement>,
  path: string,
  isInitialAnimationComplete: boolean
): void => {
  useLayoutEffect(() => {
    if (isInitialAnimationComplete) {
      const node = nodeRef.current;
      const animation = () => animateDataTransition(node, path);

      return processAnimation(animation, node);
    }
  }, [path]); // eslint-disable-line react-hooks/exhaustive-deps
};
