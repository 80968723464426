import _ from "lodash";
import { BaseApiService } from "../baseApiService";
import { URL_TYPES } from "../../common/utils/url_types";

export class ListService extends BaseApiService {
  constructor(config) {
    super(config);
    this.baseUrl = `${config.backendUrl}/ssm/${config.mode}`;
    this.backendUrl = config.backendUrl;

    this._urlTypes = _.get(config.urlFilters, "url-types");
  }

  /**
   * @param type "blacklist" | "whitelist"
   * @param subscriberId number
   * @returns {*}
   */

  get(type, subscriberId, profileName) {
    const url = _.isEmpty(profileName)
      ? `${this.baseUrl}/${encodeURIComponent(
          subscriberId
        )}/${encodeURIComponent(type)}`
      : `${this.baseUrl}/${encodeURIComponent(
          subscriberId
        )}/profile/${encodeURIComponent(profileName)}/${encodeURIComponent(
          type
        )}`;
    return this._http.get(url).then(({ data }) => {
      return _.isNil(data)
        ? Promise.reject("Personal List has undefined content")
        : data;
    });
  }

  removeItem(type, item, subscriberId, profileName) {
    return this._updateList(type, subscriberId, profileName, {
      remove: _.castArray(item)
    });
  }

  removeItems(type, items, subscriberId, profileName) {
    return this._updateList(type, subscriberId, profileName, { remove: items });
  }

  addItem(type, item, subscriberId, profileName) {
    return this._updateList(type, subscriberId, profileName, {
      add: _.castArray(item)
    });
  }

  addItems(type, items, subscriberId, profileName) {
    return this._updateList(type, subscriberId, profileName, { add: items });
  }

  _updateList(type, subscriberId, profileName, updates) {
    const url = _.isEmpty(profileName)
      ? `${this.baseUrl}/${encodeURIComponent(
          subscriberId
        )}/${encodeURIComponent(type)}`
      : `${this.baseUrl}/${encodeURIComponent(
          subscriberId
        )}/profile/${encodeURIComponent(profileName)}/${encodeURIComponent(
          type
        )}`;

    return this._http.post(url, updates);
  }

  checkUrl(url) {
    return this._http
      .post(`${this.backendUrl}/ssm/check/list/node`, { url })
      .then(({ data }) => this._processUrls(data.result));
  }

  checkUrls(urls) {
    return this._http.post(`${this.backendUrl}/ssm/check/list/nodes`, { urls });
  }

  _processUrls(data) {
    if (data.length === 1) return data;

    const urls = _.filter(data, ({ type }) => type !== URL_TYPES.PUBLIC_SUFFIX);
    const PRIORITY_OF_TYPES = {
      [URL_TYPES.CORE_DOMAIN]: 1,
      [URL_TYPES.FQDN]: 2,
      [URL_TYPES.HOST_PATH]: 3,
      [URL_TYPES.NAKED_HOST_PATH]: 4
    };

    const _data = _.map(urls, item =>
      _.extend(item, { priority: PRIORITY_OF_TYPES[item.type] })
    );
    const filtered = _.filter(_data, i => _.includes(this._urlTypes, i.type));
    const ordered = _.sortBy(filtered, "priority");

    return _.uniqBy(ordered, "node");
  }
}
