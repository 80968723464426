import React from "react";
import PropTypes from "prop-types";

import { ellipsis } from "../../helpers/ellipsis.helper";
import { useIntlTranslate } from "../../hooks";

export const SeverityTooltip = ({ severity, description }) => {
  const translate = useIntlTranslate();

  const text = [
    severity !== 0 && translate(`${severity}_severity_level_description`),
    description && ellipsis(description, 60)
  ]
    .filter(Boolean)
    .join(". ");

  return <span>{text}</span>;
};

SeverityTooltip.propTypes = {
  severity: PropTypes.number.isRequired,
  description: PropTypes.string
};
