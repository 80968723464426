import _ from "lodash";

// TODO: Remove 'config?' if we don't use it
export interface HttpService {
  get<T = any, R = HttpServiceResponse<T>>(
    url: string,
    config?: HttpServiceRequestConfig
  ): Promise<R>;

  post<T = any, R = HttpServiceResponse<T>>(
    url: string,
    data?: any,
    config?: HttpServiceRequestConfig
  ): Promise<R>;

  put<T = any, R = HttpServiceResponse<T>>(
    url: string,
    data?: any,
    config?: HttpServiceRequestConfig
  ): Promise<R>;

  patch<T = any, R = HttpServiceResponse<T>>(
    url: string,
    data?: any,
    config?: HttpServiceRequestConfig
  ): Promise<R>;

  delete<T = any, R = HttpServiceResponse<T>>(
    url: string,
    config?: HttpServiceRequestConfig
  ): Promise<R>;
}

// Actually it is an AxiosResponse
export interface HttpServiceResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  // config: AxiosRequestConfig; // Let's consider it private, we can expose it part by part as necessary.
  request?: any;
}

// Actually it is an AxiosError
export interface HttpServiceError<T = any> extends Error {
  code?: string; // TODO: ?
  request?: any; // The request was made but no response was received
  response?: HttpServiceResponse<T>; // The request was made and the server responded with a status code that falls out of the range of 2xx
  // isAxiosError: boolean;
  // config: AxiosRequestConfig; // Let's consider it private, we can expose it part by part as necessary.
}

// Errors explicitly caused by server thus have status code
export interface ServerError<T = any> extends Error {
  response: HttpServiceResponse<T>;
}

export function isServerError(error: any): error is ServerError {
  return error.response;
}

export function statusCodeMatches(error: any, code: number | number[]) {
  return (
    isServerError(error) && _.castArray(code).includes(error.response.status)
  );
}

export interface HttpServiceRequestConfig {
  // Please move fields here from AxiosRequestConfig as necessary
}

// export interface AxiosRequestConfig {
//   url?: string;
//   method?: Method;
//   baseURL?: string;
//   transformRequest?: AxiosTransformer | AxiosTransformer[];
//   transformResponse?: AxiosTransformer | AxiosTransformer[];
//   headers?: any;
//   params?: any;
//   paramsSerializer?: (params: any) => string;
//   data?: any;
//   timeout?: number;
//   timeoutErrorMessage?: string;
//   withCredentials?: boolean;
//   adapter?: AxiosAdapter;
//   auth?: AxiosBasicCredentials;
//   responseType?: ResponseType;
//   xsrfCookieName?: string;
//   xsrfHeaderName?: string;
//   onUploadProgress?: (progressEvent: any) => void;
//   onDownloadProgress?: (progressEvent: any) => void;
//   maxContentLength?: number;
//   validateStatus?: (status: number) => boolean;
//   maxRedirects?: number;
//   socketPath?: string | null;
//   httpAgent?: any;
//   httpsAgent?: any;
//   proxy?: AxiosProxyConfig | false;
//   cancelToken?: CancelToken;
// }
