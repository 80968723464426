import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import TimePicker from "../../../../../components/timePicker/TimePicker";

import "./scheduleTime.scss";

export const ScheduleTime = props => {
  const {
    className,
    timeFormat24h,
    timeStart,
    timeEnd,
    onTimePickerChange
  } = props;

  return (
    <div
      className={classnames("schedule-time", {
        [`${className}`]: className
      })}
    >
      <div
        className={classnames("schedule-time__picker", {
          [`${className}__picker`]: className
        })}
      >
        <label id="picker-start">
          <FormattedMessage id={"starts"} />
        </label>
        <TimePicker
          timeFormat24h={timeFormat24h}
          value={timeStart}
          onChange={time => onTimePickerChange(time, "timeStart")}
          ariaLabelledBy="picker-start"
        />
      </div>
      <div
        className={classnames("schedule-time__picker", {
          [`${className}__picker`]: className
        })}
      >
        <label id="picker-end">
          <FormattedMessage id={"ends"} />
        </label>
        <TimePicker
          timeFormat24h={timeFormat24h}
          value={timeEnd}
          onChange={time => onTimePickerChange(time, "timeEnd")}
          ariaLabelledBy="picker-end"
        />
      </div>
    </div>
  );
};
