import {
  GET_SETTINGS_SUCCESS,
  SettingsActionTypes,
  SettingsSuccessPayload
} from "./settings.types";

export type SettingsState = SettingsSuccessPayload;

const initialState = {} as SettingsState;

export const reducer = (
  state: SettingsState = initialState,
  action: SettingsActionTypes
): SettingsState => {
  switch (action.type) {
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
