import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import "./BrandingForm.scss";

import {
  changeColorToSave,
  changeLogoToSave,
  changeTaglineToSave,
  getWebContentPreview,
  loadLogo,
  resetToSave,
  saveBranding
} from "./branding.actions";
import { loadWebContent } from "../webFilteringForm/webContent.actions";
import { TabControls } from "../../components/tabControls/TabControls";
import { withPromptDialog } from "../../../../hocs/withPrompt";
import { useBrandingForm } from "./useBrandingForm";
import { useMount } from "@sportal/cdk/hooks";
import { previewPage } from "../shared.actions";
import { Tagline } from "./sections/Tagline";
import { getBrandingValidation } from "./branding.selectors";
import { Logo } from "./sections/Logo";
import { Color } from "./sections/Color";
import { clearLogoFromStorage } from "../../components";

const DEFAULT_COLOR = "#02A2D8";

const BrandingFormContent = withPromptDialog(
  ({ hasChanges, save, discard }) => {
    const dispatch = useDispatch();
    const validation = useSelector(getBrandingValidation, _.isEqual);

    const { color, tagline, logo, toSave, isValid } = useBrandingForm();

    useMount(() => {
      if (!color && !tagline) {
        dispatch(loadWebContent());
      }

      if (!logo) {
        dispatch(loadLogo());
      }
    });

    const preview = async () =>
      await previewPage(dispatch, getWebContentPreview(), "web-filtering");

    return (
      <div className="branding">
        <h2 className={"blocked-page__title"}>
          <FormattedMessage id={"branding_setup"} />
        </h2>
        <section className={"branding__description"}>
          <FormattedMessage id={"branding_description"} />
        </section>
        <TabControls
          isFormValid={isValid}
          isFormUnchanged={!hasChanges}
          onReset={discard}
          onSave={save}
          onPreview={preview}
        />
        <Tagline
          tagline={
            toSave.tagline || toSave.tagline === "" ? toSave.tagline : tagline
          }
          onChange={tagline => dispatch(changeTaglineToSave(tagline))}
          validation={validation.tagline}
        />
        <div className="logo-color-wrapper">
          <Logo
            logo={toSave.logo || _.isNil(toSave.logo) ? toSave.logo : logo}
            onChange={logo => dispatch(changeLogoToSave(logo))}
          />
          <Color
            color={toSave.color || color || DEFAULT_COLOR}
            onChange={color => dispatch(changeColorToSave(color))}
          />
        </div>
      </div>
    );
  }
);

export const BrandingForm = () => {
  const dispatch = useDispatch();
  const { hasChanges } = useBrandingForm();

  const discard = () => {
    clearLogoFromStorage();
    dispatch(resetToSave());
  };
  const save = () => dispatch(saveBranding());

  return (
    <BrandingFormContent
      hasChanges={hasChanges}
      save={save}
      discard={discard}
    />
  );
};
