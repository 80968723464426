/* eslint-disable jsx-a11y/anchor-has-content */

import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { stripTrailingSlash } from "../../helpers/strings.helper";
import { useIntlTranslate } from "../../hooks";

export const useOpenHelp = section => {
  const { url, isExternal } = useSelector(state => state.config.helpPageLink);

  const helpLink = isExternal || _.isNil(section) ? url : `${url}/${section}`;

  // in demo.d the link will break without including `pathname` because full url of the
  // demo.d page looks like: {protocol}//{hostname}:{port}/secure-business/{segmentWithHash}
  const targetUrl = isExternal
    ? url
    : stripTrailingSlash(window.location.pathname) + helpLink;

  const action = useCallback(() => {
    window.open(targetUrl, "_blank", "noopener noreferrer");
  }, [targetUrl]);

  return [action, targetUrl];
};

export const HelpLink = ({ section, ...attrs }) => {
  const translate = useIntlTranslate();
  const [openHelp, url] = useOpenHelp(section);

  return (
    <a
      {...attrs}
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      onClick={event => {
        event.preventDefault();
        openHelp();
      }}
      aria-label={
        section
          ? translate("aria_see_more_information_on_help", {
              key: section
            })
          : null
      }
    />
  );
};
