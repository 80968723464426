import { useRef } from "react";
import { useMount } from "./useMount";

export function useIsMounted() {
  const mountedRef = useRef(false);

  useMount(() => {
    mountedRef.current = true;

    return () => (mountedRef.current = false);
  });

  return () => mountedRef.current;
}
