export interface ReportSlice {
  time: number;
  value: number; // | array of arrays containing [string, number]
}

export type ReportData = ReportSlice[];

export enum Durations {
  Day = "P1D",
  Week = "P7D",
  Month = "P30D"
}

export type duration = Durations.Day | Durations.Week | Durations.Month;
