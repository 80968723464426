import React, { useCallback, FC } from "react";

import { useLayoutContext } from "../../layout";
import { EulaBanner } from "../eulaBanner";

import "./EulaHeader.scss";

export const EulaHeader: FC = () => {
  const { setEulaHeaderHeight } = useLayoutContext();

  const handleHeightChange = useCallback(
    (height: number): void => {
      setEulaHeaderHeight(height);
    },
    [setEulaHeaderHeight]
  );

  return (
    <EulaBanner
      className="eula-header"
      contentId="eulaHeaderText"
      onHeightChange={handleHeightChange}
    />
  );
};
