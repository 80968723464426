import _ from "lodash";
import React, { Component } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import "./ScheduleRuleList.scss";

import { Counter } from "../../../../../components/counter/Counter";
import { ScheduleRuleListItem } from "./ScheduleRuleListItem";
import RulePopup from "../rulePopup/RulePopup";
import { getDaysShifted, SCHEDULE_LIMIT } from "../schedules.selectors";
import { ScheduleRuleHint } from "../scheduleRuleHint/ScheduleRuleHint";

export class ScheduleRuleList extends Component {
  ruleListRef = {};
  componentWillUnmount() {
    this.props.toggleEditPopup();
  }

  render() {
    const {
      timeFormat24h,
      schedules,
      changeSchedule,
      removeSchedule,
      activeRule,
      firstDayOfWeek,
      setActiveRule,
      editPopup,
      toggleEditPopup
    } = this.props;

    return (
      <div className="schedule__rule">
        <div className="schedule__rule-title">
          <Counter
            label={<FormattedMessage id={"rules"} />}
            amount={schedules.length}
            limit={SCHEDULE_LIMIT}
          />
        </div>
        <div className="schedule__rule-list" id="schedule__rule-list">
          {_.map(schedules, schedule => (
            <ScheduleRuleListItem
              className={classnames("schedule__rule-list__item", {
                "schedule__rule-list__item--active": schedule.id === activeRule
              })}
              ruleRef={list => (this.ruleListRef[schedule.id] = list)}
              key={schedule.id}
              allowDelete={schedules.length > 1}
              {...schedule}
              firstDayOfWeek={firstDayOfWeek}
              timeFormat24h={timeFormat24h}
              selectedDays={schedule.day}
              onRuleChange={changes =>
                changeSchedule({
                  ...schedule,
                  ...changes
                })
              }
              editPopup={editPopup}
              onTogglePopup={id => {
                toggleEditPopup(id);
                this.setState({ isPopupOpened: !_.isEmpty(id) });
              }}
              onMouseEnter={() => setActiveRule(schedule.id)}
              onMouseLeave={() => setActiveRule(null)}
              onRuleRemove={schedule => removeSchedule(schedule.id)}
            />
          ))}
          {schedules.length === 1 && <ScheduleRuleHint />}
        </div>
        {editPopup &&
          !_.isEmpty(editPopup.id) &&
          this.renderPopup(editPopup.id)}
      </div>
    );
  }

  renderPopup(id) {
    const {
      schedules,
      firstDayOfWeek,
      timeFormat24h,
      toggleEditPopup,
      changeSchedule,
      removeSchedule
    } = this.props;
    const schedule = _.find(schedules, {
      id: id
    });

    if (!schedule) {
      return null;
    }

    const { day, timeStart, timeEnd } = schedule;
    const activeNode = this.ruleListRef[id];
    const parent = activeNode.parentNode.getBoundingClientRect();
    const offset = activeNode.getBoundingClientRect().top - parent.top + 25;

    return (
      <RulePopup
        id={id}
        days={getDaysShifted(firstDayOfWeek)}
        selectedDays={day}
        firstDayOfWeek={firstDayOfWeek}
        timeFormat24h={timeFormat24h}
        timeStart={timeStart}
        timeEnd={timeEnd}
        isEdit={true}
        onTogglePopup={toggleEditPopup}
        style={{ top: offset }}
        allowDelete={schedules.length > 1}
        schedules={schedules}
        onRuleChange={changes =>
          changeSchedule({
            ...schedule,
            ...changes
          })
        }
        onRuleRemove={schedule => removeSchedule(schedule.id)}
      />
    );
  }
}
