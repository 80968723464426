import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";

import { hasChanges, validateLength } from "../../helpers/form.helper";
import {
  getBrandingColor,
  getBrandingTagline,
  getBrandingLogo,
  getBrandingToSave,
  getBrandingValidation
} from "./branding.selectors";

export const useBrandingForm = () => {
  const toSave = useSelector(getBrandingToSave, shallowEqual);
  const validation = useSelector(getBrandingValidation, _.isEqual);
  const color = useSelector(getBrandingColor);
  const tagline = useSelector(getBrandingTagline);
  const logo = useSelector(getBrandingLogo);

  const isValid = !validateLength(
    toSave.tagline,
    validation.tagline && validation.tagline.maxLength
  );

  return {
    toSave,
    color,
    tagline,
    logo,
    isValid,
    hasChanges: hasChanges({ logo, tagline, color }, toSave)
  };
};
