import _ from "lodash";
import { BaseApiService } from "../baseApiService";

export class ScheduleService extends BaseApiService {
  constructor(config) {
    super(config);
    this.baseUrl = `${config.backendUrl}/ssm/sb`;
  }

  createAll(subscriberId, profileName, schedules) {
    return _.reduce(
      schedules,
      (chain, schedule) =>
        chain.then(() => this.create(subscriberId, profileName, schedule)),
      Promise.resolve()
    );
  }

  create(subscriberId, profileName, schedule) {
    return this._http.post(
      `${this.baseUrl}/${subscriberId}/profile/${profileName}/schedule`,
      schedule
    );
  }

  save(subscriberId, profileName, schedule) {
    return this._http.put(
      `${this.baseUrl}/${subscriberId}/profile/${profileName}/schedule/${schedule.name}`,
      schedule
    );
  }

  removeAll(subscriberId, profileName, schedules) {
    return _.reduce(
      schedules,
      (chain, schedule) =>
        chain.then(() => this.remove(subscriberId, profileName, schedule)),
      Promise.resolve()
    );
  }

  remove(subscriberId, profileName, schedule) {
    return this._http.delete(
      `${this.baseUrl}/${subscriberId}/profile/${profileName}/schedule/${schedule.name}`
    );
  }
}
