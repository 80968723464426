import { getResources } from "../../../../store/root.selectors";
import { trimValues } from "../../helpers/form.helper";

const getBranding = state => getResources(state).branding;

export const getBrandingColor = state => getBranding(state).color;
export const getBrandingTagline = state => getBranding(state).tagline;
export const getBrandingLogo = state => getBranding(state).logo;
export const getBrandingToSave = state => getBranding(state).toSave;
export const getBrandingValidation = state => getBranding(state).validation;

export const getPreviewParams = previewProps => state => {
  const params = {
    ...previewProps,
    ...getPreviewFields(state)
  };

  return trimValues(params);
};

export const getPreviewFields = state => {
  const toSave = getBrandingToSave(state);
  const color = getBrandingColor(state);
  const tagline = getBrandingTagline(state);

  return {
    color: toSave.color || color,
    tagline: toSave.tagline || toSave.tagline === "" ? toSave.tagline : tagline
  };
};
