import _ from "lodash";

export default function onOutsideClick(instance, callback, ref) {
  const handleClickOutside = event => {
    if (ref && !ref.contains(event.target)) {
      const hasFocus = ref.contains(document.activeElement);

      if (!hasFocus) {
        callback();
      }
    }
  };
  const unmountInstance = instance.componentWillUnmount;

  document.addEventListener("click", handleClickOutside, true);

  instance.componentWillUnmount = function() {
    if (_.isFunction(unmountInstance)) {
      unmountInstance.call(instance);
    }
    document.removeEventListener("click", handleClickOutside, true);
  };
}
