import { InfoProvider } from "./info.provider";
import { failure, Result, statusCodeMatches, success } from "../shared";
import { AuthProvider } from "./auth.provider";
import { AuthInfo, InfoData, UserMode } from "./auth.types";

export enum AuthServiceErrorCode {
  GENERIC, // block in dev tool, timeout, no internet connection, server fail, whatever
  UNAUTHORIZED,
  WRONG_USER_MODE
}

export class AuthService {
  static readonly legitStatusCodes = [401, 403];

  constructor(
    private infoProvider: InfoProvider,
    private authProvider: AuthProvider,
    private allowedUserModes: UserMode[]
  ) {}

  public async getInfo(): Promise<Result<InfoData, void | AuthInfo>> {
    try {
      const { data } = await this.infoProvider.get();

      if (!this.isAllowedUserMode(data)) {
        return failure(AuthServiceErrorCode.WRONG_USER_MODE, {
          authMethod: data.authMethod
        });
      }

      return success(data);
    } catch (error) {
      if (statusCodeMatches(error, AuthService.legitStatusCodes)) {
        return failure(AuthServiceErrorCode.UNAUTHORIZED, {
          authMethod: error.response.data.authMethod
        });
      }

      return failure(AuthServiceErrorCode.GENERIC);
    }
  }

  public async login(username, password): Promise<Result<undefined>> {
    try {
      await this.authProvider.login(username, password);
      return success();
    } catch (error) {
      if (statusCodeMatches(error, AuthService.legitStatusCodes)) {
        return failure(AuthServiceErrorCode.UNAUTHORIZED);
      }

      return failure(AuthServiceErrorCode.GENERIC);
    }
  }

  public async logout(): Promise<Result<undefined>> {
    try {
      await this.authProvider.logout();
      return success();
    } catch (error) {
      if (statusCodeMatches(error, AuthService.legitStatusCodes)) {
        return failure(AuthServiceErrorCode.UNAUTHORIZED);
      }

      return failure(AuthServiceErrorCode.GENERIC);
    }
  }

  private isAllowedUserMode(data: InfoData): boolean {
    return this.allowedUserModes.includes(data.mode);
  }
}
