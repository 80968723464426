import _ from "lodash";

import {
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,
  SettingsActionTypes,
  SettingsSuccessPayload,
  SettingsFailurePayload
} from "./settings.types";
import { loginFailure } from "../../pages/login/login.actions";
import {
  APPS,
  getSbSupport,
  getSsSupportForApp
} from "../../common/utils/permissions/index";
import { SettingsServerData, Service } from "@sportal/api";
import { ThunkApi } from "../../api/api";

export const getSettings = () => (dispatch, getState, { api }: ThunkApi) => {
  return api.ssm.settings
    .getSettings()
    .then(({ services, reporting }: SettingsServerData) => {
      const [sb, ss] = _.map(
        [Service.SecureBusiness, Service.SubscriberSafety],
        service => _.find(services, { service })
      );

      if (getSbSupport(sb).enabled && getSsSupportForApp(ss, APPS.SB).enabled) {
        return dispatch(getSettingsSuccess({ services, reporting }));
      }

      dispatch(loginFailure());
      return Promise.reject({ text: "app_improperly_configured" });
    })
    .catch(error => {
      dispatch(getSettingsFailure(error));
      return Promise.reject(error);
    });
};

export const getSettingsSuccess = (
  settings: SettingsSuccessPayload
): SettingsActionTypes => ({
  type: GET_SETTINGS_SUCCESS,
  payload: settings
});

export const getSettingsFailure = (
  error: SettingsFailurePayload
): SettingsActionTypes => ({
  type: GET_SETTINGS_FAILURE,
  payload: error
});
