import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import "./errorPage.scss";

import { logout } from "../login/login.actions";
import { withLayout } from "../../components/layout/Layout";
import { Button } from "../../components/button/Button";
import ButtonLink from "../../components/buttonLink/ButtonLink";

const ErrorPage = props => {
  const logout = () => props.logout().then(() => props.history.push("/login"));

  const content = {
    general: {
      title: <FormattedMessage id={"error_page_title"} />,
      body: (
        <Fragment>
          <FormattedMessage id={"error_page_msg_1"} />
          <ButtonLink onClick={() => props.history.goBack()}>
            <FormattedMessage id={"reload"} />
          </ButtonLink>
          <FormattedMessage id={"error_page_msg_2"} />
        </Fragment>
      )
    },
    account: {
      title: "",
      body: (
        <Fragment>
          <p>
            <FormattedMessage id={"account_not_exist"} />
          </p>
          <p>
            <FormattedMessage id={"contact_administrator"} />
          </p>
          <Button onClick={logout}>
            <FormattedMessage id={"go_back"} />
          </Button>
        </Fragment>
      )
    }
  };

  const { type } = props.match.params;
  const { title, body } = type ? content[type] : content.general;

  return (
    <section className={"error-page"}>
      <div className="error-page__icon">
        <svg
          width="95"
          height="110"
          viewBox="0 0 1792 1792"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#AAAFB1"
            d="M1262 1229q8 25-4 48.5t-37 31.5-49-4-32-38q-25-80-92.5-129.5t-151.5-49.5-151.5 49.5-92.5 129.5q-8 26-31.5 38t-48.5 4q-26-8-38-31.5t-4-48.5q37-121 138-195t228-74 228 74 138 195zm-494-589q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm512 0q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm256 256q0-130-51-248.5t-136.5-204-204-136.5-248.5-51-248.5 51-204 136.5-136.5 204-51 248.5 51 248.5 136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5zm128 0q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
          />
        </svg>
      </div>
      {title && <div className="error-page__title">{title}</div>}
      <div className="error-page__message">{body}</div>
    </section>
  );
};

export default _.flowRight(
  withRouter,
  withLayout({
    showHeaderUnderline: true,
    showHelpLink: true,
    showUserInfo: false,
    showNavigation: false
  }),
  connect(
    ({ config }) => ({
      showErrorBeforeLogout: config.showErrorPageBeforeAutoLogout,
      logoutUrl: config.security.logoutUrl
    }),
    { logout }
  )
)(ErrorPage);
