import { BaseApiService } from "../baseApiService";

export class SettingsService extends BaseApiService {
  constructor(config) {
    super(config);
    this.baseUrl = `${config.backendUrl}/ssm/settings`;
  }

  getSettings() {
    return this._http.get(this.baseUrl).then(({ data }) => data);
  }
}
