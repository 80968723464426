import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./RadioButton.scss";

/**
 * A checkbox to use throughout the application.
 */
class RadioButton extends Component {
  render() {
    const {
      isDisabled,
      isChecked,
      onChange = () => {},
      name,
      value,
      children,
      className
    } = this.props;

    return (
      <label
        className={classnames(
          "radio",
          {
            "radio--selected": isChecked
          },
          className
        )}
      >
        <input
          className="radio__input"
          type="radio"
          name={name}
          value={value}
          checked={isChecked}
          disabled={isDisabled}
          onChange={onChange}
        />
        <span className="radio__check" />
        <div className="radio__text">{children}</div>
      </label>
    );
  }
}

RadioButton.propTypes = {
  /**
   * Shows whether the radio is checked.
   */
  isChecked: PropTypes.bool,
  /**
   * Shows whether the radio is disabled.
   */
  isDisabled: PropTypes.bool,
  /**
   * The name of the control.
   */
  name: PropTypes.string,
  /**
   * The label content.
   */
  value: PropTypes.string,
  /**
   * The label content.
   */
  children: PropTypes.node,
  /**
   * Change event handler
   */
  onChange: PropTypes.func,
  className: PropTypes.string
};

RadioButton.defaultProps = {
  isChecked: false,
  isDisabled: false,
  name: "",
  value: "",
  children: []
};

export default RadioButton;
