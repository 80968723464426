import React, { FC, HTMLAttributes, useLayoutEffect, useRef } from "react";
import { GetInputPropsOptions } from "downshift";

interface SelectSearchProps extends HTMLAttributes<HTMLInputElement> {
  isOpen?: boolean;
  getInputProps: (options?: GetInputPropsOptions) => any;
  placeholder?: string;
}

export const SelectSearch: FC<SelectSearchProps> = ({
  isOpen,
  getInputProps,
  placeholder
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    // when combobox get proper DOM structured this can be removed
    // - it will be handled automatically by downshift
    if (isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="select__search">
      <input
        {...getInputProps({
          className: "select__search-input",
          placeholder,
          role: "search",
          ref: inputRef
        })}
        data-testid="select-search"
      />
    </div>
  );
};
