import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useIntlTranslate = () => {
  const intl = useIntl();

  return useCallback(
    (id, values) => {
      if (!intl.messages.hasOwnProperty(id)) {
        // it's a fix for bug in react-intl where if id is named the same as the one of prototype methods (constructor, hasOwnProperty, etc.)

        if (process.env.NODE_ENV !== "production") {
          // eslint-disable-next-line no-console
          console.error(
            `Missing translation for ${id} for locale "${intl.locale}", using id as fallback`
          );
        }
        return id;
      }

      return intl.formatMessage({ id }, values);
    },
    [intl]
  );
};
