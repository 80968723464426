import React, { Component } from "react";
import classnames from "classnames";
import { Button } from "../button/Button";
import "./ProgressButton.scss";

class ProgressButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonState: props.buttonState || "ready",
      statesSetups: {
        ready: {
          styleClass: "primary"
        },
        processing: {
          styleClass: "primary"
        },
        success: {
          styleClass: "primary",
          freezeTime: 1500
        },
        error: {
          styleClass: "destructive",
          freezeTime: 1500
        }
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.buttonState === prevProps.buttonState) {
      return;
    }

    switch (this.props.buttonState) {
      case "progress":
        this.processing();
        return;
      case "success":
        this.success();
        return;

      case "error":
        this.error();
        return;

      default:
        this.ready();
    }
  }

  handleClick = () => {
    const promise = this.props.onClick();

    if (promise && promise.then) {
      this.processing();

      return promise.then(() => this.success()).catch(() => this.error());
    }
  };

  render() {
    const { buttonState, statesSetups } = this.state;
    const { className, label, ...attrs } = this.props;
    const buttonStyles = classnames("progress-button", className);

    return (
      <Button
        {...attrs}
        className={buttonStyles}
        color={statesSetups[buttonState].styleClass}
        onClick={this.handleClick}
        size={"large"}
      >
        {buttonState === "ready" ? (
          <span>{label}</span>
        ) : (
          <>
            <span className="progress-button__label--hidden">{label}</span>
            {this._buttonState(buttonState)}
          </>
        )}
      </Button>
    );
  }

  _buttonState(state) {
    switch (state) {
      case "processing":
        return (
          <span className="progress-button__loader">
            <b className="progress-button-loader__block" />
            <b className="progress-button-loader__block" />
            <b className="progress-button-loader__block" />
            <b className="progress-button-loader__block" />
            <b className="progress-button-loader__block" />
            <b className="progress-button-loader__block" />
          </span>
        );
      case "success":
        return <span className="progress-button__label--icon fas fa-check" />;
      case "error":
        return <span className="progress-button__label--icon fas fa-times" />;
      default:
        return;
    }
  }

  processing() {
    this.setState({ buttonState: "processing" });
  }

  success() {
    const freezeTime = this.state.statesSetups.success.freezeTime;

    this.setState({ buttonState: "success" });

    freezeTime &&
      setTimeout(() => this.setState({ buttonState: "ready" }), freezeTime);
  }

  error() {
    const freezeTime = this.state.statesSetups.error.freezeTime;

    this.setState({ buttonState: "error" });

    freezeTime &&
      setTimeout(() => this.setState({ buttonState: "ready" }), freezeTime);
  }

  ready() {
    this.setState({ buttonState: "ready" });
  }
}

export default ProgressButton;
