import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import "./ConfirmationDialog.scss";

import { CommonModalHeader, ModalDialog } from "../modal";
import { Button } from "../button/Button";
import { Mobile, NotMobile } from "../../hocs/Responsive";

export const ConfirmationDialog = ({ modal, isValid = true }) => (
  <ModalDialog
    className="confirmation-dialog"
    submitHandler={isValid ? () => modal.resolve({ shouldSave: true }) : null}
    rejectHandler={() => modal.reject()}
  >
    <CommonModalHeader>
      <FormattedMessage id="unsaved_changes_title" />
    </CommonModalHeader>
    <ModalDialog.Body>
      <FormattedMessage id="unsaved_changes_description" />
    </ModalDialog.Body>
    <ModalDialog.Footer className="confirmation-dialog__footer">
      <ModalDialog.Control>
        <Button isOutline onClick={() => modal.resolve()} size="large">
          <FormattedMessage id="dont_save" />
        </Button>
      </ModalDialog.Control>
      <ModalDialog.Control>
        <Button isOutline onClick={() => modal.reject()} size="large">
          <FormattedMessage id="cancel" />
        </Button>
      </ModalDialog.Control>
      <ModalDialog.Control>
        <Button
          onClick={() => modal.resolve({ shouldSave: true })}
          disabled={!isValid}
          size="large"
        >
          <Mobile>
            <FormattedMessage id="save" />
          </Mobile>
          <NotMobile>
            <FormattedMessage id="save_changes" />
          </NotMobile>
        </Button>
      </ModalDialog.Control>
    </ModalDialog.Footer>
  </ModalDialog>
);

ConfirmationDialog.propTypes = {
  modal: PropTypes.object,
  isSaveDisabled: PropTypes.bool
};
