export enum UploadErrorType {
  ImageTypeNotSupported = "IMAGE_TYPE",
  FileIsTooLarge = "MAX_FILE_SIZE",
  MissingSvgAttributes = "MISSING_SVG_ATTRIBUTES",
  SvgContainsScript = "SVG_CONTAINS_SCRIPT"
}

export interface UploadValidationError {
  type: UploadErrorType;
  message: React.ReactNode;
  shortMessage: React.ReactNode;
}

export type UploadValidationErrors = {
  [index in UploadErrorType]?: UploadValidationError;
};
