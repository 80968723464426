import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import Notificator from "../../components/notification/notification.actions";
import { ResourceName } from "./resources.types";

export const EMAIL_ADMIN_FIELD = ["admin-email", "adminEmail"];

export const loadResources = () => async (dispatch, getState, { api }) => {
  const {
    subscriberInfo: { id },
    config: { emailRegex }
  } = getState();

  try {
    let resources = await api.ssm.resources.getResources(id);
    resources = resources.map(res => {
      const fields = _.map(res.fields, field => {
        const mappedField = {
          ...field,
          ...(field.name === "adminEmail" ? { emailRegex } : {}),
          ...(field.validations
            ? { maxLength: field.validations.maxLength }
            : {})
        };

        delete mappedField.validations;

        return mappedField;
      });

      return {
        ...res,
        fields
      };
    });

    resources.forEach(({ name, fields }) => {
      switch (name) {
        case ResourceName.Safety: {
          dispatch(loadSafetyValidations(fields));
          return;
        }
        case ResourceName.WebContent: {
          dispatch(loadWebContentValidations(fields));
          dispatch(loadBrandingValidations(fields));
          return;
        }
        default:
          return;
      }
    });

    dispatch(loadResourcesSuccess());
  } catch (e) {
    dispatch(Notificator.error(<FormattedMessage id={"request_failed"} />));
    dispatch(loadResourcesFailure(e));
  }
};

export const LOAD_RESOURCE_SUCCESS = "[RESOURCES] LOAD_RESOURCE_SUCCESS";
export const LOAD_RESOURCE_FAILURE = "[RESOURCES] LOAD_RESOURCE_FAILURE";
export const LOAD_BRANDING_VALIDATIONS =
  "[RESOURCES] [BRANDING] LOAD_VALIDATIONS";
export const LOAD_SAFETY_VALIDATIONS = "[RESOURCES] [SAFETY] LOAD_VALIDATIONS";
export const LOAD_WEB_CONTENT_VALIDATIONS =
  "[RESOURCES] [WEB_CONTENT] LOAD_VALIDATIONS";

export const loadResourcesSuccess = () => ({
  type: LOAD_RESOURCE_SUCCESS
});
export const loadResourcesFailure = error => ({
  type: LOAD_RESOURCE_FAILURE,
  payload: error
});

export const loadBrandingValidations = branding => ({
  type: LOAD_BRANDING_VALIDATIONS,
  payload: branding
});
export const loadSafetyValidations = safety => ({
  type: LOAD_SAFETY_VALIDATIONS,
  payload: safety
});
export const loadWebContentValidations = webContent => ({
  type: LOAD_WEB_CONTENT_VALIDATIONS,
  payload: webContent
});
