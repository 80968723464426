import { FormattedMessage } from "react-intl";
import React, { Fragment } from "react";
import _ from "lodash";

import { Icon } from "../../../../components/icon/Icon";

const AllowedCategory = () => {
  return (
    <div className="domain-lookup__table-cell">
      <div className={"category__icon"}>
        <Icon icon="far fa-check color-success" />
      </div>
      <div className={"category__status"}>
        <FormattedMessage id={"allowed"} />
      </div>
    </div>
  );
};
const BlockedCategory = () => {
  return (
    <div className="domain-lookup__table-cell">
      <div className={"category__icon"}>
        <Icon icon="far fa-ban color-danger" />
      </div>
      <div className={"category__status"}>
        <FormattedMessage id={"blocked"} />
      </div>
    </div>
  );
};
const BlockedProfiles = ({ profiles }) => {
  return (
    <div className="domain-lookup__table-cell">
      <div className={"category__icon"}>
        <Icon icon="far fa-ban color-danger" />
      </div>
      <div className={"category__status"}>{profiles.join(", ")}</div>
    </div>
  );
};

export const CategoryStatus = ({ profiles, showProfiles = true }) => {
  return _.isEmpty(profiles) ? (
    <AllowedCategory />
  ) : showProfiles ? (
    <BlockedProfiles profiles={profiles} />
  ) : (
    <BlockedCategory />
  );
};

export const COLUMNS_NOT_MOBILE = [
  {
    Header: <FormattedMessage id={"associated_category"} />,
    accessor: "name",
    sortable: true
  },
  {
    Header: <FormattedMessage id={"blocked_profiles"} />,
    accessor: "associatedProfiles",
    sortable: false,
    Cell: ({ row: { original } }) => (
      <CategoryStatus profiles={original.associatedProfiles} />
    )
  }
];

export const COLUMNS_MOBILE = [
  {
    accessor: "name",
    Cell: ({ row: { original } }) => (
      <Fragment>
        <div>{original.name}</div>
        <CategoryStatus profiles={original.associatedProfiles} />
      </Fragment>
    )
  }
];

export const COLUMNS_NOT_MOBILE_SINGLE = [
  {
    Header: <FormattedMessage id={"associated_category"} />,
    accessor: "name",
    sortable: true,
    Cell: ({ row: { original } }) => <span>{original.name}</span>
  },
  {
    accessor: "associatedProfiles",
    sortable: false,
    Cell: ({ row: { original } }) => (
      <CategoryStatus
        profiles={original.associatedProfiles}
        showProfiles={false}
      />
    )
  }
];

export const COLUMNS_MOBILE_SINGLE = [
  {
    accessor: "name",
    Cell: ({ row: { original } }) => (
      <Fragment>
        <div>{original.name}</div>
        <CategoryStatus
          profiles={original.associatedProfiles}
          showProfiles={false}
        />
      </Fragment>
    )
  }
];
