import { useState, useEffect, useCallback } from "react";
import _ from "lodash";

export const unsetPosition = {
  position: "fixed",
  transition: "none",
  top: "-9999px",
  left: "-9999px"
};

const computedPosition = {
  position: "absolute"
};

export const defaultPadding = 12; // to have some space from window edge

const getHorizontalPosition = (toggleButtonRef, dropdownRef) => {
  const toggleButtonRect = toggleButtonRef.current.getBoundingClientRect();
  const dropdownRect = dropdownRef.current.getBoundingClientRect();

  const requiredSpace =
    toggleButtonRect.left + dropdownRect.width + defaultPadding;
  const willFit = requiredSpace < document.body.clientWidth; // element.clientWidth excludes scrollbar, borders and margins

  return {
    [willFit ? "left" : "right"]: 0
  };
};

const isScrollable = element => {
  const overflowYStyle = window.getComputedStyle(element).overflowY;
  const overflowStyle = window.getComputedStyle(element).overflow;

  return (
    overflowYStyle.includes("auto") ||
    overflowYStyle.includes("scroll") ||
    overflowStyle.includes("auto") ||
    overflowStyle.includes("scroll")
  );
};

const getScrollableParent = element => {
  return !element || element === document.body
    ? document.body
    : isScrollable(element)
    ? element
    : getScrollableParent(element.parentNode);
};

const getAvailableHeight = node => {
  const scrollableParent = getScrollableParent(node);

  if (scrollableParent === document.body) {
    return document.body.clientHeight;
  }

  return _.min([
    scrollableParent.getBoundingClientRect().bottom,
    document.body.clientHeight
  ]);
};

const getVerticalPosition = (toggleButtonRef, dropdownRef) => {
  const toggleButtonRect = toggleButtonRef.current.getBoundingClientRect();
  const dropdownRect = dropdownRef.current.getBoundingClientRect();
  const requiredSpace =
    toggleButtonRect.bottom + dropdownRect.height + defaultPadding;
  const availableSpace = getAvailableHeight(dropdownRef.current);

  const willFit = requiredSpace < availableSpace;

  return {
    [willFit ? "top" : "bottom"]: toggleButtonRect.height
  };
};

export const useDropdownOrientation = (
  toggleButtonRef,
  dropdownRef,
  isOpened
) => {
  const [styles, setStyles] = useState(unsetPosition);

  const updatePosition = useCallback(() => {
    setStyles(prevState => {
      const newState = {
        ...computedPosition,
        ...getVerticalPosition(toggleButtonRef, dropdownRef),
        ...getHorizontalPosition(toggleButtonRef, dropdownRef)
      };

      if (!_.isEqual(prevState, newState)) return newState;

      return prevState;
    });
  }, [toggleButtonRef, dropdownRef]);

  const cleanListeners = useCallback(() => {
    window.removeEventListener("resize", updatePosition);
    window.removeEventListener("scroll", updatePosition);
  }, [updatePosition]);

  useEffect(() => {
    if (isOpened) {
      updatePosition();
      window.addEventListener("resize", updatePosition);
      window.addEventListener("scroll", updatePosition);
    } else {
      setStyles(unsetPosition);
      cleanListeners();
    }

    return () => cleanListeners();
  }, [isOpened, cleanListeners, updatePosition]);

  return styles;
};
