import React from "react";
import { FormattedMessage } from "react-intl";

import "./WebFilterOffNotification.scss";

import { Mobile, NotMobile } from "../../../hocs/Responsive";
import { HelpLink } from "../../helpLink/HelpLink";
import { Icon } from "../../icon/Icon";

const LearnMoreButton = () => (
  <HelpLink
    section={"turning-web-filters-on-and-off"}
    className="web-filter-off-notification__link"
  >
    <FormattedMessage id={"click_here_to_learn_more"} />
  </HelpLink>
);

export const WebFilterOffNotification = () => (
  <div className="web-filter-off-notification">
    <Icon icon="fas fa-exclamation-circle web-filter-off-notification__icon" />
    <span className="web-filter-off-notification__content">
      <span className="web-filter-off-notification__content__title-text">
        <FormattedMessage id={"protection_off_notification_title"} />
      </span>
      <NotMobile>
        <span className="web-filter-off-notification__content__description-text">
          <FormattedMessage id={"protection_off_notification_description"} />
        </span>
      </NotMobile>

      <Mobile>
        <LearnMoreButton />
      </Mobile>
    </span>

    <NotMobile>
      <LearnMoreButton />
    </NotMobile>
  </div>
);
