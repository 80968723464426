import React, { Component, Fragment } from "react";
import _ from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import classnames from "classnames";

import "./CheckUrlBlock.scss";

import Input from "../../../../components/input/Input";
import { Button } from "../../../../components/button/Button";
import ProgressButton from "../../../../components/progressButton/ProgressButton";
import UrlOptionsPicker from "../urlOptionsPicker/UrlOptionsPicker";
import { NotMobile } from "../../../../hocs/Responsive";
import { HelpLink } from "../../../../components/helpLink/HelpLink";
import { Icon } from "../../../../components/icon/Icon";

class CheckUrlBlock extends Component {
  handleSubmit = e => {
    e.preventDefault();
  };

  render() {
    const { status } = this.props.checkUrlState;
    const formClassName = classnames("check-url-block-form", {
      "check-url-block-form--has-error": status === "error"
    });

    return (
      <div className="check-url-block">
        <form className={formClassName} onSubmit={this.handleSubmit}>
          {this.renderInput()}
          {this.renderButton()}
        </form>
        <NotMobile>{this.renderCSVUpload()}</NotMobile>
        {this.renderOptionPicker()}
      </div>
    );
  }

  renderInput() {
    const { checkUrlState, clearCheckUrl, checkUrlChange, intl } = this.props;
    const { status, url, parsedURL } = checkUrlState;

    const error =
      status === "error" ? <FormattedMessage id="parse_url_error" /> : "";
    const onClear = _.isEmpty(parsedURL) ? clearCheckUrl : null;

    return (
      <Input
        className="check-url-block-form__input"
        label={<FormattedMessage id="check_url_message" />}
        id="checkUrl"
        value={url}
        disabled={status}
        onClear={onClear}
        onChange={({ target: { value } }) => checkUrlChange(value)}
        placeholder={intl.formatMessage({ id: "enter_url" })}
        errorMessage={error}
        autoComplete="url"
        aria-label={intl.formatMessage({
          id: "aria_block_and_allow_list_url"
        })}
      />
    );
  }

  renderButton() {
    const { cancelCheckUrl, checkUrl, checkUrlState } = this.props;
    const { url, status } = checkUrlState;

    if (status) {
      return (
        <Button
          className="check-url-block-form__button"
          onClick={cancelCheckUrl}
          isOutline
        >
          <FormattedMessage id="cancel" />
        </Button>
      );
    }

    return (
      <ProgressButton
        label={<FormattedMessage id="check" />}
        className="check-url-block-form__button"
        onClick={() => checkUrl(url.trim())}
        disabled={_.isEmpty(url.trim())}
      />
    );
  }

  renderCSVUpload() {
    const { toggleAddMethod } = this.props;
    const { parsedURL } = this.props.checkUrlState;

    if (!_.isEmpty(parsedURL)) return null;

    return (
      <Fragment>
        <Button isOutline className="toggle-btn" onClick={toggleAddMethod}>
          <FormattedMessage id={"upload_list"} />
        </Button>
        <HelpLink section={"bulk-upload-info"}>
          <Icon icon="far fa-info-circle" />
          <span>
            <FormattedMessage id={"about_csv"} />
          </span>
        </HelpLink>
      </Fragment>
    );
  }

  renderOptionPicker() {
    const { profileId, checkUrlState, addUrlToList, allowedTypes } = this.props;
    const { parsedURL } = checkUrlState;

    if (_.isEmpty(parsedURL)) return null;

    return (
      <UrlOptionsPicker
        classNames="check-url-block__options"
        allowedTypes={allowedTypes}
        urls={parsedURL}
        onAddUrl={addUrlToList}
        profileId={profileId}
      />
    );
  }
}

export default injectIntl(CheckUrlBlock);
