import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import _ from "lodash";

import { HelpPageNavigation } from "./HelpPageNavigation";
import { HelpSearch } from "../HelpSearch/HelpSearch";
import { useEventListener } from "@sportal/cdk/hooks";
import { useLayoutContext } from "../../../components/layout";

import "./HelpPageSidebar.scss";

const HEADER_HEIGHT = 80;
const FOOTER_HEIGHT = 80;

export const HelpPageSidebar = ({
  sections,
  activeSection,
  onSearchChange,
  onDoSearch,
  hasNoMatches,
  searchValue
}) => {
  const hostRef = useRef(null);

  const { eulaHeaderHeight, eulaFooterHeight } = useLayoutContext();

  const [top, setTop] = useState(0);

  const ensurePosition = () => {
    const hostNode = hostRef.current;
    if (!hostNode) {
      return;
    }

    const sidebarHeight = hostNode.offsetHeight;
    const availableHeight =
      window.innerHeight -
      HEADER_HEIGHT -
      FOOTER_HEIGHT -
      eulaHeaderHeight -
      eulaFooterHeight;

    const isFit = sidebarHeight < availableHeight;
    if (isFit) {
      if (top) {
        // the case when the window is being resized so that sidebar fits the window
        setTop(0);
      }
      return;
    }

    const activeItem = document.querySelector(
      ".help-page-navigation__link--active"
    );
    if (!activeItem) {
      return;
    }

    // Let's take the approximate centre in order to avoid
    // complex calculations depending on height of items and so on
    const stickPoint = availableHeight / 2;
    const activeItemOffsetTop = activeItem.offsetTop;
    const desiredOffset = _.clamp(stickPoint - activeItemOffsetTop, 0);
    if (desiredOffset !== top) {
      setTop(desiredOffset);
    }
  };

  useEventListener("resize", ensurePosition);

  return (
    <div
      className="help-page-sidebar-wrapper"
      style={{ top: `${HEADER_HEIGHT + eulaHeaderHeight}px` }}
    >
      <div ref={hostRef} className="help-page-sidebar" style={{ top }}>
        <header>
          <div className="help-page-sidebar__subtitle">
            <FormattedMessage id="help_and_documentation" />
          </div>
        </header>
        <div className="help-page-sidebar__search">
          <HelpSearch
            onChange={onSearchChange}
            onDoSearch={onDoSearch}
            hasNoMatches={hasNoMatches}
            value={searchValue}
          />
        </div>
        <HelpPageNavigation
          className={"help-page-sidebar__navigation"}
          sections={sections}
          activeSection={activeSection}
          onActiveSectionChange={() => ensurePosition()}
        />
      </div>
    </div>
  );
};

HelpPageSidebar.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired,
      header: PropTypes.shape({
        text: PropTypes.string.isRequired,
        html: PropTypes.string.isRequired
      }).isRequired,
      content: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  activeSection: PropTypes.string,
  searchString: PropTypes.string,
  onSearchChange: PropTypes.func,
  onDoSearch: PropTypes.func
};
