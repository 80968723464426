import React from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "../../../../components/button/Button";
import { Icon } from "../../../../components/icon/Icon";

import "./PreviewButton.scss";

export const PreviewButton = ({ onClick, disabled }) => (
  <Button
    className="preview-button"
    size={"large"}
    isOutline
    onClick={onClick}
    disabled={disabled}
  >
    <Icon icon="far fa-eye" />
    <span>
      <FormattedMessage id={"preview_block_page"} />
    </span>
  </Button>
);
