import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Icon } from "../icon/Icon";

import "./CrossButton.scss";

export function CrossButton({
  className,
  size,
  onClick,
  weight = "strong",
  ...attrs
}) {
  return (
    <button
      className={classnames("cross-button", `cross-button--${size}`, className)}
      type="button"
      onClick={onClick}
      {...attrs}
    >
      <Icon
        icon={classnames("fa-times", weight === "strong" ? "fas" : "far")}
      />
    </button>
  );
}

CrossButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
  weight: PropTypes.oneOf(["regular", "strong"]),
  onClick: PropTypes.func.isRequired
};
