import {
  GENERATE_DEEPLINK,
  GENERATE_DEEPLINK_SUCCESS
} from "./deeplinkDialog.actions";


const initial = {
  link: "",
  expires: "",
}

export const deeplinkReducer = (
  state = initial,
  action
) => {
  switch (action.type) {
    case GENERATE_DEEPLINK: {
      return { ...initial }
    }
    case GENERATE_DEEPLINK_SUCCESS: {
      const { link, expires } = action.payload;
      return { ...state, link, expires }
    }
    default:
      return state;
  }
}
