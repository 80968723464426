import * as _ from "lodash";

import { failure, Result, success } from "../../shared";
import {
  IDeviceRequestsProvider,
  ReportDuration,
  RequestsCollection
} from "./deviceRequests.types";
import { IManufacturerService } from "../manufacturer";

export enum DeviceRequestsServiceErrorCode {
  GENERIC
}

export class DeviceRequestsService {
  constructor(
    private deviceRequestsProvider: IDeviceRequestsProvider,
    private manufacturerService: IManufacturerService
  ) {}

  public async getRequests(
    subscriberId: string
  ): Promise<Result<RequestsCollection>> {
    try {
      const [withName, withCode, discovered] = await Promise.all([
        this.deviceRequestsProvider.getRequestsWithName(
          subscriberId,
          ReportDuration.OneDay
        ),
        this.deviceRequestsProvider.getRequestsWithCode(
          subscriberId,
          ReportDuration.OneHour
        ),
        this.deviceRequestsProvider.getDiscovered(
          subscriberId,
          ReportDuration.OneHour
        )
      ]);

      const macs = _.flatMap([withName, withCode, discovered], requests =>
        _.map(requests, ({ id }) => id)
      );

      const { data: manufacturers } = await this.manufacturerService.get(macs);

      return success({
        withName: this.addManufacturers(withName, manufacturers),
        withCode: this.addManufacturers(withCode, manufacturers),
        discovered: this.addManufacturers(discovered, manufacturers)
      });
    } catch (error) {
      return failure(DeviceRequestsServiceErrorCode.GENERIC);
    }
  }

  private addManufacturers<T extends { id: string }>(
    requests: T[],
    manufacturers
  ): (T & { manufacturer: string })[] {
    return _.map(requests, request => ({
      ...request,
      manufacturer: manufacturers[request.id]
    }));
  }
}
