import React from "react";
import { FormattedMessage } from "react-intl";
import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog
} from "../../../../components/modal";
import { punyToUnicode } from "../../../../utils/punyToUnicode";

const formatDomain = ({ node }) => `.${punyToUnicode(node)} `;

export const ConfirmBlockDialog = ({ modal, url }) => (
  <ModalDialog
    className={"confirm-block-dialog"}
    submitHandler={() => modal.resolve()}
    rejectHandler={() => modal.reject()}
  >
    <CommonModalHeader>
      <FormattedMessage id={"confirm_action"} />
    </CommonModalHeader>
    <ModalDialog.Body>
      <FormattedMessage id={"tld_block_confirm"} />
      <span className="text-highlighted">&nbsp;{formatDomain(url)}</span>
      <span className="lowercase">
        <FormattedMessage id={"domains"} />?
      </span>
    </ModalDialog.Body>
    <CommonModalFooter
      submitMessage={<FormattedMessage id="block" />}
      submitButtonType="destructive"
    />
  </ModalDialog>
);
