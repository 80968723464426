import _ from "lodash";

/**
 * Gets a source object and a keys map,
 * returns a new object with renamed fields
 * @param object object Source object
 * @param keysMap { [sourceKey: string]: string }
 * @returns object New object with renamed fields
 */
export function remap(object, keysMap) {
  return _.mapKeys(object, (value, key) => keysMap[key] || key);
}
