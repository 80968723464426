import "url-search-params-polyfill";
import "./common/utils/polyfills";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import axios from "axios";
import qs from "qs";

import "./index.scss";

import App from "./App";
import makeStore from "./store";
import { Api } from "./api/api";
import { APPS } from "./common/utils/permissions";
import { AppConfigService } from "./common/appConfig.service";
import { URL_TYPES } from "./common/utils/url_types";
import { EULA_CANCEL_STRATEGY } from "./pages/login/components";

function initialize() {
  global.nominum = process.env.NOMINUM_INFO;

  const http = axios.create({
    xsrfHeaderName: "X-CSRF-TOKEN",
    xsrfCookieName: "CSRF-TOKEN",
    paramsSerializer: params => qs.stringify(params)
  });

  const configDefaults = {
    emailRegex: '^([^\\s"@]+|(?:"[^"]+"))@([^.\\s@]+\\.)+[^.\\s@]{2,63}$',
    security: {
      noLoginPage: false,
      responseCodeHeader: "X-Response-Code",
      urlParamsForSubscriberData: ["id", "name", "mode"],
      logoutUrl: ""
    },
    firstDayOfWeek: "MO",
    helpPageLink: "/#help",
    logoLink: "/home",
    footerCompanyLink: "https://akamai.com",
    useFallbackLanguage: true,
    urlFilters: {
      "url-types": [URL_TYPES.CORE_DOMAIN]
    },
    secureBlocksLimit: 50000,
    secureBlocksLimitCsv: 500000,
    customLinkRef: "",
    showScheduledReports: true,
    showDrilldownPeriod: true,
    appTitle: "Secure Business",
    showEulaHeader: false,
    showEulaFooter: false,
    showEulaDialog: false,
    eulaCancelStrategy: EULA_CANCEL_STRATEGY.RELOAD,
    eulaCancelRedirectUrl: "",
    // These options are only for one customer
    // Please do not add this properties to appConfig.json.example
    footerIcons: [],
    footerPhoneNumbers: [],
    footerSupportLink: ""
  };

  return new AppConfigService(http, configDefaults).get().then(config => {
    const api = new Api({
      ...config,
      http,
      mode: APPS.SB
    });

    document.title = config.appTitle;

    const store = makeStore({ api }, config);

    http.interceptors.request.use(requestConfig => {
      const { businessName } = store.getState().accountSettings;
      if (businessName) {
        requestConfig.headers.common["X-Business-Name"] = businessName;
      }
      return requestConfig;
    });

    return {
      store
    };
  });
}

initialize().then(({ store }) =>
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  )
);
