import _ from "lodash";

import { BaseAppConfig } from "@sportal/api";
import { BaseApiService } from "../baseApiService";
import {
  ResourceDefinition,
  ResourceFieldName,
  ResourceFieldValues,
  ResourceName,
  ResourcesServerData,
  SubscriberResource
} from "../../pages/blockPageContent/resources.types";

enum ResponseType {
  Blob = "blob"
}

const BooleanResourceFields = [
  ResourceFieldName.ShowMessage,
  ResourceFieldName.ShowAdmin,
  ResourceFieldName.ShowCode,
  ResourceFieldName.ShowName
];

const webContentFieldMap: Record<string, ResourceFieldName> = {
  color: ResourceFieldName.Color,
  tagline: ResourceFieldName.Tagline,
  "web-page-admin-email": ResourceFieldName.AdminEmail,
  "web-page-admin-phone": ResourceFieldName.AdminPhone,
  "web-page-message": ResourceFieldName.Message,
  "web-page-show-message": ResourceFieldName.ShowMessage,
  "web-page-show-admin": ResourceFieldName.ShowAdmin,
  "web-page-show-code": ResourceFieldName.ShowCode,
  "web-page-show-name": ResourceFieldName.ShowName
};

const safetyFieldMap: Record<string, ResourceFieldName> = {
  color: ResourceFieldName.Color,
  tagline: ResourceFieldName.Tagline,
  "sec-page-admin-email": ResourceFieldName.AdminEmail,
  "sec-page-admin-phone": ResourceFieldName.AdminPhone,
  "sec-page-message": ResourceFieldName.Message,
  "sec-page-show-message": ResourceFieldName.ShowMessage,
  "sec-page-show-admin": ResourceFieldName.ShowAdmin
};

const serverToAppFieldMap = { ...webContentFieldMap, ...safetyFieldMap };

const mapAppToServerKeys = (
  values: ResourceFieldValues,
  resource: ResourceName
): Partial<typeof serverToAppFieldMap> => {
  const map =
    resource === ResourceName.WebContent ? webContentFieldMap : safetyFieldMap;

  return _.transform(
    values,
    (acc, value, key) => {
      const mappedKey = _.findKey(map, val => val === key);
      acc[mappedKey] = value;
      return acc;
    },
    {}
  );
};

export class ResourcesService extends BaseApiService {
  accountUrl: string;

  constructor(config: BaseAppConfig) {
    super(config);
    this.accountUrl = `${config.backendUrl}/ssm/account`;
  }

  getResources(subscriberId: string): Promise<ResourceDefinition[]> {
    return this._http
      .get<ResourcesServerData>(`${this.accountUrl}/${subscriberId}/resource`)
      .then(({ data: { content } }) =>
        content.map(({ fields, name }) => ({
          name,
          fields: fields.map(field => ({
            ...field,
            name: serverToAppFieldMap[field.name] || field.name
          }))
        }))
      );
  }

  getLogo(subscriberId: string): Promise<Blob> {
    return this._http
      .get(`${this.accountUrl}/${subscriberId}/resource/sb-logo/preview`, {
        responseType: ResponseType.Blob
      })
      .then(({ data }) => data);
  }

  getResource(
    subscriberId: string,
    resource: ResourceName
  ): Promise<ResourceFieldValues> {
    return this._http
      .get<SubscriberResource>(
        `${this.accountUrl}/${subscriberId}/resource/${resource}`
      )
      .then(({ data: { fields } }) => {
        return _.reduce(
          fields,
          (acc, value, key: ResourceFieldName) => {
            const prop = serverToAppFieldMap[key] || key;
            acc[prop] = BooleanResourceFields.includes(prop)
              ? value === "true"
              : value;
            return acc;
          },
          {}
        ) as ResourceFieldValues;
      });
  }

  updateResource(
    subscriberId: string,
    resource: ResourceName,
    values: ResourceFieldValues
  ): Promise<void> {
    return this._http.put(
      `${this.accountUrl}/${subscriberId}/resource/${resource}`,
      mapAppToServerKeys(values, resource)
    );
  }

  async updateLogo(subscriberId: string, logoObjectURL: string): Promise<void> {
    let data;

    if (logoObjectURL) {
      data = (await this._http.get(logoObjectURL, {
        responseType: ResponseType.Blob
      })).data;
    }

    const formData = new FormData();
    formData.append("file", data || null);

    return this._http.post(
      `${this.accountUrl}/${subscriberId}/resource/sb-logo`,
      formData
    );
  }

  getPreview(
    subscriberId: string,
    resource: ResourceName,
    values: ResourceFieldValues
  ) {
    return this._http.get(
      `${this.accountUrl}/${subscriberId}/resource/${resource}/preview`,
      { params: mapAppToServerKeys(values, resource) }
    );
  }
}
