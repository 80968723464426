import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import {
  DeviceNameInput,
  MacAddressInput,
  ProfileSelect
} from "../../../../components/devices/commonComponents";
import { HelpLink } from "../../../../components/helpLink/HelpLink";
import { ModalFormControl } from "../../../../components/modal";

const CLASS_NAME = "add-device-modal";

export const AddDeviceByMac = props => {
  return (
    <div className={props.className}>
      <div className={`${CLASS_NAME}__tip`}>
        <HelpLink section={"assigning-devices-to-user-groups"}>
          <FormattedMessage id="where_to_find_mac" />
        </HelpLink>
      </div>
      <ModalFormControl>
        <MacAddressInput
          error={props.errorAddress}
          address={props.address}
          label={props.intl.formatMessage({ id: "mac_address" })}
          devices={props.devices}
          handleChange={(address, error) =>
            props.onAddressChange(address, error)
          }
          autoFocus
        />
      </ModalFormControl>
      <ModalFormControl>
        <DeviceNameInput
          handleChange={(name, error) => props.onNameChange(name, error)}
          label={props.intl.formatMessage({ id: "device_name" })}
          devices={props.devices}
          name={props.name}
          error={props.errorName}
        />
      </ModalFormControl>
      <ModalFormControl>
        <ProfileSelect
          profiles={props.profiles}
          profile={props.profile}
          handleChange={selected => props.onProfileChange(selected)}
          className={CLASS_NAME}
        />
      </ModalFormControl>
    </div>
  );
};

AddDeviceByMac.propTypes = {
  /** Device name */
  name: PropTypes.string,
  /** Profiles for the select element*/
  profiles: PropTypes.array,
  /** Text of error for name field */
  errorName: PropTypes.string,
  /** Text of error for address field */
  errorAddress: PropTypes.string,
  onProfileChange: PropTypes.func,
  onNameChange: PropTypes.func,
  onAddressChange: PropTypes.func
};
