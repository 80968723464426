import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import Notificator from "../../../../components/notification/notification.actions";
import { getPreviewParams } from "../brandingForm/branding.selectors";
import {
  getInvalidValues,
  removeInvalidValues,
  trimValues
} from "../../helpers/form.helper";
import { ResourceName } from "../../resources.types";

export const loadWebContent = () => async (dispatch, getState, { api }) => {
  const {
    subscriberInfo: { id }
  } = getState();

  try {
    const fields = await api.ssm.resources.getResource(
      id,
      ResourceName.WebContent
    );
    dispatch(loadWebContentSuccess(fields));
  } catch (e) {
    dispatch(
      Notificator.error(<FormattedMessage id={"error_failed_to_load_data"} />)
    );
    dispatch(generalFailure(e, LOAD_WEB_CONTENT_FAILURE));
  }
};

export const changeWebContent = () => async (dispatch, getState, { api }) => {
  const {
    subscriberInfo: { id },
    resources: {
      webContent: { resource, validation, toSave }
    }
  } = getState();

  const newWebContent = trimValues(toSave);

  try {
    const updatedWebContent = _.defaults(newWebContent, resource),
      validatedWebContent = removeInvalidValues(updatedWebContent, validation),
      toSave = getInvalidValues(newWebContent, validation);

    await api.ssm.resources.updateResource(
      id,
      ResourceName.WebContent,
      validatedWebContent
    );

    dispatch(changeWebContentSuccess(validatedWebContent, toSave));
  } catch (e) {
    dispatch(
      Notificator.error(<FormattedMessage id={"couldnt_save_changes"} />)
    );
    dispatch(generalFailure(e, CHANGE_WEB_CONTENT_FAILURE));
    return Promise.reject();
  }
};

export const getWebFilteringPreview = () => async (
  dispatch,
  getState,
  { api }
) => {
  const state = getState(),
    {
      subscriberInfo: { id },
      resources: { webContent }
    } = state;

  try {
    const { toSave: newWebContent, resource, validation } = webContent,
      updatedWebContent = _.defaults(newWebContent, resource),
      validatedWebContent = removeInvalidValues(updatedWebContent, validation),
      previewParams = getPreviewParams(validatedWebContent)(state);

    const { data } = await api.ssm.resources.getPreview(
      id,
      ResourceName.WebContent,
      previewParams
    );
    localStorage.setItem("web-filtering", data);
  } catch (e) {
    dispatch(
      Notificator.error(<FormattedMessage id={"error_failed_to_load_data"} />)
    );
    dispatch(generalFailure(e, LOAD_WEB_CONTENT_FAILURE));
    return Promise.reject();
  }
};

export const LOAD_WEB_CONTENT_SUCCESS =
  "[RESOURCES] [WEB_CONTENT] LOAD_WEB_CONTENT_SUCCESS";
export const LOAD_WEB_CONTENT_FAILURE =
  "[RESOURCES] [WEB_CONTENT] LOAD_WEB_CONTENT_FAILURE";
export const CHANGE_WEB_CONTENT_SUCCESS =
  "[RESOURCES] [WEB_CONTENT] CHANGE_WEB_CONTENT_SUCCESS";
export const CHANGE_WEB_CONTENT_FAILURE =
  "[RESOURCES] [WEB_CONTENT] CHANGE_WEB_CONTENT_FAILURE";
export const CHANGE_ADMIN_EMAIL_TO_SAVE =
  "[RESOURCES] [WEB_CONTENT] CHANGE_ADMIN_EMAIL_TO_SAVE";
export const CHANGE_ADMIN_PHONE_TO_SAVE =
  "[RESOURCES] [WEB_CONTENT] CHANGE_ADMIN_PHONE_TO_SAVE";
export const CHANGE_MESSAGE_TO_SAVE =
  "[RESOURCES] [WEB_CONTENT] CHANGE_MESSAGE_TO_SAVE";
export const CHANGE_SHOW_CODE_TO_SAVE =
  "[RESOURCES] [WEB_CONTENT] CHANGE_SHOW_CODE_TO_SAVE";
export const CHANGE_SHOW_MESSAGE_TO_SAVE =
  "[RESOURCES] [WEB_CONTENT] CHANGE_SHOW_MESSAGE_TO_SAVE";
export const CHANGE_SHOW_NAME_TO_SAVE =
  "[RESOURCES] [WEB_CONTENT] CHANGE_SHOW_NAME_TO_SAVE";
export const RESET_TO_SAVE = "[RESOURCES] [WEB_CONTENT] RESET_TO_SAVE";

export const changeAdminEmailToSave = adminEmail => ({
  type: CHANGE_ADMIN_EMAIL_TO_SAVE,
  payload: adminEmail
});
export const changeAdminPhoneToSave = adminPhone => ({
  type: CHANGE_ADMIN_PHONE_TO_SAVE,
  payload: adminPhone
});
export const changeMessageToSave = message => ({
  type: CHANGE_MESSAGE_TO_SAVE,
  payload: message
});
export const changeShowCodeToSave = () => ({
  type: CHANGE_SHOW_CODE_TO_SAVE
});
export const changeShowMessageToSave = () => ({
  type: CHANGE_SHOW_MESSAGE_TO_SAVE
});
export const changeShowNameToSave = () => ({
  type: CHANGE_SHOW_NAME_TO_SAVE
});
export const loadWebContentSuccess = webContent => ({
  type: LOAD_WEB_CONTENT_SUCCESS,
  payload: webContent
});
export const changeWebContentSuccess = (webContent, toSave) => ({
  type: CHANGE_WEB_CONTENT_SUCCESS,
  payload: { webContent, toSave }
});

export const resetToSave = () => ({
  type: RESET_TO_SAVE
});
export const generalFailure = (error, type) => ({
  type,
  payload: error
});
