import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import * as Notificator from "../../../../components/notification/notification.actions";
import { FIXED_PROFILES } from "../../../../store/profiles/fixedProfiles";
import { loadCategories } from "../../../../store/categories/categories.actions";
import { loadSharedProtections } from "../../../../store/protections/protections.actions";
import { getCustomProtections } from "../../../profiles/components/webFilters/webFilters.actions";
import { Protection } from "@sportal/api";

export const load = () => dispatch => {
  dispatch(loadCategories());
  dispatch(loadSharedProtections());
  dispatch(getCustomProtections());
};

export const fetchTableData = url => (dispatch, getState, { api }) => {
  if (_.isEmpty(url)) {
    return Promise.resolve();
  }

  const requestData = { type: "fqdn", node: url },
    { profiles, webFilters, protections } = getState(),
    customProtections = webFilters.customProtections;

  dispatch(removeError());

  return api.ssm.protection
    .getCategoriesByUrl(requestData)
    .then(resp => {
      const tableData = processingData(
        resp.data.content,
        profiles,
        protections,
        customProtections
      );

      dispatch(processDataSuccess(tableData));
    })
    .catch(error => {
      switch (error.response && error.response.status) {
        case 500: {
          dispatch(
            Notificator.error(
              <FormattedMessage id={"domain_lookup_server_error"} />
            )
          );
          break;
        }
        case 400: {
          dispatch(
            processDataFailure(
              <FormattedMessage id={"domain_lookup_parse_failed"} />
            )
          );
          break;
        }
        default: {
          dispatch(
            Notificator.error(
              <FormattedMessage id={"domain_lookup_smth_wrong"} />
            )
          );
        }
      }
      dispatch(fetchTableDataFailure(error));
      return Promise.reject();
    });
};

export function processingData(
  categories,
  profiles,
  protections,
  customProtections
) {
  let tableData = [];

  _.each(categories, category => {
    let associatedProfiles = [];

    _.each(profiles.saved.list, profile => {
      let isFixed = _.some(FIXED_PROFILES, { name: profile.name }),
        blocked =
          profile.protection !== Protection.Custom
            ? protections.list[profile.protection].categories
            : customProtections[profile.id];

      if (_.includes(blocked, category.name)) {
        associatedProfiles.push({
          name: isFixed ? profile.type : profile.name,
          isFixed
        });
      }
    });

    tableData.push({
      category: category,
      associatedProfiles: associatedProfiles
    });
  });

  return tableData;
}

export const SEARCH_URL_CHANGE = "[DOMAIN_LOOKUP] SEARCH_URL_CHANGE";
export const PROCESS_DATA_SUCCESS = "[DOMAIN_LOOKUP] PROCESS_DATA_SUCCESS";
export const PROCESS_DATA_FAILURE = "[DOMAIN_LOOKUP] PROCESS_DATA_FAILURE";
export const FETCH_TABLE_DATA_FAILURE =
  "[DOMAIN_LOOKUP] FETCH_TABLE_DATA_FAILURE";
export const REMOVE_ERROR_MESSAGE = "[DOMAIN_LOOKUP] REMOVE_ERROR_MESSAGE";
export const CLEAR_ALL = "[DOMAIN_LOOKUP] CLEAR_ALL";

export const searchUrlChange = searchUrl => ({
  type: SEARCH_URL_CHANGE,
  payload: { searchUrl }
});
export const processDataSuccess = tableData => ({
  type: PROCESS_DATA_SUCCESS,
  payload: { tableData }
});
export const processDataFailure = errorMessage => ({
  type: PROCESS_DATA_FAILURE,
  payload: { errorMessage }
});
export const removeError = () => ({
  type: REMOVE_ERROR_MESSAGE
});
export const clearAllData = () => ({
  type: CLEAR_ALL
});
export const fetchTableDataFailure = error => ({
  type: FETCH_TABLE_DATA_FAILURE,
  payload: { error }
});
