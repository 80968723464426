import React from "react";
import { FormattedMessage } from "react-intl";

import { useForm } from "@sportal/cdk/form/Form";

import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog,
  ModalFormControl
} from "../../../modal";
import { DeviceNameInput } from "../../commonComponents";
import { useIntlTranslate } from "../../../../hooks";

export const RenameDeviceDialog = ({ modal, device, devices }) => {
  const translate = useIntlTranslate();
  const formProps = useForm({ name: device.name });

  const close = () => modal.reject();
  const submit = () => modal.resolve({ name: formProps.values.name.trim() });

  const createInputChangeHandler = name => (value, error) => {
    formProps.set(name, value);
    formProps.setError(name, error);
  };

  return (
    <ModalDialog
      className={"rename-device-dialog"}
      submitHandler={submit}
      rejectHandler={close}
      disabled={!formProps.didFormChange() || formProps.hasErrors()}
    >
      <CommonModalHeader>
        <FormattedMessage id={"rename_device"} />
      </CommonModalHeader>
      <ModalDialog.Body>
        <ModalFormControl>
          <DeviceNameInput
            name={formProps.values.name}
            error={formProps.errors.name && translate(formProps.errors.name)}
            devices={devices}
            handleChange={createInputChangeHandler("name")}
            label={<FormattedMessage id={"device_name"} />}
            autoFocus
          />
        </ModalFormControl>
      </ModalDialog.Body>
      <CommonModalFooter submitMessage={<FormattedMessage id="rename" />} />
    </ModalDialog>
  );
};
