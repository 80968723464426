import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { EMAIL_ADMIN_FIELD } from "../resources.actions";

const getErrorMessage = limit => (
  <FormattedMessage id={"max_characters_limit"} values={{ limit }} />
);

export const validateLength = (value, maxLength) => {
  if (!value || value.trim().length <= maxLength) return null;

  return getErrorMessage(maxLength);
};

export const trimValues = obj =>
  _.mapValues(obj, prop => (prop && prop.trim ? prop.trim() : prop));

const validateEmailPattern = (value, pattern) => {
  const emailRegex = new RegExp(pattern);

  return emailRegex.test(value);
};

export const validateResourceFields = (
  formValues,
  validation,
  fieldsToValidate
) => {
  const fields = _.toPairs(validation);

  const validatedFields = fields
    .filter(field => _.includes(fieldsToValidate, field[0]))
    .map(field => {
      const name = field[0];
      const rules = field[1];
      const value = formValues[name];

      const isLengthValid = !validateLength(value, rules.maxLength);

      if (EMAIL_ADMIN_FIELD.includes(name)) {
        const isEmailPatternValid =
          !value || validateEmailPattern(value, rules.emailRegex);
        const isValid = isLengthValid && isEmailPatternValid;

        return [name, { isValid, isEmailPatternValid }];
      }

      return [name, { isValid: isLengthValid }];
    });

  return _.fromPairs(validatedFields);
};

export const getFormValues = (saved, toSave, requiredFields) =>
  _.pick(
    {
      ...saved,
      ...toSave
    },
    requiredFields
  );

export const validateResourceForm = (
  validatedFields,
  values,
  validationMap
) => {
  if (_.isEmpty(validatedFields)) {
    return false;
  }

  const fieldsValidity = validationMap.map(({ name }) => {
    const foundedValidation = _.find(validationMap, { name }),
      isChecked = values[foundedValidation.name];

    if (!isChecked) {
      return true;
    }

    return foundedValidation.fields.map(
      field => validatedFields[field].isValid
    );
  });

  const isSomeFieldInvalid = _.flatten(fieldsValidity).find(field => !field);

  return isSomeFieldInvalid === undefined;
};

export const removeInvalidValues = (content, { fieldsToValidate, rules }) => {
  const validation = validateResourceFields(content, rules, fieldsToValidate),
    validContent = { ...content };

  for (let rule in validation) {
    if (!validation.hasOwnProperty(rule)) {
      break;
    }

    validContent[rule] = validation[rule].isValid ? validContent[rule] : "";
  }

  return validContent;
};

export const getInvalidValues = (content, { fieldsToValidate, rules }) => {
  const validation = validateResourceFields(content, rules, fieldsToValidate),
    invalidContent = {};

  for (let rule in validation) {
    if (!validation.hasOwnProperty(rule) && validation[rule].isValid) {
      break;
    }

    invalidContent[rule] = content[rule];
  }

  return invalidContent;
};

export const getMaxLengthValidationProps = (
  { maxLength: limit } = {},
  value
) => {
  const validate = (val, lim) => () => validateLength(val, lim);

  const limitProps = { limit, showLimit: true };
  const descriptiveErrorProps = {
    descriptiveError: getErrorMessage(limit),
    validate: validate(value, limit)
  };

  return {
    ...descriptiveErrorProps,
    ...limitProps
  };
};

const isNonEmptyString = str => {
  if (!_.isString(str)) return false;

  return str.trim().length > 0;
};

export const hasNonEmptyString = strings => _.some(strings, isNonEmptyString);

export const hasChanges = (saved, toSave) => {
  const changes = Object.keys(saved)
    .map(key => {
      const isLogo = key === "logo";
      const trim = val => val && val.trim && val.trim();
      const normalize = val => {
        if (!_.isString(val)) return val;
        if (val === "") return false;

        return val === "true";
      };
      const hasChange = () => {
        if (key && key.startsWith("show")) {
          return trim(normalize(saved[key])) !== trim(normalize(toSave[key]));
        }

        return trim(saved[key]) !== trim(toSave[key]);
      };
      const isChanged = hasChange();

      return {
        [key]: isLogo ? isChanged : !_.isNil(toSave[key]) && isChanged
      };
    })
    .reduce((prev, next) => ({ ...prev, ...next }), {});

  const changedFields = _(changes)
    .values()
    .compact()
    .value();

  return !_.isEmpty(changedFields);
};

export const prepareFormValues = (
  toSave,
  resource,
  requiredFields,
  rules,
  fieldsToValidate,
  scheme
) => {
  const validatedFields = validateResourceFields(
    toSave,
    rules,
    fieldsToValidate
  );

  const formValues = getFormValues(resource, toSave, requiredFields);
  const isValid = validateResourceForm(validatedFields, formValues, scheme);
  const haveChanges = hasChanges(
    resource,
    removeInvalidValues(formValues, { fieldsToValidate, rules }),
    scheme
  );

  return {
    formValues,
    isValid,
    isEmailPatternValid:
      validatedFields.adminEmail &&
      validatedFields.adminEmail.isEmailPatternValid,
    hasChanges: haveChanges
  };
};
