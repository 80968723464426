import React, { memo, ReactNode } from "react";

import { Tooltip } from "../../../tooltip/Tooltip";

interface DeviceNameCellProps {
  name?: string;
}

export const DeviceNameCell = memo<DeviceNameCellProps>(({ name = "" }) => {
  let content: ReactNode | string = name ? name : "-";

  if (name.length > 15) {
    content = (
      <Tooltip placement="top-start" content={name} autoWidth>
        {name}
      </Tooltip>
    );
  }

  return <div className="device-name">{content}</div>;
});

export const DeviceNameCellMobile = memo<DeviceNameCellProps>(
  ({ name = "-" }) => {
    return <span className="device-name">{name}</span>;
  }
);
