import React, { FC, LabelHTMLAttributes } from "react";
import { GetLabelPropsOptions } from "downshift";
import classNames from "classnames";

import "./SelectLabel.scss";

interface SelectLabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  getLabelProps: (options?: GetLabelPropsOptions) => any;
  ariaLabel?: string;
  value?: string;
}

export const SelectLabel: FC<SelectLabelProps> = ({
  getLabelProps,
  ariaLabel,
  value
}) => {
  return (
    <label
      {...getLabelProps({
        className: classNames("select__label", {
          "select__label--hidden": !value
        }),
        "aria-label": ariaLabel
      })}
      data-testid="select-label"
    >
      {value}
    </label>
  );
};
