import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";

import { prepareFormValues } from "../../helpers/form.helper";
import {
  getFromWebContentValidation,
  getWebContentRequiredFields,
  getWebContentResource,
  getWebContentToSave
} from "./webContent.selectors";

export const useWebFilteringForm = () => {
  const toSave = useSelector(getWebContentToSave, shallowEqual);
  const resource = useSelector(getWebContentResource, shallowEqual);
  const requiredFields = useSelector(getWebContentRequiredFields, shallowEqual);

  const rules = useSelector(getFromWebContentValidation("rules"), _.isEqual);
  const fieldsToValidate = useSelector(
    getFromWebContentValidation("fieldsToValidate"),
    shallowEqual
  );
  const scheme = useSelector(getFromWebContentValidation("scheme"), _.isEqual);

  return prepareFormValues(
    toSave,
    resource,
    requiredFields,
    rules,
    fieldsToValidate,
    scheme
  );
};
