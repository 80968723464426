import React, { FC } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog
} from "../../../../components/modal";
import {
  getEulaCancelRedirectUrl,
  getEulaCancelStrategy
} from "../../../../store/config.selectors";

import "./EulaDialog.scss";

export enum EULA_CANCEL_STRATEGY {
  BLANK = "blank",
  RELOAD = "reload",
  REDIRECT = "redirect"
}

interface EulaDialogProps {
  modal: any; // todo: until the ModalService passes the proper type this will stay as any
}

const reload = (): void => window.location.reload();
const redirect = (url: string): void => window.location.assign(url);

export const BLANK_PAGE = "about:blank";

export const EulaDialog: FC<EulaDialogProps> = ({ modal }) => {
  const cancelStrategy = useSelector(getEulaCancelStrategy);
  const redirectURL = useSelector(getEulaCancelRedirectUrl);

  const handleCancelStrategy = () => {
    if (cancelStrategy === EULA_CANCEL_STRATEGY.BLANK) {
      redirect(BLANK_PAGE);
      return;
    }

    if (cancelStrategy === EULA_CANCEL_STRATEGY.REDIRECT) {
      redirect(redirectURL);
      return;
    }

    reload();
  };

  const handleReject = e => {
    e.preventDefault();

    handleCancelStrategy();
    modal.reject();
  };

  return (
    <ModalDialog
      className="eula-dialog"
      submitHandler={() => modal.resolve()}
      rejectHandler={handleReject}
    >
      <CommonModalHeader>
        <FormattedMessage id="eulaDialogHeader" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <FormattedMessage id="eulaDialogText" />
      </ModalDialog.Body>
      <CommonModalFooter
        submitMessage={<FormattedMessage id="eulaAccept" />}
        cancelMessage={<FormattedMessage id="eulaReject" />}
      />
    </ModalDialog>
  );
};
