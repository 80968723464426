import React, { Fragment } from "react";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { Button } from "../../../components/button/Button";
import { Icon } from "../../../components/icon/Icon";
import { HelpLink } from "../../../components/helpLink/HelpLink";
import { Desktop, TabletMobile } from "../../../hocs/Responsive";
import { generateDeeplink } from "../../../components/deeplinkDialog/deeplinkDialog.actions";

import "./SpsonPanel.scss";

const SpsonPanelBase = ({ className, description }) => {
  const dispatch = useDispatch();

  function handleGenerateLinkClick() {
    dispatch(generateDeeplink());
  }

  const panelClasses = classnames("spson-panel", className);

  return (
    <div className={panelClasses}>
      <div className={"spson-panel__header"}>
        <FormattedMessage id={"spson"} />{" "}
        <FormattedMessage id={"instalation_link"} />
      </div>
      {description && (
        <div className={"spson-panel__description"}>
          <FormattedMessage id={"spson_installation_description"} />
        </div>
      )}
      <HelpLink
        className={"spson-panel__extra-info"}
        section={"sps-remote-client"}
      >
        <Icon icon={"far fa-question-circle"} />
        <span>
          <FormattedMessage id={"get_help"} />
        </span>
      </HelpLink>
      <Button
        size={"large"}
        className={"spson-panel__cta"}
        onClick={handleGenerateLinkClick}
      >
        <Icon icon={"fas fa-arrow-circle-right"} />
        <span>
          <FormattedMessage id={"generate_link"} />
        </span>
      </Button>
    </div>
  );
};

export const SpsonFluidPanel = ({ className }) => (
  <SpsonPanelBase className={classnames(className, "spson-panel--fluid")} />
);

export const SpsonPanel = ({ className }) => (
  <Fragment>
    <TabletMobile>
      <SpsonFluidPanel className={className} />
    </TabletMobile>
    <Desktop>
      <SpsonPanelBase
        className={classnames(className, "spson-panel--sidebar")}
        description={<FormattedMessage id={"spson_installation_description"} />}
      />
    </Desktop>
  </Fragment>
);
