import React, { Component, Fragment } from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import "./UploadCsvBlock.scss";

import Input from "../../../../components/input/Input";
import ButtonLink from "../../../../components/buttonLink/ButtonLink";
import { Button } from "../../../../components/button/Button";
import { URL_TYPES } from "../../../../common/utils/url_types";
import { Icon } from "../../../../components/icon/Icon";

class UploadCsvBlock extends Component {
  render() {
    const { toggleAddMethod, uploadUrlsState, clearUploadState } = this.props;
    const {
      siteList,
      fileName,
      errorMessage,
      isUploadFailed
    } = uploadUrlsState;

    const isEmpty = _.isEmpty(siteList);
    const containsPublicSuffixType = siteList.some(
      ({ type }) => type === URL_TYPES.PUBLIC_SUFFIX
    );

    return (
      <div className="upload-csv">
        <div className="upload-csv__container">
          {isEmpty && (
            <Button as={"label"} size={"large"} className={"input__label"}>
              <FormattedMessage id={"choose_file"} />
              <Input
                type="file"
                id="upload-csv"
                accept=".csv,text/csv"
                hidden
                value=""
                onChange={this.onInputChange}
              />
            </Button>
          )}
          <div className="upload-csv__input-message">
            {isEmpty ? (
              <FormattedMessage id={"upload_one_file"} />
            ) : (
              <Fragment>
                <span className="upload-csv__fileName">{fileName}</span>
                <div className="upload-csv__description">
                  <span className="list-length">{siteList.length}</span>
                  <FormattedMessage id={"domains"} />
                  <span
                    className="upload-csv__clear-input"
                    onClick={clearUploadState}
                  >
                    &times;
                  </span>
                </div>
              </Fragment>
            )}
          </div>
        </div>
        {containsPublicSuffixType && (
          <div className="upload-csv__top-level-domain">
            <Icon icon="far fa-exclamation-triangle color-danger upload-csv__warning-icon" />
            <div className="upload-csv__top_level_domain-message">
              <FormattedMessage
                id="top_level_domain_in_csv"
                values={{
                  enterManually: (
                    <ButtonLink
                      className="toggle-btn"
                      onClick={this.goBackToManualTyping}
                    >
                      <span className="lowercase">
                        <FormattedMessage id="enter_manually" />
                      </span>
                    </ButtonLink>
                  )
                }}
              />
            </div>
          </div>
        )}
        {errorMessage && (
          <div className="upload-csv__error-message">{errorMessage}</div>
        )}

        {(!isEmpty || isUploadFailed) &&
          this.renderActionButtons(containsPublicSuffixType)}

        {isEmpty && !isUploadFailed ? (
          <ButtonLink className="toggle-btn" onClick={toggleAddMethod}>
            <FormattedMessage id={"enter_manually"} />
          </ButtonLink>
        ) : null}
      </div>
    );
  }

  renderActionButtons(containsPublicSuffix) {
    const { errorMessage, isUploadFailed } = this.props.uploadUrlsState;

    return (
      <div className="upload-csv__action-buttons">
        {errorMessage || isUploadFailed || containsPublicSuffix ? (
          <Button isOutline onClick={this.props.clearUploadState}>
            <FormattedMessage id="cancel" />
          </Button>
        ) : (
          <Fragment>
            <Button
              color={"destructive"}
              onClick={() => this.handleAddUrls("blacklist")}
            >
              <FormattedMessage id="block" />
            </Button>
            <Button onClick={() => this.handleAddUrls("whitelist")}>
              <FormattedMessage id="allow" />
            </Button>
          </Fragment>
        )}
      </div>
    );
  }

  onInputChange = event => {
    const input = event.target;

    this.props.fileParser(input.files[0], input.value);
  };

  handleAddUrls = list => {
    const { addUrlsToList, uploadUrlsState, profileId } = this.props;

    addUrlsToList(list, uploadUrlsState.siteList, profileId);
  };

  goBackToManualTyping = () => {
    this.props.toggleAddMethod();
    this.props.clearUploadState();
  };
}

export default UploadCsvBlock;
