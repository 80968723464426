import _ from "lodash";
import marked from "marked";

// There are a few types of links in help md:
// 1. <img> ones which should reference the directory where md file is placed
// and it is fixed by passing baseUrl option to marked
// 2. <a> ones which should reference relatively to the current page
// and it is fixed by the following renderer
class Renderer extends marked.Renderer {
  link(href, title, text) {
    // All links without `#` are treated as external
    href = _.startsWith(href, "#") ? `#help/${href.replace("#", "")}` : href;
    return super.link(href, title, text);
  }
}

const getMarkedOptions = ({ language }) => ({
  baseUrl: `translations/help/${language}/`,
  smartypants: true,
  renderer: new Renderer()
});

const makeId = (text, index) => {
  const explicitId = text.match(/\{#(.+)\}/);
  const id = !_.isEmpty(explicitId)
    ? explicitId[1]
    : // It is copied from marked's sources, since there it is used by parser and
      // we need it before parser stage
      text.toLowerCase().replace(/[^\w]+/gu, "-");

  return id !== "-" ? id : `s${index}`;
};

const explicitIdRegexp = /\{#(.+)\}/;

const splitSections = tokens =>
  _.transform(
    tokens,
    (sections, token, index) => {
      const section = _.last(sections);
      if (token.type === "heading" && _.includes([1, 2], token.depth)) {
        sections.push({
          id: makeId(token.text, index),
          level: token.depth,
          header: { ...token, text: token.text.replace(explicitIdRegexp, "") },
          tokens: []
        });
      } else if (section) {
        section.tokens.push(token);
      }
    },
    []
  );

const parse = (tokens, options) =>
  _([...tokens]) // marked erases the tokens array
    .assign({ links: {} })
    .thru(tokens => marked.parser(tokens, options))
    .value();

/**
 * Parses provided markdown string
 * @param mdContent string Markdown markup
 * @param language string Language code used as part of baseUrl for loading images
 * @returns {Array<{id: string, level: number, header: string, content: string}>}
 */
export const parseMarkdown = (mdContent, language) => {
  const options = getMarkedOptions({ language });
  const tokens = marked.lexer(mdContent, options);
  const sections = splitSections(tokens);

  return _.map(sections, section => ({
    id: section.id,
    level: section.level,
    header: {
      html: parse([section.header], options),
      text: section.header.text
    },
    content: {
      html: parse(section.tokens, options)
    }
  }));
};
