import { useSelector } from "react-redux";
import { isValidProfileId } from "../../store/profiles/profiles.selectors";

export const useValidIdForSettingsPaths = (
  profileId,
  defaultProfileId,
  allowedId
) => {
  const isProfileIdValid = useSelector(isValidProfileId(profileId));

  return isProfileIdValid || profileId === allowedId
    ? profileId
    : defaultProfileId;
};
