const USER_AGENTS = {
  iPad: /iPad/,
  iPhone: /iPhone/,
  windowsPhone: /Windows Phone/,
  android: "/Android/",
  macOS: /Mac OS/,
  windows: /Windows NT/,
  iOS: /iPhone|iPad/
};

export const isIPad = () => {
  return USER_AGENTS.iPad.test(navigator.userAgent);
};

export const isIOS = () => {
  return USER_AGENTS.iOS.test(navigator.userAgent);
};
