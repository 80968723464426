import React from "react";
import { useIntlTranslate } from "../../hooks";
import NativeSelect from "../select/NativeSelect";
import { isFixed } from "../../store/profiles/fixedProfiles";

export const ProfilesNativeSelect = ({
  id,
  profiles,
  selected,
  onChange,
  formatSelection,
  label,
  ariaLabelMessage
}) => {
  const translate = useIntlTranslate();
  const translateProfile = id => (isFixed(id) ? translate(id) : id);

  return (
    <NativeSelect
      id={id}
      options={profiles}
      selected={selected}
      toggleView="input"
      onChange={selected => onChange(selected)}
      formatSelection={(id, value) =>
        formatSelection ? formatSelection(id, value) : translateProfile(id)
      }
      formatOption={id => translateProfile(id)}
      label={label}
      ariaLabelMessage={ariaLabelMessage}
    />
  );
};
