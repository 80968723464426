import React, { Component } from "react";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import "./NotificationContainer.scss";
import CloseButton from "./CloseButton";
import Notificator from "./notification";
import { PRIORITIES, remove } from "./notification.actions";
import { getTopOffset } from "../../helpers/navigation.helper";
import { getRtl } from "../../store/language.selectors";
import { getNotifications } from "../../store/root.selectors";
import { useLayoutContext } from "../layout";

export const NOTIFICATION_DURATION = 3000; // move to config

// This functions ensures tha all dom elements are rendered when callback is executed
// https://stackoverflow.com/a/34999925
const onNextFrame = callback =>
  setTimeout(() => {
    window.requestAnimationFrame(callback);
  }, 0);

class NotificationContainerContent extends Component {
  state = {
    topOffset: 0
  };

  defaultOptions = {
    hideProgressBar: true,
    autoClose: NOTIFICATION_DURATION,
    position: toast.POSITION.TOP_CENTER,
    className: "notificationWrapper",
    closeButton: <CloseButton />,
    closeOnClick: false,
    newestOnTop: true
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleOnScrollOrResize);
    window.addEventListener("resize", this.handleOnScrollOrResize);
    this.updateTopOffset();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScrollOrResize);
    window.removeEventListener("resize", this.handleOnScrollOrResize);
  }

  handleOnScrollOrResize = () => {
    if (!this.getActiveCount(this.props.notifications.list)) return;

    this.updateTopOffset();
  };

  updateTopOffset = () => {
    this.setState({ topOffset: getTopOffset(this.props.eulaHeaderHeight) });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.notifications !== nextProps.notifications) {
      return true;
    }

    return this.state.topOffset !== nextState.topOffset;
  }

  componentDidUpdate(prevProps) {
    onNextFrame(this.updateTopOffset);

    const {
      notifications: { list }
    } = this.props;
    if (!list.length) {
      Notificator.dismissAll();
      return;
    }

    if (prevProps.notifications.list.length === list.length) {
      return;
    }

    const latestNotification = list && list[list.length - 1];
    const highestPriorityNotification = _.findLast(list, {
      priority: PRIORITIES.high
    });

    this.showNotification(highestPriorityNotification || latestNotification);
  }

  showNotification({ content, type, id, dismissed, title }) {
    if (Notificator.isActive(id) || dismissed) {
      return;
    }

    Notificator[type](content, {
      id,
      title,
      onClose: () => this.props.remove(id)
    });
  }

  getActiveCount(notifications) {
    return notifications.filter(notification => !notification.dismissed).length;
  }

  render() {
    const isMultiple = this.getActiveCount(this.props.notifications.list) > 1,
      duration = isMultiple ? [0, 200] : [300, 200];

    return (
      <ToastContainer
        {...this.defaultOptions}
        rtl={this.props.isRtl || false}
        transition={cssTransition({
          enter: "fadeIn",
          exit: "fadeOut",
          duration
        })}
        style={{ top: this.state.topOffset }}
      />
    );
  }
}

export const NotificationContainer = () => {
  const dispatch = useDispatch();

  const rtl = useSelector(getRtl);
  const notifications = useSelector(getNotifications, _.isEqual);

  const { eulaHeaderHeight } = useLayoutContext();

  return (
    <NotificationContainerContent
      isRtl={rtl}
      notifications={notifications}
      eulaHeaderHeight={eulaHeaderHeight}
      remove={id => dispatch(remove(id))}
    />
  );
};
