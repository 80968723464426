import React, { useRef, Fragment, useEffect, useCallback } from "react";
import _ from "lodash";
import * as d3 from "d3";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "@sportal/cdk/hooks";

import { useBarChartContext } from "../../BarChartProvider";
import {
  applyZoom,
  cancelZoom
} from "../../../../../store/reports/reports.actions";
import {
  getReportsZoomVisible,
  getZoomEnd,
  getZoomStart
} from "../../../../../store/reports/reports.selectors";
import { Granularity } from "../../../../../helpers/reports.helper.types";

import "./Brush.scss";

const BRUSH_LINE_OFFSET = 1;

export const Brush = ({ children }) => {
  const {
    chartWidth,
    chartHeight,
    bottomChartHeight,
    period,
    ticks,
    xBandScale
  } = useBarChartContext();
  const dispatch = useDispatch();
  const wrapper = useRef(null);

  const startTick = useSelector(getZoomStart);
  const endTick = useSelector(getZoomEnd);
  const visible = useSelector(getReportsZoomVisible);
  const prevVisibility = usePrevious(visible);

  const barWidth = xBandScale.bandwidth();
  const ticksPositions = _.map(ticks, tick => ({
    tick,
    coordinate: xBandScale(tick)
  }));
  const brush = d3
    .brushX()
    .handleSize(2)
    .touchable(false)
    .on("start brush", brushed)
    .on("end", brushEnded);

  const getEndTick = useCallback(
    (end, field = "coordinate") => {
      const endTickStub = {
        tick: _.last(ticksPositions).tick + Granularity[period],
        coordinate: chartWidth + BRUSH_LINE_OFFSET
      };

      if (field === "coordinate") {
        return end > chartWidth - barWidth / 2
          ? endTickStub
          : closest(ticksPositions, end);
      } else {
        return ticksPositions.find(({ tick }) => tick === end) || endTickStub;
      }
    },
    [barWidth, chartWidth, period, ticksPositions]
  );

  const selectBars = useCallback(() => {
    const x0 = closest(ticksPositions, startTick, "tick");
    const x1 = getEndTick(endTick, "tick");

    d3.select(wrapper.current).call(brush.move, [x0.coordinate, x1.coordinate]);
  }, [brush.move, endTick, getEndTick, startTick, ticksPositions]);

  const resetBrush = useCallback(() => {
    d3.selectAll(".bar").classed("rect-stack--brushed", false);
    d3.select(wrapper.current).call(brush.move, null);
  }, [brush.move]);

  useEffect(() => {
    if (prevVisibility && prevVisibility !== visible) {
      resetBrush();
    }

    d3.select(wrapper.current).call(
      brush.extent([[0, 0], [chartWidth, chartHeight - bottomChartHeight]])
    );
  }, [
    bottomChartHeight,
    brush,
    chartHeight,
    chartWidth,
    prevVisibility,
    resetBrush,
    visible
  ]);

  useEffect(() => {
    if (!startTick || !endTick) return;

    selectBars();
  }, [startTick, endTick, selectBars]);

  function brushed({ selection }) {
    if (!selection) return;

    const [start, end] = selection;
    if (start === end) {
      dispatch(cancelZoom());
      resetBrush();
    }

    const bars = d3.selectAll(".bar");
    bars.classed("rect-stack--brushed", d => {
      const position = d.position.x + barWidth / 2;
      return start < position && end > position;
    });
  }

  function brushEnded({ sourceEvent, selection }) {
    if (!sourceEvent || !selection) return; // Only transition after interaction.

    const [start, end] = selection;
    const x0 = closest(ticksPositions, start);
    const x1 = getEndTick(end);

    if (x0.coordinate === x1.coordinate) {
      dispatch(cancelZoom());
      resetBrush();

      return;
    }

    if (!_.isEqual([x0.tick, x1.tick], [startTick, endTick])) {
      dispatch(applyZoom(x0.tick, x1.tick));
    }

    d3.select(this)
      .transition()
      .delay(50)
      .call(brush.move, [
        x0.coordinate - BRUSH_LINE_OFFSET,
        x1.coordinate - BRUSH_LINE_OFFSET
      ]);
  }

  return (
    <Fragment>
      <g className={`brush-container`} pointerEvents={"none"} ref={wrapper} />
      {children}
    </Fragment>
  );
};

const closest = (collection, goal, field = "coordinate") =>
  _.reduce(collection, (prev, curr) =>
    Math.abs(curr[field] - goal) < Math.abs(prev[field] - goal) ? curr : prev
  );
