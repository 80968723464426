import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import _ from "lodash";

import Input from "../../../components/input/Input";
import { Mobile } from "../../../hocs/Responsive";
import { Button } from "../../../components/button/Button";
import { Icon } from "../../../components/icon/Icon";
import { useLayoutContext } from "../../../components/layout";

import "./HelpSearch.scss";

const MOBILE_HEADER_HEIGHT = 57;

const HelpSearchContent = ({
  onGoToNext,
  onGoToPrevious,
  hasNoMatches,
  value,
  onChange,
  onDoSearch,
  intl
}) => {
  const { eulaHeaderHeight } = useLayoutContext();

  const [isSearchApplied, setSearchApplied] = useState(false);

  const emitValue = _.debounce(value => {
    const isSearchApplied = value.length > 2;

    setSearchApplied(isSearchApplied);
    onDoSearch(isSearchApplied ? value : "");
  }, 300);

  const handleInputChange = value => {
    emitValue(value);
    onChange(value);
  };

  return (
    <div
      className="help-search"
      style={{ top: `${eulaHeaderHeight + MOBILE_HEADER_HEIGHT}px` }}
    >
      <div className="help-search__controls">
        <Input
          icon="fas fa-search"
          className="help-search__input"
          placeholder={intl.formatMessage({ id: "search" })}
          onChange={({ target: { value } }) => handleInputChange(value)}
          onClear={() => handleInputChange("")}
          value={value}
          aria-label={intl.formatMessage({
            id: "aria_help_and_documentation"
          })}
        />
        {isSearchApplied && !hasNoMatches && (
          <Mobile>
            <Button
              className="help-search__nav-button"
              onClick={onGoToPrevious}
              isOutline
              size={"large"}
            >
              <Icon icon="far fa-chevron-up" />
            </Button>
            <Button
              className="help-search__nav-button"
              onClick={onGoToNext}
              isOutline
              size={"large"}
            >
              <Icon icon="far fa-chevron-down" />
            </Button>
          </Mobile>
        )}
      </div>
      {isSearchApplied && hasNoMatches && (
        <div className="help-search__message">
          <FormattedMessage id="no_matches_found" />
        </div>
      )}
    </div>
  );
};

export const HelpSearch = injectIntl(HelpSearchContent);
