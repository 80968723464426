import _ from "lodash";

function stringifyArgs(
  locale: string,
  options: Intl.DateTimeFormatOptions
): string {
  const sortedKeys = Object.keys(options).sort();

  return `${locale},{${sortedKeys.map(key => `${key}:${options[key]}`)}}`;
}

function createDateTimeFormat(
  locale: string,
  options: Intl.DateTimeFormatOptions
): Intl.DateTimeFormat {
  return new Intl.DateTimeFormat(locale, options);
}

export const dateTimeFormatCache = _.memoize(
  createDateTimeFormat,
  stringifyArgs
);
