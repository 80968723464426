import React from "react";
import classnames from "classnames";

import "./SeverityIndicator.scss";

export const SeverityIndicator = props => {
  const { severity, className, children } = props;

  return (
    <div
      className={classnames(
        `severity-level severity-level--${severity}`,
        className
      )}
    >
      {children}
    </div>
  );
};

SeverityIndicator.defaultProps = {
  severity: 0
};
