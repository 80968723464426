import _ from "lodash";

import Notificator from "../../components/notification/notification.actions";

export const fetchThreats = () => (dispatch, getState, { api }) => {
  const { accountSettings, threats } = getState();

  const shouldFetch = _.isEmpty(threats.keys);
  if (!shouldFetch) {
    return Promise.resolve();
  }

  return api.ssm.threats
    .getThreats(accountSettings.language)
    .then(({ content }) => {
      const list = _.map(content, threat =>
        _.defaults(threat, { severity: 0 })
      );
      dispatch(fetchThreatsSuccess(list));
    })
    .catch(response => {
      dispatch(Notificator.error("failed_to_load_threats"));
      return Promise.reject(response);
    });
};

export const FETCH_THREATS_SUCCESS = "[THREATS] FETCH_THREATS_SUCCESS";

export const fetchThreatsSuccess = threats => ({
  type: FETCH_THREATS_SUCCESS,
  payload: threats
});
