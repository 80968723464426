import _ from "lodash";
import { intervalToDuration } from "date-fns";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

import { getDaysShifted } from "../schedules.selectors";
import { parse } from "../../../../../components/timePicker/TimePicker";

import "./ScheduleRule.scss";

const FIXED_START_DATE = "01/01/2021";
const FIXED_END_DATE = "01/02/2021";

class ScheduleRule extends Component {
  render() {
    const {
      intl,
      timeStart,
      timeEnd,
      selectedDays,
      onMouseEnter,
      onMouseLeave,
      timeFormat24h,
      useShortNotations,
      firstDayOfWeek
    } = this.props;

    const start = parse(timeStart, timeFormat24h);
    const end = parse(timeEnd, timeFormat24h);

    const selectedSortedDays = getDaysShifted(firstDayOfWeek).filter(day =>
      selectedDays.includes(day)
    );
    const days = translateDays(selectedSortedDays, this.props);
    const showDaysError = _.isEmpty(days);

    return (
      <div
        className={"rule__preview"}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={"rule__preview__time"}>
          <span className={"rule__preview__time--bold"}>
            <span>{renderTime(start)}</span>
            {" – "}
            <span>{renderTime(end)}</span>
          </span>
          <span>{renderDuration(intl, useShortNotations)}</span>
        </div>
        <div className={"rule__preview__days"}>
          {showDaysError ? (
            <FormattedMessage id={"days_error"} />
          ) : (
            days.join(", ")
          )}
        </div>
      </div>
    );

    function translateDays(days, props) {
      const { intl, useShortNotations } = props;
      const suffix = useShortNotations ? "_short" : "";

      return _.map(days, day => intl.formatMessage({ id: `${day}${suffix}` }));
    }

    function renderDuration(intl, useShortNotations) {
      const message = (type, val) => {
        if (useShortNotations) {
          return val
            ? `${val}${intl.formatMessage({ id: `${type}_short` })}`
            : "";
        }

        return val ? `${intl.formatMessage({ id: type }, { total: val })}` : "";
      };

      const startDate = new Date(`${FIXED_START_DATE} ${timeStart}`);
      const endDate = new Date(`${FIXED_END_DATE} ${timeEnd}`);

      const duration = intervalToDuration({
        start: startDate,
        end: endDate
      });

      let hours;
      let minutes;

      if (
        duration.days === 1 &&
        duration.hours === 0 &&
        duration.minutes === 0
      ) {
        hours = message("hours", 24);
      } else {
        hours = message("hours", duration.hours);
        minutes = message("minutes", duration.minutes);
      }

      const joined = _.join(_.compact([hours, minutes]), " ");

      return `(${joined})`;
    }

    function renderTime(time) {
      return `${time.hours}:${time.minutes} ${time.ampm ? time.ampm : ""}`;
    }
  }
}

ScheduleRule.propTypes = {
  timeFormat24h: PropTypes.bool,
  firstDayOfWeek: PropTypes.string,
  daysTaken: PropTypes.arrayOf(PropTypes.string),
  onRuleChange: PropTypes.func,
  useShortNotations: PropTypes.bool
};

ScheduleRule.defaultProps = {
  timeFormat24h: false,
  daysTaken: [],
  removeDisabled: false,
  firstDayOfWeek: "MO"
};

export default injectIntl(ScheduleRule);
