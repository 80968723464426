const ELLIPSIS_SYMBOL = "…";

export const ellipsis = (text, maxlen) => {
  if (text.length > maxlen) {
    return text.substr(0, maxlen - ELLIPSIS_SYMBOL.length) + ELLIPSIS_SYMBOL;
  }
  return text;
};

export const ellipsisInTheMiddle = (text, maxlen) => {
  if (text.length > maxlen) {
    return (
      text.substring(0, 4) +
      ELLIPSIS_SYMBOL +
      text.substring(text.length - (maxlen - 4 - ELLIPSIS_SYMBOL.length))
    );
  }
  return text;
};
