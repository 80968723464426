import React, { FC } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { getReportsZoom } from "../../../../store/reports/reports.selectors";
import { TimeRange } from "../../../../components/time/timeRange/TimeRange";
import ButtonLink from "../../../../components/buttonLink/ButtonLink";
import { Icon } from "../../../../components/icon/Icon";
import { ReportsPeriod } from "../../../../helpers/reports.helper.types";

interface ChartSliceInfoProps {
  onCancelZoom: () => void;
  period: ReportsPeriod;
}

export const ChartSliceInfo: FC<ChartSliceInfoProps> = ({
  onCancelZoom,
  period
}) => {
  const zoom = useSelector(getReportsZoom);

  if (!zoom.start || !zoom.end) {
    return null;
  }

  return (
    <div className="time-range" data-testid="selected-time-range">
      <span className="time-range__title">
        <FormattedMessage id="selected_period" />
      </span>
      <TimeRange
        className="time-range__info"
        {...zoom}
        period={period}
        fullNotation={true}
      />
      <ButtonLink
        className="time-range__reset"
        onClick={onCancelZoom}
        data-testid="reset-time-range-button"
      >
        <Icon icon="far fa-times" />
      </ButtonLink>
    </div>
  );
};
