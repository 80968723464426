import React, { forwardRef, ButtonHTMLAttributes } from "react";
import classNames from "classnames";

import "./UnstyledButton.scss";

export const UnstyledButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, ...rest }, ref) => (
  <button
    ref={ref}
    className={classNames("unstyled-button", className)}
    {...rest}
  />
));

UnstyledButton.defaultProps = {
  type: "button"
};
