import React, { memo, useRef } from "react";

import {
  useDataTransitionAnimation,
  useWalkAnimation
} from "../animations.hooks";

interface LineChartProps {
  path: string;
  id: string;
}

export const LineChart = memo<LineChartProps>(function LineChart({ path, id }) {
  const pathRef = useRef<SVGPathElement>(null);

  const { isFinished } = useWalkAnimation(pathRef);
  useDataTransitionAnimation(pathRef, path, isFinished);

  const additionalAttrs = !isFinished ? { d: path } : {};

  return (
    <path
      ref={pathRef}
      {...additionalAttrs}
      id={`${id}_line`}
      fill="none"
      strokeWidth={2}
    />
  );
});
