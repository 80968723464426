import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { getShowEulaDialog } from "../../../../store/config.selectors";
import { EulaDialog } from "./EulaDialog";
import { ModalService } from "@sportal/cdk/modal";

export const useEulaDialog = (): void => {
  const showEulaDialog = useSelector(getShowEulaDialog);

  useEffect(() => {
    if (showEulaDialog) {
      ModalService.show(modal => ({
        dialog: <EulaDialog modal={modal} />
      }))
        .then(() => {})
        .catch(e => new Error(e));
    }

    return (): void => ModalService.modalReject();
  }, [showEulaDialog]);
};
