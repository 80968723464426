import { useEffect } from "react";

import { useCallbackInstance } from "./useCallbackInstance";

export const useTimeout = (callback, delay) => {
  const savedCallback = useCallbackInstance(callback);

  useEffect(() => {
    function onTimeout() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setTimeout(onTimeout, delay);
      return () => clearTimeout(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay]);
};
