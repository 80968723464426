import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { ModalDialog, useModalDialogContext } from "../Modal";
import { Button } from "../../button/Button";

export const CommonModalFooter = ({
  submitButtonType,
  submitMessage,
  cancelMessage
}) => {
  const { disabled, submit, reject } = useModalDialogContext();

  return (
    <ModalDialog.Footer>
      <ModalDialog.Control>
        <Button onClick={reject} isOutline size={"large"}>
          {cancelMessage}
        </Button>
      </ModalDialog.Control>
      <ModalDialog.Control>
        <Button
          color={submitButtonType}
          disabled={disabled}
          onClick={submit}
          size={"large"}
        >
          {submitMessage}
        </Button>
      </ModalDialog.Control>
    </ModalDialog.Footer>
  );
};

CommonModalFooter.defaultProps = {
  submitButtonType: "primary",
  submitMessage: <FormattedMessage id="save" />,
  cancelMessage: <FormattedMessage id="cancel" />
};

CommonModalFooter.propTypes = {
  submitButtonType: PropTypes.string,
  submitMessage: PropTypes.node.isRequired,
  cancelMessage: PropTypes.node.isRequired
};
