import React, { Fragment, useRef } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useElementDimensions } from "@sportal/cdk/hooks";

import { NotMobile } from "../../../hocs/Responsive";
import { ReportTypes } from "../../../helpers/reports.helper.types";
import {
  getBarchartReports,
  getReportsPeriod
} from "../../../store/reports/reports.selectors";
import {
  BarChartProvider,
  BarChartTooltip,
  Bars,
  ChartNoDataMessage,
  useBarChartContext,
  useBarchartTooltipCleanup,
  XAxis,
  yLinearScale,
  yPowScale
} from "../../../components/charts/barChart";

const BOTTOM_CHART_HEIGHT = 60;

export const CHART_SETTINGS = {
  barTypes: [ReportTypes.Activity, ReportTypes.Webfilter, ReportTypes.Malware],
  dataSelection: {
    top: d => d[ReportTypes.Activity] || d[ReportTypes.Webfilter],
    bottom: d => d[ReportTypes.Malware]
  },
  [ReportTypes.Activity]: {
    yOffsets: val => Math.abs(yPowScale(val)) || 0,
    getHeight: val => Math.abs(yPowScale(val) - yPowScale(0)) || 0
  },
  [ReportTypes.Webfilter]: {
    yOffsets: val => Math.abs(yPowScale(val)) || 0,
    getHeight: val => Math.abs(yPowScale(val) - yPowScale(0)) || 0
  },
  [ReportTypes.Malware]: {
    yOffsets: () => 176,
    getHeight: val => Math.abs(yLinearScale(val) - yLinearScale(0)) || 0
  }
};

export const HomePageBarChart = () => {
  const container = useRef(null);
  const rawData = useSelector(getBarchartReports);
  const period = useSelector(getReportsPeriod);
  const { width, height } = useElementDimensions(container);

  useBarchartTooltipCleanup();

  return (
    <div className={"scrollable-container"}>
      <div className={"width-container"}>
        <div
          className="home-page__bar-chart barchart-container"
          ref={container}
        >
          <BarChartProvider
            chartWidth={width}
            chartHeight={height}
            bottomChartHeight={BOTTOM_CHART_HEIGHT}
            chartSettings={CHART_SETTINGS}
            period={period}
            data={rawData}
          >
            <BarChart container={container} />
          </BarChartProvider>
        </div>
      </div>
    </div>
  );
};

const BarChart = ({ container }) => {
  const { data } = useBarChartContext();

  return (
    <Fragment>
      <svg>
        <XAxis />
        {!_.isEmpty(data) ? (
          <Fragment>
            <Bars data={data} type={ReportTypes.Activity} />
            <Bars data={data} type={ReportTypes.Webfilter} />
            <Bars data={data} type={ReportTypes.Malware} />
          </Fragment>
        ) : (
          <ChartNoDataMessage />
        )}
      </svg>
      <NotMobile>
        <BarChartTooltip container={container} showTopData={true} />
      </NotMobile>
    </Fragment>
  );
};
