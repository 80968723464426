import _ from "lodash";
import {
  addItemsToList,
  removeItemFromList,
  removeItemsFromList
} from "../../../../store/profiles/profiles.selectors";
import {
  ADD_SCHEDULE,
  GET_SCHEDULES_SUCCESS,
  CHANGE_SCHEDULE,
  REMOVE_SCHEDULE,
  CHANGE_ENABLED,
  CHANGE_CATEGORIES,
  CHANGE_SCHEDULES,
  SAVE_SCHEDULES_SUCCESS,
  TOGGLE_EDIT_POPUP
} from "./schedules.actions";
import {
  RESET_PROFILE,
  RESET_ALL_PROFILES
} from "../../../../store/profiles/profiles.types";
import { getIgnoredScheduleIds } from "./schedules.selectors";
import { UPDATE_PROFILE_IDS } from "../../../../store/profiles/createProfiles.actions";

/*
  interface Schedule {
    id: string,
    enabled: bool,
    profile: string,
    name: string,
    type: string, //enum: homework | internet-off
    categories: string[],
    day: string[],
    timeEnd: string // HH:mm || hh:mm:a
    timeStart: string // HH:mm || hh:mm:a
  }
*/

const initialState = {
  saved: {
    list: {},
    keys: []
  },
  changed: {
    list: {},
    keys: []
  },
  editPopup: {
    id: null
  }
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SCHEDULES_SUCCESS:
      const schedules = addItemsToList(
        _.reduce(
          payload,
          (acc, { id, schedules }) =>
            _.concat(acc, _.map(schedules, s => ({ ...s, profile: id }))),
          []
        ),
        {
          list: {},
          keys: []
        }
      );

      return {
        ...state,
        saved: schedules,
        changed: _.cloneDeep(schedules)
      };
    case ADD_SCHEDULE:
      return {
        ...state,
        changed: addItemsToList([payload], state.changed)
      };
    case CHANGE_SCHEDULE:
      return {
        ...state,
        changed: {
          ...state.changed,
          list: {
            ...state.changed.list,
            [payload.id]: {
              ...state.changed.list[payload.id],
              ...payload
            }
          }
        }
      };
    case CHANGE_ENABLED:
    case CHANGE_CATEGORIES:
      return {
        ...state,
        changed: {
          ...state.changed,
          list: _.mapValues(state.changed.list, schedule =>
            schedule.type === payload.type &&
            schedule.profile === payload.profile
              ? {
                  ...schedule,
                  ...payload
                }
              : schedule
          )
        }
      };
    case CHANGE_SCHEDULES:
      return {
        ...state,
        changed: addItemsToList(payload, state.changed)
      };
    case REMOVE_SCHEDULE:
      return {
        ...state,
        changed: removeItemFromList(payload, state.changed)
      };
    case SAVE_SCHEDULES_SUCCESS:
      const toRemove = _.union(
          getIgnoredScheduleIds(state),
          _.map(payload.removed, "id")
        ),
        changed = removeItemsFromList(toRemove, state.changed);

      return {
        ...state,
        saved: _.cloneDeep(changed),
        changed: changed
      };
    case RESET_PROFILE:
    case RESET_ALL_PROFILES:
      return {
        ...state,
        changed: _.cloneDeep(state.saved)
      };

    case TOGGLE_EDIT_POPUP:
      const { id } = payload;

      return {
        ...state,
        editPopup: {
          id: _.isEmpty(id) ? null : id
        }
      };
    case UPDATE_PROFILE_IDS:
      return {
        ...state,
        saved: {
          keys: state.saved.keys,
          list: _.mapValues(state.saved.list, schedule => ({
            ...schedule,
            profile: payload.map[schedule.profile] || schedule.profile
          }))
        },
        changed: {
          keys: state.changed.keys,
          list: _.mapValues(state.changed.list, schedule => ({
            ...schedule,
            profile: payload.map[schedule.profile] || schedule.profile
          }))
        }
      };
    default:
      return state;
  }
};
