import _ from "lodash";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { differenceInMinutes } from "date-fns";
import { scroller } from "react-scroll";

import { MouseTooltip } from "../../../../../components/tooltip/Tooltip";
import ScheduleRule from "../scheduleRule/ScheduleRule";

import "./ScheduleTimeGrid.scss";

class ScheduleTimeGrid extends Component {
  render() {
    const {
      days,
      timeFormat24h,
      schedules,
      activeRule,
      setActiveRule
    } = this.props;

    const columnClass = "time-grid__grid__column";
    const mod = timeFormat24h ? 24 : 12;

    const dayRules = _.transform(
      schedules,
      (acc, schedule) => {
        _.each(schedule.day, day => {
          const duration = diffInMinutes(schedule.timeEnd, schedule.timeStart);
          const nextDay = days[(_.indexOf(days, day) + 1) % 7];

          switch (true) {
            case duration > 0:
              acc[day].push({
                id: schedule.id,
                top: (diffInMinutes(schedule.timeStart, "00:00") * 2) / 5 + 3,
                height: (duration * 2) / 5
              });
              break;
            case duration === 0:
              const height = diffInMinutes("24:00", schedule.timeStart);
              acc[day].push({
                id: schedule.id,
                top: (diffInMinutes(schedule.timeStart, "00:00") * 2) / 5 + 3,
                height: (height * 2) / 5
              });
              if (height < 1440) {
                acc[nextDay].push({
                  id: schedule.id,
                  top: 3,
                  height: (diffInMinutes(schedule.timeEnd, "00:00") * 2) / 5
                });
              }
              break;
            case duration < 0:
              acc[day].push({
                id: schedule.id,
                top: (diffInMinutes(schedule.timeStart, "00:00") * 2) / 5 + 3,
                height: (diffInMinutes("24:00", schedule.timeStart) * 2) / 5
              });
              acc[nextDay].push({
                id: schedule.id,
                top: 3,
                height: (diffInMinutes(schedule.timeEnd, "00:00") * 2) / 5
              });
              break;
            default:
              break;
          }
        });
      },
      _.zipObject(
        days,
        Array(7)
          .fill()
          .map(() => [])
      )
    );

    //TODO: find a way to get list of short day names from cldr
    return (
      <div className="time-grid">
        <div className="time-grid__hour-axis">
          {_.times(9, idx => (
            <div key={idx}>{(idx * 3) % mod || mod}</div>
          ))}
        </div>
        <div className="time-grid__day-axis">
          {_.map(days, day => (
            <div key={day}>
              <FormattedMessage id={`${day}_short`} />
            </div>
          ))}
        </div>
        <div className="time-grid__grid">
          {_.map(days, day => (
            <div
              key={day}
              className={classnames(columnClass, {
                [`${columnClass}--highlighted`]: ["SA", "SU"].includes(day)
              })}
            >
              {_.map(dayRules[day], (rule, key) => {
                const { intl, timeStart, timeEnd, day } = _.find(
                  this.props.schedules,
                  { id: rule.id }
                );

                return (
                  <MouseTooltip
                    key={key}
                    placement="right"
                    content={
                      <ScheduleRule
                        intl={intl}
                        timeFormat24h={timeFormat24h}
                        timeStart={timeStart}
                        timeEnd={timeEnd}
                        selectedDays={day}
                        useShortNotations={true}
                      />
                    }
                    style={{ top: rule.top, height: rule.height }}
                  >
                    <div
                      className={classnames("time-grid__box", {
                        "time-grid__box--active": activeRule === rule.id
                      })}
                      onMouseEnter={() => setActiveRule(rule.id)}
                      onMouseLeave={() => setActiveRule(null)}
                      onClick={() => {
                        // toggleEditPopup(rule.id);
                        this.scrollTo();
                      }}
                    />
                  </MouseTooltip>
                );
              })}
            </div>
          ))}
        </div>
        <div className="time-grid__hour-line-layer">
          {_.times(4, idx => (
            <div key={`hour-line-${idx}`} className="time-grid__hour-line" />
          ))}
        </div>
      </div>
    );
  }

  scrollTo = () => {
    scroller.scrollTo("schedule__rule-list__item--active", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "schedule__rule-list"
    });
  };
}

const escape24hr = time => (time === "24:00" ? "00:00" : time);

const FIXED_START_DATE = "01/01/2021";
const FIXED_END_DATE = "01/02/2021";
function diffInMinutes(end, start) {
  const endDate = end === "24:00" ? FIXED_END_DATE : FIXED_START_DATE;

  return differenceInMinutes(
    new Date(`${endDate} ${escape24hr(end)}`),
    new Date(`${FIXED_START_DATE} ${escape24hr(start)}`)
  );
}

export default ScheduleTimeGrid;
