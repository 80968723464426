import _ from "lodash";
import {
  LOAD_SUCCESS,
  CHANGE_PERIOD,
  CHANGE_PROFILE,
  APPLY_ZOOM,
  CANCEL_ZOOM
} from "./reports.actions";
import { ReportsSection, ReportsState } from "./reports.types";
import { ReportsPeriod } from "../../helpers/reports.helper.types";

export const initialState = {
  period: ReportsPeriod.Day,
  profile: "all_profiles",
  widgets: {},
  barchart: [],
  table: {},
  zoom: {
    visible: false
  }
};

export const reducer = (
  state: ReportsState = initialState,
  action
): ReportsState => {
  switch (action.type) {
    case LOAD_SUCCESS: {
      const data = action.payload.data;
      const type = action.payload.type;

      if (_.isNil(type)) {
        return {
          ...state,
          widgets: { ...state.widgets, ...data },
          barchart: { ...state.barchart, ...data }
        };
      }

      return type === ReportsSection.Barchart
        ? {
            ...state,
            [type]: [...data]
          }
        : {
            ...state,
            [type]: { ...state[type], ...data }
          };
    }
    case CHANGE_PERIOD:
    case CHANGE_PROFILE: {
      return {
        ...state,
        ...action.payload,
        zoom: initialState.zoom
      };
    }
    case APPLY_ZOOM: {
      return { ...state, ...action.payload };
    }
    case CANCEL_ZOOM: {
      return {
        ...state,
        zoom: initialState.zoom
      };
    }
    default:
      return state;
  }
};
