import { BaseApiService } from "../baseApiService";

export class SafetyService extends BaseApiService {
  constructor(config) {
    super(config);
    this.baseUrl = `${config.backendUrl}/ssm/ss`;
  }

  get(subscriberId) {
    return this._http
      .get(`${this.baseUrl}/${subscriberId}`)
      .then(({ data }) => data);
  }

  saveProfile(subscriberId, profileName, enabled) {
    return this._http.patch(
      `${this.baseUrl}/${subscriberId}/profile/${profileName}`,
      { "subscriber-safety": enabled }
    );
  }

  removeProfile(subscriberId, profileName) {
    return this._http.delete(
      `${this.baseUrl}/${subscriberId}/profile/${profileName}`
    );
  }

  saveProfiles(subscriberId, profiles = []) {
    return profiles.reduce(
      (chain, profile) =>
        chain.then(() =>
          this.saveProfile(subscriberId, profile.name, profile.enabled)
        ),
      Promise.resolve()
    );
  }
}
