import React, { Fragment } from "react";

import { Mobile, NotMobile } from "../../../../hocs/Responsive";
import { DeviceActionsToggle } from "../../../../components/devices/DeviceActionsDialog";
import { DeviceDropdownMenu } from "../../../../components/devices/DeviceDropdownMenu";
import {
  useEditRoamingDeviceMenuItem,
  useRevokeRoamingDeviceAccessMenuItem,
  useAssignRoamingDeviceMenuItem
} from "../../../../components/devices/hooks";

export const ProfileRoamingDevicesDropdownActions = ({
  identifier,
  fullName,
  name
}) => {
  const menuItems = [
    useEditRoamingDeviceMenuItem(identifier),
    useAssignRoamingDeviceMenuItem(identifier),
    useRevokeRoamingDeviceAccessMenuItem(identifier)
  ];

  return (
    <Fragment>
      <Mobile>
        <DeviceActionsToggle
          deviceName={fullName}
          menuItems={menuItems}
          ariaDeviceName={name}
        />
      </Mobile>
      <NotMobile>
        <DeviceDropdownMenu menuItems={menuItems} deviceName={name} />
      </NotMobile>
    </Fragment>
  );
};
