import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./Checkbox.scss";

export const Checkbox = ({
  className,
  isDisabled,
  isChecked,
  onChange,
  name,
  children,
  description,
  ...attrs
}) => (
  <div className={classnames("checkbox", className)}>
    <label className="checkbox__label">
      <div className="checkbox__title">
        <input
          {...attrs}
          type="checkbox"
          className="checkbox__input"
          name={name}
          checked={isChecked}
          disabled={isDisabled}
          onChange={onChange}
        />
        <span className="checkbox__check" />
        <div className="checkbox__text">{children}</div>
      </div>
      {description && (
        <div className="checkbox__description">{description}</div>
      )}
    </label>
  </div>
);

Checkbox.propTypes = {
  /** Shows whether the checkbox is checked */
  isChecked: PropTypes.bool,
  /** Shows whether the checkbox is disabled */
  isDisabled: PropTypes.bool,
  /** The name of the control */
  name: PropTypes.string,
  /** The label content */
  children: PropTypes.node,
  /** Change event handler */
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

Checkbox.defaultProps = {
  isChecked: false,
  isDisabled: false,
  name: ""
};
