export enum DrilldownDataKeys {
  webfilter = "secure/content/blocks",
  malware = "secure/threat/blocks",
  botnet = "secure/threat/stops"
}

export const drilldownDataFields = {
  webfilter: [
    "protocol",
    "url",
    "categories",
    "reason",
    "group-name",
    "device-id",
    "device-name",
    "timestamp",
    "rendered"
  ],
  malware: [
    "protocol",
    "url",
    "reason",
    "group-name",
    "device-id",
    "device-name",
    "timestamp",
    "rendered"
  ],
  botnet: [
    "name",
    "threat-id",
    "device-id",
    "device-name",
    "timestamp",
    "count"
  ]
};

export enum ReportsPeriod {
  Day = "1",
  Week = "7",
  Month = "30"
}

export const Granularity = {
  [ReportsPeriod.Day]: 900,
  [ReportsPeriod.Week]: 7200,
  [ReportsPeriod.Month]: 28800
};

export interface ReportSlice {
  time: number;
  value: number;
}

export type DomainStats = [string, number];
export type TopSitesByTimestamp = [number, DomainStats[]];

export interface ReportTopDataSlice {
  time: number;
  value: DomainStats[];
}

export interface TopDomains {
  [domainName: string]: number; // it's a data from DomainStats in different format
}

export interface TopData {
  topVisited: TopSitesByTimestamp[];
  topBlocked: TopSitesByTimestamp[];
}

export enum DataKeys {
  webfilter = "secure/content/blocks-by-group",
  webfilter_top = "secure/top-business-blocks",
  malware = "secure/threat/blocks-by-group",
  botnet = "ss/stops-by-group",
  activity = "dns/requests-by-group",
  activity_top = "dns/top-requests-by-group"
}

export enum ReportTypes {
  Activity = "activity",
  ActivityTop = "activity_top",
  Malware = "malware",
  Botnet = "botnet",
  Webfilter = "webfilter",
  WebfilterTop = "webfilter_top"
}

export const ReportKeys = {
  [DataKeys.activity]: ReportTypes.Activity,
  [DataKeys.activity_top]: ReportTypes.ActivityTop,
  [DataKeys.malware]: ReportTypes.Malware,
  [DataKeys.botnet]: ReportTypes.Botnet,
  [DataKeys.webfilter]: ReportTypes.Webfilter,
  [DataKeys.webfilter_top]: ReportTypes.WebfilterTop
};

export const topKeys = [DataKeys.activity_top, DataKeys.webfilter_top];

export const widgetKeys = [
  DataKeys.webfilter,
  DataKeys.malware,
  DataKeys.botnet
];

export const barchartKeys = [
  DataKeys.activity,
  DataKeys.webfilter,
  DataKeys.malware
];

export const usageStatisticKeys = [...barchartKeys, ...topKeys];

export interface AllReports {
  [DataKeys.activity]?: ReportSlice[];
  [DataKeys.webfilter]?: ReportSlice[];
  [DataKeys.malware]?: ReportSlice[];
  [DataKeys.botnet]?: ReportSlice[];
  [DataKeys.activity_top]?: ReportTopDataSlice[];
  [DataKeys.webfilter_top]?: ReportTopDataSlice[];
}

type TopKeys = DataKeys.activity_top | DataKeys.webfilter_top;
type AllReportsWithoutTopKeys = Exclude<keyof AllReports, TopKeys>;
export type AllReportsWithoutTopData = Pick<
  AllReports,
  AllReportsWithoutTopKeys
>;

interface ActivityReport {
  time: number;
  activity: number;
}

interface WebFilterReport {
  time: number;
  webfilter: number;
}

interface MalwareReport {
  time: number;
  malware: number;
}

interface BotnetReport {
  time: number;
  botnet: number;
}

export type ReportType =
  | ActivityReport
  | WebFilterReport
  | MalwareReport
  | BotnetReport;

interface ActivityAndWebFilter {
  time: number;
  activity: number;
  webfilter: number;
}

export interface BarChartData {
  activityAndWebfilter: ActivityAndWebFilter[];
  malware: MalwareReport[];
}

export interface TimeFormat {
  timeZone: string;
  hour12?: boolean;
  day?: "numeric";
  weekday?: "short";
  month?: "short";
  hour?: "2-digit";
  minute?: "2-digit";
}
