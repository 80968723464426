import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import { Protection } from "@sportal/api";
import {
  getProfileSchedules,
  getScheduleTypeActive,
  SCHEDULE_TYPES
} from "./schedules.selectors";
import { Schedule } from "./Schedule";
import { Icon } from "../../../../components/icon/Icon";
import { getProfileById } from "../../../../store/profiles/profiles.selectors";
import {
  getCategories,
  getProtections
} from "../../../../store/root.selectors";
import { useSelectedProfileId } from "../hooks";
import { getCustomProtections } from "../webFilters/webFilters.selectors";
import { useIntlTranslate } from "../../../../hooks";

const ScheduleContainerComponent = ({
  internetOff,
  homework,
  profileId,
  protection,
  categories = [],
  internetOffActive,
  homeworkActive,
  blockedByWebFilter = []
}) => {
  const translate = useIntlTranslate();

  return (
    <Fragment>
      <Schedule
        className={"homework-time"}
        data-id={SCHEDULE_TYPES.HOMEWORK}
        type={SCHEDULE_TYPES.HOMEWORK}
        profile={profileId}
        protection={protection}
        categories={categories}
        isActive={homeworkActive}
        blockedByWebFilter={blockedByWebFilter}
        name={translate("homework_time_schedule")}
        description={translate("homework_time_description")}
        schedules={homework}
        disabled={categories.length === blockedByWebFilter.length}
      />
      <h3 className="internet-off__header">
        <Icon icon="far fa-calendar-alt" />
        <span>{translate("internet-off")}</span>
      </h3>
      <Schedule
        className={"internet-off"}
        data-id={SCHEDULE_TYPES.INTERNET_OFF}
        type={SCHEDULE_TYPES.INTERNET_OFF}
        profile={profileId}
        name={translate("internet_off_schedule")}
        isActive={internetOffActive}
        description={translate("internet_off_description")}
        schedules={internetOff}
      />
    </Fragment>
  );
};

export const ScheduleContainer = ({ className }) => {
  const profileId = useSelectedProfileId();
  const schedules = useSelector(state => state.schedules);
  const categories = useSelector(getCategories);
  const protections = useSelector(getProtections);
  const customProtections = useSelector(getCustomProtections);
  const profile = useSelector(getProfileById(profileId));
  const protection = profile && profile.protection;

  const blockedByWebFilter =
    protection !== Protection.Custom
      ? protections.list[protection] && protections.list[protection].categories
      : customProtections[profileId];
  const internetOff = getProfileSchedules(
    schedules,
    profileId,
    SCHEDULE_TYPES.INTERNET_OFF
  );
  const homework = getProfileSchedules(
    schedules,
    profileId,
    SCHEDULE_TYPES.HOMEWORK
  );
  const internetOffActive = getScheduleTypeActive(
    schedules,
    profileId,
    SCHEDULE_TYPES.INTERNET_OFF
  );
  const homeworkActive = getScheduleTypeActive(
    schedules,
    profileId,
    SCHEDULE_TYPES.HOMEWORK
  );

  return (
    <ScheduleContainerComponent
      internetOff={internetOff}
      homework={homework}
      profileId={profileId}
      protection={protection}
      categories={categories}
      internetOffActive={internetOffActive}
      homeworkActive={homeworkActive}
      blockedByWebFilter={blockedByWebFilter}
      className={className}
    />
  );
};
