import _ from "lodash";
import {
  SERVICES,
  SERVICE_PROFILES,
  PROFILES
} from "../../common/utils/permissions";
import {
  AccountActionTypes,
  GET_ACCOUNT_SUCCESS,
  GET_SPSON_SUCCESS,
  UPDATE_WEB_FILTER_SUCCESS
} from "./account.types";
import { AccountData } from "@sportal/api";

export type AccountState = Omit<AccountData, "timezone"> & { isSpsponEnabled: boolean };

const initialState = {
  isSpsponEnabled: false,
} as AccountState;

export default (
  state: AccountState = initialState,
  action: AccountActionTypes
): AccountState => {
  switch (action.type) {
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        ..._.omit(action.payload, "timezone")
      };
    case UPDATE_WEB_FILTER_SUCCESS:
      return {
        ...state,
        [SERVICES.SB]: {
          ...state[SERVICES.SB],
          enabled: action.payload
        }
      };
    case GET_SPSON_SUCCESS: {
      return {
        ...state,
        isSpsponEnabled: true
      }
    }
    default:
      return state;
  }
};

export const subscriberActivated = account =>
  account[SERVICES.SB] && account[SERVICES.SB].activated;

//TODO: actually service profiles do not always correspond to the types returns by /account (case of perdevice and per-device in PI)
//need to add type/service profile associations to the directory and rewrite the method
export const isSbMultiple = account =>
  account[SERVICES.SB] &&
  account[SERVICES.SB].type ===
    SERVICE_PROFILES[SERVICES.SB][PROFILES.MULTIPLE];
