import { APPS, isUserAllowed } from "../../common/utils/permissions/index";
import {
  LOAD_INFO_SUCCESS,
  LOAD_INFO_FAILURE,
  SET_AUTH_METHOD,
  WRONG_USER_MODE,
  InfoActionTypes
} from "./info.types";
import {
  AuthMethod,
  HttpServiceError,
  HttpServiceResponse,
  InfoServerData
} from "@sportal/api";
import { ThunkApi } from "../../api/api";

export const loadInfo = () => (dispatch, getState, { api }: ThunkApi) => {
  let authMethod;

  return api.auth
    .getInfo()
    .then(({ request, data }: HttpServiceResponse<InfoServerData>) => {
      authMethod = request.getResponseHeader("Authentication-Type");

      if (!sbAllowed(data.mode)) {
        dispatch(wrongUserMode());
        return Promise.reject({ request });
      }

      dispatch(loadInfoSuccess(data));

      return Promise.resolve();
    })
    .catch(error => {
      const { request } = error;
      authMethod = request.getResponseHeader("Authentication-Type");
      dispatch(loadInfoFailure(error));

      return Promise.reject();
    })
    .finally(() => {
      dispatch(setAuthMethod(authMethod));
    });
};

const sbAllowed = mode => isUserAllowed({ app: APPS.SB, access: mode });

export const wrongUserMode = (): InfoActionTypes => ({
  type: WRONG_USER_MODE
});

export const loadInfoSuccess = (info: InfoServerData): InfoActionTypes => ({
  type: LOAD_INFO_SUCCESS,
  payload: info
});
export const loadInfoFailure = (error: HttpServiceError): InfoActionTypes => ({
  type: LOAD_INFO_FAILURE,
  payload: error
});

export const setAuthMethod = (method: AuthMethod): InfoActionTypes => ({
  type: SET_AUTH_METHOD,
  payload: method
});
