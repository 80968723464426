import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Icon } from "../../../../components/icon/Icon";
import { DeviceWithType } from "../../../../components/devices/DeviceWithType";
import { Manufacturer } from "../../../../components/manufacturer/Manufacturer";
import { Table } from "../../../../components/table/Table";
import { getOnlineDevicesForTable } from "../../../../store/devices/devices.selectors";
import { Button } from "../../../../components/button/Button";
import { fetchDiscovered } from "../../../../store/devices/devices.actions";
import { TableExpandableCard } from "../../../../components/table/components/TableExpandableCard";
import { Mobile, NotMobile } from "../../../../hocs/Responsive";
import { OnlineDevicesDropdownActions } from "./OnlineDevicesDropdownActions";
import { singleDeviceIdSort } from "../../../../components/table/helpers/sorting.helper";

const COLUMNS = [
  {
    Header: <FormattedMessage id={"device_id"} />,
    accessor: "address",
    sortable: true,
    Cell: ({
      row: {
        original: { address, type }
      }
    }) => <DeviceWithType type={type} identifier={address} />,
    sortType: singleDeviceIdSort
  },
  {
    Header: <FormattedMessage id={"manufacturer"} />,
    accessor: "manufacturer",
    sortable: true,
    Cell: ({
      row: {
        original: { manufacturer }
      }
    }) => <Manufacturer manufacturer={manufacturer} />
  },
  {
    id: "actions",
    sortable: false,
    Header: <FormattedMessage id={"actions"} />,
    headerClassName: "table__cell--small-cell",
    cellClassName: "table__cell--center table__cell--overflow",
    Cell: ({
      row: {
        original: { address, type, manufacturer }
      }
    }) => (
      <OnlineDevicesDropdownActions
        address={address}
        type={type}
        manufacturer={manufacturer}
      />
    )
  }
];

const COLUMNS_MOBILE = [
  {
    accessor: "address",
    cellClassName: "table__cell--overflow",
    SortingLabel: <FormattedMessage id={"device_id"} />,
    Cell: ({
      row: {
        original: { type, manufacturer, address }
      }
    }) => (
      <TableCard type={type} manufacturer={manufacturer} address={address} />
    ),
    sortType: singleDeviceIdSort
  },
  {
    accessor: "manufacturer",
    SortingLabel: <FormattedMessage id={"manufacturer"} />
  }
];

const SEARCH_KEYS = ["address", "manufacturer"];

export const OnlineDevicesTable = () => {
  const devices = useSelector(getOnlineDevicesForTable, _.isEqual);

  return (
    <Fragment>
      <OnlineDevicesInfo />
      <Mobile>
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          showMobileSort
          visibleHeader={false}
          data={devices}
          columns={COLUMNS_MOBILE}
          hiddenColumns={["manufacturer"]}
          searchAriaLabelMessage="aria_search_for_device"
        />
      </Mobile>
      <NotMobile>
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          data={devices}
          columns={COLUMNS}
          searchAriaLabelMessage="aria_search_for_device"
        />
      </NotMobile>
    </Fragment>
  );
};

export const OnlineDevicesInfo = () => {
  const dispatch = useDispatch();

  return (
    <div className={"devices__refresh"}>
      <Button
        isOutline
        size={"large"}
        onClick={() => dispatch(fetchDiscovered())}
      >
        <Icon icon="far fa-sync-alt color-secondary" />
        <span>
          <FormattedMessage id={"refresh"} />
        </span>
      </Button>
    </div>
  );
};

const TableCard = ({ type, address, manufacturer }) => {
  return (
    <TableExpandableCard
      showExpand={false}
      content={
        <Fragment>
          <DeviceWithType type={type} identifier={address} />
          {manufacturer && (
            <div className={"color-secondary"}>{manufacturer}</div>
          )}
        </Fragment>
      }
      aside={
        <OnlineDevicesDropdownActions
          address={address}
          type={type}
          manufacturer={manufacturer}
        />
      }
    />
  );
};
