export enum ResourceName {
  Safety = "business-sec-template",
  WebContent = "business-web-template"
}

export enum ResourceFieldName {
  Color = "color",
  Tagline = "tagline",
  ShowMessage = "showMessage",
  Message = "message",
  ShowAdmin = "showAdmin",
  AdminEmail = "adminEmail",
  AdminPhone = "adminPhone",
  ShowCode = "showCode",
  ShowName = "showName"
}

export interface ResourcesServerData {
  total: number;
  limit: number;
  content: ServerResourceDefinition[];
}

export interface ServerResourceDefinition {
  name: ResourceName;
  type: "text/html";
  fields: ResourceFieldDefinition[];
}

export interface ResourceDefinition {
  name: ResourceName;
  fields: ResourceFieldDefinition[];
}

export interface ResourceFieldDefinition {
  name: ResourceFieldName;
  defaultValue?: any;
  validations?: {
    maxLength: number;
  };
}

export type ResourceFieldValues = {
  [key in ResourceFieldName]: string;
};

export type SubscriberResource = {
  name: ResourceName;
  type: "text/html";
  fields: ResourceFieldValues;
};
