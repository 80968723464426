import React from "react";

import { useLoadableSlice } from "../../store/shared";
import {
  fetchLogicalDevices,
  fetchDiscovered,
  fetchLines,
  fetchRequestsWithName
} from "../../store/devices/devices.actions";
import {
  getLogicalDevices,
  getDiscoveredDevices,
  getLines,
  getRequestsWithName
} from "../../store/devices/devices.selectors";
import { getDisplayName } from "../../utils/getDisplayName";

export function withSettingDevicesGuard(Component) {
  function SettingDevicesGuard() {
    const logicalSlice = useLoadableSlice(
      fetchLogicalDevices(),
      getLogicalDevices
    );
    const linesSlice = useLoadableSlice(fetchLines(), getLines);
    const discoveredSlice = useLoadableSlice(
      fetchDiscovered(true),
      getDiscoveredDevices
    );
    const withNameSlice = useLoadableSlice(
      fetchRequestsWithName(true),
      getRequestsWithName
    );

    if (
      [logicalSlice, linesSlice, discoveredSlice, withNameSlice].includes(null)
    ) {
      return null;
    }

    return <Component />;
  }

  SettingDevicesGuard.displayName = `withSettingDevicesGuard(${getDisplayName(
    Component
  )})`;
  return SettingDevicesGuard;
}
