import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import "./TableExpandableCard.scss";

export const TableExpandableCard = ({
  showExpand,
  isExpanded,
  content,
  aside
}) => {
  return (
    <div className={"table-card"}>
      {showExpand && (
        <div
          className={classnames("table-card__expand-arrow", {
            "table-card__expand-arrow__expanded": isExpanded
          })}
        />
      )}
      <div className="table-card__content">{content}</div>
      {aside && <div className="table-card__aside">{aside}</div>}
    </div>
  );
};

TableExpandableCard.defaultProps = {
  showExpand: true
};

TableExpandableCard.propTypes = {
  showExpand: PropTypes.bool,
  isExpanded: PropTypes.bool,
  content: PropTypes.node.isRequired,
  aside: PropTypes.node
};
