import _ from "lodash";
import { loadInfo } from "../info/info.actions";
import { getSettings } from "../settings/settings.actions";
import { getAccount } from "../account/account.actions";
import { loadAttributes } from "../attributes/attributes.actions";
import { getProfiles } from "../profiles/profiles.actions";
import { subscriberActivated } from "../account/account.reducer";
import { loadTranslation } from "../../i18n/translation.actions";
import { SERVICES } from "../../common/utils/permissions";
import Notificator from "../../components/notification/notification.actions";

export const getInitialData = () => (dispatch, getState) => {
  return dispatch(loadInfo())
    .then(() => dispatch(getSettings()))
    .then(() => dispatch(getAccount()))
    .then(() => dispatch(loadAttributes()))
    .then(() =>
      subscriberActivated(getState().account)
        ? dispatch(getProfiles())
        : Promise.resolve()
    )
    .then(() => {
      const { profiles, account } = getState();

      if (_.isEmpty(profiles.saved.keys) || account[SERVICES.SB].enabled)
        return;
      dispatch(Notificator.webFilterOff());
    })
    .then(() =>
      dispatch(loadTranslation()).finally(() => dispatch(initialLoadSuccess()))
    )
    .catch(error => {
      return dispatch(loadTranslation()).finally(() => {
        dispatch(initialLoadFailure(error));
        return Promise.reject(error);
      });
    });
};

export const INITIAL_LOAD_SUCCESS = "[AUTH] INITIAL_LOAD_SUCCESS";
export const initialLoadSuccess = info => ({
  type: INITIAL_LOAD_SUCCESS,
  payload: info
});

export const INITIAL_LOAD_FAILURE = "[AUTH] INITIAL_LOAD_FAILURE";
export const initialLoadFailure = info => ({
  type: INITIAL_LOAD_FAILURE,
  payload: info
});
