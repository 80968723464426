import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link, useParams } from "react-router-dom";
import _ from "lodash";
import classnames from "classnames";

import { useMount } from "@sportal/cdk/hooks";
import { isSBReportingLicensed } from "../../store/settings/settings.selectors";
import { withLayout } from "../../components/layout/Layout";
import { withPageName } from "../../components/layout/horizontalMenu/PageName";
import { Icon } from "../../components/icon/Icon";
import { DrilldownTable, DrilldownTitle, DrilldownChart } from "./components";
import { DrilldownRoutePage } from "./drilldown.types";
import { setupDrilldown } from "./drilldown.actions";

import "./DrilldownPage.scss";

interface RouteParams {
  page: DrilldownRoutePage;
}

export const DrilldownPage = () => {
  const { page } = useParams<RouteParams>();

  const dispatch = useDispatch();

  useMount(() => {
    dispatch(setupDrilldown(page));
  });

  const isLicensed: boolean = useSelector(isSBReportingLicensed);

  if (!isLicensed) {
    return <Redirect to="/home" />;
  }

  return (
    <div className={classnames("drilldowns", page)}>
      <DrilldownTitle page={page} />
      <DrilldownChart page={page} />
      <DrilldownTable className="drilldowns__table" page={page} />
    </div>
  );
};

const pageNameContent = (
  <div className="drilldowns__header">
    <Link to="/home">
      <Icon icon="far fa-angle-left" />
      <span>
        <FormattedMessage id="back_to_dashboard" />
      </span>
    </Link>
    <h1>
      <FormattedMessage id="blocked_reports" />
    </h1>
  </div>
);

export default _.flowRight(
  withLayout(),
  withPageName(pageNameContent)
)(DrilldownPage);
