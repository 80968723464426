import React from "react";
import { FormattedMessage } from "react-intl";
import { Mobile, NotMobile } from "../../../hocs/Responsive";

import "./ContactInfoFooter.scss";
import { ConfigLink } from "../../configLink/ConfigLink";

const renderIcon = src => (
  <NotMobile>
    <img
      className="contact-info-footer__icon"
      onError={e => (e.target.style.display = "none")}
      src={src}
      alt=""
    />
  </NotMobile>
);
const renderSupportLink = (link, icon) => (
  <div className="contact-info-footer__item">
    {icon && renderIcon(icon)}
    <div>
      <NotMobile>
        <div>
          <FormattedMessage id={"more_support"} />
        </div>
      </NotMobile>
      <ConfigLink className="contact-info-footer__link" to={link}>
        <FormattedMessage id={"create_online_ticket"} />
      </ConfigLink>
    </div>
  </div>
);

const renderPhone = (text, number, icon) => (
  <div className="contact-info-footer__item">
    {icon && renderIcon(icon)}
    <div>
      <NotMobile>
        <div>
          <FormattedMessage id={text} />
        </div>
      </NotMobile>
      <a className="contact-info-footer__link" href={`tel:${number}`}>
        <NotMobile>{number || null}</NotMobile>
        <Mobile>
          <FormattedMessage id={text} />
        </Mobile>
      </a>
    </div>
  </div>
);

export const ContactInfoFooter = ({
  footerIcons,
  footerPhoneNumbers,
  footerSupportLink
}) => (
  <div className="contact-info-footer">
    <div className={"contact-info-footer__wrapper"}>
      {footerPhoneNumbers[0] &&
        renderPhone(
          "contact_customer_service",
          footerPhoneNumbers[0],
          footerIcons[0]
        )}
      {footerSupportLink.url &&
        renderSupportLink(footerSupportLink, footerIcons[1])}
      {footerPhoneNumbers[1] &&
        renderPhone("contact_sales", footerPhoneNumbers[1], footerIcons[2])}
    </div>
  </div>
);
