import _ from "lodash";

import {
  LOAD_SHARED_PROTECTIONS_SUCCESS,
  ProtectionsActionTypes
} from "./protections.types";
import { SBSharedProtection } from "@sportal/api";
import { ListSlice } from "../redux.types";

export type ProtectionsState = ListSlice<SBSharedProtection>;

export const initialState = {
  list: {},
  keys: []
};

export const reducer = (
  state: ProtectionsState = initialState,
  action: ProtectionsActionTypes
): ProtectionsState => {
  switch (action.type) {
    case LOAD_SHARED_PROTECTIONS_SUCCESS:
      const list = _.keyBy(action.payload, "name");
      const keys = _.keys(list);
      return {
        list,
        keys
      };
    default:
      return state;
  }
};

export const findProtectionByBlocked = ({ protections }, blocked) =>
  _.find(
    protections.list,
    ({ categories }) => _.xor(categories, blocked).length === 0
  );
