import React, { Fragment } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import classnames from "classnames";
import _ from "lodash";
import { useSelector } from "react-redux";

import { StickySidebar } from "../sidebar/StickySidebar";
import { Desktop, TabletMobile } from "../../hocs/Responsive";
import { ProfilePicker } from "../profilePicker/ProfilePicker";
import { getDefaultProfile } from "../../store/profiles/profiles.selectors";
import { GLOBAL_URL_FILTER_ID } from "../../pages/urlFilters/urlFilters.constants";
import { useValidIdForSettingsPaths } from "../../pages/settings/useValidIdForSettingsPaths";

import "./SettingsSidebarNavigation.scss";

export const SettingsSidebarNavigation = ({
  className,
  menuClassName,
  sidebarMenuHeader,
  tabs,
  activeTabClass,
  ...rest
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const onTabClick = profileId =>
    history.push(`/settings/${pathname.split("/")[2]}/${profileId}`);
  const { profileId: idFromUrl } = useParams();
  const defaultProfileId = useSelector(state => getDefaultProfile(state).id);
  const validId = useValidIdForSettingsPaths(
    idFromUrl,
    defaultProfileId,
    GLOBAL_URL_FILTER_ID
  );
  const currentTab = tabs.find(({ id }) => validId === id) || _.head(tabs);

  return (
    <Fragment>
      <TabletMobile>
        <ProfilePicker
          {...rest}
          activeTab={validId}
          activeTabClass={activeTabClass}
          tabs={tabs}
          onTabClick={onTabClick}
        />
      </TabletMobile>
      <Desktop>
        <SettingsLayout
          profileId={currentTab.id}
          sidebar={
            <SettingsSidebar
              menuClassName={menuClassName}
              sidebarMenuHeader={sidebarMenuHeader}
              tabs={tabs}
              activeTab={validId}
              activeTabClass={activeTabClass}
            />
          }
        >
          {currentTab.content}
        </SettingsLayout>
      </Desktop>
    </Fragment>
  );
};

const SettingsLayout = ({ children, profileId, sidebar }) => (
  <div className="row settings__row">
    {sidebar}
    <div key={profileId} id={profileId} data-id={profileId} className="col-9">
      {children}
    </div>
  </div>
);

const SettingsSidebar = ({ sidebarMenuHeader, tabs, activeTab }) => (
  <StickySidebar
    sidebarMenuHeader={sidebarMenuHeader}
    activeTab={activeTab}
    tabs={tabs}
  >
    {_.map(tabs, ({ id, header }) => (
      <div
        key={id}
        data-id={id}
        className={classnames("sidebar__tab-header", {
          "sidebar__tab-header--active": activeTab === id
        })}
      >
        {header}
      </div>
    ))}
  </StickySidebar>
);
