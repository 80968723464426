import React, { Component } from "react";
import classnames from "classnames";

import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import { Tooltip } from "../../../../../components/tooltip/Tooltip";
import { Icon } from "../../../../../components/icon/Icon";

import "./CategoryItem.scss";

export class CategoryItem extends Component {
  render() {
    const { icon, category, changeAction } = this.props;

    return (
      <UnstyledButton
        className="categories-item"
        onClick={() => changeAction(category.name)}
      >
        <div className="categories-item__content">
          <Icon icon={classnames("category-icon far", icon)} />
          <div>
            {category.displayName}
            {category.description
              ? this.renderDescription(category.name, category.description)
              : null}
          </div>
        </div>
        {category.warning ? (
          <div className="categories-item__warning">{category.warning}</div>
        ) : null}
      </UnstyledButton>
    );
  }

  renderDescription = (id, description) => (
    <Tooltip content={description}>
      <Icon icon="category-info far fa-info-circle" />
    </Tooltip>
  );
}
