import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "../../../../components/icon/Icon";

export const ColorPickerPreview = ({ color }) => (
  <div className={"color-preview"}>
    <div className={"color-preview__circle"}>
      <Icon icon="fas fa-lock" style={{ color }} />
    </div>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a style={{ color }}>
      <FormattedMessage id={"link_example"} />
    </a>
  </div>
);
