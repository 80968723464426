import _ from "lodash";

import { ReportsProvider } from "./reports.provider";
import { failure, success, Result } from "../shared";
import { Durations, ReportData } from "./reports.types";

export enum ReportsServiceErrorCodes {
  GENERIC
}

export class ReportsService {
  constructor(private reportsProvider: ReportsProvider) {}

  public async get(
    endpoint: string,
    subscriberId: string,
    duration: Durations,
    profileName?: string
  ): Promise<Result<ReportData>> {
    try {
      const { data } = await this.reportsProvider.get(
        endpoint,
        subscriberId,
        duration,
        profileName
      );
      const ordered = _.orderBy(data, "time");
      return success(ordered);
    } catch (e) {
      return failure(ReportsServiceErrorCodes.GENERIC);
    }
  }
}
