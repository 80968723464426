import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import "./ProtectionEditorDialog.scss";

import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog
} from "../../../../../components/modal";
import { Button } from "../../../../../components/button/Button";
import { CategoriesList } from "../protectionEditor/CategoriesList";
import { CheckboxButton } from "../checkboxButton/CheckboxButton";
import { Icon } from "../../../../../components/icon/Icon";

const BLOCKED_CATEGORY = "blocked";
const ALLOWED_CATEGORY = "allowed";

class ProtectionEditorDialog extends Component {
  constructor(props) {
    super(props);
    const { categories } = props;

    const blocked = setIsBlocked(categories.blocked, true);
    const allowed = setIsBlocked(categories.allowed, false);

    this.state = {
      categories: [...blocked, ...allowed],
      filter: "" //allowed/blocked
    };
  }

  render() {
    const { modal, blockedByWebFilter, protection } = this.props;

    return (
      <ModalDialog
        className="protection-editor-modal"
        submitHandler={this.handleSubmit}
        rejectHandler={() => modal.reject()}
      >
        <CommonModalHeader>
          <FormattedMessage id="protection_level_categories" />
        </CommonModalHeader>
        <ModalDialog.Body>
          <div className="categories-actions">
            <div className={"categories-actions--title"}>
              <span>
                <FormattedMessage id="categories" />
              </span>
              {this.renderFilterActions()}
            </div>
            {this.renderListActions()}
          </div>
          <div className={"categories-list-wrapper"}>
            <CategoriesList
              className="categories-list"
              list={this.getFilteredList()}
              blockedByWebFilter={blockedByWebFilter}
              protection={protection}
            />
          </div>
        </ModalDialog.Body>
        <CommonModalFooter submitMessage={<FormattedMessage id="ok" />} />
      </ModalDialog>
    );
  }

  renderFilterActions() {
    const { categories, filter } = this.state;

    return (
      <div className={"filter-actions"}>
        <CheckboxButton
          isOutline={filter !== ALLOWED_CATEGORY}
          onClick={() => this.filterChange(ALLOWED_CATEGORY)}
        >
          <span className="categories-count">
            <Icon icon="far fa-check" />
            &nbsp;
            {getByFilter(categories, ALLOWED_CATEGORY).length}
          </span>
        </CheckboxButton>
        <CheckboxButton
          isOutline={filter !== BLOCKED_CATEGORY}
          onClick={() => this.filterChange(BLOCKED_CATEGORY)}
        >
          <span className="categories-count">
            <Icon icon="far fa-ban" />
            &nbsp;
            {getByFilter(categories, BLOCKED_CATEGORY).length}
          </span>
        </CheckboxButton>
      </div>
    );
  }

  renderListActions() {
    const { categories } = this.state;

    return (
      <div className={"list-actions"}>
        <Button
          className="action-section__button"
          isOutline
          onClick={() => this.toggleAll(true)}
          disabled={_.isEmpty(getByFilter(categories, ALLOWED_CATEGORY))}
        >
          <Icon icon={"far fa-ban color-danger"} />
          <span>
            <FormattedMessage id="block_all" />
          </span>
        </Button>
        <Button
          className="action-section__button"
          isOutline
          onClick={() => this.toggleAll(false)}
          disabled={_.isEmpty(getByFilter(categories, BLOCKED_CATEGORY))}
        >
          <Icon icon={"far fa-check color-success"} />
          <span>
            <FormattedMessage id="allow_all" />
          </span>
        </Button>
      </div>
    );
  }

  getFilteredList = () => {
    const { categories, filter } = this.state;
    return this.getMapList(getByFilter(categories, filter));
  };

  getMapList = categories =>
    _.map(categories, category => ({
      ...category,
      changeAction: this.toggleCategory,
      icon: category.isBlocked
        ? "fa-ban color-danger"
        : "fa-check color-success"
    }));

  filterChange = option => {
    if (this.state.filter === option) return this.setState({ filter: "" });

    return this.setState({ filter: option });
  };

  toggleCategory = name => {
    this.setState(({ categories }) => ({
      categories: _.map(categories, category =>
        category.name === name
          ? { ...category, isBlocked: !category.isBlocked }
          : category
      )
    }));
  };

  toggleAll = isBlocked => {
    this.setState(({ categories }) => ({
      categories: setIsBlocked(categories, isBlocked)
    }));
  };

  handleSubmit = () =>
    this.props.modal.resolve(getBlockedNames(this.state.categories));
}

const setIsBlocked = (categories, isBlocked) =>
  _.map(categories, category => ({
    ...category,
    isBlocked
  }));

const getByFilter = (categories, filter) =>
  _.isEmpty(filter)
    ? categories
    : _.filter(categories, { isBlocked: filter === BLOCKED_CATEGORY });

const getBlockedNames = categories =>
  _(categories)
    .filter({ isBlocked: true })
    .map("name")
    .value();

export default ProtectionEditorDialog;
