import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

import { AppStatus } from "./AppStatus";

export const RoamingRowSubComponent = ({
  row: {
    original: { fullName, appStatus, identifier, softwareVersion, model, os }
  }
}) => (
  <div className={"sub-row-content"}>
    <SubRowContentRow>
      <SubRowItem header={<FormattedMessage id={"full_name"} />}>
        {renderSafely(fullName)}
      </SubRowItem>
    </SubRowContentRow>

    <SubRowContentRow>
      <SubRowItem header={<FormattedMessage id={"app_status"} />}>
        <AppStatus status={appStatus} />
      </SubRowItem>
      <SubRowItem header={<FormattedMessage id={"uuid"} />}>
        {renderSafely(identifier)}
      </SubRowItem>
      <SubRowItem header={<FormattedMessage id={"app_version"} />}>
        {renderSafely(softwareVersion)}
      </SubRowItem>
      <SubRowItem header={<FormattedMessage id={"device_info"} />}>
        {renderSafely(model)}
      </SubRowItem>
      <SubRowItem header={<FormattedMessage id={"os"} />}>
        {renderSafely(os)}
      </SubRowItem>
    </SubRowContentRow>
  </div>
);

export const RoamingRowSubComponentMobile = ({
  row: {
    original: { fullName, appStatus, identifier, softwareVersion, os }
  }
}) => (
  <div className={"sub-row-content"}>
    <SubRowContentRow>
      <SubRowItem header={<FormattedMessage id={"full_name"} />}>
        {renderSafely(fullName)}
      </SubRowItem>
    </SubRowContentRow>

    <SubRowContentRow>
      <SubRowItem header={<FormattedMessage id={"uuid"} />}>
        {renderSafely(identifier)}
      </SubRowItem>
    </SubRowContentRow>

    <SubRowContentRow>
      <SubRowItem header={<FormattedMessage id={"os"} />}>
        {renderSafely(os)}
      </SubRowItem>
    </SubRowContentRow>

    <SubRowContentRow>
      <SubRowItem header={<FormattedMessage id={"app_status"} />}>
        <AppStatus status={appStatus} />
      </SubRowItem>
      <SubRowItem header={<FormattedMessage id={"app_version"} />}>
        {renderSafely(softwareVersion)}
      </SubRowItem>
    </SubRowContentRow>
  </div>
);

const SubRowItem = ({ children, header }) => (
  <div className={"sub-row-content__item"}>
    <div className={"sub-row-content__header"}>{header}</div>
    {children}
  </div>
);

const SubRowContentRow = ({ children }) => (
  <div className={"sub-row-content__row"}>{children}</div>
);

const renderSafely = info => <Fragment>{info || "—"}</Fragment>;
