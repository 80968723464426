import React, { Fragment, useRef } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useElementDimensions } from "@sportal/cdk/hooks";

import { getBarchartReports } from "../../../../store/reports/reports.selectors";
import { getDrilldownReportsPeriod } from "../../drilldown.selectors";
import { NotMobile } from "../../../../hocs/Responsive";
import {
  BarChartProvider,
  BarChartTooltip,
  Bars,
  Brush,
  ChartNoDataMessage,
  useBarChartContext,
  useBarchartTooltipCleanup,
  XAxis,
  yPowScale
} from "../../../../components/charts/barChart";

import "./DrilldownBarchart.scss";

const BOTTOM_CHART_HEIGHT = 30;

const getChartSettings = page => ({
  barTypes: [page],
  dataSelection: {
    top: d => d[page]
  },
  [page]: {
    yOffsets: val => Math.abs(yPowScale(val)) || 0,
    getHeight: val => Math.abs(yPowScale(val) - yPowScale(0)) || 0
  }
});

export const DrilldownBarchart = ({ page }) => {
  const container = useRef(null);
  const rawData = useSelector(getBarchartReports);
  const period = useSelector(getDrilldownReportsPeriod);
  const { width, height } = useElementDimensions(container);

  useBarchartTooltipCleanup();

  return (
    <div className="drilldown-barchart barchart">
      <div className="width-container">
        <div className="barchart-container" ref={container}>
          <BarChartProvider
            chartWidth={width}
            chartHeight={height}
            bottomChartHeight={BOTTOM_CHART_HEIGHT}
            chartSettings={getChartSettings(page)}
            period={period}
            data={rawData}
          >
            <BarChart container={container} page={page} />
          </BarChartProvider>
        </div>
      </div>
    </div>
  );
};

const BarChart = ({ container, page }) => {
  const { data } = useBarChartContext();

  return (
    <Fragment>
      <svg>
        <XAxis />
        {!_.isEmpty(data) ? (
          <Brush>
            <Bars data={data} type={page} />
          </Brush>
        ) : (
          <ChartNoDataMessage />
        )}
      </svg>
      <NotMobile>
        <BarChartTooltip container={container} showTopData={true} />
      </NotMobile>
    </Fragment>
  );
};
