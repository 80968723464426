import _ from "lodash";

import { Protection } from "@sportal/api";
import { getWebFilters } from "../../../../store/root.selectors";

export const getCustomProtections = state =>
  getWebFilters(state).customProtections;

export const getBlockedCategories = (profileId, protection) => state => {
  const {
    protections,
    webFilters: { customProtections }
  } = state;

  return protection === Protection.Custom
    ? customProtections[profileId]
    : (protections.list[protection] || {}).categories;
};

export const getMapList = (
  { categories, ...actionsMap },
  { id, actionName, icon }
) =>
  _.map(categories[id], listItem => ({
    id: id,
    name: listItem["name"],
    icon: icon,
    changeAction: actionsMap[actionName]
  }));
