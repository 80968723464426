import { INITIAL_LOAD_SUCCESS, INITIAL_LOAD_FAILURE } from "./initial.actions";
import { LOGIN_FAILURE } from "../../pages/login/login.actions";

export default (state = {}, action) => {
  switch (action.type) {
    case INITIAL_LOAD_FAILURE:
    case INITIAL_LOAD_SUCCESS:
    case LOGIN_FAILURE:
      return {
        ...state,
        completed: true
      };
    default:
      return state;
  }
};
