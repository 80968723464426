import React, { FC, useEffect, useState } from "react";
import { useCombobox } from "downshift";
import classNames from "classnames";
import _ from "lodash";

import { BaseSelectContentProps, SelectContent } from "../select";
import { ISelectItem } from "../select/components";

interface ComboboxProps extends BaseSelectContentProps {
  selected: string;
  onChange: (value: string) => void;
}

export const Combobox: FC<ComboboxProps> = ({
  items,
  selected,
  className,
  onChange,
  ...props
}) => {
  const [filteredItems, setFilteredItems] = useState(items);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    const filteredList = !searchValue
      ? items
      : items.filter(item => {
          return item.content
            .toLowerCase()
            .includes(_.trim(searchValue.toLowerCase()));
        });

    setFilteredItems(filteredList);
  }, [items, searchValue]);

  const {
    isOpen,
    highlightedIndex,
    getInputProps,
    getComboboxProps,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps
  } = useCombobox<ISelectItem>({
    items: filteredItems,
    inputValue: searchValue,
    itemToString: item => (item ? item.content : ""),
    onStateChange: ({ selectedItem, isOpen }) => {
      selectedItem && onChange(selectedItem.value);
      isOpen === false && setSearchValue("");
    },
    onInputValueChange: ({ inputValue }) => {
      setSearchValue(inputValue);
    },
    circularNavigation: true
  });

  return (
    <SelectContent
      {...props}
      searchable
      className={classNames(className, "combobox")}
      items={filteredItems}
      isOpen={isOpen}
      getInputProps={getInputProps}
      getComboboxProps={getComboboxProps}
      selectedItem={_.find(items, { value: selected })}
      highlightedIndex={highlightedIndex}
      getToggleButtonProps={getToggleButtonProps}
      getLabelProps={getLabelProps}
      getMenuProps={getMenuProps}
      getItemProps={getItemProps}
    />
  );
};
