import _ from "lodash";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

import Notificator from "../../components/notification/notification.actions";
import { URL_TYPES } from "../../common/utils/url_types";
import { GLOBAL_URL_FILTER_ID } from "./urlFilters.constants";

export const load = (lists, profileId) => (dispatch, getState, { api }) => {
  const { urlFilters, subscriberInfo, profiles } = getState();
  const profileName = getProfileName(profiles, profileId);

  // Is it possible that one list is empty and the second one - is not?
  const isEmptyLists = _.every(_.pick(urlFilters[profileId], lists), list =>
    _.isEmpty(list)
  );

  if (!isEmptyLists) return Promise.resolve();

  dispatch(startLoading());
  lists = _.castArray(lists);

  return Promise.all(
    _.map(lists, list => api.ssm.list.get(list, subscriberInfo.id, profileName))
  )
    .then(results => {
      const data = { [profileId]: _.zipObject(lists, results) };

      dispatch(loadingSuccess(data));
    })
    .catch(error => {
      dispatch(
        Notificator.error(<FormattedMessage id={"url_filters_error"} />)
      );
      dispatch(generalFailure(error, LOADING_FAILURE));
      return Promise.reject();
    });
};

export const removeItem = (list, item, profileId) => (
  dispatch,
  getState,
  { api }
) => {
  const { urlFilters, subscriberInfo, profiles } = getState();
  const profileName = getProfileName(profiles, profileId);

  if (_.isEmpty(urlFilters[profileId][list].content)) return Promise.resolve();

  return api.ssm.list
    .removeItem(list, item, subscriberInfo.id, profileName)
    .then(() => dispatch(removeItemSuccess(list, item, profileId)))
    .catch(error => {
      dispatch(
        Notificator.error(
          <FormattedMessage id={"url_filters_removal_single_error"} />
        )
      );
      dispatch(generalFailure(error, REMOVE_ITEM_FAILURE));
      return Promise.reject();
    });
};

export const removeAll = (list, profileId) => (dispatch, getState, { api }) => {
  const { urlFilters, subscriberInfo, profiles } = getState(),
    profileName = getProfileName(profiles, profileId);

  const items = urlFilters[profileId][list].content;

  if (_.isEmpty(items)) return Promise.resolve();

  return api.ssm.list
    .removeItems(list, items, subscriberInfo.id, profileName)
    .then(() => dispatch(removeAllSuccess(list, profileId)))
    .catch(error => {
      dispatch(
        Notificator.error(
          <FormattedMessage id={"url_filters_removal_all_error"} />
        )
      );
      dispatch(generalFailure(error, REMOVE_ALL_FAILURE));
      return Promise.reject();
    });
};

export const checkUrl = url => (dispatch, getState, { api }) => {
  if (_.isEmpty(url)) {
    return Promise.resolve();
  }

  return api.ssm.list
    .checkUrl(url)
    .then(urls => dispatch(checkUrlSuccess(urls)))
    .catch(error => {
      dispatch(checkUrlFailure(error));
      return Promise.reject();
    });
};

export const addUrlToList = (list, url, type, profileId) => (
  dispatch,
  getState,
  { api }
) => {
  const { urlFilters, subscriberInfo, profiles } = getState();
  const profileName = getProfileName(profiles, profileId);
  const { blacklist, whitelist } = urlFilters[profileId];
  const item = {
    type,
    node: url
  };
  const isAlreadyInCurrentList = _.includes(
    urlFilters[profileId][list].content,
    url
  );
  const isAlreadyInOtherList =
    (list === "whitelist" && _.includes(blacklist.content, url)) ||
    (list === "blacklist" && _.includes(whitelist.content, url));
  const isLimitReached =
    urlFilters[profileId][list].limit <
    urlFilters[profileId][list].content.length + 1; // 1 url

  if (isLimitReached) {
    dispatch(
      Notificator.warning(
        <FormattedMessage
          id="can_not_add_sites_limit"
          values={{ limit: urlFilters[profileId][list].limit }}
        />
      )
    );
    return Promise.reject();
  }

  if (isAlreadyInCurrentList) {
    dispatch(Notificator.warning(<FormattedMessage id={"already_in_list"} />));
    return Promise.reject();
  }

  if (isAlreadyInOtherList) {
    dispatch(
      Notificator.error(<FormattedMessage id={"already_in_another_list"} />)
    );
    return Promise.reject();
  }

  return api.ssm.list
    .addItem(list, item, subscriberInfo.id, profileName)
    .then(resp => {
      const respItem = _.map(resp.data.adds, "node");

      dispatch(addUrlToListSuccess(list, respItem, profileId));
      dispatch(clearCheckUrl());
    })
    .catch(error => {
      dispatch(
        Notificator.error(<FormattedMessage id={"can_not_add_more_sites"} />)
      );
      dispatch(generalFailure(error, ADD_URL_TO_LIST_FAILURE));
      return Promise.reject();
    });
};

export const addUrlsToList = (list, urls, profileId) => (
  dispatch,
  getState,
  { api }
) => {
  const { subscriberInfo, urlFilters, profiles } = getState(),
    urlsNodes = urls.map(({ node }) => node),
    profileName = getProfileName(profiles, profileId),
    { blacklist, whitelist } = urlFilters[profileId],
    isAlreadyInCurrentList = !_.isEmpty(
      _.intersection(urlFilters[profileId][list].content, urlsNodes)
    ),
    isAlreadyInOtherList =
      (list === "whitelist" &&
        !_.isEmpty(_.intersection(blacklist.content, urlsNodes))) ||
      (list === "blacklist" &&
        !_.isEmpty(_.intersection(whitelist.content, urlsNodes))),
    isLimitReached =
      urlFilters[profileId][list].limit <
      urlFilters[profileId][list].content.length + urlsNodes.length;

  if (isLimitReached) {
    dispatch(
      Notificator.error(
        <FormattedMessage
          id="can_not_add_sites_limit"
          values={{ limit: urlFilters[profileId][list].limit }}
        />
      )
    );

    dispatch(uploadCsvFailure());
    return Promise.reject();
  }

  if (isAlreadyInCurrentList) {
    dispatch(
      Notificator.warning(
        <FormattedMessage id={"one_or_more_already_in_list"} />
      )
    );
    return Promise.reject();
  }

  if (isAlreadyInOtherList) {
    dispatch(
      Notificator.error(
        <FormattedMessage id={"one_or_more_already_in_another_list"} />
      )
    );
    return Promise.reject();
  }

  return api.ssm.list
    .addItems(list, urls, subscriberInfo.id, profileName)
    .then(resp => {
      const respItem = _.map(resp.data.adds, "node");
      const faluredItems =
        !_.isEmpty(resp.data.failures) &&
        _.map(resp.data.failures.adds, "node");

      dispatch(addUrlToListSuccess(list, respItem, profileId));

      //TODO: Probably we should remove markup from action section
      if (!_.isEmpty(faluredItems)) {
        dispatch(
          parsingCsvFailure(
            <Fragment>
              <FormattedMessage id={"partial_save_error"} />
              <ul className="urls-error-list">
                {_.map(faluredItems, (messageItem, key) => (
                  <li key={key}>{messageItem}</li>
                ))}
              </ul>
            </Fragment>
          )
        );

        return;
      }

      dispatch(clearCsvFile());
    })
    .catch(error => {
      dispatch(generalFailure(error, ADD_URL_TO_LIST_FAILURE));
      return Promise.reject();
    });
};

export const fileParser = (file, inputVal) => dispatch => {
  const regex = /^.*\.csv$/,
    fileName = file && file.name;

  if (file && regex.test(inputVal.toLowerCase())) {
    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();
      reader.onload = e => {
        const parsedList = e.target.result.split(/\s/g).filter(Boolean);

        if (_.isEmpty(parsedList)) {
          dispatch(
            parsingCsvFailure(<FormattedMessage id={"empty_csv_error"} />)
          );
          return;
        }

        dispatch(checkUrlsList(fileName, parsedList));
      };
      reader.readAsText(file);
    } else {
      dispatch(
        parsingCsvFailure(<FormattedMessage id={"not_supported_html5"} />)
      );
    }
  } else {
    dispatch(parsingCsvFailure(<FormattedMessage id={"upload_valid_csv"} />));
  }
};

export const checkUrlsList = (fileName, siteList) => (
  dispatch,
  getState,
  { api }
) => {
  return api.ssm.list
    .checkUrls(siteList)
    .then(({ data }) => {
      const { PUBLIC_SUFFIX, HOST_PATH } = URL_TYPES;

      if (_.isEmpty(data.failures) && !_.isEmpty(data.result)) {
        const resultList = _.map(data.result, ({ nodes }) => {
          if (nodes.length > 1) {
            return _.find(nodes, { type: HOST_PATH });
          }

          return {
            node: nodes[0].node,
            type: PUBLIC_SUFFIX
          };
        });

        dispatch(parsingCsvSuccess(fileName, resultList));
      } else {
        dispatch(
          Notificator.error(
            <FormattedMessage id={"one_or_more_urls_checking_failed"} />
          )
        );
        dispatch(uploadCsvFailure());
        dispatch(
          parsingCsvFailure(
            <Fragment>
              <FormattedMessage id={"list_url_failures"} />
              <ul className="urls-error-list">
                {_.map(data.failures, (messageItem, key) => (
                  <li key={key}>{messageItem.url}</li>
                ))}
              </ul>
            </Fragment>
          )
        );
      }
    })
    .catch(error => {
      dispatch(parsingCsvFailure(<FormattedMessage id={"check_csv_error"} />));
      dispatch(generalFailure(error, CLEAR_CHECK_URL));
      return Promise.reject();
    });
};

export const START_LOADING = "[URL_FILTERS] START_LOADING";
export const LOADING_SUCCESS = "[URL_FILTERS] LOADING_SUCCESS";
export const LOADING_FAILURE = "[URL_FILTERS] LOADING_FAILURE";
export const REMOVE_ITEM = "[URL_FILTERS] REMOVE_ITEM";
export const REMOVE_ITEM_SUCCESS = "[URL_FILTERS] REMOVE_ITEM_SUCCESS";
export const REMOVE_ITEM_FAILURE = "[URL_FILTERS] REMOVE_ITEM_FAILURE";
export const REMOVE_ALL = "[URL_FILTERS] REMOVE_ALL";
export const REMOVE_ALL_SUCCESS = "[URL_FILTERS] REMOVE_ALL_SUCCESS";
export const REMOVE_ALL_FAILURE = "[URL_FILTERS] REMOVE_ALL_FAILURE";
export const CHECK_URL_CHANGE = "[URL_FILTERS] CHECK_URL_CHANGE";
export const CHECK_URL = "[URL_FILTERS] CHECK_URL";
export const CHECK_URL_SUCCESS = "[URL_FILTERS] CHECK_URL_SUCCESS";
export const CHECK_URL_FAILURE = "[URL_FILTERS] CHECK_URL_FAILURE";
export const CLEAR_CHECK_URL = "[URL_FILTERS] CLEAR_CHECK_URL";
export const CANCEL_CHECK_URL = "[URL_FILTERS] CANCEL_CHECK_URL";
export const ADD_URL_TO_LIST = "[URL_FILTERS] ADD_URL_TO_LIST";
export const ADD_URLS_TO_LIST = "[URL_FILTERS] ADD_URLS_TO_LIST";
export const ADD_URL_TO_LIST_SUCCESS = "[URL_FILTERS] ADD_URL_TO_LIST_SUCCESS";
export const ADD_URL_TO_LIST_FAILURE = "[URL_FILTERS] ADD_URL_TO_LIST_FAILURE";
export const PARSE_CSV_FILE = "[URL_FILTERS] PARSE_CSV_FILE";
export const PARSE_CSV_FILE_SUCCESS = "[URL_FILTERS] PARSE_CSV_FILE_SUCCESS";
export const PARSE_CSV_FILE_FAILURE = "[URL_FILTERS] PARSE_CSV_FILE_FAILURE";
export const UPLOAD_CSV_FILE_FAILURE = "[URL_FILTERS] UPLOAD_CSV_FILE_FAILURE";
export const CLEAR_CSV_FILE = "[URL_FILTERS] CLEAR_CSV_FILE";

export const startLoading = () => ({
  type: START_LOADING
});
export const loadingSuccess = data => ({
  type: LOADING_SUCCESS,
  payload: data
});
export const removeItemSuccess = (list, item, profileId) => ({
  type: REMOVE_ITEM_SUCCESS,
  payload: { list, item, profileId }
});
export const removeAllSuccess = (list, profileId) => ({
  type: REMOVE_ALL_SUCCESS,
  payload: { list, profileId }
});
export const checkUrlChange = url => ({
  type: CHECK_URL_CHANGE,
  payload: { url }
});
export const checkUrlSuccess = urls => ({
  type: CHECK_URL_SUCCESS,
  payload: { urls }
});
export const checkUrlFailure = error => ({
  type: CHECK_URL_FAILURE,
  payload: error
});
export const clearCheckUrl = () => ({
  type: CLEAR_CHECK_URL
});
export const cancelCheckUrl = () => ({
  type: CANCEL_CHECK_URL
});
export const addUrlToListSuccess = (list, item, profileId) => ({
  type: ADD_URL_TO_LIST_SUCCESS,
  payload: { list, item, profileId }
});
export const parsingCsvSuccess = (fileName, siteList, errorMessage) => ({
  type: PARSE_CSV_FILE_SUCCESS,
  payload: { fileName, siteList, errorMessage, isUploadFailed: false }
});
export const parsingCsvFailure = errorMessage => ({
  type: PARSE_CSV_FILE_FAILURE,
  payload: { errorMessage }
});
export const uploadCsvFailure = () => ({
  type: UPLOAD_CSV_FILE_FAILURE,
  payload: { isUploadFailed: true }
});
export const clearCsvFile = () => ({
  type: CLEAR_CSV_FILE
});
export const generalFailure = (error, type) => ({
  type,
  payload: error
});

const getProfileName = (profiles, profileId) => {
  const profilesList = profiles && profiles.saved.list;

  return profileId === GLOBAL_URL_FILTER_ID
    ? null
    : profilesList[profileId].name;
};
