import { AnyAction } from "redux";
import _ from "lodash";

import { Protection } from "@sportal/api";
import {
  saveCustomProtection,
  wipeCustomProtection
} from "../../pages/profiles/components/webFilters/webFilters.actions";
import { dehydrate, rehydrate } from "./profiles.helpers";
import { saveSchedules } from "../../pages/profiles/components/schedule/schedules.actions";
import { saveSsProfiles } from "../../pages/profiles/components/internetSecurity/subscriberSafety/ssProfiles.actions";
import { getValidationErrors } from "../../pages/profiles/components/schedule/schedules.selectors";
import { SBThunkAction } from "../redux.types";
import { createProfilesSuccess } from "./profiles.actions";
import { SBProfile } from "./profiles.types";

// TODO: amitrofa: probably should not be a thunk, think a little bit more
//  also it is creation in terms of BE
export const createProfilesRequest = (
  profiles: any
): SBThunkAction<Promise<SBProfile[]>, AnyAction> => (
  dispatch,
  getState,
  { api }
) => {
  const state = getState();
  const {
    subscriberInfo: { id },
    schedules
  } = state;

  const errors = getValidationErrors(schedules);
  if (!_.isEmpty(errors)) {
    return Promise.reject({ customErrors: errors });
  }

  //  Following check is supposed to handle a case when profiles were saved
  //  but saving ssProfiles failed so that we can get profiles from backend
  //  but ssProfiles should be saved again.
  //  It is not clear where user is redirected to in this case
  //  so this code is here as a generic way to handle it.
  if (_.isEmpty(profiles)) {
    // That's actually strange, but let's count it ok
    return Promise.resolve();
  }

  let savedProfiles;

  // TODO: Error handling
  return api.ssm.profile
    .create(id, _.map(profiles, dehydrate))
    .catch(error => {
      const { response } = error;
      let errors = [];

      if (
        response &&
        response.data &&
        response.data.error.reason === "PROFILE_LIMIT_REACHED"
      ) {
        errors.push({ message: "profile_limit_reached_wizard" });
      }

      return Promise.reject({ customErrors: errors, error });
    })
    .then(({ data }) => {
      const updateMap = _.reduce(
        profiles,
        (map, profile) => ({
          ...map,
          [profile.id]: data.find(({ name }) => name === profile.name).id
        }),
        {}
      );

      dispatch(updateProfileIds(updateMap));
      return data;
    })
    .then(saved => {
      savedProfiles = _.map(saved, profile => rehydrate(profile, state));

      return _.reduce(
        saved,
        (result, profile) => {
          if (profile.protection === Protection.Custom) {
            return result.then(() => dispatch(saveCustomProtection(profile)));
          } else {
            dispatch(wipeCustomProtection(profile.id));
            return result;
          }
        },
        Promise.resolve()
      );
    })
    .then(() => dispatch(saveSchedules()))
    .then(() => dispatch(createProfilesSuccess(savedProfiles)))
    .then(() => dispatch(saveSsProfiles(savedProfiles)))
    .then(() => Promise.resolve(savedProfiles));
};

export const UPDATE_PROFILE_IDS = "[PROFILES] UPDATE_PROFILE_IDS";
export const updateProfileIds = map => {
  return {
    type: UPDATE_PROFILE_IDS,
    payload: { map }
  };
};
