import React from "react";
import { Icon as SharedIcon } from "@sportal/cdk/icon/Icon";

import "./Icon.scss";

export const Icon = props => <SharedIcon {...props} />;

Icon.defaultProps = {
  "aria-hidden": true
};
