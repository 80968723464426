import {
  SEARCH_URL_CHANGE,
  PROCESS_DATA_FAILURE,
  PROCESS_DATA_SUCCESS,
  REMOVE_ERROR_MESSAGE,
  CLEAR_ALL,
  FETCH_TABLE_DATA_FAILURE
} from "../actions";

export const initialState = {
  searchUrl: "",
  errorMessage: "",
  tableData: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_URL_CHANGE:
    case PROCESS_DATA_SUCCESS:
    case PROCESS_DATA_FAILURE:
    case FETCH_TABLE_DATA_FAILURE: {
      return { ...state, ...action.payload };
    }
    case REMOVE_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: ""
      };
    }
    case CLEAR_ALL: {
      return initialState;
    }

    default:
      return state;
  }
};
