import { StaticResourceProvider } from "./StaticResourceProvider";

export class StaticResourceService {
  constructor(config) {
    this.provider = new StaticResourceProvider(config);
  }

  getHelp(language, fallbackLanguage) {
    return this.provider
      .getHelpContent(language)
      .catch(error =>
        language !== fallbackLanguage
          ? this.provider.get(fallbackLanguage)
          : Promise.reject(error)
      )
      .then(({ data }) => data);
  }

  getTimezones() {
    return this.provider.getTimezones().then(({ data }) => data);
  }

  getTranslation(localeKey) {
    return this.provider.getTranslation(localeKey).then(({ data }) => data);
  }
}
