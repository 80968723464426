import React, { FC, Fragment } from "react";

import { Tooltip } from "../../../../components/tooltip/Tooltip";
import { Mobile, NotMobile } from "../../../../hocs/Responsive";

interface Props {
  url?: string;
  shortUrl?: string;
  isLink?: boolean;
}

export const OriginalUrl: FC<Props> = ({ url, shortUrl, isLink }) => {
  if (!url) {
    return <Fragment>-</Fragment>;
  }

  const urlLink = isLink ? <a href={url}>{shortUrl}</a> : <span>{url}</span>;

  return (
    <div className="drilldowns__table__original-url">
      <Mobile>{urlLink}</Mobile>
      <NotMobile>
        <Tooltip placement="top-start" autoWidth content={<span>{url}</span>}>
          {urlLink}
        </Tooltip>
      </NotMobile>
    </div>
  );
};
