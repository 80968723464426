import _ from "lodash";
import { Protection } from "@sportal/api";
import { ProfileType } from "./profiles.types";

export enum ProfileGroups {
  Employees = "sb_group_employee",
  Blocked = "sb_group_blocked_all_hidden",
  Visitors = "sb_group_visitor"
}

export const DEFAULT_PROFILE = {
  name: ProfileGroups.Visitors,
  type: ProfileType.Visitors,
  protection: Protection.Medium,
  default: true
};

export const FIXED_PROFILES = [
  DEFAULT_PROFILE,
  {
    name: ProfileGroups.Employees,
    type: ProfileType.Employees,
    protection: Protection.Light
  },
  {
    name: ProfileGroups.Blocked,
    type: ProfileType.Blocked,
    protection: Protection.None,
    "internet-access": false,
    hidden: true
  }
];

export const isFixed = name => _.some(FIXED_PROFILES, { type: name });
