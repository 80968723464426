import _ from "lodash";
import {
  ADD_URL_TO_LIST_SUCCESS,
  CHECK_URL_CHANGE,
  CHECK_URL_FAILURE,
  CHECK_URL_SUCCESS,
  CLEAR_CHECK_URL,
  CANCEL_CHECK_URL,
  CLEAR_CSV_FILE,
  LOADING_SUCCESS,
  PARSE_CSV_FILE_FAILURE,
  PARSE_CSV_FILE_SUCCESS,
  REMOVE_ALL_SUCCESS,
  REMOVE_ITEM_SUCCESS,
  UPLOAD_CSV_FILE_FAILURE
} from "./urlFilters.actions";

const initialState = {
  checkUrlState: {
    url: "",
    status: null,
    parsedURL: {}
  },
  uploadUrlsState: {
    fileName: "",
    siteList: [],
    errorMessage: "",
    isUploadFailed: false
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case ADD_URL_TO_LIST_SUCCESS:
      const { list, item, profileId } = action.payload;
      const newItems = _.uniq(
        _.concat(_.get(state[profileId], `${list}.content`), item)
      );
      return {
        ...state,
        [profileId]: {
          ...state[profileId],
          [list]: {
            ...state[profileId][list],
            content: newItems,
            total: newItems.length
          }
        }
      };
    case REMOVE_ITEM_SUCCESS: {
      const { list, item, profileId } = action.payload;
      const newItems = _.without(
        _.get(state[profileId], `${list}.content`),
        item
      );

      return {
        ...state,
        [profileId]: {
          ...state[profileId],
          [list]: {
            ...state[profileId][list],
            content: newItems,
            total: newItems.length
          }
        }
      };
    }
    case REMOVE_ALL_SUCCESS: {
      const { list, profileId } = action.payload;
      return {
        ...state,
        [profileId]: {
          ...state[profileId],
          [list]: {
            ...state[profileId][list],
            content: [],
            total: 0
          }
        }
      };
    }
    case CHECK_URL_CHANGE: {
      const { url } = action.payload;
      return { ...state, checkUrlState: { ...state.checkUrlState, url: url } };
    }
    case CHECK_URL_SUCCESS: {
      const { urls } = action.payload;
      return {
        ...state,
        checkUrlState: {
          ...state.checkUrlState,
          parsedURL: urls,
          status: "success"
        }
      };
    }
    case CHECK_URL_FAILURE: {
      return {
        ...state,
        checkUrlState: { ...state.checkUrlState, status: "error" }
      };
    }
    case CLEAR_CHECK_URL: {
      return {
        ...state,
        checkUrlState: { url: "", parsedURL: {}, status: null }
      };
    }
    case CANCEL_CHECK_URL: {
      return {
        ...state,
        checkUrlState: { ...state.checkUrlState, parsedURL: {}, status: null }
      };
    }
    case PARSE_CSV_FILE_SUCCESS:
    case PARSE_CSV_FILE_FAILURE:
    case UPLOAD_CSV_FILE_FAILURE: {
      return {
        ...state,
        uploadUrlsState: { ...state.uploadUrlsState, ...action.payload }
      };
    }
    case CLEAR_CSV_FILE: {
      return {
        ...state,
        uploadUrlsState: { ...initialState.uploadUrlsState }
      };
    }
    default:
      return state;
  }
};
