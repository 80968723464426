import { ResourceProvider, ResourceType } from "./resource.provider";
import { failure, Result, success } from "../shared";

export enum TimezoneServiceErrorCodes {
  GENERIC
}

export type Timezones = string[];

export class TimezoneService {
  constructor(private provider: ResourceProvider) {}

  public async get(): Promise<Result<Timezones>> {
    try {
      const { data } = await this.provider.get<Timezones>(
        "timezones",
        ResourceType.JSON
      );
      return success(data);
    } catch (error) {
      return failure(TimezoneServiceErrorCodes.GENERIC);
    }
  }
}
