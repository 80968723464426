export const getAttributesFromFile = async svg => {
  const svgText = await readFileAsText(svg);
  const pattern = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
  let wrapperEl = document.createElement("div");
  wrapperEl.innerHTML = svgText;

  if (pattern.test(wrapperEl.innerHTML)) {
    return Promise.reject();
  }

  const attributesNodes = wrapperEl.querySelector("svg").attributes;

  return getAttributesNamesFromNodeList(attributesNodes);
};

const getAttributesNamesFromNodeList = nodeList => {
  let attrNames = [];

  Array.prototype.forEach.call(nodeList, node => {
    attrNames.push(node.nodeName);
  });

  return attrNames;
};

const readFileAsText = file => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error("Problem with parsing input file"));
    };

    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.readAsText(file);
  });
};
