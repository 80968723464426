import React, { useLayoutEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { Icon } from "../../icon/Icon";

export function defaultIsMenuSmallerThanWrapper(menuElement, wrapperRef) {
  if (
    menuElement &&
    menuElement.offsetWidth &&
    wrapperRef.current.offsetWidth
  ) {
    return wrapperRef.current.offsetWidth > menuElement.offsetWidth;
  }

  return null;
}

export const SubMenu = ({
  wrapperRef,
  items,
  hasActiveItem,
  isOpened,
  isMenuSmallerThanWrapper
}) => {
  const [menuElement, setMenuElement] = useState();

  const [smallerThanItem, setSmallerThanItemState] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    const nextState = isMenuSmallerThanWrapper(menuElement, wrapperRef);

    if (smallerThanItem === null && nextState !== null) {
      setSmallerThanItemState(nextState);
    }
  });

  return (
    <div className="hm__item__sub-menu-container">
      <Icon
        icon={classNames("hm__item__caret fal", {
          "fa-angle-down": hasActiveItem && !isOpened,
          "fa-angle-up": !hasActiveItem || isOpened
        })}
        data-testid="menu-item-arrow-icon"
      />
      <ul
        ref={setMenuElement}
        className={classNames("sub-menu", {
          "sub-menu--smaller-than-item": smallerThanItem,
          "sub-menu--hidden": smallerThanItem === null
        })}
        data-testid="submenu-list"
      >
        {_.map(items, (item, key) => (
          <li key={key} className="sub-menu__item">
            <NavLink to={item.url} activeClassName="sub-menu__item--active">
              <FormattedMessage id={item.text} />
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

SubMenu.defaultProps = {
  isMenuSmallerThanWrapper: defaultIsMenuSmallerThanWrapper
};
