import React, { Fragment } from "react";

import { DeviceDropdownMenu } from "../../../../components/devices/DeviceDropdownMenu";
import {
  useMergeDeviceMenuItem,
  useMoveDeviceMenuItem,
  useRemoveDeviceMenuItem,
  useUnmergeDeviceMenuItem,
  useRenameDeviceMenuItem
} from "../../../../components/devices/hooks";
import { NotMobile, Mobile } from "../../../../hocs/Responsive";
import { DeviceActionsToggle } from "../../../../components/devices/DeviceActionsDialog";

const useMergeDeviceMenuItemIfAvailable = deviceToMerge => {
  const item = useMergeDeviceMenuItem(deviceToMerge);
  const isMergeAvailable = deviceToMerge.identifiers.length < 2;

  if (!isMergeAvailable) {
    return null;
  }

  return item;
};

const useUnmergeDeviceMenuItemIfAvailable = device => {
  const item = useUnmergeDeviceMenuItem(device);
  const isUnmergeAvailable = device.identifiers.length > 1;

  if (!isUnmergeAvailable) {
    return null;
  }

  return item;
};

export const ProfileDevicesDropdownActions = ({
  identifiers,
  deviceDetails,
  name,
  profile
}) => {
  // temporary fix for the case when backend doesn't return device details
  const identifierInfo = deviceDetails[identifiers[0]];
  const deviceType = identifierInfo && identifierInfo.type;

  const menuItems = [
    useMergeDeviceMenuItemIfAvailable({
      identifiers,
      type: deviceType
    }),
    useRenameDeviceMenuItem({ identifiers, name, profile }),
    useMoveDeviceMenuItem(name),
    useRemoveDeviceMenuItem(name),
    useUnmergeDeviceMenuItemIfAvailable({ identifiers, name, profile })
  ].filter(Boolean);

  return (
    <Fragment>
      <Mobile>
        <DeviceActionsToggle
          deviceName={name}
          menuItems={menuItems}
          ariaDeviceName={name}
        />
      </Mobile>
      <NotMobile>
        <DeviceDropdownMenu menuItems={menuItems} deviceName={name} />
      </NotMobile>
    </Fragment>
  );
};
