import React from "react";
import _ from "lodash";

export const Manufacturer = ({ className, manufacturer }) => {
  const isManufacturerDefined = () =>
    !_.isEmpty(manufacturer) && !_.isNil(manufacturer);

  return (
    <span className={className}>
      {isManufacturerDefined() ? manufacturer : "—"}
    </span>
  );
};
