import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { Mobile, NotMobile } from "../../hocs/Responsive";
import { Button } from "../button/Button";
import { useIntlTranslate } from "../../hooks";
import { Icon } from "../icon/Icon";
import { widgetTitles } from "../../pages/home/widgets/WidgetHeader";

import "./charts.scss";

class Widget extends Component {
  render() {
    const { drilldown, className, children } = this.props;

    const widgetClasses = `widget ${className || ""}`;

    return (
      <div className={widgetClasses}>
        {this.renderHeader()}
        {children}
        <DrilldownLink drilldown={drilldown} />
      </div>
    );
  }

  renderHeader() {
    const { icon, data, period, header } = this.props;
    const iconClass = `far fa-${icon}`;
    const sum = _.reduce(data, (sum, item) => sum + item.value, 0);

    return (
      <Fragment>
        <Mobile>
          <div className={"widget__header"}>
            <div className="widget__title">{header(period)}</div>
            <div className="widget__count">{sum}</div>
          </div>
          <div className={"widget__overlay"} />
        </Mobile>
        <NotMobile>
          <div className="widget__count">
            <Icon icon={iconClass} />
            <span>{sum}</span>
          </div>
          <div className="widget__title">{header(period)}</div>
        </NotMobile>
      </Fragment>
    );
  }
}

const DrilldownLink = ({ drilldown }) => {
  const translate = useIntlTranslate();
  const ariaLabel = translate("aria_see_more_blocked_by", {
    blockedBy: translate(widgetTitles[drilldown].long)
  });

  return (
    <Fragment>
      <Mobile>
        <Button
          as={Link}
          to={`drilldown/${drilldown}`}
          className={"widget__link widget__button"}
          aria-label={ariaLabel}
        >
          <Icon icon="far fa-angle-right" />
        </Button>
      </Mobile>
      <NotMobile>
        <div className="widget__button">
          <Button
            as={Link}
            to={`drilldown/${drilldown}`}
            isOutline
            pillButton
            size={"small"}
            aria-label={ariaLabel}
          >
            <span className="widget__button__content">
              <FormattedMessage id="see_more" />
            </span>
          </Button>
        </div>
      </NotMobile>
    </Fragment>
  );
};

Widget.propTypes = {
  icon: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  header: PropTypes.func,
  endpoint: PropTypes.string,
  drilldown: PropTypes.string
};

export default Widget;
