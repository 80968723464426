import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { FormattedMessage, useIntl } from "react-intl";

import {
  fetchNewDevices,
  fetchInfected,
  fetchLogicalDevices,
  fetchLines
} from "../../../store/devices/devices.actions";
import { DevicesSets, TABS } from "../../../helpers/devices.helpers";
import { DiscoveredDevicesTable } from "./DiscoveredDevicesTable";
import { InfectedDevicesTable } from "./InfectedDevicesTable";
import { BlockedDevicesTable } from "./BlockedDevicesTable";
import { RoamingDevicesTable } from "./RoamingDevicesTable";
import { NotMobile, Mobile } from "../../../hocs/Responsive";
import { HorizontalScrollTabs } from "../../../components/horizontalScrollTabs/HorizontalScrollTabs";
import { useMount } from "@sportal/cdk/hooks";
import { TabList, createDeviceTab } from "../../../components/tabList";
import { isSPSONAvailable } from "../../../store/account";

import "../../../components/devices/TableDevices.scss";
import "./Devices.scss";

export const Devices = () => {
  const [selectedType, setSelectedType] = useState(DevicesSets.Discovered);
  const showSPSON = useSelector(isSPSONAvailable);
  const intl = useIntl();
  const dispatch = useDispatch();

  const tabs = useMemo(() => {
    const filteredTabs = showSPSON
      ? TABS
      : _.reject(TABS, {
          value: DevicesSets.Roaming
        });

    return filteredTabs.map(tab => createDeviceTab(tab, intl));
  }, [showSPSON, intl]);

  const handleTabChange = useCallback(
    type => {
      setSelectedType(type);
      type === DevicesSets.Discovered && dispatch(fetchNewDevices());
    },
    [dispatch]
  );

  useMount(() => {
    dispatch(fetchLogicalDevices());
    dispatch(fetchInfected());
    dispatch(fetchLines());
    dispatch(fetchNewDevices());
  });

  return (
    <section className="reporting-devices">
      <h4 className="reporting-devices__title">
        <FormattedMessage id="network_device" />
      </h4>
      <div className={"devices__layout"}>
        <NotMobile>
          <TabList
            items={tabs}
            selected={selectedType}
            onClick={handleTabChange}
          />
        </NotMobile>
        <Mobile>
          <HorizontalScrollTabs
            tabs={tabs}
            onClick={handleTabChange}
            selected={selectedType}
          />
        </Mobile>
        {renderDevicesTable(selectedType, { showRoaming: showSPSON })}
      </div>
    </section>
  );
};

const renderDevicesTable = (type, { showRoaming }) => {
  if (type === DevicesSets.Discovered) return <DiscoveredDevicesTable />;
  if (type === DevicesSets.Infected) return <InfectedDevicesTable />;
  if (type === DevicesSets.Blocked) return <BlockedDevicesTable />;
  if (type === DevicesSets.Roaming && showRoaming)
    return <RoamingDevicesTable />;
  return null;
};
