import _ from "lodash";
import {
  DevicesTypes,
  getIdentifiers,
  getInfectedDeviceName,
  getTypeForIdentifier,
  getDeviceType,
  isAddressInList
} from "../../helpers/devices.helpers";
import { getThreatsList } from "../threats/threats.selectors";
import { getDevices } from "../root.selectors";
import { ProfileGroups } from "../profiles/fixedProfiles";

export const getLogicalDevices = _.flow([getDevices, state => state.all]);
export const getRoamingLimit = _.flow([
  getDevices,
  state => state.roamingDevicesLimit
]);
export const getLogicalDevicesList = _.flow([
  getLogicalDevices,
  state => state.list
]);
export const getDevicesDetails = _.flow([
  getLogicalDevices,
  state => state.detailsByIdentifier
]);

export const getLines = _.flow([getDevices, state => state.lines]);
export const getLinesList = _.flow([getLines, state => state.list]);
export const getLimit = _.flow([getDevices, state => state.limit]);

//region Profile Devices
export const getDevicesByProfile = profile =>
  _.flow([getLogicalDevicesList, devices => _.filter(devices, { profile })]);
export const getDevicesByProfileWithDetails = profile => state => {
  const profileDevices = getDevicesByProfile(profile)(state);
  const details = getDevicesDetails(state);

  return profileDevices.map(extendDeviceWithDetails(details));
};

export const getBlockedDevices = getDevicesByProfile(ProfileGroups.Blocked);
export const getNotBlockedDevices = _.flow([
  getLogicalDevicesList,
  devices => _.reject(devices, { profile: ProfileGroups.Blocked })
]);

export const getSavedDevicesWithLinesOnly = state => {
  const devices = getNotBlockedDevices(state);
  const details = getDevicesDetails(state);
  const lines = getLinesList(state);

  return _.filter(devices, device => {
    if (device.identifiers.length > 1) return;

    const id = device.identifiers[0];
    const type = details[id].type;

    if (type !== DevicesTypes.Mobile) return;

    const associatedLine = getAssociatedLine(device, lines);
    return associatedLine && associatedLine["is-device"];
  });
};
export const getSavedDevicesWithMacsOnly = state => {
  const devices = getNotBlockedDevices(state);
  const details = getDevicesDetails(state);
  const lines = getLinesList(state);

  return _.filter(devices, device => {
    if (device.identifiers.length > 1) return;

    const id = device.identifiers[0];
    const type = details[id].type;

    if (type !== DevicesTypes.Wifi) return;

    const associatedLine = getAssociatedLine(device, lines);
    return !associatedLine || !associatedLine["is-device"];
  });
};

export const getNotRoamingDevicesByProfile = profileName => state => {
  const profileDevices = getDevicesByProfileWithDetails(profileName)(state);

  return _.reject(profileDevices, device =>
    _.some(
      device.identifiers,
      id => device.details[id].type === DevicesTypes.Roaming
    )
  );
};

export const getDevicesForMerge = device => state => {
  const devicesWithLinesOnly = getSavedDevicesWithLinesOnly(state);
  const devicesWithMacsOnly = getSavedDevicesWithMacsOnly(state);
  const type = device.type;
  const devices =
    type === DevicesTypes.Mobile ? devicesWithMacsOnly : devicesWithLinesOnly;
  const id = device.address || device.identifiers[0];

  return _.filter(devices, d => d.identifiers[0] !== id);
};

const isDeviceSaved = (saved, id) => {
  const ids = getIdentifiers(saved);

  return isAddressInList(ids, id);
};
//endregion

//region Reporting Devices
export const getInfectionsMap = state => state.devices.infected.list;
export const getDiscoveredDevices = state => state.devices.discovered;
export const getDiscoveredDevicesList = _.flow([
  getDiscoveredDevices,
  state => state.list
]);
export const getRequestsWithName = state => state.devices.withName;
export const getRequestsWithNameList = _.flow([
  getRequestsWithName,
  state => state.list
]);

export const getNotBlockedInfectedDevices = state => {
  const saved = getLogicalDevicesList(state);
  const threats = getThreatsList(state);
  const infected = getInfectionsMap(state);
  const namedRequests = getDevicesWithActiveRequests(state);
  const blockedIds = _.flow([getBlockedDevices, getIdentifiers])(state);

  return _(infected)
    .omit(blockedIds)
    .map((threatsIds, id) =>
      extendDeviceWithInfections({ id }, threatsIds, threats)
    )
    .map(device => ({
      ...device,
      name: getInfectedDeviceName(device, namedRequests, saved)
    }))
    .value();
};
export const getDevicesWithActiveRequests = state => {
  const named = getRequestsWithNameList(state);
  const saved = getLogicalDevicesList(state);

  return _(named)
    .reject(({ address }) => isDeviceSaved(saved, address))
    .groupBy("address")
    .map(requests => _.head(requests))
    .orderBy("lastseen", "asc")
    .value();
};
export const getLastRequests = state => {
  const namedRequests = getDevicesWithActiveRequests(state);
  const ordered = _.orderBy(namedRequests, ["lastseen"], ["desc"]);

  return {
    requests: ordered.slice(0, 4),
    totalLength: ordered.length
  };
};

export const anyNewRequests = state =>
  getDevicesWithActiveRequests(state).length > 0;
//endregion

//region Lines
const getAssociatedLine = (device, lines) => {
  return _.find(lines, { name: device.identifiers[0] });
};
//endregion

//region Profile + Reporting Devices
export const getBlockedDevicesWithInfections = state => {
  const blocked = getBlockedDevices(state);
  const threats = getThreatsList(state);
  const infected = getInfectionsMap(state);

  return _(blocked)
    .map(device => {
      const threatsIds = _(infected)
        .pick(device.identifiers)
        .values()
        .flatten()
        .value();

      return extendDeviceWithInfections(device, threatsIds, threats);
    })
    .value();
};
//endregion

//region Roaming Devices
const mapRoamingDevice = (details, logicalDevice) => {
  return {
    name: details.name,
    identifier: details.identifier,
    profile: logicalDevice.profile,
    lastseen: logicalDevice.lastseen,
    os: details.os,
    softwareVersion: details["software-version"],
    model: details.model,
    fullName: details.username,
    appStatus: details.status,
    protectionStatus: details.status,
    logicalDeviceId: details.logicalDeviceId
  };
};
const pickRoamingDevices = devices => {
  const details = devices.detailsByIdentifier;
  const roaming = _.filter(details, id => id.type === DevicesTypes.Roaming);

  return _.map(roaming, device => {
    const logicalDeviceId = device.logicalDeviceId;
    const savedDevice = getDeviceByGeneratedId(devices.list, logicalDeviceId);

    return mapRoamingDevice(device, savedDevice);
  });
};
const pickRoamingDevicesByProfile = profileName => devices => {
  const allRoamingDevices = pickRoamingDevices(devices);

  return _.filter(allRoamingDevices, device => device.profile === profileName);
};

export const getRoamingDevices = _.flow([
  getLogicalDevices,
  pickRoamingDevices
]);
export const getRoamingDevicesCount = _.flow([
  getRoamingDevices,
  devices => devices.length
]);
export const getRoamingDevicesByProfile = profileName =>
  _.flow([getLogicalDevices, pickRoamingDevicesByProfile(profileName)]);

export const getRoamingDetailsByIdentifier = (state, identifier) => {
  const devices = getLogicalDevices(state);
  const details = devices.detailsByIdentifier[identifier];
  const logicalDevice = getDeviceByGeneratedId(
    devices.list,
    details.logicalDeviceId
  );

  return mapRoamingDevice(details, logicalDevice);
};
//endregion

//region Devices in tables
export const getOnlineDevices = state => {
  const saved = getLogicalDevicesList(state);
  const lines = getLinesList(state);
  const discovered = getDiscoveredDevicesList(state);

  const selectedLines = _(lines)
    .reject(({ name }) => isDeviceSaved(saved, name))
    .map(({ name }) => {
      const discoveredLine = _.find(discovered, { address: name });

      if (!discoveredLine) {
        return { address: name };
      }

      return {
        address: name,
        lastseen: discoveredLine.lastseen
      };
    })
    .value();

  const selectedDevices = _(discovered)
    .reject(({ address }) => isDeviceSaved(saved, address))
    .value();

  const onlineDevices = [...selectedDevices, ...selectedLines];

  return _.uniqBy(onlineDevices, "address");
};

export const getOnlineDevicesForTable = state => {
  const online = getOnlineDevices(state);

  return enhanceDeviceWithType(state, online);
};
export const getBlockedDevicesForTable = state => {
  const blocked = getBlockedDevicesWithInfections(state);
  const details = getDevicesDetails(state);

  return _.map(blocked, device => {
    return {
      ...device,
      flatInfections: _.map(device.infections, "name"),
      details: _.pick(details, device.identifiers)
    };
  });
};
export const getInfectedDevicesForTable = state => {
  const infected = getNotBlockedInfectedDevices(state);

  const mapped = _.map(infected, device => ({
    ...device,
    flatInfections: _.map(device.infections, "name")
  }));

  return enhanceDeviceWithType(state, mapped, "id");
};

export const getRequestedDevicesForTable = state => {
  const requested = getDevicesWithActiveRequests(state);

  return enhanceDeviceWithType(state, requested);
};
//endregion

//region Utils
const sortInfectionsBySeverity = infections =>
  _.orderBy(infections, ["severity", "name"], ["desc", "asc"]);

const convertThreatIdsToFullDetails = (threatsIds, threats) => {
  return _.map(threatsIds, id =>
    _.get(threats, id, { id, notFullThreat: true, name: "" + id })
  );
};
const extendDeviceWithInfections = (device, ...rest) => {
  const fullThreats = convertThreatIdsToFullDetails(...rest);

  return {
    ...device,
    infections: sortInfectionsBySeverity(fullThreats)
  };
};
const extendDeviceWithDetails = details => device => {
  return {
    ...device,
    details: _.pick(details, device.identifiers)
  };
};

/**
 * @param {*} identifierKey temporary workaround until device address property names unification
 */
const enhanceDeviceWithType = (state, devices, identifierKey = "address") => {
  const lines = getLinesList(state);
  const details = getDevicesDetails(state);

  return _.map(devices, device => {
    let associatedLine;

    if (_.has(device, "identifiers")) {
      associatedLine = _.find(lines, line =>
        isAddressInList(device.identifiers, line.name)
      );
    } else {
      associatedLine = _.find(lines, { name: device[identifierKey] });
    }

    const deviceDetails = _.get(details, device[identifierKey]);

    return {
      ...device,
      type: getDeviceType(associatedLine, deviceDetails)
    };
  });
};

export const isBlockActionDisabled = state => {
  const limit = getLimit(state);
  const blocked = getBlockedDevices(state);

  return Boolean(limit && blocked.length >= limit / 2);
};

export const getTypesForIds = (state, identifiers) => {
  const lines = getLinesList(state);
  const details = getDevicesDetails(state);

  return _.reduce(
    identifiers,
    (memo, identifier) => ({
      ...memo,
      [identifier]: getTypeForIdentifier(identifier, lines, details)
    }),
    {}
  );
};
export const getDeviceByGeneratedId = (devices, id) => {
  return _.find(devices, { logicalDeviceId: id });
};
//endregion
