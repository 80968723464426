import { useState } from "react";
import { usePopper } from "react-popper";

export const PLACEMENTS = [
  "auto",
  "auto-start",
  "auto-end",
  "top",
  "bottom",
  "right",
  "left",
  "top-start",
  "top-end",
  "bottom-start",
  "bottom-end",
  "right-start",
  "right-end",
  "left-start",
  "left-end"
];

export const getOffset = ({ placement }) => {
  if (placement === "top" || placement === "bottom") {
    return [0, 4];
  } else {
    return [0, 8];
  }
};

export const usePopperAPI = (
  placement = "top",
  modifiers = [],
  referrerElement = null
) => {
  const [referenceElement, setReferenceElement] = useState(referrerElement);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      { name: "arrow", options: { element: arrowElement, padding: 10 } },
      { name: "offset", options: { offset: getOffset } },
      ...modifiers
    ]
  });

  return {
    setReferenceElement,
    setPopperElement,
    setArrowElement,
    styles,
    attributes
  };
};
