import React from "react";
import { FormattedMessage } from "react-intl";

import "./ScheduleRuleHint.scss";

import { Icon } from "../../../../../components/icon/Icon";

export const ScheduleRuleHint = () => {
  return (
    <div className="schedule-rule-hint">
      <Icon icon="schedule-rule-hint__icon far fa-info-circle" />
      <div className="schedule-rule-hint__note">
        <FormattedMessage id={"content_restriction_note"} />
      </div>
    </div>
  );
};
