import React, { FC, useLayoutEffect, useRef, useState } from "react";
import _ from "lodash";

import { useElementDimensions, useToggle } from "@sportal/cdk/hooks";
import { TabList, TabItem } from "../tabList";
import { doesTabsFit, getHiddenStart, getWidths } from "./tabsSelect.helpers";
import { HiddenOptions } from "./hiddenOptions/HiddenOptions";

import "./TabsSelect.scss";

interface TabSelectProps {
  items: TabItem[];
  selected: string;
  onClick: (id: string) => void;
}

export const TabsSelect: FC<TabSelectProps> = ({
  items = [],
  selected,
  onClick
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLUListElement>();

  const [repaint, toggleRepaint] = useToggle(false);
  const [selectStart, setSelectStart] = useState<number>(null);

  const { width: wrapperWidth } = useElementDimensions(wrapperRef);

  useLayoutEffect(() => {
    // paint it all over again
    setSelectStart(null);
    toggleRepaint();
  }, [items, wrapperWidth, toggleRepaint]);

  useLayoutEffect(() => {
    if (_.isNull(selectStart)) {
      const { tabWidths, totalTabsWidth } = getWidths(tabsRef);
      const showSelect = !doesTabsFit(wrapperWidth, totalTabsWidth);

      if (showSelect) {
        setSelectStart(() => getHiddenStart(wrapperWidth, tabWidths));
      }
    }
  }, [repaint, selectStart, wrapperWidth]);

  const showSelect =
    wrapperWidth && !_.isEmpty(items) && !_.isNull(selectStart);

  return (
    <div className="tabs-select" ref={wrapperRef}>
      <TabList
        items={!showSelect ? items : items.slice(0, selectStart)}
        tabsRef={tabsRef}
        selected={selected}
        onClick={onClick}
      />
      {showSelect && (
        <HiddenOptions
          items={items.slice(selectStart)}
          selected={selected}
          onClick={onClick}
        />
      )}
    </div>
  );
};
