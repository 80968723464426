import { Service } from "../settings";

export enum ServiceProfiles {
  SBSingle = "single-profile",
  SBMultiple = "multiple-profile"
}

export interface AccountData {
  id: string;
  timezone?: string;
  [Service.HttpsTermination]: {
    activated: boolean;
    enabled: boolean;
  };
  [Service.PersonalInternet]: {
    activated: boolean;
    enabled: boolean;
    time?: string;
    type?: string;
    "bypass-pin-enabled"?: boolean;
    "bypass-pin-value"?: string;
    "profile-count"?: number;
    "profile-limit"?: number;
  };
  [Service.SecureBusiness]: {
    activated: boolean;
    enabled: boolean;
    time?: string;
    type?: string;
    "bypass-pin-enabled"?: boolean;
    "bypass-pin-value"?: string;
    "profile-count"?: number;
    "profile-limit"?: number;
    "spson-enabled"?: boolean;
    status?: string;
  };
  [Service.SubscriberSafety]: {
    activated: boolean;
    enabled: boolean;
    time?: string;
    type?: string; // not sure if this key can be received for this case
    "bypass-pin-enabled"?: boolean;
    "bypass-pin-value"?: string;
  };
}

export interface BypassPinUpdate {
  enabled?: boolean;
  value?: string;
}

export type BypassPinUpdates = {
  [Service.PersonalInternet]?: BypassPinUpdate;
  [Service.SubscriberSafety]?: BypassPinUpdate;
};
