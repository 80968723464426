import React, { FC } from "react";
import classnames from "classnames";

import {
  Select as SharedSelect,
  SelectProps as SharedSelectProps
} from "@sportal/cdk/select/Select";

import "./Select.scss";

interface SelectProps extends SharedSelectProps {
  small?: boolean;
}

export const Select: FC<SelectProps> = ({ className, small, ...props }) => {
  return (
    <SharedSelect
      className={classnames(className, {
        "select-wrapper--small": small
      })}
      {...props}
    />
  );
};
