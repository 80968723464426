import _ from "lodash";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import "./UrlOptionsPicker.scss";

import UrlOption from "../urlOption/UrlOption";
import { Button } from "../../../../components/button/Button";
import { ModalService } from "../../../../components/modal";
import { ConfirmBlockDialog } from "../confirmBlockDialog/ConfirmBlockDialog";
import { punyToUnicode } from "../../../../utils/punyToUnicode";
import { URL_TYPES } from "../../../../common/utils/url_types";

class UrlOptionsPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: _.last(props.urls).type
    };
  }

  handleChange = selectedItem => this.setState({ selectedItem });

  handleAddUrl = list => {
    const { urls, profileId, onAddUrl } = this.props;
    const url = _.find(urls, { type: this.state.selectedItem }).node;

    onAddUrl(list, url, this.state.selectedItem, profileId);
  };

  getTld = () =>
    this.props.urls.find(({ type }) => type === URL_TYPES.PUBLIC_SUFFIX);

  render() {
    const { urls } = this.props;
    const isTld = !!this.getTld();

    return (
      <div className="url-options-picker">
        {isTld && this.renderTldInfo()}
        <div className="url-options-picker__list">
          {_.map(urls, ({ node, type }, key) => (
            <UrlOption
              key={key}
              type={type}
              node={node}
              isChecked={type === this.state.selectedItem}
              onChange={() => this.handleChange(type)}
            />
          ))}
          <div className="url-options-picker__action-box">
            <div className="url-options-picker__action-buttons">
              <Button
                color={"destructive"}
                onClick={() =>
                  isTld
                    ? this.openBlockTldModal()
                    : this.handleAddUrl("blacklist")
                }
              >
                <FormattedMessage id="block" />
              </Button>
              {!isTld && (
                <Button
                  disabled={this.state.selectedItem === URL_TYPES.HOST_PATH}
                  onClick={() => this.handleAddUrl("whitelist")}
                >
                  <FormattedMessage id="allow" />
                </Button>
              )}
            </div>
            {!isTld && (
              <p className="url-options-picker__warning">
                <FormattedMessage id="whitelist_priority_warning" />
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  openBlockTldModal = () =>
    openModal(this.getTld())
      .then(() => this.handleAddUrl("blacklist"))
      .catch(() => null);

  renderTldInfo = () => (
    <div className="url-options-picker__tld-info">
      <FormattedMessage
        id="tld_block_info"
        values={{
          domain: (
            <span className="text-highlighted">
              .{punyToUnicode(this.getTld().node)}
            </span>
          )
        }}
      />
      <p>
        <span className="text-highlighted">
          <FormattedMessage id="note" />:
        </span>
        &nbsp;
        <FormattedMessage id="tld_block_note" />
      </p>
    </div>
  );
}

const openModal = url =>
  ModalService.show(modal => ({
    dialog: <ConfirmBlockDialog url={url} modal={modal} />
  }));

export default UrlOptionsPicker;
