import _ from "lodash";

export const VALIDATION_MAP = [
  {
    name: "showMessage",
    fields: ["message"]
  },
  {
    name: "showAdmin",
    fields: ["adminEmail", "adminPhone"]
  }
];

export const setValidation = (fields, fieldsToKeep) => {
  const fieldPairs = fields.map(({ name, maxLength, emailRegex }) => {
      if (emailRegex) {
        return [name, { maxLength, emailRegex }];
      }

      return [name, { maxLength }];
    }),
    validation = _.fromPairs(fieldPairs),
    filteredValidation = fieldsToKeep.map(field => {
      const isValueUndefined = !Object.values(validation[field]).filter(
        value => !!value
      ).length;

      if (isValueUndefined) return {};

      return {
        [field]: validation[field]
      };
    });

  return flattenArrayToObject(filteredValidation);
};

export const toggleToSaveProperty = (value, valueToSave) =>
  _.isUndefined(valueToSave) ? !value : !valueToSave;

const flattenArrayToObject = arr =>
  arr.reduce((prev, cur) => ({ ...prev, ...cur }), {});
