import React, { Fragment } from "react";

import {
  useAssignDeviceMenuItem,
  useMergeDeviceMenuItem
} from "../../../../components/devices/hooks";
import { DeviceDropdownMenu } from "../../../../components/devices/DeviceDropdownMenu";
import { NotMobile, Mobile } from "../../../../hocs/Responsive";
import { DeviceActionsToggle } from "../../../../components/devices/DeviceActionsDialog";

export const RequestedDevicesDropdownActions = ({ address, name, type }) => {
  const menuItems = [
    useAssignDeviceMenuItem(
      { identifiers: [address], name, type },
      "assign_to_group"
    ),
    useMergeDeviceMenuItem({ address, type, name })
  ];

  return (
    <Fragment>
      <Mobile>
        <DeviceActionsToggle
          deviceName={name}
          menuItems={menuItems}
          ariaDeviceName={name}
        />
      </Mobile>
      <NotMobile>
        <DeviceDropdownMenu menuItems={menuItems} deviceName={name} />
      </NotMobile>
    </Fragment>
  );
};
