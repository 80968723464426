export interface SuccessResult<T> {
  success: true;
  data: T;
}

interface FailureResult<T> {
  success: false;
  error: T & {
    code: number | string;
  };
}

export enum UpdateResponseStatus {
  SUCCESS = "success",
  PARTIAL_SUCCESS = "partial-success"
}

export type Result<S, F = object> = SuccessResult<S> | FailureResult<F>;

export function isFailure(
  result: SuccessResult<any> | FailureResult<any>
): result is FailureResult<any> {
  return !result.success;
}

export function success<T>(data?: T): SuccessResult<T> {
  return {
    success: true,
    data
  };
}

export function failure(code: number | string): FailureResult<any>;
export function failure<T extends {}>(
  code: number | string,
  meta: T
): FailureResult<T>;
export function failure(code, meta?) {
  return {
    success: false,
    error: { ...meta, code }
  };
}
