import _ from "lodash";

import { SERVICES } from "../../common/utils/permissions";
import {
  ADD_PROFILE,
  AddProfileAction,
  CHANGE_PROTECTION,
  CHANGE_SAFE_SEARCH_SERVICE,
  ChangeProtectionAction,
  ChangeSafeSearchServiceAction,
  CREATE_PROFILES_SUCCESS,
  CreateProfilesSuccessAction,
  GET_PROFILES_SUCCESS,
  GetProfilesSuccessAction,
  ProfilesSlice,
  ProfilesState,
  REMOVE_PROFILE,
  REMOVE_PROFILE_SUCCESS,
  RemoveProfileAction,
  RemoveProfileSuccessAction,
  RENAME_PROFILE_SUCCESS,
  RenameProfileSuccessAction,
  RESET_ALL_PROFILES,
  RESET_PROFILE,
  ResetProfileAction,
  ResetProfilesAction,
  SAVE_PROFILE_SUCCESS,
  SaveProfileSuccessAction,
  SBProfile,
  SET_INITIAL_PROFILES,
  SET_SELECTED_PROFILE,
  SetInitialProfilesAction,
  SetSelectedProfileAction,
  UpdateProfileIds
} from "./profiles.types";

import {
  GET_SETTINGS_SUCCESS,
  GetSettingsSuccess
} from "../settings/settings.types";
import { getCurrentProfiles } from "./profiles.selectors";
import { reducer as profileReducer } from "./profile.reducer";
import { addItemsToList, updateProfileIdsInList } from "./profiles.helpers";
import { UPDATE_PROFILE_IDS } from "./createProfiles.actions";

export const initialState: ProfilesState = {
  saved: {
    list: {},
    keys: []
  },
  changed: {
    list: {},
    keys: []
  },
  limit: null,
  selected: ""
};

type ActionTypes =
  | GetSettingsSuccess
  | SetInitialProfilesAction
  | AddProfileAction
  | RemoveProfileAction
  | SaveProfileSuccessAction
  | CreateProfilesSuccessAction
  | RemoveProfileSuccessAction
  | GetProfilesSuccessAction
  | ResetProfileAction
  | ResetProfilesAction
  | ChangeSafeSearchServiceAction
  | ChangeProtectionAction
  | RenameProfileSuccessAction
  | SetSelectedProfileAction
  | UpdateProfileIds;

export const reducer = (
  state = initialState,
  action: ActionTypes
): ProfilesState => {
  switch (action.type) {
    case GET_SETTINGS_SUCCESS: {
      const services = action.payload.services,
        sb = services.find(s => s.service === SERVICES.SB);

      return {
        ...state,
        limit: sb["max-profiles"]
      };
    }
    case SET_INITIAL_PROFILES: {
      const profiles = addItemsToList(action.payload);
      return {
        ...state,
        changed: profiles,
        selected: _.head(profiles.keys)
      };
    }
    case ADD_PROFILE: {
      return {
        ...state,
        changed: addItemsToList([action.payload], state.changed)
      };
    }
    case REMOVE_PROFILE: {
      const changed = {
        keys: _.without(state.changed.keys, action.payload),
        list: _.omit(state.changed.list, action.payload)
      };

      return {
        ...state,
        changed,
        selected:
          action.payload === state.selected
            ? _.head(changed.keys)
            : state.selected
      };
    }
    case SAVE_PROFILE_SUCCESS: {
      return {
        ...state,
        saved: getCurrentProfiles({ profiles: state }) // TODO: seems that it can "save" changes for all profiles not only those send to BE
      };
    }
    case CREATE_PROFILES_SUCCESS: {
      const defaultProfile = _.find(action.payload, ["default", true]);

      if (defaultProfile) {
        return {
          ...state,
          saved: addItemsToList(action.payload),
          changed: addItemsToList(action.payload),
          selected: defaultProfile.id
        };
      }

      return {
        ...state,
        saved: addItemsToList(action.payload, state.saved),
        changed: addItemsToList(action.payload, state.changed)
      };
    }
    case REMOVE_PROFILE_SUCCESS: {
      return {
        ...state,
        saved: {
          keys: _.without(state.saved.keys, action.payload.id),
          list: _.omit(state.saved.list, action.payload.id)
        }
      };
    }
    case GET_PROFILES_SUCCESS: {
      const profiles = addItemsToList(action.payload);
      return {
        ...state,
        saved: profiles,
        changed: _.cloneDeep(profiles),
        selected: _.head(profiles.keys)
      };
    }
    case RESET_PROFILE: {
      return {
        ...state,
        changed: {
          ...state.changed,
          list: {
            ...state.changed.list,
            [action.payload.id]: _.cloneDeep(
              state.saved.list[action.payload.id]
            )
          }
        }
      };
    }
    case RESET_ALL_PROFILES: {
      return {
        ...state,
        changed: _.cloneDeep(state.saved)
      };
    }
    case CHANGE_SAFE_SEARCH_SERVICE:
    case CHANGE_PROTECTION:
    case RENAME_PROFILE_SUCCESS: {
      const id = action.payload.profile;
      return {
        ...state,
        changed: changeProfile(state.changed, id, action)
      };
    }
    case SET_SELECTED_PROFILE: {
      return {
        ...state,
        selected: action.payload
      };
    }
    case UPDATE_PROFILE_IDS: {
      return {
        ...state,
        saved: updateProfileIdsInList(state.saved, action.payload.map),
        changed: updateProfileIdsInList(state.changed, action.payload.map),
        selected: action.payload.map[state.selected] || state.selected
      };
    }
    default: {
      return state;
    }
  }
};

const changeProfile = (
  slice: ProfilesSlice,
  id: string,
  action
): ProfilesSlice => ({
  ...slice,
  list: {
    ...slice.list,
    [id]: profileReducer(slice.list[id], action) as SBProfile
  }
});
