import { loaderShow, loaderHide } from "../components/loader/loader.actions";

export const loaderMiddleware = ({ dispatch }) => {
  return next => {
    return (action, { showLoader = true } = { showLoader: true }) => {
      const nextAction = next(action);
      const isPromise = nextAction && !!nextAction.then;

      if (isPromise && showLoader) {
        dispatch(loaderShow());
        nextAction.then(
          () => dispatch(loaderHide()),
          () => dispatch(loaderHide())
        );
      }

      return nextAction;
    };
  };
};
