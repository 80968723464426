import React from "react";
import PropTypes from "prop-types";

/**
 * Wrapper allows to prevent click propagation,
 * but triggers click on html element, so document level handlers work.
 *
 * Consider using it only if changing behavior of the wrapped component
 * is not possible or highly undesirable.s
 */
export const ClickAbsorber = ({ children, ...props }) => (
  <div
    {...props}
    onClick={event => {
      event.stopPropagation();
      event.preventDefault();
      document.getElementsByTagName("html")[0].click();
    }}
  >
    {children}
  </div>
);

ClickAbsorber.propTypes = {
  children: PropTypes.node.isRequired
};
