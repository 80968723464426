import React, { Fragment, isValidElement, useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import { useIntlTranslate } from "../../hooks";
import { Icon } from "../icon/Icon";

import "./MobileMenu.scss";

const getOpenedMenuId = (menuItems, activeItem) => {
  const openSubMenu = _.find(menuItems, item =>
    _.some(item.subMenu, { url: activeItem })
  );

  return _.isEmpty(openSubMenu) ? "" : openSubMenu.id;
};

const MenuContentItem = ({ item }) => {
  return <li data-id={item.id}>{item.staticContent}</li>;
};

const MenuActionItem = ({ item }) => {
  return (
    <li data-id={item.id}>
      <UnstyledButton
        onClick={item.action}
        className={classnames("menu__action-item", item.className)}
      >
        {item.text && <FormattedMessage id={item.text} />}
        {item.content}
      </UnstyledButton>
    </li>
  );
};

const MenuModal = ({ item, onToggleMenu }) => {
  return (
    <li data-id={item.id} onClick={onToggleMenu}>
      {item.modal}
    </li>
  );
};

const MenuLinkItem = ({ item, activeItem }) => {
  return (
    <li data-id={item.id}>
      {isValidElement(item.url) ? (
        item.url
      ) : (
        <Link
          className={classnames("menu__item", {
            "menu__item--active": item.url === activeItem
          })}
          to={item.url}
        >
          <FormattedMessage id={item.text} />
        </Link>
      )}
    </li>
  );
};

const SubMenuItem = ({
  item,
  activeItem,
  openMenuId,
  onToggleSubMenu,
  onToggleMenu
}) => {
  const openStatus = openMenuId === item.id;

  const subMenuItems = renderItems({
    items: item.subMenu,
    activeItem,
    openMenuId,
    onToggleSubMenu,
    onToggleMenu
  });

  if (item.id === "userMenu") {
    return (
      <li>
        <div
          className={classnames("menu__user-menu", {
            "menu__user-menu--open": openStatus
          })}
        >
          <div className="account-info">
            <div>
              <FormattedMessage id={"account_name"} />
            </div>
            <div className="account-info__name">{item.subscriberName}</div>
          </div>
          <UnstyledButton
            className="toggle-btn"
            onClick={() => onToggleSubMenu(item.id)}
          >
            {openStatus ? (
              <Icon icon="fas fa-caret-up" />
            ) : (
              <Icon icon="fas fa-caret-down" />
            )}
          </UnstyledButton>
        </div>
        {subMenuItems}
      </li>
    );
  }

  return (
    <li>
      <UnstyledButton
        className={classnames("menu__sub-menu", {
          "menu__sub-menu--open": openStatus
        })}
        onClick={() => onToggleSubMenu(item.id)}
      >
        <FormattedMessage id={item.text} />
        {openStatus ? (
          <Icon icon="far fa-chevron-up" />
        ) : (
          <Icon icon="far fa-chevron-down" />
        )}
      </UnstyledButton>
      {subMenuItems}
    </li>
  );
};

function renderItems({
  items,
  activeItem,
  openMenuId,
  onToggleSubMenu,
  onToggleMenu,
  ...rest
}) {
  return (
    <ul {...rest}>
      {_.map(items, (item, key) => (
        <Fragment key={key}>
          {item.id === "divider" && <li className="divider" />}
          {item.subMenu && (
            <SubMenuItem
              item={item}
              activeItem={activeItem}
              openMenuId={openMenuId}
              onToggleSubMenu={onToggleSubMenu}
              onToggleMenu={onToggleMenu}
            />
          )}
          {item.staticContent && <MenuContentItem item={item} />}
          {item.action && <MenuActionItem item={item} />}
          {item.modal && <MenuModal item={item} onToggleMenu={onToggleMenu} />}
          {item.id !== "divider" &&
            !item.subMenu &&
            !item.action &&
            !item.staticContent &&
            !item.modal && <MenuLinkItem item={item} activeItem={activeItem} />}
        </Fragment>
      ))}
    </ul>
  );
}

export const MobileMenuContent = ({ items, activeItem, onToggleMenu }) => {
  const translate = useIntlTranslate();

  const [openMenuId, setOpenMenuId] = useState(() => {
    return getOpenedMenuId(items, activeItem);
  });

  const handleToggleSubMenu = itemId => {
    setOpenMenuId(openMenuId === itemId ? "" : itemId);
  };

  return renderItems({
    "aria-label": translate("aria_primary_navigation"),
    items,
    activeItem,
    openMenuId,
    onToggleSubMenu: handleToggleSubMenu,
    onToggleMenu
  });
};
