import { FETCH_THREATS_SUCCESS } from "./threats.actions";
import { normalize } from "../../utils/normalize";

/*
interface Threat {
  id: number;
  name: string;
  description: string;
  source: string;
  localized: boolean;
  // TODO: Describing correspondence between numbers and textual severity levels:
  //  0 - without severity 1 - Low to 2 - medium 3 - high and 4 - critical
  severity: number;
}

interface ThreatsList {
  list: {[id: number]: Threat};
  keys: number[];
}

interface ThreatsError {
  // TODO: establish a common shape for errors
  error: {
    // Translation key
    key: string;
  };
}

type ThreatsState = ThreatsList | ThreatsError
 */

const initialState = normalize();

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_THREATS_SUCCESS:
      return normalize(action.payload);
    default:
      return state;
  }
};
