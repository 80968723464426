import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { MobileMenu } from "../../mobileMenu/MobileMenu";
import { MobileMenuContent } from "../../mobileMenu/MobileMenuContent";
import { useMenuItems } from "./useMenuItems";

export const MobileMenuContainer = ({
  subscriberName,
  portalName,
  isSbEnabled,
  updateWebFilter,
  logout
}) => {
  const { pathname: activeTab } = useLocation();

  const menuItems = useMenuItems({
    subscriberName,
    portalName,
    isSbEnabled,
    updateWebFilter,
    logout
  });

  return (
    <MobileMenu className="mobile-menu" key={activeTab}>
      {({ onToggleMenu }) => (
        <MobileMenuContent
          items={menuItems}
          activeItem={activeTab}
          onToggleMenu={onToggleMenu}
        />
      )}
    </MobileMenu>
  );
};

MobileMenuContainer.propTypes = {
  subscriberName: PropTypes.string.isRequired,
  portalName: PropTypes.string,
  isSbEnabled: PropTypes.bool.isRequired,
  updateWebFilter: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};
