import {
  DevicesActionTypes,
  EDIT_DEVICE_SUCCESS,
  EDIT_ROAMING_DEVICE_SUCCESS,
  FETCH_ALL_DEVICES,
  FETCH_ALL_DEVICES_FAILURE,
  FETCH_ALL_DEVICES_SUCCESS,
  FETCH_DISCOVERED_SUCCESS,
  FETCH_INFECTED_SUCCESS,
  FETCH_LINES_SUCCESS,
  FETCH_REQUESTS_NAME_SUCCESS,
  MERGE_DEVICE_SUCCESS,
  REMOVE_DEVICE_SUCCESS,
  REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS,
  SAVE_DEVICE_SUCCESS,
  FETCH_DISCOVERED,
  FETCH_INFECTED,
  FETCH_LINES,
  FETCH_REQUESTS_NAME,
  FETCH_DISCOVERED_FAILURE,
  FETCH_INFECTED_FAILURE,
  FETCH_REQUESTS_NAME_FAILURE,
  FETCH_LINES_FAILURE,
  FETCH_ROAMING_LIMIT_SUCCESS
} from "./devices.types";
import {
  RENAME_PROFILE_SUCCESS,
  REMOVE_PROFILE_SUCCESS
} from "../profiles/profiles.types";
import { withLoadingState } from "../shared";
import { logicalDevicesReducer } from "./logical.devices.reducer";

const makeListReducer = successAction => (state, action) =>
  successAction === action.type
    ? {
        ...state,
        list: action.payload
      }
    : state;

const discoveredDevicesReducer = withLoadingState({
  loadActionType: FETCH_DISCOVERED,
  successActionType: FETCH_DISCOVERED_SUCCESS,
  failureActionType: FETCH_DISCOVERED_FAILURE
})(makeListReducer(FETCH_DISCOVERED_SUCCESS));

const infectedDevicesReducer = withLoadingState({
  loadActionType: FETCH_INFECTED,
  successActionType: FETCH_INFECTED_SUCCESS,
  failureActionType: FETCH_INFECTED_FAILURE
})(makeListReducer(FETCH_INFECTED_SUCCESS));

const withNameDevicesReducer = withLoadingState({
  loadActionType: FETCH_REQUESTS_NAME,
  successActionType: FETCH_REQUESTS_NAME_SUCCESS,
  failureActionType: FETCH_REQUESTS_NAME_FAILURE
})(makeListReducer(FETCH_REQUESTS_NAME_SUCCESS));

const linesDevicesReducer = withLoadingState({
  loadActionType: FETCH_LINES,
  successActionType: FETCH_LINES_SUCCESS,
  failureActionType: FETCH_LINES_FAILURE
})(makeListReducer(FETCH_LINES_SUCCESS));

interface DevicesState {
  all: ReturnType<typeof logicalDevicesReducer>;
  limit?: number;
  roamingDevicesLimit?: number;
  discovered: ReturnType<typeof discoveredDevicesReducer>;
  infected: ReturnType<typeof infectedDevicesReducer>;
  withName: ReturnType<typeof withNameDevicesReducer>;
  lines: ReturnType<typeof linesDevicesReducer>;
}

const initialState: DevicesState = {
  all: logicalDevicesReducer(undefined, {}),
  discovered: discoveredDevicesReducer(undefined, {}),
  infected: infectedDevicesReducer(undefined, {}),
  withName: withNameDevicesReducer(undefined, {}),
  lines: linesDevicesReducer(undefined, {})
};

export default (
  state = initialState,
  action: DevicesActionTypes
): DevicesState => {
  switch (action.type) {
    case FETCH_DISCOVERED:
    case FETCH_DISCOVERED_FAILURE:
    case FETCH_DISCOVERED_SUCCESS: {
      return {
        ...state,
        discovered: discoveredDevicesReducer(state.discovered, action)
      };
    }
    case FETCH_INFECTED:
    case FETCH_INFECTED_FAILURE:
    case FETCH_INFECTED_SUCCESS: {
      return {
        ...state,
        infected: infectedDevicesReducer(state.infected, action)
      };
    }
    case FETCH_REQUESTS_NAME:
    case FETCH_REQUESTS_NAME_FAILURE:
    case FETCH_REQUESTS_NAME_SUCCESS: {
      return {
        ...state,
        withName: withNameDevicesReducer(state.withName, action)
      };
    }
    case FETCH_LINES:
    case FETCH_LINES_FAILURE:
    case FETCH_LINES_SUCCESS: {
      return {
        ...state,
        lines: linesDevicesReducer(state.lines, action)
      };
    }
    case FETCH_ALL_DEVICES_SUCCESS: {
      return {
        ...state,
        limit: action.payload.limit,
        all: logicalDevicesReducer(state.all, action)
      };
    }
    case FETCH_ROAMING_LIMIT_SUCCESS: {
      return {
        ...state,
        roamingDevicesLimit: action.payload.limit
      };
    }
    case FETCH_ALL_DEVICES:
    case FETCH_ALL_DEVICES_FAILURE:
    case SAVE_DEVICE_SUCCESS:
    case REMOVE_DEVICE_SUCCESS:
    case EDIT_DEVICE_SUCCESS:
    case REMOVE_PROFILE_SUCCESS:
    case MERGE_DEVICE_SUCCESS:
    case EDIT_ROAMING_DEVICE_SUCCESS:
    case RENAME_PROFILE_SUCCESS:
    case REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS: {
      return {
        ...state,
        all: logicalDevicesReducer(state.all, action)
      };
    }
    default: {
      return state;
    }
  }
};
