import _ from "lodash";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { Dropdown } from "../../../../components/dropdown/Dropdown";
import { ClickAbsorber } from "../../../../components/clickAbsorber/ClickAbsorber";
import { Icon } from "../../../../components/icon/Icon";
import { useIntlTranslate } from "../../../../hooks";

import "./ProfileTab.scss";

const ProfileTabDropdown = ({ menu }) => {
  const translate = useIntlTranslate();

  return (
    <ClickAbsorber className="profile-tab__dropdown">
      <Dropdown
        toggleButton={
          <div className="profile-tab__toggle-actions">
            <Icon icon="fas fa-ellipsis-v" />
          </div>
        }
        items={menu}
        label={translate("aria_actions")}
        hiddenLabel
        disableHorizontalPosition
      />
    </ClickAbsorber>
  );
};

export const ProfileTab = ({ to, as: Component, ...props }) => {
  return (
    <Component
      to={to}
      className="profile-tab"
      activeClassName="profile-tab--active"
    >
      <ProfileTabContent {...props} />
    </Component>
  );
};

ProfileTab.propTypes = {
  // type: // TODO: rid off,
  menu: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  to: PropTypes.string
};

// Keep it synced with scss
ProfileTab.activeClass = "profile-tab--active";

export const ProfileTabContent = ({
  type = "custom",
  title: profileName,
  menu,
  description
}) => {
  return (
    <Fragment>
      <div className="profile-tab__content">
        <h4 className="profile-tab__title">
          {type !== "custom" ? (
            <FormattedMessage id={profileName} defaultMessage={profileName} />
          ) : (
            profileName
          )}
        </h4>
        <div className="profile-tab__description">
          {React.isValidElement(description) ? (
            description
          ) : (
            <FormattedMessage id={`${type}_description`} defaultMessage={""} />
          )}
        </div>
      </div>
      {!_.isEmpty(menu) && <ProfileTabDropdown menu={menu} />}
    </Fragment>
  );
};
