import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import TimePickerComponent from "./TimePickerComponent";

function prependZeroIfNeeded(timeStr) {
  if (timeStr.length === 1) {
    return "0" + timeStr;
  }

  return timeStr;
}

export function parse(time, timeFormat24h) {
  const [hours, minutes] = time.split(":");
  const H = Number(hours) % 24;

  if (!timeFormat24h) {
    const h = H % 12 || 12;

    return {
      hours: prependZeroIfNeeded(h.toString()),
      minutes,
      ampm: H < 12 ? "am" : "pm"
    };
  }

  return {
    hours: prependZeroIfNeeded(H.toString()),
    minutes
  };
}

// this function should handle hour converting to 24h format
// 12 am goes to 0 hour on 24h format
// for pm values it just increases value with 12 hours
export function convertTo24h({ hours, ampm }) {
  let adjustedHours = Number(hours);

  if (ampm === "am" && adjustedHours === 12) {
    adjustedHours = 0;
  } else if (ampm === "pm" && adjustedHours < 12) {
    adjustedHours += 12;
  }

  return adjustedHours.toString();
}

const validation12 = {
  hours: {
    pattern: /^((0?[0-9])|([01][0-2]))$/
  },
  minutes: {
    pattern: /^([0-5]?[0-9])$/
  },
  ampm: {
    pattern: /^(a|p)m$/i
  }
};

const validation24 = {
  hours: {
    pattern: /^(([0-1]?[0-9])|(2[0-3]))$/
  },
  minutes: {
    pattern: /^([0-5]?[0-9])$/
  }
};

class TimePicker extends Component {
  render() {
    const { timeFormat24h, value, ariaLabelledBy } = this.props;
    const { hours, minutes, ampm } = parse(value, timeFormat24h);

    return (
      <TimePickerComponent
        timeFormat24h={timeFormat24h}
        hours={hours}
        minutes={minutes}
        ampm={ampm}
        onInputChange={this.handleInputChange}
        preValidate={this.preValidate}
        ariaLabelledBy={ariaLabelledBy}
      />
    );
  }

  handleInputChange = (fieldName, fieldValue) => {
    const { timeFormat24h, value, onChange } = this.props;
    const parsedTime = _.assignIn(parse(value, timeFormat24h), {
      [fieldName]: fieldValue
    });

    if (!timeFormat24h) {
      parsedTime.hours = convertTo24h(parsedTime);
    }

    onChange(
      [parsedTime.hours, parsedTime.minutes].map(prependZeroIfNeeded).join(":")
    );
  };

  preValidate = (fieldName, value) => {
    return this.props.timeFormat24h
      ? validation24[fieldName].pattern.test(value)
      : validation12[fieldName].pattern.test(value);
  };
}

export function valuePropValidator(props, propName, componentName) {
  const value = props[propName];

  if (!_.isString(value)) {
    return new Error(
      `Invalid prop '${propName}' supplied to '${componentName}'. Value should be string`
    );
  }

  const [hours, minutes] = value.split(":");

  // this value is considered as a valid
  if (value === "24:00") {
    return;
  }

  // component accepts only 24h format because onChange is fired with this format
  if (
    !validation24.hours.pattern.test(hours) ||
    !validation24.minutes.pattern.test(minutes)
  ) {
    return new Error(
      `Invalid prop '${propName}' supplied to '${componentName}'. Value should be date string in HH:mm format`
    );
  }
}

TimePicker.propTypes = {
  timeFormat24h: PropTypes.bool,
  onChange: PropTypes.func,
  value: valuePropValidator,
  ariaLabelledBy: PropTypes.string
};

export default TimePicker;
