import React, { memo } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedRelativeTime } from "react-intl";
import { useSelector } from "react-redux";
import { selectUnit } from "@formatjs/intl-utils";
import classnames from "classnames";

import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import { Icon } from "../../components/icon/Icon";
import { getLastRequests } from "../../store/devices/devices.selectors";
import { getDefaultProfile } from "../../store/profiles/profiles.selectors";
import { useAssignAction } from "../../components/devices/hooks";
import { Tooltip } from "../../components/tooltip/Tooltip";
import { Mobile, NotMobile } from "../../hocs/Responsive";
import { isAssignActionDisabled } from "../../store/profiles/profiles.selectors";
import { useIntlTranslate } from "../../hooks";

import "./NewRequests.scss";

const RequestTime = ({ date }) => {
  const { value, unit } = selectUnit(date);

  return <FormattedRelativeTime value={value} unit={unit} numeric="auto" />;
};

export const NewRequestsPanel = () => {
  const translate = useIntlTranslate();
  const { requests, totalLength } = useSelector(getLastRequests, _.isEqual);
  const isAssignDisabled = useSelector(isAssignActionDisabled);
  const defaultProfileId = useSelector(state => getDefaultProfile(state).id);

  return (
    <div className="new-requests">
      <div className="new-requests__header">
        <h4 className="new-requests__header-title">
          <FormattedMessage id="new_requests" />
        </h4>
        <span className="new-requests__header-count">({totalLength})</span>
        <Link
          className="new-requests__header-link"
          to={`/settings/devices/${defaultProfileId}/new-requests`}
          aria-label={translate("aria_see_all_new_requests")}
        >
          <FormattedMessage id="see_all" />
        </Link>
      </div>
      <NewRequestsList items={requests} isAssignDisabled={isAssignDisabled} />
    </div>
  );
};

export const NewRequests = memo(NewRequestsPanel);

const NewRequestsList = ({ items, isAssignDisabled }) => (
  <div className="new-requests__list">
    {_.map(items, (item, index) => (
      <NewRequestsListItem
        key={`${item.name}-${index}`}
        item={item}
        disabled={isAssignDisabled}
      />
    ))}
  </div>
);

const NewRequestsListItem = ({
  item: { name, lastseen, address },
  disabled
}) => {
  const translate = useIntlTranslate();

  const assign = useAssignAction({ identifiers: [address] });

  return (
    <UnstyledButton
      className={classnames("new-requests__list-item", {
        "new-requests__list-item--disabled": disabled
      })}
      onClick={!disabled ? assign : undefined}
      aria-label={translate("aria_assign_device_to_group", {
        deviceName: name
      })}
    >
      <span className="new-requests__list-item__icon-container">
        <NotMobile>
          <Tooltip
            theme="dark"
            content={<FormattedMessage id="assign_to_group" />}
          >
            <Icon icon="far fa-plus-circle" />
          </Tooltip>
        </NotMobile>
        <Mobile>
          <Icon icon="far fa-plus-circle" />
        </Mobile>
      </span>
      <div className="new-requests__list-item__info">
        <div className="new-requests__list-item__name">{name}</div>
        <div className="new-requests__list-item__date">
          <RequestTime date={lastseen} />
        </div>
      </div>
    </UnstyledButton>
  );
};
