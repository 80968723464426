import React, { Fragment, useMemo } from "react";

import { Select } from "../../../components/select";
import { FREQUENCIES } from "../../../store/attributes/attributes.constants";
import { Mobile, NotMobile } from "../../../hocs/Responsive";
import NativeSelect from "../../../components/select/NativeSelect";
import { useIntlTranslate } from "../../../hooks";

const frequencyOptions = [
  {
    name: "off_1",
    value: FREQUENCIES.NEVER
  },
  {
    name: "day",
    value: FREQUENCIES.DAILY
  },
  {
    name: "week",
    value: FREQUENCIES.WEEKLY
  },
  {
    name: "month",
    value: FREQUENCIES.MONTHLY
  }
];

const FREQUENCY = "frequency";

export const ReportFrequencyPicker = ({ selected, onChange }) => {
  const translate = useIntlTranslate();

  const items = useMemo(() => {
    return frequencyOptions.map(frequency => ({
      value: frequency.value,
      content: translate(frequency.name)
    }));
  }, [translate]);

  return (
    <Fragment>
      <NotMobile>
        <Select
          items={items}
          selected={selected}
          onChange={onChange}
          label={translate(FREQUENCY)}
        />
      </NotMobile>
      <Mobile>
        <NativeSelect
          id="report-frequency-picker-select"
          options={frequencyOptions}
          selected={selected}
          toggleView="input"
          formatOption={id => translate(id)}
          formatSelection={id => translate(id)}
          onChange={onChange}
          label={FREQUENCY}
        />
      </Mobile>
    </Fragment>
  );
};
