// Case insensitive, mixed separators are restricted
const macFormats = [
  /^([0-9A-F]{2}:){5}[0-9A-F]{2}$/i, // AA:BB:CC:DD:ee:Ff
  /^([0-9A-F]{2}-){5}[0-9A-F]{2}$/i // AA-BB-CC-DD-ee-Ff
];

export function isValidMac(mac: string): boolean {
  return macFormats.some(regex => regex.test(mac));
}

export function macToOui(mac: string): string {
  return mac
    .replace(/[-:]/g, "")
    .substr(0, 6)
    .toLowerCase();
}

export function normalizeMac(mac: string): string {
  return mac.replace(/[-:]/g, ":").toLowerCase();
}
