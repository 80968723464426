import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog
} from "../../../modal";
import { Checkbox } from "../../../checkbox/Checkbox";

import "./HistoryClearDialog.scss";

const CLASS_NAME = "clear-data-dialog";
const METRICS = {
  dns: {
    label: "internet_activity",
    value: "dns"
  },
  content: {
    label: "blocked_activity",
    value: "content"
  },
  safety: {
    label: "malware_and_phishing_blocked",
    value: "safety"
  },
  botnet: {
    label: "botnet_blocked",
    value: "botnet"
  }
};

class HistoryClearDialog extends Component {
  state = { metrics: _.keys(METRICS) };

  render() {
    const { modal } = this.props;

    return (
      <ModalDialog
        className={CLASS_NAME}
        submitHandler={this.onSubmit}
        rejectHandler={() => modal.reject()}
        disabled={this.isSubmitDisabled()}
      >
        <CommonModalHeader>
          <FormattedMessage id={"clear_usage_data"} />
        </CommonModalHeader>
        <ModalDialog.Body>
          <p>
            <FormattedMessage id={"select_data_to_remove"} />
          </p>
          {_.map(METRICS, ({ value, label }, key) => (
            <Checkbox
              key={key}
              id={value}
              name={value}
              isChecked={this.isChecked(value)}
              onChange={({ currentTarget: { name } }) =>
                this.onCheckboxChange(name)
              }
            >
              <FormattedMessage id={label} />
            </Checkbox>
          ))}
        </ModalDialog.Body>
        <CommonModalFooter
          submitMessage={<FormattedMessage id="clear" />}
          submitButtonType="destructive"
        />
      </ModalDialog>
    );
  }

  onCheckboxChange(metric) {
    const { metrics } = this.state;
    let newMetrics = [];

    if (_.includes(metrics, metric)) {
      newMetrics = _.without(metrics, metric);
    } else {
      newMetrics = _.concat(metrics, metric);
    }

    this.setState({ metrics: newMetrics });
  }

  isSubmitDisabled() {
    return _.isEmpty(this.state.metrics);
  }

  isChecked(metric) {
    return _.includes(this.state.metrics, metric);
  }

  onSubmit = () => {
    if (this.isSubmitDisabled()) {
      return;
    }

    return this.props.modal.resolve(this.state.metrics);
  };
}

export default HistoryClearDialog;
