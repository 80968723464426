import React, { Component, useCallback, useReducer } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import scrollIntoView from "scroll-into-view-if-needed";
import classnames from "classnames";

import MenuItem from "./MenuItem";
import { useDesktopMenuItems } from "../menu.config";

import { AddDevice } from "../../../pages/devices/components/addDevice/AddDevice";
import { Desktop, sizes, TabletMobile } from "../../../hocs/Responsive";
import { SubMenuItem } from "./SubMenuItem";
import { isMultipleUserLevel } from "../../../store/account";
import { useIntlTranslate } from "../../../hooks";

import "./HorizontalMenu.scss";

export const horizontalMenuClassName = "hm__wrapper";
export const horizontalMenuPortalClassName = "hm__page-name__portal";

const openedDropdownReducer = (state, item) => {
  if (item.open) {
    return item.id;
  }

  if (state === item.id) {
    return null;
  }

  return state;
};

export const HorizontalMenuItems = ({ items, pathname }) => {
  const [openedDropdownId, dispatch] = useReducer(openedDropdownReducer, null);
  const translate = useIntlTranslate();
  const ariaLabel = translate("aria_primary_navigation");

  const changeOpenedSubMenuDropdown = useCallback((id, open) => {
    dispatch({ id, open });
  }, []);

  return (
    <nav aria-label={ariaLabel}>
      <ul className="hm" role="menubar" aria-label={ariaLabel}>
        {_.map(items, (item, key) => (
          <MenuItem
            key={key}
            item={item}
            currentPathname={pathname}
            changeOpenedSubMenuDropdown={changeOpenedSubMenuDropdown}
            isSubMenuOpened={openedDropdownId === item.id}
          />
        ))}
      </ul>
    </nav>
  );
};

export const shouldShowSubMenu = (url, configUrl) => url.includes(configUrl);

function isSubMenuVisible(items, pathname) {
  return !!_.chain(items)
    .filter("subMenu")
    .map(item => _.map(item.subMenu, ({ url, baseUrl }) => baseUrl || url))
    .flatten()
    .find(url => shouldShowSubMenu(pathname, url))
    .value();
}

function getSubMenuItems(items, pathname) {
  const itemsWithSubmenu = _.filter(items, "subMenu");
  const currentMenuItem = _.find(itemsWithSubmenu, item => {
    const paths = _.map(item.subMenu, ({ url, baseUrl }) => baseUrl || url);

    return _.find(paths, url => shouldShowSubMenu(pathname, url));
  });

  return currentMenuItem.subMenu;
}

const HorizontalMenuSubMenu = ({ items, pathname }) => {
  const subMenuItems = getSubMenuItems(items, pathname);

  return (
    <div className="hm__submenu" data-testid="hm-submenu">
      <div className="hm__container">
        <ul className="hm">
          {_.map(subMenuItems, (item, key) => (
            <SubMenuItem item={item} key={key} pathname={pathname} />
          ))}
        </ul>
      </div>
    </div>
  );
};

class HorizontalMenu extends Component {
  componentDidMount() {
    this.scrollToActive();
  }

  componentDidUpdate() {
    if (window.innerWidth <= sizes.mobileMax) {
      this.scrollToActive();
    }
  }

  get activeOption() {
    return this.hmRef.querySelector(".hm__item--active");
  }

  scrollToActive = () =>
    this.activeOption &&
    scrollIntoView(this.activeOption, { inline: "center" });

  render() {
    const { isMultiple, menuItems, location } = this.props;

    const shouldRenderSubMenu = isSubMenuVisible(menuItems, location.pathname);

    const portalClasses = classnames(horizontalMenuPortalClassName, {
      [`${horizontalMenuPortalClassName}--with-sub-menu`]: shouldRenderSubMenu
    });

    return (
      <div className={horizontalMenuClassName} ref={ref => (this.hmRef = ref)}>
        <TabletMobile>
          <div className={portalClasses} />
        </TabletMobile>
        <Desktop>
          <div className={"hm__primary"}>
            <div className={"hm__container"}>
              <HorizontalMenuItems
                items={menuItems}
                pathname={location.pathname}
              />
              {isMultiple && <AddDevice className={"hm__right"} />}
            </div>
          </div>
        </Desktop>
        {shouldRenderSubMenu && (
          <HorizontalMenuSubMenu
            pathname={location.pathname}
            items={menuItems}
          />
        )}
      </div>
    );
  }
}

const ConnectHorizontalMenu = () => {
  const isMultiple = useSelector(isMultipleUserLevel);
  const menuItems = useDesktopMenuItems();
  const location = useLocation();

  return (
    <HorizontalMenu
      location={location}
      isMultiple={isMultiple}
      menuItems={menuItems}
    />
  );
};

export default ConnectHorizontalMenu;
