import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";

import Notificator from "../../components/notification/notification.actions";
import { ReportKeys, ReportTypes } from "../../helpers/reports.helper.types";
import { ReportsSection } from "./reports.types";

//This mapping needs for consistency with SC reports
//it will help us to move some logic to /cdk
const mapDataToReportKeys = (data, type) =>
  _.map(data, ({ value, time }) => ({
    [ReportKeys[type]]: value,
    time
  }));

const mergeObjects = data =>
  _.reduce(
    data,
    (memo, obj) => {
      // This part is for solving the issue EPORTAL-8820.
      // It's happening because of more number of proxy-transactions than dns-queries.
      // The current solution is just to add the value of blocked requests to activity,
      // we able to do it because we show the approximate value an activity

      if (obj[ReportTypes.Webfilter]) {
        memo[ReportTypes.Activity] = _.sum([
          memo[ReportTypes.Activity],
          obj[ReportTypes.Webfilter]
        ]);
      }

      return { ...memo, ...obj };
    },
    {}
  );

export const mergeData = data =>
  _.chain(data)
    .flatMap(mapDataToReportKeys)
    .groupBy("time")
    .map(mergeObjects)
    .value();

export const load = (reports, options = {}) => async (
  dispatch,
  getState,
  { api }
) => {
  const { subscriberInfo } = getState();
  const { period, type, profile, withGranularity, limit } = options;

  try {
    const data = await Promise.all(
      reports.map(key =>
        api.ssm.series.getReportData({
          key,
          id: subscriberInfo.id,
          type,
          profile,
          period,
          withGranularity,
          limit
        })
      )
    );

    const result = _.zipObject(reports, data);

    type === ReportsSection.Barchart
      ? dispatch(loadSuccess(mergeData(result), type))
      : dispatch(loadSuccess(result, type));
  } catch (e) {
    dispatch(
      Notificator.error(<FormattedMessage id={"failed_load_reports_data"} />)
    );
    dispatch(loadFailure(e));
  }
};

export const LOAD_SUCCESS = "[REPORTS] LOAD_SUCCESS";
export const LOAD_FAILURE = "[REPORTS] LOAD_FAILURE";
export const CHANGE_PERIOD = "[REPORTS] CHANGE_PERIOD";
export const CHANGE_PROFILE = "[REPORTS] CHANGE_PROFILE";
export const APPLY_ZOOM = "[REPORTS] APPLY ZOOM";
export const CANCEL_ZOOM = "[REPORTS] CANCEL ZOOM";

export const loadSuccess = (data, type) => ({
  type: LOAD_SUCCESS,
  payload: { data, type }
});

export const loadFailure = error => ({
  type: LOAD_FAILURE,
  payload: error
});

export const changePeriod = period => ({
  type: CHANGE_PERIOD,
  payload: { period }
});

export const changeProfile = profile => ({
  type: CHANGE_PROFILE,
  payload: { profile }
});

export const applyZoom = (start, end) => ({
  type: APPLY_ZOOM,
  payload: { zoom: { visible: true, start, end } }
});

export const cancelZoom = () => ({
  type: CANCEL_ZOOM
});
