import React, { Fragment, FC } from "react";
import { FormattedMessage } from "react-intl";

import { HelpLink } from "../../../../components/helpLink/HelpLink";
import { SeverityIndicator } from "../../../../components/severityIndicator/SeverityIndicator";
import { Tooltip } from "../../../../components/tooltip/Tooltip";

interface SeverityLevelProps {
  threatId: string;
  severityIndex: number;
  threatTooltip?: string;
  threat?: string;
}

export const SeverityLevel: FC<SeverityLevelProps> = ({
  threatId: id,
  severityIndex: severity,
  threatTooltip,
  threat
}) => {
  if (!threat) return <span>{id}</span>;

  return (
    <SeverityIndicator severity={severity}>
      <Tooltip
        content={
          <span>
            {severity !== 0 && (
              <Fragment>
                <FormattedMessage
                  id={`${severity}_severity_level_description`}
                />
                {". "}
              </Fragment>
            )}
            {threatTooltip}
          </span>
        }
      >
        <HelpLink section={`threat-list-${id}`}>{threat}</HelpLink>
      </Tooltip>
    </SeverityIndicator>
  );
};
