import _ from "lodash";
import { ACCESS_TYPES, PROFILES, SUPPORT, SERVICES } from "./directory";

export function isUserAllowed({ app, access }) {
  const accessObj = typeof access === "string" ? ACCESS_TYPES[access] : access;

  return accessObj && _.includes(accessObj.apps, app);
}

export const isUserMultiple = state => {
  const access = state.subscriberInfo.mode;
  const accessObj = typeof access === "string" ? ACCESS_TYPES[access] : access;

  return accessObj && accessObj.profilesAllowed === PROFILES.MULTIPLE;
};

function getServiceSupport(settings, name) {
  const service = SUPPORT[name];

  return {
    enabled: settings.enabled,
    single: settings[service[PROFILES.SINGLE]],
    multiple: settings[service[PROFILES.MULTIPLE]]
  };
}

export function getSbSupport(settings) {
  return getServiceSupport(settings, SERVICES.SB);
}

export function getPiSupport(settings) {
  return getServiceSupport(settings, SERVICES.PI);
}

export function getSsSupportForApp(settings, app) {
  const ss = SUPPORT[SERVICES.SS][app],
    none = settings[ss[PROFILES.NONE]],
    single = settings[ss[PROFILES.SINGLE]],
    multiple = settings[ss[PROFILES.MULTIPLE]];

  return {
    enabled: none || single || multiple,
    none,
    single,
    multiple
  };
}

export function getServices({ access, single }) {
  const services =
    (ACCESS_TYPES[access] && ACCESS_TYPES[access].services) || {};

  return single && services[PROFILES.SINGLE]
    ? services[PROFILES.SINGLE]
    : services[PROFILES.MULTIPLE] ||
        services[PROFILES.SINGLE] ||
        services[PROFILES.NONE];
}

export * from "./directory";
