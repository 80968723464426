import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./Icon.scss";

/**
 * This component represents font-awesome icon.
 */

export const Icon = ({ icon, passive = true, ...rest }) => (
  <i
    className={classNames("icon", icon, { "icon--interactive": !passive })}
    {...rest}
  />
);

Icon.propTypes = {
  icon: PropTypes.string,
  passive: PropTypes.bool // determines if pointer events on icon are enabled (used for hover in tooltip)
};
