// Example of practice that react does not encouraged
// https://reactjs.org/docs/refs-and-the-dom.html#when-to-use-refs
export class ModalService {
  static setElement(ref) {
    this.ref = ref;
  }

  static show(getOptions) {
    return new Promise((resolve, reject) => {
      this.ref.current.show(getOptions(this.ref.current), resolve, reject); // a bit messy
    });
  }

  static modalReject() {
    this.ref.current.reject();
  }
}
