import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { getAvailableName } from "../schedules.selectors";
import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog,
  ModalService
} from "../../../../../components/modal";
import { ScheduleEditForm } from "../scheduleRulesAccordion/scheduleEditForm";
import { ScheduleRulesOverlay } from "../scheduleRulesOverlay/scheduleRulesOverlay";

const getTitle = (isEdit, type) => {
  const editTitle = `${type}_edit_rule`;
  const createTitle = `${type}_create_rule`;

  return <FormattedMessage id={isEdit ? editTitle : createTitle} />;
};

export class ScheduleCreateDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDays: props.day || [],
      timeStart: props.timeStart || "01:00",
      timeEnd: props.timeEnd || "01:01"
    };
  }

  render() {
    const { type, firstDayOfWeek, timeFormat24h, isEdit = false } = this.props;
    const { timeStart, timeEnd, selectedDays } = this.state;
    const submitText = isEdit ? "update_rule" : "create_rule";

    return (
      <ModalDialog
        className={"schedule-modal"}
        rejectHandler={() => this.onModalCancel()}
        submitHandler={() => this.handleSubmit()}
        disabled={this.isDisabled()}
      >
        <CommonModalHeader>{getTitle(isEdit, type)}</CommonModalHeader>
        <ModalDialog.Body>
          <ScheduleEditForm
            firstDayOfWeek={firstDayOfWeek}
            timeFormat24h={timeFormat24h}
            schedule={{ timeStart, timeEnd, selectedDays }}
            onDaysChange={days => this.onDaysChange(days)}
            onTimePickerChange={(time, prop) =>
              this.onTimePickerChange(time, prop)
            }
          />
        </ModalDialog.Body>
        <CommonModalFooter
          submitMessage={<FormattedMessage id={submitText} />}
        />
      </ModalDialog>
    );
  }

  isDisabled() {
    return _.isEmpty(this.state.selectedDays);
  }

  onDaysChange(days) {
    this.setState({ selectedDays: days });
  }

  onTimePickerChange(time, prop) {
    this.setState({ [prop]: time });
  }

  onModalCancel() {
    const { isEdit = false, modal } = this.props;

    return isEdit
      ? this.showOverlayWithRules(this.props.schedules)
      : modal.reject();
  }

  showOverlayWithRules(schedules) {
    const {
      type,
      changeSchedules,
      removeSchedule,
      timeFormat24h,
      firstDayOfWeek
    } = this.props;

    return ModalService.show(modal => ({
      dialog: (
        <ScheduleRulesOverlay
          modal={modal}
          schedules={schedules}
          type={type}
          changeSchedules={changeSchedules}
          removeSchedule={removeSchedule}
          timeFormat24h={timeFormat24h}
          firstDayOfWeek={firstDayOfWeek}
        />
      )
    }));
  }

  handleSubmit() {
    const { type, profile, schedules, isEdit = false, modal } = this.props;

    if (!isEdit) {
      return modal.resolve({
        type,
        profile,
        enabled: true,
        name: getAvailableName(type, _.map(schedules, "name")),
        categories: (schedules[0] && schedules[0].categories) || [],
        timeStart: this.state.timeStart,
        timeEnd: this.state.timeEnd,
        day: this.state.selectedDays
      });
    }

    const changes = {
      timeStart: this.state.timeStart,
      timeEnd: this.state.timeEnd,
      day: this.state.selectedDays
    };
    const updatedSchedules = _.map(this.props.schedules, schedule =>
      this.props.id === schedule.id ? { ...schedule, ...changes } : schedule
    );
    this.showOverlayWithRules(updatedSchedules);
  }
}
