import { BaseApiService } from "../baseApiService";

export class HistoryClearService extends BaseApiService {
  constructor(config) {
    super(config);

    this.historyClearUrl = `${config.backendUrl}/ssm/account`;
  }

  clear(subscriberId, metrics) {
    return this._http.post(
      `${this.historyClearUrl}/${subscriberId}/history:clear`,
      { metrics }
    );
  }
}
