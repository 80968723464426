import _ from "lodash";
import {
  CHANGE_ADMIN_EMAIL_TO_SAVE,
  CHANGE_ADMIN_PHONE_TO_SAVE,
  CHANGE_MESSAGE_TO_SAVE,
  CHANGE_SAFETY_SUCCESS,
  CHANGE_SHOW_MESSAGE_TO_SAVE,
  LOAD_SAFETY_SUCCESS,
  RESET_TO_SAVE
} from "./safety.actions";
import { LOAD_SAFETY_VALIDATIONS } from "../../resources.actions";
import {
  VALIDATION_MAP,
  setValidation,
  toggleToSaveProperty
} from "../reducer.helpers";
import { hasNonEmptyString } from "../../helpers/form.helper";
import { CHANGE_PALETTE_SUCCESS } from "../brandingForm/branding.actions";

const initialState = {
  resource: {},
  toSave: {},
  validation: {
    rules: {},
    scheme: VALIDATION_MAP,
    fieldsToValidate: _.flatten(VALIDATION_MAP.map(obj => obj.fields))
  },
  requiredFields: [],
  isFetched: false
};

export const safetyReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SAFETY_VALIDATIONS: {
      const rules = setValidation(
        action.payload,
        action.payload.map(field => field.name)
      );

      return {
        ...state,
        validation: {
          ...state.validation,
          rules
        }
      };
    }
    case LOAD_SAFETY_SUCCESS:
      return {
        ...state,
        isFetched: true,
        requiredFields: Object.keys(action.payload),
        resource: action.payload
      };
    case CHANGE_SAFETY_SUCCESS: {
      const { safety: resource, toSave } = action.payload;

      return {
        ...state,
        resource,
        toSave
      };
    }
    case CHANGE_ADMIN_EMAIL_TO_SAVE: {
      const adminEmail = action.payload;
      const adminPhone = _.defaults({}, state.toSave, state.resource).adminPhone;

      return {
        ...state,
        toSave: {
          ...state.toSave,
          adminEmail,
          showAdmin: hasNonEmptyString([adminEmail, adminPhone])
        }
      };
    }
    case CHANGE_ADMIN_PHONE_TO_SAVE: {
      let adminPhone = action.payload;
      let adminEmail = _.defaults({}, state.toSave, state.resource).adminEmail;

      return {
        ...state,
        toSave: {
          ...state.toSave,
          adminPhone,
          showAdmin: hasNonEmptyString([adminEmail, adminPhone])
        }
      };
    }
    case CHANGE_MESSAGE_TO_SAVE:
      return {
        ...state,
        toSave: {
          ...state.toSave,
          message: action.payload
        }
      };
    case CHANGE_SHOW_MESSAGE_TO_SAVE:
      return {
        ...state,
        toSave: {
          ...state.toSave,
          showMessage: toggleToSaveProperty(
            state.resource.showMessage,
            state.toSave.showMessage
          )
        }
      };
    case CHANGE_PALETTE_SUCCESS: {
      const { color, tagline } = action.payload;

      return {
        ...state,
        resource: {
          ...state.resource,
          color,
          tagline
        }
      };
    }
    case RESET_TO_SAVE:
      return {
        ...state,
        toSave: {}
      };
    default:
      return state;
  }
};
