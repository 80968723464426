import React, { FC } from "react";
import { useSelector } from "react-redux";

import TimePeriodSelector from "../../../../components/charts/timePeriodSelector/TimePeriodSelector";
import { getShowDrilldownPeriod } from "../../../../store/config.selectors";
import { ReportsPeriod } from "../../../../helpers/reports.helper.types";

interface TimePeriodProps {
  period: ReportsPeriod;
  onTimePeriodChange: (period: ReportsPeriod) => void;
}

export const DrilldownTimePeriod: FC<TimePeriodProps> = ({
  period,
  onTimePeriodChange
}) => {
  const showDrilldownPeriod = useSelector(getShowDrilldownPeriod);

  return (
    <TimePeriodSelector
      showSelector={showDrilldownPeriod}
      period={period}
      onChange={onTimePeriodChange}
    />
  );
};
