import {
  ADD_NOTIFICATION,
  DISMISS_NOTIFICATION,
  NOTIFICATION_TYPES,
  REMOVE_ALL_STANDARD_NOTIFICATIONS
} from "./notification.actions";

import { NOTIFICATION_DURATION } from "./NotificationContainer";

function getNextId(arr) {
  if (!arr.length) {
    return 1;
  }

  return Math.max(...arr.map(el => el.id)) + 1;
}

const initialState = {
  list: []
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const id = getNextId(state.list),
        { type } = action.payload,
        isWebFilterOff = type === NOTIFICATION_TYPES.WEB_FILTER_OFF;

      if (isWebFilterOff) {
        const notification = {
          ...action.payload,
          id
        };

        return {
          ...state,
          webFilterOff: notification
        };
      }

      const notification = {
        ...action.payload,
        id,
        duration: NOTIFICATION_DURATION,
        dismissed: false
      };

      return {
        ...state,
        list: [...state.list, notification]
      };
    }
    case DISMISS_NOTIFICATION: {
      const id = action.payload;
      const staticNotificationsKeys = Object.keys(state).filter(
          key => key !== "list"
        ),
        isStatic = staticNotificationsKeys.includes(id);

      if (isStatic) {
        return {
          ...state,
          [id]: {
            dismissed: true
          }
        };
      }

      return {
        ...state,
        list: state.list.map(notification => ({
          ...notification,
          dismissed: notification.id === id ? true : notification.dismissed
        }))
      };
    }
    case REMOVE_ALL_STANDARD_NOTIFICATIONS: {
      return { ...state, list: [] };
    }
    default:
      return state;
  }
};
