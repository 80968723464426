import React, { FC, HTMLAttributes } from "react";
import { GetToggleButtonPropsOptions } from "downshift";
import { ISelectItem } from "../selectItem";

import "./SelectButton.scss";

interface SelectButtonProps extends HTMLAttributes<HTMLButtonElement> {
  getToggleButtonProps: (options?: GetToggleButtonPropsOptions) => any;
  disabled?: boolean;
  selectedItem?: ISelectItem;
}

export const SelectButton: FC<SelectButtonProps> = ({
  getToggleButtonProps,
  disabled,
  selectedItem
}) => {
  return (
    <button
      {...getToggleButtonProps({
        disabled,
        type: "button",
        tabIndex: 0,
        className: "select__button"
      })}
      data-testid="select-button"
    >
      <span data-testid="selected-value" className="select__button-content">
        {selectedItem ? selectedItem.content : ""}
      </span>
      <span className="select__button-arrow" />
    </button>
  );
};
