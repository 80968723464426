import React from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import {
  makeRouteBackup,
  resetUser,
  setSessionExpired
} from "../pages/login/login.actions";
import Notificator, {
  PRIORITIES
} from "../components/notification/notification.actions";
import { ModalService } from "../components/modal";

export default ({ dispatch, getState }) => {
  return next => {
    return action => {
      const { account } = getState();

      if (isSessionExpired(action) && isUserLoggedIn(account)) {
        dispatch(makeRouteBackup(window.location.hash.substring(1)));
        dispatch(setSessionExpired());
        dispatch(resetUser());
        dispatch(
          Notificator.error(
            <FormattedMessage id={"session_expired_some_time"} />,
            { priority: PRIORITIES.high }
          )
        );
        ModalService.modalReject();
      }

      return next(action);
    };
  };
};

const isSessionExpired = action => {
  const codes = [401, 403];

  if (_.has(action, "payload.response.status")) {
    return _.includes(codes, action.payload.response.status);
  }

  return false;
};

const isUserLoggedIn = account => account && account.id;
