import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { Mobile, NotMobile } from "../../../hocs/Responsive";
import { TimeRange } from "../../../components/time/timeRange/TimeRange";
import { ProfileSelector } from "../../../components/charts/profileSelector/ProfileSelector";
import { HomePageBarChart } from "./HomePageBarChart";
import { ChartTitle } from "../Dashboard";
import { isMultipleUserLevel } from "../../../store/account/index";
import { getReportsPeriod } from "../../../store/reports/reports.selectors";
import { changeReportsProfile } from "../dashboard.actions";

import "./BarChartSection.scss";

const TimePeriodInfoMobile = () => {
  const period = useSelector(getReportsPeriod);

  return <TimeRange className={"time-period__info"} period={period} />;
};

export const BarChartSection = () => {
  const dispatch = useDispatch();

  const isMultiple = useSelector(isMultipleUserLevel);

  const handleProfileChange = useCallback(
    profile => {
      dispatch(changeReportsProfile(profile));
    },
    [dispatch]
  );

  return (
    <div className="home-page__barchart barchart">
      <ChartTitle title={<FormattedMessage id="activity_and_blocks" />} />
      {isMultiple && <ProfileSelector onProfileChange={handleProfileChange} />}
      <Mobile>
        <div className={"homepage-reports__hint"}>
          *<FormattedMessage id={"report_chart_legend"} />
        </div>
        <TimePeriodInfoMobile />
      </Mobile>
      <HomePageBarChart />
      <div className={"legend"}>
        <div className={"legend__item legend__item--activity"}>
          <FormattedMessage id={"internet_activity"} />
        </div>
        <div className={"legend__item legend__item--blocked"}>
          <FormattedMessage id={"blocked_activity"} />
        </div>
        <div className={"legend__item legend__item--malware"}>
          <FormattedMessage id={"malware_and_phishing_blocked"} />
        </div>
      </div>
      <NotMobile>
        <div className={"homepage-reports__hint"}>
          *<FormattedMessage id={"report_chart_legend"} />
        </div>
      </NotMobile>
    </div>
  );
};
