import _ from "lodash";

import { Protection } from "@sportal/api";
import { loadCategories } from "../../../../store/categories/categories.actions";
import { loadSharedProtections as loadProtections } from "../../../../store/protections/protections.actions";
import { findProtectionByBlocked } from "../../../../store/protections/protections.reducer";
import {
  getProfileById,
  getSavedProfiles
} from "../../../../store/profiles/profiles.selectors";
import { getBlockedCategories } from "./webFilters.selectors";
import { changeProtection } from "../../../../store/profiles/profile.actions";
import { changeEnabled } from "../schedule/schedules.actions";
import { SCHEDULE_TYPES } from "../schedule/schedules.selectors";

const makeCategoriesAction = transform => (categoriesNames, profileId) => (
  dispatch,
  getState
) => {
  const state = getState();
  const allCategories = state.categories;
  const profile = getProfileById(profileId)(state);
  const currentBlocked = getBlockedCategories(profile.id, profile.protection)(
    state
  );
  const blocked = transform(currentBlocked, _.castArray(categoriesNames));
  const protection = findProtectionByBlocked(state, blocked);

  if (protection) {
    dispatch(changeProtection(profile.id, protection.name));
  } else {
    dispatch(changeProtection(profile.id, Protection.Custom));
    dispatch(setCustomProtection(profile.id, blocked));
    if (allCategories.length === blocked.length) {
      dispatch(changeEnabled(SCHEDULE_TYPES.HOMEWORK, profile.id, false));
    }
  }
};

export const block = makeCategoriesAction(_.union);
export const allow = makeCategoriesAction(_.difference);
// TODO: kind of kludge for mobile version, would be nice to minimize
//  number of actions and the reducer logic
export const setBlocked = makeCategoriesAction((current, blocked) => blocked);

export const inferProtectionByBlocked = (state, profile) => {
  const blocked = getBlockedCategories(profile.id, profile.protection)(state);
  return findProtectionByBlocked(state, blocked);
};
// Handles case when 'custom' is empty and should be replaced to 'none' on save
export const ensureProtection = profile => (dispatch, getState) => {
  const state = getState();
  const protection = inferProtectionByBlocked(state, profile);
  if (protection) {
    dispatch(changeProtection(profile.id, protection.name));
  }
};

export const isProtectionChanged = ({ webFilters }, { id, protection }) => {
  if (protection !== Protection.Custom) return false;

  return (
    _.xor(
      webFilters.customProtections[id] || [],
      webFilters._customProtections[id] || []
    ).length > 0
  );
};

export const load = () => dispatch => {
  dispatch(loadProtections());
  dispatch(loadCategories());
  dispatch(getCustomProtections());
};

export const getCustomProtections = () => (dispatch, getState, { api }) => {
  const state = getState();
  const { webFilters, subscriberInfo } = state;

  const profilesToLoad = _.filter(
    getSavedProfiles(state).list,
    ({ id, protection }) =>
      protection === Protection.Custom &&
      !_.has(webFilters.customProtections, id)
  );

  return Promise.all(
    _.map(profilesToLoad, profile =>
      api.ssm.profile
        .getCustomProtection(subscriberInfo.id, profile.name)
        .then(({ content }) => {
          dispatch(
            getCustomProtectionSuccess(profile.id, _.map(content, "name"))
          );
        })
        .catch(error => {
          // TODO: check old behavior in case of errors
          dispatch(getCustomProtectionFailure(error));
          return Promise.reject();
        })
    )
  );
};

export const saveCustomProtection = profile => (
  dispatch,
  getState,
  { api }
) => {
  const { webFilters, subscriberInfo } = getState();
  const isChanged = isProtectionChanged({ webFilters }, profile);

  if (!isChanged) return;

  return api.ssm.profile
    .saveCustomProtection(
      subscriberInfo.id,
      profile.name,
      webFilters.customProtections[profile.id]
    )
    .then(() => {
      dispatch(saveCustomProtectionSuccess(profile.id));
    })
    .catch(error => {
      dispatch(saveCustomProtectionFailure(error));
      return Promise.reject();
    });
};

export const SET_SEARCH_STRING = "[WEB_FILTERS] SET_SEARCH_STRING";
export const SET_CUSTOM_PROTECTION = "[WEB_FILTERS] SET_CUSTOM_PROTECTION";
export const WIPE_CUSTOM_PROTECTION = "[WEB_FILTERS] WIPE_CUSTOM_PROTECTION";
export const GET_CUSTOM_PROTECTION_SUCCESS =
  "[WEB_FILTERS] GET_CUSTOM_PROTECTION_SUCCESS";
export const GET_CUSTOM_PROTECTION_FAILURE =
  "[WEB_FILTERS] GET_CUSTOM_PROTECTION_FAILURE";
export const SAVE_CUSTOM_PROTECTION_SUCCESS =
  "[WEB_FILTERS] SAVE_CUSTOM_PROTECTION_SUCCESS";
export const SAVE_CUSTOM_PROTECTION_FAILURE =
  "[WEB_FILTERS] SAVE_CUSTOM_PROTECTION_FAILURE";

export const setSearchString = searchString => ({
  type: SET_SEARCH_STRING,
  payload: searchString
});

export const setCustomProtection = (profileId, categories) => ({
  type: SET_CUSTOM_PROTECTION,
  payload: { profileId, categories }
});

export const wipeCustomProtection = profileId => ({
  type: WIPE_CUSTOM_PROTECTION,
  payload: profileId
});

export const getCustomProtectionSuccess = (profileId, categories) => ({
  type: GET_CUSTOM_PROTECTION_SUCCESS,
  payload: { profileId, categories }
});

export const getCustomProtectionFailure = error => ({
  type: GET_CUSTOM_PROTECTION_FAILURE,
  payload: error
});

export const saveCustomProtectionSuccess = profileId => ({
  type: SAVE_CUSTOM_PROTECTION_SUCCESS,
  payload: profileId
});

export const saveCustomProtectionFailure = error => ({
  type: SAVE_CUSTOM_PROTECTION_FAILURE,
  payload: error
});
