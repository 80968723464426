import _ from "lodash";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Profiles } from "../profiles/Profiles";
import { Button } from "../../components/button/Button";
import ProgressButton from "../../components/progressButton/ProgressButton";
import { resetProfiles } from "../../store/profiles/profiles.actions";
import { saveProfile } from "../../store/profiles/profile.actions";
import Notificator from "../../components/notification/notification.actions";
import { hasChanges } from "../../store/profiles/profiles.selectors";
import {
  showConfirmationDialog,
  withPromptDialog
} from "../../hocs/withPrompt";
import { useSelectedProfileId } from "../profiles/components/hooks";

const ProfilesSettingsComponent = withPromptDialog(
  ({ save, discard, hasChanges, profileId, ...profilesProps }) => {
    return (
      <Fragment>
        <Profiles {...profilesProps} />
        <div className="settings-page__footer">
          {hasChanges && (
            <div className="settings-page__unsaved-warning">
              <FormattedMessage id="unsaved_changes" />
            </div>
          )}
          <Button
            className="settings-page__action-btn"
            isOutline
            size={"large"}
            onClick={discard}
            disabled={!hasChanges}
          >
            <FormattedMessage id="discard" />
          </Button>
          <ProgressButton
            label={<FormattedMessage id="save" />}
            disabled={!hasChanges}
            className="settings-page__action-btn"
            onClick={() => save(profileId)}
          />
        </div>
      </Fragment>
    );
  }
);

ProfilesSettingsComponent.propTypes = {
  save: PropTypes.func.isRequired,
  discard: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool,
  // TODO: following can be computed based on type of item in profiles prop
  //  clarify if it is really needed
  customProfilesAmount: PropTypes.number,
  limit: PropTypes.number.isRequired,
  addDialogWillShow: PropTypes.func
};

function joinErrors(errors) {
  try {
    return (
      <Fragment>
        {errors[0] && errors[0].type !== "validation" && (
          <Fragment>
            <FormattedMessage id={"couldnt_save_changes_because"} />
            <br />
          </Fragment>
        )}
        {_.map(errors, ({ message }, idx) => (
          <Fragment key={idx}>
            {!!idx && ", "}
            <FormattedMessage id={message} />
          </Fragment>
        ))}
      </Fragment>
    );
  } catch (e) {
    return <FormattedMessage id={"failed_to_save_profile"} />;
  }
}

export const ProfilesSettings = () => {
  const dispatch = useDispatch();
  const profileId = useSelectedProfileId();
  const hasChanged = useSelector(state => hasChanges(profileId, state));
  const limit = useSelector(state => state.profiles.limit);
  const customProfilesAmount = useSelector(
    state => state.profiles.saved.keys.length - 3
  );
  const save = () =>
    dispatch(saveProfile(profileId)).catch(errors => {
      dispatch(Notificator.error(joinErrors(errors)));
      return Promise.reject();
    });
  const discard = () => dispatch(resetProfiles());
  const showAddDialogProps = hasChanged
    ? {
        addDialogWillShow: nextAction => {
          showConfirmationDialog({
            save,
            discard,
            nextAction
          });
        }
      }
    : {};

  return (
    <ProfilesSettingsComponent
      {...showAddDialogProps}
      save={save}
      discard={discard}
      hasChanges={hasChanged}
      limit={limit}
      customProfilesAmount={customProfilesAmount}
      profileId={profileId}
    />
  );
};
