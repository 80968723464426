import React, { Fragment } from "react";
import _ from "lodash";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { Icon } from "../../../components/icon/Icon";
import { Tooltip } from "../../../components/tooltip/Tooltip";
import { Mobile, NotMobile } from "../../../hocs/Responsive";
import { RoamingDeviceProtectionStatus } from "../spson.types";

import "./ProtectionStatus.scss";

interface Props {
  status: RoamingDeviceProtectionStatus;
}

const defaultInfo = {
  colorScheme: "disabled",
  icon: "fa-times-circle",
  tooltipMessage: "device_not_protected"
};

export const ProtectionStatus = ({ status }: Props) => {
  const protectionStatus = status || "unknown";
  const statusInfo = _.get(
    {
      [RoamingDeviceProtectionStatus.Disabled]: defaultInfo,
      [RoamingDeviceProtectionStatus.Enabled]: {
        colorScheme: "enabled",
        icon: "fa-check-circle",
        tooltipMessage: "device_protected"
      },
      unknown: {
        colorScheme: "unknown",
        icon: "fa-question-circle",
        tooltipMessage: "status_unknown"
      }
    },
    protectionStatus,
    defaultInfo
  );

  const content = (
    <span
      className={classnames(
        "protection-status",
        `protection-status--${statusInfo.colorScheme}`
      )}
    >
      <Icon icon={`fas ${statusInfo.icon}`} />
    </span>
  );

  return (
    <Fragment>
      <Mobile>{content}</Mobile>
      <NotMobile>
        <Tooltip
          content={<FormattedMessage id={statusInfo.tooltipMessage} />}
          theme="dark"
        >
          {content}
        </Tooltip>
      </NotMobile>
    </Fragment>
  );
};
