import { SsmService } from "./ssm/ssmService";
import { AuthService } from "./authService";
import { StaticResourceService } from "../common/staticResources/StaticResourceService";
import { DevicesService } from "./ssm/devicesService";
import { SettingsService } from "./ssm/settingsService";
import { ProtectionService } from "./ssm/protectionService";

/*
interface ApiConfig {
  backendUrl: string;
  urlTypes: string[];

  mode: 'pi' | 'sb'; // part of url which is used to distinguish endpoints

  // DI fields:
  http: any; // something that lets to make requests, for now it is axios instance
  // ssm part
  ssm?: SsmService;
  list?: ListService;
  series?: SeriesService;
  auth?: AuthService;
}
*/

// The entry point that provides access to all inner services
// Responsible for creation inner services instances
// so that they do not have to handle it by themselves

interface ApiServices {
  auth: AuthService;
  ssm: {
    devices: DevicesService;
    settings: SettingsService;
    protection: ProtectionService;
  };
}

export interface ThunkApi {
  api: Api;
}

export class Api implements ApiServices {
  _ssm: SsmService;
  _auth: AuthService;
  _resources: StaticResourceService;

  get ssm() {
    return this._ssm;
  }

  get auth() {
    return this._auth;
  }

  get resources() {
    return this._resources;
  }

  // Please be aware of DI used here
  constructor(config) {
    this._ssm = config.ssm || new SsmService(config);
    this._auth = config.auth || new AuthService(config);
    this._resources = config.resources || new StaticResourceService(config);
  }
}
