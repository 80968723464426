import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SeverityIndicator } from "../../../components/severityIndicator/SeverityIndicator";

import "./ThreatList.scss";

import { NotMobile } from "../../../hocs/Responsive";

export const ThreatList = ({ threats, useFallbackLanguage }) => {
  const renderDescription = useFallbackLanguage
    ? renderDescriptionAsIs
    : renderDescriptionOrDefault;

  return (
    <table className="threat-list">
      <NotMobile>
        <thead>
          <tr>
            <th>
              <FormattedMessage id={"threat_name"} />
            </th>
            <th>
              <FormattedMessage id={"severity_level"} />
            </th>
            <th>
              <FormattedMessage id={"threat_description"} />
            </th>
          </tr>
        </thead>
      </NotMobile>
      <tbody>
        {_.map(threats, ({ id, name, description, localized, severity }) => (
          <tr key={id}>
            <td id={`threat-list-${id}`}>{name}</td>
            <td>
              <SeverityIndicator severity={severity}>
                <FormattedMessage
                  id={`${severity}_severity_level`}
                  defaultMessage={" "}
                />
              </SeverityIndicator>
            </td>
            {renderDescription(description, localized)}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const descriptionClassName = "threat-description";

const renderDescriptionOrDefault = (description, localized) =>
  localized ? renderDescriptionAsIs(description) : renderDefaultDescription();

const renderDescriptionAsIs = description => (
  <td
    className={descriptionClassName}
    dangerouslySetInnerHTML={{ __html: description }}
  />
);

const renderDefaultDescription = () => (
  <td className={descriptionClassName}>
    <FormattedMessage id="default_threat_description" />
  </td>
);
