const URL = window.URL || window.webkitURL;

export const getImage = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const src = URL.createObjectURL(file);
    let img = new Image();

    img.onload = () => resolve(src);
    img.onerror = reject;
    img.src = src;
  });
};
