import React, { useState } from "react";
import Responsive from "react-responsive";
import { useEventListener } from "@sportal/cdk/hooks";

//TODO: We can import variables from '../../../styles/_variables.scss' but not right now
// https://www.bluematador.com/blog/how-to-share-variables-between-js-and-sass

export const sizes = {
  mobileMax: 767,
  tabletMin: 768,
  tabletMax: 991,
  desktopMin: 992
};

export const Desktop = props => (
  <Responsive {...props} minWidth={sizes.desktopMin} />
);
export const Tablet = props => (
  <Responsive
    {...props}
    minWidth={sizes.tabletMin}
    maxWidth={sizes.tabletMax}
  />
);
export const Mobile = props => (
  <Responsive {...props} maxWidth={sizes.mobileMax} />
);
export const TabletMobile = props => (
  <Responsive {...props} maxWidth={sizes.tabletMax} />
);
export const NotMobile = props => (
  <Responsive {...props} minWidth={sizes.tabletMin} />
);

// todo: temporary solution until react-responsive is updated
const isDesktopScreen = () => window.innerWidth >= sizes.desktopMin;
export const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(isDesktopScreen());

  useEventListener("resize", () => {
    setIsDesktop(isDesktopScreen());
  });

  return isDesktop;
};
