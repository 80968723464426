import _ from "lodash";

import { getThreats } from "../root.selectors";

//TODO: Need make something like generation list of severity indexes by all indexes from request "/threats"
export const SEVERITIES = [4, 3, 2, 1, 0];

const priorities = _.transform(
  SEVERITIES,
  (result, severity, index) => {
    result[severity] = index;
  },
  {}
);

export const getSeverityIndex = ({ severity } = {}) =>
  _.isEmpty(severity) ? null : priorities[severity];

export const getThreatsList = state => getThreats(state).list;
