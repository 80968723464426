import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { OptionalFormattedMessage } from "../../../i18n/OptionalFormattedMessage";
import { DrilldownRoutePage } from "../drilldown.types";

const TITLES = {
  webfilter: "blocked_activity",
  malware: "malware_and_phishing_blocked",
  botnet: "botnet_blocked"
};

interface Props {
  page: DrilldownRoutePage;
}

export const DrilldownTitle = ({ page }: Props) => {
  return (
    <Fragment>
      <h2 className={"drilldowns__title"}>
        <FormattedMessage id={TITLES[page]} />
      </h2>
      <div className={"drilldowns__subtitle"}>
        <OptionalFormattedMessage id={`${page}_drilldown_subtitle`} />
      </div>
    </Fragment>
  );
};
