import React, { FC } from "react";

import { TabControl, TabItem } from "../tabList";

import "./HorizontalScrollTabs.scss";

interface HorizontalScrollTabsProps {
  tabs: TabItem[];
  selected?: string;
  onClick?: (id: string) => any;
}

export const HorizontalScrollTabs: FC<HorizontalScrollTabsProps> = ({
  tabs = [],
  selected,
  onClick
}) => (
  <ul className="horizontal-scroll-tabs">
    {tabs.map(item => (
      <li key={item.id} className="horizontal-scroll-tabs__tab">
        <TabControl
          item={item}
          selected={selected === item.id}
          onClick={onClick}
        />
      </li>
    ))}
  </ul>
);
