import { HttpServiceError, SettingsServerData } from "@sportal/api";

export const GET_SETTINGS_SUCCESS = "[AUTH] GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAILURE = "[AUTH] GET_SETTINGS_FAILURE";

export type SettingsSuccessPayload = Pick<
  SettingsServerData,
  "services" | "reporting"
>;

export type SettingsFailurePayload = HttpServiceError | { text: string };

export interface GetSettingsSuccess {
  type: typeof GET_SETTINGS_SUCCESS;
  payload: SettingsSuccessPayload;
}

interface GetSettingsFailure {
  type: typeof GET_SETTINGS_FAILURE;
  payload: SettingsFailurePayload;
}

export type SettingsActionTypes = GetSettingsSuccess | GetSettingsFailure;
