import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { SectionHeader } from "../sectionHeader/SectionHeader";
import { CheckboxElement } from "../checkboxElement/CheckboxElement";
import { getMaxLengthValidationProps } from "../../helpers/form.helper";
import { Textarea } from "../../../../components/textarea/Textarea";
import { useIntlTranslate } from "../../../../hooks";

export const BlockingMessage = ({
  showMessage,
  toggleMessage,
  changeMessage,
  message,
  messageValidation
}) => {
  const translate = useIntlTranslate();

  return (
    <div className="form-section">
      <SectionHeader title={<FormattedMessage id={"blocking_reason"} />} />
      <CheckboxElement
        data-testid="show-reason"
        onChange={toggleMessage}
        isChecked={showMessage}
        title="show_reason"
      />
      {showMessage && (
        <Textarea
          id="blocking-message"
          onChange={changeMessage}
          value={message}
          validation={getMaxLengthValidationProps(messageValidation, message)}
          aria-label={translate("leave_a_message_for_the_block_page")}
        />
      )}
    </div>
  );
};

BlockingMessage.propTypes = {
  showMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  toggleMessage: PropTypes.func.isRequired,
  changeMessage: PropTypes.func,
  message: PropTypes.string,
  messageValidation: PropTypes.object
};
