import { HttpService, HttpServiceResponse } from "../../shared";
import {
  ILogicalDevicesProvider,
  LogicalDeviceServerData,
  LogicalDevicesServerData,
  LogicalDeviceUpdate
} from "./logicalDevices.types";

export class LogicalDevicesProvider implements ILogicalDevicesProvider {
  constructor(private http: HttpService, private baseUrl: string) {}

  public get(
    subscriberId: string
  ): Promise<HttpServiceResponse<LogicalDevicesServerData>> {
    return this.http.get<LogicalDevicesServerData>(this.url(subscriberId));
  }

  public create(
    subscriberId: string,
    { name, id, profile }: { name: string; id: string; profile?: string }
  ): Promise<HttpServiceResponse<LogicalDeviceServerData>> {
    return this.http
      .post(this.url(subscriberId), {
        // prettier-ignore
        add: [{ name, profile, identifiers: [id] }]
      })
      .then(response => ({
        ...response,
        data: response.data.succeeded[0]
      }));
  }

  public remove(
    subscriberId: string,
    deviceName: string
  ): Promise<HttpServiceResponse<undefined>> {
    return this.http.delete(
      `${this.url(subscriberId)}/${encodeURIComponent(deviceName)}`
    );
  }

  public update(
    subscriberId: string,
    deviceName: string,
    updates: LogicalDeviceUpdate
  ): Promise<HttpServiceResponse<LogicalDeviceServerData>> {
    return this.http
      .put(
        `${this.url(subscriberId)}/${encodeURIComponent(deviceName)}`,
        updates
      )
      .then(response => ({
        ...response,
        data: response.data.succeeded[0]
      }));
  }

  private url(subscriberId: string): string {
    return `${this.baseUrl}/ssm/account/${subscriberId}/logical-device`;
  }
}
