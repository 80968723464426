import _ from "lodash";
import React, { Fragment, useMemo } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import { Mobile, NotMobile } from "../../../hocs/Responsive";
import { Select } from "../../select";
import NativeSelect from "../../select/NativeSelect";
import { Icon } from "../../icon/Icon";
import { useIntlTranslate } from "../../../hooks";

import "./Pagination.scss";

const PAGINATION_OPTIONS = ["25", "50", "100"];

export const Pagination = ({
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageCount,
  previousPage,
  rows,
  setPageSize,
  state
}) => {
  function handlePageSizeChange(value) {
    setPageSize(+value);
    gotoPage(0);
  }

  return (
    <div className={"pagination"}>
      <PaginationMessage
        rowsCount={rows.length}
        onPageSizeChange={handlePageSizeChange}
        state={state}
      />
      <PaginationControls
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        pageCount={pageCount}
        previousPage={previousPage}
        state={state}
      />
    </div>
  );
};

export const PaginationMessage = ({
  onPageSizeChange,
  rowsCount,
  state: { pageIndex, pageSize }
}) => {
  const translate = useIntlTranslate();

  const listOfSizes = useMemo(() => {
    return PAGINATION_OPTIONS.map(option => ({
      value: option,
      name: option
    }));
  }, []);

  const items = useMemo(() => {
    return PAGINATION_OPTIONS.map(option => ({
      value: option,
      content: option
    }));
  }, []);

  const userPage = pageIndex + 1;
  const entries = rowsCount;
  const start = userPage * pageSize - pageSize + 1;
  const end = userPage * pageSize > entries ? entries : userPage * pageSize;

  const page = rowsCount !== 0 ? `${start} - ${end}` : 0; // 0 should be displayed when table has no records
  const pageValues = { page, entries };

  return (
    <div className={"pagination__message"}>
      <div className={"pagination__rows-select"}>
        <span id="rows-per-page-label">
          <FormattedMessage id={"rows_per_page"} />
        </span>
        <NotMobile>
          <Select
            items={items}
            selected={`${pageSize}`}
            onChange={onPageSizeChange}
            ariaLabel={translate("rows_per_page")}
            small
          />
        </NotMobile>
        <Mobile>
          <NativeSelect
            options={listOfSizes}
            selected={`${pageSize}`}
            className={"pagination__rows-select__select"}
            toggleView={"link"}
            translateKey="amount"
            onChange={onPageSizeChange}
            ariaLabelledBy="rows-per-page-label"
          />
        </Mobile>
      </div>
      <span data-testid="pagination-info">
        <NotMobile>
          <FormattedMessage
            id={"showing_amount_of_table_items"}
            values={pageValues}
          />
        </NotMobile>
        <Mobile>
          <FormattedMessage id={"amount_of_table_items"} values={pageValues} />
        </Mobile>
      </span>
    </div>
  );
};

export const PaginationControls = ({
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageCount,
  previousPage,
  state: { pageIndex }
}) => {
  return (
    <div className={"pagination__controls"}>
      <UnstyledButton
        className="prev"
        disabled={!canPreviousPage}
        onClick={() => previousPage()}
      >
        <Icon icon="far fa-angle-left" />
      </UnstyledButton>
      <div className={"pages"}>
        <div className={"pages__container"}>
          <PaginationPages
            pageCount={pageCount}
            activePageIndex={pageIndex}
            gotoPage={gotoPage}
          />
        </div>
      </div>
      <UnstyledButton
        className="next"
        disabled={!canNextPage}
        onClick={() => nextPage()}
      >
        <Icon icon="far fa-angle-right" />
      </UnstyledButton>
    </div>
  );
};

const Page = ({ pageIndex, activePageIndex, gotoPage }) => (
  <UnstyledButton
    className={classnames("page", {
      active: pageIndex === activePageIndex
    })}
    onClick={() => gotoPage(pageIndex)}
  >
    {pageIndex + 1}
  </UnstyledButton>
);

const PaginationPages = ({ pageCount, activePageIndex, gotoPage }) => {
  const first = 0;

  if (!pageCount) {
    return (
      <Page
        pageIndex={first}
        activePageIndex={activePageIndex}
        gotoPage={gotoPage}
      />
    );
  }

  if (pageCount < 5) {
    return (
      <Fragment>
        {_.times(pageCount, pageIndex => (
          <Page
            key={pageIndex}
            pageIndex={pageIndex}
            activePageIndex={activePageIndex}
            gotoPage={gotoPage}
          />
        ))}
      </Fragment>
    );
  }

  const last = pageCount - 1;

  let second = activePageIndex > 1 ? activePageIndex : 1;
  let third = second + 1;

  if (activePageIndex === last || activePageIndex === last - 1) {
    third = last - 1;
    second = third - 1;
  }

  return (
    <Fragment>
      <Page
        pageIndex={first}
        activePageIndex={activePageIndex}
        gotoPage={gotoPage}
      />
      {activePageIndex > 1 && (
        <div className={"spread"} data-testid="first-spread">
          ...
        </div>
      )}

      <Page
        pageIndex={second}
        activePageIndex={activePageIndex}
        gotoPage={gotoPage}
      />
      <Page
        pageIndex={third}
        activePageIndex={activePageIndex}
        gotoPage={gotoPage}
      />

      {activePageIndex < last - 2 && (
        <div className={"spread"} data-testid="last-spread">
          ...
        </div>
      )}
      <Page
        pageIndex={last}
        activePageIndex={activePageIndex}
        gotoPage={gotoPage}
      />
    </Fragment>
  );
};
