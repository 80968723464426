import React, { Fragment, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from "react-router-dom";

import {
  DevicesSets,
  rejectRequests,
  rejectRoaming,
  rejectUnassigned
} from "../../../../helpers/devices.helpers";
import { TabletMobile, Desktop } from "../../../../hocs/Responsive";
import { SpsonFluidPanel } from "../../../spson/spsonPanel/SpsonPanel";
import { isSPSONAvailable } from "../../../../store/account";
import { RequestedDevicesTable } from "./RequestedDevicesTable";
import { OnlineDevicesTable } from "./OnlineDevicesTable";
import { ProfileRoamingDevicesTable } from "../shared/ProfileRoamingDevicesTable";
import { translateMap } from "../../../../utils/translateMap";
import { ProfileGroups } from "../../../../store/profiles/fixedProfiles";
import { anyNewRequests } from "../../../../store/devices/devices.selectors";
import { hasDefaultProfileNonRoamingDevices } from "../../../../store/profiles/profiles.selectors";
import { ProfileNotRoamingDevicesTable } from "../shared/ProfileNotRoamingDevicesTable";
import { TabList, createDeviceLinkTab } from "../../../../components/tabList";
import { HorizontalScrollTabs } from "../../../../components/horizontalScrollTabs/HorizontalScrollTabs";

import "../../../../components/devices/TableDevices.scss";
import { withSettingDevicesGuard } from "../../withSettingsDevicesGuard";

const TABS_SETTINGS = [
  {
    value: DevicesSets.Requests,
    name: "new_requests",
    url: "new-requests",
    description: <FormattedMessage id={"requested_devices_description"} />,
    content: <RequestedDevicesTable />
  },
  {
    value: DevicesSets.Discovered,
    name: "online",
    url: "online",
    description: <FormattedMessage id="online_devices_description" />,
    content: <OnlineDevicesTable />
  },
  {
    value: DevicesSets.NotRoaming,
    name: "unassigned",
    url: "unassigned",
    description: <FormattedMessage id={"unassigned_devices_description"} />,
    content: (
      <ProfileNotRoamingDevicesTable profileName={ProfileGroups.Visitors} />
    )
  },
  {
    value: DevicesSets.Roaming,
    name: "roaming",
    url: "roaming",
    description: <FormattedMessage id="roaming_devices_description" />,
    content: <ProfileRoamingDevicesTable profileName={ProfileGroups.Visitors} />
  }
];

export const getRoutes = (tabs, baseUrl) =>
  tabs.map((tab, index) => (
    <Route exact path={`${baseUrl}/${tab.url}`} key={index}>
      {tab.content}
    </Route>
  ));

const getDescription = (selectedTab, tabs) => {
  const currentTab = _.find(tabs, { url: selectedTab });

  return currentTab
    ? currentTab.description
    : _.first(TABS_SETTINGS).description;
};

const getSelectedTab = url => _.last(url.split("/"));

export const DefaultProfileContent = withSettingDevicesGuard(
  function DefaultProfileContent() {
    const spsonAvailable = useSelector(isSPSONAvailable);
    const hasNewRequests = useSelector(anyNewRequests);
    const hasUnassigned = useSelector(hasDefaultProfileNonRoamingDevices);
    const intl = useIntl();
    const { url } = useRouteMatch();
    const { pathname } = useLocation();
    const selectedTab = getSelectedTab(pathname);

    const filteredTabs = useMemo(() => {
      const filters = [
        !spsonAvailable && rejectRoaming,
        !hasNewRequests && rejectRequests,
        !hasUnassigned && rejectUnassigned
      ].filter(Boolean);

      return _.flow(filters)(TABS_SETTINGS);
    }, [spsonAvailable, hasNewRequests, hasUnassigned]);

    const routes = useMemo(() => {
      return translateMap(filteredTabs, intl);
    }, [filteredTabs, intl]);

    const tabs = useMemo(() => {
      return filteredTabs.map(tab => createDeviceLinkTab(tab, url, intl));
    }, [filteredTabs, intl, url]);

    const showSPSON = spsonAvailable && selectedTab === DevicesSets.Roaming;

    return (
      <Fragment>
        <Desktop>{showSPSON && renderSPSONPanel()}</Desktop>
        <h4>
          <FormattedMessage id={"devices"} />
        </h4>
        <p className={"settings-page__description"}>
          {getDescription(selectedTab, TABS_SETTINGS)}
        </p>
        <div className={`devices__layout devices--${selectedTab}`}>
          <Desktop>
            <TabList items={tabs} />
          </Desktop>
          <TabletMobile>
            <HorizontalScrollTabs tabs={tabs} />
            {showSPSON && renderSPSONPanel()}
          </TabletMobile>
          <Switch>
            {getRoutes(routes, url)}
            <Redirect to={`${url}/${_.head(routes).url}`} />
          </Switch>
        </div>
      </Fragment>
    );
  }
);

const renderSPSONPanel = () => {
  return <SpsonFluidPanel className="devices__spson-panel" />;
};
