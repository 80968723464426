import {
  DataKeys,
  DrilldownDataKeys,
  ReportsPeriod,
  ReportTypes
} from "../../helpers/reports.helper.types";
import { ReportSlice } from "@sportal/api/reports";

export enum ReportsSection {
  Table = "table",
  Barchart = "barchart",
  Widgets = "widgets"
}

export interface Zoom {
  visible: boolean;
  start?: number;
  end?: number;
}

export interface WidgetsData {
  [DataKeys.webfilter]?: ReportSlice[];
  [DataKeys.malware]?: ReportSlice[];
  [DataKeys.botnet]?: ReportSlice[];
}

export interface FormattedBarChartData {
  [ReportTypes.Activity]?: number;
  [ReportTypes.Webfilter]?: number;
  [ReportTypes.Malware]?: number;
  [ReportTypes.ActivityTop]?: [string, number];
  [ReportTypes.WebfilterTop]?: [string, number];
  time: number;
}

interface TableReport {
  "device-id": string;
  "first-occurrence": number;
  "group-name": string;
  categories: string[];
  count: number;
  protocol: string;
  reason: string;
  rendered: boolean;
  url: string;
}

interface TableReportSlice {
  time: number;
  value: TableReport[];
}

export interface TableData {
  [DrilldownDataKeys.webfilter]?: TableReportSlice[];
  [DrilldownDataKeys.malware]?: TableReportSlice[];
  [DrilldownDataKeys.botnet]?: TableReportSlice[];
}

export interface ReportsState {
  period: ReportsPeriod;
  profile: string;
  widgets: WidgetsData;
  barchart: FormattedBarChartData[];
  table: TableData;
  zoom: Zoom;
}
