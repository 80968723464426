import React, { FC, memo, Fragment, useRef } from "react";

import {
  useAppearAnimation,
  useDataTransitionAnimation
} from "../animations.hooks";

interface GradientAreaChartProps {
  path: string;
  id: string;
}

export const GradientAreaChart = memo<GradientAreaChartProps>(
  function GradientAreaChart({ path, id }) {
    const pathRef = useRef<SVGPathElement>(null);

    const { isFinished } = useAppearAnimation(pathRef);
    useDataTransitionAnimation(pathRef, path, isFinished);

    const additionalAttrs = !isFinished ? { d: path } : {};
    const gradientId = `${id}_gradient`;

    return (
      <Fragment>
        <path
          ref={pathRef}
          {...additionalAttrs}
          fill={`url(#${gradientId})`}
          fillOpacity={0.6}
          stroke="none"
        />
        <LinearGradientBackground id={gradientId} />
      </Fragment>
    );
  }
);

interface LinearGradientBackgroundProps {
  id: string;
}

const LinearGradientBackground: FC<LinearGradientBackgroundProps> = ({
  id
}) => {
  return (
    <defs>
      <linearGradient id={id} x1="0%" x2="0%" y1="0%" y2="100%">
        <stop offset="10%" />
        <stop offset="80%" />
      </linearGradient>
    </defs>
  );
};
