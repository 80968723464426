import React from "react";
import { FormattedMessage } from "react-intl";

import "./InfectionsSummary.scss";

export const InfectionsSummary = ({ infections }) => {
  return (
    <div>
      <span className={"infections-summary"}>
        <FormattedMessage
          id={"infections_count"}
          values={{ count: infections.length }}
        />
      </span>
    </div>
  );
};
