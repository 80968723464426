import React from "react";
import classnames from "classnames";
import "./Counter.scss";

export function Counter(props) {
  const { className, amount, limit, label } = props;

  return (
    <div className={classnames("counter", className)}>
      <span className={classnames("counter__label", `${className}__label`)}>
        {label}
      </span>
      <span className={classnames("counter__amount", `${className}__amount`)}>
        (<span>{amount}</span>/<span>{limit}</span>)
      </span>
    </div>
  );
}
