import React, { useState } from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog,
  ModalFormControl
} from "../../../modal";
import { ProfileSelect } from "../../commonComponents";
import { haveNonBlockedProfilesReachedLimit } from "../../../../store/profiles/profiles.selectors";

import "../../commonStyles.scss";
import "./UnblockDeviceDialog.scss";

const CLASS_NAME = "unblock-device-modal";

export const UnblockDeviceDialog = ({ modal, device, profiles }) => {
  const notDisabledProfile = _.find(profiles, { isDisabled: false });
  const defaultProfile = notDisabledProfile ? notDisabledProfile.value : "";
  const isLimitReached = useSelector(haveNonBlockedProfilesReachedLimit);

  const [profile, setProfile] = useState(defaultProfile);

  const close = () => modal.reject();
  const submit = () => modal.resolve({ profile });

  const getDeviceInfo = () => {
    const deviceInfo = device.manufacturer
      ? `${device.name} ${device.manufacturer}`
      : device.name;

    return <span className="device-name">{deviceInfo}</span>;
  };

  return (
    <ModalDialog
      className={CLASS_NAME}
      submitHandler={submit}
      rejectHandler={close}
      disabled={profile === ""}
    >
      <CommonModalHeader>
        <FormattedMessage id={"unblock_device"} />
      </CommonModalHeader>
      <ModalDialog.Body>
        <div className={`${CLASS_NAME}__message`}>
          <FormattedMessage
            id={"cancel_blocking"}
            values={{ deviceName: getDeviceInfo() }}
          />{" "}
          <FormattedMessage id={"removed_from_blocked"} />
        </div>
        <ModalFormControl>
          <ProfileSelect
            profiles={profiles}
            profile={profile}
            handleChange={value => setProfile(value)}
            className={CLASS_NAME}
          />
          {isLimitReached && (
            <p className={`color-danger ${CLASS_NAME}__error`}>
              <FormattedMessage id={"cant_unblock_limit_reached"} />
            </p>
          )}
        </ModalFormControl>
      </ModalDialog.Body>
      <CommonModalFooter submitMessage={<FormattedMessage id="unblock" />} />
    </ModalDialog>
  );
};

UnblockDeviceDialog.propTypes = {
  device: PropTypes.object
};
