import React, { Fragment, useState } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import { useMount } from "@sportal/cdk/hooks";
import { TabletMobile } from "../../../hocs/Responsive";
import { MENU_ITEMS } from "../menu.config";
import { horizontalMenuPortalClassName } from "./HorizontalMenu";

import "./PageName.scss";

const getHorizontalMenuPortalElement = () =>
  document.getElementsByClassName(horizontalMenuPortalClassName)[0];

const useRouteMenuItem = () => {
  const { pathname } = useLocation();

  const menuItems = Object.values(MENU_ITEMS);
  const menuItem = menuItems.find(({ url }) => url === pathname);

  if (menuItem) {
    return menuItem;
  }

  return menuItems.find(
    ({ subMenu }) =>
      subMenu && subMenu.find(subMenuItem => pathname.includes(subMenuItem.url))
  );
};

const RoutePageName = () => {
  const menuItem = useRouteMenuItem();

  return (
    menuItem &&
    menuItem.text && (
      <h2>
        <FormattedMessage id={menuItem.text} />
      </h2>
    )
  );
};

export const PageName = ({ value }) => {
  const [horizontalMenuPortal, setHorizontalMenuPortal] = useState(() => {
    return getHorizontalMenuPortalElement();
  });

  useMount(() => {
    setHorizontalMenuPortal(getHorizontalMenuPortalElement());
  });

  if (!horizontalMenuPortal) {
    return null;
  }

  return createPortal(
    <div className="page-name">
      <div className="page-name__content">{value || <RoutePageName />}</div>
    </div>,
    horizontalMenuPortal
  );
};

export const withPageName = pageNameContent => Page => props => (
  <Fragment>
    <TabletMobile>
      <PageName value={pageNameContent} />
    </TabletMobile>
    <Page {...props} />
  </Fragment>
);
