import _ from "lodash";

/** Takes an array of objects and replaces name value with its translation */
export const translateMap = (list, intl) =>
  _.map(list, item => ({
    ...item,
    name: intl.formatMessage({
      id: item.name,
      defaultMessage: item.name
    })
  }));
