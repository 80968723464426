import React from "react";
import _ from "lodash";
import classnames from "classnames";

import "./CharacterLimitInfo.scss";

export const DescriptiveError = ({ id, message, validate }) => {
  const classes = classnames({
    "color-danger": !_.isNil(validate())
  });

  return (
    <div id={id} className={classes}>
      {message}
    </div>
  );
};
