import React from "react";
import classnames from "classnames";

import { Button } from "../../../../../components/button/Button";

import "./CheckboxButton.scss";

export const CheckboxButton = ({ isOutline, onClick, children }) => {
  const classNames = classnames("checkbox-button", {
    "checkbox-button--checked": !isOutline
  });

  return (
    <Button
      className={classNames}
      isOutline={isOutline}
      onClick={onClick}
      pillButton
      size={"small"}
    >
      {children}
    </Button>
  );
};
