import React from "react";
import PropTypes from "prop-types";

import { DropdownItemPropTypes } from "@sportal/cdk/dropdown/DropdownItem";
import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import { useIntlTranslate } from "../../hooks";
import { Dropdown } from "../dropdown/Dropdown";
import { Icon } from "../icon/Icon";

import "./DeviceDropdownMenu.scss";

export const DeviceDropdownMenu = ({ menuItems, deviceName }) => {
  const translate = useIntlTranslate();

  const label = deviceName
    ? translate("aria_named_device_actions", {
        deviceName
      })
    : translate("aria_device_actions");

  return (
    <Dropdown
      className={"device-menu"}
      items={menuItems}
      toggleButton={
        <UnstyledButton className="device-menu__toggle-button">
          <Icon icon="fas fa-ellipsis-v" />
        </UnstyledButton>
      }
      label={label}
      hiddenLabel
      disableHorizontalPosition
    />
  );
};

DeviceDropdownMenu.propTypes = {
  menuItems: PropTypes.arrayOf(DropdownItemPropTypes).isRequired,
  deviceName: PropTypes.string
};
