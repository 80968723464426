import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import {
  load,
  fetchTableData,
  searchUrlChange,
  clearAllData,
  getErrorMessage,
  getNode
} from "./store";

import { withLayout } from "../../components/layout/Layout";
import Input from "../../components/input/Input";
import ProgressButton from "../../components/progressButton/ProgressButton";
import { DomainLookupTable } from "./components";
import { useMount } from "@sportal/cdk/hooks";

import "./DomainLookupPage.scss";

export const DomainLookupContent = () => {
  const dispatch = useDispatch();
  const node = useSelector(getNode);
  const error = useSelector(getErrorMessage);

  const handleSubmit = e => {
    e.preventDefault();
  };
  const handleNodeChange = e => {
    dispatch(searchUrlChange(e.target.value));
  };
  const handleNodeClear = () => {
    dispatch(searchUrlChange(""));
    dispatch(clearAllData());
  };

  useMount(() => {
    dispatch(load());

    return () => dispatch(clearAllData());
  });

  return (
    <div className={"domain-lookup"}>
      <h2 className="domain-lookup__header">
        <FormattedMessage id={"domain_lookup"} />
      </h2>
      <div className="domain-lookup__description">
        <FormattedMessage id={"domain_search_tip"} />
      </div>
      <div className={"domain-lookup__content"}>
        <form className="domain-lookup__search-form" onSubmit={handleSubmit}>
          <Input
            className="domain-lookup__input"
            data-testid="domain-lookup-input"
            id="domain-lookup"
            value={node}
            onChange={handleNodeChange}
            onClear={handleNodeClear}
            label={<FormattedMessage id={"domain_name"} />}
            errorMessage={error}
            autoComplete="url"
          />
          <ProgressButton
            type="submit"
            label={<FormattedMessage id="search" />}
            data-testid="domain-lookup-search-button"
            className="domain-lookup__search-button"
            onClick={() => dispatch(fetchTableData(node))}
            disabled={_.isEmpty(node)}
          />
        </form>
        <DomainLookupTable className={"domain-lookup__table"} />
      </div>
    </div>
  );
};

export const DomainLookupPage = withLayout()(DomainLookupContent);
