import _ from "lodash";
import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Desktop, TabletMobile } from "../../hocs/Responsive";
import { Sidebar } from "../sidebar/Sidebar";
import { WizardSidebarMenu } from "../sidebar/WizardSidebarMenu";
import { ProfilePicker } from "../profilePicker/ProfilePicker";

export const WizardSidebarNavigation = ({
  className,
  menuClassName,
  sidebarMenuHeader,
  tabs,
  activeTab,
  activeTabClass,
  onTabClick,
  ...rest
}) => (
  <Fragment>
    <TabletMobile>
      <ProfilePicker
        {...rest}
        activeTab={activeTab}
        activeTabClass={activeTabClass}
        tabs={tabs}
        onTabClick={onTabClick}
      />
    </TabletMobile>
    <Desktop>
      <Sidebar
        tabs={tabs}
        activeTab={activeTab || _.head(tabs).id}
        sidebarMenu={
          <WizardSidebarMenu
            menuClassName={menuClassName}
            sidebarMenuHeader={sidebarMenuHeader}
            tabs={tabs}
            activeTab={activeTab || _.head(tabs).id}
            activeTabClass={activeTabClass}
            onTabClick={onTabClick}
            isInteractive={tabs.length > 1}
          />
        }
      />
    </Desktop>
  </Fragment>
);

WizardSidebarNavigation.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      header: PropTypes.node.isRequired,
      content: PropTypes.node
    })
  ).isRequired,
  onTabClick: PropTypes.func,
  activeTab: PropTypes.string,
  activeTabClass: PropTypes.string,
  menuClassName: PropTypes.string,
  sidebarMenuHeader: PropTypes.node
};
