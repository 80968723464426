import React, { createContext, useContext } from "react";
import { useSelector } from "react-redux";
import * as d3 from "d3";
import _ from "lodash";

import { inRange } from "@sportal/lib";
import { Granularity } from "../../../helpers/reports.helper.types";
import { getTimeBounds } from "../../../helpers/reports.helper";
import { getTimeZone, is24HourFormat } from "../../../store/account";
import { xBandScale, updateXScale, updateYScales } from "./barChartScales";

//calculate Y Offset for each data type
//Y Offset is for the position where bar should begin
const calculateYOffsets = (data, chartSettings) =>
  _.mapValues(_.pick(data, chartSettings.barTypes), (value, key) =>
    chartSettings[key].yOffsets(value)
  );
//calculate height for each data type
//height is for the height of the bar
const calculateHeights = (data, chartSettings) =>
  _.mapValues(_.pick(data, chartSettings.barTypes), (value, key) =>
    chartSettings[key].getHeight(value)
  );

//calculate all ticks for x axis in defined time range
export const calculateTicks = (rawData, duration) => {
  const { start, end } = getTimeBounds(duration);
  const granularity = Granularity[duration];

  if (_.isEmpty(rawData)) {
    return d3.range(start, end, granularity);
  }

  let tick = start;
  const ticks = [];

  do {
    ticks.push(tick);
    tick -= granularity;
  } while (tick > start);

  tick = start + granularity;
  while (tick < end) {
    ticks.push(tick);
    tick += granularity;
  }

  return _.orderBy(ticks);
};

const isInInterval = (time, start, end) => {
  return inRange(time, { start, end }, "[)");
};

// merge collection to one object with summed values
export const mergeObjects = collection => {
  const result = {};

  _.forEach(collection, obj => {
    for (let [key, value] of Object.entries(_.omit(obj, "time"))) {
      if (result[key]) {
        _.isArray(value)
          ? _.concat(result[key], value)
          : (result[key] += value);
      } else {
        result[key] = value;
      }
    }
  });
  return result;
};
//Processing the data and apply them to ticks.
//This processing is need because some reports data came with a different granularity
export const processData = (rawData, ticks, period) => {
  if (!rawData) return [];

  return _.compact(
    _.map(ticks, (tick, i) => {
      const collectionFromInterval = _.filter(rawData, ({ time }) => {
        const start = ticks[i];
        const end = tick[i + 1] || start + Granularity[period];

        return isInInterval(time, start, end);
      });

      if (_.isEmpty(collectionFromInterval)) return;

      const data = mergeObjects(collectionFromInterval);

      return { time: tick, ...data };
    })
  );
};

//extend data from the store with chart-specific properties
export const prepareReportData = (reportWidth, rawData, chartSettings) => {
  if (!rawData || !reportWidth) return [];

  return _.map(rawData, rawSlice => {
    const { time, ...data } = rawSlice;
    const width = xBandScale.bandwidth();

    return {
      time,
      data,
      position: {
        x: xBandScale(time),
        y: calculateYOffsets(data, chartSettings),
        height: calculateHeights(data, chartSettings),
        width
      }
    };
  });
};

export const BarChartContext = createContext({});
export const BarChartProvider = ({
  chartWidth,
  chartHeight,
  chartSettings,
  children,
  bottomChartHeight,
  period,
  data: rawData
}) => {
  const is24 = useSelector(is24HourFormat);
  const tz = useSelector(getTimeZone);

  const ticks = calculateTicks(rawData, period);
  const processedData = processData(rawData, ticks, period);

  updateXScale(ticks, chartWidth);
  updateYScales(processedData, chartHeight - bottomChartHeight, chartSettings);

  const reportData = prepareReportData(
    chartWidth,
    processedData,
    chartSettings
  );

  const contextValue = {
    chartWidth,
    chartHeight,
    bottomChartHeight,
    data: reportData,
    is24,
    period,
    ticks,
    tz,
    xBandScale,
    topOffset: chartHeight - bottomChartHeight
  };

  return (
    <BarChartContext.Provider value={contextValue}>
      {children}
    </BarChartContext.Provider>
  );
};

export const useBarChartContext = () => useContext(BarChartContext);
