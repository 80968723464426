import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog,
  ModalFormControl
} from "../../../modal";
import {
  IdentifiersStubForUnmerge,
  IdentifiersStub
} from "../../commonComponents";
import { DevicesTypes } from "../../../../helpers/devices.helpers";

import "./UnmergeDeviceDialog.scss";

const CLASS_NAME = "unmerge-device-modal";

class UnmergeDeviceDialog extends Component {
  state = {
    removed: ""
  };

  render() {
    const { modal, device } = this.props;

    return (
      <ModalDialog
        className={CLASS_NAME}
        submitHandler={this.handleSubmit}
        rejectHandler={() => modal.reject()}
        disabled={this.isSubmitDisabled()}
      >
        <CommonModalHeader>
          <FormattedMessage id={"unmerge_devices"} />
        </CommonModalHeader>
        <ModalDialog.Body>
          <ModalFormControl>
            <IdentifiersStub
              className={CLASS_NAME}
              value={device.name}
              label={"device_name"}
              id={"device-name"}
            />
          </ModalFormControl>
          {_.map(device.identifiers, (id, idx) => (
            <IdentifiersStubForUnmerge
              key={idx}
              className={CLASS_NAME}
              value={device.identifiers[idx]}
              label={this.getLabel(device.identifiers[idx])}
              removed={this.state.removed === id}
              disabled={this.state.removed && this.state.removed !== id}
              onClick={() => this.handleIconClick(id)}
            />
          ))}
        </ModalDialog.Body>
        <CommonModalFooter submitMessage={<FormattedMessage id="unmerge" />} />
      </ModalDialog>
    );
  }

  getLabel(id) {
    return this.props.idsTypes[id] === DevicesTypes.Wifi
      ? "mac_address"
      : "phone_number";
  }

  isSubmitDisabled() {
    return !this.state.removed;
  }

  handleIconClick(removed) {
    if (this.state.removed === removed) {
      this.setState({ removed: "" });
      return;
    }

    this.setState({ removed });
  }

  handleSubmit = () => {
    if (this.isSubmitDisabled()) return;

    return this.props.modal.resolve(this.state.removed);
  };
}

UnmergeDeviceDialog.propTypes = {
  idsTypes: PropTypes.object,
  device: PropTypes.object
};

export default injectIntl(UnmergeDeviceDialog);
