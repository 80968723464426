import { useSelector } from "react-redux";
import _ from "lodash";

import { getRoamingDevicesByProfile } from "../../../../store/devices/devices.selectors";
import { useRoamingDevicesEnhancer } from "../../../spson/roamingTables/useRoamingDevices";

export const useProfileRoamingDevices = profileName => {
  const roamingDevices = useSelector(
    getRoamingDevicesByProfile(profileName),
    _.isEqual
  );

  return useRoamingDevicesEnhancer(roamingDevices);
};
