import { SafeSearchService } from "../settings";
import { Schedule } from "../schedules";
import { Protection } from "../protections";

interface DefaultProfile {
  id: string; // actually the id is removed when request is send to the backend
  default: true;
  name: "Default";
  "internet-access": true;
  "protection-enabled": true;
  protection: Protection.Light;
  "safe-search": true;
  "safe-search-services": {
    [SafeSearchService.YouTube]: true;
    [SafeSearchService.Google]: true;
    [SafeSearchService.Bing]: true;
  };
  schedules: Schedule[]; // schedules are extracted while setting to the store
}

export interface CustomProfile {
  id: string; // actually the id is removed when request is send to the backend
  default: false;
  name: string;
  "internet-access": boolean;
  "protection-enabled": boolean;
  protection: string;
  "safe-search": boolean;
  "safe-search-services": {
    [SafeSearchService.Google]: boolean;
    [SafeSearchService.YouTube]: boolean;
    [SafeSearchService.Bing]: boolean;
  };
  "age-group": AgeGroup;
  "https-termination": boolean;
  "inspect-stream": boolean;
  devices: []; //TODO: needs to be replaced with device interface
  schedules: Schedule[]; // schedules are extracted while setting to the store
}

// todo: it would be nice to make adjustments in Profile type for both FE and BE usage
export type Profile = DefaultProfile | CustomProfile;

export enum AgeGroup {
  Kids = "kids",
  Teens = "teens",
  Adults = "adults"
}
