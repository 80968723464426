import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { getSavedProfiles } from "../../store/profiles/profiles.selectors";
import { subscriberActivated } from "../../store/account/account.reducer";

class AuthRoute extends Component {
  render() {
    const {
      isRender,
      component,
      subscriberInfo,
      subscriberActivated,
      isSessionExpired,
      sessionExpiryURL,
      ...rest
    } = this.props;

    // returning a new function each time for `component` prop
    // is intend for "backward compatibility"
    return (
      <Route
        {...rest}
        render={isRender ? this.renderFunction : null}
        component={isRender ? null : props => this.renderFunction(props)}
      />
    );
  }

  renderFunction = props => {
    const {
      component: RouteComponent,
      subscriberInfo,
      subscriberActivated,
      isSessionExpired,
      sessionExpiryURL
    } = this.props;

    if (!subscriberInfo.id) {
      if (isSessionExpired && sessionExpiryURL) {
        window.location = sessionExpiryURL;
      } else {
        return <Redirect to="/login" />;
      }
    }

    const isWizard = _.startsWith(props.location.pathname, "/wizard");

    if (!subscriberActivated) {
      return isWizard ? (
        <RouteComponent {...props} />
      ) : (
        <Redirect to="/wizard" />
      );
    }

    return isWizard ? <Redirect to="/home" /> : <RouteComponent {...props} />;
  };
}

export default connect(
  ({
    subscriberInfo,
    account,
    profiles,
    isSessionExpired = false,
    config: { sessionExpiryURL }
  }) => ({
    subscriberInfo: subscriberInfo,
    subscriberActivated: !!(
      subscriberActivated(account) &&
      getSavedProfiles({ profiles }).keys.length !== 0
    ),
    sessionExpiryURL,
    isSessionExpired
  })
)(AuthRoute);
