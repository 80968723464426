import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./List.scss";

import ButtonLink from "../buttonLink/ButtonLink";

export const List = ({
  className,
  title,
  clearText,
  onClear,
  placeholder,
  children
}) => {
  const isEmpty = React.Children.count(children) === 0;

  return (
    <div className={classnames("list", className)}>
      <div className="list-header">
        <div className="list-header__title">{title}</div>
        <ButtonLink onClick={onClear} disabled={isEmpty}>
          {clearText}
        </ButtonLink>
      </div>
      <div className="list-content">
        {isEmpty ? (
          <div className="list-content__placeholder">{placeholder}</div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

List.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  clearText: PropTypes.node.isRequired,
  onClear: PropTypes.func.isRequired,
  placeholder: PropTypes.node.isRequired
};
