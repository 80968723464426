import React from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import Notificator from "../../../../components/notification/notification.actions";
import { getPreviewParams } from "../brandingForm/branding.selectors";
import { ResourceName } from "../../resources.types";
import {
  getInvalidValues,
  removeInvalidValues
} from "../../helpers/form.helper";

export const loadSafety = () => async (dispatch, getState, { api }) => {
  const {
    subscriberInfo: { id }
  } = getState();

  try {
    const fields = await api.ssm.resources.getResource(id, ResourceName.Safety);
    dispatch(loadSafetySuccess(fields));
  } catch (e) {
    dispatch(
      Notificator.error(<FormattedMessage id={"error_failed_to_load_data"} />)
    );
    dispatch(loadSafetyFailure(e));
  }
};

export const changeSafety = () => async (dispatch, getState, { api }) => {
  const {
    subscriberInfo: { id },
    resources: {
      safety: { resource, validation, toSave }
    }
  } = getState();

  const newSafety = _.mapValues(toSave, prop =>
    prop.trim ? prop.trim() : prop
  );

  try {
    const updatedSafety = _.defaults(newSafety, resource),
      validatedSafety = removeInvalidValues(updatedSafety, validation),
      toSave = getInvalidValues(newSafety, validation);

    await api.ssm.resources.updateResource(
      id,
      ResourceName.Safety,
      validatedSafety
    );

    dispatch(changeSafetySuccess(validatedSafety, toSave));
  } catch (e) {
    dispatch(
      Notificator.error(<FormattedMessage id={"couldnt_save_changes"} />)
    );
    dispatch(changeSafetyFailure(e));
    return Promise.reject();
  }
};

export const getSafetyPreview = () => async (dispatch, getState, { api }) => {
  const state = getState(),
    {
      subscriberInfo: { id },
      resources: { safety }
    } = state;

  try {
    const { toSave: newSafety, resource, validation } = safety,
      updatedSafety = _.defaults(newSafety, resource),
      validatedSafety = removeInvalidValues(updatedSafety, validation),
      previewParams = getPreviewParams(validatedSafety)(state);

    const { data } = await api.ssm.resources.getPreview(
      id,
      ResourceName.Safety,
      previewParams
    );
    localStorage.setItem("sub-safety", data);
  } catch (e) {
    dispatch(
      Notificator.error(<FormattedMessage id={"error_failed_to_load_data"} />)
    );
    dispatch(loadSafetyFailure(e));
    return Promise.reject();
  }
};

export const LOAD_SAFETY_SUCCESS = "[RESOURCES] [SAFETY] LOAD_SAFETY_SUCCESS";
export const LOAD_SAFETY_FAILURE = "[RESOURCES] [SAFETY] LOAD_SAFETY_FAILURE";
export const CHANGE_ADMIN_EMAIL_TO_SAVE =
  "[RESOURCES] [SAFETY] CHANGE_ADMIN_EMAIL_TO_SAVE";
export const CHANGE_ADMIN_PHONE_TO_SAVE =
  "[RESOURCES] [SAFETY] CHANGE_ADMIN_PHONE_TO_SAVE";
export const CHANGE_MESSAGE_TO_SAVE =
  "[RESOURCES] [SAFETY] CHANGE_MESSAGE_TO_SAVE";
export const CHANGE_SHOW_MESSAGE_TO_SAVE =
  "[RESOURCES] [SAFETY] CHANGE_SHOW_MESSAGE_TO_SAVE";
export const CHANGE_SAFETY_SUCCESS =
  "[RESOURCES] [SAFETY] CHANGE_SAFETY_SUCCESS";
export const CHANGE_SAFETY_FAILURE =
  "[RESOURCES] [SAFETY] CHANGE_SAFETY_FAILURE";
export const RESET_TO_SAVE = "[RESOURCES] [SAFETY] RESET_TO_SAVE";

export const loadSafetySuccess = safety => ({
  type: LOAD_SAFETY_SUCCESS,
  payload: safety
});
export const loadSafetyFailure = error => ({
  type: LOAD_SAFETY_FAILURE,
  payload: error
});
export const changeAdminEmailToSave = adminEmail => ({
  type: CHANGE_ADMIN_EMAIL_TO_SAVE,
  payload: adminEmail
});
export const changeAdminPhoneToSave = adminPhone => ({
  type: CHANGE_ADMIN_PHONE_TO_SAVE,
  payload: adminPhone
});
export const changeMessageToSave = message => ({
  type: CHANGE_MESSAGE_TO_SAVE,
  payload: message
});
export const changeShowMessageToSave = () => ({
  type: CHANGE_SHOW_MESSAGE_TO_SAVE
});
export const changeSafetySuccess = (safety, toSave) => ({
  type: CHANGE_SAFETY_SUCCESS,
  payload: { safety, toSave }
});
export const changeSafetyFailure = error => ({
  type: CHANGE_SAFETY_FAILURE,
  payload: error
});
export const resetToSave = () => ({
  type: RESET_TO_SAVE
});
