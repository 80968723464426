import { MutableRefObject } from "react";
import _ from "lodash";

export interface TabWidths {
  tabWidths: number[];
  totalTabsWidth: number;
}

export function getWidth(element: MutableRefObject<Element>): number {
  // please note that it doesn't include margin, so space distribution must be set by paddings
  return Math.ceil(element.current.getBoundingClientRect().width);
}

export function getWidths(
  tabsRef: MutableRefObject<HTMLUListElement>
): TabWidths {
  const tabs = _.toArray(tabsRef.current.querySelectorAll(".tab-item"));
  const widths = tabs.map(tab => getWidth({ current: tab }));

  return {
    tabWidths: widths,
    totalTabsWidth: _.sum(widths)
  };
}

const SELECT_WIDTH = 100;
export function getHiddenStart(
  wrapperWidth: number,
  tabsWidths: number[]
): number {
  const maxWidth = wrapperWidth - SELECT_WIDTH;

  let accumulatedWidth = 0;
  let index = 0;

  while (accumulatedWidth < maxWidth && index < tabsWidths.length) {
    const tabWidth = tabsWidths[index];

    if (accumulatedWidth + tabWidth >= maxWidth) break;

    accumulatedWidth += tabWidth;
    index++;
  }

  return index;
}

export function doesTabsFit(wrapperWidth: number, tabsWidth: number): boolean {
  return wrapperWidth >= tabsWidth;
}
