import _ from "lodash";
import { BaseApiService } from "../baseApiService";

const defaultProfile = "all_profiles";

export class SeriesService extends BaseApiService {
  constructor(config) {
    super(config);
    this.baseUrl = `${config.backendUrl}/ssm/series`;
  }

  getReportData({
    id,
    key,
    type,
    period = 1,
    profile,
    limit,
    withGranularity = false
  }) {
    const granularity = withGranularity ? this._getGranularity(period) : "";
    const url = `${this.baseUrl}/${key}${granularity}`;

    return this._http
      .get(url, {
        params: {
          view: id,
          duration: this._formatPeriod(period),
          ...this._addGroupName(type, profile),
          ...this._addLimit(type, limit)
        }
      })
      .then(({ data }) => data);
  }

  _formatPeriod = period => `P${period}D`;

  _addGroupName = (type, profile) =>
    type !== "widgets" && profile !== defaultProfile
      ? { group_name: profile }
      : {};

  _addLimit = (type, limit) =>
    type === "table" && !_.isNil(limit) ? { limit } : {};

  _getGranularity(period) {
    switch (+period) {
      case 7:
        return "2h";
      case 30:
        return "8h";
      default:
        return "15m";
    }
  }
}
