import _ from "lodash";
import {
  LOAD_LOGO_SUCCESS,
  CHANGE_PALETTE_SUCCESS,
  CHANGE_TAGLINE_TO_SAVE,
  CHANGE_COLOR_TO_SAVE,
  CHANGE_LOGO_SUCCESS,
  RESET_TO_SAVE,
  CHANGE_LOGO_TO_SAVE
} from "./branding.actions";
import { LOAD_BRANDING_VALIDATIONS } from "../../resources.actions";
import { setValidation } from "../reducer.helpers";
import { LOAD_WEB_CONTENT_SUCCESS } from "../webFilteringForm/webContent.actions";
import { LOAD_SAFETY_SUCCESS } from "../malwareForm/safety.actions";

const initialState = {
  color: null,
  tagline: null,
  logo: null,
  toSave: {
    color: null,
    tagline: null,
    logo: null
  },
  validation: {
    tagline: null
  }
};

export const brandingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BRANDING_VALIDATIONS: {
      return {
        ...state,
        validation: setValidation(action.payload, Object.keys(state.validation))
      };
    }
    case LOAD_SAFETY_SUCCESS:
    case LOAD_WEB_CONTENT_SUCCESS: {
      const { color, tagline } = action.payload;

      return {
        ...state,
        tagline,
        color,
        toSave: {
          ...initialState.toSave,
          logo: state.toSave.logo
        }
      };
    }
    case LOAD_LOGO_SUCCESS:
    case CHANGE_LOGO_SUCCESS: {
      const { logo } = action.payload;

      return {
        ...state,
        logo,
        toSave: {
          ...state.toSave,
          logo
        }
      };
    }
    case CHANGE_PALETTE_SUCCESS: {
      const { color, tagline } = action.payload;

      return {
        ...state,
        color,
        tagline,
        toSave: {
          ...state.toSave,
          color: initialState.toSave.color,
          tagline: initialState.toSave.tagline
        }
      };
    }
    case CHANGE_COLOR_TO_SAVE: {
      return {
        ...state,
        toSave: {
          ...state.toSave,
          color: action.payload
        }
      };
    }
    case CHANGE_TAGLINE_TO_SAVE:
      return {
        ...state,
        toSave: {
          ...state.toSave,
          tagline: action.payload
        }
      };
    case CHANGE_LOGO_TO_SAVE: {
      const { logo } = action.payload;

      return {
        ...state,
        toSave: {
          ...state.toSave,
          logo: _.isUndefined(logo) ? null : logo
        }
      };
    }
    case RESET_TO_SAVE: {
      return {
        ...state,
        toSave: {
          ...initialState.toSave,
          logo: state.logo
        }
      };
    }
    default:
      return state;
  }
};
