import { BaseApiService } from "../baseApiService";

export class ProfileService extends BaseApiService {
  constructor(config) {
    super(config);
    this.baseUrl = `${config.backendUrl}/ssm/${config.mode}`;
  }

  get(subscriberId) {
    return this._http
      .get(`${this.baseUrl}/${subscriberId}/profile`)
      .then(({ data }) => data);
  }

  create(subscriberId, profiles) {
    return this._http.post(`${this.baseUrl}/${subscriberId}/profile`, profiles);
  }

  save(subscriberId, profileName, updates) {
    return this._http.patch(
      `${this.baseUrl}/${subscriberId}/profile/${profileName}`,
      updates
    );
  }

  remove(subscriberId, profileName) {
    return this._http.delete(
      `${this.baseUrl}/${subscriberId}/profile/${profileName}`
    );
  }

  getCustomProtection(subscriberId, profileName) {
    return this._http
      .get(`${this.baseUrl}/${subscriberId}/profile/${profileName}/protection`)
      .then(({ data }) => data);
  }

  saveCustomProtection(subscriberId, profileName, categories) {
    return this._http.post(
      `${this.baseUrl}/${subscriberId}/profile/${profileName}/protection`,
      categories
    );
  }
}
