import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import { withLayout } from "../../components/layout/Layout";
import { getNoLoginPage } from "../../store/config.selectors";
import { isSSO } from "../../store/info/info.selectors";
import { CommonLogin, NoLogin, SsoLogin, useEulaDialog } from "./components";

import "./LoginPage.scss";

const LoginPage = () => {
  const noLoginPage = useSelector(getNoLoginPage);
  const authSSO = useSelector(isSSO);

  useEulaDialog();

  return (
    <div className="login-page row">
      {noLoginPage ? <NoLogin /> : authSSO ? <SsoLogin /> : <CommonLogin />}
    </div>
  );
};

export default _.flowRight(
  withRouter,
  withLayout({
    showHeaderUnderline: true,
    showHelpLink: true,
    showUserInfo: false,
    showNavigation: false
  })
)(LoginPage);
