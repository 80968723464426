import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  changeAdminEmailToSave,
  changeAdminPhoneToSave,
  changeMessageToSave,
  changeShowMessageToSave,
  loadSafety,
  changeSafety,
  resetToSave,
  getSafetyPreview
} from "./safety.actions";
import { AdminInfo } from "../../components/adminInfo/AdminInfo";
import { TabControls } from "../../components/tabControls/TabControls";
import { withPromptDialog } from "../../../../hocs/withPrompt";
import { useMalwareForm } from "./useMalwareForm";
import { useMount } from "@sportal/cdk/hooks";
import { getFromSafetyValidation, isSafetyFetched } from "./safety.selectors";
import { BlockingMessage } from "../../components/blockingMessage/BlockingMessage";
import { previewPage } from "../shared.actions";

const MalwareFormContent = withPromptDialog(({ hasChanges, save, discard }) => {
  const dispatch = useDispatch();

  const isFetched = useSelector(isSafetyFetched);
  const rules = useSelector(getFromSafetyValidation("rules"), _.isEqual);

  const { isValid, formValues, isEmailPatternValid } = useMalwareForm();

  useMount(() => {
    !isFetched && dispatch(loadSafety());
  });

  const preview = async () =>
    await previewPage(dispatch, getSafetyPreview(), "safety");

  return (
    <div className="malware-form">
      <h2 className={"blocked-page__title"}>
        <FormattedMessage id={"malware_and_phishing"} />
      </h2>
      <TabControls
        isFormValid={isValid}
        isFormUnchanged={!hasChanges}
        onReset={discard}
        onSave={save}
        onPreview={preview}
      />
      <BlockingMessage
        showMessage={formValues.showMessage}
        toggleMessage={() => dispatch(changeShowMessageToSave())}
        changeMessage={newMessage => dispatch(changeMessageToSave(newMessage))}
        message={formValues.message}
        messageValidation={rules.message}
      />
      <AdminInfo
        email={{
          value: formValues.adminEmail,
          action: adminEmail => dispatch(changeAdminEmailToSave(adminEmail)),
          validation: rules.adminEmail,
          isEmailPatternValid
        }}
        phone={{
          value: formValues.adminPhone,
          action: adminPhone => dispatch(changeAdminPhoneToSave(adminPhone)),
          validation: rules.adminPhone
        }}
      />
    </div>
  );
});

export const MalwareForm = () => {
  const dispatch = useDispatch();
  const { hasChanges } = useMalwareForm();

  const save = () => dispatch(changeSafety());
  const discard = () => dispatch(resetToSave());

  return (
    <MalwareFormContent hasChanges={hasChanges} save={save} discard={discard} />
  );
};
