import React, { FC, useRef } from "react";
import * as d3 from "d3";

import { useElementDimensions } from "@sportal/cdk/hooks";
import { LineChart, GradientAreaChart } from "./components";
import { ReportSlice } from "../../../helpers/reports.helper.types";

import "./WidgetLineChart.scss";

const HEIGHT = 60;

interface WidgetLineChartProps {
  id: string;
  data: ReportSlice[];
}

const isEmptyData = (data: ReportSlice[]): boolean => {
  return data.every(report => report.value === 0);
};

export const WidgetLineChart: FC<WidgetLineChartProps> = ({ id, data }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { width } = useElementDimensions(wrapperRef);

  const xMinMax = d3.extent(data, report => report.time);
  const yMinMax = isEmptyData(data)
    ? [0, 1] // otherwise it will draw chart in the middle of svg
    : d3.extent(data, report => report.value);

  const walkX = d3
    .scaleLinear()
    .domain(xMinMax)
    .range([5, width - 5]);

  const walkY = d3
    .scaleLinear()
    .domain(yMinMax)
    .range([HEIGHT - 5, 5]);

  const line = d3
    .line<ReportSlice>()
    .curve(d3.curveMonotoneX)
    .x(report => walkX(report.time))
    .y(report => walkY(report.value));

  const area = d3
    .area<ReportSlice>()
    .curve(d3.curveMonotoneX)
    .x(report => walkX(report.time))
    .y0(HEIGHT)
    .y1(report => walkY(report.value));

  return (
    <div ref={wrapperRef} id={id} className="widget-line-chart">
      {width && (
        <svg viewBox={`0 0 ${width} ${HEIGHT}`}>
          <GradientAreaChart id={id} path={area(data)} />
          <LineChart id={id} path={line(data)} />
        </svg>
      )}
    </div>
  );
};
