import React from "react";
import { FormattedMessage } from "react-intl";

import { HorizontalMenuLink } from "./HorizontalMenuLink";

export const SubMenuItem = ({ item: { id, text, baseUrl, url }, pathname }) => {
  const isNavLinkActive = () => pathname.includes(baseUrl || url);

  return (
    <li data-id={id} className="hm__item">
      <HorizontalMenuLink to={url} isActive={isNavLinkActive}>
        <FormattedMessage id={text} />
      </HorizontalMenuLink>
    </li>
  );
};
