import { combineReducers } from "redux";
import { reducer as urlFiltersReducer } from "../pages/urlFilters/urlFilters.reducer";
import { reducer as loaderReducer } from "../components/loader/loader.reducer";
import { reducer as notificationReducer } from "../components/notification/notification.reducer";
import { reducer as profilesReducer } from "./profiles/profiles.reducer";
import { reducer as ssProfilesReducer } from "../pages/profiles/components/internetSecurity/subscriberSafety/ssProfiles.reducer";
import { reducer as reportsReducer } from "./reports/reports.reducer";
import { reducer as webFiltersReducer } from "../pages/profiles/components/webFilters/webFilters.reducer";
import { reducer as domainLookup } from "../pages/domainLookup/store";
import {
  InfoState,
  reducer as subscriberInfoReducer
} from "./info/info.reducer";
import { reducer as accountSettingsReducer } from "./attributes/attributes.reducer";
import { reducer as resourcesReducer } from "../pages/blockPageContent/resources.reducer";
import { reducer as settingsReducer } from "./settings/settings.reducer";
import accountReducer from "./account/account.reducer";
import initialReducer from "./initial/initial.reducer";
import { reducer as categoriesReducer } from "./categories/categories.reducer";
import { reducer as protectionsReducer } from "./protections/protections.reducer";
import devicesReducer from "./devices/devices.reducer";
import threatsReducer from "./threats/threats.reducer";
import { reducer as schedulesReducer } from "../pages/profiles/components/schedule/schedules.reducer";
import { reducer as helpReducer } from "../pages/help/help.reducer";
import { reducer as translationReducer } from "../i18n/translation.reducer";
import { reducer as historyReducer } from "./history.reducer";
import { deeplinkReducer } from "../components/deeplinkDialog/deeplinkDialog.reducer";

import {
  LOGIN_FAILURE,
  RESET_USER,
  SET_SESSION_EXPIRED
} from "../pages/login/login.actions";
import { CHANGE_LANGUAGE_SUCCESS } from "./attributes/attributes.actions";

const featureReducers = combineReducers({
  config: (state: any = {}) => state,
  isSessionExpired: () => false,
  initialLoad: initialReducer,
  translation: translationReducer,
  loader: loaderReducer,
  notifications: notificationReducer,
  subscriberInfo: subscriberInfoReducer,
  settings: settingsReducer,
  account: accountReducer,
  accountSettings: accountSettingsReducer,
  resources: resourcesReducer,
  profiles: profilesReducer,
  ssProfiles: ssProfilesReducer,
  webFilters: webFiltersReducer,
  schedules: schedulesReducer,
  categories: categoriesReducer,
  protections: protectionsReducer,
  urlFilters: urlFiltersReducer,
  domainLookup: domainLookup,
  reports: reportsReducer,
  devices: devicesReducer,
  help: helpReducer,
  threats: threatsReducer,
  history: historyReducer,
  deepLink: deeplinkReducer,
});

type FeatureState = ReturnType<typeof featureReducers>;

const rootReducer = (state: FeatureState, action) => {
  switch (action.type) {
    case LOGIN_FAILURE:
    case RESET_USER:
      const {
        initialLoad,
        subscriberInfo,
        config,
        loader,
        translation,
        help,
        notifications: { list },
        history
      } = state;

      return {
        ...featureReducers({} as any, action),
        subscriberInfo: { authMethod: subscriberInfo.authMethod } as InfoState,
        initialLoad,
        config,
        loader,
        translation,
        help,
        notifications: { list },
        history
      };
    case SET_SESSION_EXPIRED: {
      const {
        initialLoad,
        subscriberInfo,
        config,
        loader,
        translation,
        help,
        notifications: { list },
        history
      } = state;

      return {
        ...featureReducers({} as any, action),
        isSessionExpired: true,
        subscriberInfo: { authMethod: subscriberInfo.authMethod } as InfoState,
        initialLoad,
        config,
        loader,
        translation,
        help,
        notifications: { list },
        history
      };
    }
    case CHANGE_LANGUAGE_SUCCESS:
      return {
        ...featureReducers(state, action),
        threats: threatsReducer(undefined, action)
      };
    default:
      return {
        ...state,
        ...featureReducers(state, action)
      };
  }
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
