import _ from "lodash";
import { LOAD_FAILURE, LOAD_SUCCESS, SET_SEARCH_RESULT } from "./help.actions";

/*
interface HelpState extends HelpError {
  sections: {
    id: string;
    level: number;
    header: { text: string; html: string; };
    content: { html: string; };
  }[];
  searchResult: { [sectionName: string]: number; };
  error?: {
    // Translation key
    key: string;
  };
}
*/

const initialState = {
  sections: [],
  searchResult: {}
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SUCCESS:
      return { ...state, sections: action.payload };
    case LOAD_FAILURE:
      return { ...state, error: action.payload };
    case SET_SEARCH_RESULT:
      return { ...state, searchResult: action.payload };
    default:
      return state;
  }
};

export const getHelp = state => {
  const { help } = state;

  const hasNoMatches = !_.some(help.searchResult);

  if (hasNoMatches) {
    return {
      ...help,
      hasNoMatches
    };
  }

  return {
    ...help,
    hasNoMatches,
    sections: _.map(help.sections, section => ({
      ...section,
      isHidden: help.searchResult[section.id] === 0
    }))
  };
};
