import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { getInitialData } from "./store/initial/initial.actions";
import AuthRoute from "./components/authRoute/AuthRoute";
import { RouterHook } from "./router";
import { NotificationContainer } from "./components/notification/NotificationContainer";
import LoginPage from "./pages/login/LoginPage";
import { HomePage } from "./pages/home/HomePage";
import DrilldownPage from "./pages/drilldown/DrilldownPage";
import GlobalUrlFiltersPage from "./pages/urlFilters/components/urlFiltersPage/GlobalUrlFiltersPage";
import AccountSettingsPage from "./pages/accountSettings/AccountSettingsPage";
import { ScheduledReportsPage } from "./pages/scheduledReports/ScheduledReportsPage";
import BlockPageContentPage from "./pages/blockPageContent/BlockPageContentPage";
import { SettingsPage } from "./pages/settings/SettingsPage";
import {
  MultipleWizardPage,
  SingleWizardPage,
  ProtectionChoicePage
} from "./pages/wizard";
import { WebContentPreviewPage } from "./pages/blockPageContent/WebContentPreviewPage";
import { SafetyPreviewPage } from "./pages/blockPageContent/SafetyPreviewPage";
import { HelpPage } from "./pages/help/HelpPage";
import { DomainLookupPage } from "./pages/domainLookup/DomainLookupPage";
import WithIntl from "./i18n/withIntl";
import ErrorPage from "./pages/errorPage/errorPage";
import { removeAllStandard } from "./components/notification/notification.actions";
import { Loader } from "./components/loader";
import { SpsonPage } from "./pages/spson/SpsonPage";
import { ModalHost } from "./components/modal/Modal";
import { sizes } from "./hocs/Responsive";
import { AppRouter } from "./router";
import { isScheduleReportAvailable } from "./store/config.selectors";
import { useEulaContext, LayoutContext } from "./components/layout";
import { useMount } from "@sportal/cdk/hooks";

const App = ({
  getInitialData,
  initialLoad,
  removeAllNotifications,
  showScheduledReports
}) => {
  const layoutContext = useEulaContext();

  useMount(() => {
    getInitialData();
  });

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return !initialLoad.completed ? (
    <Loader />
  ) : (
    <WithIntl>
      <LayoutContext.Provider value={layoutContext}>
        <AppRouter>
          <RouterHook action={scrollToTop} />
          <RouterHook action={removeAllNotifications} />
          <NotificationContainer />
          <Switch>
            <AuthRoute path="/home" component={HomePage} />
            <AuthRoute
              path="/wizard/protection-choice"
              component={ProtectionChoicePage}
            />
            <AuthRoute path="/wizard/multiple" component={MultipleWizardPage} />
            <AuthRoute path="/wizard/single" component={SingleWizardPage} />
            <Redirect from="/wizard" to="/wizard/protection-choice" />
            <AuthRoute path="/drilldown/:page" component={DrilldownPage} />
            <AuthRoute path={"/settings/sps-remote"} component={SpsonPage} />
            <AuthRoute
              exact
              path="/settings/global-url-filters"
              component={GlobalUrlFiltersPage}
            />
            <AuthRoute
              exact
              path="/settings/account"
              component={AccountSettingsPage}
            />
            {showScheduledReports && (
              <AuthRoute
                exact
                path="/settings/scheduled-reports"
                component={ScheduledReportsPage}
                isRender={true}
              />
            )}
            <AuthRoute
              path="/settings/blocked-pages"
              component={BlockPageContentPage}
              isRender={true}
            />
            <AuthRoute
              exact
              path="/preview/web-filtering"
              component={WebContentPreviewPage}
            />
            <AuthRoute
              exact
              path="/settings/domain-lookup"
              component={DomainLookupPage}
            />
            <AuthRoute
              exact
              path="/preview/safety"
              component={SafetyPreviewPage}
            />
            <AuthRoute
              path="/settings"
              component={SettingsPage}
              isRender={true}
            />
            <Redirect exact strict from="/help" to="/help/" />
            <Route
              exact
              path="/help/:section?"
              render={props => (
                <HelpPage
                  {...props}
                  eulaHeaderHeight={layoutContext.eulaHeaderHeight}
                />
              )}
            />
            <Route exact path="/error/:type?" component={ErrorPage} />
            <Route exact path="/login" component={LoginPage} />
            <Redirect to="/home" />
          </Switch>
          <ModalHost mobileBreakpoint={sizes.mobileMax} />
        </AppRouter>
      </LayoutContext.Provider>
    </WithIntl>
  );
};

export default connect(
  state => {
    return {
      initialLoad: state.initialLoad,
      showScheduledReports: isScheduleReportAvailable(state)
    };
  },
  { removeAllNotifications: removeAllStandard, getInitialData }
)(App);
