import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { SectionHeader } from "../../../components/sectionHeader/SectionHeader";
import { ColorPickerPreview } from "../../../components/colorPicker/ColorPickerPreview";
import ColorPicker from "../../../components/colorPicker/ColorPicker";

export const Color = ({ color, onChange }) => (
  <section className="branding__color col-md-6">
    <SectionHeader title={<FormattedMessage id={"icon_and_link_color"} />} />
    <div className={"branding__color__content"}>
      <div className={"picker"}>
        <div id="color-picker-label" className="picker__label">
          <FormattedMessage id="selected_color" />
        </div>
        <ColorPicker
          color={color}
          onColorChange={onChange}
          ariaLabelledBy="color-picker-label"
        />
      </div>
      <div className={"preview"}>
        <div className={"preview__label"}>
          <FormattedMessage id={"preview"} />
        </div>
        <ColorPickerPreview color={color} />
      </div>
    </div>
  </section>
);

Color.propTypes = {
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired
};
