import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { useIntlTranslate } from "../../../hooks";
import { Desktop, TabletMobile } from "../../../hocs/Responsive";
import { HorizontalScrollTabs } from "../../horizontalScrollTabs/HorizontalScrollTabs";
import { ProfileGroups } from "../../../store/profiles/fixedProfiles";
import { getVisibleProfiles } from "../../../store/profiles/profiles.selectors";
import { getSelectedReportsProfile } from "../../../store/reports/reports.selectors";
import { TabsSelect } from "../../tabsSelect/TabsSelect";

const prepareProfiles = allProfiles => {
  const profileToExclude = ProfileGroups.Blocked;

  const profiles = _.reject(_.values(allProfiles), {
    name: profileToExclude
  });

  return [{ name: "all_profiles", type: "all_groups" }, ...profiles];
};

export const ProfileSelector = ({ onProfileChange }) => {
  const translate = useIntlTranslate();
  const profiles = useSelector(getVisibleProfiles, _.isEqual);
  const selectedProfile = useSelector(getSelectedReportsProfile);

  const tabs = useMemo(
    () =>
      _.map(prepareProfiles(profiles), p => ({
        id: p.name,
        content: p.type === "custom" ? p.name : translate(p.type)
      })),
    [profiles, translate]
  );

  if (_.isEmpty(profiles)) return null;

  return (
    <div className={"tabs-wrapper"}>
      <TabletMobile>
        <HorizontalScrollTabs
          tabs={tabs}
          selected={selectedProfile}
          onClick={selected => onProfileChange(selected)}
        />
      </TabletMobile>
      <Desktop>
        <TabsSelect
          items={tabs}
          selected={selectedProfile}
          onClick={selected => onProfileChange(selected)}
        />
      </Desktop>
    </div>
  );
};
