import { parseMarkdown } from "./parseMarkdown";
import { getLanguageKey } from "../../store/root.selectors";

export const loadHelp = () => (dispatch, getState, { api }) => {
  const state = getState();
  const { config } = state;
  const language = getLanguageKey(state);
  const defaultLanguage = config.languages.default;

  return api.resources
    .getHelp(language, defaultLanguage)
    .then(mdContent => {
      dispatch(loadHelpSuccess(parseMarkdown(mdContent, language)));
    })
    .catch(error => {
      dispatch(loadHelpFailure({ key: "error_failed_to_load_data" }));
      // eslint-disable-next-line no-console
      console.error(error);
    });
};

export const LOAD_SUCCESS = "[HELP] LOAD_SUCCESS";
export const loadHelpSuccess = sections => ({
  type: LOAD_SUCCESS,
  payload: sections
});

export const LOAD_FAILURE = "[HELP] LOAD_FAILURE";
export const loadHelpFailure = error => ({
  type: LOAD_FAILURE,
  payload: error
});

export const SET_SEARCH_RESULT = "[HELP] SET_SEARCH_RESULT";
export const setSearchResult = searchResult => ({
  type: SET_SEARCH_RESULT,
  payload: searchResult
});
