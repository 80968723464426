import React from "react";
import _ from "lodash";
import { AnyAction } from "redux";
import { FormattedMessage } from "react-intl";

import { Profile } from "@sportal/api";
import {
  getAddedItems,
  getDefaultProfile,
  shouldSaveChangesToBackEnd
} from "./profiles.selectors";
import {
  ensureProtection,
  inferProtectionByBlocked
} from "../../pages/profiles/components/webFilters/webFilters.actions";
import { getSchedulesSuccess } from "../../pages/profiles/components/schedule/schedules.actions";
import {
  addSsProfileRequest,
  addSsProfiles,
  addSsProfileWithDefault,
  getSsProfiles,
  removeSsProfile
} from "../../pages/profiles/components/internetSecurity/subscriberSafety/ssProfiles.actions";
import { SBThunkAction } from "../redux.types";
import { FIXED_PROFILES } from "./fixedProfiles";
import { loadSharedProtections } from "../protections/protections.actions";
import { createProfilesRequest } from "./createProfiles.actions";
import { construct, rehydrate, sortProfiles } from "./profiles.helpers";
import {
  ADD_PROFILE,
  ADD_PROFILE_FAILED,
  AddProfileAction,
  AddProfileFailedAction,
  CREATE_PROFILES_SUCCESS,
  CreateProfilesSuccessAction,
  GET_PROFILES_FAILURE,
  GET_PROFILES_SUCCESS,
  GetProfilesFailureAction,
  GetProfilesSuccessAction,
  ProfileError,
  REMOVE_PROFILE,
  REMOVE_PROFILE_SUCCESS,
  RemoveProfileAction,
  RemoveProfileSuccessAction,
  RESET_ALL_PROFILES,
  RESET_PROFILE,
  ResetProfileAction,
  ResetProfilesAction,
  SBProfile,
  SET_INITIAL_PROFILES,
  SET_SELECTED_PROFILE,
  SetInitialProfilesAction,
  SetSelectedProfileAction
} from "./profiles.types";
import { RemoveSsProfileAction } from "../../pages/profiles/components/internetSecurity/subscriberSafety/ssProfiles.types";
import { RemoveProfileDialog } from "../../pages/profiles/components/removeProfile/RemoveProfileDialog";
import Notificator from "../../components/notification/notification.actions";
import { ModalService } from "../../components/modal";
import ProfileDialog from "../../pages/profiles/components/profileDialog/ProfileDialog";
import { AddNotificationAction } from "../../components/notification/notification.types";
import { history } from "../../router";

// TODO: replace 'AnyAction' type with return type of getSchedulesSuccess once we have it
export const getProfiles = (): SBThunkAction<
  Promise<void>,
  GetProfilesSuccessAction | GetProfilesFailureAction | AnyAction
> => (dispatch, getState, { api }) => {
  return api.ssm.profile
    .get(getState().subscriberInfo.id)
    .then(({ content }) => {
      const state = getState();
      const profiles = _.map(content, partialProfile =>
        rehydrate(partialProfile, state)
      );
      const sortedProfiles = sortProfiles(profiles);

      dispatch(getProfilesSuccess(sortedProfiles));
      dispatch(getSchedulesSuccess(sortedProfiles));
    })
    .catch(error => {
      dispatch(getProfilesFailure(error));
      return Promise.reject(error);
    })
    .then(() => dispatch(getSsProfiles()));
};

const getProfilesSuccess = (
  profiles: SBProfile[]
): GetProfilesSuccessAction => ({
  type: GET_PROFILES_SUCCESS,
  payload: profiles
});

const getProfilesFailure = (error: ProfileError): GetProfilesFailureAction => ({
  type: GET_PROFILES_FAILURE,
  payload: error
});

export const setInitialProfiles = ({
  isSingle
}): SBThunkAction<
  Promise<void>,
  SetInitialProfilesAction | ResetProfilesAction
> => (dispatch, getState) => {
  // We need to have protections before constructing the profiles
  return dispatch(loadSharedProtections()).then(() => {
    const state = getState();
    const profiles = _.map(
      isSingle ? _.take(FIXED_PROFILES) : FIXED_PROFILES,
      profile => construct(profile, state)
    );

    dispatch(resetProfiles()); // TODO: change reducer logic so that SET_INITIAL_PROFILES includes resetting
    dispatch({
      type: SET_INITIAL_PROFILES,
      payload: profiles
    });
    _.forEach(profiles, profile => dispatch(ensureProtection(profile)));
    dispatch(addSsProfiles());
    return Promise.resolve();
  });
};

export const addProfile = (
  partialProfile: any
): SBThunkAction<
  Promise<void>,
  AddProfileAction | SetSelectedProfileAction
> => (dispatch, getState) => {
  const profile = construct(partialProfile, getState());
  dispatch({
    type: ADD_PROFILE,
    payload: profile
  });
  dispatch(ensureProtection(profile)); // TODO: rework, it is possible to "fix" profile before adding it to the store
  // TODO: make consistent with addSsProfiles or even merge them
  dispatch(addSsProfileWithDefault(profile.id));
  dispatch(setSelectedProfile(profile.id));
  return Promise.resolve();
};

// Saves added (with ADD_PROFILE) profiles.
// There is no bunch update operation so it is pretty clear
export const saveProfilesRequest = (): SBThunkAction<void, AnyAction> => (
  dispatch,
  getState
) => {
  let profiles = getAddedItems(getState().profiles);

  _.forEach(profiles, profile => dispatch(ensureProtection(profile)));
  profiles = getAddedItems(getState().profiles);

  return dispatch(createProfilesRequest(sortProfiles(profiles)));
};

export const openAddDialog = (profiles): SBThunkAction<void, AnyAction> => (
  dispatch,
  getState
) => {
  const sendRequest = shouldSaveChangesToBackEnd(getState());

  ModalService.show(modal => ({
    dialog: (
      <ProfileDialog
        profiles={profiles}
        modal={modal}
        title={"add_new_group"}
        submitText={"add_group"}
      />
    )
  }))
    .then(name =>
      sendRequest
        ? dispatch(addProfileRequest({ name }))
        : dispatch(addProfile({ name }))
    )
    .catch(() => null);
};

export const addProfileRequest = (
  partialProfile: any
): SBThunkAction<
  void,
  SetSelectedProfileAction | AddProfileFailedAction | AddNotificationAction
> => (dispatch, getState) => {
  const state = getState();
  const profile = construct(partialProfile, state);

  const protectionByBlocked = inferProtectionByBlocked(state, profile);
  if (protectionByBlocked) {
    profile.protection = protectionByBlocked.name;
  }

  return (
    dispatch(createProfilesRequest([profile]))
      // TODO: better error handling, now failed ss does not deny profile creation
      .then(([{ id: newId }]) => {
        history.push(`/settings/profiles/${newId}`);
        return dispatch(addSsProfileRequest(newId));
      })
      .catch(({ error }) => {
        dispatch(addProfileFailed(error));
        dispatch(
          Notificator.error(
            <FormattedMessage id={"failed_to_create_new_profile"} />
          )
        );
        return Promise.reject();
      })
  );
};

export const createProfilesSuccess = (
  profiles: SBProfile[]
): CreateProfilesSuccessAction => ({
  type: CREATE_PROFILES_SUCCESS,
  payload: profiles
});

export const setSelectedProfile = (
  selectedProfileId: string
): SetSelectedProfileAction => ({
  type: SET_SELECTED_PROFILE,
  payload: selectedProfileId
});

export const removeProfile = (id: string): RemoveProfileAction => ({
  type: REMOVE_PROFILE,
  payload: id
});

export const openConfirmationDialog = (
  profile: SBProfile
): SBThunkAction<void, any> => (dispatch, getState) => {
  const sendRequest = shouldSaveChangesToBackEnd(getState());

  ModalService.show(modal => ({
    dialog: <RemoveProfileDialog modal={modal} profileName={profile.name} />
  }))
    .then(() =>
      sendRequest
        ? dispatch(removeProfileRequest(profile.id))
        : dispatch(removeProfile(profile.id))
    )
    .catch(() => null);
};

export const removeProfileRequest = (
  id: string
): SBThunkAction<
  Promise<void>,
  | RemoveProfileAction
  | RemoveProfileSuccessAction
  | RemoveSsProfileAction
  | AddNotificationAction
> => (dispatch, getState, { api }) => {
  const state = getState();
  const { subscriberInfo } = state;
  const profile = state.profiles.saved.list[id];
  const defaultProfile = getDefaultProfile(state) as Profile;

  // TODO: write a comment about the case handled by the following check (atm idk about it)
  if (!profile) {
    return Promise.resolve();
  }

  return api.ssm.profile
    .remove(subscriberInfo.id, profile.name)
    .then(() => {
      dispatch(removeProfile(id));
      dispatch(removeSsProfile(id));
      dispatch(removeProfileSuccess(id, profile.name, defaultProfile.name));
    })
    .catch(() => {
      dispatch(
        Notificator.error(<FormattedMessage id={"failed_to_remove_profile"} />)
      );
      return Promise.reject();
    });
};

export const removeProfileSuccess = (
  profileId: string,
  currentName: string,
  defaultProfileName: string
): RemoveProfileSuccessAction => ({
  type: REMOVE_PROFILE_SUCCESS,
  payload: { id: profileId, currentName, newName: defaultProfileName } //TODO: remove name when devices use id
});

export const resetProfiles = (): ResetProfilesAction => ({
  type: RESET_ALL_PROFILES
});

export const resetProfile = (profileId: string): ResetProfileAction => ({
  type: RESET_PROFILE,
  payload: {
    id: profileId
  }
});

export const addProfileFailed = (
  error: ProfileError
): AddProfileFailedAction => ({
  type: ADD_PROFILE_FAILED,
  payload: error
});
