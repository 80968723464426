import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./root.reducer";
import authMiddleware from "../middlewares/auth.middleware";
import { loaderMiddleware } from "../middlewares/loader.middleware";
import { errorsMiddleware } from "../middlewares/errors.middleware";

export default (deps, appConfig) =>
  createStore(
    rootReducer,
    {
      config: appConfig,
      isSessionExpired: false
    },
    // TODO: remove devtools in prod
    composeWithDevTools(
      applyMiddleware(
        loaderMiddleware,
        thunk.withExtraArgument(deps),
        authMiddleware,
        errorsMiddleware
      )
    )
  );
