import React from "react";

import { ImageUpload } from "../imageUpload";
import { ACCEPTED_FILE_TYPES } from "../../blockPage.constants";
import { validateLogo } from "./logoUpload.helpers";

const acceptedFileTypes = Object.values(ACCEPTED_FILE_TYPES).join(", ");

interface Props {
  logo: string;
  onUploadSuccess: (image?: string) => void;
}

export const LogoUpload = ({ logo, onUploadSuccess }: Props) => (
  <ImageUpload
    acceptedFileTypes={acceptedFileTypes}
    imageUrl={logo}
    onUploadSuccess={onUploadSuccess}
    customValidators={validateLogo}
  />
);
