import _ from "lodash";

import { HttpService, HttpServiceResponse, isServerError } from "../shared";
import { AuthMethod, InfoData, InfoServerData } from "./auth.types";

export class InfoProvider {
  get url(): string {
    return `${this.baseUrl}/info`;
  }

  constructor(private http: HttpService, private baseUrl: string) {}

  public async get(): Promise<HttpServiceResponse<InfoData>> {
    try {
      const response: HttpServiceResponse<InfoServerData> = await this.http.get(
        this.url
      );
      return {
        ...response,
        data: {
          defaultTimezone: response.data["default-timezone"],
          mode: response.data.mode,
          id: response.data.id,
          name: response.data.name,
          authMethod: this.extractAuthMethod(response)
        }
      };
    } catch (error) {
      if (isServerError(error)) {
        error.response.data = {
          authMethod: this.extractAuthMethod(error.response)
        };
      }
      throw error;
    }
  }

  private extractAuthMethod(response): AuthMethod | undefined {
    return _.get(response, ["headers", "authentication-type"]);
  }
}
