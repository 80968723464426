import { find } from "lodash";

import { SettingsProvider } from "./settings.provider";
import { SettingsServerData } from "./settings.types";
import { ServiceConfiguration } from "./serviceConfiguration.types";
import { failure, Result, success } from "../shared";

export enum SettingsServiceErrorCode {
  GENERIC,
  MISCONFIGURED
}

export class SettingsService {
  constructor(
    private settingsProvider: SettingsProvider,
    private servicesConfiguration: ServiceConfiguration[]
  ) {}

  public async get(): Promise<Result<SettingsServerData>> {
    try {
      const { data } = await this.settingsProvider.get();

      if (!this.areMandatoryServicesAvailable(data.services)) {
        return failure(SettingsServiceErrorCode.MISCONFIGURED);
      }

      return success(data);
    } catch (error) {
      return failure(SettingsServiceErrorCode.GENERIC);
    }
  }

  private areMandatoryServicesAvailable(
    servicesData: SettingsServerData["services"]
  ): boolean {
    return this.servicesConfiguration.every(serviceConfiguration =>
      find(servicesData, { enabled: true, ...serviceConfiguration })
    );
  }
}
