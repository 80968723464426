import _ from "lodash";
import React, { Fragment } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

const isEven = num => num % 2 === 0;

export const TBody = ({
  noDataMessageID,
  noFilteredMessageID,
  renderRowSubComponent,
  showPagination,
  tableProps
}) => {
  const {
    data,
    globalFilteredRows,
    page,
    prepareRow,
    getTableBodyProps,
    rows,
    toggleRowExpanded,
    visibleColumns
  } = tableProps;

  if (_.isEmpty(data) || _.isEmpty(globalFilteredRows)) {
    return (
      <tbody {...getTableBodyProps()}>
        <NoDataRow
          visibleColumns={visibleColumns}
          noDataMessageID={
            _.isEmpty(data) ? noDataMessageID : noFilteredMessageID
          }
        />
      </tbody>
    );
  }

  const items = showPagination ? page : rows;

  return (
    <tbody {...getTableBodyProps()}>
      {items.map((row, index) => {
        prepareRow(row);

        const className = isEven(index) ? "--even" : "--odd";

        if (renderRowSubComponent) {
          return (
            <ExpandableRow
              key={row.id}
              row={row}
              className={className}
              renderRowSubComponent={renderRowSubComponent}
              toggleRowExpanded={toggleRowExpanded}
              visibleColumns={visibleColumns}
            />
          );
        }

        return <Row key={row.id} row={row} className={className} />;
      })}
    </tbody>
  );
};

const NoDataRow = ({ visibleColumns, noDataMessageID }) => (
  <tr className="no-data-row">
    <td colSpan={visibleColumns.length}>
      <FormattedMessage id={noDataMessageID} />
    </td>
  </tr>
);

const Row = ({ className, row, onClick }) => (
  <tr
    className={className}
    {...row.getRowProps()}
    onClick={onClick}
    data-testid="table-row"
  >
    {row.cells.map((cell, i) => (
      <Cell key={i} cell={cell} />
    ))}
  </tr>
);

const ExpandableRow = ({
  className,
  renderRowSubComponent,
  row,
  toggleRowExpanded,
  visibleColumns
}) => (
  <Fragment>
    <Row
      row={row}
      className={classnames(className, "expand-row", {
        "expand-row__expanded": row.isExpanded
      })}
      onClick={() => toggleRowExpanded(row.id)}
    />
    {row.isExpanded ? (
      <SubRow
        renderRowSubComponent={renderRowSubComponent}
        visibleColumns={visibleColumns}
        row={row}
      />
    ) : null}
  </Fragment>
);

const Cell = ({ cell }) => (
  <td className={cell.column.cellClassName} {...cell.getCellProps()}>
    {cell.render("Cell")}
  </td>
);

const SubRow = ({ renderRowSubComponent, row, visibleColumns }) => (
  <tr className="sub-row">
    <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
  </tr>
);
