import React, { Component } from "react";
import "./PreviewPage.scss";

const insertIframeElement = (className, iframeContent) => {
  const iframe = document.createElement("iframe");

  document.getElementsByClassName(className)[0].appendChild(iframe);

  let iframeEl = document.getElementsByTagName("iframe")[0],
    iframeElDoc = iframeEl.contentWindow.document;

  iframeElDoc.open();
  iframeElDoc.write(iframeContent);
  iframeElDoc.close();

  return iframeEl;
};

export class PreviewPage extends Component {
  className = "preview-page";

  render() {
    return <div className={this.className} />;
  }

  componentDidMount() {
    const previewString = localStorage.getItem(this.props.localStorageKey);
    insertIframeElement(this.className, previewString);
  }
}
