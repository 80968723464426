import { useState, MutableRefObject, useCallback } from "react";

import { useResizeObserver } from "../useResizeObserver";

interface UseElementDimensionsResult {
  width: number;
  height: number;
}

const roundUp = (num: number): number => Math.ceil(num);

export function useElementDimensions(
  elementRef: MutableRefObject<HTMLElement>
): UseElementDimensionsResult {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const updateDimensions = useCallback<ResizeObserverCallback>(entries => {
    const entry = entries[0];
    const node = entry.target;
    const borderBox = node.getBoundingClientRect();

    setWidth(roundUp(borderBox.width));
    setHeight(roundUp(borderBox.height));
  }, []);

  useResizeObserver(elementRef, updateDimensions);

  return {
    width,
    height
  };
}
