import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { ClickAbsorber } from "../../../../components/clickAbsorber/ClickAbsorber";
import { ProtectionStatus } from "../../../spson/roamingTables/ProtectionStatus";
import {
  RoamingDeviceNameCard,
  RoamingDeviceNameMobileCard
} from "../../../spson/roamingTables/RoamingDeviceNameCard";
import { ProfileRoamingDevicesDropdownActions } from "./ProfileRoamingDevicesDropdownActions";
import { Table } from "../../../../components/table/Table";
import {
  RoamingRowSubComponent,
  RoamingRowSubComponentMobile
} from "../../../spson/roamingTables/RoamingRowSubComponent";
import { useProfileRoamingDevices } from "./useProfileRoamingDevices";
import { Mobile, NotMobile } from "../../../../hocs/Responsive";
import { timeSort } from "../../../../components/table/helpers/sorting.helper";
import { ellipsifyUUID } from "../../../../helpers/devices.helpers";
import { RoamingCounter } from "../../../../components/roamingCounter/RoamingCounter";

const COLUMNS = [
  {
    Header: <FormattedMessage id={"device_name"} />,
    accessor: "name",
    cellClassName: "table__cell--overflow",
    SortingLabel: <FormattedMessage id={"device_name"} />,
    Cell: ({
      row: {
        isExpanded,
        original: { name }
      }
    }) => <RoamingDeviceNameCard isExpanded={isExpanded} name={name} />
  },
  {
    Header: <FormattedMessage id={"protection_status"} />,
    accessor: "protectionStatus",
    sortable: true,
    cellClassName: "table__cell--center",
    headerClassName: "table__cell--center",
    Cell: ({ row }) => (
      <ProtectionStatus status={row.original.protectionStatus} />
    )
  },
  {
    Header: <FormattedMessage id={"last_seen"} />,
    accessor: "lastseen",
    sortType: timeSort,
    cellClassName: "color-secondary",
    Cell: ({
      row: {
        original: { lastseen }
      }
    }) => <div>{lastseen || "—"}</div>
  },
  {
    Header: <FormattedMessage id={"actions"} />,
    id: "actions",
    sortable: false,
    headerClassName: "table__cell--small-cell",
    cellClassName: "table__cell--center table__cell--overflow",
    Cell: ({
      row: {
        original: { identifier, fullName, name }
      }
    }) => (
      <ClickAbsorber>
        <ProfileRoamingDevicesDropdownActions
          identifier={identifier}
          fullName={fullName}
          name={name}
        />
      </ClickAbsorber>
    )
  }
];

const COLUMNS_MOBILE = [
  {
    accessor: "name",
    SortingLabel: <FormattedMessage id={"device_name"} />,
    cellClassName: "table__cell--overflow",
    Cell: ({
      row: {
        isExpanded,
        original: { name, protectionStatus, lastseen, identifier }
      }
    }) => (
      <RoamingDeviceNameMobileCard
        isExpanded={isExpanded}
        name={name}
        lastseen={lastseen}
        protectionStatus={protectionStatus}
        aside={
          <ProfileRoamingDevicesDropdownActions
            identifier={identifier}
            fullName={name || ellipsifyUUID(identifier)}
            name={name}
          />
        }
      />
    )
  },
  {
    SortingLabel: <FormattedMessage id={"protection_status"} />,
    accessor: "protectionStatus"
  },
  {
    accessor: "lastseen",
    sortType: timeSort,
    SortingLabel: <FormattedMessage id={"last_seen"} />
  }
];

const SEARCH_KEYS = [
  "appStatus",
  "fullName",
  "identifier",
  "lastseen",
  "model",
  "name",
  "os",
  "softwareVersion"
];

const initialSort = [{ id: "lastseen", desc: true }];

export const ProfileRoamingDevicesTable = ({ profileName }) => {
  const roamingDevices = useProfileRoamingDevices(profileName);

  const subHeader = _.isEmpty(roamingDevices) && (
    <FormattedMessage id="roaming_devices_table_description" />
  );

  return (
    <Fragment>
      <Mobile>
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          visibleHeader={false}
          showMobileSort
          data={roamingDevices}
          hiddenColumns={["protectionStatus", "lastseen"]}
          columns={COLUMNS_MOBILE}
          renderRowSubComponent={RoamingRowSubComponentMobile}
          defaultSorted={initialSort}
          noDataMessageID={"no_devices_found"}
          subHeader={subHeader}
          searchAriaLabelMessage="aria_search_for_device"
          mobileSortAside={<RoamingCounter />}
        />
      </Mobile>
      <NotMobile>
        <RoamingCounter />
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          data={roamingDevices}
          columns={COLUMNS}
          renderRowSubComponent={RoamingRowSubComponent}
          defaultSorted={initialSort}
          noDataMessageID={"no_devices_found"}
          subHeader={subHeader}
          searchAriaLabelMessage="aria_search_for_device"
        />
      </NotMobile>
    </Fragment>
  );
};
