import {
  DeviceDetails,
  DiscoveredDevice,
  InfectedDeviceServerData,
  LineServerData,
  LogicalDevice,
  LogicalDeviceDetails,
  LogicalDeviceServerData,
  RequestWithName
} from "@sportal/api";
import {
  RenameProfileSuccessAction,
  REMOVE_PROFILE_SUCCESS
} from "../profiles/profiles.types";
import { ActionError } from "../redux.types";

// region actions

export const FETCH_DISCOVERED = "[DEVICES] FETCH_DISCOVERED";
export const FETCH_DISCOVERED_SUCCESS = "[DEVICES] FETCH_DISCOVERED_SUCCESS";
export const FETCH_DISCOVERED_FAILURE = "[DEVICES] FETCH_DISCOVERED_FAILURE";
export const FETCH_LINES = "[DEVICES] FETCH_LINES";
export const FETCH_LINES_SUCCESS = "[DEVICES] FETCH_LINES_SUCCESS";
export const FETCH_LINES_FAILURE = "[DEVICES] FETCH_LINES_FAILURE";
export const FETCH_REQUESTS_NAME = "[DEVICES] FETCH_REQUESTS_NAME";
export const FETCH_REQUESTS_NAME_SUCCESS =
  "[DEVICES] FETCH_REQUESTS_NAME_SUCCESS";
export const FETCH_REQUESTS_NAME_FAILURE =
  "[DEVICES] FETCH_REQUESTS_NAME_FAILURE";
export const FETCH_INFECTED = "[DEVICES] FETCH_INFECTED";
export const FETCH_INFECTED_SUCCESS = "[DEVICES] FETCH_INFECTED_SUCCESS";
export const FETCH_INFECTED_FAILURE = "[DEVICES] FETCH_INFECTED_FAILURE";
export const FETCH_ALL_DEVICES = "[DEVICES] FETCH_ALL_DEVICES";
export const FETCH_ALL_DEVICES_SUCCESS = "[DEVICES] FETCH_ALL_DEVICES_SUCCESS";
export const FETCH_ALL_DEVICES_FAILURE = "[DEVICES] FETCH_ALL_DEVICES_FAILURE";
export const SAVE_DEVICE_SUCCESS = "[DEVICE] SAVE_DEVICE_SUCCESS";
export const SAVE_DEVICE_FAILURE = "[DEVICE] SAVE_DEVICE_FAILURE";
export const REMOVE_DEVICE_SUCCESS = "[DEVICE] REMOVE_DEVICE_SUCCESS";
export const REMOVE_DEVICE_FAILURE = "[DEVICE] REMOVE_DEVICE_FAILURE";
export const EDIT_DEVICE_SUCCESS = "[DEVICES] EDIT_DEVICE_SUCCESS";
export const EDIT_DEVICE_FAILURE = "[DEVICES] EDIT_DEVICE_FAILURE";
export const MERGE_DEVICE_SUCCESS = "[DEVICES] MERGE_DEVICE_SUCCESS";
export const MERGE_DEVICE_FAILURE = "[DEVICES] MERGE_DEVICE_FAILURE";
export const UNMERGE_DEVICE_SUCCESS = "[DEVICES] UNMERGE_DEVICE_SUCCESS";
export const UNMERGE_DEVICE_FAILURE = "[DEVICES] UNMERGE_DEVICE_FAILURE";
export const EDIT_ROAMING_DEVICE_SUCCESS =
  "[DEVICES] EDIT_ROAMING_DEVICE_SUCCESS";
export const EDIT_ROAMING_DEVICE_FAILURE =
  "[DEVICES] EDIT_ROAMING_DEVICE_FAILURE";
export const REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS =
  "[DEVICES] REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS";
export const REVOKE_ROAMING_DEVICE_ACCESS_FAILURE =
  "[DEVICES] REVOKE_ROAMING_DEVICE_ACCESS_FAILURE";
export const CHECK_CODE_FAILURE = "[DEVICES] CHECK_CODE_FAILURE";
export const FETCH_ROAMING_LIMIT_SUCCESS =
  "[DEVICES] FETCH_ROAMING_LIMIT_SUCCESS";
export const FETCH_ROAMING_LIMIT_FAILURE =
  "[DEVICES] FETCH_ROAMING_LIMIT_FAILURE";

export type DevicesFailureActionTypes =
  | typeof FETCH_DISCOVERED_FAILURE
  | typeof FETCH_LINES_FAILURE
  | typeof FETCH_REQUESTS_NAME_FAILURE
  | typeof FETCH_INFECTED_FAILURE
  | typeof FETCH_ALL_DEVICES_FAILURE
  | typeof SAVE_DEVICE_FAILURE
  | typeof REMOVE_DEVICE_FAILURE
  | typeof EDIT_DEVICE_FAILURE
  | typeof MERGE_DEVICE_FAILURE
  | typeof UNMERGE_DEVICE_FAILURE
  | typeof EDIT_ROAMING_DEVICE_FAILURE
  | typeof REVOKE_ROAMING_DEVICE_ACCESS_FAILURE
  | typeof CHECK_CODE_FAILURE
  | typeof FETCH_ROAMING_LIMIT_FAILURE;

interface FetchDiscoveredRequestAction {
  type: typeof FETCH_DISCOVERED;
}

interface FetchDiscoveredFailureAction {
  type: typeof FETCH_DISCOVERED_FAILURE;
  payload: ActionError;
}

interface FetchDiscoveredSuccessAction {
  type: typeof FETCH_DISCOVERED_SUCCESS;
  payload: SBDiscoveredDevice[];
}

interface FetchInfectedRequestAction {
  type: typeof FETCH_INFECTED;
}

interface FetchInfectedFailureAction {
  type: typeof FETCH_INFECTED_FAILURE;
  payload: ActionError;
}

interface FetchInfectedSuccessAction {
  type: typeof FETCH_INFECTED_SUCCESS;
  payload: InfectedDeviceServerData;
}

interface FetchRequestWithNamesFailureAction {
  type: typeof FETCH_REQUESTS_NAME_FAILURE;
  payload: ActionError;
}

interface FetchRequestWithNamesRequestAction {
  type: typeof FETCH_REQUESTS_NAME;
}

interface FetchRequestWithNamesSuccessAction {
  type: typeof FETCH_REQUESTS_NAME_SUCCESS;
  payload: SBRequestWithName[];
}

interface FetchLinesFailureAction {
  type: typeof FETCH_LINES_FAILURE;
  payload: ActionError;
}

interface FetchLinesRequestAction {
  type: typeof FETCH_LINES;
}

interface FetchLinesSuccessAction {
  type: typeof FETCH_LINES_SUCCESS;
  payload: (LineServerData & { "is-device": boolean })[];
}

interface fetchLogicalDevicesFailureAction {
  type: typeof FETCH_ALL_DEVICES_FAILURE;
  payload: ActionError;
}

interface fetchLogicalDevicesRequestAction {
  type: typeof FETCH_ALL_DEVICES;
}

interface fetchLogicalDevicesSuccessAction {
  type: typeof FETCH_ALL_DEVICES_SUCCESS;
  payload: {
    limit: number;
    all: LogicalDevicesPayload;
  };
}

interface SaveDeviceSuccessAction {
  type: typeof SAVE_DEVICE_SUCCESS;
  payload: LogicalDevicesPayload;
}

interface RemoveDeviceSuccessAction {
  type: typeof REMOVE_DEVICE_SUCCESS;
  payload: {
    device: SBLogicalDevice;
  };
}

interface EditDeviceSuccessAction {
  type: typeof EDIT_DEVICE_SUCCESS;
  payload: {
    oldName: string;
    device: LogicalDeviceUpdates;
  };
}

interface RemoveProfileSuccessAction {
  type: typeof REMOVE_PROFILE_SUCCESS;
  payload: {
    id: string;
    currentName: string;
    newName: string;
  };
}

interface MergeDeviceSuccessAction {
  type: typeof MERGE_DEVICE_SUCCESS;
  payload: {
    updatedDevices: LogicalDevicesPayload;
    removedDevice: SBLogicalDevice;
  };
}

interface UnmergeDeviceSuccessAction {
  type: typeof UNMERGE_DEVICE_SUCCESS;
  payload: {
    updatedDevice: LogicalDeviceUpdates;
    newDevice: LogicalDeviceUpdates;
  };
}

export interface EditRoamingDeviceSuccessAction {
  type: typeof EDIT_ROAMING_DEVICE_SUCCESS;
  payload: {
    identifier: string;
    changes: Partial<EditRoamingDeviceChangesPayload>;
  };
}

interface RevokeDeviceAccessSuccessAction {
  type: typeof REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS;
  payload: {
    identifier: string;
  };
}

interface FetchRoamingLimitSuccessAction {
  type: typeof FETCH_ROAMING_LIMIT_SUCCESS;
  payload: {
    limit: number;
  };
}

// endregion

// region payloads
export interface LogicalDevicesPayload {
  plainDevices: SBLogicalDevice[];
  identifiers: Record<string, SBDeviceDetails>;
}

export interface EditRoamingDeviceChangesPayload {
  name: string;
  username: string;
}

export interface SBDiscoveredDevice extends Omit<DiscoveredDevice, "id"> {
  address: string;
}

export interface SBRequestWithName extends Omit<RequestWithName, "id"> {
  address: string;
}

export interface SBLogicalDevice extends Omit<LogicalDevice, "lastSeen"> {
  logicalDeviceId: string;
  lastseen: number;
  manufacturer: string;
}

export interface SBDeviceDetails extends DeviceDetails {
  logicalDeviceId: string;
}

export interface SBLogicalDeviceServerData extends LogicalDeviceServerData {
  "device-details": LogicalDeviceDetails[];
}

export type LogicalDeviceUpdate = Omit<SBLogicalDeviceServerData, "last-seen">;

export type LogicalDeviceUpdates = Omit<
  SBLogicalDeviceServerData,
  "last-seen" | "device-details"
>;

// endregion

export type LogicalDevicesActionTypes =
  | fetchLogicalDevicesRequestAction
  | fetchLogicalDevicesSuccessAction
  | fetchLogicalDevicesFailureAction
  | SaveDeviceSuccessAction
  | RemoveDeviceSuccessAction
  | EditDeviceSuccessAction
  | RemoveProfileSuccessAction
  | MergeDeviceSuccessAction
  | UnmergeDeviceSuccessAction
  | EditRoamingDeviceSuccessAction
  | RevokeDeviceAccessSuccessAction
  | RenameProfileSuccessAction;
export type DevicesActionTypes =
  | FetchDiscoveredRequestAction
  | FetchDiscoveredFailureAction
  | FetchDiscoveredSuccessAction
  | FetchInfectedRequestAction
  | FetchInfectedFailureAction
  | FetchInfectedSuccessAction
  | FetchRequestWithNamesRequestAction
  | FetchRequestWithNamesFailureAction
  | FetchRequestWithNamesSuccessAction
  | FetchLinesRequestAction
  | FetchLinesFailureAction
  | FetchLinesSuccessAction
  | FetchRoamingLimitSuccessAction
  | LogicalDevicesActionTypes;
