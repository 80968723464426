import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import { useIntlTranslate } from "../../../hooks";
import { Icon } from "../../icon/Icon";

import "./ListItem.scss";

export const ListItem = ({
  className,
  item,
  icon,
  onRemove,
  children,
  dataId
}) => {
  const translate = useIntlTranslate();

  return (
    <div className={classnames("list-item", className)} data-id={dataId}>
      <Icon icon={classnames("list-item__icon", "far", "fa-fw", icon)} />
      <div
        className={classnames("list-item__content", `${className}__content`)}
      >
        {children}
      </div>
      <UnstyledButton
        onClick={() => onRemove(item)}
        className={classnames(
          "list-item__remove-link",
          className && `${className}__remove-link`
        )}
        aria-label={translate("aria_remove_domain_from_the_list")}
      >
        <Icon icon="far fa-times list-item__remove-icon" />
      </UnstyledButton>
    </div>
  );
};

ListItem.propTypes = {
  item: PropTypes.any.isRequired,
  icon: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  dataId: PropTypes.string // For testing
};
