import { InfectedDevicesProvider } from "./infectedDevices.provider";
import { Result, success, failure } from "../shared";
import { InfectedDeviceServerData } from "./infectedDevices.types";
import { Durations } from "../reports";

export enum InfectedDevicesServiceErrorCode {
  GENERIC
}

export const INFECTED_DEVICES_DEFAULT_DURATION = Durations.Month;

export class InfectedDevicesService {
  constructor(private provider: InfectedDevicesProvider) {}

  public async get(
    subscriberId: string
  ): Promise<Result<InfectedDeviceServerData>> {
    try {
      const { data } = await this.provider.get(
        subscriberId,
        INFECTED_DEVICES_DEFAULT_DURATION
      );
      return success(data[0] || {});
    } catch (error) {
      return failure(InfectedDevicesServiceErrorCode.GENERIC);
    }
  }
}
