import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog
} from "../../../modal";

import "./RemoveDeviceDialog.scss";

class RemoveDeviceDialog extends Component {
  render() {
    const { modal, device } = this.props;

    return (
      <ModalDialog
        className={"remove-device-modal"}
        submitHandler={this.onSubmit}
        rejectHandler={() => modal.reject()}
      >
        <CommonModalHeader>
          <FormattedMessage id={"remove_device"} />
        </CommonModalHeader>
        <ModalDialog.Body>
          <div className={"remove-device-modal__message"}>
            <FormattedMessage
              id={"remove_device_description"}
              values={{
                device: <span className={"text-highlighted"}>{device}</span>
              }}
            />
          </div>
          <div className={"remove-device-modal__note"}>
            <FormattedMessage id={"remove_device_note"} />
          </div>
        </ModalDialog.Body>
        <CommonModalFooter
          submitMessage={<FormattedMessage id="remove" />}
          submitButtonType="destructive"
        />
      </ModalDialog>
    );
  }

  onSubmit = () => this.props.modal.resolve(this.props.device);
}

RemoveDeviceDialog.propTypes = {
  device: PropTypes.string
};

export default RemoveDeviceDialog;
