import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { PreviewButton } from "../previewButton/PreviewButton";
import { Button } from "../../../../components/button/Button";
import ProgressButton from "../../../../components/progressButton/ProgressButton";

import "./TabControls.scss";

export const TabControls = ({
  isFormValid,
  isFormUnchanged,
  onReset,
  onSave,
  onPreview,
  className
}) => (
  <div className={classnames(className, "tab-controls")}>
    <PreviewButton onClick={onPreview} disabled={!isFormValid} />
    <div className={"tab-controls__saving-buttons"}>
      <Button
        data-testid="discard-button"
        isOutline
        size={"large"}
        onClick={onReset}
        disabled={isFormUnchanged}
      >
        <FormattedMessage id={"discard"} />
      </Button>

      <ProgressButton
        data-testid="save-button"
        className="web-filtering-form__send-button"
        label={<FormattedMessage id={"save_changes"} />}
        onClick={onSave}
        disabled={isFormUnchanged || !isFormValid}
      />
    </div>
  </div>
);
