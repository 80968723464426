import { BaseApiService } from "./baseApiService";
import { stringifyQueryParams } from "./utils";

export class AuthService extends BaseApiService {
  constructor(config) {
    super(config);
    this.baseUrl = config.backendUrl;
  }

  login(username, password) {
    return this._http.post(
      `${this.baseUrl}/login`,
      stringifyQueryParams({ username, password }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    );
  }

  getInfo() {
    return this._http.get(`${this.baseUrl}/info`);
  }

  logout() {
    return this._http.post(`${this.baseUrl}/logout`);
  }
}
