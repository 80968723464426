import React from "react";
import { FormattedMessage } from "react-intl";

import Notificator from "../../../notification/notification.actions";

export const clearHistory = metrics => (dispatch, getState, { api }) => {
  const id = getState().subscriberInfo.id;

  return api.ssm.history
    .clear(id, metrics)
    .then(() => {
      dispatch(
        Notificator.info(<FormattedMessage id={"remove_data_note"} />, {
          title: <FormattedMessage id={"remove_data_success"} />
        })
      );
    })
    .catch(error => {
      dispatch(
        Notificator.error(<FormattedMessage id={"failed_to_delete_data"} />)
      );
      dispatch({ payload: error });
    });
};
