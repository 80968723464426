import React from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import {
  LOAD_SHARED_PROTECTIONS_SUCCESS,
  LOAD_SHARED_PROTECTIONS_FAILURE,
  ProtectionsActionTypes,
  SharedProtectionsSuccessPayload
} from "./protections.types";
import Notificator from "../../components/notification/notification.actions";
import {
  HttpServiceError,
  Protection,
  SharedProtectionsServerData
} from "@sportal/api";
import { SBThunkAction } from "../redux.types";
import { AddNotificationAction } from "../../components/notification/notification.types";
import { isSessionExpired } from "../../utils/isSessionExpired";

export const loadSharedProtections = (): SBThunkAction<
  Promise<void>,
  ProtectionsActionTypes | AddNotificationAction
> => (dispatch, getState, { api }) => {
  const { config, protections } = getState();

  if (!_.isEmpty(protections.keys)) {
    return Promise.resolve();
  }

  return api.ssm.protection
    .get()
    .then(({ content }: SharedProtectionsServerData) => {
      const enabledProtections = content.filter(
        protection => config.protections[protection.name]
      );
      const customProtection = _.find(content, {
        name: Protection.Custom
      });
      const sortedProtections = _.sortBy(
        _.without(enabledProtections, customProtection),
        protection => protection.categories.length
      );

      dispatch(
        loadSharedProtectionsSuccess(
          _.map([...sortedProtections, customProtection], protection => ({
            name: protection.name,
            categories: _.map(protection.categories, "name")
          }))
        )
      );
    })
    .catch(error => {
      dispatch(loadSharedProtectionsFailure(error));

      if (!isSessionExpired(error)) {
        window.location.href = "#error";
        dispatch(
          Notificator.error(
            <FormattedMessage id={"error_failed_to_load_data"} />
          )
        );
      }
      return Promise.reject();
    });
};

export const loadSharedProtectionsSuccess = (
  sharedProtections: SharedProtectionsSuccessPayload
): ProtectionsActionTypes => ({
  type: LOAD_SHARED_PROTECTIONS_SUCCESS,
  payload: sharedProtections
});
export const loadSharedProtectionsFailure = (
  error: HttpServiceError
): ProtectionsActionTypes => ({
  type: LOAD_SHARED_PROTECTIONS_FAILURE,
  payload: error
});
