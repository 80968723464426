import React from "react";
import PropTypes from "prop-types";

import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import ScheduleRule from "../scheduleRule/ScheduleRule";
import ButtonLink from "../../../../../components/buttonLink/ButtonLink";
import { Icon } from "../../../../../components/icon/Icon";
import { useIntlTranslate } from "../../../../../hooks";

export const ScheduleRuleListItem = props => {
  const {
    id,
    ruleRef,
    className,
    onTogglePopup,
    onRuleRemove,
    allowDelete
  } = props;

  const translate = useIntlTranslate();

  return (
    <div className={className} id={id} ref={ruleRef}>
      <ScheduleRule {...props} useShortNotations={true} />
      <div className="rule__actions">
        <UnstyledButton
          className="rule__actions__button edit-schedule"
          onClick={() => onTogglePopup(id)}
          aria-label={translate("aria_edit_rule")}
          data-testid={"edit-rule-button"}
        >
          <Icon icon="far fa-pencil" />
        </UnstyledButton>
        {allowDelete && (
          <ButtonLink
            className="far fa-trash rule__actions__button remove-schedule"
            onClick={() => onRuleRemove({ id: id })}
            aria-label={translate("aria_delete_rule")}
            data-testid={"remove-rule-button"}
          />
        )}
      </div>
    </div>
  );
};

ScheduleRuleListItem.propTypes = {
  allowDelete: PropTypes.bool,
  categories: PropTypes.array,
  className: PropTypes.string,
  day: PropTypes.array,
  editPopup: PropTypes.shape({ id: PropTypes.string }),
  enabled: PropTypes.bool,
  firstDayOfWeek: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onRuleChange: PropTypes.func,
  onRuleRemove: PropTypes.func,
  onTogglePopup: PropTypes.func,
  profile: PropTypes.string,
  ruleRef: PropTypes.func,
  selectedDays: PropTypes.array,
  timeEnd: PropTypes.string,
  timeFormat24h: PropTypes.bool,
  timeStart: PropTypes.string,
  type: PropTypes.string
};
