import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import _ from "lodash";

import { Granularity } from "../../../helpers/reports.helper.types";
import { getReportsZoom } from "../../../store/reports/reports.selectors";
import { getTimeZone, is24HourFormat } from "../../../store/account";
import { getDrilldownReportsPeriod } from "../drilldown.selectors";
import { punyToUnicode } from "../../../utils/punyToUnicode";
import { ellipsis } from "../../../helpers/ellipsis.helper";
import { DrilldownRoutePage } from "../drilldown.types";
import { getTimeRange } from "../../../components/time/timeRange/TimeRange";
import { getThreatsList } from "../../../store/threats/threats.selectors";
import { inRange } from "@sportal/lib";

const filterData = (data, start, end) => {
  if (!start || !end) return data;

  return _.filter(data, obj => inRange(obj.time, { start, end }, "[)"));
};

const getThreat = (list, id, page) => {
  if (page !== DrilldownRoutePage.Botnet) return {};

  const threat = list[id];
  const commonValues = {
    "threat-id": id,
    severityIndex: _.get(threat, "severity", 0)
  };

  return !threat
    ? commonValues
    : {
        ...commonValues,
        threat: threat.name,
        "threat-tooltip": ellipsis(threat.description, 60)
      };
};

const getUrls = (item, page) => {
  const isBotnet = page === DrilldownRoutePage.Botnet;

  if (isBotnet || item.url) {
    const url = isBotnet
      ? punyToUnicode(item.name)
      : `${item.protocol}://${punyToUnicode(item.url)}`;

    return {
      url,
      shortUrl: getShortURL(url)
    };
  }
};

const getShortURL = url => {
  return url.length > 30 ? url.slice(0, 30) + "..." : url;
};

const formatTimeSlice = ({ time, period, ...rest }) => {
  return getTimeRange(
    Object.assign(
      {
        start: time,
        end: time + Granularity[period],
        period
      },
      rest
    )
  );
};

export const useDrilldownData = (page, table) => {
  const { start, end } = useSelector(getReportsZoom);
  const is24 = useSelector(is24HourFormat);
  const tz = useSelector(getTimeZone);
  const period = useSelector(getDrilldownReportsPeriod);
  const threats = useSelector(getThreatsList, _.isEqual);
  const intl = useIntl();

  const getMappedCategories = useCallback(
    pieceOfReport => {
      if (_.isEmpty(pieceOfReport.categories)) return "";

      return _.map(pieceOfReport.categories, cat =>
        intl.formatMessage({ id: `category_${cat}` })
      ).join(", ");
    },
    [intl]
  );

  const getMappedValues = useCallback(
    (values, time) => {
      return values.map(value => {
        return Object.assign(
          {},
          value,
          getUrls(value, page),
          getThreat(threats, value["threat-id"], page),
          {
            unix: time,
            device: value["device-name"] || value["device-id"],
            reason: value.reason
              ? intl.formatMessage({ id: value.reason })
              : "",
            categories: getMappedCategories(value),
            time: formatTimeSlice({
              is24,
              period,
              time,
              tz,
              locale: intl.locale
            })
          }
        );
      });
    },
    [page, threats, intl, getMappedCategories, is24, period, tz]
  );

  const flattenData = useCallback(
    data => {
      let result = [];

      for (let i = 0; i < data.length; i++) {
        const timestampData = data[i];

        result = result.concat(
          getMappedValues(timestampData.value, timestampData.time)
        );
      }

      return result;
    },
    [getMappedValues]
  );

  const getDrilldownData = useCallback(() => {
    const filteredData = filterData(table, start, end) || [];

    return flattenData(filteredData);
  }, [end, flattenData, start, table]);

  return useMemo(getDrilldownData, [getDrilldownData]);
};
