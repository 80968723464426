import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import { useToggle, useOutsideClick } from "@sportal/cdk/hooks";
import { useIntlTranslate } from "../../hooks";
import { Icon } from "../icon/Icon";

import "./MobileMenu.scss";

const MobileMenuContentWrapper = ({ onToggleMenu, children }) => {
  const ref = useOutsideClick(() => {
    onToggleMenu();
  }, true);

  return (
    <div ref={ref} className="menu__content">
      {children({ onToggleMenu })}
    </div>
  );
};

export const MobileMenu = ({ className, children }) => {
  const [open, toggleOpen] = useToggle(false);
  const translate = useIntlTranslate();

  const handleToggleMenu = () => toggleOpen();

  return (
    <div
      className={classnames(className, "side-menu", {
        "side-menu--open": open
      })}
    >
      <UnstyledButton
        className="menu-button"
        onClick={handleToggleMenu}
        aria-label={translate("aria_user_menu")}
        data-testid="mobile-menu-button"
      >
        <Icon icon="far fa-bars" />
      </UnstyledButton>
      <div className={classnames("menu", { "menu--open": open })}>
        <UnstyledButton
          className="close-button"
          onClick={handleToggleMenu}
          aria-label={translate("aria_close")}
        >
          <Icon icon="fas fa-times-circle" />
        </UnstyledButton>
        {open && (
          <MobileMenuContentWrapper
            onToggleMenu={handleToggleMenu}
            children={children}
          />
        )}
      </div>
    </div>
  );
};

MobileMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.func.isRequired
};
