import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";

import { DrilldownDataKeys } from "../../../helpers/reports.helper.types";
import { getTableReports } from "../../../store/reports/reports.selectors";
import { isMultipleUserLevel } from "../../../store/account/index";
import { Desktop, Mobile, NotMobile } from "../../../hocs/Responsive";
import { CsvDownload } from "./csvDownload/CsvDownload";
import { DrilldownRoutePage } from "../drilldown.types";
import { DrilldownTableDesktop } from "./drilldownTable/DrilldownTableDesktop";
import { DrilldownTableMobile } from "./drilldownTable/DrilldownTableMobile";
import { useDrilldownData } from "./useDrilldownData";

const getTableCount = table => {
  return _.reduce(
    table,
    (sum, item) => sum + _.sum(item.value.map(obj => obj.count)),
    0
  );
};

export const DrilldownTable = ({ className, page }) => {
  const isMultiple = useSelector(isMultipleUserLevel);
  const table = useSelector(
    state => getTableReports(state)[DrilldownDataKeys[page]],
    _.isEqual
  );

  const data = useDrilldownData(page, table);

  return (
    <div className={className}>
      <Desktop>
        <div className={"drilldowns__table__info"}>
          <CsvDownload page={page} />
        </div>
      </Desktop>

      <Mobile>
        <DrilldownTableMobile page={page} isMultiple={isMultiple} data={data} />
      </Mobile>

      <NotMobile>
        <DrilldownTableDesktop
          page={page}
          isMultiple={isMultiple}
          tableCount={getTableCount(table)}
          data={data}
        />
      </NotMobile>
    </div>
  );
};

DrilldownTable.propTypes = {
  page: PropTypes.oneOf([
    DrilldownRoutePage.Webfilter,
    DrilldownRoutePage.Malware,
    DrilldownRoutePage.Botnet
  ]),
  className: PropTypes.string
};
