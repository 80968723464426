import _ from "lodash";
import { normalizeMacAddress } from "../../../helpers/validation.helper";

const compareStrings = (a, b) => (a < b ? -1 : a > b ? 1 : 0);

const compareFn = itemTransformFn => (a, b) => {
  const itemA = itemTransformFn(a),
    itemB = itemTransformFn(b);

  if (itemA === itemB) return 0;

  return itemA > itemB ? 1 : -1;
};

const SEVERITY_WEIGHTS = {
  0: 1,
  1: 10,
  2: 100,
  3: 1000,
  4: 10000
};

export const multipleThreatsSort = (a, b) => {
  const getSeverityScore = infections => {
    if (_.isEmpty(infections)) return 0;

    return infections.reduce((sum, threat) => {
      const severity = threat.severity || 0;

      return sum + SEVERITY_WEIGHTS[severity];
    }, 0);
  };

  const aInfections = _.toArray(a.original.infections);
  const bInfections = _.toArray(b.original.infections);

  const aScore = getSeverityScore(aInfections);
  const bScore = getSeverityScore(bInfections);

  return aScore - bScore;
};

export const singleThreatSort = (a, b) => {
  const getSeverityIndex = ({ original: { severityIndex } }) => +severityIndex;
  const getThreat = ({ original: { threat } }) => threat + "";
  const aSeverity = getSeverityIndex(a);
  const bSeverity = getSeverityIndex(b);

  return aSeverity !== bSeverity
    ? aSeverity - bSeverity
    : compareStrings(getThreat(a), getThreat(b));
};

export const timeSort = (a, b) => {
  const getTime = ({ original: { unix } }) => unix;

  return compareFn(getTime)(a, b);
};

export const alphanumericSort = (rowA, rowB, columnId) => {
  let a = rowA.original[columnId] || "";
  let b = rowB.original[columnId] || "";

  return compareStrings(a, b);
};

const compareDeviceIds = (a, b) => {
  const normalizedA = normalizeMacAddress(a);
  const normalizedB = normalizeMacAddress(b);

  return compareStrings(normalizedA, normalizedB);
};

export const singleDeviceIdSort = (a, b) => {
  const getDeviceId = ({ original: { address, id } }) => address || id;
  const aId = getDeviceId(a);
  const bId = getDeviceId(b);

  return compareDeviceIds(aId, bId);
};

export const multipleDeviceIdSort = (a, b) => {
  const getIdentifiers = ({ original: { identifiers } }) => identifiers;
  const aId = getIdentifiers(a)[0];
  const bId = getIdentifiers(b)[0];

  return compareDeviceIds(aId, bId);
};
