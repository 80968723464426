import _ from "lodash";

const isLinkValid = link => /^https?:\/\//i.test(link);

const mergeLink = (value, configValue) => {
  const isExternal = isLinkValid(configValue);

  if (_.isObject(value)) {
    return {
      isExternal: isExternal || value.isExternal,
      url: isExternal ? configValue : value.url
    };
  }

  return {
    isExternal,
    url: isExternal ? configValue : value || configValue
  };
};

const mergeUrl = (defaultValue, configValue) =>
  !configValue || isLinkValid(configValue) ? configValue : defaultValue;

const mergeLimit = (defaultValue, configValue) =>
  _.isInteger(configValue) && configValue > 0 ? configValue : defaultValue;

const mergeString = (defaultValue, configValue) => configValue || defaultValue;

const mergeCustomizers = {
  helpPageLink: mergeLink,
  logoLink: mergeLink,
  footerSupportLink: mergeLink,
  footerCompanyLink: mergeLink,
  logoutUrl: mergeUrl,
  secureBlocksLimit: mergeLimit,
  secureBlocksLimitCsv: mergeLimit,
  appTitle: mergeString,
  eulaCancelRedirectUrl: mergeUrl
};

export class AppConfigService {
  constructor(http, defaults) {
    this._http = http;
    this._defaults = defaults;
  }

  get() {
    return this._http
      .get("./appConfig.json")
      .then(({ data }) => this.mergeDefaults(data));
  }

  /** @private */
  mergeDefaults(config) {
    return _.mergeWith(
      {},
      this._defaults,
      config,
      (defaultValue, configValue, key) => {
        if (_.has(mergeCustomizers, key)) {
          return mergeCustomizers[key](defaultValue, configValue);
        }
      }
    );
  }
}
