import React, { FC, Fragment, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import _ from "lodash";

import { useMount } from "@sportal/cdk/hooks";
import { Button } from "../../../../components/button/Button";
import {
  ACCEPTED_FILE_TYPES,
  MAX_FILE_SIZE_KB
} from "../../blockPage.constants";
import { uuid } from "../../../../utils/uuid";
import { Icon } from "../../../../components/icon/Icon";
import { getImage } from "./imageUpload.helpers";
import {
  UploadErrorType,
  UploadValidationError,
  UploadValidationErrors
} from "./upload.types";

import "./ImageUpload.scss";

const acceptedFileTypes = Object.keys(ACCEPTED_FILE_TYPES)
  .join(", ")
  .toLowerCase();

export const IMAGE_VALIDATION_ERRORS: UploadValidationErrors = {
  IMAGE_TYPE: {
    type: UploadErrorType.ImageTypeNotSupported,
    message: (
      <Fragment>
        <FormattedMessage id="image_type_not_supported" /> &nbsp;
        <FormattedMessage id="supported_types_are" />
        {acceptedFileTypes}
      </Fragment>
    ),
    shortMessage: <FormattedMessage id="image_type_not_supported" />
  }
};

const ImageUploadControls = ({
  onRemoveFile
}: {
  onRemoveFile: () => void;
}) => (
  <div
    className="image-upload__info__controls"
    data-testid="image-upload-controls"
  >
    <label
      className="image-upload__info__controls__change"
      htmlFor="image-upload"
    >
      <Icon icon="far fa-upload color-secondary" />
      <span>
        <FormattedMessage id="upload_image" />
      </span>
    </label>
    <Button
      color={"destructive"}
      isOutline={true}
      onClick={onRemoveFile}
      data-testid="remove-uploaded-image-button"
    >
      <Icon icon="far fa-trash color-danger" />
      <span>
        <FormattedMessage id="remove" />
      </span>
    </Button>
  </div>
);

const ImageUploadField = ({ imageUrl }: { imageUrl: string }) => {
  if (imageUrl) {
    return (
      <div className="image-upload__box" data-testid="image-preview">
        <img src={imageUrl} alt="" />
      </div>
    );
  }

  return (
    <label
      className="upload-field"
      htmlFor="image-upload"
      data-testid="upload-file-label"
    >
      <Icon icon="far fa-upload upload-field__icon" />
      <p className="upload-field__text">
        <FormattedMessage id="upload_image" />
      </p>
    </label>
  );
};

const ImageUploadInfo = ({
  imageUrl,
  onRemoveFile
}: {
  imageUrl: string;
  onRemoveFile: () => void;
}) => {
  const [types] = useState(() => _.keys(ACCEPTED_FILE_TYPES).join(", "));

  return (
    <div className="image-upload__info" data-testid="image-upload-info">
      <div className="image-upload__info__hints">
        <div className="image-upload__info__hints__labels">
          <div>
            <FormattedMessage id="file_format" />
          </div>
          <div>
            <FormattedMessage id="max_size" />
          </div>
        </div>
        <div className="image-upload__info__hints__values">
          <div>{types}</div>
          <div>{`${MAX_FILE_SIZE_KB} KB`}</div>
        </div>
      </div>
      {imageUrl && <ImageUploadControls onRemoveFile={onRemoveFile} />}
    </div>
  );
};

const LOGO_KEY = "sb-branding-logo";

const setLogoInStorage = (logo: string): void => {
  localStorage.setItem(LOGO_KEY, logo);
};
export const clearLogoFromStorage = (): void => {
  localStorage.removeItem(LOGO_KEY);
};

export type CustomValidators = (file: File) => Promise<UploadValidationError>;

interface ImageUploadProps {
  imageUrl: string;
  acceptedFileTypes: string;
  onUploadSuccess: (image?: string) => void;
  customValidators: CustomValidators;
}

export const ImageUpload: FC<ImageUploadProps> = ({
  imageUrl,
  acceptedFileTypes,
  customValidators,
  onUploadSuccess
}) => {
  const [logoError, setLogoError] = useState(null);

  useMount(() => {
    clearLogoFromStorage();
  });

  const handleFileUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawImage = e.target.files[0];
      if (!rawImage) return;

      let image: string;
      try {
        image = await getImage(rawImage);
      } catch (e) {
        setLogoError(IMAGE_VALIDATION_ERRORS.IMAGE_TYPE.message);

        return;
      }

      const reader = new FileReader();

      const validationError = await customValidators(rawImage);

      if (validationError) {
        setLogoError(validationError.shortMessage);

        return;
      }

      setLogoError(null);

      reader.readAsDataURL(rawImage);
      reader.onloadend = e => {
        setLogoInStorage(e.target.result as string);
      };

      onUploadSuccess(image);
    },
    [customValidators, onUploadSuccess]
  );

  const handleFileRemove = useCallback(() => {
    setLogoError(null);
    clearLogoFromStorage();
    onUploadSuccess();
  }, [onUploadSuccess]);

  return (
    <div className="image-upload">
      <input
        hidden
        id="image-upload"
        key={uuid()}
        type="file"
        onChange={handleFileUpload}
        accept={acceptedFileTypes}
        data-testid="image-upload-input"
      />
      <div className="image-upload__logo">
        <ImageUploadField imageUrl={imageUrl} />
        {logoError && (
          <div className="image-upload__error" data-testid="image-upload-error">
            {logoError}
          </div>
        )}
      </div>
      <ImageUploadInfo imageUrl={imageUrl} onRemoveFile={handleFileRemove} />
    </div>
  );
};

ImageUpload.propTypes = {
  imageUrl: PropTypes.string,
  acceptedFileTypes: PropTypes.string,
  onUploadSuccess: PropTypes.func,
  customValidators: PropTypes.func
};
