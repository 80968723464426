import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CheckCodeField from "./CheckCodeField";
import {
  DeviceNameInput,
  ProfileSelect
} from "../../../../components/devices/commonComponents";
import { HelpLink } from "../../../../components/helpLink/HelpLink";
import { ModalFormControl } from "../../../../components/modal";

const CLASS_NAME = "add-device-modal";

export const AddDeviceByCode = props => {
  return (
    <Fragment>
      <div className={`${CLASS_NAME}__tip`}>
        <HelpLink section={"changing-restrictions-for-unrecognized-devices"}>
          <FormattedMessage id="where_to_find_code" />
        </HelpLink>
        <p>
          <FormattedMessage id="device_should_be_on_network" />
        </p>
      </div>
      <ModalFormControl>
        <CheckCodeField
          onAddressChange={address => props.onAddressChange(address)}
          errorAddress={props.errorAddress}
        />
      </ModalFormControl>
      <ModalFormControl>
        <DeviceNameInput
          handleChange={(name, error) => props.onNameChange(name, error)}
          devices={props.devices}
          name={props.name}
          error={props.errorName}
          label={props.intl.formatMessage({ id: "device_name" })}
        />
      </ModalFormControl>
      <ModalFormControl>
        <ProfileSelect
          profiles={props.profiles}
          profile={props.profile}
          handleChange={selected => props.onProfileChange(selected)}
          className={CLASS_NAME}
        />
      </ModalFormControl>
    </Fragment>
  );
};

AddDeviceByCode.propTypes = {
  /** Device name */
  name: PropTypes.string,
  /** Profiles for the select element*/
  profiles: PropTypes.array,
  /** Text of error for name field */
  errorName: PropTypes.string,
  /** Text of error for address field */
  errorAddress: PropTypes.string,
  /** First of profiles or specified value, if modal was opened from profile devices*/
  onProfileChange: PropTypes.func,
  onNameChange: PropTypes.func,
  onAddressChange: PropTypes.func
};
