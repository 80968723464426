import { BaseApiService } from "../baseApiService";
import {
  BaseAppConfig,
  DiscoveredDevicesServerData,
  InfectedDevicesServerData,
  LinesServerData,
  LogicalDevicesServerData,
  LogicalDeviceUpdate,
  Manufacturer,
  RequestsWithCodeServerData,
  RequestsWithNameServerData,
  UpdateResponseStatus
} from "@sportal/api";
import {
  LogicalDeviceUpdates,
  SBLogicalDeviceServerData
} from "../../store/devices/devices.types";

interface SpsonDeviceUpdateAttributes {
  name?: string;
  username?: string;
}

interface LogicalDeviceUpdateResponse {
  status: UpdateResponseStatus;
  succeeded: SBLogicalDeviceServerData[];
}

export class DevicesService extends BaseApiService {
  infectedUrl: string;
  discoveredUrl: string;
  namedRequestsUrl: string;
  codesUrl: string;
  manufacturersUrl: string;
  accountUrl: string;

  constructor(config: BaseAppConfig) {
    super(config);

    const series = `${config.backendUrl}/ssm/series`;

    this.infectedUrl = `${series}/ss/infections-by-device`;
    this.discoveredUrl = `${series}/device/discovered`;
    this.namedRequestsUrl = `${series}/device/registration`;
    this.codesUrl = `${series}/device/blocks`;

    this.manufacturersUrl = `${config.backendUrl}/manufacturer`;
    this.accountUrl = `${config.backendUrl}/ssm/account`;
  }

  getDiscovered(id: string): Promise<DiscoveredDevicesServerData> {
    return this._http
      .get<DiscoveredDevicesServerData>(
        `${this.discoveredUrl}?duration=PT1H&view=${encodeURIComponent(id)}`
      )
      .then(({ data }) => data);
  }

  getInfected(id: string): Promise<InfectedDevicesServerData> {
    return this._http
      .get<InfectedDevicesServerData[]>(
        `${this.infectedUrl}?duration=P30D&view=${encodeURIComponent(id)}`
      )
      .then(({ data }) => data[0]);
  }

  getRequestsWithName(id: string): Promise<RequestsWithNameServerData> {
    return this._http
      .get<RequestsWithNameServerData>(
        `${this.namedRequestsUrl}?duration=P1D&view=${encodeURIComponent(id)}`
      )
      .then(({ data }) => data);
  }

  getLogicalDevices(id: string): Promise<LogicalDevicesServerData> {
    return this._http
      .get<LogicalDevicesServerData>(
        `${this.accountUrl}/${encodeURIComponent(id)}/logical-device`
      )
      .then(({ data }) => data);
  }

  getManufacturers(devices: Manufacturer[]): Promise<Manufacturer[]> {
    return this._http
      .post<Manufacturer[]>(`${this.manufacturersUrl}`, devices)
      .then(({ data }) => data);
  }

  getLines(id: string): Promise<LinesServerData> {
    return this._http
      .get<LinesServerData>(`${this.accountUrl}/${encodeURIComponent(id)}/line`)
      .then(({ data }) => data);
  }

  removeDevice(id: string, deviceName: string): Promise<undefined> {
    return this._http.delete(
      `${this.accountUrl}/${encodeURIComponent(
        id
      )}/logical-device/${encodeURIComponent(deviceName)}`
    );
  }

  saveDevice(
    id: string,
    device: LogicalDeviceUpdate
  ): Promise<LogicalDeviceUpdateResponse> {
    return this._http
      .post<LogicalDeviceUpdateResponse>(
        `${this.accountUrl}/${id}/logical-device`,
        {
          add: [
            {
              name: device.name,
              identifiers: device.identifiers,
              profile: device.profile
            }
          ]
        }
      )
      .then(({ data }) => data);
  }

  getRequestsByCode(id: string): Promise<RequestsWithCodeServerData> {
    return this._http
      .get<RequestsWithCodeServerData>(
        `${this.codesUrl}?duration=PT15M&view=${id}`
      )
      .then(({ data }) => data);
  }

  editDevice(
    id: string,
    oldName: string,
    { name, profile, identifiers }: LogicalDeviceUpdates
  ): Promise<LogicalDeviceUpdateResponse> {
    return this._http
      .put<LogicalDeviceUpdateResponse>(
        `${this.accountUrl}/${encodeURIComponent(
          id
        )}/logical-device/${encodeURIComponent(oldName)}`,
        { name, profile, identifiers }
      )
      .then(({ data }) => data);
  }

  updateSpsonDevice(
    id: string,
    address: string,
    attributes: SpsonDeviceUpdateAttributes
  ): Promise<undefined> {
    const url = `${this.accountUrl}/${encodeURIComponent(
      id
    )}/spson/devices/${encodeURIComponent(address)}`;

    return this._http.patch(url, attributes);
  }

  removeSpsonDevice(id: string, address: string): Promise<undefined> {
    return this._http.delete(
      `${this.accountUrl}/${encodeURIComponent(
        id
      )}/spson/devices/${encodeURIComponent(address)}`
    );
  }
}
