import React, { FC, useLayoutEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { useElementDimensions } from "@sportal/cdk/hooks";

import "./EulaBanner.scss";

interface EulaBannerProps {
  className: string;
  contentId: string;
  onHeightChange: (height: number) => void;
}

export const EulaBanner: FC<EulaBannerProps> = ({
  className,
  contentId,
  onHeightChange
}) => {
  const container = useRef<HTMLDivElement>(null);

  const { height } = useElementDimensions(container);

  useLayoutEffect(() => {
    if (height) {
      onHeightChange(height);
    }
  }, [onHeightChange, height]);

  return (
    <div ref={container} className={classNames("eula-banner", className)}>
      <FormattedMessage id={contentId} />
    </div>
  );
};
