import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { SeparatedLists } from "../../../../components/separatedLists/SeparatedLists";
import _ from "lodash";
import { UrlList } from "../urlList/UrlList";
import AddUrlSection from "../addUrlSection/AddUrlSection";

import "./UrlFiltersContent.scss";
import { ModalService } from "../../../../components/modal";
import { RemoveAllDialog } from "../removeAllDialog/RemoveAllDialog";
import { connect } from "react-redux";
import {
  clearCheckUrl,
  clearCsvFile,
  load,
  removeAll,
  removeItem
} from "../../urlFilters.actions";
import { FormattedMessage } from "react-intl";

const listsConfig = [
  {
    name: "black_list_blocked",
    key: "blacklist",
    icon: "fa-ban color-danger"
  },
  {
    name: "white_list_allowed",
    key: "whitelist",
    icon: "fa-check color-success"
  }
];

const openModal = list =>
  ModalService.show(modal => ({
    dialog: <RemoveAllDialog list={list} modal={modal} />
  }));

class UrlFiltersContent extends Component {
  componentDidMount() {
    this.props.clearCheckUrl();
    this.props.clearCsvFile();
    this.props.load(_.map(listsConfig, "key"), this.props.profileId);
  }

  componentDidUpdate(nextProps) {
    if (nextProps.profileId !== this.props.profileId) {
      this.props.load(_.map(listsConfig, "key"), this.props.profileId);
    }
  }

  render() {
    const { urlFilters, removeItem, removeAll, profileId } = this.props;
    const lists = urlFilters[profileId];

    return (
      <Fragment>
        <AddUrlSection profileId={profileId} />
        <h4 className={"url-filters__subheader"}>
          <FormattedMessage id={"lists"} />
        </h4>
        <SeparatedLists>
          {_.map(listsConfig, ({ name, icon, key }) => (
            <UrlList
              key={key}
              name={<FormattedMessage id={name} />}
              icon={icon}
              items={_.get(lists, `${key}.content`, [])}
              onItemRemove={item => removeItem(key, item, profileId)}
              onClear={() =>
                openModal(name)
                  .then(() => removeAll(key, profileId))
                  .catch(() => null)
              }
            />
          ))}
        </SeparatedLists>
      </Fragment>
    );
  }
}

UrlFiltersContent.propTypes = {
  removeItem: PropTypes.func,
  removeAll: PropTypes.func,
  urlFilters: PropTypes.object,
  profileId: PropTypes.string
};

export default connect(
  ({ urlFilters }) => ({ urlFilters }),
  {
    load,
    removeItem,
    removeAll,
    clearCheckUrl,
    clearCsvFile
  }
)(UrlFiltersContent);
