import { SHOW_LOADER, HIDE_LOADER } from "./loader.actions";

const initialState = {
  loadersShown: 0
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        loadersShown: ++state.loadersShown
      };
    case HIDE_LOADER:
      return {
        ...state,
        loadersShown: --state.loadersShown
      };
    default:
      return state;
  }
};
