import React, { FC } from "react";
import { useSelect } from "downshift";
import _ from "lodash";

import { BaseSelectContentProps, SelectContent } from "./SelectContent";
import { ISelectItem } from "./components";

import "./Select.scss";

export interface SelectProps extends BaseSelectContentProps {
  selected: string;
  onChange: (value: string) => void;
}

export const Select: FC<SelectProps> = ({
  items,
  selected,
  onChange,
  ...props
}) => {
  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps
  } = useSelect<ISelectItem>({
    items,
    itemToString: item => (item ? item.content : ""),
    selectedItem: _.find(items, { value: selected }) || null,
    onStateChange: changes => {
      changes.selectedItem && onChange(changes.selectedItem.value);
    },
    circularNavigation: true
  });

  return (
    <SelectContent
      {...props}
      items={items}
      isOpen={isOpen}
      selectedItem={selectedItem}
      highlightedIndex={highlightedIndex}
      getToggleButtonProps={getToggleButtonProps}
      getLabelProps={getLabelProps}
      getMenuProps={getMenuProps}
      getItemProps={getItemProps}
    />
  );
};
