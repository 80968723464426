import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import { MultipleUserChoice } from "./MultipleUserChoice";
import { SingleUserChoice } from "./SingleUserChoice";
import { isUserMultiple } from "../../../common/utils/permissions";
import { isMultipleSBSupported } from "../../../store/settings/settings.selectors";

export const ProtectionChoicePageForNonProvisioned = () => {
  const hasUserMultipleAccess = useSelector(isUserMultiple);
  const hasSBMultipleSupport = useSelector(isMultipleSBSupported);
  const isMultiple = hasSBMultipleSupport && hasUserMultipleAccess;

  return (
    <Fragment>
      {isMultiple && (
        <Fragment>
          <MultipleUserChoice />
          <div className="divider" />
        </Fragment>
      )}
      {<SingleUserChoice />}
    </Fragment>
  );
};
