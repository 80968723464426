import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

const RouterHook = ({ history, action }) => {
  useEffect(() => history.listen(action), [history, action]);
  return null;
};

RouterHook.propTypes = {
  history: PropTypes.object.isRequired,
  action: PropTypes.func.isRequired
};

export default withRouter(RouterHook);
