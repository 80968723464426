import { LOAD_TRANSLATIONS_SUCCESS } from "./translation.actions";

/*
  interface TranslationState {
    [localeKey: string]: TranslationList;
  }
*/

/*
  interface TranslationList {
    key: string;
    value: string;
  }
*/

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_TRANSLATIONS_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
