import React, { FC, Fragment } from "react";
import { FormattedMessage } from "react-intl";

import { ProfileSelector } from "../../../../components/charts/profileSelector/ProfileSelector";
import { TabletMobile } from "../../../../hocs/Responsive";

interface ProfileSelectProps {
  onProfileChange: (profile: string) => void;
}

export const DrilldownProfileSelect: FC<ProfileSelectProps> = ({
  onProfileChange
}) => {
  return (
    <Fragment>
      <TabletMobile>
        <div className="text-label">
          <FormattedMessage id="show_results_group" />
        </div>
      </TabletMobile>
      <ProfileSelector onProfileChange={onProfileChange} />
    </Fragment>
  );
};
