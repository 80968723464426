import React from "react";

import { UnstyledButton } from "@sportal/cdk/unstyledButton/UnstyledButton";
import { Icon } from "../icon/Icon";

import "./ShowToggle.scss";

export const ShowToggle = props => (
  <UnstyledButton className="show-toggle" onClick={() => props.toggleOpen()}>
    <Icon icon={`fal fa-angle-${props.isOpen ? "up" : "down"}`} />
  </UnstyledButton>
);
