import React, { memo, useMemo } from "react";

import { useIntlTranslate } from "../../../../hooks";
import { Combobox } from "../../../../components/combobox/Combobox";

const TimezonePicker = ({ timezones, selected, onChange }) => {
  const translate = useIntlTranslate();

  const items = useMemo(() => {
    return timezones.map(timezone => ({
      value: timezone.value,
      content: `(GMT ${timezone.offset}) ${translate(timezone.name)}`
    }));
  }, [timezones, translate]);

  return (
    <Combobox
      items={items}
      onChange={onChange}
      selected={selected}
      label={translate("choose_timezone")}
      searchInputPlaceholder={translate("search")}
    />
  );
};

export default memo(TimezonePicker);
