import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { withLayout } from "../../components/layout/Layout";
import { withPromptDialog } from "../../hocs/withPrompt";
import { makeComparator } from "../../utils/makeComparator";
import ProgressButton from "../../components/progressButton/ProgressButton";
import Input from "../../components/input/Input";
import { ReportFrequencyPicker } from "./components/ReportFrequencyPicker";
import {
  changeAddress,
  changeFrequency,
  discardScheduledReportsSettings,
  saveScheduledReportsSettings
} from "../../store/attributes/attributes.actions";
import { Button } from "../../components/button/Button";
import { FREQUENCIES } from "../../store/attributes/attributes.constants";
import { getAccountSettings } from "../../store/root.selectors";
import { useIntlTranslate } from "../../hooks";

import "./ScheduledReportsPage.scss";

const ScheduledReportsPageComponent = withPromptDialog(
  ({
    hasChanges,
    save,
    discard,
    onChangeAddress,
    onChangeFrequency,
    address,
    frequency,
    isValid
  }) => {
    const translate = useIntlTranslate();

    return (
      <div className="scheduled-reports-page row">
        <div className="col-12 col-lg-8 scheduled-reports-page__wrapper">
          <h2 className="scheduled-reports-page__header">
            <FormattedMessage id={"scheduled_reports"} />
          </h2>

          <div className="page-description">
            <FormattedMessage id={"manage_report_settings"} />
          </div>

          <div className="scheduled-reports-form row">
            <div
              className="col-12 col-md-7 col-lg-6"
              data-testid="input-container"
            >
              <Input
                id="scheduled-reports-email-input"
                className="scheduled-reports-form__mail"
                onChange={({ target: { value } }) => onChangeAddress(value)}
                onClear={() => onChangeAddress("")}
                errorMessage={!isValid && <FormattedMessage id="email_error" />}
                value={address}
                label={<FormattedMessage id="email" />}
                placeholder={translate("enter_email")}
              />
            </div>

            <div className="col-12 col-md-5 col-lg-4">
              <ReportFrequencyPicker
                data-testid="report-frequency-picker"
                selected={frequency}
                onChange={onChangeFrequency}
              />
            </div>
          </div>
          <Button
            data-testid="discard-button"
            isOutline
            size={"large"}
            onClick={discard}
            disabled={!hasChanges}
          >
            <FormattedMessage id="discard" />
          </Button>

          <ProgressButton
            data-testid="save-button"
            className="scheduled-reports-form__send-button"
            label={<FormattedMessage id="save" />}
            disabled={!isValid || !hasChanges}
            onClick={save}
          />
        </div>
      </div>
    );
  }
);

const getScheduledReportsSettings = ({ email: { address, frequency } }) => ({
  address,
  frequency
});

const comparator = makeComparator(
  ({ toSave }) => getScheduledReportsSettings(toSave),
  getScheduledReportsSettings
);

const validateAddress = address =>
  /^([^\s"@]+|(?:"[^"]+"))@([^.\s@]+\.)+[^.\s@]{2,63}$/.test(address);

export const ScheduledReportsPageContent = () => {
  const dispatch = useDispatch();
  const accountSettings = useSelector(getAccountSettings);
  const hasChanges = !comparator(accountSettings);
  const save = () => dispatch(saveScheduledReportsSettings());
  const discard = () => dispatch(discardScheduledReportsSettings());
  const { address, frequency } = getScheduledReportsSettings(
    accountSettings.toSave
  );
  const isValid =
    frequency === FREQUENCIES.NEVER
      ? _.isEmpty(address) || validateAddress(address)
      : !_.isEmpty(address) && validateAddress(address);

  const handleOnChangeAddress = value => {
    dispatch(changeAddress(value));
  };

  const handleOnChangeFrequency = frequency => {
    dispatch(changeFrequency(frequency));
  };

  return (
    <ScheduledReportsPageComponent
      hasChanges={hasChanges}
      save={save}
      discard={discard}
      accountSettings={accountSettings}
      onChangeAddress={handleOnChangeAddress}
      onChangeFrequency={handleOnChangeFrequency}
      address={address}
      frequency={frequency}
      isValid={isValid}
    />
  );
};

export const ScheduledReportsPage = withLayout()(ScheduledReportsPageContent);
