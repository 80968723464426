import React, { FC, useCallback } from "react";

import { useLayoutContext } from "../../layout";
import { EulaBanner } from "../eulaBanner";

import "./EulaFooter.scss";

export const EulaFooter: FC = () => {
  const { setEulaFooterHeight } = useLayoutContext();

  const handleHeightChange = useCallback(
    (height: number): void => {
      setEulaFooterHeight(height);
    },
    [setEulaFooterHeight]
  );

  return (
    <EulaBanner
      className="eula-footer"
      contentId="eulaFooterText"
      onHeightChange={handleHeightChange}
    />
  );
};
