export const APPS = {
  PI: "pi",
  SB: "sb",
  SS: "ss"
};

export const SERVICES = {
  SS: "subscriber-safety",
  PI: "personal-internet",
  SB: "secure-business",
  HT: "https-termination"
};

export const PROFILES = {
  NONE: "NONE",
  SINGLE: "SINGLE",
  MULTIPLE: "MULTIPLE"
};

export const SUPPORT = {
  [SERVICES.PI]: {
    [PROFILES.SINGLE]: "household-support",
    [PROFILES.MULTIPLE]: "perdevice-support"
  },
  [SERVICES.SB]: {
    [PROFILES.SINGLE]: "single-profile-support",
    [PROFILES.MULTIPLE]: "multiple-profiles-support"
  },
  [SERVICES.SS]: {
    [APPS.PI]: {
      [PROFILES.NONE]: "standard-support",
      [PROFILES.SINGLE]: "standard-support",
      [PROFILES.MULTIPLE]: "standard-support"
    },
    [APPS.SB]: {
      [PROFILES.SINGLE]: "sb-single-support",
      [PROFILES.MULTIPLE]: "sb-multiple-support"
    }
  }
};

export const SERVICE_PROFILES = {
  [SERVICES.SB]: {
    [PROFILES.SINGLE]: "single-profile",
    [PROFILES.MULTIPLE]: "multiple-profile"
  },
  [SERVICES.PI]: {
    [PROFILES.SINGLE]: "household",
    [PROFILES.MULTIPLE]: "perdevice"
  },
  [SERVICES.SS]: {
    [APPS.SS]: "standard",
    [APPS.PI]: {
      [PROFILES.SINGLE]: "standard",
      [PROFILES.MULTIPLE]: "standard"
    },
    [APPS.SB]: {
      [PROFILES.SINGLE]: "sb-single",
      [PROFILES.MULTIPLE]: "sb-multiple"
    }
  }
};

export const ACCESS_TYPES = {
  "subscriber-safety": {
    app: APPS.PI,
    profilesAllowed: PROFILES.NONE,
    services: {
      [PROFILES.NONE]: [
        {
          service: SERVICES.SS,
          profile: SERVICE_PROFILES[SERVICES.SS][APPS.SS]
        }
      ]
    }
  },
  "pi-single": {
    apps: APPS.PI,
    profilesAllowed: PROFILES.SINGLE,
    services: {
      [PROFILES.SINGLE]: [
        {
          service: SERVICES.PI,
          profile: SERVICE_PROFILES[SERVICES.PI][PROFILES.SINGLE]
        }
      ]
    }
  },
  "pi-profile": {
    apps: APPS.PI,
    profilesAllowed: PROFILES.MULTIPLE,
    services: {
      [PROFILES.SINGLE]: [
        {
          service: SERVICES.PI,
          profile: SERVICE_PROFILES[SERVICES.PI][PROFILES.MULTIPLE]
        }
      ],
      [PROFILES.MULTIPLE]: [
        {
          service: SERVICES.PI,
          profile: SERVICE_PROFILES[SERVICES.PI][PROFILES.MULTIPLE]
        }
      ]
    }
  },
  household: {
    apps: APPS.PI,
    profilesAllowed: PROFILES.SINGLE,
    services: {
      [PROFILES.SINGLE]: [
        {
          service: SERVICES.PI,
          profile: SERVICE_PROFILES[SERVICES.PI][PROFILES.SINGLE]
        },
        {
          service: SERVICES.SS,
          profile: SERVICE_PROFILES[SERVICES.SS][APPS.PI][PROFILES.SINGLE]
        }
      ]
    }
  },
  "per-device": {
    apps: APPS.PI,
    profilesAllowed: PROFILES.MULTIPLE,
    services: {
      [PROFILES.SINGLE]: [
        {
          service: SERVICES.PI,
          profile: SERVICE_PROFILES[SERVICES.PI][PROFILES.MULTIPLE]
        },
        {
          service: SERVICES.SS,
          profile: SERVICE_PROFILES[SERVICES.SS][APPS.PI][PROFILES.MULTIPLE]
        }
      ],
      [PROFILES.MULTIPLE]: [
        {
          service: SERVICES.PI,
          profile: SERVICE_PROFILES[SERVICES.PI][PROFILES.MULTIPLE]
        },
        {
          service: SERVICES.SS,
          profile: SERVICE_PROFILES[SERVICES.SS][APPS.PI][PROFILES.MULTIPLE]
        }
      ]
    }
  },
  "sb-single": {
    apps: APPS.SB,
    profilesAllowed: PROFILES.SINGLE,
    services: {
      [PROFILES.SINGLE]: [
        {
          service: SERVICES.SB,
          profile: SERVICE_PROFILES[SERVICES.SB][PROFILES.SINGLE]
        },
        {
          service: SERVICES.SS,
          profile: SERVICE_PROFILES[SERVICES.SS][APPS.SB][PROFILES.SINGLE]
        }
      ]
    }
  },
  "sb-profile": {
    apps: APPS.SB,
    profilesAllowed: PROFILES.MULTIPLE,
    services: {
      [PROFILES.SINGLE]: [
        {
          service: SERVICES.SB,
          profile: SERVICE_PROFILES[SERVICES.SB][PROFILES.SINGLE]
        },
        {
          service: SERVICES.SS,
          profile: SERVICE_PROFILES[SERVICES.SS][APPS.SB][PROFILES.SINGLE]
        }
      ],
      [PROFILES.MULTIPLE]: [
        {
          service: SERVICES.SB,
          profile: SERVICE_PROFILES[SERVICES.SB][PROFILES.MULTIPLE]
        },
        {
          service: SERVICES.SS,
          profile: SERVICE_PROFILES[SERVICES.SS][APPS.SB][PROFILES.MULTIPLE]
        }
      ]
    }
  }
};
