import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FormattedMessage, injectIntl } from "react-intl";

import { Button } from "../button/Button";
import { Icon } from "../icon/Icon";

import "./NativeSelect.scss";

class NativeSelect extends Component {
  render() {
    const { id, label, className } = this.props;

    return (
      <div
        className={classnames("native-select", className, {
          "native-select--labeled": label
        })}
      >
        {label && (
          <label htmlFor={id} className="native-select__label">
            <FormattedMessage id={label} />
          </label>
        )}
        {this.renderToggle()}
        {this.renderMenu()}
      </div>
    );
  }

  getInputAriaAttributes = () => {
    const { ariaLabelledBy, ariaLabelMessage, intl } = this.props;

    const attributes = {};

    if (ariaLabelledBy) {
      attributes["aria-labelledby"] = ariaLabelledBy;
    } else if (ariaLabelMessage) {
      attributes["aria-label"] = intl.formatMessage({
        id: ariaLabelMessage
      });
    }

    return attributes;
  };

  renderToggle() {
    const { toggleView, isOutline, formatSelection } = this.props;

    const selectedOption = this.getSelectedOption();
    const selectedOptionName = selectedOption && selectedOption.name;
    const selectedOptionValue = selectedOption && selectedOption.value;

    const formattedSelection = formatSelection
      ? formatSelection(selectedOptionName, selectedOptionValue)
      : selectedOptionName;

    switch (toggleView) {
      case "input":
        return (
          <div
            className="native-select__toggle select__toggle_input"
            aria-hidden
          >
            <div className="native-select__toggle__content">
              <span>{formattedSelection}</span>
              <Icon />
            </div>
          </div>
        );
      case "link":
        return (
          <div
            className="native-select__toggle select__toggle_link"
            aria-hidden
          >
            <div className="native-select__toggle__content">
              <span>{formattedSelection}</span>
              <Icon />
            </div>
          </div>
        );
      default: {
        return (
          <div className="native-select__toggle" aria-hidden>
            <div className="native-select__toggle__content">
              <Button isOutline={isOutline} size={"large"}>
                <div className={"btn__content"}>
                  <span>{formattedSelection}</span>
                  <Icon />
                </div>
              </Button>
            </div>
          </div>
        );
      }
    }
  }

  renderMenu() {
    const { id, formatOption, selected, options } = this.props;

    if (_.isEmpty(options)) {
      return null;
    }

    const ariaAttributes = this.getInputAriaAttributes();

    return (
      <select
        id={id}
        onChange={event => this.handleChange(event)}
        value={selected}
        {...ariaAttributes}
      >
        {_.map(options, ({ name, value, isDisabled }) => (
          <option key={value} value={value} disabled={isDisabled}>
            {formatOption ? formatOption(name) : name}
          </option>
        ))}
      </select>
    );
  }

  getSelectedOption() {
    const { options, selected } = this.props;
    return selected ? _.find(options, { value: selected }) : _.first(options);
  }

  handleChange({ target }) {
    const selected = target.value;
    const { onChange } = this.props;

    onChange && onChange(selected);
  }
}

NativeSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string, // Displayed value
      value: PropTypes.string // Value passed to onChange handler
    })
  ),
  selected: PropTypes.string, // value
  isOutline: PropTypes.bool,
  toggleView: PropTypes.oneOf(["button", "input", "link"]).isRequired,
  onChange: PropTypes.func,
  formatOption: PropTypes.func,
  formatSelection: PropTypes.func,
  id: PropTypes.string,
  ariaLabelMessage: PropTypes.string,
  ariaLabelledBy: PropTypes.string
};

export default injectIntl(NativeSelect);
