import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog
} from "../../../../components/modal";

export const RemoveAllDialog = class RemoveAllDialog extends Component {
  render() {
    const { modal, list } = this.props;

    return (
      <ModalDialog
        className={"remove-all-modal"}
        submitHandler={() => modal.resolve()}
        rejectHandler={() => modal.reject()}
      >
        <CommonModalHeader>
          <FormattedMessage id={"confirm_action"} />
        </CommonModalHeader>
        <ModalDialog.Body>
          <FormattedMessage
            id={"clean_up_message"}
            values={{ list: <FormattedMessage id={list} /> }}
          />
        </ModalDialog.Body>
        <CommonModalFooter
          submitMessage={<FormattedMessage id="clean_up" />}
          submitButtonType="destructive"
        />
      </ModalDialog>
    );
  }
};
