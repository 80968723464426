import React from "react";
import _ from "lodash";
import { AnyAction } from "redux";
import { FormattedMessage } from "react-intl";

import { Protection, SafeSearchService } from "@sportal/api";
import { ModalService } from "../../components/modal";
import { SBThunkAction } from "../redux.types";
import { getChangedFields } from "./profiles.helpers";
import {
  ensureProtection,
  saveCustomProtection,
  wipeCustomProtection
} from "../../pages/profiles/components/webFilters/webFilters.actions";
import { getValidationErrors } from "../../pages/profiles/components/schedule/schedules.selectors";
import { saveSchedules } from "../../pages/profiles/components/schedule/schedules.actions";
import { saveSsProfile } from "../../pages/profiles/components/internetSecurity/subscriberSafety/ssProfiles.actions";
import {
  CHANGE_PROTECTION,
  ChangeProtectionAction,
  CHANGE_SAFE_SEARCH_SERVICE,
  ChangeSafeSearchServiceAction,
  RENAME_PROFILE_SUCCESS,
  RenameProfileSuccessAction,
  RENAME_PROFILE_FAILURE,
  RenameProfileFailureAction,
  SAVE_PROFILE_SUCCESS,
  SaveProfileSuccessAction,
  SAVE_PROFILE_FAILURE,
  SaveProfileFailureAction,
  ProfileError,
  SBProfile
} from "./profiles.types";
import Notificator from "../../components/notification/notification.actions";
import ProfileDialog from "../../pages/profiles/components/profileDialog/ProfileDialog";
import { AddNotificationAction } from "../../components/notification/notification.types";
import { shouldSaveChangesToBackEnd } from "./profiles.selectors";

export const renameProfileSuccess = (
  profile: string,
  newName: string,
  currentName: string
): RenameProfileSuccessAction => ({
  type: RENAME_PROFILE_SUCCESS,
  payload: { profile, newName, currentName }
});

export const renameProfileFailure = (
  error: ProfileError
): RenameProfileFailureAction => ({
  type: RENAME_PROFILE_FAILURE,
  payload: error
});

export const openEditProfileModal = (
  profileNames: string[],
  profile: SBProfile
): SBThunkAction<void, any> => (dispatch, getState) => {
  const sendRequest = shouldSaveChangesToBackEnd(getState());

  ModalService.show(modal => ({
    dialog: (
      <ProfileDialog
        profiles={profileNames}
        profile={profile}
        modal={modal}
        title={"edit_group"}
        submitText={"rename"}
      />
    )
  }))
    .then(name =>
      sendRequest
        ? dispatch(renameProfileRequest(profile.id, name))
        : dispatch(renameProfileSuccess(profile.id, name, profile.name))
    )
    .catch(() => null);
};

// TODO: rename `renameProfileRequest` and `renameProfile` (or replace field-changing actions with generic one)
export const renameProfileRequest = (
  profileId: string,
  newName: string
): SBThunkAction<
  Promise<void>,
  | RenameProfileSuccessAction
  | SaveProfileSuccessAction
  | RenameProfileFailureAction
  | AddNotificationAction
> => (dispatch, getState, { api }) => {
  const { profiles, subscriberInfo } = getState();
  const currentName = profiles.saved.list[profileId].name;

  return api.ssm.profile
    .save(subscriberInfo.id, currentName, {
      name: newName
    })
    .then(() => {
      dispatch(renameProfileSuccess(profileId, newName, currentName));
      dispatch(saveProfileSuccess());
    })
    .catch(error => {
      dispatch(
        Notificator.error(<FormattedMessage id={"failed_to_save_profile"} />)
      );
      dispatch(renameProfileFailure(error));
      return Promise.reject();
    });
  // TODO: old app did not do this, new behavior is unclear
  // .catch(() => {
  //   resetProfile(profileId);
  //   return Promise.reject();
  // });
};

export const changeProtection = (
  profile: string,
  protection: Protection
): ChangeProtectionAction => ({
  type: CHANGE_PROTECTION,
  payload: { profile, protection }
});

export const changeSafeSearchService = (
  profile: string,
  service: SafeSearchService,
  enabled: boolean
): ChangeSafeSearchServiceAction => ({
  type: CHANGE_SAFE_SEARCH_SERVICE,
  payload: { profile, service, enabled }
});

export const ERRORS = {
  policies: "policies_save_request_fail",
  schedules: "schedules_save_request_fail",
  ss: "ss_save_request_fail",
  protection: "cannot_save_protection_level",
  safeSearch: "cannot_save_safe_search"
};

// TODO: probably can be named as update? saveProfileChanges?
// TODO: add action types to union once we get them
export const saveProfile = (
  profileId: string
): SBThunkAction<
  Promise<void>,
  SaveProfileSuccessAction | SaveProfileFailureAction | AnyAction
> => (dispatch, getState, { api }) => {
  dispatch(ensureProtection(getState().profiles.changed.list[profileId]));

  let { profiles, subscriberInfo, schedules } = getState();
  let profile = profiles.changed.list[profileId];

  const updates = getChangedFields(profileId, profiles);
  const saveErrors = [];

  const errors = getValidationErrors(schedules);
  if (!_.isEmpty(errors)) {
    return Promise.reject(errors);
  }

  let saveProfile = !_.isEmpty(updates)
    ? api.ssm.profile
        .save(subscriberInfo.id, profile.name, updates)
        .catch(error => {
          _.has(updates, "protection") && saveErrors.push(ERRORS.protection);
          _.has(updates, "safe-search-services") &&
            saveErrors.push(ERRORS.safeSearch);

          return dispatch(saveProfileFailure(error));
        })
    : Promise.resolve();

  if (profile.protection === Protection.Custom) {
    saveProfile = saveProfile
      .then(() => dispatch(saveCustomProtection(profile)))
      .catch(() => saveErrors.push(ERRORS.policies));
  } else {
    dispatch(wipeCustomProtection(profile.id));
  }

  saveProfile = saveProfile
    .then(() => dispatch(saveSchedules()))
    .catch(() => saveErrors.push(ERRORS.schedules));

  saveProfile = saveProfile
    .then(() => dispatch(saveSsProfile(profileId)))
    .catch(() => saveErrors.push(ERRORS.ss));

  return saveProfile
    .then(() =>
      !_.isEmpty(saveErrors)
        ? Promise.reject(
            _.map(saveErrors, error => ({
              type: "save",
              message: error
            }))
          )
        : Promise.resolve()
    )
    .then(() => dispatch(saveProfileSuccess()));
};

export const saveProfileSuccess = (): SaveProfileSuccessAction => ({
  type: SAVE_PROFILE_SUCCESS
});

export const saveProfileFailure = (
  error: ProfileError
): SaveProfileFailureAction => ({
  type: SAVE_PROFILE_FAILURE,
  payload: error
});
