import React, { useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Modal } from "./Modal";
import { ModalService } from "./modal.service";

export const ModalHost = props => {
  const modalRef = useRef();

  // useEffect was replaced due to undefined modalRef reference when user loads the page for a first time
  useLayoutEffect(() => {
    if (modalRef) {
      ModalService.setElement(modalRef);
    }
  });

  return <Modal ref={modalRef} {...props} />;
};

ModalHost.propTypes = {
  mobileBreakpoint: PropTypes.number.isRequired
};
