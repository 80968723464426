import { useState, useMemo } from "react";

export const useTooltipEvents = () => {
  const [show, setShow] = useState(false);

  const toggleStateEvents = useMemo(() => {
    const display = () => setShow(true);
    const hide = () => setShow(false);

    return {
      onMouseEnter: display,
      onFocus: display,
      onMouseLeave: hide,
      onBlur: hide
    };
  }, []);

  return [show, toggleStateEvents];
};
