import { AuthMethod, HttpServiceError, InfoServerData } from "@sportal/api";

export const LOAD_INFO_SUCCESS = "[AUTH] LOAD_INFO_SUCCESS";
export const LOAD_INFO_FAILURE = "[AUTH] LOAD_INFO_FAILURE";
export const SET_AUTH_METHOD = "[AUTH] SET_AUTH_METHOD";
export const WRONG_USER_MODE = "[AUTH] WRONG USER MODE";

interface LoadInfoSuccessAction {
  type: typeof LOAD_INFO_SUCCESS;
  payload: InfoServerData;
}

interface LoadInfoFailureAction {
  type: typeof LOAD_INFO_FAILURE;
  payload: HttpServiceError;
}

interface SetAuthMethod {
  type: typeof SET_AUTH_METHOD;
  payload: AuthMethod;
}

interface WrongUserMode {
  type: typeof WRONG_USER_MODE;
}

export type InfoActionTypes =
  | LoadInfoSuccessAction
  | LoadInfoFailureAction
  | SetAuthMethod
  | WrongUserMode;
