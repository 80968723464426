import React, { Component, Fragment } from "react";
import { Prompt } from "react-router-dom";
import PropTypes from "prop-types";

import { getDisplayName } from "../utils/getDisplayName";
import { ModalService } from "../components/modal";
import { ConfirmationDialog } from "../components/confirmationDialog/ConfirmationDialog";
import { history } from "../router";

export function withPrompt(options = {}) {
  const { showDialog } = options;

  return WrappedComponent =>
    class WithPrompt extends Component {
      static displayName = `WithPrompt(${getDisplayName(WrappedComponent)})`;
      static propTypes = {
        hasChanges: PropTypes.bool.isRequired,
        // TODO: location is used to prevent navigation to the same path
        //  would be nice to fix it at high level somehow
        //  https://github.com/ReactTraining/history/issues/470
        location: PropTypes.object.isRequired
      };

      render() {
        const { hasChanges } = this.props;
        return (
          <Fragment>
            <Prompt when={hasChanges} message={this.handleNavigation} />
            <WrappedComponent {...this.props} />
          </Fragment>
        );
      }

      handleNavigation = nextLocation => {
        const { location } = this.props;

        if (location.pathname === nextLocation.pathname) {
          return false;
        }

        showDialog({
          ...this.props,
          nextAction: () => history.push(nextLocation.pathname)
        });
        return false; // Blocks navigation
      };
    };
}

export const withPromptDialog = Component => ({
  hasChanges,
  save,
  discard,
  ...componentProps
}) => {
  const handleNavigation = nextLocation => {
    if (history.location.pathname === nextLocation.pathname) {
      return false;
    }

    showConfirmationDialog({
      save,
      discard,
      nextAction: () => history.push(nextLocation.pathname)
    })
      .then(() => {})
      .catch(e => new Error(e));

    return false; // Blocks navigation
  };

  return (
    <Fragment>
      <Prompt when={hasChanges} message={handleNavigation} />
      <Component
        {...componentProps}
        hasChanges={hasChanges}
        save={save}
        discard={discard}
      />
    </Fragment>
  );
};

withPromptDialog.propTypes = {
  hasChanges: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  discard: PropTypes.func.isRequired
};

/** Default implementation */
export const showConfirmationDialog = ({
  nextAction,
  save,
  discard,
  ...restProps
}) =>
  ModalService.show(modal => ({
    dialog: <ConfirmationDialog modal={modal} {...restProps} />
  }))
    .then(({ shouldSave } = {}) => (shouldSave ? save() : discard()))
    .then(() => {
      nextAction();
    })
    .catch(() => {});
