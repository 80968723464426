import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import _ from "lodash";

import "./Header.scss";

import Flipswitch from "../../flipswitch/Flipswitch";
import UserMenu from "./userMenu/UserMenu";
import ButtonLink from "../../buttonLink/ButtonLink";
import {
  Desktop,
  Mobile,
  NotMobile,
  TabletMobile
} from "../../../hocs/Responsive";
import { MobileMenuContainer } from "../mobileMenu/MobileMenuContainer";
import { PortalTitle } from "../portalTitle/PortalTitle";

import { SERVICES } from "../../../common/utils/permissions";
import { updateWebFilter } from "../../../store/account/account.actions";
import { logout } from "../../../pages/login/login.actions";
import { HelpLink } from "../../helpLink/HelpLink";

export const headerClassName = "main-header";

class Header extends Component {
  render() {
    const {
      portalName,
      logoLink,
      showUserInfo,
      showHeaderUnderline,
      showLogOutLink,
      showHelpLink
    } = this.props;

    const headerClasses = classnames(headerClassName, {
      header: true,
      "header--bordered": showHeaderUnderline
    });

    return (
      <div className={headerClasses}>
        <div className={"header__content"}>
          <PortalTitle title={portalName} link={logoLink} />
          {showUserInfo && this.renderUserInfo()}
          {showHelpLink && this.renderHelpLink()}
          {showLogOutLink && this.renderLogOutLink()}
        </div>
      </div>
    );
  }

  renderUserInfo() {
    const {
      subscriberName,
      logout,
      portalName,
      isSbEnabled,
      updateWebFilter,
      customLinkRef
    } = this.props;

    return (
      <div className="header__info">
        {customLinkRef && (
          <a className={"custom-header-link"} href={customLinkRef}>
            <FormattedMessage id={"custom_header_link"} />
          </a>
        )}
        <Desktop>
          <Flipswitch
            id="web-filter-switch"
            label={<FormattedMessage id="protection" />}
            isChecked={isSbEnabled}
            handleChange={event => updateWebFilter(event.target.checked)}
          />
          <UserMenu
            subscriberName={subscriberName}
            logout={logout}
            portalName={portalName}
          />
        </Desktop>
        <TabletMobile>
          <MobileMenuContainer
            subscriberName={subscriberName}
            portalName={portalName}
            logout={logout}
            isSbEnabled={isSbEnabled}
            updateWebFilter={updateWebFilter}
          />
        </TabletMobile>
      </div>
    );
  }

  renderLogOutLink() {
    return (
      <ButtonLink className="header__link" onClick={this.props.logout}>
        <FormattedMessage id={"logout"} />
      </ButtonLink>
    );
  }

  renderHelpLink() {
    return (
      <HelpLink className="header__link">
        <NotMobile>
          <FormattedMessage id={"help_and_documentation"} />
        </NotMobile>
        <Mobile>
          <FormattedMessage id={"help"} />
        </Mobile>
      </HelpLink>
    );
  }
}

Header.propTypes = {
  showUserInfo: PropTypes.bool,
  showHeaderUnderline: PropTypes.bool,
  showHelpLink: PropTypes.bool,
  showLogOutLink: PropTypes.bool
};

export default connect(
  state => {
    const {
      subscriberInfo,
      account,
      accountSettings: { businessName },
      config: { logoLink, customLinkText, customLinkRef }
    } = state;

    return {
      subscriberName: subscriberInfo.name,
      portalName: businessName,
      isSbEnabled:
        !_.isUndefined(account[SERVICES.SB]) && account[SERVICES.SB].enabled,
      logoLink,
      customLinkRef,
      customLinkText
    };
  },
  {
    logout,
    updateWebFilter
  }
)(Header);
